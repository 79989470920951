import {ColumnId} from 'common/types/storage'
import {CellObject} from 'common/types/data_table'
import {Filter, FilterCondition, FilterOperator, FilterType, FilterValue} from 'common/types/filter'
import {FilterFn, TableObject} from 'common/objects/data_table'
import {throw_error} from 'common/utils'
import {prepare_filter_conditions} from './filter_utils'
import {filter_to_fn} from './filter_predicates'

//iterate through all values in one filter condition
export const apply_one_filter_on_cell = (
  cell: CellObject,
  type: FilterType,
  values: FilterValue<'parsed'>[],
  operator: FilterOperator
): boolean => {
  const _comparator_fn = (filter_value: FilterValue<'parsed'>) =>
    filter_to_fn[type](cell, filter_value)

  switch (operator) {
    case 'or':
      return values.some(_comparator_fn)
    case 'and':
      return values.every(_comparator_fn)
    default:
      return throw_error('Unknown filter operator', 'operator', operator)
  }
}

//iterate through all filter conditions applicable to the cell
export const apply_all_filters_on_cell = (
  cell: CellObject,
  conditions: FilterCondition<'parsed'>,
  operator: FilterOperator
): boolean => {
  const _apply_filter = (filter_name: FilterType) =>
    apply_one_filter_on_cell(cell, filter_name, conditions[filter_name], operator)

  switch (operator) {
    case 'or':
      return Object.keys(conditions).some(_apply_filter)
    case 'and':
      return Object.keys(conditions).every(_apply_filter)
    default:
      return throw_error('Unknown filter operator', 'operator', operator)
  }
}

// create filtering function, which will take one row and iterate through
// all columns which have some filters defined
export const create_advanced_filter = (
  filter: Filter<'internal'>,
  table: TableObject
): FilterFn => {
  const {operator} = filter
  const conditions = prepare_filter_conditions(filter.conditions)
  const filter_col_ids = Object.keys(conditions).filter((col_id) => col_id in table._cols)

  if (filter_col_ids.length === 0) {
    // No valid conditions on existing columns = no filtering applied
    return () => true
  }

  return ({row}) => {
    const filter_cell = (col_id: ColumnId) =>
      apply_all_filters_on_cell(row._cell(col_id), conditions[col_id], operator)

    switch (operator) {
      case 'or':
        return filter_col_ids.some(filter_cell)
      case 'and':
        return filter_col_ids.every(filter_cell)
      default:
        return throw_error('Unknown filter operator', 'operator', operator)
    }
  }
}
