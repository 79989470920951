import React from 'react'
import {Grid} from '@material-ui/core'
import _ from 'lodash'

import {ROUTES} from './utils/navigation_utils'
import GalleryListItem from './GalleryListItem'
import SectionCaption from './components/SectionCaption'
import {useRuntimeSelector} from './utils/connect_hocs'
import LoadingSkeleton from './components/LoadingSkeleton'

const RecentlyViewed = () => {
  const {storage} = useRuntimeSelector()

  if (!storage) return <LoadingSkeleton />

  const organisations = _.sortBy(
    Object.values(_.pickBy(storage.entity_headers, (entity) => entity.type === 'organisation')),
    [(organisation) => organisation.name.toLowerCase()]
  )
  const organisations_changes = _.groupBy(
    Object.values(
      _.pickBy(
        storage.entity_headers,
        (entity) => entity.type === 'project' && !_.isEmpty(storage.multidiff[entity.entity_id])
      )
    ),
    (project) => project.parent_id
  )
  return (
    <>
      <SectionCaption header="Organisations" />
      <Grid container spacing={2}>
        {organisations.map(({entity_id, name, type}) => (
          <GalleryListItem
            key={entity_id}
            label={name}
            href={ROUTES.organisation(entity_id)}
            type={type}
            hasUnsavedChanges={
              !_.isEmpty(organisations_changes[entity_id]) ||
              !_.isEmpty(storage.multidiff[entity_id])
            }
            entity_id={entity_id}
          />
        ))}
      </Grid>
      {/*
      <SectionCaption header="Recently open" />
      <Grid container spacing={2}>
        {RECENT_TABLES.map(({id, label}) => (
          <GalleryListItem key={id} label={label} href={ROUTES.table(id)} type="data_table" />
        ))}
      </Grid>
      */}
    </>
  )
}

export default RecentlyViewed
