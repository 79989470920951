import {createTheme} from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import green from '@material-ui/core/colors/green'
import blueGrey from '@material-ui/core/colors/blueGrey'

// augmentColor is not provided as static method, create an empty pallete to get it
const {augmentColor} = createPalette({})

declare module '@material-ui/core/styles/createPalette' {
  interface GridPalette {
    border: string
    background: string
    outerBorder: string
    cell: {
      defaultBackground: string
      defaultText: string
      createdBackground: string
      changedBackground: string
      foundBackground: string
    }
  }

  interface ColorPalette {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }

  // define custom palette colors for create
  interface PaletteOptions {
    background?: Partial<TypeBackground>
    positive?: PaletteColorOptions
    negative?: PaletteColorOptions
    diff?: PaletteColorOptions
    link?: PaletteColorOptions
    panelBackground?: PaletteColorOptions
    grid?: GridPalette
    greenPalette?: ColorPalette
    greyPalette?: ColorPalette
  }

  // define custom palette colors for use of Theme
  interface Palette {
    background: TypeBackground
    positive: PaletteColor
    negative: PaletteColor
    diff: PaletteColor
    link: PaletteColor
    changed: PaletteColor
    panelBackground: PaletteColor
    grid: GridPalette
    greenPalette: ColorPalette
    greyPalette: ColorPalette
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface TypographyOptions {
    gridCell?: {
      normal: string
      mono: string
      icon: string
    }
  }

  interface Typography {
    gridCell: {
      normal: string
      mono: string
      icon: string
    }
  }
}

const theme = createTheme({
  palette: {
    common: {
      black: blueGrey[900],
      white: '#fff',
    },
    primary: {
      main: green[600],
    },
    // these will get auto-augmented (i.e. light/dark variants will be added automatically)
    secondary: {
      main: '#e79920',
    },
    error: {
      light: '#ffebee',
      main: '#d50000',
    },
    warning: {
      light: '#fff3e0',
      main: '#ff9800',
    },
    info: {
      main: '#3b99fc',
    },
    success: {
      main: '#3a835b',
    },
    // we need to augment custom colors manually
    positive: augmentColor({main: '#3a835b'}),
    negative: augmentColor({main: '#d9364c'}),
    diff: augmentColor({main: '#eb6d24'}),
    link: augmentColor({main: '#6b6b6b'}),
    panelBackground: augmentColor({main: '#f5f7f9'}),
    grid: {
      border: blueGrey[100],
      background: '#f3f3f3',
      outerBorder: '#c0c0c0',
      cell: {
        defaultBackground: '#fff',
        defaultText: blueGrey[900],
        createdBackground: '#99ffb3',
        changedBackground: '#ffd399',
        foundBackground: '#ffd66e',
      },
    },
    greenPalette: {
      50: green[50],
      100: green[100],
      200: green[200],
      300: green[300],
      400: green[400],
      500: green[500],
      600: green[600],
      700: green[700],
      800: green[800],
      900: green[900],
    },
    greyPalette: {
      50: blueGrey[50],
      100: blueGrey[100],
      200: blueGrey[200],
      300: blueGrey[300],
      400: blueGrey[400],
      500: blueGrey[500],
      600: blueGrey[600],
      700: blueGrey[700],
      800: blueGrey[800],
      900: blueGrey[900],
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    h1: {
      fontSize: 96,
      lineHeight: '113px',
    },
    h2: {
      fontSize: 60,
      lineHeight: '70px',
    },
    h3: {
      fontSize: 48,
      lineHeight: '56px',
    },
    h4: {
      fontSize: 34,
      lineHeight: '40px',
    },
    h5: {
      fontSize: 24,
      lineHeight: '28px',
    },
    h6: {
      fontSize: 20,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '20px',
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '16px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
    },
    gridCell: {
      normal: '14px Roboto, Helvetica, sans-serif',
      mono: '14px "Courier New", Courier, monospace',
      icon: '600 14px "Font Awesome 5 Free"',
    },
  },
})

export default theme
