import _ from 'lodash'
import ColumnItem from './ColumnItem'
import React, {FC, useCallback, useState} from 'react'
import {Card, CardContent, Box, styled} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {ColumnId} from 'common/types/storage'
import {col_id_on_index} from '../utils/table_helpers'
import {useRuntimeSelector, AlwaysDefinedRuntime} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import CustomInput from '../components/CustomInput'
import CustomButton from '../components/CustomButton'

const StyledBox = styled(Box)(({theme}) => ({
  '& > *': {
    marginRight: theme.spacing(1),
  },
  '& > *:last-child': {
    marginRight: 0,
  },
}))

const StyledCardContent = styled(CardContent)(({theme}) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}))

const HideColumnsEditor: FC = () => {
  const {
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const [filter_text, set_filter_text] = useState('')

  const {_cols: cols, _cols_order: cols_order} = table
  const col_ids = _.filter(
    cols_order,
    (col_id) => cols[col_id].name.includes(filter_text) && col_id !== col_id_on_index(table, 0)
  )

  const {dispatch_storage} = useRuntimeActions()

  const toggle = useCallback(
    (col_ids: ColumnId[], checked: boolean) => {
      dispatch_storage(table._actions.change_cols_hidden_flag(col_ids, checked))
    },
    [dispatch_storage, table._actions]
  )

  const handle_change = useCallback((evt) => {
    set_filter_text(evt.target.value)
  }, [])
  return (
    <Card>
      <StyledCardContent>
        <CustomInput
          autoFocus
          placeholder="Filter columns"
          value={filter_text}
          onChange={handle_change}
          icon={<SearchIcon />}
          fullWidth
        />
        {_.map(col_ids, (col_id) => (
          <ColumnItem key={col_id} table={table} col_id={col_id} toggle={toggle} />
        ))}
        <StyledBox display="flex" justifyContent="space-between" mt={2}>
          <CustomButton onClick={() => toggle(col_ids, false)} type="tertiary" fullWidth>
            Show all
          </CustomButton>
          <CustomButton onClick={() => toggle(col_ids, true)} type="tertiary" fullWidth>
            Hide all
          </CustomButton>
        </StyledBox>
      </StyledCardContent>
    </Card>
  )
}

export default HideColumnsEditor
