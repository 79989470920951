import _ from 'lodash'
import {ProjectId, EntityHeaders, EntityHeader, Entity, OrganisationId} from './types/storage'
import {Runtime} from 'common/create_runtime'
import {MaybeError} from 'common/error'

const get_projects_in_organisation = (
  entity_headers: EntityHeaders,
  organisation_id: OrganisationId
): EntityHeaders<'project'> => {
  return _.pickBy(
    entity_headers,
    (header): header is EntityHeader<'project'> =>
      ['project'].includes(header.type) && header.parent_id === organisation_id
  )
}

const organisations_project_entities_task = (
  runtime: Runtime,
  project_id: ProjectId
): Array<() => MaybeError<Entity<'project'>>> => {
  const headers = runtime.get_headers()
  const project_headers = get_projects_in_organisation(headers, project_id)
  return Object.keys(project_headers).map((entity_id) => () =>
    runtime.get_entity<'project'>(entity_id)
  )
}

export {organisations_project_entities_task, get_projects_in_organisation}
