import {ReduxThunk, get_table_ui_dispatch, get_table_ui_state} from '../utils/connect_hocs'
import {get_filter_conditions} from 'common/filter/filter_utils'
import {TableEntityId} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {UiFilterCondition} from 'common/types/filter'

export function open_filter_dropdown(
  table_entity_id: TableEntityId,
  table: TableObject,
  new_conditions: UiFilterCondition[] = []
): ReduxThunk {
  return (dispatch, get_state) => {
    const {filter} = get_table_ui_state(get_state(), table_entity_id)
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    set_table_ui_state(
      {
        dropdown: 'filter',
        filter_dropdown: {
          conditions: get_filter_conditions(filter, table).concat(new_conditions),
          operator: filter ? filter.operator : 'and',
        },
      },
      'open_filter_dropdown'
    )
  }
}

export function open_sort_dropdown(table_entity_id: TableEntityId): ReduxThunk {
  return (dispatch) => {
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    set_table_ui_state(
      {
        dropdown: 'sort',
      },
      'open_sort_dropdown'
    )
  }
}

export function open_params_dropdown(table_entity_id: TableEntityId): ReduxThunk {
  return (dispatch) => {
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    set_table_ui_state(
      {
        dropdown: 'params',
      },
      'open_params_dropdown'
    )
  }
}

export function close_toolbar_dropdowns(table_entity_id: TableEntityId): ReduxThunk {
  return (dispatch) => {
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    set_table_ui_state(
      {
        dropdown: null,
      },
      'close_toolbar_dropdowns'
    )
  }
}
