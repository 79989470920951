import map_diff from './map_diff'
import _ from 'lodash'
import {create_squash} from './create_squash'
import {get_indexes, throw_error} from '../../utils'
import {Nullable} from 'common/types/storage'

export type OrderedDiff = {
  old_index: Nullable<number>
  new_index: Nullable<number>
}

export function ordered_diff(delete_empty = true) {
  // conflict-free value apply
  function ordered_apply(old_index, diff: OrderedDiff) {
    const {old_index: o2, new_index: n2} = diff
    if (old_index === o2) {
      return n2
    } else if (old_index == null) {
      return null
    } else {
      return n2
    }
  }

  function ordered_reverse(diff: OrderedDiff): OrderedDiff {
    return {old_index: diff.new_index, new_index: diff.old_index}
  }

  function ordered_ensure_consistency(index, diff: OrderedDiff) {
    if (index === undefined) index = null
    if (index !== diff.old_index)
      throw_error(
        'Value diff consistency problem: Can not apply diff without causing conflicts',
        'value',
        index,
        'diff',
        diff
      )
  }

  function ordered_change_diff(
    old_index: Nullable<number>,
    new_index: Nullable<number>
  ): OrderedDiff {
    return {old_index, new_index}
  }

  // conflict-free value squash
  function ordered_squash2(diff1, diff2) {
    const {old_index: o1, new_index: n1} = diff1
    const {old_index: o2, new_index: n2} = diff2

    if (n1 === o2) {
      return ordered_change_diff(o1, n2)
    } else if (n1 == null) {
      return ordered_change_diff(o1, null)
    } else if (o2 == null) {
      return ordered_change_diff(null, n2)
    } else {
      return ordered_change_diff(o1, n2)
    }
  }

  function is_empty(value) {
    return value == null
  }

  function get_old_index(diff) {
    return diff.old_index
  }

  function get_new_index(diff) {
    return diff.new_index
  }

  return {
    apply: ordered_apply,
    reverse: ordered_reverse,
    ensure_consistency: ordered_ensure_consistency,
    squash2: ordered_squash2,
    squash: create_squash(ordered_squash2),
    change_diff: ordered_change_diff,
    is_empty,
    get_old_index,
    get_new_index,
    delete_empty,
  }
}

export default function ordered_set_diff(delete_empty = true) {
  const inner_diff = ordered_diff()

  const {
    apply: _apply,
    reverse,
    ensure_consistency: _ensure_consistency,
    squash2,
    squash,
    change_diff: _change_diff,
  } = map_diff(inner_diff)

  function apply(value, diff) {
    const old_indexes = get_indexes(value)
    const new_indexes = _.pickBy(_apply(old_indexes, diff), (index) => index != null)

    const inverted = _.invertBy(new_indexes)
    return _.flatten(
      _.compact(
        _.reduce(
          inverted,
          (acc, keys, index) => {
            acc[index] = keys.sort()
            return acc
          },
          []
        )
      )
    )
  }

  function ensure_consistency(value, diff) {
    //set of keys of diff should be superset of set of elements of value
    //in other words union of keys(value) and keys(diff) should equal keys(diff)
    if (_.difference(value, Object.keys(diff)).length > 0)
      throw_error(
        'Ordered set diff consistency problem: set of keys of diff is not superset of set of keys of value',
        'value',
        value,
        'diff',
        diff
      )

    const value_indexes = get_indexes(value)
    _ensure_consistency(value_indexes, diff)
  }

  function unapply(value, diff, ensure: boolean = true) {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(value, reversed_diff)
    return apply(value, reversed_diff)
  }

  function conflict_free(value) {
    return value
  }

  function is_conflict(value) {
    return false
  }

  function get_conflicts(value) {
    return []
  }

  function is_empty(value) {
    return _.isEmpty(value)
  }

  // helper for add/remove/move elements from ordered set
  function change_diff(old_values, new_values) {
    const old_indexes = get_indexes(old_values)
    const new_indexes = get_indexes(new_values)
    return _change_diff(old_indexes, new_indexes)
  }

  function clean_neutral_diffs(diff) {
    // ordered set diff is neutral, only if it doesn't change the whole vector
    const old_indexes = Object.values(diff).map(inner_diff.get_old_index)
    const new_indexes = Object.values(diff).map(inner_diff.get_new_index)
    if (_.isEqual(old_indexes, new_indexes)) {
      return null
    }
    return diff
  }

  return {
    conflict_free,
    is_conflict,
    get_conflicts,
    is_empty,
    apply,
    reverse,
    ensure_consistency,
    unapply,
    squash2,
    squash,
    change_diff,
    create_diff: (value) => change_diff(null, value),
    remove_diff: (value) => change_diff(value, null),
    clean_neutral_diffs,
    delete_empty,
    default_value: [],
  }
}
