import React, {useCallback, useMemo} from 'react'
import {MenuItem} from '@material-ui/core'
import {CellType, ColumnType} from 'common/types/storage'
import {FilterType} from 'common/types/filter'
import {
  filter_to_display_name,
  get_filter_types_applicable_to_column,
} from 'common/filter/filter_utils'
import CustomSelect from '../components/CustomSelect'

type FilterTypeSelectorProps = {
  value: FilterType
  col_type: ColumnType<CellType, 'entity'>
  on_change: (value: FilterType) => void
}

const FilterTypeSelector: React.FC<FilterTypeSelectorProps> = ({value, col_type, on_change}) => {
  const handle_change = useCallback((e) => on_change(e.target.value), [on_change])

  const filter_types = useMemo(() => get_filter_types_applicable_to_column(col_type), [col_type])

  return (
    <CustomSelect
      value={value}
      onChange={handle_change}
      renderValue={(value: FilterType) => filter_to_display_name[value]}
    >
      {filter_types.map((filter_type) => (
        <MenuItem key={filter_type} value={filter_type}>
          {filter_to_display_name[filter_type]}
        </MenuItem>
      ))}
    </CustomSelect>
  )
}

export default FilterTypeSelector
