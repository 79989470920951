import {CursorCoordinates, TableUiState} from './connect_hocs'

type ScrollNewCursorPayload = Pick<
  TableUiState,
  'cursor' | 'last_cursor' | 'scroll_to' | 'last_scroll_to' | 'selection_size'
>

const scroll_to_new_cursor_payload = (
  new_cursor: CursorCoordinates | null
): ScrollNewCursorPayload => ({
  cursor: new_cursor,
  last_cursor: new_cursor,
  scroll_to: new_cursor,
  last_scroll_to: new_cursor,
  selection_size: [0, 0],
})

export {scroll_to_new_cursor_payload}
