import React, {FC, useEffect, useState} from 'react'
import styles from './DetailedRowView.module.css'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {focus_table_content} from '../utils/focus'
import {clear_history, get_current_entry, HistoryEntry} from './history_actions'
import DetailedViewHeader from './DetailedViewHeader'
import CellDetail from './CellDetail'
import {index_of_row} from '../utils/table_helpers'
import {ColumnId} from 'common/types/storage'
import DetailTooltips from './DetailTooltips'

const DetailedRowView: FC = () => {
  const {
    resources: {
      table_resources: {table_entity_id, detailed_view_table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const detailed_view = useTableUiSelector(table_entity_id, 'detailed_view')
  const rows_order = useTableUiSelector(detailed_view_table._entity_id, 'full_rows_order')

  const current_entry = get_current_entry(detailed_view)
  const [last_entry, set_last_entry] = useState<HistoryEntry | null>(null)

  let displayed_entry: HistoryEntry | null
  // Displayed entry may not be the 'current' one, if the new table is still loading.
  // Therefore, we check if the entry really corresponds to the loaded table, so we don't
  // try to access the displayed row on the wrong table.
  if (current_entry?.table_entity_id === detailed_view_table._entity_id) {
    displayed_entry = current_entry
  } else if (last_entry?.table_entity_id === detailed_view_table._entity_id) {
    displayed_entry = last_entry
  } else {
    displayed_entry = null
  }

  // Remember the last displayed entry so we can show it during loading
  useEffect(() => {
    if (displayed_entry !== last_entry) {
      set_last_entry(displayed_entry)
    }
  }, [displayed_entry, last_entry, set_last_entry])

  const displayed_entity_id = displayed_entry?.table_entity_id
  const displayed_row_id = displayed_entry?.row_id

  const [focused_col_id, set_focused_col_id] = useState<ColumnId | null>(null)

  // Reset focused column when displayed table changes
  useEffect(() => {
    set_focused_col_id(null)
  }, [set_focused_col_id, displayed_entity_id])

  // Clear history when detailed view is closed
  useEffect(() => {
    return () => {
      set_table_ui_state(clear_history(), 'close_detailed_view')
      focus_table_content()
    }
  }, [set_table_ui_state])

  if (displayed_row_id === undefined) {
    return <>{'No row to display.'}</>
  } else if (index_of_row(rows_order, displayed_row_id) < 0) {
    return <>{`Row with id ${displayed_row_id} not found.`}</>
  }

  const displayed_row = detailed_view_table._row(displayed_row_id)
  const label = String(displayed_row.get_label())

  return (
    <>
      <div className={styles.details}>
        <DetailedViewHeader label={label} row_id={displayed_row_id} rows_order={rows_order} />
        {detailed_view_table._visible_cols_order.map((col_id) => (
          <CellDetail
            key={col_id}
            col_id={col_id}
            row_id={displayed_row_id}
            focused={col_id === focused_col_id}
            on_show_tooltips={set_focused_col_id}
          />
        ))}
      </div>
      {focused_col_id != null &&
        detailed_view_table._visible_cols_order.includes(focused_col_id) && (
          <DetailTooltips row_id={displayed_row_id} col_id={focused_col_id} />
        )}
    </>
  )
}

export default DetailedRowView
