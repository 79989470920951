import React, {useCallback, FC, ReactNode} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import FunctionEditor from './FunctionEditor'
import styles from './FunctionEditorSwitch.module.css'
import classNames from 'classnames'
import templates from './functionTemplates'
import {TextField, MenuItem} from '@material-ui/core'
import {
  SummaryComputeMethod,
  get_available_methods_for_col_type,
} from 'common/summary/compute_methods'
import {EditFlag} from 'common/objects/data_table'
import {CellType} from 'common/types/storage'

export type ColSettingsSummary = {
  label: string
  // we use extended Summary type, because we want to know when user
  // select short function and when he really change custom function
  compute_method: SummaryComputeMethod
  custom_fn: string
}

export type FunctionChangeHandler = (
  field: 'style' | 'tooltip' | 'fn' | 'summary',
  value: string | ColSettingsSummary
) => void

type FunctionEditorSwitchProps = {
  col_type: CellType
  computed: boolean
  on_change: FunctionChangeHandler
  style: string
  tooltip: string
  fn: string
  summary: ColSettingsSummary
  edit_flag: EditFlag
}

type SummaryEditorProps = {
  col_type: CellType
  fn_template: string
  summary: ColSettingsSummary
  on_change: FunctionChangeHandler
  read_only: boolean
}

type TabProps = {
  title: string
  disabled: boolean
  children: ReactNode
}

const Tab: FC<TabProps> = ({title, disabled, children}) => {
  return (
    <>
      <h3
        className={classNames(disabled && styles.disabled)}
        title={disabled ? 'Not available for this type of column' : undefined}
      >
        {title}
      </h3>
      {!disabled && children}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(1),
      width: '25ch',
    },
  })
)

const SummaryEditor: FC<SummaryEditorProps> = ({
  col_type,
  fn_template,
  summary,
  on_change,
  read_only,
}) => {
  const classes = useStyles()

  const on_change_summary = useCallback(
    (key, value) => {
      on_change('summary', {...summary, [key]: value})
    },
    [on_change, summary]
  )

  const available_methods = get_available_methods_for_col_type(col_type)

  return (
    <>
      <div>
        <TextField
          id="summary_label"
          className={classes.input}
          label="Label"
          value={summary.label}
          onChange={(e) => on_change_summary('label', e.target.value)}
          disabled={read_only}
        />
      </div>
      <div>
        <TextField
          id="summary_compute_method"
          className={classes.input}
          select
          label="Method"
          value={summary.compute_method}
          onChange={(e) => on_change_summary('compute_method', e.target.value)}
          disabled={read_only}
        >
          {available_methods.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {summary.compute_method === SummaryComputeMethod.CUSTOM && (
        <FunctionEditor
          fn_template={fn_template}
          fn_string={summary.custom_fn}
          on_edit={(new_fn) => on_change_summary('custom_fn', new_fn)}
          preview_type="summary"
          read_only={read_only}
        />
      )}
    </>
  )
}

const FunctionEditorSwitch: FC<FunctionEditorSwitchProps> = ({
  col_type,
  computed,
  on_change,
  style,
  tooltip,
  fn,
  summary,
  edit_flag,
}) => {
  return (
    <>
      <Tab title="Computed column function" disabled={!computed}>
        <FunctionEditor
          fn_template={templates.computed_column}
          fn_string={fn}
          on_edit={(new_fn) => on_change('fn', new_fn)}
          preview_type="column"
          read_only={edit_flag !== 'editable'}
        />
      </Tab>
      <Tab title="Style" disabled={false}>
        <FunctionEditor
          fn_template={templates.style}
          fn_string={style}
          on_edit={(new_fn) => on_change('style', new_fn)}
          preview_type="style"
          read_only={edit_flag === 'readonly'}
        />
      </Tab>
      <Tab title="Tooltip" disabled={false}>
        <FunctionEditor
          fn_template={templates.tooltip}
          fn_string={tooltip}
          on_edit={(new_fn) => on_change('tooltip', new_fn)}
          preview_type="tooltip"
          read_only={edit_flag === 'readonly'}
        />
      </Tab>
      <Tab title="Summary" disabled={false}>
        <SummaryEditor
          col_type={col_type}
          fn_template={templates.summary}
          summary={summary}
          on_change={on_change}
          read_only={edit_flag === 'readonly'}
        />
      </Tab>
    </>
  )
}

export default FunctionEditorSwitch
