import {useState} from 'react'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {BasicSchema, EntityId, EntityType} from 'common/types/storage'
import {open_modal} from '../Modals'
import {useDispatch} from 'react-redux'
import {change_archived_status} from 'common/entities/entity_actions'
import {validate_entity_name} from 'common/project_utils'

export type ToggleArchiveTableHook = [is_toggling: boolean, start_toggling: () => void]

export const useToggleArchived = ({
  on_redirect,
  on_error,
  entity,
}: {
  on_redirect: (data: {type: EntityType; entity_id: EntityId}) => void
  on_error: (error: {message: string; severity?: 'error' | 'success' | 'info' | 'warning'}) => void
  entity: BasicSchema<EntityType, 'entity'>
}): ToggleArchiveTableHook => {
  const [is_toggling, set_is_toggling] = useState<boolean>(false)

  const dispatch = useDispatch()
  const {dispatch_storage, fetch_entity_headers_action} = useRuntimeActions()
  const {
    resources: {table_resources},
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const _toggle = async () => {
    set_is_toggling(true)

    if (entity.archived) {
      const error_message = validate_entity_name(
        entity_headers,
        entity_headers[entity.entity_id].type,
        entity.parent_id,
        entity.name
      )

      if (error_message) {
        // eslint-disable-next-line no-alert
        alert(error_message)
        // on_error({message: error_message, severity: 'error'})
        return
      }
    }

    dispatch_storage(change_archived_status(entity, !entity.archived))

    on_redirect({
      type: 'project',
      entity_id: table_resources?.table._parent_id,
    })

    await fetch_entity_headers_action()
    set_is_toggling(false)
  }

  const start_toggling = () => {
    if (is_toggling) {
      return
    }
    dispatch(
      open_modal('confirm_toggle_archived', {
        on_toggle: _toggle,
        is_archived: !!entity.archived,
        entity_type: entity.type,
      })
    )
  }
  return [is_toggling, start_toggling]
}
