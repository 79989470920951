import React, {FC, ReactNode} from 'react'
import {get_cell_tooltips, TOOLTIP_PROPS, Tooltip} from '../Tooltips/cell_tooltips'
import {cell_change_from_diff, CELL_VIEW_MODE, TableDiff} from '../table_data_helpers'
import {AlwaysDefinedRuntime, useRuntimeSelector, useZoneUiSelector} from '../utils/connect_hocs'
import {ColumnId, RowId} from 'common/types/storage'
import styles from './DetailTooltips.module.css'
import {Runtime} from 'common/create_runtime'
import {TableObject} from 'common/objects/data_table'

type DetailTooltipsProps = {
  col_id: ColumnId
  row_id: RowId
}

type DetailTooltipProps = {
  children: ReactNode
  color: string
  title: string
}

const get_tooltips = (
  runtime: Runtime,
  table: TableObject,
  col_id: ColumnId,
  row_id: RowId,
  diff: TableDiff | undefined,
  with_changes: boolean
): Tooltip[] =>
  get_cell_tooltips({
    runtime,
    col_spec: table._cols[col_id],
    cell: table._cell(row_id, col_id),
    style: table._get_style(row_id, col_id),
    custom_tooltips: table._get_tooltips(row_id, col_id),
    changes: with_changes && diff ? cell_change_from_diff(diff, row_id, col_id) : null,
    mode: CELL_VIEW_MODE.default,
  })

const DetailTooltip: FC<DetailTooltipProps> = ({children, color, title}) => {
  return (
    <div className={styles.tooltip} style={{borderTopColor: color}}>
      <strong className={styles.title} style={{color}}>
        {title}
      </strong>
      <br />
      <span className={styles.content}>{children}</span>
    </div>
  )
}

const DetailTooltips: FC<DetailTooltipsProps> = ({col_id, row_id}) => {
  const {
    runtime,
    resources: {
      table_resources: {detailed_view_table, detailed_view_diff, zone_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const show_changes = useZoneUiSelector(zone_id, 'sidebar') === 'history'

  const tooltips = get_tooltips(
    runtime,
    detailed_view_table,
    col_id,
    row_id,
    detailed_view_diff,
    show_changes
  )

  return (
    <div className={styles.tooltipsContainer}>
      {tooltips.map((tooltip, i) => (
        <DetailTooltip key={i} {...TOOLTIP_PROPS[tooltip.type]}>
          {tooltip.content}
        </DetailTooltip>
      ))}
    </div>
  )
}

export default DetailTooltips
