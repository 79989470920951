import React, {FC, useEffect} from 'react'
import AddRow from './AddRow'
import Search from './Search'
import SortButton from './SortButton'
import HideColumnsButton from './HideColumnsButton'
import {
  useRuntimeSelector,
  useThunkDispatch,
  AlwaysDefinedRuntime,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {close_toolbar_dropdowns} from './toolbar_actions'
import {styled, Box} from '@material-ui/core'
import FilterButton from './FilterButton'
import SetView from './SetView'
import {is_permission_table_subtype} from 'common/permission/permission_utils'
import UndoButton from './UndoButton'
import RedoButton from './RedoButton'
import ShowShortcutsButton from './ShowShortcutsButton'
import SelectionSummary from './SelectionSummary'
import ParamsButton from './ParamsButton'

const FlexContainer = styled('div')(({theme}) => ({
  backgroundColor: `${theme.palette.panelBackground}`,
  padding: theme.spacing(1, 1.5, 1, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const StyledBox = styled(Box)(({theme}) => ({
  '& > *': {
    marginRight: theme.spacing(1),
  },
  '& > *:last-child': {
    marginRight: 0,
  },
}))

const Toolbar: FC = () => {
  const {
    resources: {
      table_resources: {table, table_entity_id},
    },
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const dispatch = useThunkDispatch()

  const cursor = useTableUiSelector(table_entity_id, 'cursor')

  useEffect(() => {
    // Close toolbar dropdowns when leaving table page
    return () => dispatch(close_toolbar_dropdowns(table_entity_id))
  }, [dispatch, table_entity_id])

  return (
    <FlexContainer>
      <Box display="flex" alignItems="center">
        <StyledBox display="flex" alignItems="center">
          <UndoButton />
          <RedoButton />
        </StyledBox>
        <StyledBox display="flex" alignItems="center" mx={4}>
          {!is_permission_table_subtype(entity_headers[table_entity_id].subtype) && <SetView />}
          <Search />
          <SortButton />
          <FilterButton />
          {table?.type === 'view_table' && <HideColumnsButton />}
          {table?.type === 'computed_table' && <ParamsButton />}
        </StyledBox>
        <AddRow />
      </Box>
      <Box display="flex">
        <StyledBox display="flex" mx={1.5}>
          {table != null && cursor != null && <SelectionSummary />}
        </StyledBox>
        <ShowShortcutsButton />
      </Box>
    </FlexContainer>
  )
}

export default Toolbar
