import React, {FC, useState, useContext, useMemo} from 'react'
import {ZoneId} from 'common/types/storage'
import {ActionPayload} from 'common/dispatch'
import {create_runtime_context, RuntimeContext} from 'common/create_runtime_context'
import {get_fetchers} from 'common/fetchers'
import {empty_storage} from 'common/storage'
import {throw_error} from 'common/utils'
import {with_retry} from './utils/http_utils'
import * as local_storage from './local_storage'

// Unique runtime context instance throughout the app
const DEFAULT_FN = () => throw_error('No runtime context provided')
const DEFAULT_SEQUENCE = 0

export const RuntimeContextContext = React.createContext<RuntimeContext | null>(null)
export const RuntimeSequenceContext = React.createContext<number>(DEFAULT_SEQUENCE)

export type RuntimeActions = {
  dispatch_storage: (action: ActionPayload) => void
  fetch_commits_action: (zone_id: string) => Promise<void>
  fetch_entity_headers_action: () => Promise<void>
}

export const RuntimeActionsContext = React.createContext<RuntimeActions>({
  dispatch_storage: DEFAULT_FN,
  fetch_commits_action: DEFAULT_FN,
  fetch_entity_headers_action: DEFAULT_FN,
})

// Shorthand hooks - use these where possible
export const useRuntimeContext = (): RuntimeContext => {
  const runtime_context = useContext(RuntimeContextContext)

  if (runtime_context === null) {
    // this happens only if useRuntimeContext isn't used inside provider
    throw_error('runtime context not initialized')
  }

  return runtime_context
}
export const useRuntimeSequence = () => useContext(RuntimeSequenceContext)
export const useRuntimeActions = () => useContext(RuntimeActionsContext)

// Provider for runtime sequence/actions - call once at the top of the hierarchy
const RuntimeContextProvider: FC = ({children}) => {
  const {fetch_commits, fetch_entity_headers, fetch_resource, fetch_external_data} = get_fetchers()
  const [runtime_context] = useState(() =>
    create_runtime_context(
      {
        ...empty_storage,
        ...local_storage.get_diff_storage(),
        ...local_storage.get_settings_storage(),
      },
      {
        fetch_commits: with_retry(fetch_commits),
        fetch_entity_headers: with_retry(fetch_entity_headers),
        fetch_resource: with_retry(fetch_resource),
        fetch_external_data,
      }
    )
  )
  const [runtime_sequence, set_runtime_sequence] = useState(DEFAULT_SEQUENCE)

  const runtime_actions: RuntimeActions = useMemo(() => {
    const dispatch_storage = (action: ActionPayload) => {
      const old_storage = runtime_context.get_storage_state()
      runtime_context.dispatch([action])

      const new_storage = runtime_context.get_storage_state()
      const references_have_changed =
        old_storage.diffs !== new_storage.diffs || old_storage.diff_cnt !== new_storage.diff_cnt
      if (references_have_changed) local_storage.update(new_storage)
      local_storage.update_settings({include_archived: new_storage.include_archived})

      set_runtime_sequence((sequence) => sequence + 1)
    }

    const fetch_commits_action = async (zone_id: ZoneId) => {
      await runtime_context.fetch_commits_action(zone_id)
      set_runtime_sequence((sequence) => sequence + 1)
    }

    const fetch_entity_headers_action = async () => {
      await runtime_context.fetch_entity_headers_action()
      set_runtime_sequence((sequence) => sequence + 1)
    }

    return {
      dispatch_storage,
      fetch_commits_action,
      fetch_entity_headers_action,
    }
  }, [runtime_context, set_runtime_sequence])

  return (
    <RuntimeContextContext.Provider value={runtime_context}>
      <RuntimeSequenceContext.Provider value={runtime_sequence}>
        <RuntimeActionsContext.Provider value={runtime_actions}>
          {children}
        </RuntimeActionsContext.Provider>
      </RuntimeSequenceContext.Provider>
    </RuntimeContextContext.Provider>
  )
}

export default RuntimeContextProvider
