import React, {FC} from 'react'
import ContextMenu, {ContextMenuProps} from '../../../ContextMenu/ContextMenu'
import AddTableItem from './AddTableItem'

type EditorContextMenuProps = ContextMenuProps & {
  on_click_add_table: () => void
}

const EditorContextMenu: FC<EditorContextMenuProps> = ({pos, on_close, on_click_add_table}) => {
  return (
    <ContextMenu pos={pos} on_close={on_close}>
      <AddTableItem on_close={on_close} on_select={on_click_add_table} />
    </ContextMenu>
  )
}

export default React.memo(EditorContextMenu)
