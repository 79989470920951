/**
 * @namespace TableObject
 * @see {@tutorial table_object}
 * @see {@tutorial table_interaction}
 */
/**
 * Cell object
 * @namespace CellObject
 */
/**
 * Table row object
 * @namespace TableRowObject
 */
import _ from 'lodash'
import {ensure, id, split_on_space_outside_quotes, throw_error} from '../utils'
import data_table_diff from '../diffs/models/data_table_diff'
import val_diff from '../diffs/base/value_diff'
import {create_row} from './table_row'
import {error_handling_decorator} from '../eval_function'
import {is_error, MaybeError} from '../error'
import {
  compose_resource_id,
  compose_table_id,
  ComputedParamsSpec,
  parse_resource_id,
  parse_table_id,
  TableIdParts,
} from '../params_utils'
import {convert_single_value_to_raw_value} from '../types'
import {get_table_actions, TableActions} from '../entities/table_actions'
import {get_schema_diffs} from '../entities/table_diffs'
import {create_advanced_filter} from '../filter/filter'
import {Filter, SubtableSelection} from '../types/filter'

import {
  CellRawValue,
  CellType,
  ColumnId,
  ColumnSpec,
  ColumnType,
  ComputedTable,
  DataTable,
  EntityId,
  LabelData,
  RowId,
  SingleCellRawValue,
  SingleCellRawValueKey,
  SortField,
  SortOrder,
  TableId,
  TableSubtype,
  TableType,
  ViewTable,
  ZoneId,
} from 'common/types/storage'

import {
  CellObject,
  CellResource,
  CellResourceType,
  CellStyle,
  CellTooltip,
  CellValue,
  ColumnResource,
  Conflict,
  TableRowObject,
  SimpleCellObject,
} from 'common/types/data_table'
import {Runtime} from 'common/create_runtime'
import {get_new_cols_order} from 'common/entities/table_utils'
import {table_sentinel} from 'common/objects/table_utils'
import {validate_cell_raw_value, validate_column_data} from 'common/validation'
import {
  has_create_permissions,
  has_write_permissions,
  is_permission_table_subtype,
} from 'common/permission/permission_utils'
import {log_with_color} from '../../client/src/utils/log_utils'
import {is_entity_or_parent_archived} from 'common/archived_utils'

export type EditFlag = 'editable' | 'readonly' | 'overridable'

const value_diff = val_diff()
const TABLE_TYPES: TableType[] = ['data_table', 'computed_table', 'view_table']

const TYPE_TO_DATA_KEY_MAPPING: Record<CellResourceType, keyof TableInlineResources> = {
  column: 'computed_cols',
  style: 'styles',
  tooltip: 'tooltips',
}

type DataTableEntity = DataTable<'entity'>
type DataTableDiff = DataTable<'diff'>

export type TableCols = Record<ColumnId, ColumnSpec<CellType, 'entity'>>

type TableInlineResources = {
  computed_cols?: Record<ColumnId, MaybeError<ColumnResource<'column'>>>
  tooltips?: Record<ColumnId, MaybeError<ColumnResource<'tooltip'>>>
  styles?: Record<ColumnId, MaybeError<ColumnResource<'style'>>>
}

type TableInlineData = TableInlineResources & {
  table_id: TableId
  was_inlined?: boolean
  is_error_table?: boolean
  // true if the cells can be modified (add/remove row, change cells values)
  is_cells_editable: boolean
  // true if the schema of data can be modified (common data for all table types - cols and visuals)
  is_schema_editable: boolean
  original_zone_id: ZoneId
  original_entity_id: EntityId
  original_subtype?: TableSubtype
  view_entity?: ViewTable<'entity'>
  computed_table_entity?: ComputedTable<'entity'>
  fn?: string
  params?: ComputedParamsSpec
  invalids?: Conflict[]
  label?: string
  log_computation?: boolean
}

type ColumnUniqueValueLabelData = Record<NonNullable<SingleCellRawValueKey>, SingleCellRawValue>

// This data are filled after creation of the object on their first request
type TableCacheData = Partial<{
  conflicts: Conflict[]
  contains_error_data: boolean
  unique_values: Record<ColumnId, ColumnUniqueValueLabelData>
}>

export type TableData = TableInlineData &
  {
    [K in keyof DataTableEntity]: K extends 'type' ? TableType : DataTableEntity[K]
  }

type ColSpecObject = ColumnSpec<CellType, 'entity'> & {
  is_computed: boolean
  col_id: ColumnId
}

type MapToColumnFn<T = any> = (row: TableRowObject) => T
type MapToColumn<T = any> = (fn: MapToColumnFn<T>) => Record<RowId, MaybeError<T>>

/**
 * Filter function
 * @function
 * @memberOf TableObject
 * @see {@tutorial computed_tables_user_guide}
 * @param args {{row: TableRowObject, table: TableObject}}
 * @return {boolean}
 */
export type FilterFn = ({row, table}: {row: TableRowObject; table: TableObject}) => boolean
export type FilterUsingColumnValue = [string, MaybeError<SingleCellRawValue>]

export type TableFilterArgument = FilterFn | FilterUsingColumnValue
/**
 * Filter types
 * @memberOf TableObject
 * @see {@tutorial computed_tables_user_guide}
 * @type {'function' | 'column_value'}
 */
export type TableFilterType = 'function' | 'column_value'
export type TableFilterOptions = {
  type: TableFilterType
}

type ComputedColumnFn = (table: TableObject) => MaybeError<ColumnResource<'column'>>

/**
 * Data table API
 * @memberOf TableObject
 * @see {@tutorial computed_tables_user_guide}
 */
export type TableObject = {
  [K in typeof table_sentinel]: true
} & {
  // ATTRIBUTES
  /** Header attributes (Internal, User) */
  name: string
  /** (Header attributes (Internal, User) */
  description: string | undefined
  /** (Header attributes (Internal, User) */
  type: TableType
  /** Header attributes (Internal, User) */
  archived: boolean | undefined | null
  _table_id: TableId
  /** Header attributes (Internal, User) */
  _entity_id: EntityId
  // Source entity attributes (Internal)
  _original_entity_id: EntityId
  _original_zone_id: ZoneId
  // Table schema attributes (Internal)
  _label: string | undefined
  _fn: string | undefined
  _log_computation: boolean | undefined
  _row_ids: RowId[]
  _cols: TableCols
  _frozen_cols: number
  _cols_order: ColumnId[]
  _visible_cols_order: ColumnId[]
  _validated_order_by: SortField[]
  // Data predicate attributes (Internal)
  _is_error_table: boolean
  _was_inlined: boolean
  /** Action predicate attributes (User) */
  _cols_edit_flags: Record<ColumnId, EditFlag>
  /** Other (Internal, User) */
  compute_column: MapToColumn
  /** Other (Internal, User) */
  _zone_id: ZoneId
  /** Other (Internal, User) */
  _parent_id: EntityId
  /** Other (Internal, User) */
  _subtype: TableSubtype | undefined
  /** Other (Internal, User) */
  _actions: TableActions

  // METHODS
  // Data predicate methods (Internal)
  _contains_error_data: () => Boolean
  _has_summary: () => boolean
  _has_row: (row_id: RowId) => boolean
  _has_col: (col_id: ColumnId) => boolean
  // Action predicate methods (Internal-Client)
  _can_edit_schema: () => boolean
  _can_edit_cells: () => boolean
  _can_edit_cell: (col_id: ColumnId) => boolean
  _can_insert_row: () => boolean
  _can_insert_column: () => boolean
  _can_create_view: () => boolean
  // Actions (Internal, User) - All actions returns modified TableObject
  /** (Internal, User) */
  apply: (diff: DataTableDiff) => TableObject
  /** (Internal, User) */
  add_column: (args: {
    name: string
    slug?: string
    description: string
    type: ColumnType<CellType, 'entity'>
    index_to?: number
    fn: ComputedColumnFn
  }) => TableObject
  /** (Internal, User) */
  hide_column: (args: {name: string}) => TableObject
  /** (Internal, User) */
  move_column: (args: {name: string; index_to?: number}) => TableObject
  /** (Internal, User) */
  filter: (argument: TableFilterArgument, options: TableFilterOptions) => TableObject
  /** (Internal, User) */
  _advanced_filter: (filter: Filter<'internal'>) => TableObject
  /** (Internal, User) */
  _search: (text: string) => TableObject
  // Computed resource methods (Internal)
  _get_style: (row_id: RowId, col_id: ColumnId) => MaybeError<CellStyle>
  _get_tooltips: (row_id: RowId, col_id: ColumnId) => MaybeError<CellTooltip>
  _get_summary: (col_id: ColumnId) => MaybeError<CellRawValue>
  _get_label: (col_id?: ColumnId, enable_formatting?: boolean) => MaybeError<LabelData>
  /** Row methods (Internal, User) */
  one: () => TableRowObject
  /** Row methods (Internal, User) */
  rows: (rows_order?: RowId[]) => TableRowObject[]
  /** Row methods (Internal, User) */
  rows_in_order: (new_order_by?: SortField[]) => TableRowObject[]
  /** Row methods (Internal, User) */
  row_count: () => number
  /** Row methods (Internal, User) */
  _get_rows_order: (order_by?: SortField[]) => RowId[]
  /** Row methods (Internal, User) */
  _row: (row_id: RowId) => TableRowObject
  params: ComputedParamsSpec | undefined
  /** Column methods (Internal, User) */
  col_count: () => number
  /** Column methods (Internal, User) */
  _get_unique_values: (col_id: ColumnId) => ColumnUniqueValueLabelData
  // Cell methods (Internal)
  _get: (row_id: RowId, col_name: string) => CellValue
  _cell: (row_id: RowId, col_id: ColumnId) => CellObject
  // Conflict methods (Internal)
  _get_problems: () => Conflict[]
  // Other (Internal)
  _load: () => TableObject
  _to_json: () => TableData
  _inline_resources: () => TableObject
  _do: (fn: Function) => TableObject
  /** Create subtable diff */
  _create_subtable_diff: (selection: SubtableSelection) => DataTableDiff
  _fork: (new_runtime: Runtime) => TableObject
}

function create_row_comparator(order_by: [ColumnId, SortOrder, CellType][]) {
  return (first_row: TableRowObject, second_row: TableRowObject): number => {
    for (const [col_id, order, col_type] of order_by) {
      let result = 0
      const first = first_row._cell(col_id)
      const second = second_row._cell(col_id)

      if (first.error && second.error) {
        result = String(first.get_label()).localeCompare(String(second.get_label()))
      } else if (first.error) {
        return -1
      } else if (second.error) {
        return 1
      } else {
        if (col_type === 'number' || col_type === 'date' || col_type === 'date_time') {
          result = Number(first.get_value()) - Number(second.get_value())
        } else {
          result = String(first.get_label()).localeCompare(
            String(second.get_label()),
            /*language-code=*/ undefined
          )
        }
      }

      if (result !== 0) return result * order
    }
    return 0
  }
}

function compute_label(
  runtime: Runtime,
  table_id: TableId,
  enable_formatting: boolean,
  col_id?: ColumnId
): MaybeError<LabelData> {
  const table = runtime._get_table_or_error(table_id)
  if (is_error(table)) {
    return table
  }
  return table.compute_column((row) => row.get_label(col_id, enable_formatting))
}

function conflicts_to_problem_list(data: Pick<TableData, 'label' | 'cols' | 'cells'>): Conflict[] {
  const {label, cols, cells} = data

  const table_value_props_problem_list: Conflict[] = Object.entries({label})
    .filter(([, value]) => value_diff.is_conflict(value))
    .map(([key, value]) => ({
      type: 'conflict',
      subtype: `table-${key}`,
      data: {[key]: value_diff.get_conflicts(value)},
    }))

  const c_cols = _.get(data_table_diff.get_conflicts({cols}), 'cols', {})
  const columns_problem_list: Conflict[] = Object.entries(c_cols).map(([col_id, col]) => ({
    type: 'conflict',
    subtype: 'column-spec',
    data: {cols: {[col_id]: col}},
  }))

  const cc_cells = _.get(data_table_diff.get_conflicts({cells}), 'cells', {})
  const cells_problem_list: Conflict[] = Object.entries<object>(cc_cells)
    .map(([row_id, row]) =>
      Object.entries(row).map(([col_id, cell]) => ({
        type: 'conflict',
        subtype: 'cell-value',
        data: {cells: {[row_id]: {[col_id]: cell}}},
      }))
    )
    .flat()

  return table_value_props_problem_list.concat(columns_problem_list, cells_problem_list)
}

// data can contains inline computed attributes which are not part of the entity
// they are unknown to the data_table_diff
const filter_recipe_data = (data: TableData): DataTableEntity => ({
  ...(_.pick(
    data,
    Object.keys(data_table_diff.recipe).filter((key) => key !== 'type')
  ) as DataTableEntity),
  type: 'data_table',
})

const filter_inline_data = (data: TableData): TableInlineData & {type: TableType} => ({
  ...(_.omit(data, _.keys(data_table_diff.recipe)) as TableInlineData),
  type: data.type,
})

/**
 * Create table object from table data. Based on data.type it can be data, computed or view table.
 * Most of the table's methods and properties don't depend on the table's type (.rows, .filter...),
 * but some of them may (._actions, ._can_edit_cells...). Such methods are smart and handle
 * different logic based on data.type itself.
 *
 * All these table's methods and properties are also known under the term Table API.
 *
 * @param runtime {Runtime}
 * @param data {TableData} all data needed for creating table object - it might be different data
 * for different data.type, but some are common (like table_id, cols, cells ...)
 *
 * @param cache_data {TableCacheData} cache for all data we don't want to compute multiple times
 * during lifetime of the table object (like conflics, unique column values...). They can be
 * undefined until the moment we need them, then we compute them and next time we want them from the
 * table object, we obtain a cached value. Since data in this cache can depend on other resources,
 * it needs to be cleared on storage recalculation.
 * @memberOf TableObject
 * @return {TableObject}
 */
function create_table(runtime: Runtime, data: TableData, cache_data: TableCacheData = {}) {
  ensure(data !== undefined, 'create_data_table is missing its second argument (data).')

  // prefix c_ means the variable stores the original with-conflicts version of data
  const {
    type,
    subtype,
    table_id,
    label: c_label,
    fn: _fn,
    log_computation: _log_computation,
    params,
    cols: c_cols,
    frozen_cols: _frozen_cols,
    cells,
    computed_cols = {},
    was_inlined = false,
    invalids = [],
    original_entity_id,
    original_zone_id,
    original_subtype,
    is_error_table: _is_error_table = false,
    view_entity,
    is_cells_editable,
    is_schema_editable,
  } = data

  const {
    name,
    description,
    archived,
    label: _label,
    cols,
    cols_order: _cols_order,
    order_by: _order_by,
    entity_id,
    zone_id,
    parent_id,
  }: DataTable<'entity'> = data_table_diff.conflict_free(
    _.omit(filter_recipe_data(data), ['cells'])
  )
  const _row_ids: RowId[] = _.keys(cells.data)
  ensure(TABLE_TYPES.includes(type), 'Table got input data of a wrong type', {type})
  const is_permission_table = is_permission_table_subtype(subtype)

  const _visible_cols_order = _.filter(_cols_order, (col_id) => !cols[col_id].hidden)
  const _validated_order_by = _order_by
    ? _order_by.filter(([col_id]) => _cols_order.includes(col_id))
    : []

  let table: TableObject //eslint-disable-line prefer-const

  const is_archived =
    typeof runtime.get_headers === 'function' &&
    is_entity_or_parent_archived(entity_id, runtime.get_headers())
  const can_write_schema = has_write_permissions(runtime, entity_id) && !is_archived
  const can_write_data = has_write_permissions(runtime, original_entity_id) && !is_archived
  const base_table_entity_id = type === 'view_table' ? parent_id : entity_id
  const can_create = has_create_permissions(runtime, base_table_entity_id) && !is_archived

  /** Returns true if schema of common data can be edited */
  function _can_edit_schema(): boolean {
    return can_write_schema && is_schema_editable && (!subtype || subtype !== 'permission_entities')
  }

  function _can_create_view(): boolean {
    return can_create
  }

  const _cols_edit_flags = _.mapValues(
    cols,
    (col_spec, col_id): EditFlag => {
      if (!_can_edit_schema()) {
        return 'readonly'
      }
      if (is_permission_table) {
        return 'overridable'
      }
      if (type === 'view_table' && !_.has(view_entity!.cols, col_id)) {
        return 'overridable'
      }
      return col_spec.is_default ? 'overridable' : 'editable'
    }
  )

  function throw_missing_col_name_error(column_name: string): never {
    throw_error('Table does not have column with given name', {
      type: 'unknown-column',
      name,
      column_name,
    })
  }

  /** Checks if table has row with given row_id */
  function _has_row(row_id: RowId): boolean {
    return cells.data[row_id] != null
  }

  /** Checks if table has column with given col_id */
  function _has_col(col_id: ColumnId): boolean {
    return cols.hasOwnProperty(col_id)
  }

  /**
   * Creates and returns a TableRowObject based on given the row_id
   * @memberOf TableObject
   * @param row_id {RowId}
   * @return {TableRowObject}
   */
  function _row(row_id: RowId): TableRowObject {
    const row_factory = create_row({
      runtime,
      table_id,
      table,
      // from with-conflicts version of data
      cells,
      computed_cols,
      // from conflict-free version of data
      name,
      label: _label,
      cols,
      cols_order: _visible_cols_order,
      entity_id,
    })

    return row_factory(row_id)
  }

  function row_count(): number {
    return _row_ids.length
  }

  function col_count(): number {
    return _visible_cols_order.length
  }

  /** Computed columns, styles, tooltips are being looked up on two places: in
  the storage and within the table's data (the latter has bigger precedence). This
  function does the lookup, _inline_resources then copies the resources from storage
  to the table.

  The reason for this duality is that we want to support both:
  - asynchronous computation of the resources that can depend on not just
    current table (storage is good for that)
  - ad-hoc modifying the e.g. computed columns when filtering (inlined data
    model is good for this)
   @memberOf TableObject
   @param resource_type {T}
   @param col_id {ColumnId}
   @return {MaybeError<ColumnResource<T>>}
  */
  function get_custom_col_resource<T extends CellResourceType>(
    resource_type: T,
    col_id: ColumnId
  ): MaybeError<ColumnResource<T>> {
    const data_key = TYPE_TO_DATA_KEY_MAPPING[resource_type]
    if (_.has(data, [data_key, col_id])) {
      if (data[data_key]) {
        return data[data_key]![col_id] as MaybeError<ColumnResource<'tooltip'>>
      }
      return throw_error('invariant violation')
    } else {
      return runtime._get_resource<ColumnResource<T>>(
        compose_resource_id({type: resource_type, column_id: col_id, table_id})
      )
    }
  }

  // see the comment on get_custom_col_resource
  function _inline_resources(): TableObject {
    if (table._was_inlined) {
      return table
    }

    const compute_cols_resources = <T extends CellResourceType>(type: T) =>
      Object.fromEntries(
        Object.entries(cols)
          .filter(([, col_spec]) => (type === 'column' ? col_spec.computed : _.has(col_spec, type)))
          .map(([col_id]) => [col_id, get_custom_col_resource(type, col_id)])
      )

    const computed_cols = compute_cols_resources('column')
    const styles = compute_cols_resources('style')
    const tooltips = compute_cols_resources('tooltip')

    return create_table(runtime, {
      ...data,
      computed_cols,
      styles,
      tooltips,
      was_inlined: true,
    })
  }

  /**
   * Transform table function `fn` with a given name to a function that is called on inlined table
   * @memberOf TableObject
   * @param fn {TableObject[]}
   * @param fn_name {P}
   * @return {TableObject[]}
   */
  function on_inlined<P extends keyof TableObject>(fn: TableObject[P], fn_name: P) {
    if (was_inlined) {
      return fn
    } else {
      return _inline_resources()[fn_name]
    }
  }

  function to_json(): TableData {
    return data
  }

  function _get_problems(): Conflict[] {
    if (type !== 'data_table') {
      return []
    }
    if (cache_data.conflicts == null) {
      cache_data.conflicts = conflicts_to_problem_list({
        label: c_label,
        cols: c_cols,
        cells,
      })
    }
    return invalids.concat(cache_data.conflicts)
  }

  /**
   * Create and returns list of TableRowObject based on given rows_order
   * (default is insertion order)
   * @memberOf TableObject
   * @param rows_order=_row_ids {RowId}
   * @return {TableRowObject[]}
   */
  function rows(rows_order: RowId[] = _row_ids): TableRowObject[] {
    const rows: TableRowObject[] = []
    for (const row_id of rows_order) {
      rows.push(_row(row_id))
    }
    return rows
  }

  /**
   * Return valid value-label pairs of a column
   * @memberOf TableObject
   * @param col_id {ColumnId}
   * @return {ColumnUniqueValueLabelData}
   */
  function _get_unique_values(col_id: ColumnId): ColumnUniqueValueLabelData {
    if (cache_data.unique_values == null) {
      cache_data.unique_values = {}
    }
    if (cache_data.unique_values[col_id] == null) {
      // raw_values are saved to object to prevent duplicates
      const unique_values = {} as ColumnUniqueValueLabelData
      rows().forEach((row) => {
        row._cell(col_id).forEach((subcell) => {
          if (!subcell.error && subcell.raw_value != null && !is_error(subcell.raw_value)) {
            if (typeof subcell.raw_value === 'boolean') {
              unique_values[String(subcell.raw_value).valueOf()] = subcell.get_label()
            } else {
              unique_values[subcell.raw_value] = subcell.get_label()
            }
          }
        })
      })
      cache_data.unique_values[col_id] = unique_values
    }
    return cache_data.unique_values[col_id]
  }

  /**
   * Check if cells contains any error
   * @memberOf TableObject
   * @return {boolean}
   */
  function _contains_error_data(): boolean {
    if (cache_data.contains_error_data == null) {
      cache_data.contains_error_data = rows().some((row) =>
        _visible_cols_order.some((col_id) => row._cell(col_id).error)
      )
    }
    return cache_data.contains_error_data
  }

  function compute_column(fn: MapToColumnFn) {
    const {
      storage: {resources},
    } = runtime
    const src_table_id = compose_resource_id({
      type: 'table',
      table_id: compose_table_id({entity_id: table._entity_id}),
    })
    const requiring_entities = _.keys(resources[src_table_id]?.required_by)
      .filter(
        (res_key) =>
          resources[res_key].status === 'in-progress' &&
          parse_resource_id(res_key).type === 'computed_table_data'
      )
      .map(
        (res_key) =>
          resources[
            compose_resource_id({
              type: 'entity',
              entity_id: parse_resource_id(res_key).entity_id,
            })
          ]
      )
    const log =
      _log_computation ||
      _.some(
        requiring_entities,
        ({result}) => result?.log_computation?.value || result?.log_computation
      )
    const decorated_fn = error_handling_decorator(fn)
    if (log) {
      log_with_color('Column computation', 'red')
    }
    return _.fromPairs(
      rows().map((row, index) => {
        const col_fn_result = decorated_fn(row)
        if (log) {
          const compute_log = {
            column_fn: fn,
            row_index: index,
            row_id: row.row_id,
            result: col_fn_result,
          }
          console.log(compute_log)
        }
        return [row.row_id, col_fn_result]
      })
    )
  }

  /**
   * Return new TableObject with applied diff
   * @memberOf TableObject
   * @param diff {DataTableDiff}
   * @return {TableObject}
   */
  function apply(diff: DataTableDiff) {
    const recipe_data = filter_recipe_data(data)
    const inline_data = filter_inline_data(data)

    const new_data = {
      ...data_table_diff.apply(recipe_data, diff),
      ...inline_data,
    }
    return create_table(runtime, new_data)
  }

  function _filter(fn: FilterFn, filtered_table_id?: TableId): TableObject {
    ensure(_.isFunction(fn), 'Invalid filter argument, expected function.', {fn})

    const new_rows_order = table
      .rows()
      .filter((row) => fn({row, table}))
      .map((row) => row.row_id)

    const narrow_to_selected_rows = (rowset) => {
      return is_error(rowset) ? rowset : _.pick(rowset, new_rows_order)
    }

    return create_table(runtime, {
      ...data,
      ...(filtered_table_id != null ? {table_id: filtered_table_id} : {}),
      cells: {
        slots: cells.slots,
        data: narrow_to_selected_rows(cells.data),
      },
    })
  }

  /**
   * Return ColSpecObject of column specified by the column name
   * @memberOf TableObject
   * @param col_name {string}
   * @return {ColSpecObject | undefined}
   */
  function col_spec(col_name: string): ColSpecObject | undefined {
    const col_id = _.findKey(cols, {name: col_name}) as string
    const res = cols[col_id]
    if (res != null) {
      return {...res, is_computed: res.fn != null, col_id}
    } else {
      return undefined
    }
  }

  function col_name_to_id(name: string): ColumnId | undefined {
    const spec = col_spec(name)
    return spec?.col_id
  }

  function col_value_filter_fn(col_name: string, value: MaybeError<SingleCellRawValue>): FilterFn {
    ensure(_.isString(col_name), 'Invalid filter arguments, expected col_name and value, got', {
      col_name,
      value,
    })
    const col_id = col_name_to_id(col_name)
    if (col_id == null) {
      throw_missing_col_name_error(col_name)
    }
    const col_type = cols[col_id].type

    const compare = (
      cell: SimpleCellObject,
      value: MaybeError<SingleCellRawValue>,
      col_type: ColumnType<CellType, 'entity'>
    ) => {
      if (is_error(cell.error) && is_error(value)) {
        //compare value only for data cells error (value, ref), computed errors doesn't have value
        return ['ref', 'value'].includes(cell.error.type)
          ? cell.error.type === value.type && _.isEqual(cell.error.value, value.value)
          : cell.error.type === value.type
      } else if (!is_error(cell.error) && !is_error(value)) {
        try {
          // case types are incompatible
          if (value === undefined) {
            return true
          } else {
            const raw_value = convert_single_value_to_raw_value(value, col_type)
            return _.isEqual(cell.raw_value, raw_value)
          }
        } catch (e) {
          return false
        }
      }
      // One is error other is not
      return false
    }

    return ({row}) => {
      const cell = row._cell(col_id)

      // note: even single cell is implemented as an array of simple_cells
      if (Array.isArray(cell)) {
        return cell.some((sub_cell) => compare(sub_cell, value, col_type))
      } else {
        return throw_error('invariant violation')
      }
    }
  }

  /**
   * Return new TableObject with applied filters
   * @memberOf TableObject
   * @param argument {TableFilterArgument}
   * @param options='function' {TableFilterOptions}
   */
  function filter(
    argument: TableFilterArgument,
    options: TableFilterOptions = {type: 'function'}
  ): TableObject {
    switch (options.type) {
      case 'function':
        return _filter(argument as FilterFn)
      case 'column_value':
        return _filter(col_value_filter_fn(...(argument as FilterUsingColumnValue)))
      default:
        return throw_error('Bad filter type')
    }
  }

  const diffs = get_schema_diffs({...data, type: 'data_table'}, invalids)

  /**
   * Apply diffs on data without creating a new table
   * @memberOf TableObject
   * @param diffs {DataTableDiff[]}
   * @return {TableData}
   */
  function _data_apply(...diffs: DataTableDiff[]): TableData {
    return {
      ...data_table_diff.apply(filter_recipe_data(data), data_table_diff.squash(...diffs)),
      ...filter_inline_data(data),
    }
  }

  /**
   * Change order of a given column (by name) to a given index and returns a new TableObject
   * @memberOf TableObject
   * @param column_name {string}
   * @param index_to=-1 {number}
   * @return {TableObject}
   */
  function move_column({name: column_name, index_to = -1}: {name: string; index_to?: number}) {
    const col_id = col_name_to_id(column_name)
    if (col_id == null) {
      throw_missing_col_name_error(column_name)
    }

    // we need to change column position in original cols_order, which includes hidden cols
    const new_cols_order = get_new_cols_order(
      _cols_order,
      _visible_cols_order,
      col_id,
      false,
      index_to
    )

    return create_table(
      runtime,
      _data_apply(diffs.diff_for_cols_order(new_cols_order) as DataTable<'diff'>)
    )
  }

  /**
   * Hide column and returns new TableObject
   * @memberOf TableObject
   * @param column_name {string}
   * @return {TableObject}
   */
  function hide_column({name: column_name}: {name: string}) {
    const col_id = col_name_to_id(column_name)
    if (col_id == null) {
      throw_missing_col_name_error(column_name)
    }
    return create_table(
      runtime,
      _data_apply(diffs.diff_for_col_hidden(col_id, true) as DataTable<'diff'>)
    )
  }

  /**
   * Add column described by fn with contract (table) => ({[row_id]: value})
   * and returns a new TableObject
   * @memberOf TableObject
   * @param column_name {string}
   * @param slug {string | undefined}
   * @param type {ColumnType<CellType, 'entity'>}
   * @param [index_to] {number}
   * @param fn {ComputedColumnFn}
   * @return {TableObject}
   */
  function add_column({
    name: column_name,
    description,
    slug,
    type,
    index_to = col_count(),
    fn,
  }: {
    name: string
    description: string
    slug?: string
    type: ColumnType<CellType, 'entity'>
    index_to?: number
    fn: ComputedColumnFn
  }) {
    ensure(col_name_to_id(column_name) == null, 'Table already has column with given name.', {
      table: name,
      column_name,
    })
    const col_id = id(column_name)
    const col_spec = {name: column_name, slug, description, type, computed: true, fn: fn.toString()}
    const decorated_fn: ComputedColumnFn = error_handling_decorator(fn)
    const column = decorated_fn(table)
    // return object with value exactly for each row_id, error otherwise
    const computed_col = validate_column_data(column, table, validate_cell_raw_value, type.multi)
    const new_computed_cols = {...computed_cols, [col_id]: computed_col}
    // we need to add column to original cols_order, which includes hidden cols
    const new_cols_order = get_new_cols_order(
      _cols_order,
      _visible_cols_order,
      col_id,
      true,
      index_to
    )
    return create_table(runtime, {
      ..._data_apply(
        data_table_diff.squash(
          diffs.diff_for_col_spec(col_id, col_spec),
          diffs.diff_for_cols_order(new_cols_order)
        )
      ),
      computed_cols: new_computed_cols,
    })
  }

  /**
   * Returns one row or throws
   * @memberOf TableObject
   * @param rows_order {RowId[]}
   * @return {TableRowObject}
   */
  function one(rows_order: RowId[] = _row_ids): TableRowObject {
    ensure(rows_order.length > 0, 'Row does not exist', {code: 'expected_one_row'})
    ensure(rows_order.length < 2, 'Multiple rows', {code: 'expected_one_row'})
    return _row(rows_order[0])
  }

  /**
   * Returns cell value based on given row_id and col_name
   * @memberOf TableObject
   * @param row_id {RowId}
   * @param col_name {string}
   * @return {CellValue}
   */
  function _get(row_id: RowId, col_name: string): CellValue {
    return _row(row_id).get(col_name)
  }

  /**
   * Returns cell object based on given row_id and col_id
   * @memberOf TableObject
   * @param row_id {RowId}
   * @param col_id {ColumnId}
   * @return {CellObject}
   */
  function _cell(row_id: RowId, col_id: ColumnId): CellObject {
    return _row(row_id)._cell(col_id)
  }

  function get_custom_cell_resource<T extends CellResourceType>(
    resource_type: T,
    row_id: RowId,
    col_id: ColumnId,
    default_value: CellResource<T>
  ): MaybeError<CellResource<T>> {
    if (!cols[col_id][resource_type as CellResourceType]) return default_value
    const custom_resource = get_custom_col_resource<T>(resource_type, col_id)
    if (is_error(custom_resource)) return custom_resource
    return custom_resource[row_id] as MaybeError<CellResource<T>>
  }

  /**
   * Returns style of a cell at given row (row_id) and column (col_id)
   * @memberOf TableObject
   * @param row_id {RowId}
   * @param col_id {ColumnId}
   * @return {MaybeError<CellStyle>}
   */
  function _get_style(row_id: RowId, col_id: ColumnId): MaybeError<CellStyle> {
    return get_custom_cell_resource('style', row_id, col_id, {})
  }

  /**
   * Returns tooltips of a cell at given row (row_id) and column (col_id)
   * @memberOf TableObject
   * @param row_id {RowId}
   * @param col_id {ColumnId}
   * @return {MaybeError<CellTooltip>}
   */
  function _get_tooltips(row_id: RowId, col_id: ColumnId): MaybeError<CellTooltip> {
    return get_custom_cell_resource('tooltip', row_id, col_id, [])
  }

  // We don't need to recalculate the label resource each time we change the filter.
  // We can refer to label of full table
  const full_table_id = compose_table_id(_.pick(parse_table_id(table_id), ['entity_id', 'params']))

  /**
   * Returns a mapping from row_id to row_label for each row in the table
   * @memberOf TableObject
   * @return {MaybeError<LabelData>}
   */
  function _get_label(col_id?: ColumnId, enable_formatting: boolean = true): MaybeError<LabelData> {
    const resource_id = compose_resource_id({
      type: 'label',
      table_id: full_table_id,
      column_id: col_id,
    })
    return runtime._get_resource(resource_id, (runtime) =>
      compute_label(runtime, full_table_id, enable_formatting, col_id)
    )
  }

  /**
   * Returns summary of a column at given column_id
   * @memberOf TableObject
   * @param col_id {ColumnId}
   * @return {MaybeError<CellRawValue>}
   */
  function _get_summary(col_id: ColumnId): MaybeError<CellRawValue> {
    if (_.get(cols[col_id], ['summary', 'fn'], null) === null) {
      return null
    }

    const summary_resource = runtime._get_resource(compose_resource_id({type: 'summary', table_id}))
    ensure(_.has(summary_resource, col_id), 'missing col_id in summary resource', {
      table_id,
      col_id,
    })
    return summary_resource[col_id]
  }

  /**
   * Checks if there is any column with a summary
   * @memberOf TableObject
   * @return {boolean}
   */
  function _has_summary(): boolean {
    return _.some(cols, (col_spec) => _.get(col_spec, ['summary', 'fn'], null) !== null)
  }

  /**
   * Ensures that the table is fully loaded:
   * - Touches all the labels for all referenced tables
   * - Returns untouched table object, ensures computed resources
   * (styles, tooltips and computed columns) are computed
   * @memberOf TableObject
   * @return {TableObject}
   */
  function _load(): TableObject {
    for (const col_spec of _.values(cols)) {
      if (col_spec.type.type === 'reference') {
        for (const table_reference of Object.values(col_spec.type.tables)) {
          const referenced_table = runtime.get_table_or_error(table_reference.table_id)
          if (!is_error(referenced_table)) {
            referenced_table._get_label(table_reference.column_id ?? undefined)
          }
        }
      }
    }
    _inline_resources()
    if (_has_summary()) {
      runtime._get_resource(compose_resource_id({type: 'summary', table_id}))
    }
    return table
  }

  function search(text: string): TableObject {
    const table_id_parts: TableIdParts = parse_table_id(table_id)
    const filtered_table_id = compose_table_id({...table_id_parts, search: text})

    const text_items = split_on_space_outside_quotes(text).map((str) => str.toLowerCase())
    const full_text_search_filter = (text: string): FilterFn => ({row, table}) => {
      return Object.keys(table._cols).some((col_id) => {
        // textual representation
        const label = String(row._cell(col_id).get_label()).toLowerCase()
        return text_items.every((str) => label.includes(str))
      })
    }
    return _filter(full_text_search_filter(text), filtered_table_id)
  }

  function advanced_filter(filter: Filter<'internal'>): TableObject {
    const table_id_parts: TableIdParts = parse_table_id(table_id)
    const filtered_table_id = compose_table_id({...table_id_parts, filter})
    return _filter(create_advanced_filter(filter, table), filtered_table_id)
  }

  function _create_subtable_diff({allowed_rows, allowed_cols}: SubtableSelection): DataTableDiff {
    const {diff: row_diff} = table._actions.remove_rows(_.difference(table._row_ids, allowed_rows))
    const {diff: col_diff} = table
      .apply(row_diff)
      ._actions.remove_columns(_.difference(Object.keys(table._cols), allowed_cols))
    return data_table_diff.squash(row_diff, col_diff)
  }

  /**
   * Returns ordered list of RowId according to given order_by
   * (default is _validated_order_by)
   * @memberOf TableObject
   * @param new_order_by=_validated_order_by {SortField[]}
   * @return {RowId[]}
   */
  function get_rows_order(new_order_by: SortField[] = _validated_order_by): RowId[] {
    // Filter out missing columns from order_by
    const valid_order_by = new_order_by.filter(([col_id]) => _.has(cols, col_id))
    const valid_order_by_with_type = valid_order_by.map(
      ([col_id, dir]) => [col_id, dir, cols[col_id].type.type] as [ColumnId, SortOrder, CellType]
    )
    return _.isEmpty(valid_order_by_with_type)
      ? _.keys(cells.data)
      : rows()
          .sort(create_row_comparator(valid_order_by_with_type))
          .map((row) => row.row_id)
  }

  /**
   * Returns an ordered list of TableRowObject according to given new_order_by
   * (default is table's _validated_order_by
   * @memberOf TableObject
   * @param [new_order_by] {SortField[]}
   * @return {TableRowObject[]}
   */
  function rows_in_order(new_order_by?: SortField[]): TableRowObject[] {
    return table.rows(table._get_rows_order(new_order_by))
  }

  /* Following helpers are used in the UI to check, is user can modify parts of the table. */

  /**
   * Returns true if cells can be edited
   * (edit cells in the specified column can still be disabled)
   * @memberOf TableObject
   * @return {boolean}
   */
  function _can_edit_cells(): boolean {
    return (
      can_write_data &&
      is_cells_editable &&
      (!original_subtype || original_subtype !== 'permission_entities')
    )
  }

  /**
   * Returns true if cell values in the specified column can be edited
   * @memberOf TableObject
   * @param col_id {ColumnId}
   * @return {boolean}
   */
  function _can_edit_cell(col_id: ColumnId): boolean {
    return _can_edit_cells() && !cols[col_id]?.computed && !cols[col_id]?.is_default
  }

  /**
   * Returns true if it's possible to add new rows to the table
   * @memberOf TableObject
   * @return {boolean}
   */
  function _can_insert_row(): boolean {
    return _can_edit_cells() && col_count() > 0
  }

  /**
   * Returns true if it's possible to add new columns to the table
   * @memberOf TableObject
   * @return {boolean}
   */
  function _can_insert_column(): boolean {
    return _can_edit_schema() && !is_permission_table
  }

  const actions = get_table_actions(data, invalids, cols, _visible_cols_order, runtime)

  const frozen_cols = Math.min(_frozen_cols, col_count())

  /**
   * Creates new TableObject with given Runtime
   * @memberOf TableObject
   * @param new_runtime {Runtime}
   * @return {TableObject}
   */
  function _fork(new_runtime: Runtime): TableObject {
    return create_table(new_runtime, data, cache_data)
  }

  // Table API
  table = {
    [table_sentinel]: true,
    _was_inlined: was_inlined,
    _table_id: table_id,
    // id that should be using for creating diffs. If for example dealing with computed table, the
    // diff should be applied on the parent table (if such term makes sense)
    // that was a data source for this.
    _entity_id: entity_id,
    _original_entity_id: original_entity_id,
    _original_zone_id: original_zone_id,
    // zone_id used for creating diffs - it should always match entity_id
    _zone_id: zone_id,
    _parent_id: parent_id,
    name,
    description,
    archived,
    type,
    _subtype: subtype,
    rows, // array of row objects
    compute_column,
    apply: (...args) => on_inlined(apply, 'apply')(...args),
    filter: (argument, options) => on_inlined(filter, 'filter')(argument, options),
    move_column: (...args) => on_inlined(move_column, 'move_column')(...args),
    hide_column: (...args) => on_inlined(hide_column, 'hide_column')(...args),
    add_column: (...args) => on_inlined(add_column, 'add_column')(...args),
    _get_rows_order: get_rows_order,
    rows_in_order,
    one, // get one row or throw
    _to_json: () => on_inlined(to_json, '_to_json')(),
    _cols: cols,
    _frozen_cols: frozen_cols,
    _label,
    _fn,
    params,
    _log_computation,
    _visible_cols_order,
    _cols_order,
    _row_ids, // ids of all rows, in a random/unsorted order
    _validated_order_by,
    _get_style,
    _get_tooltips,
    _get_label,
    _get_summary,
    _has_summary,
    _row, // row by id
    _has_row,
    _has_col,
    _get, // value by row_id and col_name
    _cell, // cell by row_id and col_id
    row_count,
    col_count,
    _do: (fn) => {
      fn()
      return table
    }, // useful for debugging
    _inline_resources,
    _load,
    _get_problems,
    _get_unique_values,
    _contains_error_data,
    _search: (...args) => on_inlined(search, '_search')(...args),
    _advanced_filter: (...args) => on_inlined(advanced_filter, '_advanced_filter')(...args),
    _actions: actions,
    _create_subtable_diff,
    _fork,
    _is_error_table,
    _can_edit_schema,
    _can_edit_cells,
    _can_edit_cell,
    _can_insert_row,
    _can_insert_column,
    _cols_edit_flags,
    _can_create_view,
  }

  return table
}

export {create_table, TABLE_TYPES}
