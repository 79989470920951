import ih from 'immutability-helper'
import _ from 'lodash'
import {useMemo} from 'react'
import {ThunkDispatch, ThunkAction} from 'redux-thunk'
import {useDispatch, useSelector} from 'react-redux'
import {empty_table_ui_state, empty_zone_ui_state} from '../index'
import {PreviewId, PreviewData} from '../ColSettings/FunctionEditor/FunctionPreview'
import {ModalState} from '../Modals/Modals'
import {TablePageResources} from '../Pages/TablePage'
import {ProjectPageResources, OrganisationPageResources} from '../Pages/BrowserPage'
import {ExplorerState} from '../Pages/Explorer'
import {ErrorObject} from 'common/error'
import {Runtime} from 'common/create_runtime'
import {Storage, RowId, ColumnId, TableEntityId, SortField} from 'common/types/storage'
import {DetailedViewHistory} from '../DetailedView/history_actions'
import {with_ui_validation} from './ui_validation'
import {Filter, UiFilterCondition, FilterOperator} from 'common/types/filter'
import {FindResult} from '../TableFind/AsyncFind'
import {ComputedParams} from 'common/params_utils'

export type CellCoordinates = {
  row_id: RowId
  col_id: ColumnId
}

// row_id === null for column header
// col_id === null for row number column
export type CursorCoordinates = {
  row_id: RowId | null
  col_id: ColumnId | null
}

export type TableUiState = {
  // Validated cursor and scrolling locations
  cursor: CursorCoordinates | null
  scroll_to: CursorCoordinates | null
  selection_size: [number, number]

  // Last-know cursor and scrolling locations
  // May not be valid anymore and shouldn't be used in UI
  last_cursor: CursorCoordinates | null
  last_scroll_to: CursorCoordinates | null

  // Validated row/column orderings
  rows_order: RowId[]
  cols_order: ColumnId[]
  // For the full (= unfiltered) table
  full_rows_order: RowId[]

  // Cached row ordering for memoization/optimization
  // May not be valid anymore and shouldn't be used in UI
  cache_rows_order: RowId[] | null
  cache_order_by: SortField[] | null

  // Currently displayed view for base table
  last_view_entity_id: TableEntityId | null

  // Other fields don't need validation
  editing: CellCoordinates | null
  view_editing: CellCoordinates | null
  dragged_column_id: ColumnId | null
  dragged_column_pos: number | null
  search_text: string | null
  detailed_view: DetailedViewHistory
  previews: Record<PreviewId, PreviewData>
  filter: Filter<'internal'> | null
  params: ComputedParams | null
  dropdown: 'filter' | 'sort' | 'params' | null
  filter_dropdown: {
    conditions: UiFilterCondition[]
    operator: FilterOperator
  }
  find_result: FindResult | null
  find_position: number | null
}

export type ZoneUiState = {
  sidebar: 'conflicts' | 'history' | null
}

export type RuntimeResources = {
  table_resources?: TablePageResources
  project_resources?: ProjectPageResources
  organisation_resources?: OrganisationPageResources
}

type AlwaysDefinedResources<T extends keyof RuntimeResources> = Omit<RuntimeResources, T> &
  {
    [P in T]: Required<NonNullable<RuntimeResources[P]>>
  }

export type AlwaysDefinedRuntime<T extends keyof RuntimeResources = keyof RuntimeResources> = {
  // assume that these fields are never null
  runtime: Runtime
  storage: Storage
  resources: AlwaysDefinedResources<T>
  status: 'done' | 'in-progress'
  error: ErrorObject | null
}

export type RuntimeState = {
  runtime: Runtime | null
  storage: Storage | null
  resources: RuntimeResources
  status: 'done' | 'in-progress' | 'error'
  error: Error | ErrorObject | null
}

export type ReduxState = {
  table_ui: Record<string, TableUiState>
  zone_ui: Record<string, ZoneUiState>
  modal: ModalState
  runtime: RuntimeState
  explorer: ExplorerState
}

export type ReduxAction<T = any> = {
  type: string
  payload: T
  reducer: (state: ReduxState, payload: T) => ReduxState
}

export type ThunkExtraArgs = {
  now: () => number
}

export type ReduxThunk<T = void> = ThunkAction<T, ReduxState, ThunkExtraArgs, ReduxAction>
export type ReduxDispatch = ThunkDispatch<ReduxState, ThunkExtraArgs, ReduxAction>
export const useThunkDispatch = useDispatch as () => ReduxDispatch

const merge_state = <T extends object>(old: T, payload: Partial<T>): T =>
  _.pickBy(_.assign({}, old, payload), (v) => v !== undefined) as T

export type SetTableUiState = (payload: Partial<TableUiState>, type?: string) => void

export const get_table_ui_reducer = (table_entity_id: string, payload: Partial<TableUiState>) => (
  state: ReduxState
): ReduxState =>
  ih(state, {
    table_ui: {
      [table_entity_id]: {
        $apply: (old: TableUiState = empty_table_ui_state) => merge_state(old, payload),
      },
    },
  })

export const get_table_ui_dispatch = (
  dispatch: ReduxDispatch,
  table_entity_id: TableEntityId
): SetTableUiState => {
  return (payload: Partial<TableUiState>, type: string = 'set_table_ui_state') => {
    const reducer = get_table_ui_reducer(table_entity_id, payload)
    dispatch({type, payload, reducer})
  }
}

export type SetZoneUiState = (payload: Partial<ZoneUiState>, type?: string) => void

export const get_zone_ui_reducer = (zone_id: string, payload: Partial<ZoneUiState>) => (
  state: ReduxState
): ReduxState =>
  ih(state, {
    zone_ui: {
      [zone_id]: {
        $apply: (old: ZoneUiState = empty_zone_ui_state) => merge_state(old, payload),
      },
    },
  })

export const get_zone_ui_dispatch = (dispatch: ReduxDispatch, zone_id: string): SetZoneUiState => {
  return (payload: Partial<ZoneUiState>, type: string = 'set_zone_ui_state') => {
    const reducer = get_zone_ui_reducer(zone_id, payload)
    dispatch({type, payload, reducer})
  }
}

export type SetRuntimeUiState = (payload: Partial<RuntimeState>, type?: string) => void

export const get_runtime_ui_reducer = (payload: Partial<RuntimeState>) => {
  const reducer = (state: ReduxState): ReduxState =>
    ih(state, {
      runtime: {
        $apply: (old: RuntimeState) => merge_state(old, payload),
      },
    })

  // If the payload modifies resources, trigger UI validation
  return payload.resources ? with_ui_validation(reducer) : reducer
}

export const get_runtime_ui_dispatch = (dispatch: ReduxDispatch): SetRuntimeUiState => {
  return (payload: Partial<RuntimeState>, type: string = 'set_runtime_ui_state') => {
    const reducer = get_runtime_ui_reducer(payload)
    dispatch({type, payload, reducer})
  }
}

export const useSetRuntimeState = () => {
  const dispatch = useThunkDispatch()
  return useMemo(() => get_runtime_ui_dispatch(dispatch), [dispatch])
}

export const useSetTableUiState = (table_entity_id: TableEntityId) => {
  const dispatch = useThunkDispatch()
  return useMemo(() => get_table_ui_dispatch(dispatch, table_entity_id), [
    dispatch,
    table_entity_id,
  ])
}

export const useSetZoneUiState = (zone_id: string) => {
  const dispatch = useThunkDispatch()
  return useMemo(() => get_zone_ui_dispatch(dispatch, zone_id), [dispatch, zone_id])
}

export function get_table_ui_state(
  state: ReduxState,
  table_entity_id: TableEntityId
): TableUiState {
  return state.table_ui[table_entity_id] || empty_table_ui_state
}

export function get_zone_ui_state(state: ReduxState, zone_id: string): ZoneUiState {
  return state.zone_ui[zone_id] || empty_zone_ui_state
}

type TableUiKey = keyof TableUiState
export function useTableUiSelector<K extends TableUiKey>(
  table_entity_id: TableEntityId,
  key: K
): TableUiState[K] {
  return useSelector((state: ReduxState) => get_table_ui_state(state, table_entity_id)[key])
}

type ZoneUiKey = keyof ZoneUiState
export function useZoneUiSelector<K extends ZoneUiKey>(zone_id: string, key: K): ZoneUiState[K] {
  return useSelector((state: ReduxState) => get_zone_ui_state(state, zone_id)[key])
}

export function useRuntimeSelector() {
  return useSelector((state: ReduxState) => state.runtime)
}
