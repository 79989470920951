import React, {useCallback} from 'react'
import ContextMenuItem from './ContextMenuItem'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import UnarchiveTwoToneIcon from '@material-ui/icons/UnarchiveTwoTone'
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone'
import {EntityId} from 'common/types/storage'
import {useToggleArchived} from '../navigation/toggle_archived_hook'

type ArchiveEntityItemProps = {
  on_close: () => void
  entity_id: EntityId
}

const ArchiveEntityItem: React.FC<ArchiveEntityItemProps> = ({on_close, entity_id}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const [is_toggling, start_toggling] = useToggleArchived({
    on_redirect() {},
    on_error() {},
    entity: entity_headers[entity_id],
  })

  const on_click = useCallback(() => {
    start_toggling()
    on_close()
  }, [start_toggling, on_close])
  const entity = entity_headers[entity_id].type === 'project' ? ' project' : ' table'

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={
        entity_headers[entity_id].archived ? (
          <UnarchiveTwoToneIcon fontSize="small" />
        ) : (
          <ArchiveTwoToneIcon fontSize="small" />
        )
      }
      item={(entity_headers[entity_id].archived ? 'Unarchive' : 'Archive') + entity}
      disabled={is_toggling}
    />
  )
}

export default ArchiveEntityItem
