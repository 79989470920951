import React, {createContext, FC, useEffect, useState} from 'react'
import {RouteComponentProps} from 'react-router'
import {withRouter} from 'react-router-dom'
import {Box} from '@material-ui/core'

import {Auth} from '@aws-amplify/auth'
import {Hub} from '@aws-amplify/core'

import {UserIdentity} from 'common/types/user'
import aws_config from './config/aws'
import {ROUTES} from './utils/navigation_utils'

type UserData = {
  UserAttributes: {
    Name: string
    Value: string
  }[]
  Username: string
}
export type HubCapsule = {payload: {event: string; data?: any; message?: string}}

export const UserAccountContext = createContext<UserIdentity | null>(null)

// Provider component for user account information.
const UserAccountProvider: FC<RouteComponentProps> = ({history, location, children}) => {
  const [auth_completed, set_auth_completed] = useState(false)
  const [redirection_target, set_redirection_target] = useState<string | null>(null)

  const [current_user, set_current_user] = useState<UserIdentity | null>(null)

  // A one-time effect which:
  // 1. schedules an unconditional update
  //    to the data about the currently logged-in user and
  // 2. adds a function for listening to 'signIn' event
  //    from the 'auth' channel of events
  //    that are dispatched by Hub module from AWS amplify.
  useEffect(() => {
    Auth.configure(aws_config)

    const get_current_user_data = () => {
      Auth.currentUserPoolUser()
        .then((result) => {
          result.getUserData((error, data: UserData) => {
            if (error) {
              console.error("Unable to get the user's data", error)
            } else {
              const user_attributes = Object.fromEntries(
                data.UserAttributes.map((attribute) => [attribute.Name, attribute.Value])
              ) as UserIdentity
              if (user_attributes.email) {
                set_current_user(user_attributes)
              } else {
                // User whose attributes do not include email
                // is not suitable for authentication within this application.
                console.error("User's attributes do not include email")
              }
            }
          })
        })
        .catch((reason) => {
          if (reason !== 'No current user') {
            console.error('Unable to get the current user', reason)
          }
          // The rejection of the above promise
          // does not necessarily represent an error within the application,
          // because that promise can be rejected also when no user is signed in.
          // Therefore, no exception is logged.
        })
        .finally(() => {
          set_auth_completed(true)
        })
    }

    get_current_user_data()
    const listener: (capsule: HubCapsule) => void = ({payload: {event, data, message}}) => {
      switch (event) {
        case 'signIn':
          get_current_user_data()
          break
        case 'customOAuthState':
          set_redirection_target(decodeURIComponent(data))
          break
        default:
        // Other events are unhandled here.
      }
    }
    Hub.listen('auth', listener)
    return () => Hub.remove('auth', listener)
  }, [])

  useEffect(() => {
    if (current_user && redirection_target) {
      // Only redirect once both the user object
      // and the redirection target have been populated
      // by the 'auth' events dispatched via Hub.
      history.replace(redirection_target)
    }
  }, [current_user, history, redirection_target])

  // this auth_completed lies, when sign-in is in process,.. detect sign-in process using location
  if (!auth_completed || location.pathname === ROUTES.sign_in()) {
    // wait for auth is complete, as rendering starts pulling resources
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        Authenticating...
      </Box>
    )
  }

  // this must be rendered after authentication and sign-in is completed
  return <UserAccountContext.Provider value={current_user}>{children}</UserAccountContext.Provider>
}

export default withRouter(UserAccountProvider)
