import React, {FC} from 'react'
import {SwitchProps} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {withStyles} from '@material-ui/core/styles'

const StyledGroup = withStyles((theme) => ({
  grouped: {
    color: theme.palette.greenPalette[600],
    padding: theme.spacing(0.75, 2),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 4,
  },
}))(ToggleButtonGroup)

const StyledToggleButton = withStyles((theme) => ({
  root: {
    'height': 30,
    'width': 50,
    'borderRadius': 4,
    'paddingRight': 10,
    'paddingLeft': 10,
    '&$selected': {
      backgroundColor: theme.palette.greyPalette[50],
    },
    '&:hover': {
      borderColor: `${theme.palette.greyPalette[300]} !important`,
    },
    '&:focus': {
      borderColor: `${theme.palette.greyPalette[300]} !important`,
    },
  },
  selected: {
    backgroundColor: theme.palette.greyPalette[50],
  },
}))(ToggleButton)

type ToggleButtonsProps = SwitchProps & {
  labelChecked: string
  labelUnchecked: string
  asc: number
  onChange: () => void
}

const ToggleButtons: FC<ToggleButtonsProps> = ({labelChecked, labelUnchecked, asc, onChange}) => (
  <StyledGroup size="small" value={asc} onChange={onChange} exclusive>
    <StyledToggleButton value={1}>{labelChecked}</StyledToggleButton>
    <StyledToggleButton value={-1}>{labelUnchecked}</StyledToggleButton>
  </StyledGroup>
)

export default ToggleButtons
