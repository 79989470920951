import {id} from 'common/utils'
import {apply as apply_diff} from 'common/diffs/models/entity_diff'
import {empty_storage_with_version} from 'common/storage'
import {create_runtime_context} from 'common/create_runtime_context'
import {is_error, throw_error_object} from 'common/error'
import {RowId} from 'common/types/storage'

// For performance measurement I used adjusted test_data_generator with only string columns
const ROW_PREFIX = 'id_row_'
const COL_TYPE = 'string'
const TABLE_ENTITY_ID = id('wake')

// Create table entity object from our pre-generated table
// This is needed as an argument for the apply function

export const create_apply_helpers = async (fetchers) => {
  const {run} = create_runtime_context(empty_storage_with_version(), fetchers)
  const table = await run((runtime) => runtime.get_table(TABLE_ENTITY_ID)._load())
  const table_entity = await run((runtime) => runtime.get_entity(TABLE_ENTITY_ID, 'data_table'))
  if (is_error(table_entity)) {
    throw_error_object(table_entity)
  }
  const actions = table._actions

  // run apply function on created test table entity
  const run_apply = (diff) => apply_diff(table_entity, diff)

  // change value of cells in @rows_cnt x @cols_cnt area
  // we are using repetition feature of edit_cells table action
  const create_value_change_diff = (rows_cnt, cols_cnt) => {
    return actions.edit_cells(
      [0, 0],
      [cols_cnt, rows_cnt],
      [['new value']],
      table._get_rows_order(),
      table._can_edit_cell,
      'Jane Doe'
    ).diff
  }

  // Create diff for removing @rows_cnt rows
  // We are supposing that table is bigger than @rows_cnt
  const create_remove_row_diff = (rows_cnt) => {
    const row_ids: RowId[] = []
    for (let i = 0; i < rows_cnt; i++) {
      row_ids.push(`${ROW_PREFIX}${i}`)
    }
    return actions.remove_rows(row_ids).diff
  }

  // Create diff for adding @rows_cnt empty rows to the table
  const create_add_row_diff = (rows_cnt) => {
    const row_ids: RowId[] = []
    for (let i = 0; i < rows_cnt; i++) {
      row_ids.push(`${ROW_PREFIX}_new_${i}`)
    }
    return actions.add_rows(row_ids, 'John Doe').diff
  }

  // Create diff for adding new column to the table
  const create_add_column_diff = () => {
    return actions.add_column(
      'id_new_column',
      {name: 'New column', description: '', type: {type: COL_TYPE}},
      null
    ).diff
  }

  // Create diff for removing the first column from the table
  const create_remove_column_diff = () => {
    const col_id = table_entity.cols_order[0]
    return actions.remove_columns([col_id]).diff
  }

  // Create diff for reordering columns
  const create_reorder_column_diff = () => {
    const col_id = table_entity.cols_order[0]
    return actions.circular_column_reorder(col_id, 1).diff
  }

  // Create diff for changing column width
  const create_column_width_change_diff = () => {
    const col_id = table_entity.cols_order[0]
    return actions.change_column_width(col_id, 1000).diff
  }

  return {
    run_apply,
    create_value_change_diff,
    create_remove_row_diff,
    create_add_row_diff,
    create_add_column_diff,
    create_remove_column_diff,
    create_reorder_column_diff,
    create_column_width_change_diff,
  }
}
