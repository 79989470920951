import {CellType} from '../types/storage'

export enum SummaryComputeMethod {
  CUSTOM = 'CUSTOM',
  SUM = 'SUM',
  COUNT = 'COUNT',
  AVG = 'AVG',
  AVGSTD = 'AVGSTD',
  STD = 'STD',
  MIN = 'MIN',
  MAX = 'MAX',
}

export const fn_to_compute_method = (fn: string): SummaryComputeMethod => {
  return SummaryComputeMethod[fn] || SummaryComputeMethod.CUSTOM
}

export const get_available_methods_for_col_type = (col_type: CellType): SummaryComputeMethod[] => {
  switch (col_type) {
    case 'number':
      return [
        SummaryComputeMethod.CUSTOM,
        SummaryComputeMethod.COUNT,
        SummaryComputeMethod.SUM,
        SummaryComputeMethod.AVG,
        SummaryComputeMethod.AVGSTD,
        SummaryComputeMethod.STD,
        SummaryComputeMethod.MIN,
        SummaryComputeMethod.MAX,
      ]
    case 'date_time':
    case 'date':
      return [
        SummaryComputeMethod.CUSTOM,
        SummaryComputeMethod.COUNT,
        SummaryComputeMethod.MIN,
        SummaryComputeMethod.MAX,
      ]
    case 'string':
    default:
      return [SummaryComputeMethod.CUSTOM, SummaryComputeMethod.COUNT]
  }
}
