import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import {Box, Grid} from '@material-ui/core'

const LoadingSkeleton = () => (
  <div>
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={4}>
      <Skeleton animation="wave" variant="rect" width={200} height={48} />
      <Skeleton animation="wave" variant="rect" width={200} height={36} />
    </Box>
    <Grid container spacing={2}>
      <Grid item sm={12} md={4} lg={3} xl={2}>
        <Skeleton animation="wave" height={93.34} width="100%" />
      </Grid>
      <Grid item sm={12} md={4} lg={3} xl={2}>
        <Skeleton animation="wave" height={93.34} width="100%" />
      </Grid>
      <Grid item sm={12} md={4} lg={3} xl={2}>
        <Skeleton animation="wave" height={93.34} width="100%" />
      </Grid>
    </Grid>
  </div>
)

export default LoadingSkeleton
