import _ from 'lodash'
import {throw_error} from '../../utils'
import {Nullable} from '../../types/storage'

export type ConstantDiff<T> = {
  old_value: Nullable<T>
  new_value: Nullable<T>
}

function is_value_type(diff: unknown): diff is ConstantDiff<any> {
  return typeof diff === 'object' && diff != null && 'old_value' in diff && 'new_value' in diff
}

function ensure_diff(diff: unknown) {
  if (
    !is_value_type(diff) ||
    // value cannot be modified and conflicted, so the diff can be only (null, value)
    // or (value, null) to be able to apply the reverse
    (diff.old_value == null && diff.new_value == null) ||
    (diff.old_value != null && diff.new_value != null)
  ) {
    throw_error('Given object is not valid constant diff.', 'diff', diff)
  }
}

export default function constant_diff(delete_empty = true) {
  function get_old_value(diff) {
    return diff.old_value
  }

  function get_new_value(diff) {
    return diff.new_value
  }

  function change_diff(old_value, new_value) {
    return {old_value, new_value}
  }

  function apply(value, diff) {
    ensure_diff(diff)
    if (value === get_old_value(diff)) {
      return get_new_value(diff)
    } else {
      throw_error(
        'Constant diff apply problem: can not apply without causing conflicts',
        'value',
        value,
        'diff',
        diff
      )
      return null
    }
  }

  function reverse(diff) {
    ensure_diff(diff)
    return change_diff(get_new_value(diff), get_old_value(diff))
  }

  function ensure_consistency(value, diff) {
    ensure_diff(diff)
    if (!_.isEqual(value, get_old_value(diff)))
      throw_error(
        'Constant diff consistency problem: Can not apply diff without causing conflicts',
        'value',
        value,
        'diff',
        diff
      )
  }

  function unapply(value, diff, ensure = true) {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(value, reversed_diff)
    return apply(value, reversed_diff)
  }

  function squash(...diffs) {
    throw_error('Squashing multiple diffs modifying constant field', 'diffs', diffs)
    return null
  }

  function get_value(value) {
    return value
  }

  function is_conflict(value) {
    return false
  }

  function get_conflicts(value) {
    return []
  }

  function is_empty(value) {
    return value == null
  }

  function clean_neutral_diffs(diff) {
    if (is_value_type(diff) && get_old_value(diff) === get_new_value(diff)) {
      return null
    }
    return diff
  }

  const create_diff = (value) => change_diff(null, value)

  return {
    // value can't be modified, so there is no conflict
    conflict_free: get_value,
    get_old_value,
    get_new_value,
    is_conflict,
    get_conflicts,
    is_empty,
    apply,
    reverse,
    ensure_consistency,
    unapply,
    squash,
    change_diff,
    create_diff,
    clean_neutral_diffs,
    delete_empty,
    default_value: null,
  }
}
