import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {open_modal} from '../Modals'
import ContextMenuItem from './ContextMenuItem'
import {AlwaysDefinedRuntime, useRuntimeSelector, useTableUiSelector} from '../utils/connect_hocs'
import Edit from '@material-ui/icons/Edit'

type EditOrViewColumnItemProps = {
  on_close: () => void
  view_only: boolean
}

const EditOrViewColumnItem: React.FC<EditOrViewColumnItemProps> = ({on_close, view_only}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const dispatch = useDispatch()
  const col_id = cursor?.col_id

  const on_click = useCallback(() => {
    if (col_id == null) return
    dispatch(open_modal(view_only ? 'view_column' : 'edit_column', {table_entity_id, col_id}))
    on_close()
  }, [dispatch, view_only, table_entity_id, col_id, on_close])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={view_only ? <i className="fas fa-eye" /> : <Edit fontSize="small" />}
      item={`${view_only ? 'View' : 'Edit'} column`}
    />
  )
}

export default EditOrViewColumnItem
