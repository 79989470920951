import {createHash} from 'crypto'
import _ from 'lodash'
import {parse as date_fns_parse} from 'date-fns'
import numbro from 'numbro'
import csCZ from 'numbro/languages/cs-CZ'
import enGB from 'numbro/languages/en-GB'
import huHU from 'numbro/languages/hu-HU'
import skSK from 'numbro/languages/sk-SK'
import {
  NumberFormatParameters,
  NUMBRO_DEFAULT_LANGUAGE,
  SUPPORTED_CURRENCIES,
} from './formatting/number'
import {
  DATE_DEFAULT_LOCALE,
  get_date_format,
  DateFormatParameters,
  get_time_format,
  DateFormatId,
  TimeFormatId,
  get_date_time_format_combinations,
  DATE_FORMATS_PARAMS,
  DATETIME_FORMATS_PARAMS,
} from './formatting/date'
import {get_boolean_format_by_value} from 'common/formatting/boolean'

/**
 * @module Utils
 */
numbro.registerLanguage(csCZ, false)
numbro.registerLanguage(enGB, false)
numbro.registerLanguage(huHU, false)
numbro.registerLanguage(skSK, false)

// Utility type for arbitrary generic functions
export type Fn<K extends any[], T> = (...args: K) => T

// matches more than one whitespace character in a row
const MULTIPLE_WHITESPACE_RE = /\s\s+/g

// matches string or quoted string with whitespaces
// escaped quote-aware
const SPLIT_NONQUOTED_WHITESPACE_RE = /(?:\\'|\\"|[^\s'"])+|"(?:\\"|[^"])+"|'(?:\\'|[^'])+'/g

function is_object(obj: unknown): obj is object {
  return _.isObject(obj) && !Array.isArray(obj)
}

function is_null_or_empty(obj: unknown): boolean {
  return obj == null || (_.isObject(obj) && _.isEmpty(obj))
}

type ErrorData = {[key: string]: string}
type Params = [ErrorData, string, ...any[]]

function throw_error(...args: Params | [string, ...any[]]): never {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  throw construct_error(...args)
}

function str_repr(obj: unknown, prettify: boolean = false): string {
  const t = typeof obj
  if (obj === null) {
    return 'null'
  } else if (t === 'undefined') {
    return 'undefined'
  } else if (['function', 'symbol'].includes(t)) {
    return (obj as Function | Symbol).toString()
  } else if (['boolean', 'number', 'string', 'object'].includes(t)) {
    return prettify ? JSON.stringify(obj, null, 4) : JSON.stringify(obj)
  } else {
    return throw_error('Throwing error failed: Unknown object type.', 'Type', t)
  }
}

function construct_error(...args: Params | [string, ...any[]]): Error {
  const [data, msg, ...params] = (_.isObject(args[0]) ? args : [{}, ...args]) as Params
  const space = '          '
  const err = [msg, '\n']
  for (const [name, obj] of _.chunk(params, 2)) {
    err.push(space)
    err.push(`${name}: `)
    err.push(_.truncate(str_repr(obj), {length: 100 + space.length}))
    err.push('\n')
  }
  const error = new Error(err.join(''))
  // Note: mutates error
  Object.assign(error, data)
  return error
}

function get_indexes(array: string[]): Record<string, number> {
  return _.transform(
    array,
    (indexes, value, index) => {
      indexes[value] = index
    },
    {} as Record<string, number>
  )
}

function _throw_error(msg: string, extra?: object): never {
  const space = '          '
  const err = [msg, '\n']
  _.toPairs(extra).forEach(([key, val]) => {
    err.push(space)
    err.push(`${key}: `)
    err.push(_.truncate(str_repr(val), {length: 100 + space.length}))
    err.push('\n')
  })

  const error = new Error(err.join(''))
  // Note: mutates error
  Object.assign(error, extra)
  throw error
}

function ensure(expr: unknown, msg: string, extra?: object): asserts expr {
  if (!expr) {
    _throw_error(msg, extra)
  }
}

function not_null<T>(value: T | null | undefined): value is T {
  return value != null
}

function id(name: string): string {
  return `id_${name}`
}

function uuid(): string {
  const result: string[] = []
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 12; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

function sha(str: string) {
  return createHash('sha256').update(str).digest('hex')
}

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

function normalize_name(name: string): string {
  // Strip accents: https://stackoverflow.com/a/37511463/1761457
  return name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/ /g, '_')
}

function remove_multiple_whitespace(str: string): string {
  return str.trim().replace(MULTIPLE_WHITESPACE_RE, ' ')
}

/**
 * consume escape character for quotes
 * @param str {string}
 * @return {string}
 */
function unescape_quotes(str: string): string {
  return str.replace(/\\('|")/g, '$1')
}

/**
 * remove extra quotes (a matching enclosing pair)
 * @param str {string}
 * @return {string}
 */
function remove_enclosing_quotes(str: string): string {
  if (str[0] === '"' && str[str.length - 1] === '"') return str.slice(1, -1)
  if (str[0] === "'" && str[str.length - 1] === "'") return str.slice(1, -1)
  return str
}

/**
 * split string on whitespaces except inside quotes (single or double)
 * => ["first", "second", ""third word""]
 * and remove extra quotes
 * @param str {string}
 * @return {string[]}
 */
function split_on_space_outside_quotes(str: string): string[] {
  const retval = str.match(SPLIT_NONQUOTED_WHITESPACE_RE)
  return (retval || []).map((str) => unescape_quotes(remove_enclosing_quotes(str)))
}

/**
 * modulo with negative number does not work in JS
 * @param n {number}
 * @param m {number}
 * @return {number}
 */
function mod(n: number, m: number): number {
  // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
  return ((n % m) + m) % m
}

function sum(arr: number[]): number {
  return arr.reduce((a, b) => a.add(b), numbro(0)).value()
}

function unformat_number(value: string, language?: string): number {
  // to properly parse localized currency, numbro needs global settings
  // that are associated with the corresponding language
  const _language = language || NUMBRO_DEFAULT_LANGUAGE
  if (numbro.language() !== _language) {
    numbro.setLanguage(_language)
  }
  // the reason to not use second parameter of unformat - format is that nubro as it is for now
  // doesn't look at this parameter and is parsing only according to language settings
  const val = numbro.unformat(value)
  return val
}

function parse_number<T>(value: T, parameters?: NumberFormatParameters): number | T {
  if (typeof value !== 'string') return value

  // remove currency symbols
  let stripped_value: string = value
  for (const currency of SUPPORTED_CURRENCIES) {
    stripped_value = stripped_value.replace(currency, '')
  }
  stripped_value = stripped_value.trim()

  let ordered_languages: string[]
  switch (parameters?.language) {
    case 'cs-CZ':
    case 'hu-HU':
    case 'sk-SK':
      // the reason for using 'hu-HU' and even 'sk-SK' is that one has " " as thousand separator
      // and the second has "\u00a0"
      ordered_languages = ['sk-SK', 'hu-HU', 'en-US']
      break
    case 'en-US':
    case 'en-GB':
    default:
      ordered_languages = ['en-US', 'sk-SK', 'hu-HU']
  }

  for (const language of ordered_languages) {
    const val = unformat_number(stripped_value, language)
    if (Number.isFinite(val)) return val
  }
  return value
}

function _strip_timezone(value: Date): Date {
  return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()))
}

function _parse_date(
  date_string: string,
  preferred_formats: DateFormatParameters[],
  all_possible_formats: DateFormatParameters[]
): Date | string {
  const _locale_hint =
    preferred_formats.length > 0 ? preferred_formats[0].locale : DATE_DEFAULT_LOCALE
  let parsed_date: Date
  //Try to parse date_string using supported formats
  //parse order:
  //1 - given by target column format
  for (const format of preferred_formats) {
    parsed_date = date_fns_parse(date_string, format.template, new Date())
    if (isFinite(parsed_date.getTime())) return parsed_date
  }
  //in case string format is ambiguous (5.10.2020),
  //try same locale as (first) target (day-month vs month-day) first
  //2 - supported formats with same DMY type
  for (const date_format of all_possible_formats) {
    if (date_format.locale === _locale_hint) {
      const _format = date_format?.template || ''
      parsed_date = date_fns_parse(date_string, _format, new Date())
      if (isFinite(parsed_date.getTime())) return parsed_date
    }
  }

  //3 - supported formats with other DMY type
  for (const date_format of all_possible_formats) {
    if (date_format.locale !== _locale_hint) {
      const _format = date_format?.template || ''
      parsed_date = date_fns_parse(date_string, _format, new Date())
      if (isFinite(parsed_date.getTime())) return parsed_date
    }
  }
  //4 - fallback to whatever Date can handle
  parsed_date = new Date(date_string)
  if (isFinite(parsed_date.getTime())) return parsed_date
  //miss
  return date_string
}

function parse_date(date_string: string, format_id?: DateFormatId): Date | string {
  const format = get_date_format(format_id)
  const parsed_date = _parse_date(date_string, format ? [format] : [], DATE_FORMATS_PARAMS)
  return parsed_date instanceof Date ? _strip_timezone(parsed_date) : date_string
}

function parse_date_time(
  date_time_string: string,
  date_format_id?: DateFormatId,
  time_format_id?: TimeFormatId
): Date | string {
  //when a number was passed to date_fns it was causing crashes
  const parsed_as_number = Number(date_time_string)
  if (Number.isFinite(parsed_as_number)) {
    return new Date(parsed_as_number)
  }

  const date_format = get_date_format(date_format_id)
  const time_format = get_time_format(time_format_id)

  return _parse_date(
    date_time_string,
    get_date_time_format_combinations(
      date_format ? [date_format] : [],
      time_format ? [time_format] : []
    ),
    DATETIME_FORMATS_PARAMS
  )
}

/**
 * calling convert_date_to_iso_string converts to utc and crops date portion
 * depending on time and timezone of date, this can cause day shifts
 * date needs to be expressed in utc to prepare for said conversion
 * @param value {Date | null}
 * @return {Date | null}
 */
function strip_timezone(value: Date | null): Date | null {
  if (value && value instanceof Date) {
    return _strip_timezone(value)
  } else {
    return null
  }
}

function convert_date_to_iso_string(date: Date | null): string {
  // Convert Date object to the ISO string representation (without time)
  // Returns: string, e.g. '2019-08-08'
  return date ? date.toISOString().split('T')[0] : 'null'
}

function convert_date_time_to_iso_string(date: Date | null): string {
  return date ? date.toISOString() : 'null'
}

function parse_iso_date(iso_date_string: string): Date | null {
  // Check whether the date string is in valid format (YYYY...-MM-DD)
  // Returns: Date if valid ISO date string, otherwise null
  iso_date_string = iso_date_string.trim()
  try {
    const date = new Date(iso_date_string)
    return iso_date_string === convert_date_to_iso_string(date) ? date : null
  } catch (_err) {
    return null
  }
}

function parse_iso_date_time(iso_date_time_string: string): Date | null {
  try {
    const date = new Date(iso_date_time_string.trim())
    return Number.isFinite(date.getTime()) ? date : null
  } catch (_err) {
    return null
  }
}

/**
 * check whether the two lists contain the same elements
 * @param list_a {T[]}
 * @param list_b {T[]}
 * @return {boolean}
 */
function has_same_elements<T>(list_a: T[], list_b: T[]): boolean {
  if (list_a.length !== list_b.length) return false
  const set_b = new Set(list_b)
  for (const elem_a of list_a) {
    if (!set_b.has(elem_a)) return false
  }
  return true
}

function move_in_array<T>(array: readonly T[], from_index: number, to_index: number) {
  const mutable_array = [...array]
  if (!_.inRange(from_index, array.length) || !_.inRange(to_index, array.length)) {
    return mutable_array
  }
  const [moved_item] = mutable_array.splice(from_index, 1)
  mutable_array.splice(to_index, 0, moved_item)
  return mutable_array
}

/**
 * Like Lodash's `_.sortedIndex()` but accepts a comparator function.
 * @param array {T[]}
 * @param value {T}
 * @param less_than
 * @return {boolean}
 */
function sorted_index_by<T>(array: readonly T[], value: T, less_than: (a: T, b: T) => boolean) {
  let low = 0,
    high = array.length
  while (low < high) {
    // bit shift instead of division & floor (same as in Lodash source)
    const middle = (low + high) >>> 1 // eslint-disable-line no-bitwise
    if (less_than(array[middle], value)) {
      low = middle + 1
    } else {
      high = middle
    }
  }
  return high
}

/**
 * Composes single-argument functions from right to left. The rightmost
 * function can take multiple arguments as it provides the signature for the
 * resulting composite function.
 *
 * @param funcs {Function[]} The functions to compose.
 * @returns {Function} A function obtained by composing the argument functions from right
 *   to left. For example, `compose(f, g, h)` is identical to doing
 *   `(...args) => f(g(h(...args)))`.
 */

function compose(...funcs: Function[]): Function {
  if (funcs.length === 0) {
    // infer the argument type so it is usable in inference down the line
    return <T>(arg: T) => arg
  }

  if (funcs.length === 1) {
    return funcs[0]
  }

  return funcs.reduce((a, b) => (...args: any) => a(b(...args)))
}

const parse_boolean = (value: string): boolean | string => {
  const boolean_format = get_boolean_format_by_value(value)
  if (boolean_format) {
    return value === boolean_format[0]
  }
  return value
}

export {
  get_indexes,
  construct_error,
  throw_error,
  ensure,
  not_null,
  id,
  wait,
  is_object,
  is_null_or_empty,
  normalize_name,
  remove_multiple_whitespace,
  split_on_space_outside_quotes,
  remove_enclosing_quotes,
  uuid,
  sha,
  mod,
  sum,
  str_repr,
  parse_number,
  parse_date,
  parse_date_time,
  strip_timezone,
  parse_iso_date,
  parse_iso_date_time,
  convert_date_to_iso_string,
  convert_date_time_to_iso_string,
  has_same_elements,
  move_in_array,
  sorted_index_by,
  compose,
  parse_boolean,
}
