import React, {FC, useCallback, useRef} from 'react'
import {Card, IconButton, makeStyles, Popper} from '@material-ui/core'

import AsyncFind from './AsyncFind'
import JumpButtons from './JumpButtons'
import {control_key} from '../utils/keyboard'

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1200,
    marginTop: -1,
  },
  find_bar: {
    padding: '16px 12px',
    backgroundColor: theme.palette.background.default,
    borderTop: 0,
    borderRadius: '0px 0px 8px 8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.spacing(1),
    borderRadius: 0,
  },
}))

const consume_key_down_event = (e: React.KeyboardEvent<HTMLDivElement>) => {
  // block global bindings like Ctrl+Z (undo) when find is focused,
  // except Ctrl+F (focuses find input and prevent native browser's find)
  if (!(control_key(e) && e.key.toLowerCase() === 'f')) {
    e.stopPropagation()
  }
}

const consumeEvent = (e) => {
  e.stopPropagation()
}

type TableFindProps = {on_close: () => void; open: boolean}

// TableFind doesn't block interaction with the rest of the table while it is opened, so the user
// is able to navigate between find results, focus/edit cells without closing the TableFind.
// TableFind stays open with unchanged find text in input when switching between tables.
const TableFind: FC<TableFindProps> = ({on_close, open}) => {
  const anchor_ref = useRef<any>(null)

  const handle_key_down = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      consume_key_down_event(e)
      if (e.key === 'Escape') {
        on_close()
      }
    },
    [on_close]
  )

  const classes = useStyles()

  return (
    <>
      <div
        ref={anchor_ref}
        style={{
          position: 'absolute',
          top: 0,
          right: 44,
        }}
      />
      <Popper
        id={'table-find'}
        className={classes.popper}
        open={open}
        anchorEl={anchor_ref.current}
        placement="left-start"
      >
        <Card
          className={classes.find_bar}
          variant="outlined"
          onKeyDown={handle_key_down}
          onPaste={consumeEvent}
          onCopy={consumeEvent}
          onCut={consumeEvent}
        >
          <AsyncFind />
          <JumpButtons />
          <IconButton className={classes.button} size="small" onClick={on_close} disableRipple>
            <i className="fas fa-times" />
          </IconButton>
        </Card>
      </Popper>
    </>
  )
}

export default TableFind
