const style_template = `/* table is an object representing the current table
   This function should return style object for every row of this column.
   For that, you can use compute_column method: */

(runtime, {table}) =>
    table
      .compute_column((row) =>
        row.get('name_of_column') === null ? {backgroundColor: '#fdd'} : {backgroundColor: '#dfd'} 
      )
`

const tooltip_template = `/* table is an object representing the current table 
   This function should return a string (or an array of strings) for every row */

   (runtime, {table}) =>
   table
     .compute_column((row) => [
       'Value of this row is: ' + row.get('name_of_column'),
       'This is another tooltip',
     ])
`

const computed_column_template = `/* table is an object representing the current table 
   This function should return value for every row of this column. 
   For that, you can use compute_column method */

// Example 1: computed (numeric) column doesn't depend on other columns from the table
(runtime, {table}) => {
  let index = 385
  return table.compute_column((row) => index++)
}

/* Tip: You can choose other tables in selector by pressing Ctrl+D */

/* Example 2: computed column value depends on other columns: 
(runtime, {table}) =>
  table.compute_column((row) => row.get('column_name_1') + row.get('column_name_2'))
*/
`

const summary_template = `/* table is an object representing the current table 
   This function should return value shown in summary row.*/

(runtime, {table}) => 
  table.rows().filter(row => row.get("name_of_column") !== null).length

`
export default {
  style: style_template,
  tooltip: tooltip_template,
  computed_column: computed_column_template,
  summary: summary_template,
}
