import _ from 'lodash'
import {SummaryComputeMethod, get_available_methods_for_col_type} from './compute_methods'
import {create_error, ErrorObject, is_error} from 'common/error'
import {throw_error} from 'common/utils'
import {CellType} from '../types/storage'

// Based on https://en.wikipedia.org/wiki/Standard_deviation
const get_std_dev = (values: number[]): number | ErrorObject => {
  if (values.length < 2) {
    return create_error('user', {
      subtype: 'impossible-calculation',
      message: 'For calculation we need at least two values',
    })
  }

  const avg = _.mean(values)
  const squared_sums = _.map(values, (value) => (value - avg) ** 2)
  const pre_sqrt = _.sum(squared_sums) / (values.length - 1)

  return Math.sqrt(pre_sqrt)
}

export const eval_short_fn = (
  col_type: CellType,
  values: any[],
  fn: SummaryComputeMethod
): number | Date | number[] | ErrorObject => {
  if (!get_available_methods_for_col_type(col_type).includes(fn)) {
    return create_error('user', {
      subtype: 'impossible-calculation',
      message: `Using unavaiable method ${fn} for column type ${col_type}`,
      col_type,
      short_fn: fn,
    })
  }

  const values_without_empty = values.filter(
    (val) => !(val === null || (Array.isArray(val) && val.length === 0))
  )

  switch (fn) {
    case SummaryComputeMethod.COUNT:
      return values_without_empty.length
    case SummaryComputeMethod.SUM:
      return _.sum(values_without_empty)
    default:
  }
  // all following short_fn needs at least one element in array
  if (values_without_empty.length === 0) {
    return create_error('user', {
      subtype: 'impossible-calculation',
      message: 'For calculation we need at least one values',
    })
  }
  switch (fn) {
    case SummaryComputeMethod.AVG:
      return _.mean(values_without_empty)
    case SummaryComputeMethod.AVGSTD: {
      const std_dev = get_std_dev(values_without_empty)
      if (is_error(std_dev)) {
        return std_dev
      }
      const avg = _.mean(values_without_empty)
      return [avg, std_dev]
    }
    case SummaryComputeMethod.STD:
      return get_std_dev(values_without_empty)
    case SummaryComputeMethod.MIN:
      return _.min(values_without_empty)
    case SummaryComputeMethod.MAX:
      return _.max(values_without_empty)
    default:
      return throw_error('Invalid summary short_fn', fn)
  }
}
