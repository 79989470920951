import {useEffect} from 'react'
import {RuntimeFn} from 'common/create_runtime_context'
import {useRuntimeContext, useRuntimeSequence} from './RuntimeContextProvider'
import {RuntimeResources, useSetRuntimeState} from './utils/connect_hocs'
import {Storage} from 'common/types/storage'
import {ErrorObject} from 'common/error'

const EMPTY_RESOURCES = {}
const get_empty_resources = () => EMPTY_RESOURCES

// IMPORTANT:
// The hooks provided in this file should only be used in one component at a time.
// The returned resources should be passed down as props.
// It's still possible to use them in different components that are never mounted
// at the same time (e.g. only mounted on different routes).

// About 'get_resources' parameter:
//  - It should be a function with signature (runtime) => resources
//  - Throwing 'in-progress' error indicates that resources are not fetched yet
//  - Throwing any other error indicates that resources failed to be fetched
//  - IMPORTANT: Use useCallback() to pass a memoized function!

// About 'get_default_resources':
// Function to calculate some basic resources even if loading fails (e.g. commit does not
// exist for table => ends with error, but table_id/zone_id can be returned from here to
// display some components)
// - It should be a function with signature (storage) => resources
// - It should never throw and should return resources immediately (no fetching)
// - IMPORTANT: Use useCallback() to pass a memoized function!
const useContextRunner = (
  get_resources: RuntimeFn<RuntimeResources>,
  get_default_resources: (storage: Storage) => RuntimeResources = get_empty_resources
) => {
  const runtime_context = useRuntimeContext()
  const runtime_sequence = useRuntimeSequence()
  const setRuntime = useSetRuntimeState()

  useEffect(() => {
    setRuntime({status: 'in-progress'}, 'set_runtime_in_progress')

    // If resources cannot be loaded, empty the resources and set the status to 'error'
    runtime_context.run_cb(get_resources, (status, result) => {
      if (status === 'done') {
        // If we're done, let's freeze the runtime_context and take the result from it.
        // This should call the callback immediately (the data is already there) and it'll return
        // resources that won't change when dispatch will happen on the main runtime_context
        const frozen_context = runtime_context.freeze()
        frozen_context.run_cb(get_resources, (status, result) => {
          if (status === 'done') {
            const runtime = frozen_context.get_runtime()
            const storage = frozen_context.get_storage_state()
            setRuntime(
              {
                status: 'done',
                resources: {
                  ...get_default_resources(storage),
                  ...(result as RuntimeResources),
                },
                error: null,
                storage,
                runtime,
              },
              'set_runtime_result'
            )
          } else {
            throw result
          }
        })
      } else if (status === 'error') {
        console.warn('Failed to load resources.', result)
        const frozen_context = runtime_context.freeze()
        const runtime = frozen_context.get_runtime()
        const storage = frozen_context.get_storage_state()
        setRuntime(
          {
            status: 'error',
            resources: get_default_resources(storage),
            error: result as ErrorObject | Error,
            runtime,
            storage,
          },
          'set_runtime_error'
        )
      } else if (status === 'in-progress') {
        // pass
      }
    })

    return () => {
      runtime_context.clear_runner_task()
    }
  }, [runtime_context, runtime_sequence, get_resources, setRuntime, get_default_resources])
}

export {useContextRunner}
