import React, {FC, useCallback} from 'react'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import {useDispatch} from 'react-redux'
import {open_modal} from '../Modals'
import CustomIconButton from '../components/CustomIconButton'

const ShowShortcutsButton: FC = () => {
  const dispatch = useDispatch()

  const handle_click = useCallback(() => {
    dispatch(open_modal('show_shortcuts', {}))
  }, [dispatch])

  return (
    <CustomIconButton size="small" tooltip="Show available shortcuts (F1)" onClick={handle_click}>
      <HelpOutlineIcon />
    </CustomIconButton>
  )
}

export default ShowShortcutsButton
