/**
 * Runtime API
 * @namespace Runtime
 */
import {compute_resource} from './compute_resource'
import {TableObject} from './objects/data_table'
import {create_computed_table_from_empty_table} from './objects/computed_table'
import {create_view_table_from_empty_table} from './objects/view_table'
import {throw_error} from './utils'
import {compose_resource_id, compose_table_id, parse_table_id, ComputedParams} from './params_utils'
import {EntityPermission, get_permission_table_entity_id} from 'common/permission/permission_utils'
import {
  is_error,
  throw_on_error,
  MaybeError,
  create_error,
  entity_has_no_changes_to_display,
} from './error'
import _ from 'lodash'
import {get_external_resource, get_resource} from './storage'
import {RuntimeContext} from './create_runtime_context'

import {
  EntityId,
  TableId,
  OrganisationId,
  Organisation,
  ProjectId,
  Project,
  TableEntityId,
  ResourceData,
  ResourceId,
  ResourceFn,
  Storage,
  EntityDiffInfo,
  EntityType,
  Entity,
  EntityHeaders,
  ZoneId,
  ExternalData,
  ExternalFn,
} from './types/storage'
import {PermissionTableSubtype} from 'common/permission/permission_tables'

/**
 * @memberOf Runtime
 * @see {@tutorial computed_tables_user_guide}
 */
export type Runtime = {
  get_headers: () => EntityHeaders
  get_organisation: (organisation_id: OrganisationId) => Organisation<'entity'>
  get_project: (project_id: ProjectId) => Project<'entity'>

  get_permission_table: (zone_id: ZoneId, subtype: PermissionTableSubtype) => TableObject

  /**
   * Creates table object from entity ID and params
   */
  get_table_or_error: (
    table_entity_id: TableEntityId,
    params?: ComputedParams
  ) => MaybeError<TableObject>

  /**
   * Creates table object from entity ID and params
   */
  get_table: (table_entity_id: TableEntityId, params?: ComputedParams) => TableObject

  /**
   * Creates table object from table ID
   */
  _get_table_or_error: (table_id: TableId) => MaybeError<TableObject>

  /**
   * Creates table object from table ID
   */
  _get_table: (table_id: TableId) => TableObject

  /**
   * Creates table object from table ID, handles error cases that we want from UI to handle nicely
   */
  get_table_for_ui: (table_id: TableId) => TableObject

  /**
   * Get entity resource and do entity type checking if second argument is provided
   */
  get_entity: <T extends EntityType>(
    entity_id: EntityId,
    expected_type?: T
  ) => MaybeError<Entity<T>>
  get_permissions_for_entity: (entity_id: EntityId) => MaybeError<EntityPermission>

  get_external_data: <T extends ExternalData = ExternalData>(
    cache_key: string,
    fn: ExternalFn<T>,
    loading_data?: T
  ) => T

  get_all: <T>(fns: Array<() => T>) => Array<T>
  get_entity_diff: (table_entity_id: TableEntityId) => EntityDiffInfo | null
  runtime_context: RuntimeContext
  _get_resource: <T extends ResourceData = ResourceData>(
    resource_id: ResourceId,
    fn?: ResourceFn<T>
  ) => MaybeError<T>
  _runtime_id: number
  storage: Storage
}

/**
 * We need to start fetching all resources at once, if we didn't catch in-progress here,
 * resource fetching would start after the previous one is finished successfully.
 * @memberOf Runtime
 * @param get_resource_fn {Fn}
 * @return {T}
 */
function _catch_in_progress<T>(get_resource_fn: () => T): T {
  try {
    return get_resource_fn()
  } catch (err) {
    if (err.type === 'in-progress') {
      return err
    } else {
      throw err
    }
  }
}

/**
 * takes an array of functions that get resources from storage,
 * e.g. () => runtime.get_entity(entity_id)
 * returns an array of resources
 * or throws in-progress if at least one of the resources threw in-progress
 * @memberOf Runtime
 * @param get_resource_fns {Fn[]}
 * @return {T[]}
 */
function get_all<T>(get_resource_fns: Array<() => T>): Array<T> {
  const resources = _.map(get_resource_fns, (fn) => _catch_in_progress(fn))

  if (_.some(resources, (entity) => is_error(entity) && entity.type === 'in-progress')) {
    throw create_error('in-progress')
  }
  return resources
}

let runtime_cnt: number = 0

/**
 * Check out docs for RuntimeContext (create_runtime_context.ts) to get some background.
 * @see {@link module:RuntimeContext|Runtime Context}
 * @memberOf Runtime
 * @param storage {Storage}
 * @param runtime_context {RuntimeContext}
 * @return {Runtime}
 */
function create_runtime(storage: Storage, runtime_context: RuntimeContext): Runtime {
  const runtime_id = runtime_cnt++

  function _get_resource<T extends ResourceData = ResourceData>(
    resource_id: ResourceId,
    fn?: ResourceFn<T>
  ): MaybeError<T> {
    return get_resource<T>({
      storage,
      fetch_commits_action: runtime_context.fetch_commits_action,
      fetch_resource_action: runtime_context.fetch_resource_action,
      fetch_external_data_action: runtime_context.fetch_external_data_action,
      mutable_dispatch: runtime_context.mutable_dispatch,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      runtime,
      compute_resource,
      resource_id,
      custom_resource_fn: fn,
    })
  }

  /**
   * Get entity resource and do entity type checking if second argument is provided
   * @memberOf Runtime
   * @param entity_id {EntityId}
   * @param [expected_type] {T}
   * @return {MaybeError<Entity<T>>}
   */
  function get_entity<T extends EntityType>(
    entity_id: EntityId,
    expected_type?: T
  ): MaybeError<Entity<T>> {
    const entity = _get_resource<Entity<T>>(compose_resource_id({type: 'entity', entity_id}))
    // Throws if the returned entity's type does not match the expected type
    // This type-checking is skipped if expected_type is omitted/undefined
    // Bee errors are always returned (they should be handled by the caller)
    if (is_error(entity)) {
      return entity
    } else if (expected_type === undefined || expected_type === entity.type) {
      return entity
    } else {
      return throw_error('Unexpected entity type', 'type', entity.type, 'expected', expected_type)
    }
  }

  /**
   * Creates table object from table ID
   * @memberOf Runtime
   * @param table_id {TableId}
   * @return {MaybeError<TableObject>} error object in case of error
   */
  function _get_table_or_error(table_id: TableId): MaybeError<TableObject> {
    const resource_id = compose_resource_id({type: 'table', table_id})
    return _get_resource(resource_id)
  }

  /**
   * Creates table object from entity ID and params
   * @memberOf Runtime
   * @param entity_id {EntityId}
   * @param [params] {ComputedParams}
   * @return {MaybeError<TableObject>} error object in case of error
   */
  function get_table_or_error(
    entity_id: EntityId,
    params?: ComputedParams
  ): MaybeError<TableObject> {
    const table_id = compose_table_id({entity_id, params})
    return _get_table_or_error(table_id)
  }

  function _get_table_from_empty(table_id: TableId): MaybeError<TableObject> {
    const {entity_id} = parse_table_id(table_id)

    const entity = get_entity(entity_id)
    if (is_error(entity)) {
      return entity
    }
    switch (entity.type) {
      case 'computed_table':
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return create_computed_table_from_empty_table(runtime, table_id, entity)
      case 'view_table':
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return create_view_table_from_empty_table(runtime, table_id, entity)
      default:
        return throw_error('Unexpected entity type', 'type', entity.type)
    }
  }

  function _get_table_or_empty(table_id: TableId): MaybeError<TableObject> {
    const res = _get_table_or_error(table_id)

    if (is_error(res) && (res.subtype === 'source_data' || res.type === 'circular-dependency')) {
      // if table is error because of source data, try to recompute with empty table
      // no need to handle filtering, since empty table doesn't have any rows
      return _get_table_from_empty(table_id)
    } else {
      return res
    }
  }

  function get_entity_diff_or_error(entity_id: EntityId): MaybeError<EntityDiffInfo | null> {
    const res = _get_resource<EntityDiffInfo>(compose_resource_id({type: 'commit', entity_id}))
    if (is_error(res) && entity_has_no_changes_to_display(res)) {
      // This means that the entity had no changes for the given commit
      return null
    } else {
      return res
    }
  }

  /**
   * Creates table object from entity ID and params
   * @function
   * @memberOf Runtime
   * @param entity_id {EntityId}
   * @param [params] {ComputedParams}
   * @return {MaybeError<TableObject>} error object in case of error
   * @throws in case of error
   */
  const get_table = throw_on_error(get_table_or_error)

  function get_permission_table(zone_id: ZoneId, subtype: PermissionTableSubtype): TableObject {
    return get_table(get_permission_table_entity_id(zone_id, subtype))
  }

  function get_headers(): EntityHeaders {
    // Initialization of entity headers can be in progress. Wait for fetch to end.
    if (storage.downloads.entity_headers === 'in-progress') {
      throw create_error('in-progress')
    }
    // Entity_headers fetch should be started during creation of runtime_context.
    if (storage.downloads.entity_headers == null) {
      return throw_error(
        'Invariant violation. Entity headers fetch should have already been started'
      )
    }
    return storage.entity_headers
  }

  function get_permissions_for_entity(entity_id: EntityId): MaybeError<EntityPermission> {
    const permissions_resource_id = compose_resource_id({type: 'permissions', entity_id})
    return _get_resource<EntityPermission>(permissions_resource_id)
  }

  function get_external_data_or_error<T extends ExternalData = ExternalData>(
    cache_key: string,
    fn: ExternalFn<T>,
    loading_data?: T
  ): MaybeError<T> {
    return get_external_resource<T>({
      storage,
      fetch_commits_action: runtime_context.fetch_commits_action,
      fetch_resource_action: runtime_context.fetch_resource_action,
      fetch_external_data_action: runtime_context.fetch_external_data_action,
      mutable_dispatch: runtime_context.mutable_dispatch,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      runtime,
      compute_resource,
      external_resource_fn: fn,
      loading_data,
      cache_key,
    })
  }

  const get_entity_diff = throw_on_error(get_entity_diff_or_error)
  /**
   * Creates table object from table ID, handles error cases that we want from UI to handle nicely
   * @function
   * @memberOf Runtime
   * @param table_id {TableId}
   * @return {TableObject}
   * @throws all unhandled errors
   */
  const get_table_for_ui = throw_on_error(_get_table_or_empty)
  const _get_table = throw_on_error(_get_table_or_error)
  const get_organisation = throw_on_error((entity_id) => get_entity(entity_id, 'organisation'))
  const get_project = throw_on_error((entity_id) => get_entity(entity_id, 'project'))
  const get_external_data = throw_on_error(get_external_data_or_error)

  const runtime: Runtime = {
    // returning full-featured objects with methods included
    get_headers,
    get_entity,
    get_organisation,
    get_project,
    get_permission_table,
    get_table_or_error,
    get_table,
    _get_table_or_error,
    _get_table,
    get_table_for_ui,
    get_permissions_for_entity,
    get_entity_diff,
    get_all,
    get_external_data,
    runtime_context,
    // following methods should be seen as private - we use it for internal purposes and debugging
    // only, but it's convenient if runtime has it
    _get_resource,
    _runtime_id: runtime_id,
    storage,
  }

  return runtime
}

export {create_runtime}
