import React, {FC, useCallback, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import Dialog from '@material-ui/core/Dialog'

import {ReduxState} from '../utils/connect_hocs'
import {CommitOptions} from '../navigation/ConfirmCommitModal'
import ModalContent from './ModalContent'
import {EntityType, TableEntityId} from 'common/types/storage'

type NewTableModalState = {
  type: 'new_table'
  params: {
    project_id: string
  }
}

type NewProjectModalState = {
  type: 'new_project'
  params: {
    organisation_id: string
  }
}

type EditComputedTableModalState = {
  type: 'edit_computed_table'
  params: {table_entity_id: TableEntityId; read_only: boolean}
}

type AddColumnModalState = {
  type: 'add_column'
  params: {
    table_entity_id: TableEntityId
    col_id: string | null
    where: 'left' | 'right'
  }
}

type EditColumnModalState = {
  type: 'edit_column'
  params: {
    table_entity_id: TableEntityId
    col_id: string
  }
}

type ViewColumnModalState = {
  type: 'view_column'
  params: {
    table_entity_id: TableEntityId
    col_id: string
  }
}

type DetailedViewState = {
  type: 'detailed_view'
  params: {}
}

type ConfirmCommitState = {
  type: 'confirm_commit'
  params: {
    on_submit: (options: CommitOptions) => Promise<void>
    entity_ids_with_order_by: TableEntityId[]
    entity_ids_with_frozen_cols: TableEntityId[]
  }
}

type ConfirmDiscardChanges = {
  type: 'confirm_discard'
  params: {
    on_discard: () => void
  }
}

type ConfirmToggleArchived = {
  type: 'confirm_toggle_archived'
  params: {
    on_toggle: () => void
    is_archived: boolean
    entity_type: EntityType
  }
}

type ClosedModalState = {
  type: null
  params: {}
}

type ShowShortcutsModalState = {
  type: 'show_shortcuts'
  params: {}
}

export type ModalState =
  | NewTableModalState
  | NewProjectModalState
  | EditComputedTableModalState
  | AddColumnModalState
  | EditColumnModalState
  | ViewColumnModalState
  | DetailedViewState
  | ConfirmCommitState
  | ClosedModalState
  | ShowShortcutsModalState
  | ConfirmDiscardChanges
  | ConfirmToggleArchived

export type ModalType = Exclude<ModalState['type'], null>
type ModalParams<T extends ModalType> = Extract<ModalState, {type: T}>['params']

export const close_modal = () => ({
  type: 'close_modal',
  payload: {type: null, params: {}},
  reducer: (state: ReduxState, modal: ModalState): ReduxState => ({
    ...state,
    modal,
  }),
})

export const open_modal = <T extends ModalType>(type: T, params: ModalParams<T>) => ({
  type: `open_${type}_modal`,
  payload: {type, params},
  reducer: (state: ReduxState, modal: ModalState): ReduxState => ({
    ...state,
    modal,
  }),
})

const Modals: FC = () => {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const modal_state = useSelector((state: ReduxState) => state.modal)
  const on_request_close = useCallback(() => {
    dispatch(close_modal())
  }, [dispatch])

  // Close modals when the page changes
  useEffect(() => on_request_close(), [on_request_close, pathname])

  return (
    <Dialog
      open={!!modal_state.type}
      onClose={on_request_close}
      scroll="paper"
      maxWidth={false}
      BackdropProps={{
        style: {backgroundColor: 'rgba(187, 187, 187, 0.5)', backdropFilter: 'blur(5px)'},
      }}
      PaperProps={
        modal_state.type != null && ['detailed_view'].includes(modal_state.type)
          ? {style: {flexDirection: 'unset'}}
          : {}
      }
    >
      <ModalContent on_request_close={on_request_close} />
    </Dialog>
  )
}

export default Modals
