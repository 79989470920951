/**
 * View table
 * @module ViewTable
 */
import _ from 'lodash'
import view_table_diff from '../diffs/models/view_table_diff'
import {compose_resource_id} from '../params_utils'
import {create_table, TableData} from './data_table'
import {is_error} from 'common/error'
import {ERROR_EMPTY_TABLE_DATA} from './empty_table_data'
import {ViewTable, TableId} from 'common/types/storage'
import {Runtime} from 'common/create_runtime'

type ViewTableEntity = ViewTable<'entity'>

/**
 * Takes table data from source table and overrides all overridable properties defined
 * in the view table entity. This function is called when runtime tries to compute 'view_table_data'
 * resource in compute_resource.ts
 * @function
 * @param source_data {TableData}
 * @param view_table_entity {ViewTableEntity}
 * @param table_id {TableId}
 * @return {TableData}
 */
const get_overridden_data = (
  source_data: TableData,
  view_table_entity: ViewTableEntity,
  table_id: TableId
): TableData => {
  const {
    type,
    subtype,
    entity_id,
    zone_id,
    parent_id,
    name,
    cols_order,
    frozen_cols,
    order_by,
    source_cols,
  } = view_table_diff.conflict_free(view_table_entity)
  const {cols: additional_cols} = view_table_entity

  const new_cols = {
    ..._.merge({}, source_data.cols, _.pick(source_cols, Object.keys(source_data.cols))),
    ...additional_cols,
  }

  let new_cols_order
  if (!_.isEmpty(cols_order)) {
    // check if there's possible new column in source_table, if so, put it at the end
    const missing_cols = _.difference(source_data.cols_order, _.values(cols_order))
    new_cols_order = [...cols_order, ...missing_cols]
    // remove col_ids from cols_order which are not in cols
    _.remove(new_cols_order, (col_id) => !_.has(new_cols, [col_id as string]))
  } else {
    //if cols_order is not defined, we use cols_order from source and put the new cols at the end
    new_cols_order = [...source_data.cols_order, ..._.keys(additional_cols)]
  }

  return {
    ...source_data,
    table_id,
    entity_id,
    zone_id,
    parent_id,
    name,
    type,
    subtype,
    is_schema_editable: !source_data.is_error_table,
    cols: new_cols,
    ...(!_.isEmpty(order_by) && {order_by}),
    // overridden styles are removed from in-lined data in order to be computed
    styles: _.omitBy(source_data.styles, (styles, col_id) => _.has(source_cols[col_id], 'style')),
    // overridden tooltips are removed from in-lined data in order to be computed
    tooltips: _.omitBy(source_data.tooltips, (tooltips, col_id) =>
      _.has(source_cols[col_id], 'tooltip')
    ),
    ...(frozen_cols != null && {frozen_cols}),
    cols_order: new_cols_order,
    view_entity: view_table_entity,
    was_inlined: false,
  }
}

/**
 * Creates view table object from table ID
 * @function
 * @param runtime {Runtime}
 * @param table_id {TableId}
 * @return {MaybeError<ViewTable>}
 */
const create_view_table = (runtime: Runtime, table_id: TableId) => {
  // Obtain view table data from runtime. These data are already overriden data of source table
  const data = runtime._get_resource<TableData>(
    compose_resource_id({type: 'view_table_data', table_id})
  )

  if (is_error(data)) {
    return data
  }
  return create_table(runtime, data)
}

const create_view_table_from_empty_table = (
  runtime: Runtime,
  table_id: TableId,
  entity: ViewTableEntity
) => {
  return create_table(runtime, get_overridden_data(ERROR_EMPTY_TABLE_DATA, entity, table_id))
}

export {create_view_table, get_overridden_data, create_view_table_from_empty_table}
