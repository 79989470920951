import React, {FC, useMemo} from 'react'
import {Grid} from '@material-ui/core'
import _ from 'lodash'

import {DataTable} from 'common/types/storage'
import {get_permission_tables_in_zone} from 'common/project_utils'

import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import SectionCaption from '../components/SectionCaption'
import {EntityListItem} from './EntityListItem'
import {ROUTES} from '../utils/navigation_utils'

const PermissionTables: FC = () => {
  const {
    storage: {entity_headers},
    resources: {
      organisation_resources: {organisation_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const tables = useMemo(() => {
    return _.sortBy(
      _.map(
        _.filter(
          get_permission_tables_in_zone(entity_headers, organisation_id),
          (table: DataTable<'entity'>) => table.subtype !== 'permission_entities'
        ),
        (table: DataTable<'entity'>) => ({
          table_entity_id: table.entity_id,
          label: table.name,
          subtype: table.subtype,
          type: table.type,
        })
      ),
      [(table) => table.label.toLowerCase()]
    )
  }, [entity_headers, organisation_id])

  return (
    <>
      <SectionCaption header="Permission Tables" />
      <Grid container spacing={2}>
        {tables.map(({table_entity_id, label, subtype, type}) => (
          <EntityListItem
            key={table_entity_id}
            entity_id={table_entity_id}
            parent_id={organisation_id}
            label={label}
            subtype={subtype}
            type={type}
            href={ROUTES.table(table_entity_id)}
          />
        ))}
      </Grid>
    </>
  )
}

export default PermissionTables
