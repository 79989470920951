/**
 * Load module from string containing its source code.
 *
 * This function uses eval. Bear in mind that evaled code has an access to all
 * local and global variables. That's the reason, why this code needs to be
 * isolated into a module.
 *
 * uglifyjs issues: it seems that uglify by default doesn't mangle names visible from code where
 * 'eval' is used. Nice! {@link https://github.com/mishoo/UglifyJS2#mangle-options}
 * @module EvalFunction
 */

import _, * as lodash from 'lodash'
import {is_error, create_error, MaybeError} from './error'
import {throw_error, Fn} from './utils'

/**
 * Behavior on errors:
 * - 'in-progress' and 'circular-dependency' errors are thrown
 * - any other bee error (i.e. with `type`) is returned as `type=depend` error
 * - errors in fn itself are returned as `type=user` error
 * @param fn {Fn}
 * @return {MaybeError<T>}
 */
function error_handling_decorator<K extends any[], T>(fn: Fn<K, T>) {
  return (...args: K): MaybeError<T> => {
    try {
      return fn(...args)
    } catch (error) {
      if (is_error(error)) {
        if (error.type === 'in-progress') {
          throw error
        } else {
          return create_error('depend', {original_error: error})
        }
      } else {
        return create_error('user', _.pick(error, ['message', 'stack']))
      }
    }
  }
}

/**
 * Evaluate function
 * @param what {string} function content
 * @return {Fn<K, MaybeError<T>>}
 */
function eval_function<K extends any[], T>(what: string): Fn<K, MaybeError<T>> {
  if (typeof what !== 'string') {
    throw_error('Argument error: Eval_function takes string argument', 'argument', what)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const module = undefined

  const _ = lodash

  // Presets and plugins come with '@babel/standalone' package, so to get more info about
  // available plugins, require '@babel/standalone' from node console and inspect the object
  try {
    // const babelified =
    //   babel.transform(`var result = ${what}`, {plugins: ['proposal-object-rest-spread']}).code
    // eval(babelified)

    // Evaled code can see all local and global variables here.
    const to_run = `result = ${what}`
    let result: unknown
    eval(to_run)

    // If result has wrong type, error_handling_decorator will handle the error
    return error_handling_decorator(result as Fn<K, T>)
  } catch (error) {
    return () => create_error('parse', _.pick(error, ['message', 'stack']))
  }
}

export {eval_function, error_handling_decorator}
