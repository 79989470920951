import numbro from 'numbro'

export const NUMBRO_DEFAULT_LANGUAGE = 'en-US'
export const SUPPORTED_CURRENCIES: string[] = ['Kč', '$', '£', 'Ft', '€']

// Overriding bad typing of numbro.Format.currencySymbol property. Can be replaced
// by "numbro.Format" type as soon as this bug is fixed in numbro library.
// For more info: https://github.com/BenjaminVanRyseghem/numbro/pull/476
//export type NumbroFormatFixed = Omit<numbro.Format, 'currencySymbol'> & {currencySymbol?: string}
export type NumbroFormatFixed = numbro.Format & {lowPrecision?: boolean}

export type NumberFormatParameters = {
  format: NumbroFormatFixed
  language?: string
}

export type NumberFormatItem = {
  name: string
  parameters: NumberFormatParameters
}

export type NumberFormatId = string

export const NUMBER_FORMAT_ITEMS: Record<NumberFormatId, NumberFormatItem> = {
  percentage: {
    name: 'Percentage',
    parameters: {
      format: {
        output: 'percent',
        mantissa: 2,
      },
    },
  },
  percentage_signed: {
    name: 'Percentage (signed)',
    parameters: {
      format: {
        output: 'percent',
        forceSign: true,
        mantissa: 2,
      },
    },
  },
  integer: {
    name: 'Integer',
    parameters: {
      format: {
        output: 'number',
        mantissa: 0,
      },
    },
  },
  decimal_tenths: {
    name: 'Decimal (tenths)',
    parameters: {
      format: {
        output: 'number',
        mantissa: 1,
        thousandSeparated: true,
      },
    },
  },
  decimal_hundredths: {
    name: 'Decimal (hundredths)',
    parameters: {
      format: {
        output: 'number',
        mantissa: 2,
        thousandSeparated: true,
      },
    },
  },
  curr_usd_prefix: {
    name: 'Currency (USD)',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: '$',
        currencyPosition: 'prefix',
        thousandSeparated: true,
      },
      language: 'en-US',
    },
  },
  curr_eur_prefix: {
    name: 'Currency (EUR) prefix',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: '€',
        currencyPosition: 'prefix',
        thousandSeparated: true,
      },
      language: 'en-US',
    },
  },
  curr_eur_postfix: {
    name: 'Currency (EUR) postfix',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: '€',
        currencyPosition: 'postfix',
        thousandSeparated: true,
      },
      language: 'sk-SK',
    },
  },
  curr_gbp_prefix: {
    name: 'Currency (GBP)',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: '£',
        currencyPosition: 'prefix',
        thousandSeparated: true,
      },
      language: 'en-GB',
    },
  },
  curr_czk_postfix: {
    name: 'Currency (CZK)',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: 'Kč',
        currencyPosition: 'postfix',
        thousandSeparated: true,
      },
      language: 'cs-CZ',
    },
  },
  curr_huf_postfix: {
    name: 'Currency (HUF)',
    parameters: {
      format: {
        output: 'currency',
        mantissa: 2,
        currencySymbol: 'Ft',
        currencyPosition: 'postfix',
        thousandSeparated: true,
      },
      language: 'hu-HU',
    },
  },
}

export const get_number_format = (format_id: NumberFormatId): NumberFormatParameters | undefined =>
  NUMBER_FORMAT_ITEMS[format_id]?.parameters

export const get_number_format_name = (format_id: NumberFormatId): string | undefined =>
  NUMBER_FORMAT_ITEMS[format_id]?.name

export const apply_number_format = (value: number, parameters?: NumberFormatParameters): string => {
  if (!Number.isFinite(value)) return ''

  const language = parameters?.language || NUMBRO_DEFAULT_LANGUAGE
  if (numbro.language() !== language) {
    numbro.setLanguage(language)
  }
  const formatted = numbro(value).format(parameters?.format)
  // numbro 2.3.1 bug adds 'lowPrecision' property to Format object when formatting currency
  // so we have to remove it after each call of .format() (at the moment is this the only
  // place in the code)
  // remove when https://github.com/BenjaminVanRyseghem/numbro/issues/565 is resolved
  delete parameters?.format.lowPrecision
  return formatted
}
