import record_diff from '../base/record_diff'
import value_diff from '../base/value_diff'
import type_diff from './type_diff'
import conflict_free_value_diff from '../base/conflict_free_value_diff'

const col_diff = record_diff({
  type: type_diff,
  name: value_diff(),
  slug: value_diff(),
  description: value_diff(),
  // If true, expect fn in the col spec and not data in the `cells` structure
  computed: value_diff(),
  // Function to calculate columns cells values
  // Contract for fn should be (runtime, {table}) => ({[row_id]: value})
  fn: value_diff(),
  // Function to calculate columns css styles (e.g. red background on some cells).
  // Contract for style should be (runtime, {table}) => ({[row_id]: value})
  style: value_diff(),
  // Function to calculate columns tooltips.
  // Contract for tooltip should be (runtime, {table}) => ({[row_id]: value})
  tooltip: value_diff(),
  width: conflict_free_value_diff(),
  // Column summary is appended computed row, where can be stored (and computed)
  // values for whole colum (like SUM, AVG or any custom user fn).
  // Column summary cell is composed as [LABEL][FN_VALUE] where label is not required
  summary: record_diff({
    // Optional prefix for computed summary value
    // ": " or any other separator isn't added, so it may be part of this value
    label: value_diff(),
    // Function to calculate columns summary (like f.e. SUM)
    // Contract for fn should be (runtime, {table}) => any
    // Result will be stored as it is, but for rendering it will be casted to String,
    // so it's supposed to be human readable after cast.
    fn: value_diff(),
  }),
  // Flag that indicates if column is hidden in UI
  hidden: conflict_free_value_diff(),
  // Flag for default columns for data table (for now last_edited_at and last_edited_by)
  // which are present in each data table
  is_default: value_diff(),
})

export const {
  apply,
  reverse,
  ensure_consistency,
  unapply,
  change_diff,
  create_diff,
  remove_diff,
  is_empty,
  clean_neutral_diffs,
  squash,
  squash2,
  conflict_free,
} = col_diff
export default col_diff
