import React, {useCallback} from 'react'
import {MenuItem, Typography, styled} from '@material-ui/core'
import {ColumnId} from 'common/types/storage'
import CustomSelect from '../components/CustomSelect'

export type ColumnSelectorProps = {
  value: ColumnId
  columns: [ColumnId, string][]
  on_change: (col_id: ColumnId) => void
}

const Wrapper = styled('div')({
  '& > *': {
    width: 120,
  },
})

const Truncate = styled(Typography)({
  maxWidth: 200,
})

const ColumnSelector: React.FC<ColumnSelectorProps> = ({value, columns, on_change}) => {
  const handle_change = useCallback(
    (e) => {
      on_change(e.target.value)
    },
    [on_change]
  )

  return (
    <Wrapper>
      <CustomSelect onChange={handle_change} value={value}>
        {columns.map(([col_id, col_name]) => (
          <MenuItem key={col_id} value={col_id}>
            <Truncate noWrap>{col_name}</Truncate>
          </MenuItem>
        ))}
      </CustomSelect>
    </Wrapper>
  )
}

export default ColumnSelector
