import React, {useState, useCallback} from 'react'
import _ from 'lodash'

import {uuid} from 'common/utils'

import ColSettingsModal from './ColSettingsModal'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'

type AddColumnModalProps = {
  col_id: string | null
  on_request_close: () => void
  where?: 'left' | 'right'
}

const AddColumnModal = (props: AddColumnModalProps) => {
  const {
    storage: {entity_headers, resources},
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {col_id, on_request_close, where} = props
  const {dispatch_storage} = useRuntimeActions()
  const [new_col_id] = useState(uuid())

  const handle_submit = useCallback(
    (new_spec) => {
      if (_.has(table._cols, new_col_id)) {
        dispatch_storage(table._actions.edit_column(new_col_id, new_spec))
      } else {
        dispatch_storage(table._actions.add_column(new_col_id, new_spec, col_id, where))
      }
    },
    [col_id, dispatch_storage, new_col_id, where, table._actions, table._cols]
  )

  return (
    <ColSettingsModal
      table={table}
      edit_flag={'editable'}
      on_submit={handle_submit}
      on_request_close={on_request_close}
      entity_headers={entity_headers}
      resources={resources}
      is_new_column
      col_id={new_col_id}
    />
  )
}

export default AddColumnModal
