import React, {FC, useCallback, useState} from 'react'
import {TextField, Button, styled, DialogContent, DialogActions} from '@material-ui/core'

import {EntityId, TableType} from 'common/types/storage'
import {validate_entity_name} from 'common/project_utils'
import {change_table_description} from 'common/entities/table_actions'

import {useRuntimeActions} from './RuntimeContextProvider'
import {AlwaysDefinedRuntime, useRuntimeSelector} from './utils/connect_hocs'
import {change_entity_name} from 'common/entities/entity_actions'
import {TABLE_TYPES} from 'common/objects/data_table'

type EntityEditorProps = {
  entity_id: EntityId
  end_edit: () => void
}

const StyledForm = styled('form')({
  width: '20vw',
  display: 'flex',
  flexDirection: 'column',
  height: 'fill-available',
  overflowY: 'auto',
})

const FieldContainer = styled('div')({
  'padding-bottom': '15px',
})

const EntitySettingsEditor: FC<EntityEditorProps> = ({entity_id, end_edit}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {name: current_name, description: current_description, parent_id} = entity_headers[
    entity_id
  ]
  const [name, set_name] = useState(current_name)
  const [description, set_description] = useState(current_description ?? '')

  const on_name_change = useCallback((e) => set_name(e.target.value), [])
  const on_description_change = useCallback((e) => set_description(e.target.value ?? ''), [])
  const {dispatch_storage} = useRuntimeActions()
  const is_table = TABLE_TYPES.includes(entity_headers[entity_id].type as TableType)

  const save = useCallback(() => {
    if (current_name !== name) {
      const error_message = validate_entity_name(
        entity_headers,
        entity_headers[entity_id].type,
        parent_id,
        name
      )

      if (error_message) {
        // eslint-disable-next-line no-alert
        alert(error_message)
        return
      }

      dispatch_storage(change_entity_name(entity_headers[entity_id], name))
    }
    if (is_table && current_description !== description) {
      dispatch_storage(change_table_description(entity_headers[entity_id], description))
    }
    end_edit()
  }, [
    dispatch_storage,
    end_edit,
    current_name,
    current_description,
    entity_headers,
    parent_id,
    entity_id,
    name,
    description,
    is_table,
  ])

  const handle_key_down = useCallback(
    (e) => {
      e.stopPropagation()
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        save()
      }
    },
    [save]
  )

  return (
    <StyledForm onKeyDown={handle_key_down}>
      <DialogContent dividers>
        <FieldContainer>
          <TextField
            fullWidth
            placeholder="Name"
            value={name}
            onChange={on_name_change}
            variant="outlined"
            autoFocus
            label="Name"
          />
        </FieldContainer>
        {is_table && (
          <FieldContainer>
            <TextField
              fullWidth
              placeholder="Description"
              value={description}
              onChange={on_description_change}
              variant="outlined"
              label="Description"
              multiline
            />
          </FieldContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="button" onClick={save}>
          Save
        </Button>
      </DialogActions>
    </StyledForm>
  )
}

export default EntitySettingsEditor
