import {useState} from 'react'
import {fetch_json} from 'common/networking'
import {with_retry} from '../utils/http_utils'
import {is_error} from 'common/error'

const upload_file = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('upload_type', 'attachment')
  return fetch_json('/upload', {
    method: 'POST',
    headers: {
      Accept: 'application/json, */*',
    },
    body: formData,
  })
}

export type UploadFileProps = {
  on_error: (file: File, message: string) => void
  on_success: (file: File, url: string) => void
}

export const useUploadFile = ({
  on_success,
  on_error,
}: UploadFileProps): [(file: File) => void, boolean] => {
  const [loading, set_loading] = useState<boolean>(false)
  const start_upload = async (file: File) => {
    const base_error = 'Error while uploading attachment'
    try {
      set_loading(true)
      const response = await with_retry(upload_file)(file)
      if (is_error(response)) {
        on_error(file, response.message ?? base_error)
        return
      }
      if (response.url) {
        on_success(file, response.url)
      }
    } catch (e) {
      on_error(file, base_error)
    } finally {
      set_loading(false)
    }
  }

  const pre_upload = (file: File) => {
    if (loading) {
      return
    }
    start_upload(file)
  }
  return [pre_upload, loading]
}
