import React, {useCallback} from 'react'
import _ from 'lodash'

import ColSettingsModal from './ColSettingsModal'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'

type EditColumnModalProps = {
  col_id: string
  on_request_close: () => void
}

const EditColumnModal = (props: EditColumnModalProps) => {
  const {
    storage: {entity_headers, resources},
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {col_id, on_request_close} = props
  const {dispatch_storage} = useRuntimeActions()

  const edit_flag = table._cols_edit_flags[col_id]
  const handle_submit = useCallback(
    (new_spec) => {
      if (_.has(table._cols, col_id)) {
        dispatch_storage(table._actions.edit_column(col_id, new_spec))
      } else {
        console.error('Invalid column id')
      }
    },
    [col_id, dispatch_storage, table]
  )

  return (
    <ColSettingsModal
      table={table}
      edit_flag={edit_flag}
      on_submit={handle_submit}
      on_request_close={on_request_close}
      entity_headers={entity_headers}
      resources={resources}
      col_id={col_id}
    />
  )
}

export default EditColumnModal
