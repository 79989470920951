import {is_error} from '../error'
import {compose_resource_id} from '../params_utils'
import computed_table_diff from '../diffs/models/computed_table_diff'
import {ERROR_EMPTY_TABLE_DATA} from 'common/objects/empty_table_data'
import {create_table, TableData} from 'common/objects/data_table'
import {ComputedTable, TableId} from 'common/types/storage'
import {Runtime} from 'common/create_runtime'

/**
 * Computed table object
 * @module ComputedTableObject
 * @see {@tutorial table_object}
 */
/**
 * Get computed table data
 * @param computed_data {TableData}
 * @param entity {ComputedTable<'entity'>}
 * @param table_id {TableId}
 * @return {TableData}
 */
const get_computed_table_data = (
  computed_data: TableData,
  entity: ComputedTable<'entity'>,
  table_id: TableId
): TableData => {
  const {
    entity_id,
    zone_id,
    parent_id,
    type,
    subtype,
    name,
    fn,
    log_computation,
    params,
  } = computed_table_diff.conflict_free(entity)
  return {
    ...computed_data,
    is_cells_editable: false,
    is_schema_editable: false,
    table_id,
    entity_id,
    zone_id,
    parent_id,
    type,
    subtype,
    name,
    fn,
    params,
    computed_table_entity: entity,
    log_computation,
  }
}

/**
 * Create table object from table ID
 * @param runtime {Runtime}
 * @param table_id {TableId}
 * @return {TableObject}
 */
function create_computed_table(runtime: Runtime, table_id: TableId) {
  const data = runtime._get_resource<TableData>(
    compose_resource_id({
      type: 'computed_table_data',
      table_id,
    })
  )
  if (is_error(data)) {
    return data
  }
  return create_table(runtime, data)
}

/**
 * Create computed table from empty table
 * @param runtime {Runtime}
 * @param table_id {TableId}
 * @param entity {ComputedTable<'entity'>}
 * @return {TableObject}
 */
function create_computed_table_from_empty_table(
  runtime: Runtime,
  table_id: TableId,
  entity: ComputedTable<'entity'>
) {
  return create_table(runtime, get_computed_table_data(ERROR_EMPTY_TABLE_DATA, entity, table_id))
}

export {create_computed_table, create_computed_table_from_empty_table, get_computed_table_data}
