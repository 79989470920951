import React, {FC, useState, useReducer, useMemo, useEffect} from 'react'
import {Link, useRouteMatch, match} from 'react-router-dom'
import _ from 'lodash'
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Collapse,
  List,
  IconButton,
  ListItemText,
  Badge,
} from '@material-ui/core'
import {ExpandLess, ExpandMore} from '@material-ui/icons'
import {styled} from '@material-ui/core/styles'
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone'
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone'
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone'
import FindInPageTwoToneIcon from '@material-ui/icons/FindInPageTwoTone'

import {TABLE_TYPES} from 'common/objects/data_table'
import {
  BasicSchema,
  EntityId,
  EntityType,
  OrganisationId,
  ProjectId,
  TableEntityId,
  EntityHeaders,
} from 'common/types/storage'
import {is_permission_table_subtype} from 'common/permission/permission_utils'

import {ROUTES} from '../utils/navigation_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {BrowserPageRouteProps} from './BrowserPage'

const IndentedList = styled(List)(({theme}) => ({
  marginLeft: theme.spacing(2),
}))

const ListItemTextWrap = styled(ListItemText)(({theme}) => ({
  maxWidth: `calc(100% - ${theme.spacing(8)}px)`,
  overflowWrap: 'break-word',
}))

export const icons = {
  organisation: BusinessTwoToneIcon,
  project: FolderOpenTwoToneIcon,
  data_table: DescriptionTwoToneIcon,
  computed_table: AccountTreeTwoToneIcon,
  view_table: FindInPageTwoToneIcon,
}

type Color = 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'

export const icon_color: {[key: string]: Color} = {
  organisation: 'action',
  project: 'secondary',
  data_table: 'primary',
  computed_table: 'primary',
  view_table: 'primary',
}

type TablePageRouteProps = {
  base_table_entity_id: TableEntityId
  view_table_entity_id?: TableEntityId
}

type FullPath = {
  organisation_id?: OrganisationId
  project_id?: ProjectId
  base_table_entity_id?: TableEntityId
  view_table_entity_id?: TableEntityId
}

type TreeItemProps = {
  label: string
  type: EntityType
  entities: Record<EntityId, BasicSchema<EntityType, 'entity'>>
  id: EntityId
}

const get_full_path = (
  organisation_match: match<BrowserPageRouteProps> | null,
  project_match: match<BrowserPageRouteProps> | null,
  table_match: match<TablePageRouteProps> | null,
  entity_headers: EntityHeaders
): FullPath => {
  if (organisation_match || project_match) {
    const organisation_id =
      project_match?.params.organisation_id || organisation_match?.params.organisation_id
    const project_id = project_match?.params.project_id
    return {organisation_id, project_id}
  }

  if (!table_match) {
    return {}
  }

  const base_table_entity_id = table_match.params.base_table_entity_id
  const view_table_entity_id = table_match.params.view_table_entity_id

  let organisation_id: EntityId | undefined
  let project_id: EntityId | undefined
  if (is_permission_table_subtype(entity_headers[base_table_entity_id]?.subtype)) {
    organisation_id = entity_headers[base_table_entity_id]?.zone_id
  } else {
    project_id = entity_headers[base_table_entity_id]?.zone_id
    organisation_id = project_id && entity_headers[project_id]?.parent_id
  }
  return {organisation_id, project_id, base_table_entity_id, view_table_entity_id}
}

type ExpanderAction = 'expand' | 'collapse' | 'toggle'

function expander_reducer(state: boolean, action: ExpanderAction) {
  switch (action) {
    case 'expand':
      return true
    case 'collapse':
      return false
    case 'toggle':
      return !state
    default:
      throw new Error(`${action} is not a valid expander action.`)
  }
}

const TreeItem: FC<TreeItemProps> = ({label, type, entities, id}: TreeItemProps) => {
  const [expanded, expander_dispatch] = useReducer(expander_reducer, false)
  const [selected, set_selected] = useState(false)

  const children = useMemo(
    () =>
      Object.values(
        _.sortBy(
          _.pickBy(entities, ({parent_id, entity_id}) => parent_id === id && entity_id !== id),
          [({type}) => (type === 'project' ? -1 : 0), ({name}) => name.toLocaleLowerCase()]
        )
      ),
    [entities, id]
  )

  const {
    storage: {entity_headers, multidiff},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const parent_id = entities[id].parent_id

  const project_match = useRouteMatch<BrowserPageRouteProps>({
    path: ROUTES.project(':organisation_id', ':project_id'),
  })
  const organisation_match = useRouteMatch<BrowserPageRouteProps>({
    path: ROUTES.organisation(':organisation_id'),
  })
  const table_match = useRouteMatch<TablePageRouteProps>({
    path: ROUTES.table(':base_table_entity_id', ':view_table_entity_id'),
  })

  const {
    organisation_id,
    project_id,
    base_table_entity_id,
    view_table_entity_id: table_entity_id,
  } = useMemo(() => get_full_path(organisation_match, project_match, table_match, entity_headers), [
    organisation_match,
    project_match,
    table_match,
    entity_headers,
  ])

  useEffect(() => {
    const should_expand = [organisation_id, project_id, base_table_entity_id].includes(id)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    _.isEmpty(children) || (should_expand && expander_dispatch('expand'))
  }, [id, children, organisation_id, project_id, base_table_entity_id])

  useEffect(() => {
    const is_selected = table_entity_id
      ? table_entity_id === id
      : project_id
      ? project_id === id
      : organisation_id === id
    set_selected(is_selected)
  }, [id, organisation_id, project_id, table_entity_id])

  const href = _.includes(TABLE_TYPES, type)
    ? type === 'view_table'
      ? ROUTES.table(parent_id, id)
      : ROUTES.table(id, id)
    : ROUTES[type](parent_id, id)

  const Icon = icons[type]

  const has_unsaved_changes =
    !_.isEmpty(multidiff[id]) ||
    (type.includes('table') && multidiff[parent_id] && !_.isEmpty(multidiff[parent_id][id]))

  return (
    <>
      <ListItem button to={href} component={Link} selected={selected}>
        <ListItemIcon>
          <Badge overlap="rectangular" color="error" variant="dot" invisible={!has_unsaved_changes}>
            <Icon color={icon_color[type]} />
          </Badge>
        </ListItemIcon>
        <ListItemTextWrap>
          {label} {!!entity_headers[id].archived && '(archived)'}
        </ListItemTextWrap>
        {children.length > 0 && (
          <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => expander_dispatch('toggle')}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={expanded} unmountOnExit>
        <IndentedList>
          {children.map(({entity_id, name, type}) => (
            <TreeItem id={entity_id} key={entity_id} label={name} entities={entities} type={type} />
          ))}
        </IndentedList>
      </Collapse>
    </>
  )
}

export default TreeItem
