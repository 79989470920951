import React, {FC} from 'react'
import {styled} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

type FrozenColsMarkerProps = {
  left: number
  height: number
  type: 'target' | 'marker'
  on_context_menu: (e: React.MouseEvent) => void
}

const Marker = styled('div')({
  top: '2px',
  position: 'absolute',
  width: '5px',
  userSelect: 'none',
  backgroundColor: '#c0c0c0',
})

const useStyles = makeStyles({
  root: (props: {left; height; type}) => ({
    left: props.left,
    height: props.height,
    opacity: props.type === 'marker' ? 0.5 : 1,
  }),
})

const FrozenColsMarker: FC<FrozenColsMarkerProps> = ({left, height, type, on_context_menu}) => {
  const classes = useStyles({left, height, type})

  return <Marker className={classes.root} onContextMenu={on_context_menu} />
}

export default FrozenColsMarker
