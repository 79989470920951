import React, {FC, useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  NativeSelect,
  styled,
} from '@material-ui/core'

import styles from './NewEntityModal.module.css'
import project_actions from 'common/entities/project_actions'
import {uuid} from 'common/utils'
import _ from 'lodash'
import {useRuntimeActions} from './RuntimeContextProvider'
import {create_new_computed_table, create_new_data_table} from 'common/entities/table_actions'
import {ROUTES} from './utils/navigation_utils'
import {ComputedTableFnEditor} from './ComputedTable/ComputedTableFnEditor'
import {validate_entity_name} from 'common/project_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from './utils/connect_hocs'
import {EntityType} from 'common/types/storage'

type CommonProps = {
  on_request_close: () => void
  organisation_id: string
}

type NewTableProps = CommonProps & {
  new_entity_type: 'table'
  project_id: string
}

type NewProjectProps = CommonProps & {
  new_entity_type: 'project'
  project_id?: string
}

type NewEntityModalProps = NewTableProps | NewProjectProps

const SecondaryContainer = styled('div')(({theme}) => ({marginTop: theme.spacing(2)}))

const NewEntityModal: FC<NewEntityModalProps> = ({
  new_entity_type,
  on_request_close,
  organisation_id,
  project_id: _project_id,
}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const history = useHistory()
  const is_new_project = new_entity_type === 'project'
  const is_new_table = new_entity_type === 'table'
  const project_id = useMemo(() => _project_id || uuid(), [_project_id])
  const {dispatch_storage} = useRuntimeActions()

  const [project_name, set_project_name] = useState('')
  const [table_name, set_table_name] = useState('')
  const [table_description, set_table_description] = useState('')
  const [table_type, set_table_type] = useState('data_table')
  const [table_fn, set_table_fn] = useState('')
  const [log_computation, set_log_computation] = useState(false)

  const on_submit = useCallback(() => {
    const resolved_table_name = _.trim(table_name)

    let error_message = validate_entity_name(
      entity_headers,
      table_type as EntityType,
      project_id,
      resolved_table_name
    )
    if (!error_message) {
      if (is_new_project) {
        if (!project_name) {
          error_message = 'Project name can not be empty'
        } else if (
          _.some(
            entity_headers,
            ({name, parent_id, archived}) =>
              parent_id === organisation_id && name === project_name && !archived
          )
        ) {
          error_message = `Project ${project_name} already exists in current organisation`
        }
      }
      if (table_type === 'computed_table' && !table_fn) {
        error_message = 'Table function has to be defined for computed table'
      }
    }

    if (error_message) {
      // eslint-disable-next-line no-alert
      alert(error_message)
      return
    }

    if (is_new_project) {
      dispatch_storage(
        project_actions.create_new_project(project_id, project_name, organisation_id)
      )
    }
    const table_entity_id = uuid()
    switch (table_type) {
      case 'data_table':
        dispatch_storage(
          create_new_data_table(table_entity_id, project_id, {
            name: resolved_table_name,
            description: table_description,
          })
        )
        break
      case 'computed_table':
        dispatch_storage(
          create_new_computed_table(table_entity_id, project_id, {
            name: resolved_table_name,
            params: {},
            fn: table_fn,
            log_computation,
          })
        )
        break
      default:
        break
    }

    history.push(ROUTES.table(table_entity_id, table_entity_id))
    on_request_close()
  }, [
    history,
    entity_headers,
    organisation_id,
    project_id,
    is_new_project,
    project_name,
    table_description,
    table_name,
    table_type,
    table_fn,
    log_computation,
    dispatch_storage,
    on_request_close,
  ])

  const update_project_name = useCallback((e) => set_project_name(e.target.value), [])
  const update_table_name = useCallback((e) => set_table_name(e.target.value), [])
  const update_table_description = useCallback((e) => set_table_description(e.target.value), [])
  const update_table_type = useCallback((e) => set_table_type(e.target.value), [])
  const log_change_handler = useCallback((log) => set_log_computation(log), [])

  const current_organisation_name = entity_headers[organisation_id].name

  return (
    <>
      <DialogTitle disableTypography>
        {is_new_project && (
          <>
            <h3>
              Create new project in organisation
              <span className={styles.headingHighlight}>{current_organisation_name}</span>
            </h3>
            <div>
              <label htmlFor="project_name_input">Project name: </label>
              <input
                id="project_name_input"
                type="string"
                placeholder="Enter project name"
                className={styles.entityNameInput}
                value={project_name}
                onChange={update_project_name}
              />
            </div>
            <div>Currently empty projects can not be created. Please create also a table.</div>
            <h3>Create table</h3>
          </>
        )}
        {is_new_table && (
          <h3>
            Create table in organisation
            <span className={styles.headingHighlight}>{current_organisation_name}</span> in project
            <span className={styles.headingHighlight}>{entity_headers[project_id].name}</span>
          </h3>
        )}
      </DialogTitle>
      <DialogContent>
        <label htmlFor="table_name_input">Table name: </label>
        <input
          id="table_name_input"
          type="string"
          placeholder="Enter table name"
          className={styles.entityNameInput}
          value={table_name}
          onChange={update_table_name}
        />

        <label htmlFor="table_description_input">Description: </label>
        <input
          id="table_description_input"
          type="string"
          placeholder="table description"
          className={styles.entityDescriptionInput}
          value={table_description}
          onChange={update_table_description}
        />

        <label htmlFor="table_type_select">Table type: </label>
        <NativeSelect value={table_type} onChange={update_table_type} id="table_type_select">
          <option value="data_table">Data table</option>
          <option value="computed_table">Computed table</option>
        </NativeSelect>

        {table_type === 'computed_table' && (
          <SecondaryContainer>
            <ComputedTableFnEditor
              fn={table_fn}
              set_fn={set_table_fn}
              read_only={false}
              set_log_computation={log_change_handler}
              log_computation={log_computation}
            />
          </SecondaryContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={on_submit} color="primary">
          Create
        </Button>
        <Button onClick={on_request_close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default NewEntityModal
