import value_diff from '../base/value_diff'
import record_diff from '../base/record_diff'
import map_diff from '../base/map_diff'
import constant_diff from '../base/constant_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import param_diff from './param_diff'

import {
  type_decorate_apply,
  type_decorate_reverse,
  type_decorate_ensure_consistency,
  type_decorate_unapply,
  type_decorate_squash2,
  type_decorate_change_diff,
  type_decorate_clean_neutral_diffs,
} from './entity_type_helpers'
import {create_squash} from '../base/create_squash'

const {
  conflict_free,
  is_conflict,
  get_conflicts,
  apply: _apply,
  reverse: _reverse,
  ensure_consistency: _ensure_consistency,
  unapply: _unapply,
  squash2: _squash2,
  change_diff: _change_diff,
  clean_neutral_diffs: _clean_neutral_diffs,
} = record_diff({
  type: constant_diff(),
  zone_id: constant_diff(),
  entity_id: constant_diff(),
  parent_id: constant_diff(),
  name: conflict_free_value_diff(),
  description: conflict_free_value_diff(),
  archived: conflict_free_value_diff(),
  params: map_diff(param_diff, false, false),
  fn: value_diff(),
  log_computation: value_diff(),
})

const apply = type_decorate_apply('computed_table', _apply)
const reverse = type_decorate_reverse('computed_table', _reverse)
const ensure_consistency = type_decorate_ensure_consistency('computed_table', _ensure_consistency)
const unapply = type_decorate_unapply('computed_table', _unapply)
const squash2 = type_decorate_squash2('computed_table', _squash2)
const change_diff = type_decorate_change_diff('computed_table', _change_diff)
const clean_neutral_diffs = type_decorate_clean_neutral_diffs(
  'computed_table',
  _clean_neutral_diffs
)

export default {
  conflict_free,
  is_conflict,
  get_conflicts,
  apply,
  reverse,
  ensure_consistency,
  unapply,
  change_diff,
  squash: create_squash(squash2),
  create_diff: (entity) => change_diff({type: 'computed_table'}, entity),
  clean_neutral_diffs,
}
