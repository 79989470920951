import React, {FC} from 'react'
import {styled, Box} from '@material-ui/core'
import SummaryCell from './SummaryCell'
import {style as GridCanvasStyle} from '../GridCanvas/style'
import {DEFAULT_ROW_NUM_COL_WIDTH} from '../table_data_helpers'

type SummaryRowProps = {
  table: any
  columns_widths: number[]
  frozen_offset: [number, number]
  scroll_offset: [number, number]
}

const RowContainer = styled('div')(({theme: {palette: {grid}, typography: {gridCell}}}) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  font: gridCell.normal,
  color: grid.cell.defaultText,
  background: grid.background,
  borderLeft: `2px solid ${grid.outerBorder}`,
  borderRight: `2px solid ${grid.outerBorder}`,
  display: 'flex',
  flexDirection: 'row',
}))

const FrozenCells = styled('div')(({theme: {palette: {grid}}}) => ({
  background: GridCanvasStyle.color.frozen,
  borderRight: `1px solid ${grid.border}`,
  display: 'flex',
  flexDirection: 'row',
  paddingRight: `${GridCanvasStyle.width.frozen}px`,
  zIndex: 2,
  paddingLeft: DEFAULT_ROW_NUM_COL_WIDTH,
}))

const RestCells = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  zIndex: 1,
})

const SummaryRow: FC<SummaryRowProps> = (props) => {
  const {table, columns_widths, frozen_offset, scroll_offset} = props

  const cells = columns_widths.map((width, index) => (
    <SummaryCell key={index} table={table} col_index={index} width={width} />
  ))
  // The SummaryRow does not display a column for the line numbers, but the passed
  // in frozen_offset accounts for it, so we need to remove it from the offset
  const frozen_cells = cells.slice(0, frozen_offset[0] - 1)
  const rest_cells = cells.slice(frozen_offset[0] - 1)

  return (
    <RowContainer>
      <FrozenCells>{frozen_cells}</FrozenCells>
      <RestCells left={-scroll_offset[0]}>{rest_cells}</RestCells>
    </RowContainer>
  )
}

export default SummaryRow
