import React, {FC, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {Button, Grid} from '@material-ui/core'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'
import {SortableContainer, SortableElement, SortEndHandler} from 'react-sortable-hoc'

import {get_tables_in_project, consolidate_tables_order} from 'common/project_utils'
import {EntityHeader, TableEntityId} from 'common/types/storage'
import project_actions from 'common/entities/project_actions'

import {open_modal} from '../Modals'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import SectionCaption from '../components/SectionCaption'
import {has_create_permissions} from 'common/permission/permission_utils'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {move_in_array} from 'common/utils'
import {EntityListItem} from './EntityListItem'
import {ROUTES} from '../utils/navigation_utils'
import {is_entity_or_parent_archived} from 'common/archived_utils'

const SortableGrid = SortableContainer(Grid)
const SortableTableListItem = SortableElement(EntityListItem)

const ProjectTables: FC = () => {
  const {
    runtime,
    storage: {entity_headers, include_archived},
    resources: {
      project_resources: {
        project_id,
        project: {tables_order},
      },
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const {dispatch_storage} = useRuntimeActions()
  const dispatch = useDispatch()

  const handle_create_table = useCallback(() => {
    dispatch(open_modal('new_table', {project_id}))
  }, [dispatch, project_id])

  const table_headers = useMemo(() => {
    return get_tables_in_project(entity_headers, project_id, include_archived)
  }, [entity_headers, project_id, include_archived])

  const consolidated_tables_order: ReadonlyArray<TableEntityId> = useMemo(() => {
    return consolidate_tables_order(table_headers, tables_order)
  }, [table_headers, tables_order])

  const on_sort_end: SortEndHandler = useCallback(
    ({oldIndex: old_index, newIndex: new_index}) => {
      if (old_index !== new_index) {
        dispatch_storage(
          project_actions.change_project_tables_order(
            entity_headers[project_id] as EntityHeader<'project'>,
            tables_order,
            move_in_array(consolidated_tables_order, old_index, new_index)
          )
        )
      }
    },
    [dispatch_storage, entity_headers, project_id, tables_order, consolidated_tables_order]
  )

  const can_create = has_create_permissions(runtime, project_id)

  return (
    <>
      <SectionCaption
        header="Tables"
        action={
          can_create &&
          !is_entity_or_parent_archived(project_id, entity_headers) && (
            <Button startIcon={<AddTwoToneIcon />} onClick={handle_create_table} color="primary">
              Create a table
            </Button>
          )
        }
      />
      <SortableGrid
        container
        spacing={2}
        /* sortable props: */
        axis="xy"
        lockToContainerEdges
        distance={2}
        onSortEnd={on_sort_end}
      >
        {consolidated_tables_order.map((entity_id, i) => (
          <SortableTableListItem
            key={entity_id}
            entity_id={entity_id}
            parent_id={project_id}
            label={table_headers[entity_id].name}
            description={table_headers[entity_id].description}
            subtype={table_headers[entity_id].subtype}
            type={table_headers[entity_id].type}
            href={ROUTES.table(entity_id)}
            /* sortable props: */
            index={i}
          />
        ))}
      </SortableGrid>
    </>
  )
}

export default ProjectTables
