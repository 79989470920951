import React, {FC, useCallback, useMemo, useState} from 'react'
import _ from 'lodash'
import {CellRawValue} from 'common/types/storage'
import {remove_multiple_whitespace, uuid} from 'common/utils'
import {raw_value_to_multiple_ids, raw_value_to_single_id} from './editor_utils'
import {Checkbox, createStyles, Paper, PaperProps, TextField, Theme} from '@material-ui/core'
import {Autocomplete, AutocompleteRenderInputParams, createFilterOptions} from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {makeStyles} from '@material-ui/core/styles'
import VirtualizedListbox from './VirtualizedListbox'

type ListboxComponentType = React.ComponentType<React.HTMLAttributes<HTMLElement>>

export type SelectOptionId = string

export type SelectOption = {
  id: SelectOptionId
  label: string
}

type NewOptionInput = {
  is_custom_new_value: true
  name: string
}

type AutocompleteValue = SelectOptionId | NewOptionInput

function is_custom_new_value(value: AutocompleteValue): value is NewOptionInput {
  return _.get(value, 'is_custom_new_value') === true
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > div > div': {
        paddingTop: '3px !important',
        paddingBottom: '3px !important',
      },
      '& > div > div > fieldset': {
        borderColor: theme.palette.greyPalette[100],
        borderRadius: 4,
      },
      '& > div > div:hover': {
        '& > fieldset': {
          borderColor: `${theme.palette.greyPalette[400]} !important`,
        },
      },
    },
    input: {
      fontSize: 13,
    },
    option: {
      margin: 0,
      padding: theme.spacing(0.5, 1),
      font: theme.typography.gridCell.normal,
    },
    listbox: {
      padding: 0,
    },
    paper: (props: {is_filter_value_editor?}) => ({
      [props.is_filter_value_editor]: {
        width: '200px',
      },
    }),
  })
)

const icon = <CheckBoxOutlineBlankIcon fontSize="inherit" />
const checkedIcon = <CheckBoxIcon fontSize="inherit" />
const useCheckboxStyles = makeStyles(
  createStyles({
    root: {
      padding: 0,
      margin: '0 4px 0 -4px',
    },
  })
)

const CheckboxMultiOption: FC<{
  selected: boolean
  label: string
}> = ({selected, label}) => {
  const checkbox_classes = useCheckboxStyles()

  return (
    <>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        classes={checkbox_classes}
        checked={selected}
      />
      {label}
    </>
  )
}

const ElevatedPaper = (props: PaperProps) => <Paper {...props} elevation={4} />

// sort options first by if option is selected, then by label alphabetically
const sort_original_options = (
  options: SelectOption[],
  selected: SelectOptionId[]
): SelectOption[] =>
  options.sort((a, b) =>
    selected.includes(a.id) === selected.includes(b.id)
      ? a.label.toLowerCase() > b.label.toLowerCase()
        ? 1
        : -1
      : selected.includes(a.id)
      ? -1
      : 1
  )

const concat_added_options = (
  options: SelectOption[],
  sorted_options: SelectOption[]
): SelectOption[] => {
  const added_options = _.differenceBy(options, sorted_options, 'id')
  return added_options.concat(sorted_options)
}

const filter = createFilterOptions<AutocompleteValue>()

function get_options_by_id(options: SelectOption[]): Record<SelectOptionId, SelectOption> {
  return _.transform(
    options,
    (acc, option) => {
      acc[option.id] = option
    },
    {}
  )
}

const create_new_option = (option_name: string): SelectOption => {
  return {id: uuid(), label: option_name}
}

export type SelectEditorProps = {
  clear_search_on_select?: boolean
  can_add_new_option?: boolean
  open: boolean | 'onFocus'
  options: SelectOption[]
  autofocus?: boolean
  show_option_chips?: boolean
  end_edit: () => void
  multi?: boolean
  value: CellRawValue
  set_value: (value: CellRawValue, new_options?: SelectOption[]) => void
  error?: boolean
  is_filter_value_editor?: boolean
}

const SelectEditor: FC<SelectEditorProps> = (props) => {
  const {
    clear_search_on_select = false,
    can_add_new_option = false,
    open,
    options,
    autofocus = false,
    show_option_chips = false,
    end_edit,
    multi = false,
    value,
    set_value,
    error,
  } = props

  const classes = useStyles(props)

  const selected_options = useMemo(() => raw_value_to_multiple_ids(value), [value])

  const fixed_value = useMemo(() => {
    if (multi) {
      return raw_value_to_multiple_ids(value)
    } else {
      return raw_value_to_single_id(value)
    }
  }, [multi, value])

  // Sorting is only done when editor is mounted or opened
  const [sorted_original_options, set_original_options] = useState(() =>
    sort_original_options(options, selected_options)
  )

  // Adding the missing options
  const sorted_options = useMemo(() => concat_added_options(options, sorted_original_options), [
    options,
    sorted_original_options,
  ])

  const options_by_id = useMemo(() => get_options_by_id(sorted_options), [sorted_options])
  const options_ids = useMemo(() => sorted_options.map((option) => option.id), [sorted_options])

  // Autocomplete search input value is controlled
  const [input_value, set_input_value] = useState('')

  // Used to detect if the selector is opened
  const [opened, set_opened] = useState(open === true)

  // Called when the selector becomes opened
  // This is NOT called when the selector is mounted with 'open' prop
  const on_selector_opened = useCallback(() => {
    set_original_options(sort_original_options(options, selected_options))
    set_opened(true)
  }, [options, selected_options])

  // Called when the selector becomes closed
  // This is called once when the selector is blurred or a single option is selected
  const on_selector_closed = useCallback(() => {
    set_input_value('')
    set_opened(false)
    end_edit()
  }, [end_edit])

  const on_key_down = useCallback(
    (e) => {
      if (e.key === 'Tab') {
        on_selector_closed()
      } else {
        // block global bindings like Ctrl+Z (undo) when editor is opened, except Tab
        e.stopPropagation()
      }
    },
    [on_selector_closed]
  )

  const is_valid_new_option = useCallback(
    (option_name: string) => {
      return (
        option_name !== '' &&
        option_name.search(';') === -1 &&
        options.every((o) => o.label !== option_name)
      )
    },
    [options]
  )

  const on_change = useCallback(
    (e, value, reason: string) => {
      if (multi) {
        const new_option_value = value.find((o) => is_custom_new_value(o))
        const existing_options = value.filter((o) => !is_custom_new_value(o))

        if (new_option_value) {
          const new_option = create_new_option(new_option_value.name)
          set_value([...existing_options, new_option.id], [new_option])
        } else {
          set_value(existing_options)
        }
      } else {
        if (value && is_custom_new_value(value)) {
          const new_option = create_new_option(value.name)
          set_value(new_option.id, [new_option])
        } else {
          set_value(value)
        }
      }

      // Reset search input when an option is selected, if requested
      if (reason === 'select-option' && clear_search_on_select) {
        set_input_value('')
      }
    },
    [multi, set_value, clear_search_on_select]
  )

  const on_input_change = useCallback((e, value: string, reason: string) => {
    // Ignore reset, which happens on (de)select and makes filter to be erased
    if (reason !== 'reset') {
      set_input_value(value)
    }
  }, [])

  const get_option_label = useCallback(
    (value: AutocompleteValue) => {
      if (is_custom_new_value(value)) {
        return `Add "${value.name}"`
      } else {
        return options_by_id[value].label
      }
    },
    [options_by_id]
  )

  const filter_options = useCallback(
    (options, state): AutocompleteValue[] => {
      const option_name = remove_multiple_whitespace(state.inputValue)
      const filtered = filter(options, {...state, inputValue: option_name})

      // Suggest the creation of a new value
      if (can_add_new_option && is_valid_new_option(option_name)) {
        filtered.push({
          is_custom_new_value: true,
          name: option_name,
        })
      }

      return filtered
    },
    [can_add_new_option, is_valid_new_option]
  )

  const render_input = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          autoFocus={autofocus}
          InputProps={
            multi && show_option_chips
              ? params.InputProps
              : {
                  ...params.InputProps,
                  // hide the selected option chips
                  startAdornment: undefined,
                }
          }
          inputProps={
            multi || opened || typeof fixed_value !== 'string'
              ? params.inputProps
              : {
                  ...params.inputProps,
                  // show the selected value in single mode if the selector is not opened
                  value: get_option_label(fixed_value),
                }
          }
          error={error}
        />
      )
    },
    [get_option_label, multi, fixed_value, opened, autofocus, show_option_chips, error]
  )

  const render_option = useMemo(() => {
    if (multi) {
      return (option: AutocompleteValue, {selected}: {selected: boolean}) => (
        <CheckboxMultiOption selected={selected} label={get_option_label(option)} />
      )
    } else {
      return (option: AutocompleteValue) => get_option_label(option)
    }
  }, [get_option_label, multi])

  const open_props = open === 'onFocus' ? {openOnFocus: true} : {open}

  return (
    <Autocomplete<AutocompleteValue, boolean, boolean>
      {...open_props}
      value={fixed_value as any} // type-checking is complaining, doesn't play nice with "multi"
      onChange={on_change}
      options={options_ids}
      multiple={multi}
      disableClearable
      disableCloseOnSelect={multi}
      getOptionLabel={get_option_label}
      filterOptions={filter_options}
      renderInput={render_input}
      renderOption={render_option}
      PaperComponent={ElevatedPaper}
      forcePopupIcon={false}
      size="small"
      classes={classes}
      inputValue={input_value}
      onInputChange={on_input_change}
      onOpen={on_selector_opened}
      onClose={on_selector_closed}
      onKeyDown={on_key_down}
      handleHomeEndKeys={false}
      ListboxComponent={VirtualizedListbox as ListboxComponentType}
      autoHighlight
    />
  )
}

export default SelectEditor
