import React, {FC} from 'react'

import OrganisationProjects from './OrganisationProjects'
import PermissionTables from './PermissionTables'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import LoadingSkeleton from '../components/LoadingSkeleton'

const OrganisationGallery: FC = () => {
  const {
    resources: {organisation_resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  if (!organisation_resources) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <OrganisationProjects />
      <PermissionTables />
    </>
  )
}

export default OrganisationGallery
