import React, {FC, ChangeEvent, ReactNode} from 'react'
import {FormControl, OutlinedInput, styled} from '@material-ui/core'
import {useInputStyles} from '../utils/customStyles'

type InputProps = {
  autoFocus?: boolean
  placeholder: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  icon: ReactNode
  fullWidth?: boolean
}

const SearchInput = styled(OutlinedInput)({
  '& > input': {
    height: 36,
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const CustomInput: FC<InputProps> = ({
  autoFocus,
  placeholder,
  value,
  onChange,
  icon,
  fullWidth,
}) => {
  const classes = useInputStyles()
  return (
    <FormControl fullWidth={fullWidth} size="small" variant="outlined">
      <SearchInput
        className={classes.styles}
        autoFocus={autoFocus}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={onChange}
        endAdornment={icon}
      />
    </FormControl>
  )
}

export default CustomInput
