import React, {FC} from 'react'

import AddColumnModal from '../ColSettings/AddColumnModal'
import EditColumnModal from '../ColSettings/EditColumnModal'
import ViewColumnModal from '../ColSettings/ViewColumnModal'
import DetailedViewModal from '../DetailedView/DetailedViewModal'
import NewEntityModal from '../NewEntityModal'
import ConfirmCommitModal from '../navigation/ConfirmCommitModal'

import {AlwaysDefinedRuntime, ReduxState, useRuntimeSelector} from '../utils/connect_hocs'
import {useSelector} from 'react-redux'
import EditComputedTableModal from '../ComputedTable/EditComputedTableModal'
import ShowShortcutsModal from './ShowShortcutsModal'
import ConfirmDiscardModal from '../navigation/ConfirmDiscardModal'
import ConfirmToggleArchivedModal from '../navigation/ConfirmToggleArchivedModal'

type Props = {
  on_request_close: () => void
}

const ModalContent: FC<Props> = ({on_request_close}) => {
  const {storage} = useRuntimeSelector() as AlwaysDefinedRuntime
  const modal_state = useSelector((state: ReduxState) => state.modal)
  const {entity_headers} = storage
  switch (modal_state.type) {
    case 'new_project':
      return (
        <NewEntityModal
          new_entity_type="project"
          on_request_close={on_request_close}
          organisation_id={modal_state.params.organisation_id}
        />
      )
    case 'new_table':
      return (
        <NewEntityModal
          new_entity_type="table"
          on_request_close={on_request_close}
          organisation_id={entity_headers[modal_state.params.project_id].parent_id}
          project_id={modal_state.params.project_id}
        />
      )
    case 'edit_computed_table':
      return (
        <EditComputedTableModal
          on_request_close={on_request_close}
          read_only={modal_state.params.read_only}
        />
      )
    case 'add_column':
      return (
        <AddColumnModal
          col_id={modal_state.params.col_id}
          on_request_close={on_request_close}
          where={modal_state.params.where}
        />
      )
    case 'edit_column':
      return (
        <EditColumnModal col_id={modal_state.params.col_id} on_request_close={on_request_close} />
      )
    case 'view_column':
      return (
        <ViewColumnModal col_id={modal_state.params.col_id} on_request_close={on_request_close} />
      )
    case 'detailed_view':
      return <DetailedViewModal />
    case 'confirm_commit':
      return (
        <ConfirmCommitModal
          on_submit={modal_state.params.on_submit}
          entity_ids_with_order_by={modal_state.params.entity_ids_with_order_by}
          entity_ids_with_frozen_cols={modal_state.params.entity_ids_with_frozen_cols}
          on_request_close={on_request_close}
        />
      )
    case 'show_shortcuts':
      return <ShowShortcutsModal />
    case 'confirm_discard':
      return (
        <ConfirmDiscardModal
          on_discard={modal_state.params.on_discard}
          on_request_close={on_request_close}
        />
      )
    case 'confirm_toggle_archived':
      return (
        <ConfirmToggleArchivedModal
          on_toggle={modal_state.params.on_toggle}
          on_request_close={on_request_close}
          is_archived={modal_state.params.is_archived}
          entity_type={modal_state.params.entity_type}
        />
      )
    default:
      return null
  }
}

export default ModalContent
