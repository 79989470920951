import _ from 'lodash'

import {create_squash} from '../base/create_squash'
import {Entity, EntityDiff, EntityType} from '../../types/storage'
import {throw_error, ensure} from '../../utils'
import computed_table_diff from './computed_table_diff'
import data_table_diff from './data_table_diff'
import organisation_diff from './organisation_diff'
import project_diff from './project_diff'
import view_table_diff from './view_table_diff'

const entity_type_diff = {
  data_table: data_table_diff,
  computed_table: computed_table_diff,
  view_table: view_table_diff,
  organisation: organisation_diff,
  project: project_diff,
}

function _apply(entity, diff) {
  if (entity == null) {
    entity = {
      type: diff.type,
    }
  }
  const type = entity.type
  const apply = entity_type_diff[type].apply

  ensure(apply != null, 'unknown entity type', {type})
  return apply(entity, diff)
}

function reverse(diff) {
  const type = diff.type
  const _reverse = entity_type_diff[type].reverse

  ensure(_reverse != null, 'unknown entity type', {type})
  return _reverse(diff)
}

function ensure_consistency(entity, diff) {
  if (entity == null) {
    entity = {
      type: diff.type,
    }
  }
  const type = entity.type
  const _ensure_consistency = entity_type_diff[type].ensure_consistency

  ensure(_ensure_consistency != null, 'unknown entity type', {type})
  return _ensure_consistency(entity, diff)
}

function _unapply(entity, diff, ensure: boolean = true) {
  const reversed_diff = reverse(diff)
  if (ensure) ensure_consistency(entity, reversed_diff)
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return apply(entity, reversed_diff)
}

function _squash2(...diffs) {
  if (diffs.length === 0) {
    return []
  }
  const type = diffs[0].type
  for (const diff of diffs) {
    if (diff.type !== type) {
      throw_error('squashing inconsistent diffs types', 'diff.type', diff.type, 'type', type)
    }
  }

  const squash = entity_type_diff[type].squash
  ensure(squash != null, 'unknown entity type', {type})
  return squash(...diffs)
}

const squash = create_squash(_squash2)

function apply(entity, ...diffs) {
  if (_.isEmpty(diffs)) {
    return entity
  } else {
    return _apply(entity, squash(...diffs))
  }
}

function unapply(entity, ...diffs) {
  if (_.isEmpty(diffs)) {
    return entity
  } else {
    return _unapply(entity, squash(...diffs))
  }
}

function change_diff(old_entity, new_entity) {
  const type = old_entity.type
  const _change_diff = entity_type_diff[type].change_diff

  ensure(_change_diff != null, 'unknown entity type', {type})
  return _change_diff(old_entity, new_entity)
}

function create_diff<E extends EntityType>(entity: Entity<E>): EntityDiff<E> {
  const type = entity.type
  const _create_diff = entity_type_diff[type].create_diff

  ensure(_create_diff != null, 'unknown entity type', {type})
  return _create_diff(entity)
}

function is_conflict<E extends EntityType>(entity: Entity<E>): boolean {
  const type = entity.type
  const _is_conflict = entity_type_diff[type].is_conflict

  ensure(_is_conflict !== null, 'unknown entity type', {type})
  return _is_conflict(entity)
}

function clean_neutral_diffs<E extends EntityType>(diff: EntityDiff<E>): EntityDiff<E> {
  const type = diff.type
  const _clean_neutral_diffs = entity_type_diff[type].clean_neutral_diffs

  ensure(_clean_neutral_diffs != null, 'unknown entity type', {type})
  return _clean_neutral_diffs(diff)
}

export {
  apply,
  reverse,
  ensure_consistency,
  unapply,
  squash,
  change_diff,
  create_diff,
  entity_type_diff,
  is_conflict,
  clean_neutral_diffs,
}

export default {
  apply,
  reverse,
  ensure_consistency,
  unapply,
  squash,
  change_diff,
  create_diff,
  is_conflict,
  clean_neutral_diffs,
}
