import React, {FC, useCallback, useRef} from 'react'
import {Popover, Tooltip, Box, styled} from '@material-ui/core'
import AdvancedFilterEditor from '../AdvancedFilter/AdvancedFilterEditor'
import CustomButton from '../components/CustomButton'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useTableUiSelector,
  useThunkDispatch,
} from '../utils/connect_hocs'
import {get_filter_conditions} from 'common/filter/filter_utils'
import {usePaperStyles} from '../utils/customStyles'
import {open_filter_dropdown, close_toolbar_dropdowns} from './toolbar_actions'

const Badge = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.greenPalette[600],
  width: 20,
  height: 20,
  fontWeight: 400,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(1),
}))

const IconWrap = styled('div')({
  width: 10,
  display: 'flex',
  justifyContent: 'center',
})

const FilterButton: FC = () => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const filter = useTableUiSelector(table_entity_id, 'filter')
  const dropdown = useTableUiSelector(table_entity_id, 'dropdown')
  const dispatch = useThunkDispatch()

  const conditions = get_filter_conditions(filter, table)

  const on_open = useCallback(() => {
    dispatch(open_filter_dropdown(table_entity_id, table))
  }, [dispatch, table_entity_id, table])

  const on_close = useCallback(() => {
    dispatch(close_toolbar_dropdowns(table_entity_id))
  }, [dispatch, table_entity_id])

  const filter_enabled = table != null && table.col_count() > 0

  const filter_button_text =
    conditions.length > 0 ? `Filtered by ${conditions.length} filters` : 'Filter'

  const anchor = useRef<HTMLButtonElement | null>(null)

  const popover_classes = usePaperStyles()

  return (
    <>
      <Tooltip title={filter_button_text}>
        <span ref={anchor}>
          <CustomButton
            type="tertiary"
            onClick={on_open}
            disabled={!filter_enabled}
            onlyIcon={conditions.length === 0}
          >
            <IconWrap>
              <i className="fas fa-filter" />
            </IconWrap>
            {conditions.length > 0 && (
              <Badge display="flex" alignItems="center" justifyContent="center" borderRadius="50%">
                {conditions.length}
              </Badge>
            )}
          </CustomButton>
        </span>
      </Tooltip>
      <Popover
        anchorEl={anchor.current}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={dropdown === 'filter' && anchor.current != null}
        onClose={on_close}
        classes={popover_classes}
      >
        <AdvancedFilterEditor on_request_close={on_close} />
      </Popover>
    </>
  )
}

export default FilterButton
