import React, {FC, useMemo} from 'react'
import _ from 'lodash'
import TableSelector from './TableSelector'
import {
  ColumnId,
  DataTable,
  EntityHeader,
  EntityHeaders,
  TableColumnReferences,
} from 'common/types/storage'
import {styled} from '@material-ui/core/styles'
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {compose_resource_id} from 'common/params_utils'

const ColumnContainer = styled('div')({
  'display': 'flex',
  'flexDirection': 'column',
  'width': '100%',
  '& label': {
    width: 'auto !important',
    padding: '0 !important',
  },
})

const ColumnsSelectorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '300px',
})

const SelectorField = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  minWidth: '300px',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: '8px',
})

const StyledFormControl = styled(FormControl)({
  width: '200px',
})

export type TableColumnReferenceSelectorProps = {
  disabled: boolean
  references: TableColumnReferences
  entity_headers: EntityHeaders
  entity_headers_filter: (entity_header: EntityHeader) => boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string[]) => void
  on_column_changed: (ref_id: string, table_id: string, column_id: string) => void
}

const TableColumnReferenceSelector: FC<TableColumnReferenceSelectorProps> = (props) => {
  const {
    disabled,
    references,
    entity_headers,
    entity_headers_filter,
    on_column_changed,
    onChange,
  } = props

  const {
    storage: {resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const tables = _.values(references).map(({table_id}) => table_id)
  const refs = _.entries(references)

  const referenced_tables_columns = useMemo((): Record<string, [string, string][]> => {
    return _.entries(references).reduce((prev, [ref_id, {table_id}]) => {
      const res_id = compose_resource_id({type: 'entity', entity_id: table_id})
      const resource = resources[res_id]

      let raw_cols: Record<ColumnId, {name: string}>
      if (resource?.result) {
        raw_cols = (resource.result as DataTable<'entity'>).cols
      } else {
        raw_cols = (entity_headers[table_id] as EntityHeader).cols!
      }

      const columns = _.entries(raw_cols).map<[ColumnId, string]>(([col_id, {name}]) => [
        col_id,
        name,
      ])

      return {...prev, [ref_id]: columns}
    }, {})
  }, [references, resources, entity_headers])

  return (
    <ColumnContainer>
      <TableSelector
        value={tables}
        entity_headers={entity_headers}
        entity_headers_filter={entity_headers_filter}
        onChange={onChange}
        multiple
        disabled={disabled}
      />
      <ColumnsSelectorContainer>
        {refs.map(([ref_id, {table_id, column_id}]) => {
          const cols = referenced_tables_columns[ref_id]
          return (
            <SelectorField key={ref_id}>
              <StyledFormControl
                key={ref_id}
                variant="outlined"
                size="small"
                disabled={_.isEmpty(cols)}
              >
                <InputLabel htmlFor={ref_id}>{entity_headers[table_id].name}</InputLabel>
                <Select
                  id={ref_id}
                  value={column_id || ''}
                  onChange={({target: {value}}) => {
                    on_column_changed(ref_id, table_id, value as string)
                  }}
                  label={table_id}
                >
                  {cols &&
                    cols.map(([id, name]) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </StyledFormControl>
            </SelectorField>
          )
        })}
      </ColumnsSelectorContainer>
    </ColumnContainer>
  )
}

export default TableColumnReferenceSelector
