import {
  ReduxState,
  ReduxAction,
  get_table_ui_state,
  get_table_ui_reducer,
  CursorCoordinates,
} from './connect_hocs'
import {index_of_col} from './table_helpers'
import {TableObject} from 'common/objects/data_table'
import {TableEntityId, RowId, SortField} from 'common/types/storage'
import _ from 'lodash'

const EMPTY_SELECTION_SIZE: [number, number] = [0, 0]

export function is_valid_coordinates(
  table: TableObject,
  {row_id, col_id}: CursorCoordinates
): boolean {
  return (
    (row_id == null || table._has_row(row_id)) &&
    (col_id == null || index_of_col(table, col_id) !== -1)
  )
}

export function validate_coordinates(
  table: TableObject | null,
  coordinates: CursorCoordinates | null
): CursorCoordinates | null {
  if (table != null && coordinates != null && is_valid_coordinates(table, coordinates)) {
    return coordinates
  } else {
    return null
  }
}

// Returns array of valid row IDs from the table ordered by base_rows_order.
// All valid row IDs which are not found in base_rows_order are concatenated to the end
// and added to base_rows_order as well
function infer_rows_order(
  table: TableObject,
  base_rows_order: RowId[]
): {base_rows_order: RowId[]; rows_order: RowId[]} {
  const base_rows_order_set = new Set(base_rows_order)
  const table_row_ids_set = new Set(table._row_ids)

  const updated_base_rows_order = base_rows_order.concat(
    table._row_ids.filter((row_id) => !base_rows_order_set.has(row_id))
  )
  const rows_order = updated_base_rows_order.filter((row_id) => table_row_ids_set.has(row_id))
  return {base_rows_order: updated_base_rows_order, rows_order}
}

function get_table_rows_order(
  table: TableObject | null,
  cache_rows_order: RowId[] | null,
  cache_order_by: SortField[] | null
): {
  rows_order: RowId[]
  cache_rows_order: RowId[] | null
  cache_order_by: SortField[] | null
} {
  if (table == null) {
    return {
      rows_order: [],
      cache_rows_order: null,
      cache_order_by: null,
    }
  }

  const {_validated_order_by: order_by} = table

  // Ordering did not change
  // Instead of fully recalculating, we can infer from last_rows_order
  if (cache_rows_order != null && _.isEqual(order_by, cache_order_by)) {
    const {base_rows_order, rows_order} = infer_rows_order(table, cache_rows_order)
    return {
      rows_order,
      cache_rows_order: base_rows_order,
      cache_order_by,
    }
  }

  // Otherwise, recalculate new_rows_order from scratch
  const new_rows_order = table._get_rows_order(order_by)
  return {
    rows_order: new_rows_order,
    cache_rows_order: new_rows_order,
    cache_order_by: order_by,
  }
}

function validate_table_ui_state(
  state: ReduxState,
  table_entity_id: TableEntityId,
  table: TableObject | null,
  full_table: TableObject | null
): ReduxState {
  const {
    last_cursor,
    last_scroll_to,
    selection_size,
    cols_order: prev_cols_order,
    rows_order: prev_rows_order,
    cache_rows_order: prev_cache_rows_order,
    cache_order_by: prev_cache_order_by,
  } = get_table_ui_state(state, table_entity_id)
  // Calculate the full row ordering on the non-filtered table
  // Calculation is optimized by using the previously-cached row ordering
  const {cache_rows_order, rows_order: full_rows_order, cache_order_by} = get_table_rows_order(
    full_table,
    prev_cache_rows_order,
    prev_cache_order_by
  )

  // Rows in table are always a sub-set of rows in full_table -> intersection
  // If there are the same object (no filtering applied), we can use the same ordering
  const rows_order =
    table === null || table === full_table
      ? full_rows_order
      : _.intersection(full_rows_order, table._row_ids)

  // Column order is managed by the table object itself - we don't validate it
  // We only need to store it to reset selection_size if it has changed
  const cols_order = table ? table._visible_cols_order : []

  // Set cursor and scroll_to to null if don't point to a valid cell
  // Using the last-known valid values, so we can restore them if they become valid again
  const cursor = validate_coordinates(table, last_cursor)
  const scroll_to = validate_coordinates(table, last_scroll_to)

  // Very basic validation for selection
  // Reset selection size whenever row/column ordering changes in any way
  const rows_have_changed = !_.isEqual(rows_order, prev_rows_order)
  const row_was_added = rows_order.length > prev_rows_order.length
  const cols_have_changed = !_.isEqual(cols_order, prev_cols_order)
  const should_reset_selection = cols_have_changed || (rows_have_changed && !row_was_added)

  return get_table_ui_reducer(table_entity_id, {
    cursor,
    scroll_to,
    selection_size: should_reset_selection ? EMPTY_SELECTION_SIZE : selection_size,
    cache_rows_order,
    cache_order_by,
    full_rows_order,
    rows_order,
    cols_order,
  })(state)
}

export function with_ui_validation(reducer: (state: ReduxState) => ReduxState) {
  return (old_state: ReduxState): ReduxState => {
    let state = reducer(old_state)

    const {
      runtime: {
        resources: {table_resources},
      },
    } = state

    if (table_resources) {
      const {
        table = null,
        full_table = null,
        table_entity_id,
        detailed_view_table = null,
      } = table_resources

      // 1. Validate UI of the currently displayed table
      state = validate_table_ui_state(state, table_entity_id, table, full_table)

      // 2. Validate UI of the detailed view table, if necessary
      if (detailed_view_table && detailed_view_table._entity_id !== table_entity_id) {
        state = validate_table_ui_state(
          state,
          detailed_view_table._entity_id,
          detailed_view_table, // detailed view table is never filtered
          detailed_view_table
        )
      }
    }
    return state
  }
}

// Clear the cached row ordering then trigger UI validation to recalculate it
export function force_recalculate_rows_order(table_entity_id: TableEntityId): ReduxAction {
  return {
    payload: {},
    type: 'force_recalculate_rows_order',
    reducer: with_ui_validation(
      get_table_ui_reducer(table_entity_id, {
        cache_rows_order: null,
        cache_order_by: null,
      })
    ),
  }
}
