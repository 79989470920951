import React, {FC} from 'react'
import DetailedRowView from './DetailedRowView'
import {useRuntimeSelector} from '../utils/connect_hocs'
import {styled} from '@material-ui/core/styles'
import {DialogContent} from '@material-ui/core'

const ModalContainer = styled(DialogContent)({
  width: '75vw',
})

const DetailedViewModal: FC = () => {
  const {resources} = useRuntimeSelector()

  // Prevent displaying detailed view modal if the corresponding table is not loaded
  if (resources?.table_resources?.detailed_view_table) {
    return (
      <ModalContainer>
        <DetailedRowView />
      </ModalContainer>
    )
  } else {
    return null
  }
}

export default DetailedViewModal
