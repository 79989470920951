import React, {FC, useCallback, useState} from 'react'
import {Box, CircularProgress, Snackbar, styled, Tooltip} from '@material-ui/core'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {Alert} from '@material-ui/lab'
import CustomButton from '../components/CustomButton'
import {EntityId, EntityType} from 'common/types/storage'
import {useToggleArchived} from './toggle_archived_hook'
import {
  has_write_permissions,
  is_permission_table_subtype,
} from 'common/permission/permission_utils'
import {is_entity_or_parent_archived} from 'common/archived_utils'

const ButtonsWrap = styled('div')(({theme}) => ({
  minWidth: 130,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '1rem',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    gap: '0.5rem',
    padding: '0.5rem',
  },
}))

const ButtonWrap = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
}))

type ArchiverButtonType = 'primary' | 'secondary' | 'tertiary'
type ArchiverProps = {
  archive_button_type?: ArchiverButtonType
  entity_id: EntityId
}

export const Archiver: FC<ArchiverProps> = ({archive_button_type = 'secondary', entity_id}) => {
  const {
    runtime,
    storage: {history_mode, entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const [popup_message, set_popup_message] = useState<{
    message: string
    severity?: 'error' | 'success' | 'info' | 'warning'
  }>({message: ''})
  const close_popup = useCallback(
    () => set_popup_message({message: '', severity: popup_message?.severity}),
    [popup_message?.severity]
  )

  const is_archived = !!entity_headers[entity_id].archived

  const [is_toggling, start_toggling] = useToggleArchived({
    on_redirect(data: {type: EntityType; entity_id: EntityId}): void {},
    on_error: set_popup_message,
    entity: entity_headers[entity_id],
  })

  const label = is_archived ? 'Unarchive table' : 'Archive table'
  const disabled =
    history_mode ||
    is_toggling ||
    !has_write_permissions(runtime, entity_id) ||
    is_permission_table_subtype(entity_headers[entity_id]?.subtype) ||
    is_entity_or_parent_archived(entity_headers[entity_id].parent_id, entity_headers)

  const buttons = [
    {
      tooltip: label,
      type: archive_button_type,
      onClick: start_toggling,
      disabled,
      label,
    },
  ]

  return (
    <Box ml={2}>
      <ButtonsWrap>
        {buttons.map(({tooltip, type, onClick, disabled, label}) => (
          <Tooltip title={tooltip} key={label}>
            <ButtonWrap>
              <CustomButton type={type} onClick={onClick} disabled={disabled} fullWidth>
                {is_toggling ? <CircularProgress color="inherit" size={24} /> : label}
              </CustomButton>
            </ButtonWrap>
          </Tooltip>
        ))}
      </ButtonsWrap>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={popup_message.message !== ''}
        autoHideDuration={6000}
        onClose={close_popup}
      >
        <Alert severity={popup_message.severity} onClose={close_popup}>
          {popup_message.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Archiver
