import React, {FC, useCallback} from 'react'
import ih from 'immutability-helper'
import ConditionRow from './ConditionRow'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  styled,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import {FilterType, FilterValue, UiFilterCondition} from 'common/types/filter'
import {ColumnId} from 'common/types/storage'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {adjust_filter_value, get_filter_from_conditions} from 'common/filter/filter_utils'
import {get_default_filter, get_default_filter_type} from './filter_utils'
import CustomButton from '../components/CustomButton'

type AdvancedFilterEditorProps = {
  on_request_close: () => void
}

const FiltersTable = styled(Table)({
  marginBottom: '1em',
})

const StyledTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.greyPalette[400],
}))

const StyledCard = styled(Card)(({theme}) => ({
  borderRadius: 4,
  minWidth: 553,
}))

const StyledCardActions = styled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2, 2, 2),
}))

const AdvancedFilterEditor: FC<AdvancedFilterEditorProps> = ({on_request_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const filter_dropdown = useTableUiSelector(table_entity_id, 'filter_dropdown')
  const {conditions, operator} = filter_dropdown

  const apply_filter = useCallback(
    ({new_operator = operator, new_conditions = conditions}) => {
      const {filter, ui_filter_conditions, error} = get_filter_from_conditions(
        new_operator,
        new_conditions,
        table
      )
      if (error) {
        // handle error state here instead of closing
        const new_dropdown_state = {...filter_dropdown, conditions: ui_filter_conditions}
        set_table_ui_state({filter_dropdown: new_dropdown_state}, 'set_filter_conditions')
      } else {
        set_table_ui_state({filter}, 'apply_filter')
        on_request_close()
      }
    },
    [operator, conditions, table, filter_dropdown, set_table_ui_state, on_request_close]
  )

  const change_filter_operator = useCallback(
    (e) => {
      const new_operator = e.target.value
      if (operator !== new_operator) {
        const new_dropdown_state = {...filter_dropdown, operator: new_operator}
        set_table_ui_state({filter_dropdown: new_dropdown_state}, 'set_filter_operator')
      }
    },
    [filter_dropdown, operator, set_table_ui_state]
  )

  const set_filter_conditions = useCallback(
    (new_conditions: UiFilterCondition[]) => {
      const {ui_filter_conditions} = get_filter_from_conditions(operator, new_conditions, table)

      const new_dropdown_state = {...filter_dropdown, conditions: ui_filter_conditions}
      set_table_ui_state({filter_dropdown: new_dropdown_state}, 'set_filter_conditions')
    },
    [filter_dropdown, operator, set_table_ui_state, table]
  )

  const add_filter_row = useCallback(
    (col_id: ColumnId) => {
      const new_filter_row = get_default_filter(col_id, table._cols[col_id].type)
      set_filter_conditions(ih(conditions, {$push: [new_filter_row]}))
    },
    [conditions, set_filter_conditions, table._cols]
  )

  const change_filter_column = useCallback(
    (index: number, col_id: ColumnId) => {
      set_filter_conditions(
        ih(conditions, {
          [index]: {
            1: {$set: col_id},
            2: {$set: get_default_filter_type(table._cols[col_id].type)},
            3: {$set: ''},
          },
        })
      )
    },
    [conditions, set_filter_conditions, table._cols]
  )

  const remove_row = useCallback(
    (index: number) => {
      set_filter_conditions(ih(conditions, {$splice: [[index, 1]]}))
    },
    [conditions, set_filter_conditions]
  )

  const reset_all_rows = useCallback(() => {
    set_filter_conditions(ih(conditions, {$set: []}))
    apply_filter({new_conditions: []})
  }, [conditions, set_filter_conditions, apply_filter])

  const change_filter_type = useCallback(
    (index: number, new_filter: FilterType) => {
      const [, col_id, old_filter, value] = conditions[index]
      const validated_value = adjust_filter_value(
        table._cols[col_id].type.type,
        old_filter,
        new_filter,
        value
      )

      set_filter_conditions(
        ih(conditions, {
          [index]: {
            2: {$set: new_filter},
            3: {$set: validated_value == null ? '' : validated_value},
          },
        })
      )
    },
    [conditions, set_filter_conditions, table._cols]
  )

  const change_filter_value = useCallback(
    (index: number, new_value: FilterValue<'internal'>) => {
      set_filter_conditions(ih(conditions, {[index]: {3: {$set: new_value}}}))
    },
    [conditions, set_filter_conditions]
  )

  return (
    <StyledCard>
      <CardContent>
        {(conditions.length > 0 && (
          <FiltersTable size="small">
            <TableBody>
              {conditions.map((condition, index) => (
                <ConditionRow
                  key={condition[0]}
                  condition={condition}
                  columns={table._cols_order.map((col_id) => [col_id, table._cols[col_id].name])}
                  col_type={table._cols[condition[1]].type}
                  operator={operator}
                  on_change_filter_operator={change_filter_operator}
                  on_remove={remove_row}
                  on_filter_column_change={change_filter_column}
                  on_filter_type_change={change_filter_type}
                  on_filter_value_change={change_filter_value}
                  autofocus={false}
                  index={index}
                />
              ))}
            </TableBody>
          </FiltersTable>
        )) || <StyledTypography variant="body2">No filters applied to this view</StyledTypography>}
      </CardContent>
      <StyledCardActions>
        <Box display="flex" alignItems="center" mr={2}>
          <CustomButton
            type="secondary"
            startIcon={<Add />}
            onClick={() => add_filter_row(table._cols_order[0])}
            disableElevation
          >
            Add filter
          </CustomButton>
          <Box ml={1}>
            <CustomButton type="primary" onClick={() => apply_filter({})}>
              Apply
            </CustomButton>
          </Box>
        </Box>
        <CustomButton type="tertiary" onClick={reset_all_rows} disabled={conditions.length < 1}>
          Reset all filters
        </CustomButton>
      </StyledCardActions>
    </StyledCard>
  )
}

export default AdvancedFilterEditor
