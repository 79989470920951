import _ from 'lodash'
import {
  ProjectId,
  EntityHeaders,
  EntityHeader,
  TableEntityId,
  TableType,
  Entity,
  ZoneId,
  OrganisationId,
  EntityType,
  EntityId,
} from './types/storage'
import {Runtime} from 'common/create_runtime'
import {MaybeError} from 'common/error'
import {PermissionTableSubtype} from 'common/permission/permission_tables'
import {is_permission_table_subtype} from 'common/permission/permission_utils'

const get_permission_tables_in_zone = (
  entity_headers: EntityHeaders,
  zone_id: ZoneId
): EntityHeaders<TableType> => {
  return _.pickBy(
    entity_headers,
    (header): header is EntityHeader<'data_table'> & {subtype: PermissionTableSubtype} =>
      header.zone_id === zone_id &&
      header.type === 'data_table' &&
      is_permission_table_subtype(header.subtype)
  )
}

const get_tables_in_project = (
  entity_headers: EntityHeaders,
  project_id: ProjectId,
  include_archived: boolean = true
): EntityHeaders<TableType> => {
  return _.pickBy(
    entity_headers,
    (header): header is EntityHeader<TableType> =>
      ['data_table', 'computed_table'].includes(header.type) &&
      header.zone_id === project_id &&
      (!header.archived || include_archived)
  )
}

const validate_entity_name = (
  entity_headers: EntityHeaders,
  entity_type: EntityType,
  new_parent_id: OrganisationId,
  entity_name: string
): string => {
  const get_entity_type = (entity_type) => {
    switch (entity_type) {
      case 'computed_table':
      case 'data_table':
      case 'view_table':
        return 'Table'
      case 'project': {
        return 'Project'
      }
      default:
        return null
    }
  }

  const entity_type_name = get_entity_type(entity_type)
  const parent_entity_type = entity_headers[new_parent_id]?.type
  if (!entity_name) {
    return `${entity_type_name} name can not be empty`
  }
  if (
    _.some(
      entity_headers,
      ({name, parent_id, archived}) =>
        parent_id === new_parent_id && name === entity_name && !archived
    )
  ) {
    return `${entity_type_name} "${entity_name}" already exists in current ${parent_entity_type}`
  }

  return ''
}
const get_entity_to_table_name_mapping = (
  entity_headers: EntityHeaders,
  filter: (entity_header: EntityHeader) => boolean
) =>
  _(entity_headers)
    .pickBy(filter)
    .mapValues(({name: table_name, zone_id}: EntityHeader) => {
      const project_entity = entity_headers[zone_id]
      const organisation_name = entity_headers[project_entity.parent_id].name
      return `${organisation_name}/${project_entity.name}/${table_name}`
    })
    .value()

const permission_table_entities_task = (
  runtime: Runtime,
  zone_id: ZoneId
): Array<() => MaybeError<Entity<'data_table'>>> => {
  const table_headers = get_permission_tables_in_zone(runtime.get_headers(), zone_id)
  return Object.keys(table_headers).map((entity_id) => () =>
    runtime.get_entity<'data_table'>(entity_id)
  )
}

const projects_table_entities_task = (
  runtime: Runtime,
  project_id: ProjectId,
  include_archived: boolean = false
): Array<() => MaybeError<Entity<TableType>>> => {
  const table_headers = get_tables_in_project(runtime.get_headers(), project_id, include_archived)
  return Object.keys(table_headers).map((entity_id) => () =>
    runtime.get_entity<TableType>(entity_id)
  )
}

const consolidate_tables_order = (
  project_tables: EntityHeaders,
  tables_order: TableEntityId[]
): TableEntityId[] => {
  return _.union(
    tables_order.filter((entity_id) => project_tables[entity_id] != null),
    _.sortBy(project_tables, [({name}) => name.toLowerCase()]).map(({entity_id}) => entity_id)
  )
}

const get_project_id = (entity_headers: EntityHeaders, entity_id: EntityId): ProjectId => {
  const entity = entity_headers[entity_id]
  if (entity.type === 'project') {
    return entity_id
  }
  return get_project_id(entity_headers, entity.parent_id)
}

export {
  get_tables_in_project,
  validate_entity_name,
  consolidate_tables_order,
  get_entity_to_table_name_mapping,
  get_permission_tables_in_zone,
  permission_table_entities_task,
  projects_table_entities_task,
  get_project_id,
}
