import React, {useCallback} from 'react'
import ToggleButtons from '../components/ToggleButtons'
import {Box, styled, Typography} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {CellType, ColumnId, ColumnSpec} from 'common/types/storage'
import CustomIconButton from '../components/CustomIconButton'
import ColumnSelector from '../Selectors/ColumnSelector'

type SortingColumnEditorProps = {
  col_id: ColumnId
  col: ColumnSpec<CellType, 'entity'>
  columns: [ColumnId, string][]
  on_column_change: (index: number, new_col_id: string) => void
  asc: number
  remove: (index: number) => void
  toggle_dir: (index: number) => void
  index: number
}

const Item = styled(Typography)(({theme}) => ({
  minWidth: 55,
}))

function get_toggle_text_from_col_type(type: CellType) {
  switch (type) {
    case 'number':
    case 'date':
    case 'date_time':
      return ['1-9', '9-1']
    default:
      return ['A-Z', 'Z-A']
  }
}

const SortingColumnEditor: React.FC<SortingColumnEditorProps> = ({
  col_id,
  col,
  columns,
  on_column_change,
  asc,
  remove,
  toggle_dir,
  index,
}) => {
  const handle_change = useCallback(() => toggle_dir(index), [index, toggle_dir])
  const remove_row = useCallback(() => remove(index), [index, remove])

  const _on_column_change = useCallback(
    (new_col_id: ColumnId) => {
      on_column_change(index, new_col_id)
    },
    [index, on_column_change]
  )

  const toggle_labels = get_toggle_text_from_col_type(col.type.type)
  return (
    <Box display="flex" alignItems="center" flexGrow={1}>
      {index === 0 && <Item variant="body2">Sort by</Item>}
      {index !== 0 && <Item variant="body2">THEN</Item>}
      <ColumnSelector value={col_id} columns={columns} on_change={_on_column_change} />
      <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1} ml={1}>
        <Typography variant="body2">from</Typography>
        <Box mx={1}>
          <ToggleButtons
            asc={asc}
            labelChecked={toggle_labels[0]}
            labelUnchecked={toggle_labels[1]}
            onChange={handle_change}
          />
        </Box>
        <CustomIconButton tooltip="remove row" size="small" onClick={remove_row}>
          <DeleteIcon fontSize="small" />
        </CustomIconButton>
      </Box>
    </Box>
  )
}

export default SortingColumnEditor
