import React, {FC, useCallback} from 'react'
import {styled} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useGlobalDragging} from './utils/hooks'

type FrozenColsDividerProps = {
  left: number
  height: number
  start_dragging: () => void
  on_drag: (e: MouseEvent) => void
  end_dragging: () => void
  on_context_menu: (e: React.MouseEvent) => void
}

const Divider = styled('div')({
  'top': '2px',
  'position': 'absolute',
  'width': '5px',
  'userSelect': 'none',
  'opacity': 0,
  '&:hover': {
    cursor: 'grab',
  },
})

const useStyles = makeStyles({
  root: (props: {left; height}) => ({
    left: props.left,
    height: props.height,
  }),
})

export const FrozenColsDivider: FC<FrozenColsDividerProps> = ({
  left,
  height,
  start_dragging,
  end_dragging,
  on_drag,
  on_context_menu,
}) => {
  const classes = useStyles({left, height})
  const start_global_dragging = useGlobalDragging(on_drag, end_dragging)

  const on_mouse_down = useCallback(
    (e: React.MouseEvent) => {
      if (e.button === 0) {
        start_dragging()
        start_global_dragging()
      }
    },
    [start_dragging, start_global_dragging]
  )

  return (
    <Divider className={classes.root} onContextMenu={on_context_menu} onMouseDown={on_mouse_down} />
  )
}

export default FrozenColsDivider
