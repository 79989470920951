import React, {FC, useCallback, useRef} from 'react'
import {Popover, Tooltip, Box, styled} from '@material-ui/core'
import SortRowsEditor from '../SortRows/SortRowsEditor'
import CustomButton from '../components/CustomButton'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useTableUiSelector,
  useThunkDispatch,
} from '../utils/connect_hocs'
import {usePaperStyles} from '../utils/customStyles'
import {open_sort_dropdown, close_toolbar_dropdowns} from './toolbar_actions'

const Badge = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.warning.main,
  width: 20,
  height: 20,
  fontWeight: 400,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(1),
}))

const IconWrap = styled('div')({
  width: 10,
  display: 'flex',
  justifyContent: 'center',
})

const SortButton: FC = () => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
    storage: {history_mode},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const dropdown = useTableUiSelector(table_entity_id, 'dropdown')
  const dispatch = useThunkDispatch()

  const on_open = useCallback(() => {
    dispatch(open_sort_dropdown(table_entity_id))
  }, [dispatch, table_entity_id])

  const on_close = useCallback(() => {
    dispatch(close_toolbar_dropdowns(table_entity_id))
  }, [dispatch, table_entity_id])

  const number_of_orders = table ? table._validated_order_by.length : 0

  const sort_enabled =
    table != null && table._can_edit_schema() && table.col_count() > 0 && !history_mode

  const sort_button_text = number_of_orders > 0 ? `Sorted by ${number_of_orders} columns` : 'Sort'

  const anchor = useRef<HTMLButtonElement | null>(null)
  const popover_classes = usePaperStyles()

  return (
    <>
      <Tooltip title={sort_button_text}>
        <span ref={anchor}>
          <CustomButton
            type="tertiary"
            onClick={on_open}
            disabled={!sort_enabled}
            onlyIcon={number_of_orders === 0}
          >
            <IconWrap>
              <i className="fas fa-sort-amount-down" />
            </IconWrap>
            {number_of_orders > 0 && (
              <Badge display="flex" alignItems="center" justifyContent="center" borderRadius="50%">
                {number_of_orders}
              </Badge>
            )}
          </CustomButton>
        </span>
      </Tooltip>
      <Popover
        anchorEl={anchor.current}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={dropdown === 'sort' && anchor.current != null}
        onClose={on_close}
        classes={popover_classes}
      >
        <SortRowsEditor on_request_close={on_close} />
      </Popover>
    </>
  )
}

export default SortButton
