import {Entity, EntityDiff, EntityHeader, EntityId, ZoneId} from 'common/types/storage'
import {_add_diff, _add_multi_diff, AddMultiDiffAction} from 'common/entities/actions'
import {create_diff} from 'common/diffs/models/entity_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import value_diff from 'common/diffs/base/value_diff'

const create_new_entities_action = (zone_id: ZoneId, ...entities: Entity[]): AddMultiDiffAction => {
  const entity_diff_pairs = entities.map((entity): [EntityId, EntityDiff] => [
    entity.entity_id,
    create_diff(entity),
  ])
  return _add_multi_diff(zone_id, entity_diff_pairs)
}

const change_entity_name = (entity_header: EntityHeader, new_name: string) => {
  const {entity_id, name, type, zone_id} = entity_header
  const name_diff = {
    type,
    name: conflict_free_value_diff().change_diff(name, new_name),
  } as EntityDiff
  return _add_diff(entity_id, zone_id, name_diff)
}

const change_archived_status = (entity_header: EntityHeader, archived_status: boolean) => {
  const {entity_id, archived, type, zone_id} = entity_header
  const archived_diff = {
    type,
    archived: value_diff().change_diff(archived, archived_status),
  } as EntityDiff
  return _add_diff(entity_id, zone_id, archived_diff)
}

export {create_new_entities_action, change_entity_name, change_archived_status}
