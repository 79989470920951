import React, {FC, useCallback} from 'react'
import styles from './DetailedViewHeader.module.css'
import {change_row, back_in_history, forward_in_history} from './history_actions'
import {index_of_row, row_id_on_index} from '../utils/table_helpers'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {RowId} from 'common/types/storage'
import {Tooltip} from '@material-ui/core'

type DetailedViewHeaderProps = {
  label: string
  row_id: RowId
  rows_order: RowId[]
}

const ARROW_TOOLTIPS = {
  UP: 'Previous row',
  DOWN: 'Next row',
  LEFT: 'Go back',
  RIGHT: 'Go forward',
}

const DetailedViewHeader: FC<DetailedViewHeaderProps> = ({label, row_id, rows_order}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const detailed_view = useTableUiSelector(table_entity_id, 'detailed_view')
  const row_index = index_of_row(rows_order, row_id)

  const {history, current} = detailed_view
  const can_go_up = row_index > 0
  const can_go_down = row_index < rows_order.length - 1
  const can_go_back = current != null && current > 0
  const can_go_forward = current != null && current < history.length - 1

  const up = useCallback(() => {
    set_table_ui_state(change_row(detailed_view, row_id_on_index(rows_order, row_index - 1)))
  }, [set_table_ui_state, detailed_view, rows_order, row_index])

  const down = useCallback(() => {
    set_table_ui_state(change_row(detailed_view, row_id_on_index(rows_order, row_index + 1)))
  }, [set_table_ui_state, detailed_view, rows_order, row_index])

  const forward = useCallback(() => {
    set_table_ui_state(forward_in_history(detailed_view))
  }, [set_table_ui_state, detailed_view])

  const back = useCallback(() => {
    set_table_ui_state(back_in_history(detailed_view))
  }, [set_table_ui_state, detailed_view])

  return (
    <div className={styles.headerRoot}>
      <div className={styles.headerId}>ID: {row_id}</div>
      <div className={styles.headerContent}>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={up} disabled={!can_go_up}>
            <Tooltip title={ARROW_TOOLTIPS.UP}>
              <i className="fas fa-chevron-up" />
            </Tooltip>
          </button>
          <button className={styles.button} onClick={down} disabled={!can_go_down}>
            <Tooltip title={ARROW_TOOLTIPS.DOWN}>
              <i className="fas fa-chevron-down" />
            </Tooltip>
          </button>
        </div>
        <div className={styles.title}>{label}</div>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={back} disabled={!can_go_back}>
            <Tooltip title={ARROW_TOOLTIPS.LEFT}>
              <i className="fas fa-arrow-alt-circle-left" />
            </Tooltip>
          </button>
          <button className={styles.button} onClick={forward} disabled={!can_go_forward}>
            <Tooltip title={ARROW_TOOLTIPS.RIGHT}>
              <i className="fas fa-arrow-alt-circle-right" />
            </Tooltip>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DetailedViewHeader
