import React, {FC, useCallback, useEffect} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {AppBar, Box, Breadcrumbs, IconButton, Link, Toolbar, Typography} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone'

import {EntityHeaders, OrganisationId, ProjectId, TableEntityId} from 'common/types/storage'
import {is_permission_table_subtype} from 'common/permission/permission_utils'
import {is_error, entity_has_no_changes_to_display} from 'common/error'

import {ROUTES} from '../utils/navigation_utils'
import {ReduxState, RuntimeResources, useRuntimeSelector} from '../utils/connect_hocs'
import QuickSearch from './QuickSearch'
import UserDetails from './UserDetails'
import {toggle_explorer} from '../Pages/Explorer'
import ToggleArchivedButton from './ToggleArchivedButton'

const StyledBreadcrumbs = styled((props) => <Breadcrumbs variant="h6" {...props} />)(({theme}) => ({
  'color': 'rgba(255, 255, 255, 0.5)',
  'flexGrow': 1,
  'overflow': 'hidden',
  'marginLeft': theme.spacing(2),
  'fontWeight': 400,
  '& > ol > li:last-child': {
    color: theme.palette.common.white,
  },
}))

const StyledToolbar = styled(Toolbar)({
  minHeight: 56,
})

const CurrentBreadcrumbTypography = styled(Typography)({
  color: '#fff',
})

type BreadcrumbProps = {
  current: boolean
  label: string
  href: string
}

const Breadcrumb: FC<BreadcrumbProps> = ({href, label, current}) => {
  return (
    <Typography variant="inherit">
      {current ? (
        <CurrentBreadcrumbTypography variant="inherit">{label}</CurrentBreadcrumbTypography>
      ) : (
        <Link component={RouterLink} to={href} color="inherit">
          {label}
        </Link>
      )}
    </Typography>
  )
}

type PathTuple = [OrganisationId | undefined, ProjectId | undefined, TableEntityId | undefined]

/* Path to show depending on what is being displayed:
   1)  not found page         =>  "Error"
   2)  landing page           =>  "Home"
   3)  organisation           =>  <organisation>
   4)  project                =>  <organisation> / <project>
   5a) table without project  =>  <organisation>
   5b) table inside project   =>  <organisation> / <project>
 */
export const get_path_ids = (
  entity_headers: EntityHeaders,
  resources: RuntimeResources
): PathTuple => {
  const table_entity_id = resources.table_resources?.table_entity_id
  let project_id: ProjectId | undefined

  if (table_entity_id == null) {
    project_id = resources.project_resources?.project_id
  } else if (is_permission_table_subtype(entity_headers[table_entity_id]?.subtype)) {
    return [entity_headers[table_entity_id]?.zone_id, undefined, table_entity_id]
  } else {
    project_id = entity_headers[table_entity_id]?.zone_id
  }
  const organisation_id =
    project_id != null
      ? entity_headers[project_id]?.parent_id
      : resources.organisation_resources?.organisation_id

  return [organisation_id, project_id, table_entity_id]
}

/* Creates breadcrumbs, quick search and committer if within organisation,
 * otherwise just "Home" typography.
 * Expects storage to be non-null.
 */
const NavigationElements: FC = () => {
  const {storage, resources} = useRuntimeSelector()
  const {entity_headers} = storage!
  const [organisation_id, project_id, table_entity_id] = get_path_ids(entity_headers, resources)

  const dispatch = useDispatch()
  const explorer_state = useSelector((state: ReduxState) => state.explorer)
  const handle_toggle_explorer = useCallback(() => {
    dispatch(toggle_explorer(!explorer_state.open))
  }, [explorer_state, dispatch])

  return (
    <>
      <IconButton onClick={handle_toggle_explorer} size="small" color="inherit">
        <MenuTwoToneIcon color="inherit" />
      </IconButton>
      <StyledBreadcrumbs aria-label="breadcrumb">
        <Breadcrumb
          href={ROUTES.browser()}
          label="Home"
          current={organisation_id == null && project_id == null && table_entity_id == null}
        />
        {organisation_id != null && (
          <Breadcrumb
            href={ROUTES.organisation(organisation_id)}
            label={entity_headers[organisation_id].name}
            current={project_id == null && table_entity_id == null}
          />
        )}
        {organisation_id != null && project_id != null && (
          <Breadcrumb
            href={ROUTES.project(organisation_id, project_id)}
            label={entity_headers[project_id].name}
            current={table_entity_id == null}
          />
        )}
      </StyledBreadcrumbs>
      <ToggleArchivedButton />
      <QuickSearch />
    </>
  )
}

const Navigation: FC = () => {
  const {storage, error} = useRuntimeSelector()
  const show_navigation_elements =
    storage != null && // storage is null when you go directly to /404 page
    (error == null || (is_error(error) && entity_has_no_changes_to_display(error)))

  const dispatch = useDispatch()
  useEffect(() => {
    show_navigation_elements || dispatch(toggle_explorer(false))
  }, [show_navigation_elements, dispatch])

  return (
    <AppBar position="sticky" elevation={2}>
      <StyledToolbar>
        {show_navigation_elements ? <NavigationElements /> : <Box flex={1} />}
        <UserDetails />
      </StyledToolbar>
    </AppBar>
  )
}

export default Navigation
