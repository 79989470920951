import React, {FC, ReactNode} from 'react'
import {Paper, Theme, Typography, Box} from '@material-ui/core'
import {createStyles, makeStyles, styled} from '@material-ui/core/styles'

type ConflictCardProps = {
  title: string
  content?: ReactNode
  onClick?: () => void
}

const Header = styled(Typography)(({theme}) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
}))

const PaperContent = styled(Box)(({theme}) => ({
  padding: theme.spacing(2),
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: 8,
      marginBottom: theme.spacing(2),
    },
    pointer: {
      cursor: 'pointer',
    },
  })
)

const ConflictCard: FC<ConflictCardProps> = ({title, content, onClick}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.card} elevation={1}>
      <PaperContent onClick={onClick} className={onClick ? classes.pointer : undefined}>
        <Header variant="subtitle1">{title}</Header>
        {content}
      </PaperContent>
    </Paper>
  )
}

export default ConflictCard
