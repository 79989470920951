import React, {FC, useRef} from 'react'
import {styled} from '@material-ui/core/styles'
import {Coordinates} from '../utils/mouse_utils'
import {Box, ClickAwayListener, MenuList, Popper} from '@material-ui/core'

export type ContextMenuProps = {
  pos: Coordinates | null
  on_close: () => void
}

const consumeContextMenuEvent = (e) => {
  // do not show browser's native context menu on the custom ContextMenu
  e.preventDefault()
}

const StyledPopper = styled(Popper)(({theme}) => ({
  //Context menu should be displayed above all other elements
  zIndex: theme.zIndex.tooltip + 1,
}))

const StyledBox = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.greyPalette[900],
}))

const StyledMenuList = styled(MenuList)(({theme}) => ({
  outline: 'none',
}))

const ContextMenu: FC<ContextMenuProps> = (props) => {
  const {pos, on_close} = props
  const anchor_ref = useRef<any>(null)

  return (
    <>
      <div
        ref={anchor_ref}
        style={{
          position: 'fixed',
          left: pos != null ? pos.left : -1000,
          top: pos != null ? pos.top : -1000,
        }}
      />
      <StyledPopper
        id="editor-context-menu"
        open={pos !== null}
        anchorEl={anchor_ref.current}
        placement={'bottom-start'}
      >
        <ClickAwayListener onClickAway={on_close} mouseEvent={'onMouseDown'}>
          <StyledBox boxShadow={3} onContextMenu={consumeContextMenuEvent} borderRadius={4}>
            <StyledMenuList autoFocus>{props.children}</StyledMenuList>
          </StyledBox>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default ContextMenu
