import React from 'react'
import {createStyles, styled, Tab, TabProps, Tooltip, withStyles} from '@material-ui/core'

type SidebarTabProps = {
  highlight?: boolean
} & TabProps

const StyledTooltip = withStyles((theme) =>
  createStyles({
    tooltipPlacementLeft: {
      margin: theme.spacing(0, 1),
    },
  })
)(Tooltip)

const SidebarTab: React.ComponentType<SidebarTabProps> = styled(({highlight, title, ...props}) => (
  <Tab
    {...props}
    icon={
      <StyledTooltip title={title} placement={'left'}>
        {props.icon}
      </StyledTooltip>
    }
  />
))((props) => ({
  'color': props.highlight ? 'red' : '#263238',
  'opacity': 0.4,
  'minWidth': 54,
  'borderBottom': '1px solid #cfd8dc',
  '&:hover': {
    borderColor: '#cfd8dc !important',
  },
}))

export default SidebarTab
