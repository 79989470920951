/**
 * @module RuntimeContext
 */
import ih_update from 'immutability-helper'
import {create_runtime, Runtime} from './create_runtime'
import {
  IMMUTABLE_ACTIONS,
  MUTABLE_ACTIONS,
  ActionPayload,
  apply_change,
  StorageDispatch,
  get_latest_versions,
} from './dispatch'
import {throw_error, ensure, compose} from './utils'
import {is_error, ErrorObject, MaybeError} from './error'
import _ from 'lodash'
import {
  Storage,
  ZoneId,
  CommitId,
  ResourceId,
  CommitInfo,
  EntityHeaders,
  Resource,
  DownloadableResourceDataWithPermissions,
  CustomForkableResourceObject,
  ExternalResourceData,
  ExternalFn,
} from './types/storage'

export type RuntimeFn<T> = (runtime: Runtime) => T
export type RuntimeCallback<T> = (
  status: 'in-progress' | 'error' | 'done' | 'killed',
  result?: T | ErrorObject | Error
) => void
export type RuntimeTask<T> = [RuntimeFn<T>, RuntimeCallback<T>]

export type CallMonitor = <T extends Function>(fn: T, name: string) => T

export type FetchCommitsAction = (zone_id: ZoneId, fixed_date?: Date) => Promise<void>
export type FetchResourceAction = (commit_id: CommitId, resource_id: ResourceId) => Promise<void>
export type FetchExternalDataAction = (resource_id: ResourceId, fn: ExternalFn) => Promise<void>

export type RuntimeContext = {
  name: string
  run: <T>(fn_to_run: RuntimeFn<T>) => Promise<T>
  run_cb: <T>(fn_to_run: RuntimeFn<T>, callback: RuntimeCallback<T>) => void
  clear_runner_task: () => void
  fork: (args?: {frozen?: boolean; name?: string}) => RuntimeContext
  freeze: (args?: {name?: string}) => RuntimeContext
  fetch_commits_action: FetchCommitsAction
  fetch_entity_headers_action: () => Promise<void>
  fetch_resource_action: FetchResourceAction
  fetch_external_data_action: FetchExternalDataAction
  get_runtime: () => Runtime
  get_storage_state: () => Storage
  dispatch: StorageDispatch
  mutable_dispatch: StorageDispatch
}

export type Fetchers = {
  fetch_resource: (
    resource_id: ResourceId,
    commit_id: CommitId
  ) => Promise<DownloadableResourceDataWithPermissions>
  fetch_commits: (
    zone_id: ZoneId
  ) => Promise<Record<ZoneId, MaybeError<Record<CommitId, CommitInfo>>>>
  fetch_entity_headers: (include_archived?: boolean) => Promise<EntityHeaders>
  fetch_external_data: (fn: ExternalFn) => Promise<ExternalResourceData>
}

function is_forkable(obj: Resource['result']): obj is CustomForkableResourceObject {
  return _.has(obj, '_fork')
}
function get_forked_resources(
  new_runtime_context: RuntimeContext,
  resources: Storage['resources']
): Storage['resources'] {
  return _.mapValues(resources, (resource) => {
    const {result} = resource
    // table object has the original `runtime_context` closured in itself
    // so we need to recreate object from the same data with `new_runtime_context`
    if (is_forkable(result)) {
      return ih_update(resource, {
        result: {$set: result._fork(new_runtime_context.get_runtime())},
      })
    }
    return resource
  })
}

// Enable/disable the with_logging decorator for client/server
const LOGGING_CLIENT_ENABLED = Number(process.env.REACT_APP_LOGS_ENABLED)
const LOGGING_SERVER_ENABLED = Number(process.env.bee_logs_server)

// Enable/disable the freezing state in logger mutable dispatch action mutates
// logged storage so logs show current state, instead of the the reality, when
// they were created. Enable it only when debugging since deep clone of storage
// is costly.
const LOGGING_DEEP_CLONE_ENABLED = Number(process.env.REACT_APP_LOGS_DEEP_CLONE_ENABLED)

/**
 * # Storage, Runtime and RuntimeContext
 * All relevant non-ui state is held in a single state object which is
 * usually called 'storage'. For example, table downloaded from the server,
 * filtered table (computed resource, which we want to keep), or sequence of
 * user changes are all kept in storage. The responsibilities of these components are:
 *
 * storage.ts: provides `get_resource` which returns the resource from the
 *   storage; it also fetches/computes and stores the resource in storage first,
 *   if needed. Furthermore, it's responsible for:
 *  - providing the resources in desirable commits
 *  - keeping track of dependencies for computed resources, handling errors that
 *    follow from faulty dependencise and handling cyclical dependencies
 *  - correctly resolving unknown resources id and unknown commits id
 *
 * Runtime: providing 'user-space' API on top of storage. For example,
 *   `get_table` or `get_project`.
 *
 * RuntimeContext: as runtime evolves via dispatches, RuntimeContext is holding
 * reference to current runtime. Runtime is tightly bound to the storage.
 * Dispatching the action using runtime_context.dispatch creates new storage and
 * new runtime, structure-sharing everything possible. OTOH
 * runtime_context.mutable_dispatch doesn't create new storage and
 * runtime--runtime_context keeps the old objects, just updates storage
 * in-place.
 *
 * This property is used not just to gain performance, but also to simplify the
 * code on a few places.
 *
 * The time-evolution of runtime looks then as such:
 *
 * runtime_context_a: runtime_1 -> runtime_2 -> runtime_3 -> ...
 *
 * This sequence is not neccessarily linear, since the forking is possible:
 *
 * runtime_context_a: runtime_a1 -> runtime_a2 ->  runtime_a3 -> runtime_a4...
 * runtime_context_b:                          \-> runtime_b1 -> runtime_b2...
 *
 * # Mutable vs immutable dispatch
 *
 * For the sake of performance, some operations mutate the state instead of
 * creating a new one. This affects mostly operations that just 'add new facts
 * to the system'. For example transition from {resource_a: 1} to {resource_a:
 * 1, resource_b: 2} can be done in a mutable way, since it only adds new value
 * to the system. On the contrary {resource_a: 1} to {resource_a: 2} needs to
 * create a new state.
 *
 * For changes that require immutability, `dispatch` is used; `mutable_dispatch`
 * is used for mutating the state.
 *
 * The current modus operandi for the client is as follows:
 * 1. User changes something, e.g. edits cell or opens new table
 * 2. This change is dispatched (in mutable or immutable manner)
 * 3. Client tries to materialize all the resources that are needed for
 *    rendering.
 * 4. If previous step cannot be done straighforwardly, because new
 *    (downloadable or computable) resources are required, they are obtained and
 *    mutable_dispatch-ed to storage. During this phase, we only add facts to
 *    the system, so using mutable dispatch is enough; storage and runtime are
 *    kept unchanged.
 * 5. After all resources are there, the final runtime is being forked and the
 *    fork is frozen (this means that no more dispatches on it are allowed).
 *    This frozen_runtime is used for rendering.
 * 6. When user does another change, the process repeat from step 1. Note that
 *    during 3 and 4, it takes some time to compute the new storage, so the old
 *    frozen_runtime (with some overlay indicating computation-in-progress is
 *    shown).
 *
 * Forking and freezing in step 5 are the only uses of forking and freezing we
 * currently do.
 *
 * @param initial_storage {Storage}
 * @param fetchers {Fetchers}
 * @param frozen=false {boolean}
 * @param name='' {string}
 * @param call_monitor=null {CallMonitor | null}
 * @return {RuntimeContext}
 */
function create_runtime_context(
  initial_storage: Storage,
  fetchers: Fetchers,
  frozen: boolean = false,
  name: string = '',
  call_monitor: CallMonitor | null = null
): RuntimeContext {
  let runtime: Runtime
  let runner_task: RuntimeTask<any> | null = null
  let currently_dispatched_action = null

  const {fetch_commits, fetch_entity_headers, fetch_resource, fetch_external_data} = fetchers

  const with_monitor = (name: string) => <T extends Function>(fn: T) =>
    call_monitor == null ? fn : call_monitor(fn, name)

  function _try_run_once() {
    if (runner_task == null) {
      return
    }
    const [fn_to_run, callback] = runner_task
    try {
      // Check if entity headers are fetched.
      runtime.get_headers()

      const result = fn_to_run(runtime)
      runner_task = null
      callback('done', result)
    } catch (err) {
      if (err.type === 'in-progress') {
        callback('in-progress')
      } else {
        runner_task = null
        callback('error', err)
      }
    }
  }

  const try_run_once = with_monitor('try_run_once')(_try_run_once)

  const try_run_once_later = () => setTimeout(try_run_once, 0)

  function get_runtime(): Runtime {
    return runtime
  }

  function get_storage_state(): Storage {
    return runtime.storage
  }

  function clear_runner_task() {
    if (runner_task != null) {
      const [, callback] = runner_task
      runner_task = null
      callback('killed', 'task-was-killed')
    }
  }

  function run_cb<T>(fn_to_run: RuntimeFn<T>, callback: RuntimeCallback<T>) {
    if (runner_task != null) {
      const [, callback] = runner_task
      callback('killed', 'task-was-killed')
    }
    runner_task = [fn_to_run, callback]
    try_run_once()
  }

  /**
   * @name run
   * @param fn_to_run RuntimeFn
   */
  function run<T>(fn_to_run: RuntimeFn<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      run_cb(fn_to_run, (status, result) => {
        if (status === 'done') {
          resolve(result as T)
        } else if (status === 'error' || status === 'killed') {
          reject(result)
        } else {
          //pass
        }
      })
    })
  }

  function fork({frozen = false, name = ''} = {}) {
    // since object resources (i.e. not-just-data resources) may have old-runtime (and old-storage)
    // closured within them--we need to replace them with new ones, with proper new-runtime
    // closured. This creates chicken-egg problem, because new-storage needs to reference the
    // new-resources, but new-resources need to reference the new-storage.
    //
    // We proceed in simple steps:
    // - create new-storage as a shallow copy of the old one, i.e. with old-resources. This is
    // benefitial because e.g. entity_headers are already there
    // - create new-resources by forking old-resoures
    // - update the new-storage with new-resources
    const storage = _.clone(get_storage_state())
    const forked_runtime_context = create_runtime_context(
      storage,
      fetchers,
      frozen,
      name,
      call_monitor
    )

    const forked_storage = {
      ...storage,
      resources: get_forked_resources(forked_runtime_context, storage.resources),
      commits: _.clone(get_storage_state().commits),
    }
    _.assign(storage, forked_storage)
    return forked_runtime_context
  }

  function freeze({name = ''} = {}) {
    return fork({frozen: true, name})
  }

  function colorize_text(text: string, color: string) {
    return [`%c${text}`, `color: ${color}`]
  }

  const with_logging_client = (dispatch) => (arg) => {
    if (arg.length === 1) {
      console.groupCollapsed('Dispatching type %s', arg[0].type)
    } else {
      console.groupCollapsed('Dispatching', arg)
    }
    if (!LOGGING_DEEP_CLONE_ENABLED) {
      console.info(
        'State can be mutated, which makes logs inaccurate. For debugging set LOGGING_DEEP_CLONE_ENABLED to true.'
      )
    }
    console.log(
      ...colorize_text('prev state%o', '#9E9E9E'),
      LOGGING_DEEP_CLONE_ENABLED ? _.cloneDeep(get_storage_state()) : get_storage_state()
    )
    console.log(...colorize_text('action    %o', '#03A9F4'), arg[0] || arg)
    try {
      dispatch(arg)
    } finally {
      console.log(
        ...colorize_text('next state%o', '#4CAF50'),
        LOGGING_DEEP_CLONE_ENABLED ? _.cloneDeep(get_storage_state()) : get_storage_state()
      )
      console.groupEnd()
    }
  }

  const with_logging_server = (dispatch) => (arg) => {
    if (arg.length === 1) {
      console.groupCollapsed('Dispatching type %s', arg[0].type)
    } else {
      console.groupCollapsed('Dispatching %o', arg)
    }
    console.log('Action %o', arg[0] || arg)
    try {
      dispatch(arg)
    } finally {
      console.log('State after dispatch', JSON.stringify(get_storage_state(), null, 2))
      console.groupEnd()
    }
  }

  const with_logging = (dispatch_fn) => (arg) => {
    // @ts-ignore
    if (typeof window !== 'undefined') {
      const fn = LOGGING_CLIENT_ENABLED ? with_logging_client(dispatch_fn) : dispatch_fn
      fn(arg)
    } else {
      const fn = LOGGING_SERVER_ENABLED ? with_logging_server(dispatch_fn) : dispatch_fn
      fn(arg)
    }
  }

  // Reject nested dispatching or dispatching on frozen context
  const with_safety_checks = (dispatch_fn) => (arg) => {
    if (frozen) {
      throw_error({type: 'dispatch-on-frozen'}, 'Dispatch on frozen!', 'action', arg)
    }
    ensure(!currently_dispatched_action, 'Nested dispatches detected', {
      currently_dispatched_action,
      action: arg,
    })
    try {
      currently_dispatched_action = arg
      dispatch_fn(arg)
    } finally {
      currently_dispatched_action = null
    }
  }

  const with_actions_type_check = (allowed_actions) => (dispatch_fn) => (arg) => {
    ensure(
      arg.every((change) => allowed_actions.has(change.type)),
      'Changes are dispatched with wrong type of dispatch',
      {
        allowed_actions,
        dispatched_actions: arg,
      }
    )
    dispatch_fn(arg)
  }

  // dispatched changes create new storage_state in immutability manner
  // after changes are applied new runtime is set
  const dispatch = compose(
    with_monitor('dispatch'),
    with_safety_checks,
    with_actions_type_check(IMMUTABLE_ACTIONS),
    with_logging
  )((changes: ActionPayload[]) => {
    const new_storage_state = changes.reduce(apply_change, runtime.storage)
    //const new_storage_state = fn(runtime.storage)
    // create_runtime has a side_effect of setting the newly-created runtime as a current runtime
    // for given runtime_context. Doing so is unneccessary in mutable_dispatch defined below.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    runtime = create_runtime(new_storage_state, runtime_context)
  })

  // dispatched changes mutate storage_state resource part
  // after changes are applied runtime is the same, but uses mutated storage_state
  const mutable_dispatch = compose(
    with_monitor('mutable_dispatch'),
    with_safety_checks,
    with_actions_type_check(MUTABLE_ACTIONS),
    with_logging
  )((changes: ActionPayload[]) => {
    changes.reduce(apply_change, runtime.storage)
  })

  async function fetch_commits_action(zone_id: ZoneId, fixed_date?: Date) {
    const commits = await fetch_commits(zone_id)
    mutable_dispatch([
      {
        type: 'update-commits',
        commits,
      },
    ])

    const latest_versions_of_fetched_commits = get_latest_versions(commits, fixed_date)
    const versions_in_storage = get_storage_state().versions

    const versions_need_update = Object.keys(latest_versions_of_fetched_commits).some(
      (zone_id) => latest_versions_of_fetched_commits[zone_id] !== versions_in_storage[zone_id]
    )

    if (!get_storage_state().history_mode && versions_need_update) {
      dispatch([
        {
          // fixed_versions are applied in "set-latest-versions"
          // that's why it is used instead of "set-versions" with latest versions as argument
          // it could be changed once we don't use fix_versions for tests
          type: 'set-latest-versions',
        },
      ])
    }
    try_run_once_later()
  }

  async function fetch_entity_headers_action() {
    const entity_headers = await fetch_entity_headers(runtime.storage.include_archived)
    mutable_dispatch([
      {
        type: 'update-entity-headers',
        entity_headers,
      },
    ])
    try_run_once_later()
  }

  function handle_error(e: any, additional_data: {resource_id?: string; commit_id?: string}) {
    if (is_error(e)) {
      const {type} = e
      const {resource_id, commit_id} = additional_data
      switch (type) {
        case 'in-progress':
          break // pass
        case 'network-error':
        case 'missing-privileges':
          mutable_dispatch([{type: 'set-resource-result', resource_id, commit_id, result: e}])
          break
        default:
          throw_error('Unknown error type', 'type', type)
      }
    } else {
      throw_error('Invariant violation', e)
    }
  }

  async function fetch_resource_action(commit_id: CommitId, resource_id: ResourceId) {
    try {
      const {resource, permissions} = await fetch_resource(resource_id, commit_id)
      mutable_dispatch([{type: 'set-resource-result', resource_id, commit_id, result: resource}])
      mutable_dispatch([
        {type: 'set-permissions-result', resource_id, commit_id, result: permissions},
      ])
    } catch (e) {
      handle_error(e, {resource_id, commit_id})
    } finally {
      try_run_once_later()
    }
  }

  async function fetch_external_data_action(resource_id: ResourceId, fn: ExternalFn) {
    try {
      const {data: result} = await fetch_external_data(fn)
      dispatch([{type: 'set-external-data-final-result', resource_id, result}])
    } catch (e) {
      handle_error(e, {resource_id})
    } finally {
      try_run_once_later()
    }
  }

  // runtime_context API
  const runtime_context: RuntimeContext = {
    // async run(task(runtime)). Runs synchronous function `task`, allowing it to access whatever
    // data from the storage it needs. If the data is not already there, the data is fethced and the
    // function is re-runned once the data is obtained. The `run` function ends asynchronously once
    // the whole `task` succesfully ran.
    run,
    // run_cb(task, callback(status, result)) callback-version of `run`. Only one task can run at a
    // time, if second task is being runned, the previous one is aborted.
    run_cb,
    // terminate the computation (useful for useEffect clean_up function)
    clear_runner_task,
    // create the new runtime_context that shares its initial state with the current one
    fork,
    // same as fork, but the newly created runtime_context will throw if anyone tries to modify it
    // via `dispatch`
    freeze,
    // the following methods do the similar thing:
    // - asynchronously fetch something
    // - dispatch the result to the actual state
    fetch_commits_action,
    fetch_entity_headers_action,
    fetch_resource_action,
    fetch_external_data_action,
    // for the debug purposes, it may be handy to name your runtime_context
    name,
    // private methods:
    get_runtime,
    dispatch,
    mutable_dispatch,
    get_storage_state,
  }

  runtime = create_runtime(initial_storage, runtime_context)
  try_run_once()

  // Headers can be initialized, e.g. when ctx is forked.
  if (initial_storage.downloads.entity_headers == null) {
    fetch_entity_headers_action()
    mutable_dispatch([{type: 'new-entity-headers'}])
  }

  return runtime_context
}

export {create_runtime_context}
