import _ from 'lodash'
import {col_id_on_index, row_id_on_index} from '../utils/table_helpers'
import {CellRawValue, RowId} from 'common/types/storage'
import {is_error} from 'common/error'
import {Dimensions, Position} from './layout'

const PASTE_SENTINEL = '__bee__paste_sentinel'

export const serialize_selection = (
  table,
  top_left: Position,
  size: Dimensions,
  rows_order: RowId[]
) => {
  const json_data = {[PASTE_SENTINEL]: true, data: [] as CellRawValue[][]}
  const plain_text_data: string[] = []

  for (let r = 0; r < size[1]; r++) {
    const row_index = top_left[1] + r
    const is_header = row_index < 0
    const row_id = !is_header && row_id_on_index(rows_order, row_index)

    const json_row: string[] = []
    const text_row: CellRawValue[] = []
    for (let c = 0; c < size[0]; c++) {
      const col_index = top_left[0] + c

      const is_row_number = col_index < 0
      if (is_row_number) {
        const row = row_index + 1
        text_row.push(row)
        json_row.push(row.toString())
        continue
      }

      const col_id = col_id_on_index(table, col_index)
      const col_spec = table._cols[col_id]

      if (is_header) {
        text_row.push(col_spec.name)
        json_row.push(col_spec.name)
        continue
      }

      const cell = table._cell(row_id, col_id)
      const cell_as_string = cell.get_label()
      const cell_json = cell.raw_value
      switch (col_spec.type.type) {
        case 'option':
          json_row.push(
            cell.map((sub_cell) =>
              is_error(sub_cell.error) ? sub_cell.raw_value : sub_cell.get_label()
            )
          )
          break
        case 'date':
        case 'number':
          json_row.push(is_error(cell.error) ? cell_json : cell_as_string)
          break
        default:
          json_row.push(cell_json)
      }
      text_row.push(String(cell_as_string).replace(/[\r\n\t]+/g, ' '))
    }
    json_data.data.push(json_row)
    plain_text_data.push(text_row.join('\t'))
  }
  const plain_text = plain_text_data.join('\n')
  return {plain_text, json_data}
}

export const write_clipboard_data = (
  clipboardData: DataTransfer,
  table,
  top_left,
  size,
  rows_order
) => {
  const {json_data, plain_text} = serialize_selection(table, top_left, size, rows_order)

  clipboardData.setData('text/plain', plain_text)
  clipboardData.setData('text/json', JSON.stringify(json_data))
}

export const extract_clipboard_data = (clipboardData: DataTransfer) => {
  if (clipboardData.types.includes('text/json')) {
    const clipboard_json = clipboardData.getData('text/json')
    const grid_data = JSON.parse(clipboard_json)
    if (grid_data && grid_data[PASTE_SENTINEL] && grid_data.data && grid_data.data.length > 0) {
      return grid_data.data
    }
  }

  if (clipboardData.types.includes('text/plain')) {
    // get rid of trailing newline that some spreadsheet editors include when copying
    const clipboard_plaintext = _.trimEnd(clipboardData.getData('text/plain'), '\r\n')
    const split_text = clipboard_plaintext.split(/\r?\n/).map((r) => r.split('\t'))
    return split_text
  }

  return null
}
