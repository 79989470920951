import React, {FC, useCallback, useMemo, useState} from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {ParamValueEditor} from './ParamValueEditor'
import CustomButton from '../components/CustomButton'
import {CellRawValue, CellType, ColumnSpec, ColumnType} from 'common/types/storage'
import {
  ComputedParams,
  ComputedParamsSpec,
  remove_param_spec,
  update_param,
  check_params_errors,
} from 'common/params_utils'
import _ from 'lodash'
import ParamForm from './ParamForm'
import {useRuntimeActions} from '../RuntimeContextProvider'
import CustomIconButton from '../components/CustomIconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import {get_icon} from '../utils/icons_utils'
import {ErrorObject} from 'common/error'
import {uuid} from 'common/utils'

type ParamsEditorProps = {
  on_request_close: () => void
}

const ParamsTable = styled(Table)({
  marginBottom: '1rem',
  marginTop: '1rem',
})

const StyledCardActions = styled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2, 2, 2),
}))

const ParamNameWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '1rem',
})

const useCellStyles = makeStyles({
  leading: {
    padding: '0.5rem 0.5rem 0.5rem 0rem',
  },
  inner: {
    padding: '0.5rem 0.5rem 0.5rem 0.5rem',
  },
  trailing: {
    padding: '0.5rem 0rem 0.5rem 0.5rem',
  },
})

const base_types: ColumnType<CellType, 'entity'>[] = [
  {type: 'number'},
  {type: 'string'},
  {type: 'boolean'},
  {type: 'date'},
  {type: 'date_time'},
  {type: 'markdown'},
]

const ParamsEditor: FC<ParamsEditorProps> = ({on_request_close}) => {
  const {dispatch_storage} = useRuntimeActions()
  const {
    resources: {
      table_resources: {table_entity_id, full_table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const params = useTableUiSelector(table_entity_id, 'params') || {}
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const [local_params, set_local_params] = useState<ComputedParams>(params)
  const [params_spec, set_params_spec] = useState<ComputedParamsSpec | undefined>(full_table.params)
  const [values_errors, set_values_errors] = useState<Record<string, ErrorObject>>({})

  const options_types = useMemo(() => {
    const entries = _.filter(
      Object.entries(full_table._cols),
      ([, spec]) => spec.type.type === 'option'
    )
    return _.reduce(
      entries,
      (result, [, spec]) => {
        return [...result, spec]
      },
      []
    ) as ColumnSpec<CellType, 'entity'>[]
  }, [full_table._cols])

  const reference_types = useMemo(() => {
    const entries = _.filter(
      Object.entries(full_table._cols),
      ([, spec]) => spec.type.type === 'reference'
    )
    return _.reduce(
      entries,
      (result, [, spec]) => {
        return [...result, spec]
      },
      []
    ) as ColumnSpec<CellType, 'entity'>[]
  }, [full_table._cols])

  const update_params = useCallback(
    (key: string, value?: CellRawValue) => {
      const new_params = update_param(local_params, key, value)
      set_local_params(new_params)
    },
    [local_params]
  )

  const save_params = useCallback(
    (params: ComputedParams, spec?: ComputedParamsSpec) => {
      set_table_ui_state({params: {...params}}, 'params')
      dispatch_storage(full_table._actions.change_params(spec))
    },
    [dispatch_storage, set_table_ui_state, full_table._actions]
  )

  const apply_params = useCallback(() => {
    const errors = check_params_errors(local_params, params_spec)

    set_values_errors(errors)
    if (_.isEmpty(errors)) {
      save_params(local_params, params_spec)
      on_request_close()
    }
  }, [on_request_close, save_params, local_params, params_spec])

  const _add_param = useCallback(
    (name: string, type: ColumnType<CellType, 'entity'>) => {
      set_params_spec((old_spec) => ({...old_spec, [uuid()]: {type, name}}))
    },
    [set_params_spec]
  )

  const _remove_param = useCallback(
    (name: string) => {
      const new_specs = remove_param_spec(name, params_spec)
      const new_params = update_param(local_params, name)
      set_params_spec(new_specs)
      set_local_params(new_params)
    },
    [params_spec, local_params]
  )

  const clear_params = useCallback(() => {
    save_params({}, params_spec)
    on_request_close()
  }, [save_params, params_spec, on_request_close])

  const cell_classes = useCellStyles()

  return (
    <Card>
      <CardContent>
        <ParamForm
          param_types={base_types}
          options={options_types}
          references={reference_types}
          existing_params={params_spec}
          on_add_param={_add_param}
        />
        {params_spec && (
          <ParamsTable size="small">
            <TableBody>
              {Object.entries(params_spec).map(([id, {name, type}]) => {
                return (
                  <TableRow key={id}>
                    <TableCell padding="none" align="center" className={cell_classes.leading}>
                      <ParamNameWrapper>
                        <i
                          style={{width: 15, padding: 0, opacity: 0.5}}
                          className={get_icon(type.type, false)}
                        />
                        <Typography> {name} </Typography>
                      </ParamNameWrapper>
                    </TableCell>
                    <TableCell padding="none" className={cell_classes.inner}>
                      <ParamValueEditor
                        end_edit={() => {}}
                        set_value={(new_value) => {
                          update_params(name, new_value)
                        }}
                        value_type={type}
                        value={local_params[name]}
                      />
                      <Collapse in={values_errors[id] !== undefined}>
                        <Typography
                          variant="body2"
                          style={{fontStyle: 'italic', fontSize: '0.75rem', color: 'red'}}
                        >
                          {values_errors[id]?.message}
                        </Typography>
                      </Collapse>
                    </TableCell>
                    <TableCell padding="none" className={cell_classes.trailing}>
                      <CustomIconButton
                        onClick={() => {
                          _remove_param(id)
                        }}
                        tooltip="delete param"
                      >
                        <DeleteIcon fontSize="small" />
                      </CustomIconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </ParamsTable>
        )}
      </CardContent>
      <StyledCardActions>
        <CustomButton type="primary" onClick={apply_params}>
          Apply
        </CustomButton>
        <CustomButton type="tertiary" onClick={clear_params} disabled={_.isEmpty(local_params)}>
          Reset all params
        </CustomButton>
      </StyledCardActions>
    </Card>
  )
}

export default ParamsEditor
