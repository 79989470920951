import record_diff from '../base/record_diff'
import constant_diff from '../base/constant_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import {
  type_decorate_apply,
  type_decorate_reverse,
  type_decorate_ensure_consistency,
  type_decorate_unapply,
  type_decorate_squash2,
  type_decorate_change_diff,
  type_decorate_clean_neutral_diffs,
} from './entity_type_helpers'
import {create_squash} from '../base/create_squash'

const {
  apply: _apply,
  reverse: _reverse,
  ensure_consistency: _ensure_consistency,
  unapply: _unapply,
  squash2: _squash2,
  change_diff: _change_diff,
  is_conflict,
  clean_neutral_diffs: _clean_neutral_diffs,
} = record_diff({
  type: constant_diff(),
  name: conflict_free_value_diff(),
  entity_id: constant_diff(),
  zone_id: constant_diff(),
  parent_id: constant_diff(),
})

const apply = type_decorate_apply('organisation', _apply)
const reverse = type_decorate_reverse('organisation', _reverse)
const ensure_consistency = type_decorate_ensure_consistency('organisation', _ensure_consistency)
const unapply = type_decorate_unapply('organisation', _unapply)
const squash2 = type_decorate_squash2('organisation', _squash2)
const change_diff = type_decorate_change_diff('organisation', _change_diff)
const clean_neutral_diffs = type_decorate_clean_neutral_diffs('organisation', _clean_neutral_diffs)

export default {
  apply,
  reverse,
  ensure_consistency,
  unapply,
  squash: create_squash(squash2),
  change_diff,
  create_diff: (entity) => change_diff({type: 'organisation'}, entity),
  is_conflict,
  clean_neutral_diffs,
}
