import React, {FC, useCallback, useState} from 'react'
import _ from 'lodash'
import ih from 'immutability-helper'
import {uuid} from 'common/utils'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Option from './Option'
import {Button, styled} from '@material-ui/core'
import {OptionId, Options} from 'common/types/storage'

const OptionsContainer = styled('div')({
  display: 'inline-block',
})

const validate_option_name = (options: Options, name: string) => {
  const trimmed_name = name.trim()
  if (!trimmed_name) {
    return 'Option name must be set.'
  }

  if (Object.values(options).filter((o) => o.name === trimmed_name).length > 1) {
    return 'Option name must be unique.'
  }

  if (trimmed_name.search(';') !== -1) {
    return 'Option cannot contain ";".'
  }

  return ''
}

export const validate_options = (options: Options) => {
  if (_.isEmpty(options)) {
    return 'At least one option must be defined!'
  }

  for (const option_id of Object.keys(options)) {
    const validation_message = validate_option_name(options, options[option_id].name)
    if (validation_message) {
      return validation_message
    }
  }
  return ''
}

const _remove_option = (options: Options, option_id: OptionId) => {
  return ih(options, {$unset: [option_id]})
}

const _change_option_name = (options: Options, option_id: OptionId, new_name: string) => {
  return ih(options, {[option_id]: {$set: {name: new_name}}})
}

const _add_option = (options: Options, option_id: OptionId) => {
  return ih(options, {[option_id]: {$set: {name: ''}}})
}

type OptionsEditorProps = {
  options: Options
  on_options_change: (options: Options) => void
  disabled: boolean
}

const OptionsEditor: FC<OptionsEditorProps> = ({options, on_options_change, disabled}) => {
  const [focused_option, set_focused_option] = useState<OptionId | null>(null)

  const change_option_name = useCallback(
    (option_id: OptionId, new_name: string) => {
      on_options_change(_change_option_name(options, option_id, new_name))
      set_focused_option(null)
    },
    [on_options_change, options]
  )

  const remove_option = useCallback(
    (option_id: OptionId) => {
      if (Object.keys(options).length <= 1) {
        set_focused_option(null)
      } else {
        const option_idx = Object.keys(options).findIndex((id) => id === option_id)
        const option_idx_to_focus = option_idx === 0 ? 1 : option_idx - 1

        set_focused_option(Object.keys(options)[option_idx_to_focus])
      }

      on_options_change(_remove_option(options, option_id))
    },
    [on_options_change, options]
  )

  const add_option = useCallback(() => {
    const new_option_id = uuid()
    on_options_change(_add_option(options, new_option_id))
    set_focused_option(new_option_id)
  }, [on_options_change, options])

  return (
    <OptionsContainer>
      {_.map(options, ({name}, option_id) => (
        <Option
          key={option_id}
          name={name}
          id={option_id}
          focused={option_id === focused_option}
          add_option={add_option}
          remove_option_with_id={remove_option}
          on_change={change_option_name}
          validation_message={validate_option_name(options, name)}
          input_disabled={disabled}
          button_disabled={disabled}
        />
      ))}
      {!disabled && <Button onClick={add_option}>+ Add new option</Button>}
    </OptionsContainer>
  )
}

export default OptionsEditor
