import React, {FC, KeyboardEvent, useCallback, useEffect, useRef, useState} from 'react'
import {
  CircularProgress,
  ClickAwayListener,
  Collapse,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CustomButton from '../components/CustomButton'
import {useUploadFile} from './upload_file_hook'
import _ from 'lodash'
import DeleteIcon from '@material-ui/icons/Delete'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {has_write_permissions} from 'common/permission/permission_utils'
import {TableObject} from 'common/objects/data_table'

type UploadIndicatorProps = {file_name?: string}
const UploadIndicator: FC<UploadIndicatorProps> = ({file_name = ''}) => {
  const useStyles = makeStyles(({palette}) => ({
    wrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 0rem',
    },
    file_name: {
      maxWidth: '10rem',
      margin: '0rem',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'auto',
    },
  }))
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Typography variant="subtitle1" className={classes.file_name}>
        {file_name}
      </Typography>
      <CircularProgress color="inherit" size={24} />
    </div>
  )
}

type AttachmentFileProps = {
  url: string
  on_remove?: () => void
}
const AttachmentFile: FC<AttachmentFileProps> = ({url, on_remove = undefined}) => {
  const useStyles = makeStyles({
    file_root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      margin: '0',
      height: '3rem',
    },
    file_name: {
      maxWidth: '10rem',
      textOverflow: 'ellipsis',
      overflow: 'auto',
    },
  })
  const classes = useStyles()
  const file_name = url.split('/').pop() ?? ''
  return (
    <div className={classes.file_root}>
      <Link className={classes.file_name} href={url}>
        {file_name}
      </Link>
      {on_remove && (
        <IconButton aria-label="Remove" onClick={on_remove}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
}

type AttachmentUploadErrorProps = {
  title: string
  message: string
}

const AttachmentUploadError: FC<AttachmentUploadErrorProps> = ({title, message}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-evenly',
      backgroundColor: `${theme.palette.error.light}`,
      flex: 1,
      borderRadius: 4,
      padding: '0.125rem 0.25rem',
      margin: '0.25rem 0rem',
    },
    message_base: {
      color: `${theme.palette.error.main}`,
      textAlign: 'center',
      fontSize: 'small',
    },
    message_bold: {
      fontWeight: 'bold',
    },
  }))
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="body2" className={`${classes.message_base} ${classes.message_bold} `}>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.message_base}>
        {message}
      </Typography>
    </div>
  )
}

type AttachmentEditorProps = {
  end_edit: () => void
  value: string
  set_value: (value: string) => void
  table: TableObject
}

const AttachmentEditor: FC<AttachmentEditorProps> = ({end_edit, value, set_value, table}) => {
  const root_ref = useRef<any>(null)
  const [uploaded_file, set_uploaded_file] = useState<string>(value)
  const [current_file, set_current_file] = useState<File>()
  const [error, set_error] = useState<{title: string; message: string} | null>(null)
  const {runtime} = useRuntimeSelector() as AlwaysDefinedRuntime
  const has_permissions = has_write_permissions(runtime, table._entity_id)
  useEffect(() => {
    root_ref?.current?.focus()
  }, [root_ref])
  const handle_error = useCallback((file: File, message: string) => {
    set_error({title: file.name, message})
  }, [])
  const handle_success = useCallback((file: File, url: string) => {
    set_uploaded_file(url)
  }, [])
  const [upload, loading] = useUploadFile({on_error: handle_error, on_success: handle_success})
  const on_close = useCallback(() => {
    end_edit()
  }, [end_edit])
  const on_save = useCallback(() => {
    if (!loading && value !== uploaded_file) {
      set_value(uploaded_file)
    }
    end_edit()
  }, [uploaded_file, end_edit, set_value, value, loading])

  const key_down = (event: KeyboardEvent): void => {
    switch (event.key) {
      case 'Escape': {
        on_save()
        on_close()
        break
      }
      default:
    }
    event.stopPropagation()
  }

  const handle_change = (event) => {
    const target = event.target as HTMLInputElement
    set_error(null)
    if (target?.files && !_.isEmpty(target?.files)) {
      set_current_file(target.files[0])
      upload(target.files[0])
    }
  }

  const reset_input_value = (event) => {
    event.target.value = ''
  }

  const remove_attachment = () => {
    set_uploaded_file('')
  }

  const useStyles = makeStyles((theme) => ({
    root_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      width: '100%',
      padding: theme.spacing(1),
      border: `2px solid ${theme.palette.greenPalette[400]}`,
      outline: 'none',
      overflow: 'hidden',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      height: '3rem',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    permissions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    content: {
      flex: 1,
      overflow: 'auto',
      maxHeight: '5rem',
      padding: '0rem',
    },
    actions: {
      height: '2rem',
      display: 'flex',
      gap: '1rem',
      margin: '0.25rem 0rem',
    },
  }))
  const classes = useStyles()

  const controls = (
    <div className={classes.controls}>
      <CustomButton type="tertiary" fullWidth={false} disabled={loading}>
        <label
          htmlFor="upload_input"
          style={{width: '100%'}}
          onChange={handle_change}
          onClick={reset_input_value}
        >
          <input id="upload_input" hidden name="" type="file" disabled={loading} />
          select file
        </label>
      </CustomButton>
      {loading && current_file && <UploadIndicator file_name={current_file.name} />}
      {!loading && uploaded_file && (
        <AttachmentFile
          url={uploaded_file}
          on_remove={has_permissions ? remove_attachment : undefined}
        />
      )}
    </div>
  )
  const missing_permissions = (
    <div className={classes.permissions}>
      <Typography variant="subtitle1">You don't have a permissions</Typography>
    </div>
  )

  return (
    <ClickAwayListener onClickAway={on_close}>
      <div className={classes.root_wrapper} ref={root_ref} tabIndex={0} onKeyDown={key_down}>
        {has_permissions ? controls : missing_permissions}
        <div className={classes.content}>
          <Collapse in={!!error} mountOnEnter>
            <AttachmentUploadError title={error?.title ?? ''} message={error?.message ?? ''} />
          </Collapse>
        </div>
        <div className={classes.actions}>
          <CustomButton
            type="primary"
            fullWidth={false}
            disabled={loading || uploaded_file === value}
            onClick={on_save}
          >
            save
          </CustomButton>
          <CustomButton type="secondary" fullWidth={false} onClick={on_close}>
            cancel
          </CustomButton>
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default AttachmentEditor
