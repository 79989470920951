import React, {FC, useCallback, useEffect, useRef} from 'react'
import {FormControl, makeStyles, MenuItem, Select, Typography} from '@material-ui/core'
import {
  BooleanFormatId,
  get_boolean_format_values,
  get_boolean_format_by_value,
} from 'common/formatting/boolean'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: (props: {highlight?: boolean}) => ({
    width: '100%',
    backgroundColor: 'white',
    border: `${
      props.highlight
        ? `2px solid ${theme.palette.greenPalette[600]}`
        : `1px solid ${theme.palette.greyPalette[100]}`
    }`,
    borderRadius: '.25rem',
  }),
  item_text: {
    width: '100%',
    padding: '0.125rem 1rem',
    margin: '0rem',
  },
}))

const useSelectStyles = makeStyles(({palette}) => ({
  icon: {
    fill: `${palette.greenPalette[600]} `,
  },
}))

type BooleanEditorProps = {
  end_edit: () => void
  value: string
  set_value: (value: string) => void
  boolean_format_id?: BooleanFormatId
  autofocus?: boolean
  highlight?: boolean
}
const BooleanEditor: FC<BooleanEditorProps> = ({
  value,
  set_value,
  end_edit,
  autofocus = true,
  highlight = false,
  boolean_format_id,
}) => {
  const select_ref = useRef<any>(null)
  const column_format = get_boolean_format_values(boolean_format_id)
  const value_format = get_boolean_format_by_value(value)
  useEffect(() => {
    select_ref?.current?.focus()
  }, [select_ref])

  const handle_change = useCallback(
    (event) => {
      set_value(event.target.value)
      end_edit()
    },
    [set_value, end_edit]
  )

  const on_close = useCallback(() => {
    end_edit()
  }, [end_edit])

  const get_value_or_default = (value: string): string => {
    if (_.isEmpty(value) || !value_format) {
      return ''
    }
    return value_format[0] === value ? column_format[0] : column_format[1]
  }

  const value_or_default = get_value_or_default(value)
  const classes = useStyles({highlight})
  const select_classes = useSelectStyles()
  return (
    <FormControl className={classes.root}>
      <Select
        disableUnderline
        value={value_or_default}
        onChange={handle_change}
        onBlur={on_close}
        ref={select_ref}
        classes={select_classes}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
        }}
        autoFocus={autofocus}
      >
        {column_format.map((choice) => (
          <MenuItem key={choice} value={choice}>
            <Typography className={classes.item_text} variant="subtitle1">
              {choice}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BooleanEditor
