import _ from 'lodash'

import {throw_error} from '../../utils'
import {DiffSpec} from './diff_type'
import {create_squash} from './create_squash'
import {$$, thread} from 'vacuumlabs-threading'
import {Nullable} from '../../types/storage'

const value_sentinel = '__bee_value_sentinel'

export type ValueConflict<T> = [Nullable<T>, Nullable<T>]
export type ValueConflicts<T> = ValueConflict<T>[]
export type ValueDiff<T> = {
  old_value: Nullable<T>
  new_value: Nullable<T>
  conflicts?: ValueConflicts<T>
  resolvers?: ValueConflicts<T>
}

type EncapsulatedValue<T> = {
  [value_sentinel]: true
  value: Nullable<T>
  conflicts: ValueConflicts<T>
}
type Value<T> = EncapsulatedValue<T> | T
type ValueDiffSpec<T> = DiffSpec<
  ValueDiff<T>,
  Nullable<Value<T>>,
  Nullable<T>,
  ValueConflicts<T>
> & {
  is_value_type: (diff: unknown) => diff is ValueDiff<any>
  get_value: (value: Value<T> | null) => Nullable<T>
  get_old_value: (diff: ValueDiff<T>) => Value<T> | null
  get_new_value: (diff: ValueDiff<T>) => Value<T> | null
  value_sentinel: typeof value_sentinel
}

function union<T>(col1: ValueConflicts<T>, col2: ValueConflicts<T>): ValueConflicts<T> {
  return _.unionWith(col1, col2, _.isEqual)
}

function difference<T>(col1: ValueConflicts<T>, col2: ValueConflicts<T>): ValueConflicts<T> {
  return _.differenceWith(col1, col2, _.isEqual)
}

function is_value_type(diff: unknown): diff is ValueDiff<any> {
  return typeof diff === 'object' && diff != null && 'old_value' in diff && 'new_value' in diff
}

function ensure_diff(diff: unknown) {
  if (!is_value_type(diff)) {
    throw_error('Given object is not valid diff.', 'diff', diff)
  }
}

function is_clean(diff): boolean {
  ensure_diff(diff)
  const {conflicts: c = [], resolvers: r = []} = diff
  return _.isEmpty(c) && _.isEmpty(r)
}

export default function value_diff<T = any>(delete_empty: boolean = true): ValueDiffSpec<T> {
  // Use only for typechecking purposes
  // Value might have been encapsulated even if there is no conflict
  function is_encapsulated(value: Value<T> | null): value is EncapsulatedValue<T> {
    return !!value?.[value_sentinel]
  }

  function encapsulate(value: Value<T> | null): EncapsulatedValue<T> {
    if (is_encapsulated(value)) {
      return value
    } else {
      return {[value_sentinel]: true, value, conflicts: []}
    }
  }

  function simplify(value: Value<T> | null): Value<T> | null {
    if (is_encapsulated(value)) {
      const {value: o, conflicts: c} = value
      if (c.length === 0) {
        return o
      }
    }
    return value
  }

  function get_old_value(diff: ValueDiff<T>): Value<T> | null {
    ensure_diff(diff)
    return diff.old_value
  }

  function get_new_value(diff: ValueDiff<T>): Value<T> | null {
    ensure_diff(diff)
    return diff.new_value
  }

  function change_diff(old_value: Value<T> | null, new_value: Value<T> | null): ValueDiff<T> {
    const {value: o1, conflicts: c1} = encapsulate(old_value)
    const {value: n2, conflicts: c2} = encapsulate(new_value)
    if ((_.isEmpty(c1) && _.isEmpty(c2)) || _.isEqual(c1, c2)) {
      return {
        old_value: o1,
        new_value: n2,
      }
    }
    return {
      old_value: o1,
      new_value: n2,
      conflicts: difference(c2, c1),
      resolvers: difference(c1, c2),
    }
  }

  function apply(old_value: Value<T> | null, diff: ValueDiff<T>): Value<T> | null {
    ensure_diff(diff)
    const {value: o1, conflicts: c1} = encapsulate(old_value)
    const {old_value: o2, new_value: n2, conflicts: c2 = [], resolvers: r2 = []} = diff
    const l = _.isEqual(o1, o2) ? [] : [[o1, o2]]
    const res: EncapsulatedValue<T> = {
      [value_sentinel]: true,
      value: n2,
      conflicts: thread(c1, [union, $$, l], [difference, $$, r2], [union, $$, c2]),
    }
    return simplify(encapsulate(res))
  }

  function reverse(diff: ValueDiff<T>): ValueDiff<T> {
    if (!is_clean(diff))
      throw_error(
        'Value diff reverse problem: diff with conflicts can not be reversed',
        'diff',
        diff
      )
    return change_diff(get_new_value(diff), get_old_value(diff))
  }

  function ensure_consistency(value: Value<T> | null, diff: ValueDiff<T>): void {
    is_clean(diff)
    if (!_.isEqual(value, get_old_value(diff)))
      throw_error(
        'Value diff consistency problem: Can not apply diff without causing conflicts',
        'value',
        value,
        'diff',
        diff
      )
  }

  function unapply(
    value: Value<T> | null,
    diff: ValueDiff<T>,
    ensure: boolean = true
  ): Value<T> | null {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(value, reversed_diff)
    return apply(value, reversed_diff)
  }

  function squash2(diff1: ValueDiff<T>, diff2: ValueDiff<T>): ValueDiff<T> {
    ensure_diff(diff1)
    ensure_diff(diff2)
    const {old_value: o1, new_value: n1, conflicts: c1 = [], resolvers: r1 = []} = diff1
    const {old_value: o2, new_value: n2, conflicts: c2 = [], resolvers: r2 = []} = diff2
    const l = _.isEqual(n1, o2) ? [] : [[n1, o2]]
    return {
      old_value: o1,
      new_value: n2,
      conflicts: thread(c1, [union, $$, l], [difference, $$, r2], [union, $$, c2]),
      resolvers: union(r1, r2),
    }
  }

  function is_conflict(value: Value<T> | null): boolean {
    return is_encapsulated(value) && value.conflicts.length > 0
  }

  function get_conflicts(value: Value<T> | null): ValueConflicts<T> {
    if (is_encapsulated(value)) {
      return value.conflicts
    } else {
      return []
    }
  }

  function is_empty(value: Value<T> | null): boolean {
    const {value: v, conflicts: c} = encapsulate(value)
    return v == null && c.length === 0
  }

  function get_value(value: Value<T> | null): Nullable<T> {
    if (is_encapsulated(value)) {
      return value.value
    } else {
      return value
    }
  }

  function clean_neutral_diffs(diff: ValueDiff<T>): ValueDiff<T> | null {
    ensure_diff(diff)
    const {old_value: o, new_value: n, conflicts: c = [], resolvers: r = []} = diff
    if (_.isEmpty(c) && _.isEmpty(r) && _.isEqual(o, n)) {
      return null
    }
    return diff
  }

  return {
    conflict_free: get_value,
    is_conflict,
    get_conflicts,
    is_empty,
    get_value,
    get_old_value,
    get_new_value,
    apply,
    ensure_consistency,
    reverse,
    unapply,
    squash2,
    squash: create_squash(squash2),
    change_diff,
    create_diff: (value: Value<T>) => change_diff(null, value),
    remove_diff: (value: Value<T>) => change_diff(value, null),
    is_value_type,
    clean_neutral_diffs,
    value_sentinel,
    delete_empty,
    default_value: null,
  }
}
