import {TableCols} from 'common/objects/data_table'
import _ from 'lodash'
import {ColumnType, DataTable, EntityHeaders, OptionId, OrganisationId} from 'common/types/storage'
import {
  create_data_table_entity,
  create_partial_schema_from_cols,
  default_cols,
} from 'common/entities/data_table'
import {get_permission_table_entity_id, PermissionType} from 'common/permission/permission_utils'
export type PermissionTableSubtype =
  | 'permission_entities'
  | 'permission_users'
  | 'permission_roles'
  | 'permission_memberships'
  | 'permission_permissions'

const PERMISSION_TABLE_SUBTYPES_MAP: Record<string, PermissionTableSubtype> = {
  entities: 'permission_entities',
  users: 'permission_users',
  roles: 'permission_roles',
  membership: 'permission_memberships',
  permissions: 'permission_permissions',
}

const PERMISSION_TABLE_SUBTYPES = Object.values(PERMISSION_TABLE_SUBTYPES_MAP)

const required_string_type: ColumnType<'string', 'entity'> = {type: 'string', required: true}

const entities_col_ids = {
  resource_id: '2opYPNGmPML',
  resource_type: '5fo1WxBGnOF',
  resource_name: 'Zv8Js9E81fK',
}

const users_col_ids = {
  email: '1VuwyvewKzx',
  name: 'UZB1ep4iFa5',
}

const roles_col_ids = {
  role_name: '0dxCGrU7WrJ',
}

const memberships_col_ids = {
  who: 'sEQEXfsy544',
  role: 'SKRdxhDzir2',
}

const permissions_col_ids = {
  who: '8V3Dxn3in7L',
  what: 'RUlCRmZKDnt',
  permission: '5AI07x6Bv24',
  filter: 'gadKRJGhr4m',
}

const entity_type_option_ids = {
  data_table: 'CHtvySYjCya',
  project: 'cV1DW8wsJPo',
  organisation: 'z9vWsiA4FPa',
}

const get_entities_table_cols = (): TableCols => ({
  [entities_col_ids.resource_id]: {
    type: required_string_type,
    name: 'resource-id',
    slug: 'resource_id',
  },
  [entities_col_ids.resource_type]: {
    type: {
      type: 'option',
      options: {
        [entity_type_option_ids.data_table]: {name: 'data_table'},
        [entity_type_option_ids.project]: {name: 'project'},
        [entity_type_option_ids.organisation]: {name: 'organisation'},
      },
      required: true,
    },
    name: 'resource-type',
    slug: 'resource_type',
  },
  [entities_col_ids.resource_name]: {
    type: required_string_type,
    name: 'resource-name',
    slug: 'resource_name',
  },
})

const get_users_table_cols = (): TableCols => ({
  [users_col_ids.email]: {
    type: required_string_type,
    name: 'email',
    slug: 'email',
    description: 'email description',
  },
  [users_col_ids.name]: {
    type: required_string_type,
    name: 'name',
    slug: 'name',
    description: 'name description',
  },
  ...default_cols,
})

const get_roles_table_cols = (): TableCols => ({
  [roles_col_ids.role_name]: {
    type: required_string_type,
    name: 'Role name',
    slug: 'role_name',
    description: 'e.g. developer or team lead',
  },
  ...default_cols,
})

const get_memberships_table_cols = (organisation_id: OrganisationId): TableCols => ({
  [memberships_col_ids.who]: {
    type: {
      type: 'reference',
      tables: {
        [`${get_permission_table_entity_id(
          organisation_id,
          PERMISSION_TABLE_SUBTYPES_MAP.users
        )}_ref`]: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.users
          ),
          column_id: null,
          index: 0,
        },
        [`${get_permission_table_entity_id(
          organisation_id,
          PERMISSION_TABLE_SUBTYPES_MAP.roles
        )}_ref`]: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.roles
          ),
          column_id: null,
          index: 1,
        },
      },
      required: true,
    },
    name: 'Who',
    slug: 'who',
    description: 'user or role',
  },
  [memberships_col_ids.role]: {
    type: {
      type: 'reference',
      tables: {
        efg: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.roles
          ),
          column_id: null,
          index: 0,
        },
      },
      multi: true,
      required: true,
    },
    name: 'Roles',
    slug: 'roles',
  },
  ...default_cols,
})

const permission_option_ids: Record<PermissionType, OptionId> = {
  admin: 'jntCZ42Ccft',
  read: 'w3hi8funnzI',
}

const get_permissions_table_cols = (organisation_id: OrganisationId): TableCols => ({
  [permissions_col_ids.who]: {
    type: {
      type: 'reference',
      tables: {
        [`${get_permission_table_entity_id(
          organisation_id,
          PERMISSION_TABLE_SUBTYPES_MAP.users
        )}_ref`]: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.users
          ),
          column_id: null,
          index: 0,
        },
        [`${get_permission_table_entity_id(
          organisation_id,
          PERMISSION_TABLE_SUBTYPES_MAP.roles
        )}_ref`]: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.roles
          ),
          column_id: null,
          index: 1,
        },
      },
      required: true,
    },
    name: 'Who',
    slug: 'who',
    description: 'user or role',
  },
  [permissions_col_ids.what]: {
    type: {
      type: 'reference',
      tables: {
        [`${get_permission_table_entity_id(
          organisation_id,
          PERMISSION_TABLE_SUBTYPES_MAP.entities
        )}_ref`]: {
          table_id: get_permission_table_entity_id(
            organisation_id,
            PERMISSION_TABLE_SUBTYPES_MAP.entities
          ),
          column_id: null,
          index: 0,
        },
      },
      required: true,
    },
    name: 'What',
    slug: 'what',
  },
  [permissions_col_ids.permission]: {
    type: {
      type: 'option',
      options: {
        [permission_option_ids.admin]: {name: 'admin'},
        [permission_option_ids.read]: {name: 'read'},
      },
      required: true,
    },
    name: 'Permission',
    slug: 'permission',
  },
  [permissions_col_ids.filter]: {
    type: {type: 'string'},
    name: 'Filter',
    slug: 'filter',
  },
  ...default_cols,
})

const permissions_table_cols_mapping = {
  permission_entities: get_entities_table_cols,
  permission_users: get_users_table_cols,
  permission_roles: get_roles_table_cols,
  permission_memberships: get_memberships_table_cols,
  permission_permissions: get_permissions_table_cols,
}
const PERMISSION_TABLE_NAMES_MAP: Record<PermissionTableSubtype, string> = {
  permission_entities: 'Entities',
  permission_users: 'Users',
  permission_roles: 'Roles',
  permission_memberships: 'Memberships',
  permission_permissions: 'Permissions',
}

const create_permissions_table_entity = <S extends PermissionTableSubtype>(
  organisation_id: OrganisationId,
  subtype: S
): DataTable<'entity'> => {
  const name = PERMISSION_TABLE_NAMES_MAP[subtype]
  const cols = permissions_table_cols_mapping[subtype](organisation_id)
  return create_data_table_entity({
    zone_id: organisation_id,
    parent_id: organisation_id,
    entity_id: get_permission_table_entity_id(organisation_id, subtype),
    subtype,
    name,
    label: entities_col_ids.resource_name,
    ...create_partial_schema_from_cols(cols),
  })
}

const create_permission_tables_entities = (
  organisation_id: OrganisationId
): DataTable<'entity'>[] => {
  return PERMISSION_TABLE_SUBTYPES.flatMap((subtype) =>
    subtype === 'permission_entities'
      ? []
      : create_permissions_table_entity(organisation_id, subtype)
  )
}

const get_entities_in_organisation = (
  entity_headers: EntityHeaders,
  organisation_id: OrganisationId,
  allowed_entity_types: string[]
): EntityHeaders => {
  const organisation_and_project_id = Object.keys(entity_headers).filter((id) => {
    const header = entity_headers[id]
    return header.parent_id === organisation_id && ['organisation', 'project'].includes(header.type)
  })

  return _.pickBy(entity_headers, (header) => {
    return (
      allowed_entity_types.includes(header.type) &&
      !(PERMISSION_TABLE_SUBTYPES as ReadonlyArray<any>).includes(header.subtype) &&
      organisation_and_project_id.includes(header.parent_id)
    )
  })
}

const get_entities_table_entity = (
  entity_headers: EntityHeaders,
  organisation_id: OrganisationId
): DataTable<'entity'> => {
  const entities_table_entity = create_permissions_table_entity(
    organisation_id,
    'permission_entities'
  )

  const cols_order = _.invert(entities_table_entity.cells.slots)

  Object.entries(
    get_entities_in_organisation(entity_headers, organisation_id, [
      'data_table',
      'organisation',
      'project',
    ])
  ).forEach(([entity_id, header]) => {
    const cols = {
      [entities_col_ids.resource_id]: header.entity_id,
      [entities_col_ids.resource_type]: entity_type_option_ids[header.type],
      [entities_col_ids.resource_name]: header.name,
    }
    entities_table_entity.cells.data[entity_id] = [
      cols[cols_order[0]],
      cols[cols_order[1]],
      cols[cols_order[2]],
    ]
  })

  return entities_table_entity
}

export {
  create_permission_tables_entities,
  PERMISSION_TABLE_SUBTYPES,
  PERMISSION_TABLE_NAMES_MAP,
  get_entities_table_entity,
  permissions_col_ids,
  users_col_ids,
  entities_col_ids,
  roles_col_ids,
  memberships_col_ids,
  permission_option_ids,
}
