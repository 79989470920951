import React from 'react'
import styles from './Tooltips.module.css'
import {get_tooltip_position} from '../utils/layout'
import {TOOLTIP_PROPS} from './cell_tooltips'

const WIDTH = 225
const HEIGHT = 115

const Tooltip = ({title, content, color, left, top}) => {
  return (
    <div
      className={styles.tooltip}
      style={{width: WIDTH, height: HEIGHT, borderLeftColor: color, left, top}}
    >
      <strong className={styles.title} style={{color}}>
        {title}
      </strong>
      <span className={styles.content}>{content}</span>
    </div>
  )
}

const Tooltips = ({cell_position, canvas_size, tooltips}) => {
  const [left, top] = get_tooltip_position(canvas_size, cell_position, WIDTH, HEIGHT)

  return (
    <>
      {tooltips.map((tooltip, i) => {
        const {title, color} = TOOLTIP_PROPS[tooltip.type]
        const calculated_top = top + HEIGHT * i
        return (
          <Tooltip
            key={i}
            color={color}
            left={left}
            top={calculated_top}
            title={title}
            content={tooltip.content}
          />
        )
      })}
    </>
  )
}

export default Tooltips
