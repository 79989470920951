import React from 'react'
import {styled} from '@material-ui/core/styles'
import TableSelector, {TableSelectorProps} from './TableSelector'
import {Coordinates} from '../utils/mouse_utils'

type PopupTableSelectorProps = TableSelectorProps & {
  pos: Coordinates | null
  width?: number
}
const PopupTableSelector: React.ComponentType<PopupTableSelectorProps> = styled(
  ({pos, ...props}) => <TableSelector {...props} />
)((props: PopupTableSelectorProps) => ({
  backgroundColor: 'white',
  zIndex: 7, //minimal z-index to overlay editor scrollbars
  width: props.width || 350,
  position: 'fixed',
  left: props.pos != null ? props.pos.left : 0,
  top: props.pos != null ? props.pos.top : 0,
}))

export default PopupTableSelector
