import _ from 'lodash'

type Squash2<Diff> = (diff_1: Diff, diff_2: Diff) => Diff

// Do not return undefined if there is at least one element
export type Squash<Diff> = <Diffs extends Diff[]>(
  ...diffs: Diffs
) => Diffs extends [Diff, ...Diff[]] ? Diff : Diff | undefined

const create_squash = <Diff>(squash2: Squash2<Diff>): Squash<Diff> => {
  return ((...diffs: Diff[]) =>
    _.reduceRight(diffs, (accum, d) => {
      return squash2(d, accum)
    })) as Squash<Diff>
}

export {create_squash}
