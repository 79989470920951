import React, {FC, useState, useCallback, useEffect, useMemo} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {TableEntityId} from 'common/types/storage'
import {compose_resource_id, compose_table_id} from 'common/params_utils'
import {useRuntimeContext} from '../../RuntimeContextProvider'
import {str_repr, uuid} from 'common/utils'
import {
  get_table_ui_dispatch,
  get_table_ui_state,
  useRuntimeSelector,
  useThunkDispatch,
  AlwaysDefinedRuntime,
  ReduxThunk,
} from '../../utils/connect_hocs'
import _ from 'lodash'
import {is_error} from 'common/error'

const PREVIEW_ITEM_LIMIT = 20
export type PreviewType = 'column' | 'style' | 'summary' | 'tooltip'
export type PreviewId = string
export type PreviewData = {
  type: PreviewType
  fn: string
}

type FunctionPreviewProps = {
  preview_type: PreviewType
  fn_string: string
}

function request_preview(
  table_entity_id: TableEntityId,
  preview_id: PreviewId,
  preview_type: PreviewType,
  fn_string: string
): ReduxThunk {
  return (dispatch, get_state) => {
    const table_ui_state = get_table_ui_state(get_state(), table_entity_id)
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    const preview_data: PreviewData = {fn: fn_string, type: preview_type}
    const previews = {...table_ui_state.previews, [preview_id]: preview_data}
    set_table_ui_state({previews}, 'request_preview')
  }
}

function release_preview(table_entity_id: TableEntityId, preview_id: PreviewId): ReduxThunk {
  return (dispatch, get_state) => {
    const state = get_state()
    const table_ui_state = get_table_ui_state(state, table_entity_id)
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    const previews = _.omit(table_ui_state.previews, preview_id)
    set_table_ui_state({previews}, 'release_preview')
  }
}

function get_preview_text(preview_data: object, table_size: number): string {
  const preview_str = str_repr(preview_data, true)
  return table_size > PREVIEW_ITEM_LIMIT
    ? `Showing ${PREVIEW_ITEM_LIMIT} of ${table_size} items:\n${preview_str}`
    : preview_str
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginLeft: '20px',
    minWidth: '25%',
    maxWidth: '25%',
    height: '100%',
    border: '1px solid #aaa',
    display: 'flex',
  },
  refresh_overlay: {
    opacity: 0.87,
    backgroundColor: '#f6f6f6',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  refresh_text: {
    color: theme.palette.text.primary,
  },
  content: {
    flex: '1 1 auto',
    color: theme.palette.text.primary,
    whiteSpace: 'pre',
    overflow: 'auto',
  },
}))

const FunctionPreview: FC<FunctionPreviewProps> = ({fn_string, preview_type}) => {
  const {
    resources: {
      table_resources: {table_entity_id, table, previews = {}},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const dispatch = useThunkDispatch()

  const classes = useStyles()

  // String that we are currently previewing
  const [preview_string, set_preview_string] = useState<string | null>(null)

  // Set our preview string to the current content of the editor
  const on_refresh = useCallback(() => {
    if (fn_string !== '' && fn_string !== preview_string) {
      set_preview_string(fn_string)
    }
  }, [preview_string, fn_string])

  // Generate a unique ID for each previewed string
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preview_id = useMemo(() => uuid(), [preview_string])

  // Result could be undefined, so checking preview_data !== undefined is not enough
  const preview_loaded = preview_id in previews
  const preview_data = previews[preview_id]
  const table_row_count = table.row_count()

  const runtime_context = useRuntimeContext()

  useEffect(() => {
    if (preview_string) {
      dispatch(request_preview(table_entity_id, preview_id, preview_type, preview_string))
    }

    return () => {
      if (preview_string) {
        dispatch(release_preview(table_entity_id, preview_id))
        // Clean up preview resource from storage
        const resource_id = compose_resource_id({
          table_id: compose_table_id({entity_id: table_entity_id}),
          type: 'preview',
          preview_id,
        })
        runtime_context.mutable_dispatch([{type: 'remove-resource', resource_id}])
      }
    }
  }, [dispatch, preview_id, preview_string, preview_type, runtime_context, table_entity_id])

  let overlay_text = ''
  if (fn_string === '') {
    overlay_text = 'No preview available'
  } else if (fn_string !== preview_string) {
    overlay_text = preview_string ? 'Click to refresh preview' : 'Click to load preview'
  } else if (!preview_loaded) {
    overlay_text = 'Loading preview...'
  }

  const preview_text = useMemo(() => {
    if (!preview_loaded) return ''
    if (!_.isObject(preview_data) || is_error(preview_data)) return str_repr(preview_data, true)
    const reduced_data = Object.fromEntries(
      Object.entries(preview_data as object).slice(0, PREVIEW_ITEM_LIMIT)
    )
    return get_preview_text(reduced_data, table_row_count)
  }, [preview_data, preview_loaded, table_row_count])

  return (
    <div className={classes.root}>
      {!!overlay_text && (
        <div className={classes.refresh_overlay} onClick={on_refresh}>
          <div className={classes.refresh_text}>{overlay_text}</div>
        </div>
      )}
      <div className={classes.content}>{preview_text}</div>
    </div>
  )
}

export default FunctionPreview
