import data_table_diff from 'common/diffs/models/data_table_diff'
import {Resource, ResourceId, ZoneDiff} from 'common/types/storage'
import {parse_resource_id} from 'common/params_utils'
import _ from 'lodash'

const entity_invalid_or_conflict = (data) => {
  return data.invalids || data_table_diff.is_conflict(data)
}

export const _is_invalid_data_table = (
  res_id: string,
  resources: Record<ResourceId, Resource>,
  multidiff: ZoneDiff
): boolean => {
  const {entity_id, type} = parse_resource_id(res_id)
  return (
    type === 'entity' &&
    multidiff &&
    multidiff[entity_id] &&
    _.get(resources[res_id], ['result', 'type']) === 'data_table' &&
    entity_invalid_or_conflict(resources[res_id].result)
  )
}
