import {create_diff} from 'common/diffs/models/entity_diff'
import {
  ProjectId,
  OrganisationId,
  EntityDiff,
  Project,
  EntityHeader,
  TableEntityId,
} from 'common/types/storage'
import {_add_diff, AddDiffAction} from 'common/entities/actions'
import {create_project_entity} from 'common/entities/project'
import ordered_set_diff from 'common/diffs/base/ordered_set_diff'

export function create_new_project(
  id: ProjectId,
  name: string,
  parent_id: OrganisationId
): AddDiffAction<EntityDiff<'project'>> {
  const entity: Project<'entity'> = create_project_entity({
    name,
    entity_id: id,
    zone_id: id,
    parent_id,
  })

  return _add_diff(id, id, create_diff<'project'>(entity))
}

export function change_project_tables_order(
  header: EntityHeader<'project'>,
  old_order: ReadonlyArray<TableEntityId>,
  new_order: ReadonlyArray<TableEntityId>
): AddDiffAction<EntityDiff<'project'>> {
  const {type, entity_id, zone_id} = header
  const tables_order_diff: EntityDiff<'project'> = {
    type,
    tables_order: ordered_set_diff().change_diff(old_order, new_order),
  }
  return _add_diff(entity_id, zone_id, tables_order_diff)
}

export default {
  create_new_project,
  change_project_tables_order,
}
