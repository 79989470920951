export function deepFreeze(obj) {
  if (typeof obj !== 'object') return
  if (obj == null) return
  if (obj === window) return

  // Assume that any frozen object is deeply frozen. Might not be true, but
  // that's an acceptable risk.
  if (Object.isFrozen(obj)) return

  Object.freeze(obj)
  for (const key of Object.keys(obj)) deepFreeze(obj[key])
}
