import record_diff from '../base/record_diff'
import value_diff from '../base/value_diff'
import map_diff from '../base/map_diff'

/*
 * These are the following types we currently recognize:
 *
 * - string (represented as string)
 * - number (represented as number)
 * - date (represented as isodate)
 * - reference (represented as encoded table_entity_id and row_id)
 * - option (represented as option id)
 */
const type_diff = record_diff({
  type: value_diff(),
  // If true, `null` is invalid value.
  required: value_diff(),
  // If true, the type describes array of such values.
  multi: value_diff(),
  // Applicable for type === 'reference', contains the list of tables that can get referenced.
  tables: map_diff(
    record_diff({
      table_id: value_diff(),
      column_id: value_diff(),
      index: value_diff(),
    }),
    false,
    false
  ),

  // Applicable for type === 'number', contains id of the format for displaying numeric values
  number_format_id: value_diff(),
  // Applicable for type === 'date' | 'date_time',
  // contains format-template for printing (labelizing) of values
  date_format_id: value_diff(),
  // Applicable for type === 'date_time',
  // contains format-template for printing (labelizing) of values
  time_format_id: value_diff(),
  // Applicable for type === 'boolean',
  boolean_format_id: value_diff(),
  // Applicable for type === 'option', contains option_id mapped to it's name (the extra nesting is
  // there just to maybe add more attributes in the future
  options: map_diff(
    record_diff({
      name: value_diff(),
    }),
    false,
    false
  ),
  // Applicable for type === 'option', if true, new option can't be added in cell editor
  disable_add_option: value_diff(),
})

export default type_diff
