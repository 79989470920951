//single domain name can contain only letters, numbers, or hyphen ("-")
//can't start nor end with hyphen, can't contain more than one hyphen in a row
// full domain name can contain dots
const REGEX_DOMAIN_NAME = '[a-z0-9]+(-[a-z0-9]+)*([.]([a-z0-9]+(-[a-z0-9]+)*)+)*'
const REGEX_TLD = '[a-z]{2,6}'
const REGEX_DOMAIN = `${REGEX_DOMAIN_NAME}[.]${REGEX_TLD}\\b`
//e-mail = local@domain
//RFC3696: (spec compliant addresses are not necessarily allowed in real life)
//without quotes, local-parts may consist of any combination of
//alphabetic characters, digits, or any of the special characters
//! # $ % & ' * + - / = ?  ^ _ ` . { | } ~
//period (".") may also appear, but may not be used to start or end the
//local part, nor may two or more consecutive periods appear.
//simplified ->
//start with alphanumeric, only single consecutive period, limited special characters
const REGEX_EMAIL_LOCAL = '[a-z0-9](?:[.]?[a-z0-9-_!#$%+~&])*'
const REGEX_EMAIL = `${REGEX_EMAIL_LOCAL}@${REGEX_DOMAIN}`

const REGEX_IP_BLOCK = '(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)'
const REGEX_IP = `${REGEX_IP_BLOCK}[.]${REGEX_IP_BLOCK}[.]${REGEX_IP_BLOCK}[.]${REGEX_IP_BLOCK}`
const REGEX_PROTOCOL = '(?:(?:http|https):\\/\\/)'
const REGEX_PORT = '(?::[0-9]{1,5})'
const REGEX_PARAM = '(?:/[-a-z0-9@:%_+.~#()?&\\/=]*)'
//URL = [http[s]://]([www.]IP|DOMAIN)[:port][/params]
//user info: http://username:password@domain.tld format has unwanted overlap with emails
//mailto:password@domain.tld and is intentionally omitted
//ip address left out for now
const REGEX_WEB_ADDRESS = `${REGEX_PROTOCOL}?(?:${REGEX_DOMAIN}|${REGEX_IP})${REGEX_PORT}?${REGEX_PARAM}?`

// RegExp objects
const WEB_OR_EMAIL_ADDRESS = new RegExp(`(?:${REGEX_EMAIL}|${REGEX_WEB_ADDRESS})`, 'gi')
const EXACTLY_ONE_WEB_ADDRESS = new RegExp(`^${REGEX_WEB_ADDRESS}$`, 'i')

// returns array of matched URLs (empty array if no match)
export const extract_hyperlinks_from_string = (str: string): string[] => {
  return str.match(WEB_OR_EMAIL_ADDRESS) || []
}

// returns true if given string is in a form of web address
export const is_web_address = (str: string) => {
  return EXACTLY_ONE_WEB_ADDRESS.test(str)
}
