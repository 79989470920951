import React, {FC, useCallback} from 'react'
import {styled, Tabs} from '@material-ui/core'
import HistorySidebar from '../History/HistorySidebar'
import ConflictsSidebar from '../Conflicts/ConflictsSidebar'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetZoneUiState,
  useZoneUiSelector,
} from '../utils/connect_hocs'
import SidebarTab from './SidebarTab'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import HistoryIcon from '@material-ui/icons/History'
import SidebarContentPanel from './SidebarContentPanel'
import {focus_table_content} from '../utils/focus'

const Content: FC<{sidebar: 'history' | 'conflicts' | null}> = ({sidebar}) => {
  switch (sidebar) {
    case 'history':
      return (
        <SidebarContentPanel title="History">
          <HistorySidebar />
        </SidebarContentPanel>
      )
    case 'conflicts':
      return (
        <SidebarContentPanel title="Conflicts">
          <ConflictsSidebar />
        </SidebarContentPanel>
      )
    default:
      return null
  }
}

const ContentWrapper = styled('div')(({theme}) => ({
  'width': '350px',
  'borderLeft': `1px solid ${theme.palette.greyPalette[100]}`,
  'flexGrow': 1,

  '&:empty': {
    display: 'none',
  },
}))

const Container = styled('div')(({theme}) => ({
  backgroundColor: `${theme.palette.panelBackground}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  float: 'right',
  overflow: 'hidden',
}))

const TableSidebar: FC = () => {
  const {
    resources: {
      table_resources: {full_table: table, zone_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const active_sidebar = useZoneUiSelector(zone_id, 'sidebar')
  const set_zone_ui_state = useSetZoneUiState(zone_id)

  const handle_tab_change = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      set_zone_ui_state({sidebar: value !== active_sidebar ? value : null})
      focus_table_content()
    },
    [active_sidebar, set_zone_ui_state]
  )

  return (
    <Container>
      <Tabs
        value={active_sidebar || false}
        onChange={handle_tab_change}
        orientation="vertical"
        indicatorColor="primary"
        aria-label="Vertical tabs example"
      >
        <SidebarTab
          value="conflicts"
          title="Conflicts"
          aria-label="Conflicts"
          icon={<ErrorOutlineIcon />}
          disabled={table == null}
        />
        <SidebarTab value="history" title="History" aria-label="History" icon={<HistoryIcon />} />
      </Tabs>
      <ContentWrapper>
        <Content sidebar={active_sidebar} />
      </ContentWrapper>
    </Container>
  )
}

export default TableSidebar
