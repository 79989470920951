import React, {FC, useCallback, useRef} from 'react'
import {Box, Popover, styled, Tooltip} from '@material-ui/core'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useTableUiSelector,
  useThunkDispatch,
} from '../utils/connect_hocs'
import {close_toolbar_dropdowns, open_params_dropdown} from './toolbar_actions'
import ParamsEditor from '../Params/ParamsEditor'
import CustomButton from '../components/CustomButton'
import _ from 'lodash'

const Badge = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.greenPalette[600],
  width: 20,
  height: 20,
  fontWeight: 400,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(1),
}))

const ParamsButton: FC = () => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const params = useTableUiSelector(table_entity_id, 'params') || {}
  const dropdown = useTableUiSelector(table_entity_id, 'dropdown')
  const dispatch = useThunkDispatch()

  const on_open = useCallback(() => {
    dispatch(open_params_dropdown(table_entity_id))
  }, [dispatch, table_entity_id])

  const on_close = useCallback(() => {
    dispatch(close_toolbar_dropdowns(table_entity_id))
  }, [dispatch, table_entity_id])

  const anchor = useRef<HTMLButtonElement | null>(null)
  const params_enabled = table != null && table.col_count() > 0

  return (
    <>
      <Tooltip title="Set params">
        <span ref={anchor}>
          <CustomButton type="tertiary" onClick={on_open} disabled={!params_enabled}>
            <i className="fas fa-code" />
            {!_.isEmpty(params) && (
              <Badge display="flex" alignItems="center" justifyContent="center" borderRadius="50%">
                {Object.keys(params).length}
              </Badge>
            )}
          </CustomButton>
        </span>
      </Tooltip>
      <Popover
        anchorEl={anchor.current}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={dropdown === 'params' && anchor.current != null}
        onClose={on_close}
      >
        <ParamsEditor on_request_close={on_close} />
      </Popover>
    </>
  )
}

export default ParamsButton
