import React, {FC, useContext, useMemo} from 'react'
import {throw_error} from 'common/utils'
import {is_error} from 'common/error'
import {
  raw_value_to_string,
  get_editor_type,
  get_available_options,
  with_additional_options,
  set_cell_value,
} from './editor_utils'
import StringEditor from './StringEditor'
import DateEditor from './DateEditor'
import SelectEditorWithDeferredSave from './SelectEditorWithDeferredSave'
import {SelectOption} from './SelectEditor'
import {EditFlag, TableObject} from 'common/objects/data_table'
import {useRuntimeSelector, AlwaysDefinedRuntime} from '../utils/connect_hocs'
import {UserAccountContext} from '../UserAccountProvider'
import {date_time_to_formatted_string} from 'common/value_to_string'
import AttachmentEditor from './AttachmentEditor'
import BooleanEditor from './BooleanEditor'
import {ColumnId, RowId, ColumnType, CellType, CellRawValue} from 'common/types/storage'
import {useRuntimeActions} from '../RuntimeContextProvider'

export type CellEditorProps = {
  in_detailed_view?: boolean
  clear_on_open?: boolean
  end_edit: () => void
  on_enter?: () => void
  table: TableObject
  col_id: ColumnId
  row_id: RowId
  boundaries_element?: Element
}

type CellSelectEditorProps = {
  end_edit: () => void
  cols_edit_flags: EditFlag
  col_type: ColumnType<CellType, 'entity'>
  set_value: (value: CellRawValue, new_options?: SelectOption[]) => void
  value: CellRawValue
}

const CellSelectEditor: FC<CellSelectEditorProps> = ({
  end_edit,
  cols_edit_flags,
  col_type,
  set_value,
  value,
}) => {
  const {runtime} = useRuntimeSelector() as AlwaysDefinedRuntime

  const column_options = useMemo(() => {
    return get_available_options(col_type, runtime)
  }, [runtime, col_type])

  const all_options = useMemo(() => {
    return with_additional_options(column_options, value, col_type, runtime)
  }, [column_options, value, col_type, runtime])

  return (
    <SelectEditorWithDeferredSave
      value={value}
      multi={col_type.multi}
      can_add_new_option={
        col_type.type === 'option' && !col_type.disable_add_option && cols_edit_flags === 'editable'
      }
      options={all_options}
      set_value={set_value}
      end_edit={end_edit}
      autofocus
      open
    />
  )
}

export const CellEditor: FC<CellEditorProps> = ({
  end_edit,
  on_enter,
  table,
  col_id,
  row_id,
  clear_on_open,
  in_detailed_view,
  boundaries_element,
}) => {
  const {dispatch_storage} = useRuntimeActions()
  const current_user = useContext(UserAccountContext)
  const set_value = useMemo(
    () => set_cell_value(table, col_id, row_id, current_user?.email, dispatch_storage),
    [table, col_id, row_id, current_user?.email, dispatch_storage]
  )

  const col_type = table._cols[col_id].type
  const value_or_error = table._cell(row_id, col_id).raw_value
  const value = is_error(value_or_error) ? null : value_or_error
  const editor_type = get_editor_type(col_type.type)

  const cols_edit_flags = table._cols_edit_flags[col_id]

  switch (editor_type) {
    case 'select':
      return (
        <CellSelectEditor
          end_edit={end_edit}
          cols_edit_flags={cols_edit_flags}
          col_type={col_type}
          set_value={set_value}
          value={value}
        />
      )
    case 'date':
      return (
        <DateEditor
          clear_on_open={clear_on_open}
          value={raw_value_to_string(value)}
          set_value={set_value}
          end_edit={end_edit}
          on_enter={on_enter}
          boundaries_element={boundaries_element}
          date_format_id={(col_type as ColumnType<'date', 'entity'>).date_format_id}
          startOpen
          autoFocus
        />
      )
    case 'attachment':
      return (
        <AttachmentEditor
          table={table}
          end_edit={end_edit}
          value={raw_value_to_string(value)}
          set_value={set_value}
        />
      )
    case 'boolean':
      return (
        <BooleanEditor
          end_edit={end_edit}
          set_value={set_value}
          value={raw_value_to_string(value)}
          boolean_format_id={(col_type as ColumnType<'boolean', 'entity'>).boolean_format_id}
          highlight
        />
      )
    case 'date_time':
      return (
        <StringEditor
          clear_on_open={clear_on_open}
          in_detailed_view={in_detailed_view}
          multiline={false}
          value={
            typeof value === 'number'
              ? date_time_to_formatted_string(
                  new Date(value),
                  (col_type as ColumnType<'date_time', 'entity'>).date_format_id,
                  (col_type as ColumnType<'date_time', 'entity'>).time_format_id
                )
              : raw_value_to_string(value)
          }
          set_value={set_value}
          end_edit={end_edit}
          on_enter={on_enter}
        />
      )
    case 'markdown':
    case 'string':
      return (
        <StringEditor
          clear_on_open={clear_on_open}
          in_detailed_view={in_detailed_view}
          multiline={editor_type === 'markdown'}
          value={raw_value_to_string(value)}
          set_value={set_value}
          end_edit={end_edit}
          on_enter={on_enter}
        />
      )
    default:
      return throw_error('Unknown cell type', editor_type)
  }
}
