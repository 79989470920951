import React, {FC, ReactNode} from 'react'
import {makeStyles, styled} from '@material-ui/core/styles'
import {MenuItem, Box} from '@material-ui/core'

export type ContextMenuItemProps = {
  onClick: () => void
  item: string
  icon: ReactNode
  disabled?: boolean
  borderTop?: boolean
  borderBottom?: boolean
}

const Line = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.greyPalette[800],
  height: 1,
  margin: theme.spacing(0, 2),
}))

const useStyles = makeStyles((theme) => ({
  root: (props: {disabled?: boolean}) => ({
    'minWidth': 235,
    'display': 'flex',
    'alignItems': 'center',
    'fontSize': theme.typography.body2.fontSize,
    'color': props.disabled ? 'rgba(176, 190, 197, 0.2)' : theme.palette.greyPalette[200],
    'cursor': props.disabled ? 'default' : 'pointer',
    'fontWeight': 500,
    'pointer-events': props.disabled ? 'none' : 'auto',
    '&:hover': {
      color: theme.palette.common.white,
    },
  }),
}))

const ContextMenuItem: FC<ContextMenuItemProps> = ({
  onClick,
  item,
  icon,
  disabled,
  borderTop,
  borderBottom,
}) => {
  const classes = useStyles({disabled})
  return (
    <>
      {borderTop && <Line />}
      <MenuItem onClick={onClick} className={classes.root}>
        <Box display="flex" alignItems="center" mr={1.5}>
          {icon}
        </Box>
        {item}
      </MenuItem>
      {borderBottom && <Line />}
    </>
  )
}

export default ContextMenuItem
