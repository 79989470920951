import React, {FC} from 'react'
import PropTypes from 'prop-types'
import difference from 'lodash/difference'
import styles from './Diff.module.css'
import {raw_value_to_string_wrapper} from 'common/value_to_string'
import {CellChange} from '../table_data_helpers'
import val_diff from 'common/diffs/base/value_diff'

const value_diff = val_diff()

// helper fn to gauge cell's emptiness
// simply isEmpty or !value won't work
const is_empty_cell_value = (value) => {
  return value == null || value === ''
}

// render diff body for multi option value change
const MultiChangeBody = ({removed, added}) => (
  <>
    {removed.map((item) => (
      <span key={item} className={styles.oldValue}>
        {item}
      </span>
    ))}
    {added.map((item) => (
      <span key={item} className={styles.currentValue}>
        {item}
      </span>
    ))}
  </>
)

const OptionsType = PropTypes.arrayOf(PropTypes.string).isRequired
MultiChangeBody.propTypes = {
  added: OptionsType,
  removed: OptionsType,
}

// render diff body for single value change
const SingleChangeBody = ({old, current}) => (
  <>
    {old !== null && (
      <>
        <span className={styles.oldValue}>{old}</span>→&nbsp;
      </>
    )}
    <span className={styles.currentValue}>{current}</span>
  </>
)

SingleChangeBody.propTypes = {
  old: PropTypes.node,
  current: PropTypes.node.isRequired,
}

// create human readable strings from values
// e.g. labels from reference ids
const parse_value = (value, type, runtime) => {
  if (is_empty_cell_value(value)) {
    return 'empty'
  } else {
    return raw_value_to_string_wrapper(runtime, value, type)
  }
}

const parse_change = (change_obj, col_spec, runtime) => {
  const {
    type: {type: cell_type, multi},
    computed,
  } = col_spec
  const {cell_change, change_type} = change_obj
  const is_new_cell = change_type === 'new_cell'

  // parse cell changes
  const [old, current] = cell_change
    ? [value_diff.get_old_value(cell_change), value_diff.get_new_value(cell_change)]
    : []

  if (is_new_cell && is_empty_cell_value(current)) {
    // new empty or computed cell
    return {
      title: `New ${cell_type} cell with ${computed ? 'computed' : 'empty'} value`,
      body: null,
    }
  }
  if (multi) {
    // multi option value change
    const removed = difference(old, current).map((val) =>
      parse_value(val, {...col_spec.type, multi: false}, runtime)
    )
    const added = difference(current, old).map((val) =>
      parse_value(val, {...col_spec.type, multi: false}, runtime)
    )

    return {
      title: is_new_cell ? 'New cell initialized with options' : 'Selected options changed',
      body: <MultiChangeBody removed={removed} added={added} />,
    }
  } else {
    // single value change
    const parsed_old = is_new_cell ? null : parse_value(old, col_spec.type, runtime)
    const parsed_current = parse_value(current, col_spec.type, runtime)

    return {
      title: is_new_cell ? `New ${cell_type} cell with initial value` : 'Value changed',
      body: <SingleChangeBody old={parsed_old} current={parsed_current} />,
    }
  }
}

type DiffProps = {
  runtime: any
  col_spec: any
  change_obj: CellChange | null
}

const Diff: FC<DiffProps> = ({change_obj, col_spec, runtime}) => {
  const {title, body} = parse_change(change_obj, col_spec, runtime)

  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>{body}</div>
    </>
  )
}

export default Diff
