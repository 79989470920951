import React, {FC} from 'react'
import _ from 'lodash'

const to_absolute_url = (link) => {
  // Tests for the protocol at the beginning of the link, e.g. http://
  // If the link doesn't start with protocol, we have to add //
  // otherwise it would be treated as a path on the server and appended to the current url
  return link.match(/^(http|https):\/\//i) ? link : `http://${link}`
}

type HyperlinksProps = {
  links: string[]
}

const Hyperlinks: FC<HyperlinksProps> = ({links}) => (
  <ul>
    {_.map(links, (link, i) => (
      <li key={i}>
        <a href={to_absolute_url(link)} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </li>
    ))}
  </ul>
)

export default Hyperlinks
