import {TableEntityId} from 'common/types/storage'

const separator = '/'
const route = (...paths: string[]): string => `${separator}${paths.join(separator)}`

const NOT_FOUNT_URL_PREFIX = '404'
const SIGN_IN_URL = 'sign-in'
const BENCHMARK_URL_PREFIX = 'benchmark'
const BROWSER_URL_PREFIX = 'browse'
const TABLE_URL_PREFIX = 'table'

export const ROUTES = {
  home: () => route(),
  not_found: () => route(NOT_FOUNT_URL_PREFIX),
  sign_in: () => route(SIGN_IN_URL),
  benchmark: () => route(BENCHMARK_URL_PREFIX),
  browser: () => route(BROWSER_URL_PREFIX),
  organisation: (organisation_id: string) => route(BROWSER_URL_PREFIX, organisation_id),
  project: (organisation_id: string, project_id: string) =>
    route(BROWSER_URL_PREFIX, organisation_id, project_id),
  table: (base_table_entity_id: TableEntityId, view_table_entity_id?: TableEntityId) =>
    view_table_entity_id
      ? route(TABLE_URL_PREFIX, base_table_entity_id, view_table_entity_id)
      : route(TABLE_URL_PREFIX, base_table_entity_id),
}
