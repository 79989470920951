import React, {FC, useCallback, useLayoutEffect, useMemo, useState} from 'react'
import {withStyles, createStyles} from '@material-ui/core/styles'
import SelectEditor, {SelectEditorProps, SelectOptionId, SelectOption} from './SelectEditor'
import _ from 'lodash'

const StyledSelectEditor = withStyles((theme) =>
  createStyles({
    root: {
      background: 'white',
      [theme.breakpoints.only('xs')]: {
        minWidth: '150px',
      },
      [theme.breakpoints.only('sm')]: {
        minWidth: '300px',
      },
      [theme.breakpoints.only('md')]: {
        minWidth: '300px',
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '300px',
      },
    },
    input: {
      margin: 0,
      padding: theme.spacing(1),
      font: theme.typography.gridCell.normal,
      height: '100%',
    },
  })
)(SelectEditor)

type SelectEditorWithDeferredSaveProps = SelectEditorProps

/*
 * Wrapper for select editor to keep changes and create diff only when end_edit is called
 */
const SelectEditorWithDeferredSave: FC<SelectEditorWithDeferredSaveProps> = (props) => {
  const {options, value: original_value, set_value, end_edit, ...other_props} = props

  const [current_value, set_current_value] = useState(original_value)
  const [new_options, set_new_options] = useState<SelectOption[]>([])
  const [save, set_save] = useState(false)

  useLayoutEffect(() => {
    if (save) {
      if (!_.isEqual(original_value, current_value) || !_.isEmpty(new_options)) {
        set_value(current_value, new_options)
      }
      end_edit()
    }
  }, [end_edit, set_value, current_value, original_value, new_options, save])

  const set_value_wrapper = useCallback(
    (new_value: SelectOptionId | SelectOptionId[], new_options?: SelectOption[]) => {
      set_current_value(new_value)
      if (new_options) {
        set_new_options((options) => [...options, ...new_options])
      }
    },
    []
  )

  const end_edit_wrapper = useCallback(() => set_save(true), [])

  const all_options = useMemo(() => [...options, ...new_options], [options, new_options])

  return (
    <StyledSelectEditor
      {...other_props}
      options={all_options}
      value={current_value}
      set_value={set_value_wrapper}
      end_edit={end_edit_wrapper}
    />
  )
}

export default SelectEditorWithDeferredSave
