import React, {FC} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import {Rect_LR_TB} from './utils/layout'

type RowNumberColumnTooltipProps = {
  cell_position: Rect_LR_TB
}

export const RowNumberColumnTooltip: FC<RowNumberColumnTooltipProps> = ({cell_position}) => {
  return (
    <Tooltip open title={'double-click to expand record'} placement="bottom-end">
      <div
        style={{
          zIndex: -1,
          position: 'absolute',
          left: cell_position[0][0],
          top: cell_position[1][0],
          width: cell_position[0][1] - cell_position[0][0],
          height: cell_position[1][1] - cell_position[1][0],
        }}
      />
    </Tooltip>
  )
}
