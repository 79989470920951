import React, {FC, memo} from 'react'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {col_id_on_index} from '../utils/table_helpers'
import {is_error, format_error, MaybeError} from 'common/error'
import {convert_date_to_iso_string} from 'common/utils'
import {apply_number_format, get_number_format} from 'common/formatting/number'
import {ColumnType, CellRawValue, CellType, EntityColumnSpec} from 'common/types/storage'
import {date_time_to_formatted_string, date_to_formatted_string} from 'common/value_to_string'
import {get_column_alignment} from '../table_data_helpers'

type SummaryCellProps = {
  table: any
  col_index: number
  width: number
}

const useStyles = makeStyles<Theme>(({palette: {grid}}: Theme) => ({
  cell: {
    background: grid.cell.defaultBackground,
    borderRight: `1px solid ${grid.border}`,
    borderBottom: `1px solid ${grid.border}`,
    padding: '5px',
    width: (props: SummaryCellProps) => `${props.width}px`,
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: (props: {col_spec: EntityColumnSpec}) => get_column_alignment(props.col_spec),
  },
  label: {
    fontWeight: 'bold',
    marginRight: '4px',
  },
}))

const get_summary_value = (
  raw_value: MaybeError<CellRawValue>,
  col_spec_type: ColumnType<CellType, 'entity'>
): string => {
  if (is_error(raw_value)) {
    if (raw_value.type === 'user' && raw_value.subtype === 'impossible-calculation') {
      return 'N/A'
    } else {
      return format_error(raw_value)
    }
  }

  if (raw_value instanceof Date) {
    switch (col_spec_type.type) {
      case 'date':
        return date_to_formatted_string(raw_value, col_spec_type.date_format_id)
      case 'date_time':
        return date_time_to_formatted_string(
          raw_value,
          col_spec_type.date_format_id,
          col_spec_type.time_format_id
        )
      default:
        // format dates as YYYY-MM-DD
        return convert_date_to_iso_string(raw_value)
    }
  }

  if (typeof raw_value === 'number') {
    if (col_spec_type.type === 'number' && col_spec_type.number_format_id) {
      return apply_number_format(raw_value, get_number_format(col_spec_type.number_format_id))
    }
  }

  if (Array.isArray(raw_value)) {
    return raw_value.map(String).join(' ± ')
  }

  return String(raw_value)
}

const SummaryCell: FC<SummaryCellProps> = (props) => {
  const {table, col_index} = props

  const col_id = col_id_on_index(table, col_index)
  const col_spec = table._cols[col_id]

  const classes = useStyles({...props, col_spec})

  const render_summary = () => {
    if (!col_spec.summary) {
      return null
    }

    const summary_value = table._get_summary(col_id)
    return (
      <>
        {col_spec.summary.label && (
          <label className={classes.label}>{col_spec.summary.label}</label>
        )}
        {get_summary_value(summary_value, col_spec.type)}
      </>
    )
  }

  return <div className={classes.cell}>{render_summary()}</div>
}

export default memo(SummaryCell)
