import React, {FC} from 'react'
import AddIcon from '@material-ui/icons/Add'
import {AlwaysDefinedRuntime, useRuntimeSelector, useTableUiSelector} from '../utils/connect_hocs'
import {TablePageResources} from '../Pages/TablePage'
import {useAddRowAction} from '../utils/table_action'
import CustomButton from '../components/CustomButton'

const AddRow: FC = () => {
  const {
    resources: {table_resources},
    storage: {history_mode},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  // full_table may be missing e.g. unknown-entity-commit error, so we widen the type
  const {full_table: table, table_entity_id} = table_resources as TablePageResources

  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const add_row = useAddRowAction(table, table_entity_id, {
    place: 'bottom',
    col_id_to_focus: cursor?.col_id,
  })
  const can_insert = !history_mode && table?._can_insert_row()

  return (
    <CustomButton type="secondary" onClick={add_row} disabled={!can_insert} startIcon={<AddIcon />}>
      Add row
    </CustomButton>
  )
}

export default AddRow
