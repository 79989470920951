import React, {FC, useCallback} from 'react'
import {useDispatch} from 'react-redux'

import {init_history} from '../DetailedView/history_actions'
import {open_modal} from '../Modals'
import {
  useSetTableUiState,
  useRuntimeSelector,
  AlwaysDefinedRuntime,
  useTableUiSelector,
} from '../utils/connect_hocs'
import ContextMenuItem from './ContextMenuItem'

type DetailedViewItemProps = {
  on_close: () => void
}

const DetailedViewItem: FC<DetailedViewItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')

  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const row_id = cursor ? cursor.row_id : null

  const dispatch = useDispatch()

  const on_click = useCallback(() => {
    if (row_id != null) {
      set_table_ui_state(init_history(table_entity_id, row_id), 'open_detailed_view')
      dispatch(open_modal('detailed_view', {}))
    }

    on_close()
  }, [set_table_ui_state, table_entity_id, row_id, dispatch, on_close])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={<i className="fas fa-eye" />}
      item="Detail view"
      borderTop
      borderBottom
    />
  )
}

export default DetailedViewItem
