import _ from 'lodash'
import {
  BooleanValueFilterSet,
  DateFilterValuesSet,
  Filter,
  FilterCondition,
  FilterConditions,
  FilterOperator,
  FilterType,
  FilterValidationResult,
  FilterValue,
  MultiColumnFilterSet,
  MultiValueFilterSet,
  NumberFilterValuesSet,
  SubtableSelection,
  SingleColumnFilterSet,
  StringFilterValuesSet,
  UiFilterCondition,
  BooleanFormatFilterSet,
} from '../types/filter'
import {CellType, ColumnId, ColumnType} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {parse_iso_date, parse_iso_date_time, parse_number, throw_error, uuid} from 'common/utils'

/**
 * Filter utils
 * @module FileterUtils
 */
const prepare_filter_values = (
  values: FilterValue<'internal'>[],
  filter_type: FilterType
): FilterValue<'parsed'>[] => {
  return values
    .map((value) =>
      DateFilterValuesSet.has(filter_type)
        ? parse_iso_date(String(value)) || parse_iso_date_time(String(value)) || value
        : value
    )
    .filter(
      (value): value is NonNullable<FilterValue<'parsed'>> =>
        value != null && !(Array.isArray(value) && value.length === 0)
    )
}

const prepare_filter_condition = (
  condition: FilterCondition<'internal'>
): FilterCondition<'parsed'> => {
  const cleaned_values = _.mapValues(condition, prepare_filter_values)
  return _.omitBy(cleaned_values, (values) => _.isEmpty(values))
}

export const prepare_filter_conditions = (
  conditions: FilterConditions<'internal'>
): FilterConditions<'parsed'> => {
  const cleaned_conditions = _.mapValues(conditions, prepare_filter_condition)
  return _.omitBy(cleaned_conditions, (condition) => _.isEmpty(condition))
}

/**
 * Transform advanced filter into array of filter rows used in UI
 * @function
 * @param filter {Filter<'internal'> | null}
 * @param table {TableObject | null}
 * @return {UiFilterCondition[]}
 */
export const get_filter_conditions = (
  filter: Filter<'internal'> | null,
  table: TableObject | null
): UiFilterCondition[] => {
  if (table == null || filter == null) {
    return []
  }

  const filter_conditions: UiFilterCondition[] = []
  _.forEach(filter.conditions, (conditions, col_id: ColumnId) => {
    if (col_id in table._cols) {
      _.forEach(conditions, (values: FilterValue<'internal'>[], filter_type: FilterType) => {
        _.forEach(values, (value: FilterValue<'internal'>) => {
          filter_conditions.push([uuid(), col_id, filter_type, value == null ? '' : value])
        })
      })
    }
  })

  return filter_conditions
}

/**
 * Converts string representation from UI filter value to internal, parsed representation
 * OR returns null if something went wrong
 *
 * Booleans and numbers are parsed
 * Dates aren't parsed, but rather only checked for validity to ensure
 * that they can be parsed at a later stage
 * @function
 * @param col_type {CellType}
 * @param filter_type {FilterType}
 * @param value {FilterValue<'internal'> | null}
 * @return {FilterValidationResult}
 */
export const validate_filter_value = (
  col_type: CellType,
  filter_type: FilterType,
  value: FilterValue<'internal'> | null
): FilterValidationResult => {
  if (BooleanValueFilterSet.has(filter_type)) {
    return typeof value === 'boolean' ? {value} : {value: true}
  }

  if (value === '' || value == null) return {value: null}

  if (MultiColumnFilterSet.has(filter_type) || MultiValueFilterSet.has(filter_type)) {
    return Array.isArray(value)
      ? {value}
      : {value: null, error: `Filter type expects value to be an array: ${value}`}
  }

  if (Array.isArray(value)) {
    return {
      value: null,
      error: `Filter type does not expect value to be an array: ${value}`,
    }
  }

  const value_str = String(value)

  if ((DateFilterValuesSet.has(filter_type) && col_type !== 'date_time') || col_type === 'date') {
    // dates are validated here and then parsed to Date objects in prepare_filter_conditions
    // date '=' and '≠' filters are compared via raw_value string and not parsed
    return parse_iso_date(value_str)
      ? {value: value_str}
      : {value: null, error: `Value ${value_str} is not a valid date.`}
  }

  if (DateFilterValuesSet.has(filter_type) || col_type === 'date_time') {
    if (Number.isFinite(Number(value_str))) {
      return {value: Number(value_str)}
    }
    const parsedDateTime = parse_iso_date_time(value_str)
    return parsedDateTime
      ? {value: parsedDateTime.getTime()}
      : {value: null, error: `Value ${value_str} is not a valid iso date time nor timestamp.`}
  }

  if (NumberFilterValuesSet.has(filter_type) || col_type === 'number') {
    const number = parse_number(value_str)
    return Number.isFinite(number)
      ? {value: number}
      : {value: null, error: `Value ${value_str} is not a valid number.`}
  }

  return {value: value_str}
}

const get_filter_value_type = (
  cell_type: CellType,
  filter_type: FilterType
): {multi: boolean; value: CellType | 'boolean'} => {
  if (BooleanValueFilterSet.has(filter_type)) {
    return {multi: false, value: 'boolean'}
  }
  if (MultiColumnFilterSet.has(filter_type) || MultiValueFilterSet.has(filter_type)) {
    return {multi: true, value: cell_type}
  }
  if (SingleColumnFilterSet.has(filter_type)) {
    return {multi: false, value: cell_type}
  }
  if (StringFilterValuesSet.has(filter_type)) {
    return {multi: false, value: 'string'}
  }
  if (DateFilterValuesSet.has(filter_type)) {
    return {multi: false, value: 'date'}
  }
  if (NumberFilterValuesSet.has(filter_type)) {
    return {multi: false, value: 'number'}
  }
  if (BooleanFormatFilterSet.has(filter_type)) {
    return {multi: false, value: 'string'}
  }
  return throw_error('Unknown filter_type', filter_type)
}

export const adjust_filter_value = (
  cell_type: CellType,
  old_filter_type: FilterType,
  new_filter_type: FilterType,
  value: FilterValue<'internal'>
): FilterValue<'internal'> | null => {
  const [old_filter, new_filter] = [
    get_filter_value_type(cell_type, old_filter_type),
    get_filter_value_type(cell_type, new_filter_type),
  ]
  // first check if value is consistent with previous filter_type
  const {value: validated_value, error} = validate_filter_value(cell_type, old_filter_type, value)

  if (error!!) {
    return value
  }

  // if the types of filter values are the same, we don't have to reset value
  if (old_filter.value === new_filter.value) {
    if (old_filter.multi === new_filter.multi) {
      return validate_filter_value(cell_type, new_filter_type, validated_value).value
    }
    return validate_filter_value(
      cell_type,
      new_filter_type,
      validated_value === null ? [] : old_filter.multi ? validated_value[0] : [validated_value]
    ).value
  }

  return validate_filter_value(cell_type, new_filter_type, null).value
}

/**
 * Transform filter rows into advanced filter
 * It's a reverse function to `get_filter_conditions`
 * @function
 * @param operator {FilterOperator}
 * @param ui_filter_conditions {UiFilterCondition[]}
 * @param table {TableObject}
 * @return {{filter: Filter<'internal'> | null, ui_filter_conditions: UiFilterCondition[],
 * error: boolean}}
 */
export const get_filter_from_conditions = (
  operator: FilterOperator,
  ui_filter_conditions: UiFilterCondition[],
  table: TableObject
): {
  filter: Filter<'internal'> | null
  ui_filter_conditions: UiFilterCondition[]
  error: boolean
} => {
  if (ui_filter_conditions.length === 0) return {filter: null, ui_filter_conditions, error: false}

  const conditions: FilterConditions<'internal'> = {}

  let error_anywhere = false

  const new_ui_filter_conditions: UiFilterCondition[] = ui_filter_conditions.map(
    ([condition_id, col_id, filter_type, value]) => {
      if (table._cols[col_id]) {
        const {value: typed_value, error: value_error} = validate_filter_value(
          table._cols[col_id].type.type,
          filter_type,
          value
        )

        if (value_error) {
          error_anywhere = true
          return [condition_id, col_id, filter_type, value, value_error]
        }

        if (conditions[col_id] === undefined) {
          conditions[col_id] = {}
        }

        if (conditions[col_id][filter_type]) {
          conditions[col_id][filter_type].push(typed_value)
        } else {
          conditions[col_id][filter_type] = [typed_value]
        }
      }

      return [condition_id, col_id, filter_type, value]
    }
  )

  return {
    filter: {operator, conditions},
    ui_filter_conditions: new_ui_filter_conditions,
    error: error_anywhere,
  }
}

export const get_filter_types_applicable_to_column = (
  col_type: ColumnType<CellType, 'entity'>
): FilterType[] => {
  if (col_type.multi)
    return [...MultiColumnFilterSet, ...MultiValueFilterSet, ...BooleanValueFilterSet]

  switch (col_type.type) {
    case 'string':
    case 'markdown':
      return [...SingleColumnFilterSet, ...StringFilterValuesSet, ...BooleanValueFilterSet]
    case 'date':
      return [...SingleColumnFilterSet, ...DateFilterValuesSet, ...BooleanValueFilterSet]
    case 'date_time':
      return [...SingleColumnFilterSet, ...DateFilterValuesSet, ...BooleanValueFilterSet]
    case 'number':
      return [...SingleColumnFilterSet, ...NumberFilterValuesSet, ...BooleanValueFilterSet]
    case 'reference':
    case 'option':
      return [...SingleColumnFilterSet, ...MultiValueFilterSet, ...BooleanValueFilterSet]
    case 'attachment':
      return [...BooleanValueFilterSet]
    case 'boolean':
      return [...BooleanFormatFilterSet, ...BooleanValueFilterSet]
    default:
      return ((x: never) => {
        throw_error(`${x} was unhandled!`) // Compile time error
      })(col_type)
  }
}

export const filter_to_display_name: Record<FilterType, string> = {
  is_empty: 'is empty',
  is_error: 'is error',
  equal_to: '=',
  not_equal_to: '≠',
  multi_equal_to: '=',
  multi_not_equal_to: '≠',
  contains: 'contains',
  has_any_of: 'has any of',
  has_none_of: 'has none of',
  substring: 'contains',
  starts_with: 'starts with',
  ends_with: 'ends with',
  before: '<',
  after: '>',
  on_or_before: '≤',
  on_or_after: '≥',
  greater_than: '>',
  less_than: '<',
  greater_than_or_equal_to: '≥',
  less_than_or_equal_to: '≤',
  is_format_false: 'is not',
  is_format_true: 'is',
}

export const subtable_union = (...selections: SubtableSelection[]): SubtableSelection => ({
  allowed_rows: _.union(...selections.map(({allowed_rows}) => allowed_rows)),
  allowed_cols: _.union(...selections.map(({allowed_cols}) => allowed_cols)),
})
