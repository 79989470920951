import React, {FC, useCallback, useMemo, useState} from 'react'
import numbro from 'numbro'
import {sum as sum_array} from 'common/utils'
import {RowId, ColumnId} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {AlwaysDefinedRuntime, useRuntimeSelector, useTableUiSelector} from '../utils/connect_hocs'
import {get_column_selection, get_row_selection} from '../table_data_helpers'
import {MenuItem} from '@material-ui/core'
import CustomSelect from '../components/CustomSelect'

type SummaryValues = {
  count: number | null
  sum: number | null
  average: number | null
}
type SummaryType = keyof SummaryValues

const SUMMARY_LABELS: Record<SummaryType, string> = {count: 'Count', sum: 'Sum', average: 'Average'}

const value_to_string = (value: number | null): string => {
  if (value === null) {
    return ''
  } else {
    return numbro(value).format()
  }
}

const get_selection_values = (
  table: TableObject,
  selected_rows: RowId[],
  selected_columns: ColumnId[],
  number_values: boolean
) => {
  const column_ids = number_values
    ? selected_columns.filter((col_id) => table._cols[col_id].type.type === 'number')
    : selected_columns

  return selected_rows.flatMap((row_id) => {
    const row = table._row(row_id)
    return column_ids.map((col_id) => {
      const cell = row._cell(col_id)

      return number_values
        ? cell.error
          ? null
          : (cell.get_value() as number)
        : cell.raw_value != null
    })
  })
}

const compute_selection_summary_values = (
  table: TableObject,
  row_selection: RowId[],
  col_selection: ColumnId[]
): SummaryValues => {
  const values = get_selection_values(table, row_selection, col_selection, false)
  const number_values = get_selection_values(table, row_selection, col_selection, true).filter(
    (value): value is number => typeof value === 'number'
  )

  const count = values.length === 0 ? null : values.filter(Boolean).length
  const sum = number_values.length === 0 ? null : sum_array(number_values)
  const average = sum == null ? null : numbro(sum).divide(number_values.length).value()

  return {count, sum, average}
}

const SelectionSummary: FC = () => {
  const {
    resources: {
      table_resources: {table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const selection_size = useTableUiSelector(table_entity_id, 'selection_size')
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')

  const [summary_type, set_summary_type] = useState<SummaryType>('sum')
  const handle_change = useCallback(
    (e) => {
      set_summary_type(e.target.value)
    },
    [set_summary_type]
  )

  const row_selection = useMemo(() => get_row_selection(rows_order, cursor, selection_size), [
    rows_order,
    cursor,
    selection_size,
  ])
  const col_selection = useMemo(() => get_column_selection(table, cursor, selection_size), [
    table,
    cursor,
    selection_size,
  ])

  const selection_summary_values = useMemo(
    () => compute_selection_summary_values(table, row_selection, col_selection),
    [table, row_selection, col_selection]
  )

  const render_value = (chosen_type: SummaryType) => {
    const type: SummaryType = selection_summary_values[chosen_type] == null ? 'count' : chosen_type
    return `${SUMMARY_LABELS[type]}: ${value_to_string(selection_summary_values[type])}`
  }

  return (
    <CustomSelect value={summary_type} onChange={handle_change} renderValue={render_value}>
      {Object.entries(SUMMARY_LABELS).map(([key, label]) => (
        <MenuItem key={key} value={key}>
          {`${label}: ${value_to_string(selection_summary_values[key])}`}
        </MenuItem>
      ))}
    </CustomSelect>
  )
}

export default SelectionSummary
