import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {styled, Theme} from '@material-ui/core'

import TablePage from './Pages/TablePage'
import PerformanceDisplay from './performance/PerformanceDisplay'
import ErrorPage from './Pages/ErrorPage'
import BrowserPage from './Pages/BrowserPage'
import Explorer, {EXPLORER_WIDTH} from './Pages/Explorer'

import {ROUTES} from './utils/navigation_utils'
import Modals from './Modals'
import Navigation from './navigation/Navigation'
import {useSelector} from 'react-redux'
import {ReduxState} from './utils/connect_hocs'

type PageContainerProps = {
  open: boolean
}

const PageContainer = styled(({open, ...props}: PageContainerProps) => <div {...props} />)(
  ({theme, open}: {theme: Theme} & PageContainerProps) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['width', 'margin-left']),
    width: open ? `calc(100% - ${EXPLORER_WIDTH}px)` : '100%',
    marginLeft: open ? EXPLORER_WIDTH : 0,
  })
)

const App = () => {
  const explorer_state = useSelector((state: ReduxState) => state.explorer)

  return (
    <>
      <Modals />
      <Switch>
        <Redirect path={ROUTES.home()} exact to={ROUTES.browser()} />
        <Route>
          <Explorer />
          <PageContainer open={explorer_state.open}>
            <Navigation />
            <Switch>
              <Route
                exact
                path={ROUTES.not_found()}
                render={() => <ErrorPage error={'The requested resource could not be found.'} />}
              />
              <Route path={ROUTES.browser()} component={BrowserPage} />

              <Route path={ROUTES.benchmark()} component={PerformanceDisplay} />
              <Route
                path={ROUTES.table(':base_table_entity_id', ':view_table_entity_id')}
                component={TablePage}
              />
              <Route path={ROUTES.table(':base_table_entity_id')} component={TablePage} />
              <Redirect to={ROUTES.not_found()} />
            </Switch>
          </PageContainer>
        </Route>
      </Switch>
    </>
  )
}

export default App
