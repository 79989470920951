import React, {FC, useState, useCallback, useMemo, useEffect} from 'react'
import {RouteComponentProps, Redirect} from 'react-router-dom'
import {Runtime} from 'common/create_runtime'
import {Table} from '../Table'
import Toolbar from '../Toolbar/Toolbar'
import TableSidebar from '../Sidebar/TableSidebar'
import GlobalOverlay from '../GlobalOverlay'
import TableTabs from '../navigation/TableTabs'
import {load_table, get_diff_for_table, TableDiff} from '../table_data_helpers'
import {useContextRunner} from '../runtime_hooks'
import {
  useRuntimeSelector,
  useTableUiSelector,
  useSetTableUiState,
  RuntimeResources,
  CursorCoordinates,
} from '../utils/connect_hocs'
import {useRuntimeContext} from '../RuntimeContextProvider'
import {ROUTES} from '../utils/navigation_utils'
import {permission_table_entities_task, projects_table_entities_task} from 'common/project_utils'
import {compose_resource_id, compose_table_id, ComputedParams} from 'common/params_utils'
import {COLUMN_RESOURCE_TYPES, execute_column, execute_value} from 'common/compute_resource'
import {throw_error} from 'common/utils'
import ErrorMessage from '../components/ErrorMessage'
import {create_error, is_error, entity_has_no_changes_to_display, ErrorObject} from 'common/error'
import {TableObject} from 'common/objects/data_table'
import {ResourceData, Storage, TableEntityId, TableId, ZoneId} from 'common/types/storage'
import {get_current_entry} from '../DetailedView/history_actions'
import {Filter} from 'common/types/filter'
import _ from 'lodash'
import ErrorPage from './ErrorPage'
import {styled, CircularProgress, Box} from '@material-ui/core'
import {log_with_color} from '../utils/log_utils'
import {get_root_error} from '../utils/error_utils'
import {ArchivedInfoBar} from '../ArchivedInfoBar'

const Content = styled('div')(({theme}) => ({
  borderTop: `1px solid ${theme.palette.greyPalette[100]}`,
  minHeight: 0,
  flex: '1 1 100%',
}))

/**
 * TablePageResources encapsulate all data needed for displaying the table page correctly.
 *
 *  table_entity_id     - entity ID of the currently displayed table (If view table is displayed it
 *                        is the entity ID of that view table)
 *
 *  zone_id             - ID of the zone of the displayed table
 *
 *  full_table          - table object without filter or search applied
 *
 *  table               - table object for the currently displayed table. If neither filter nor
 *                        search are present in the table UI state or URL parameters, it is the same
 *                        as full_table, but otherwise we need to create a second table object with
 *                        data filtered. The reason why we need separate objects for filtered table
 *                        and full table is that all table actions which modifies table entity (e.g.
 *                        sorting) we want to make on full table, even when filtered table is
 *                        displayed
 *
 *  diff                - used to style changed data when history sidebar is open. Most of the time
 *                        it contains all uncommitted (current) changes, but when in history mode,
 *                        it contains changes from the selected commit.
 *
 *  detailed_view_table - defined only when DetailedView modal is open. In this modal, we can click
 *                        on reference and then go through rows of the referenced table, so we need
 *                        separate table resource for rendering the data from the referenced table.
 *
 *  detailed_view_diff  - same as diff for table, but for detailed_view_table. We want to style
 *                        changed data differently than normal data also in detailed view. It can
 *                        contain current changes in the detailed_view_table or changes from
 *                        selected commit, when in history mode.
 *
 *  previews            - preview data for user functions in column settings editor. Currently
 *                        computed only when user requests it.
 *
 * These resources are stored in the runtime state in redux and then can be accessed in various
 * components by useRuntimeSelector(). It's because we don't want random components to have access
 * to the runtime_context and get the resources by themselves. Each such component would need to
 * handle the in-progress state as TablePage does because the resources don't have to be ready. And
 * runtime_context.run can run only one computation (if the two components try to use it in
 * parallel, the second one kills the computation of the first one).
 */
export type TablePageResources = {
  table_entity_id: TableEntityId
  zone_id?: ZoneId
  full_table?: TableObject
  table?: TableObject
  diff?: TableDiff
  detailed_view_table?: TableObject
  detailed_view_diff?: TableDiff
  previews?: Record<string, unknown>
}

type TablePageProps = RouteComponentProps<{
  base_table_entity_id: TableEntityId
  view_table_entity_id?: TableEntityId
}>

const TablePage: FC<TablePageProps> = ({
  history,
  match: {
    params: {
      base_table_entity_id: url_base_table_entity_id,
      view_table_entity_id: url_view_table_entity_id,
    },
  },
}) => {
  const set_base_table_ui_state = useSetTableUiState(url_base_table_entity_id)
  const last_view_entity_id = useTableUiSelector(url_base_table_entity_id, 'last_view_entity_id')
  // if entity_id of the view is missing in URL, set:
  // - last displayed view (stored in table_ui_state) if present
  // - default view (base table id) otherwise
  const url_table_entity_id =
    url_view_table_entity_id || last_view_entity_id || url_base_table_entity_id

  const set_table_ui_state = useSetTableUiState(url_table_entity_id)
  const search_text = useTableUiSelector(url_table_entity_id, 'search_text')
  const filter = useTableUiSelector(url_table_entity_id, 'filter')
  const params = useTableUiSelector(url_table_entity_id, 'params')
  const detailed_view = useTableUiSelector(url_table_entity_id, 'detailed_view')
  const previews = useTableUiSelector(url_table_entity_id, 'previews')
  const detailed_view_entry = get_current_entry(detailed_view)
  const detailed_view_entity_id = detailed_view_entry ? detailed_view_entry.table_entity_id : null
  // Query string inside the URL
  const url_query_string = window.location.search
  // Query string corresponding to our current redux state
  const redux_query_string = useMemo(() => {
    if (search_text || filter || params) {
      return `?${new URLSearchParams({
        ...(params && !_.isEmpty(params) && {params: JSON.stringify(params)}),
        ...(search_text && {search: search_text}),
        ...(filter && {filter: JSON.stringify(filter)}),
      }).toString()}`
    } else {
      return ''
    }
  }, [search_text, filter, params])

  // Decide if we should read the filter from the URL or from the redux state
  const [read_from_url, set_read_from_url] = useState(url_query_string !== '')
  const [table_error, set_table_error] = useState<ErrorObject | undefined>()
  useEffect(() => {
    if (url_view_table_entity_id == null) {
      history.replace(ROUTES.table(url_base_table_entity_id, url_table_entity_id))
    }
    if (url_table_entity_id !== last_view_entity_id) {
      // remember current view as last_view_entity_id in table_ui state of base table
      set_base_table_ui_state({last_view_entity_id: url_table_entity_id})
    }
  }, [
    last_view_entity_id,
    history,
    set_base_table_ui_state,
    url_base_table_entity_id,
    url_table_entity_id,
    url_view_table_entity_id,
  ])

  useEffect(() => {
    if (redux_query_string !== url_query_string) {
      if (read_from_url) {
        // Set redux state according to URL
        const search_params = new URLSearchParams(url_query_string)
        const search_text = search_params.get('search')
        const filter_text = search_params.get('filter')
        const table_params_text = search_params.get('params')
        let params: ComputedParams | null = null
        let filter: Filter<'internal'> | null = null
        if (filter_text) {
          try {
            filter = JSON.parse(filter_text) as Filter<'internal'>
          } catch (error) {
            console.error(error)
          }
        }
        if (table_params_text) {
          try {
            params = JSON.parse(table_params_text) as ComputedParams
          } catch (error) {
            console.error(error)
          }
        }

        set_table_ui_state({params, filter, search_text}, 'set_filter_from_url')

        const row_id = search_params.get('row_id')
        if (row_id) {
          const new_cursor: CursorCoordinates = {row_id, col_id: null}
          set_table_ui_state(
            {
              last_cursor: new_cursor,
              last_scroll_to: new_cursor,
            },
            'set_row_id_from_url'
          )
        }
      } else {
        // Set URL according to redux state
        history.replace(window.location.pathname + redux_query_string)
      }
    }

    if (read_from_url) {
      set_read_from_url(false)
    }
  }, [history, read_from_url, url_query_string, redux_query_string, set_table_ui_state])

  const get_resources = useCallback(
    (runtime: Runtime): RuntimeResources => {
      const base_table_entity_id = url_base_table_entity_id
      const base_table_header = runtime.get_headers()[base_table_entity_id]

      // Check if base table is computed_table/data_table
      if (base_table_header == null) {
        throw create_error('user', {subtype: 'unknown-entity', base_table_entity_id})
      } else if (!['data_table', 'computed_table'].includes(base_table_header.type)) {
        // base table can only be data_table or computed_table, not view_table
        throw_error('Unexpected entity type for base table,', 'type', base_table_header.type)
      }

      // check if view exists and its parent is base table
      const view_header = runtime.get_headers()[url_table_entity_id]
      const is_correct_view = view_header && view_header.parent_id === base_table_entity_id

      // if url_table_entity_id (view id) does not exist (or belong to base table)
      // fetch resources for base table
      const table_entity_id = is_correct_view ? url_table_entity_id : base_table_entity_id
      const table_header = runtime.get_headers()[table_entity_id]

      // These checks are handled by get_table, but we need to get project_id of table from headers
      // before obtaining its entity, so all entities can be fetched simultaneously.

      // check if entity id (view id) was non-existing and replaced by base table entity id
      if (table_entity_id === base_table_entity_id && table_entity_id !== url_table_entity_id) {
        // replace view entity id in by base table entity id in URL
        history.push(ROUTES.table(base_table_entity_id, base_table_entity_id))
      } else if (!['data_table', 'view_table', 'computed_table'].includes(table_header.type)) {
        // Can happen with the wrong URL combinations, e.g. table/id_of_organization
        throw_error('Unexpected entity type,', 'type', table_header.type)
      }

      // we want computed tables and data tables from project zone
      // but permission tables from organisation zone
      const zone_id = table_header.zone_id
      const zone_type = runtime.get_headers()[zone_id].type as 'project' | 'organisation'
      const zone_task = () => runtime.get_entity(zone_id, zone_type)
      const table_tasks =
        zone_type === 'project'
          ? projects_table_entities_task(runtime, zone_id)
          : permission_table_entities_task(runtime, zone_id)

      const [zone_entity] = runtime.get_all<
        ReturnType<typeof zone_task | typeof table_tasks[number]>
      >([zone_task, ...table_tasks])

      // Prepare table resources needed for displaying and working with the table
      // More about these resources can be found at the top of this file
      const table_resources: TablePageResources = {table_entity_id}

      const full_table_id = compose_table_id({entity_id: table_entity_id})

      const full_table = load_table(runtime, full_table_id)
      table_resources.full_table = full_table
      table_resources.diff = get_diff_for_table(runtime, full_table)
      table_resources.zone_id = full_table._zone_id

      if (filter || search_text || params) {
        const filtered_table_id: TableId = compose_table_id({
          entity_id: table_entity_id,
          ...(params ? {params} : {}),
          ...(filter ? {filter} : {}),
          ...(search_text ? {search: search_text} : {}),
        })
        table_resources.table = load_table(runtime, filtered_table_id)
      } else {
        table_resources.table = full_table
      }

      if (detailed_view_entity_id) {
        const detailed_view_table_id = compose_table_id({entity_id: detailed_view_entity_id})
        const detailed_view_table = load_table(runtime, detailed_view_table_id)
        table_resources.detailed_view_table = detailed_view_table
        table_resources.detailed_view_diff = get_diff_for_table(runtime, detailed_view_table)
      }

      table_resources.previews = _.mapValues(previews, (preview_data, preview_id) => {
        const {fn: fn_string, type: preview_type} = preview_data
        const preview_resource_id = compose_resource_id({
          table_id: full_table_id,
          type: 'preview',
          preview_id,
        })
        return runtime._get_resource(preview_resource_id, (runtime) => {
          const table = runtime._get_table(full_table_id)
          if (preview_type === 'summary') {
            return execute_value(runtime, table, fn_string) as ResourceData
          } else if (COLUMN_RESOURCE_TYPES.includes(preview_type)) {
            return execute_column(runtime, table, fn_string, preview_type)
          } else {
            return throw_error('Unexpected preview type,', 'type', preview_type)
          }
        })
      })

      return {
        table_resources,
        ...(zone_entity.type === 'project' && {
          project_resources: {project_id: zone_id, project: zone_entity},
        }),
      }
    },
    [
      url_table_entity_id,
      url_base_table_entity_id,
      filter,
      params,
      search_text,
      detailed_view_entity_id,
      previews,
      history,
    ]
  )

  const get_default_resources = useCallback(
    (storage: Storage): RuntimeResources => ({
      table_resources: {
        table_entity_id: url_table_entity_id,
        // Try to read zone_id from entity headers, if possible
        zone_id: storage.entity_headers[url_table_entity_id]?.zone_id,
      },
    }),
    [url_table_entity_id]
  )

  useContextRunner(get_resources, get_default_resources)

  const runtime_context = useRuntimeContext()
  useEffect(() => {
    return () => {
      if (search_text) {
        // needs to contain all parts applied before search and none applied later
        const table_id: TableId = compose_table_id({
          entity_id: url_table_entity_id,
          ...(filter ? {filter} : {}),
          search: search_text,
        })
        const resource_id = compose_resource_id({type: 'table', table_id})
        // Remove previous filtered (by search) table resource
        runtime_context.mutable_dispatch([{type: 'remove-resource', resource_id}])
      }
    }
  }, [runtime_context, url_table_entity_id, filter, search_text])
  useEffect(() => {
    return () => {
      if (filter) {
        // needs to contain all parts applied before filter and none applied later
        const table_id: TableId = compose_table_id({entity_id: url_table_entity_id, filter})
        const resource_id = compose_resource_id({type: 'table', table_id})
        // Remove previous filtered (by filter) table resource
        runtime_context.mutable_dispatch([{type: 'remove-resource', resource_id}])
      }
    }
  }, [runtime_context, url_table_entity_id, filter])

  const redirect_to_table = useCallback(
    (table_entity_id: TableEntityId) => {
      history.replace(ROUTES.table(table_entity_id))
    },
    [history]
  )

  const {
    status,
    error,
    storage,
    runtime,
    resources: {table_resources},
  } = useRuntimeSelector()

  useEffect(() => {
    if (!table_resources || !storage || !table_resources.table) {
      return
    }
    const {table} = table_resources
    const table_res_id = compose_resource_id({
      type: 'table',
      table_id: compose_table_id({entity_id: table?._entity_id ?? ''}),
    })
    const table_res = storage.resources[table_res_id]
    if (is_error(table_res?.result)) {
      const error_message = {title: 'table error', error: table_res.result}
      log_with_color('Table error', 'red')
      console.log(error_message)
      set_table_error(get_root_error(table_res.result))
    } else {
      set_table_error(undefined)
    }
  }, [table_resources, storage])

  if (runtime == null || storage == null) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Box>
    )
  } else if (status === 'error') {
    if (is_error(error)) {
      if (entity_has_no_changes_to_display(error)) {
        // some error messages can be displayed with whole UI except cell grid
      } else if (error.type === 'user' && error.subtype === 'unknown-entity') {
        return <Redirect to={ROUTES.not_found()} />
      } else {
        return <ErrorPage error={error} />
      }
    } else {
      return <ErrorPage error={error} />
    }
  } else if (!table_resources) {
    // Might seem like code duplication but will probably be different from the one above later on.
    // e.g: in this state we have access to entity headers so we could show the navigation bar.
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
      </Box>
    )
  }

  const {table} = table_resources!
  return (
    <>
      {table && <ArchivedInfoBar entity_id={table!._entity_id} />}
      <GlobalOverlay open={status === 'in-progress'} />
      <TableTabs />
      <Toolbar />
      <Content id="table">
        <TableSidebar />
        {table != null ? (
          table_error ? (
            <ErrorMessage error={table_error.original_error ?? table_error} />
          ) : (
            <Table redirect_to_table={redirect_to_table} />
          )
        ) : (
          <ErrorMessage error={error} />
        )}
      </Content>
    </>
  )
}

export default TablePage
