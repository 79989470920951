import React from 'react'
import _ from 'lodash'
import styles from './ErrorDescription.module.css'
import {get_root_error} from '../utils/error_utils'

const ErrorDescription = ({error}) => {
  const root_error = get_root_error(error)
  const type = _.get(root_error, 'type')

  const show_value = type === 'value'

  const show_stack = ['user', 'parse'].includes(type)

  return (
    <div className={styles.errorDescription}>
      <div>{root_error.message || type || 'Unknown Error'}</div>
      {show_value && <div>Value: {`${root_error.value}`}</div>}
      {show_stack && root_error.stack && <div>Stack: {`${root_error.stack}`}</div>}
    </div>
  )
}

export default ErrorDescription
