import React, {FC} from 'react'
import _ from 'lodash'
import Alert from '@material-ui/lab/Alert'

import {is_error, ErrorObject} from 'common/error'

export function get_error_message(error?: Error | ErrorObject | string | null): string {
  if (is_error(error)) {
    if (error.type === 'user') {
      switch (error.subtype) {
        case 'unknown-entity':
          return 'This entity does not exist.'
        case 'unknown-entity-commit':
          return 'This entity does not exist for this commit.'
        default:
          break
      }
    } else if (error.type === 'circular-dependency') {
      return 'Circular dependency error.'
    }
    return error.message || 'An unknown error occurred.'
  } else if (_.isError(error)) {
    return error.toString()
  } else if (typeof error === 'string') {
    return error
  } else {
    return 'An unknown error occurred.'
  }
}

type ErrorMessageProps = {
  error?: Error | ErrorObject | string | null
}

const ErrorMessage: FC<ErrorMessageProps> = ({error}) => (
  <Alert severity="error">{get_error_message(error)}</Alert>
)

export default ErrorMessage
