import React, {FC, RefCallback, RefObject, useCallback, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
  Theme,
} from '@material-ui/core'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import UnarchiveTwoToneIcon from '@material-ui/icons/UnarchiveTwoTone'

import {icons, icon_color} from './Pages/TreeItem'
import CustomLabel from './components/CustomLabel'
import EntityContextMenu from './ContextMenu/EntityContextMenu'
import {TableEntityId} from 'common/types/storage'
import {Coordinates} from './utils/mouse_utils'

type TextWrapperProps = {
  has_end_icon: boolean
}

const TextWrapper = styled(({has_end_icon, ...props}: TextWrapperProps) => <div {...props} />)(
  ({theme, has_end_icon}: TextWrapperProps & {theme: Theme}) => ({
    flex: 1,
    overflowWrap: 'break-word',
    marginLeft: theme.spacing(1),
    maxWidth: `calc(100% - ${theme.spacing(4)}px - ${has_end_icon ? theme.spacing(4) : 0}px)`,
  })
)

const StyledIconButton = styled(IconButton)(({theme}) => ({
  marginLeft: theme.spacing(1),
  height: theme.spacing(3),
  flexShrink: 0,
}))

type GalleryListItemProps = {
  label: string
  description?: string
  type: string
  subtype?: string
  href?: string
  hasUnsavedChanges?: boolean
  is_archived?: boolean
  is_parent_archived?: boolean
  on_click?: (e: any) => void
  on_edit?: () => any
  on_unarchive?: () => any
  can_edit?: boolean
  forwardedRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement> | null
  entity_id: TableEntityId
}

const GalleryListItem: FC<GalleryListItemProps> = ({
  on_edit,
  on_click,
  on_unarchive,
  can_edit = false,
  label,
  description,
  href,
  type,
  forwardedRef,
  subtype,
  hasUnsavedChanges = false,
  is_archived = false,
  is_parent_archived = false,
  entity_id,
}) => {
  const handle_edit = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (on_edit) on_edit()
    },
    [on_edit]
  )

  const handle_unarchive = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (on_unarchive) on_unarchive()
    },
    [on_unarchive]
  )

  const Icon = icons[type]

  const [context_menu_pos, set_context_menu_pos] = useState<Coordinates | null>(null)
  const close_context_menu = useCallback(() => {
    set_context_menu_pos(null)
  }, [])

  const on_context_menu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    const pointer_pos = {top: e.pageY, left: e.pageX}
    set_context_menu_pos(pointer_pos)
  }

  const item_box = (
    <Box display="flex" py={2} pl={2} pr={on_edit ? 1 : 2}>
      <Icon color={icon_color[type]} />
      <TextWrapper has_end_icon={!!on_edit}>
        <CustomLabel
          elementVariant="body1"
          label={label}
          showModificationIndicator={hasUnsavedChanges}
          showArchivedIndicator={is_archived}
        />
        {subtype && (
          <Typography display="block" variant="caption" color="textSecondary">
            {subtype}
          </Typography>
        )}
      </TextWrapper>
      {!!on_edit &&
        !is_parent_archived &&
        (is_archived ? (
          <Tooltip title="Unarchive">
            <StyledIconButton onClick={handle_unarchive} size="small">
              <UnarchiveTwoToneIcon fontSize="small" />
            </StyledIconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Edit">
            <StyledIconButton onClick={handle_edit} size="small">
              <EditTwoToneIcon fontSize="small" />
            </StyledIconButton>
          </Tooltip>
        ))}
    </Box>
  )

  return (
    <Grid item sm={12} md={4} lg={3} xl={2}>
      <Card variant="outlined" innerRef={forwardedRef} onContextMenu={on_context_menu}>
        {can_edit && !is_parent_archived && (
          <EntityContextMenu
            on_close={close_context_menu}
            pos={context_menu_pos}
            entity_id={entity_id}
          />
        )}
        <CardActionArea component={on_click ? 'div' : RouterLink} to={href} onClick={on_click}>
          {description ? (
            <Tooltip
              title={
                <div style={{textAlign: 'center'}}>
                  <Typography>{description}</Typography>
                </div>
              }
            >
              {item_box}
            </Tooltip>
          ) : (
            item_box
          )}
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default React.forwardRef<HTMLDivElement, GalleryListItemProps>((props, ref) => (
  <GalleryListItem {...props} forwardedRef={ref} />
))
