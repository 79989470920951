import React, {FC} from 'react'
import {Typography, Box, Theme} from '@material-ui/core'
import {makeStyles, createStyles, styled} from '@material-ui/core/styles'
import {ColumnId} from 'common/types/storage'
import {get_icon} from '../utils/icons_utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnItem: {
      cursor: 'pointer',
      color: theme.palette.greyPalette[400],
    },
    icon: (props: {checked: boolean}) => ({
      display: 'flex',
      color: props.checked ? theme.palette.greyPalette[100] : theme.palette.greyPalette[900],
    }),
    name: {
      maxWidth: 200,
      fontWeight: 500,
      marginLeft: theme.spacing(1.5),
    },
  })
)

const IconWrapper = styled('div')(({theme}) => ({
  width: 20,
  padding: theme.spacing(0, 0.25),
}))

type ColumnItemProps = {
  table
  col_id: string
  toggle: (col_ids: ColumnId[], checked: boolean) => void
}

const ColumnItem: FC<ColumnItemProps> = ({table, col_id, toggle}) => {
  const column = table._cols[col_id]
  const checked = !column.hidden

  const classes = useStyles({checked})
  return (
    <Box
      className={classes.columnItem}
      onClick={() => toggle([col_id], checked)}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
    >
      <Box display="flex" alignItems="center" mr={2}>
        <IconWrapper>
          <i className={get_icon(column.type.type, false)} />
        </IconWrapper>
        <Typography className={classes.name} color="inherit" variant="body2" noWrap>
          {String(column.name)}
        </Typography>
      </Box>
      <Box className={classes.icon}>
        <i className="fas fa-eye-slash" />
      </Box>
    </Box>
  )
}

export default ColumnItem
