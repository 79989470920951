import React, {FC, useCallback, useEffect, useRef, useState} from 'react'

import {
  AlwaysDefinedRuntime,
  get_table_ui_dispatch,
  get_table_ui_state,
  ReduxThunk,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
  useThunkDispatch,
} from '../utils/connect_hocs'
import {ColumnId, RowId, TableEntityId} from 'common/types/storage'
import {TableRowObject} from 'common/types/data_table'
import {TableObject} from 'common/objects/data_table'
import {is_error} from 'common/error'
import ih from 'immutability-helper'
import FindInput from './FindInput'
import {Position} from '../utils/layout'
import {get_coords} from '../utils/table_helpers'
import {scroll_to_new_cursor_payload} from '../utils/cursor_utils'

export type FindCellsMap = Record<RowId, Record<ColumnId, true>>
export type FindCellsOrder = Position[]

export type FindResult = {
  done: boolean
  cells_map: FindCellsMap
  cells_order: FindCellsOrder
}

function update_find_result(
  table_entity_id: TableEntityId,
  find_result_update: FindResult
): ReduxThunk {
  return (dispatch, get_state) => {
    const {find_result} = get_table_ui_state(get_state(), table_entity_id)
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)
    set_table_ui_state(
      {
        find_result: {
          ...ih(find_result || {done: false, cells_map: {}, cells_order: []}, {
            done: {$set: find_result_update.done},
            cells_map: {$merge: find_result_update.cells_map},
            cells_order: {$push: find_result_update.cells_order},
          }),
        },
      },
      'add_search_result'
    )
  }
}

const focus_first_result = (table_entity_id: TableEntityId, table: TableObject): ReduxThunk => {
  return (dispatch, get_state) => {
    const {find_result, rows_order} = get_table_ui_state(get_state(), table_entity_id)
    const set_table_ui_state = get_table_ui_dispatch(dispatch, table_entity_id)

    if (find_result?.cells_order.length) {
      const new_cursor = get_coords(find_result.cells_order[0], table, rows_order)

      set_table_ui_state(
        {
          find_position: 0,
          ...scroll_to_new_cursor_payload(new_cursor),
        },
        'focus_first_result'
      )
    }
  }
}

const get_filter_fn = (text: string) => (row: TableRowObject, col_id: ColumnId): boolean => {
  const label = String(row._cell(col_id).get_label()).toLowerCase()
  return label.includes(text)
}

function find_in_chunk(
  max_time_per_chunk: number,
  start: number,
  table: TableObject,
  rows_order: RowId[],
  cols_order: ColumnId[],
  filter_fn: (row: TableRowObject, col_id: ColumnId) => boolean
) {
  const start_time = Date.now()

  const cells_map: FindCellsMap = {}
  const cells_order: FindCellsOrder = []

  const limit = rows_order.length
  let row_index = start
  while (row_index < limit && Date.now() - start_time < max_time_per_chunk) {
    const row_id = rows_order[row_index]
    const row = table._row(row_id)
    const columns_with_matches = cols_order
      .map<[ColumnId, number]>((col_id, i) => [col_id, i])
      .filter(([col_id]) => filter_fn(row, col_id))
    if (columns_with_matches.length > 0) {
      cells_map[row_id] = Object.fromEntries(columns_with_matches.map(([col_id]) => [col_id, true]))
      cells_order.push(
        // shifted by one because of col headers / row numbers, which aren't part of the order
        ...columns_with_matches.map<Position>(([, col_index]) => [col_index + 1, row_index + 1])
      )
    }
    row_index++
  }
  return {index: row_index, cells_map, cells_order}
}

// Search is done in an asynchronous manner in several chunks. First chunk of rows is searched
// immediately and the next chunk is scheduled for later.
// The result for chunk is dispatched directly to the TableUIState and we return cancel method
// by which we can stop search in the chunks scheduled for later.
// The number of rows processed in one chunk is variable based on execution time.
function useAsyncFind(table: TableObject | undefined, table_entity_id: TableEntityId) {
  const dispatch = useThunkDispatch()

  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')
  const cols_order = useTableUiSelector(table_entity_id, 'cols_order')

  return useCallback(
    (text: string) => {
      let finished = false

      if (table != null && !is_error(table) && text !== '') {
        const filter_fn = get_filter_fn(text.toLowerCase())

        const max_time_per_chunk = 100
        let start_index = 0

        ;(async () => {
          while (start_index < rows_order.length) {
            if (finished) {
              return
            }
            const {index, cells_map, cells_order} = find_in_chunk(
              max_time_per_chunk,
              start_index,
              table,
              rows_order,
              cols_order,
              filter_fn
            )
            start_index = index

            const done = start_index >= rows_order.length

            dispatch(update_find_result(table_entity_id, {done, cells_map, cells_order}))

            if (!done) {
              await new Promise((resolve) => {
                setTimeout(resolve, 100)
              })
            }
          }
        })()
      }

      return () => {
        if (finished) {
          return
        }
        set_table_ui_state({find_result: null, find_position: null}, 'clear_search_result_and_pos')
        finished = true
      }
    },
    [cols_order, dispatch, rows_order, set_table_ui_state, table, table_entity_id]
  )
}

const AsyncFind: FC = () => {
  const {
    resources: {
      table_resources: {table_entity_id, table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const [find_text, set_find_text] = useState<string>('')
  const should_update_cursor = useRef(false)
  const async_find = useAsyncFind(table, table_entity_id)
  const dispatch = useThunkDispatch()
  const find_result = useTableUiSelector(table_entity_id, 'find_result')

  // async_find depends on table and cols/rows_order, so the search starts from the beginning
  // when something from these or find_text is changed. This secures correct result also when user
  // changes table/cell/ordering during ongoing searching. The search restarts automatically.
  // async_find returns a function that cancels the ongoing search. This is used as the useEffect's
  // cleanup and runs when the dependencies change or the component is unmounted
  useEffect(() => {
    const cancel_find = async_find(find_text)
    return cancel_find
  }, [async_find, find_text])

  // change of find_text doesn't trigger this, the subsequent update of find_result does
  useEffect(() => {
    if (should_update_cursor.current && find_result?.cells_order?.length) {
      dispatch(focus_first_result(table_entity_id, table))
      should_update_cursor.current = false
    } else if (find_result?.done) {
      should_update_cursor.current = false
    }
  }, [find_result, dispatch, table_entity_id, table])

  const on_change = (str: string) => {
    set_find_text(str)
    should_update_cursor.current = true
  }

  return <FindInput find_text={find_text} change_find_text={on_change} />
}

export default AsyncFind
