const focus = (e) => e && e.focus()

function focus_table_content() {
  // focus the table div which listens to key presses
  const table_content = document.getElementById('table-content')
  if (table_content) table_content.focus()
}

function focus_table_content_with_timeout() {
  // when resources need to be recalculated, in-progress error is thrown and table loses focus
  // hotfix: we call this after recalculations are done,
  //         it doesn't work if something needs to be fetched
  setTimeout(() => {
    focus_table_content()
  }, 0)
}

function focus_table_find_input() {
  // focus the table find input
  const find_input = document.getElementById('table-find-input')
  if (find_input) find_input.focus()
}

export {focus, focus_table_content, focus_table_content_with_timeout, focus_table_find_input}
