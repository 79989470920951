/**
 * There is a line segment |XY| of 'full_size' size, which has another line segment |AB| in it.
 * |AB|'s starting from 'start' position A and ending in 'end' position B, see:
 *
 * X              A          B               Y
 * |--------------|----------|---------------|
 * 0             start      end          full_size
 *
 * The goal is to place another line segment |MN| of 'size' size on |XY|, so preferably
 * it is as close to |AB| as possible, and preferably it doesn't overlap with it.
 *
 * The method returns the suggested starting position of |MN| and true if it overlaps with |AB|
 */
function find_fit(start, end, full_size, size) {
  //                    M             N
  // let's find fit for |=============|
  // on
  //      X        A     B          Y
  //      |--------|-----|----------|

  // if full_size is smaller than size we just put it to the 0
  // we see the left point of segment |MN|
  //      M    A    B  Y  N
  //      |====|====|==|==|

  if (full_size < size) {
    return [0, true]

    // else let's try to put it on 'B' position, check if it fits into |XY|
    //      X        A     M          Y  N
    //      |--------|-----|==========|==|
  } else if (end + size <= full_size) {
    return [end, false]

    // second let's try to put it before 'A' position, check if it fits into |XY|
    // M    X        N     B          Y
    // |====|========|-----|----------|
  } else if (start - size >= 0) {
    return [start - size, false]

    // if everything fails we just put it to the end, so it overlaps with |AB|
    //      X        A  M  B          N
    //      |--------|--|==|==========|
  } else {
    return [full_size - size, true]
  }
}

export type Position = [absolute_x: number, absolute_y: number]
export type Offset = [relative_x: number, relative_y: number]
export type Dimensions = [col_count: number, row_count: number]
export type GCSelection = [top_left_x: number, top_left_y: number, ...positive_size: Dimensions]

/*
Coordinates of cell
[[Left, Right], [Top, Bottom]]

Rect_LR_TB[0][0], Rect_LR_TB[1][0] - coordinates of the top left corner of a cell
Rect_LR_TB[0][1], Rect_LR_TB[1][1] - coordinates of the bottom right corner of a cell
 */
export type Rect_LR_TB = [[left: number, right: number], [top: number, bottom: number]]
export type CellPositionGetter = (pos: Position) => Rect_LR_TB
export type ColRectangle = {top: number; left: number; right: number; height: number; width: number}
export type ColRectangleGetter = (col: number) => ColRectangle

export function get_tooltip_position(
  canvas_size: Dimensions,
  cell_position: Rect_LR_TB,
  tt_width: number,
  tt_height: number
): Position {
  const [width, height] = canvas_size
  const [[left, right], [top, bottom]] = cell_position

  // first we place the tooltip on the horizontal axis
  const [pos_x, overlap_with_cell] = find_fit(left, right, width, tt_width)

  let pos_y
  if (overlap_with_cell) {
    // if it overlaps with cell on the horizontal axis,
    // let's find a fit on the vertical axis
    pos_y = find_fit(top, bottom, height, tt_height)[0]
  } else {
    // if there is no overlap on the horizontal axis
    // (i.e. there won't be any overlap on the vertical either)
    // let's just make it sure it fits in the window
    pos_y = find_fit(bottom, top, height, tt_height)[0]
  }

  return [pos_x, pos_y]
}
