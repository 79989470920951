import React from 'react'

const SELECT_EDITOR_MIN_WIDTH = 120
const MARKDOWN_EDITOR_MIN_WIDTH = 250
const ATTACHMENT_EDITOR_MIN_WIDTH = 350

const MARKDOWN_EDITOR_HEIGHT = 150
const ATTACHMENT_EDITOR_MIN_HEIGHT = 150
const CURSOR_BORDER_WIDTH = 2

const find_fit = (start, end, available_size, size) => {
  if (available_size < size) {
    return 0
  } else if (start + size <= available_size) {
    return start
  } else if (end - size >= 0) {
    return end - size
  } else {
    return available_size - size
  }
}

const EditorPositioner = (props) => {
  const {canvas_size, cell_position, editor_type} = props
  const [[left, right], [top, bottom]] = cell_position
  const [width, height] = canvas_size

  const _editor_width = (editor_type) => {
    switch (editor_type) {
      case 'markdown':
        return Math.max(MARKDOWN_EDITOR_MIN_WIDTH, right - left)
      case 'select':
        return Math.max(SELECT_EDITOR_MIN_WIDTH, right - left)
      case 'attachment':
        return Math.max(ATTACHMENT_EDITOR_MIN_WIDTH, right - left)
      default:
        return right - left
    }
  }

  const _editor_height = (editor_type) => {
    switch (editor_type) {
      case 'markdown':
        return MARKDOWN_EDITOR_HEIGHT
      case 'attachment':
        return Math.max(ATTACHMENT_EDITOR_MIN_HEIGHT, bottom - top)
      default:
        return bottom - top
    }
  }

  const editor_width = _editor_width(editor_type)
  const editor_height = _editor_height(editor_type)

  const pos_x = find_fit(left, right, width, editor_width)
  const pos_y = find_fit(top, bottom, height, editor_height)

  return (
    <div
      id={'editor-container'}
      style={{
        // size is increased to mask cursor's border
        left: pos_x - CURSOR_BORDER_WIDTH,
        top: pos_y - CURSOR_BORDER_WIDTH,
        width: editor_width + CURSOR_BORDER_WIDTH * 2,
        height: editor_height + CURSOR_BORDER_WIDTH * 2,
        position: 'absolute',
      }}
    >
      {props.children}
    </div>
  )
}

export default EditorPositioner
