import React, {FC, useCallback} from 'react'
import {undo} from '../utils/table_actions_helpers'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {get_storage_utils} from 'common/storage_utils'
import {TableEntityId} from 'common/types/storage'
import {ROUTES} from '../utils/navigation_utils'
import {useHistory} from 'react-router-dom'
import {Tooltip} from '@material-ui/core'
import CustomButton from '../components/CustomButton'

const UndoButton: FC = () => {
  const {dispatch_storage} = useRuntimeActions()
  const history = useHistory()
  const {
    storage,
    resources: {
      table_resources: {table_entity_id, full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const storage_utils = get_storage_utils(storage)

  const redirect_to_table = useCallback(
    (table_entity_id: TableEntityId) => {
      history.replace(ROUTES.table(table_entity_id))
    },
    [history]
  )

  const handle_click = useCallback(() => {
    undo({
      dispatch_storage,
      entity_headers: storage.entity_headers,
      redirect_to_table,
      storage_utils,
      table,
      table_entity_id,
    })
  }, [
    dispatch_storage,
    redirect_to_table,
    storage.entity_headers,
    storage_utils,
    table,
    table_entity_id,
  ])

  const disabled =
    table?._zone_id == null ||
    !storage_utils.is_able_to_undo(table._zone_id) ||
    storage.history_mode

  return (
    <Tooltip title={'Undo (Ctrl+Z)'}>
      <span>
        <CustomButton type="tertiary" onClick={handle_click} disabled={disabled} onlyIcon>
          <i className="fas fa-arrow-left" />
        </CustomButton>
      </span>
    </Tooltip>
  )
}

export default UndoButton
