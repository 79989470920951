import React from 'react'
import _ from 'lodash'
import {style} from './style'

const isChrome = !!window.chrome

// Call a function f(i, flip) for x (i=0) and y (i=1) axes and return result as
// array.
//
// Function f is called with arguments:
//
// i: current axis
// flip: array => array: helper function to reverse array for y axis
//
function xy(f) {
  return [
    f(0, ([x, y]) => [x, y]),
    f(1, ([x, y]) => [y, x]),
  ]
}

function hasIntersect([a, b], [c, d]) {
  [a, b] = a < b ? [a, b] : [b, a];
  [c, d] = c < d ? [c, d] : [d, c]
  return (b > c && a <= c) || (d > a && c <= a)
}

function rect2range(x, y, width, height) {
  return [[x, x + width], [y, y + height]]
}

function range2rect([[x1, x2], [y1, y2]]) {
  return [x1, y1, x2 - x1, y2 - y1]
}

// Grow a rectangle by dx, dy around its center.
//
// Examples:
// growRect(1, 1, 10, 10, 1, 1) -> [0, 0, 12, 12]
// growRect(5, 5, -3, -3, 1, 1) -> [6, 6, -5, -5]
// growRect(3, 3, 2, 2, -0.5, -0.5) -> [3.5, 3.5, 1, 1]
//
function growRect(x, y, w, h, dx, dy) {
  const origin = [x, y], size = [w, h], delta = [dx, dy]
  return _.flatten(_.zip(...xy((i) => {
    const di = Math.sign(size[i]) * delta[i]
    return [origin[i] - di, size[i] + 2 * di]
  })))
}

// Get values of subgrid defined by mask from arrays.
//
// Let's say we want to know a size of a top-right subgrid [1, 0] (live x,
// frozen y).
//
// Information about sizes is stored in an array of a form:
// [0] -- frozen sizes [0: x, 1: y]
// [1] -- live sizes [0: x, 1: y]
//
// The size of a subgrid with live (1) x and frozen (0) y will be:
// x: size[1][0], y: size[0][1]
//
// and we can obtain it by calling subgrid([1, 0], [size])[0]
function subgrid(mask, arrays) {
  return arrays.map((a) => mask.map((v, i) => a[v][i]))
}

function withCtx(ctx, fn) {
  ctx.save()
  fn(ctx)
  ctx.restore()
}

function clear(ctx) {
  ctx.save()
  ctx.setTransform(1, 0, 0, 1, 0, 0)
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
  ctx.restore()
}

function clipRect(ctx, ...rect) {
  ctx.beginPath()
  ctx.rect(...rect)
  ctx.clip()
}

function strokeRect(ctx, x, y, width, height, left, top, right, bottom) {
  const points = [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
  ]

  const borders = [left, top, right, bottom]

  const line = (i) => borders[i] ? ctx.lineTo(...points[i]) : ctx.moveTo(...points[i])

  ctx.beginPath()
  ctx.moveTo(...points[3])
  for (let i = 0; i < 5; i++) {
    line(i % 4)
  }
  ctx.stroke()
}

function drawMark(ctx, x, y, width, height) {
  ctx.beginPath()
  ctx.moveTo(x, y)
  ctx.lineTo(x + width, y)
  ctx.lineTo(x, y + height)
  ctx.closePath()
  ctx.fill()
}

function drawIcons(ctx, defaults, icons, x, y, w, iconSize, align) {
  icons.forEach((icon, index) => {
    const i = {...defaults, ...icon}

    const ix = x + (align === 'right' ? w - iconSize*(index+1) : 0)

    ctx.fillStyle = i.backgroundColor
    ctx.fillRect(...growRect(ix, y, iconSize, iconSize, -0.5, -0.5))

    ctx.fillStyle = i.color
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = i.font
    ctx.fillText(i.icon, ix + iconSize / 2, y + iconSize / 2)

    ctx.textBaseline = 'alphabetic'
  })
}

// Render text into a cell with reasonable paddings around it.
//
// Someone may wonder why not to use ctx.textBaseline = 'top'.
// The reason is simple, it would behave the wrong way if we started mixing font
// faces or even sizes. We don't allow that for now, we might in the future.
function fillText(ctx, str, left, right, top, bottom) {
  const x = {
    right,
    end: right,
    start: left,
    left,
    center: (right + left) / 2,
  }[ctx.textAlign]

  ctx.fillText(str, x, bottom)
}

// Return [left, right] coordinates of the text to be printed.
function measureText(ctx, str, left, right) {
  const width = Math.ceil(ctx.measureText(str).width)
  return {
    right: [right - width, right],
    end: [right - width, right],
    start: [left, left + width],
    left: [left, left + width],
    center: [(right + left - width) / 2, (right + left + width) / 2],
  }[ctx.textAlign]
}

// Render a grid with frozen rows and columns.
//
// Having frozen rows and columns effectively splits the grid into four areas.
// We will mark them by the frozen status of their [x, y] coordinates:
// 0 - frozen
// 1 - live
//
// Schematically, the grid looks like this:
//
// [0, 0] | [1, 0]
// -------|-------
// [0, 1] | [1, 1]
//
export class GridCanvas extends React.PureComponent {
  state = {}

  mounted = false

  animationLoopId = null

  offset = [0, 0]
  lastMousePos = null

  lastRefreshTime = 0

  canvasRef = React.createRef()
  scrollerRef = React.createRef()
  scrolledRef = React.createRef()

  onScroll = (e) => {
    if (this.props.onScroll) this.props.onScroll(e)
    this.offset = [e.target.scrollLeft, e.target.scrollTop]
  }

  onMouseMove = (e) => {
    const {left, top} = this.canvas.getBoundingClientRect()
    this.lastMousePos = [e.pageX - left, e.pageY - top]
  }

  componentDidMount() {
    this.mounted = true
    this.canvas = this.canvasRef.current
    this.ctx = this.canvas.getContext('2d')
    this.refresh()
    document.fonts.ready.then(() => {
      if (this.mounted) {
        this.setState({fontsLoaded: true})
      }
    })
  }

  componentDidUpdate() {
    if (this.scrollerRef.current) {
      this.offset = [this.scrollerRef.current.scrollLeft, this.scrollerRef.current.scrollTop]
    }
  }

  componentWillUnmount() {
    this.mounted = false
    window.cancelAnimationFrame(this.animationLoopId)
  }

  style = () => _.merge(_.cloneDeep(style), this.props.style)

  refresh = () => {
    this.animationLoopId = window.requestAnimationFrame(this.refresh)

    // It seems, that requestAnimationFrame is quite greedy: if the invoked function takes too
    // long to run, js interpreter runs an 'uninterruptible' sequence of requestAnimationFrame
    // calls, blocking scheduled events (e.g. via setTimeout). This can lead to quite WTF behaviour.
    if (performance.now() - this.lastRefreshTime < this.props.minRefreshDelay) return

    const {color, font, cellHeight, width, txtPadding} = this.style()

    // ------------------------------------------------------------------------
    // Extract Scroller Properties
    // ------------------------------------------------------------------------
    const scroller = this.scrollerRef.current
    const scrollerSize = [scroller.clientWidth, scroller.clientHeight]
    const scrollbarSize = [
      scroller.offsetWidth - scroller.clientWidth,
      scroller.offsetHeight - scroller.clientHeight,
    ]

    // ------------------------------------------------------------------------
    // Canvas dimensionality
    //
    // How many rows, cols, and frozen rows, cols are we actually having.
    // ------------------------------------------------------------------------
    const dim = [this.props.cols, this.props.rows]
    const frozen = xy((i) => Math.min(dim[i], this.props.frozen[i]))

    // If there is a frozen row/col, we need to render a separate border for it
    // and frozen separator.
    const frozenSeparator = xy((i) => frozen[i] > 0 ? (width.grid + width.frozen) : 0)

    // ------------------------------------------------------------------------
    // Cell Measurement Functions
    //
    // Set of helpers to translate cell indexes to pixels and vice versa
    // ------------------------------------------------------------------------

    const {colWidths: _colWidths, size: canvasSize} = this.props
    const availableWidth = canvasSize[0] // HTML canvas width reduced by:
        - 2 * width.padding // canvas padding (for outlines out of a grid)
        - width.grid // last border for live part
        - frozenSeparator[0] // frozen separator
        - scrollbarSize[0] // scrollbars if any
    const colWidths = typeof _colWidths === 'function'
        ? _colWidths(availableWidth) : _colWidths

    // All the cell borders (px values) indexed from [1, N+1].
    // Borders position for ith cell are at
    // left: _xBorders[i]
    // right: _xBorders[i+1]
    //
    // Array is guarded with sentinel values -Infinity (at 0) and +Infinity (N+2).
    const _xBorders = [-Infinity, 0]
    for (const w of colWidths) _xBorders.push(_.last(_xBorders) + w)
    _xBorders.push(Infinity)

    // Get the dimensions of a single cell at index i
    // Equivalent to pxSize(i, i + 1, d)
    const cellDim = (i, d) => d === 1 ? cellHeight : colWidths[i]

    // Get the length of the range of cells in pixels
    const pxSize = (s, e, d) => d === 1 ?
        (e - s) * cellHeight :
        _xBorders[e + 1] - _xBorders[s + 1]

    // Get the cell at the px position
    // For ith cell with borders left < right, return
    // i       : px = left
    // i + 0.5 : px between left and right
    // i + 1   : px = right
    const px2Cell = (px, d) => {
      if (d === 1) return px / cellHeight

      const i = _.sortedIndex(_xBorders, px)
      if (_xBorders[i] === px) return i - 1 // _xBorders is indexed from 1
      else return i - 1.5 // (i - 1) - 0.5
    }

    // ------------------------------------------------------------------------
    // Basic Canvas Dimensions and Measures
    //
    // Some naming conventions:
    // pxSth (size, or position in pixels)
    // vrtSth (virtual, including out-of-screen cells)
    // vsbSth (visible, only on-screen cells are included)
    // sthRange: [a, b]: first element a, last element b-1
    //
    // ------------------------------------------------------------------------
    // The cell range corresponding to a subgrid.
    //
    // Example:
    // subgrid([0, 1], [vrtRange]) -> cell range in [0, 1] subgrid
    //
    const vrtRange = [
      _.zip([0, 0], frozen), // frozen ranges
      _.zip(frozen, dim), // live ranges
    ]

    // Icon size hardcoded to cell height to make square icons
    const iconSize = cellHeight

    // Size of a subgrid in px
    const vrtSize = xy((f) => xy((i) => pxSize(...vrtRange[f][i], i)))

    // Pixel origin corresponding of a subgrid.
    const pxOrigin = [
      [width.padding, width.padding],
      xy((i) => vrtSize[0][i] + width.padding + frozenSeparator[i]),
    ]

    // Pixel dimensions of a visible part of a subgrid excluding one of the
    // borders.
    const vsbSize = [
      vrtSize[0],
      xy((i) => Math.min(
          vrtSize[1][i],
          this.props.size[i] // HTML canvas size reduced by:
          - 2 * width.padding // canvas padding (for outlines out of a grid)
          - (pxOrigin[1][i] - pxOrigin[0][i]) // size of the frozen part
          - width.grid // last border, not included in any cell
          - scrollbarSize[i] // scrollbars, so no rows are covered under them
      )),
    ]

    // Visible size used by grid. This can be lower than available canvas size
    // if there are not enough rows or columns to fit all the available space.
    //
    // Note please that this *includes* both of the table borders and none of
    // the paddings.
    const vsbGridSize = xy((i) =>
        vsbSize[1][i] // size of the live part
        + (pxOrigin[1][i] - pxOrigin[0][i]) // size of the frozen part
        + width.grid // last border, not included in any cell
    )

    // ------------------------------------------------------------------------
    // Compute Scroller Properties
    // ------------------------------------------------------------------------

    // The size of the content to be scrolled. This is effectively the virtual
    // size of the grid + the bottom padding. Top padding is not included
    // because the scroller's top and left are aligned with grid border.
    const contentSize = xy((i) =>
        vrtSize[1][i] // size of the live part
        + (pxOrigin[1][i] - pxOrigin[0][i]) // size of the frozen part
    )

    // If the actual `scrolled` div (content) differs in size from what it
    // should be, resize it.
    const scrolled = this.scrolledRef.current
    const scrolledSize = [scrolled.clientWidth, scrolled.clientHeight]
    xy((i) => {
      if (scrolledSize[i] !== contentSize[i]) {
        scrolled.style[['width', 'height'][i]] = `${contentSize[i]}px`
      }
    })

    // Property scrollTo limits valid scroll offset in such a way that scrollTo
    // element is always visible.
    //
    // Modify this.offset[i] if it violates the constraint.
    const {scrollTo, offset} = this.props
    xy((i) => { // eslint-disable-line curly
      const oldOffset = this.offset[i]

      if (scrollTo) {
        // Pixel position of the element relative to live subgrid.
        const topLeftPx = pxSize(vrtRange[1][i][0], scrollTo[i], i)
        const bottomRightPx = topLeftPx + cellDim(scrollTo[i], i)

        if (topLeftPx < 0) return // because the element is in the frozen part

        this.offset[i] = _.clamp(
            this.offset[i],
            bottomRightPx - vsbSize[1][i],
            topLeftPx
        )
      }

      if (offset) {
        this.offset[i] = _.clamp(offset[i], 0, scrolledSize[i] - scrollerSize[i])
      }

      // Propagate the modified offset to the scroller element. Do this only if
      // the offset really changed so we don't trigger some unnecessary reflows.
      const scrollProp = ['scrollLeft', 'scrollTop'][i]
      if (oldOffset !== this.offset[i]) scroller[scrollProp] = this.offset[i]
    })

    // ------------------------------------------------------------------------
    // Setup DPR
    //
    // On hDPI monitors one css pixel can correspond to multiple physical
    // pixels. The ratio between physical and css pixels is provided in this
    // variable.
    //
    // Getting crisp lines in canvas on hDPI monitors involves few nasty things:
    //
    // 1. Create a canvas with width=dpr * cssWidth, height=dpr * cssHeight. That
    //    will ensure one canvas pixel corresponds to 1 device pixel.
    //
    // 2. Scale canvas to dpr (ctx.scale(dpr, dpr)). That will ensure that all
    //    canvas commands are measured in css pixels instead of device pixels.
    //
    // Now, it may seem that step 2. cancels out step 1. It is not so. I don't
    // really understand why, but scaling everything by 200% on Mac gets you some
    // nasty blurry shit instead of trivial quadruplication of every pixel.
    // ------------------------------------------------------------------------
    const dpr = window.devicePixelRatio

    // ------------------------------------------------------------------------
    // Compute positions and ranges depending on scroll offset
    // ------------------------------------------------------------------------

    // Offset in pixels relative to the cell [0, 0].
    //
    // It is defined in such a way that px position of top-left corner of cell c
    // can be computed as:
    // pxOrigin[f][i] - pxOffset[f][i] + pxSize(0, c[i], i)
    //
    // We modify the actual scroll offset by the size of frozen cells as those
    // are already "hidden behind the scroll" in a sense.
    //
    // The value is rounded to integer number of device pixels to avoid subpixel
    // rendering.
    //
    const pxOffset = [
      [0, 0],
      xy((i) =>
          Math.round(this.offset[i] * dpr) / dpr // scroll offset
          + pxSize(0, vrtRange[1][i][0], i) // size of frozen cells
      ),
    ]

    // Virtual origin
    //
    // It's the origin modified in such a way that the px position of top-left
    // corner of a cell c can be computed as:
    // pxVrtOrigin[f][i] + pxSize(0, c[i], i)
    //
    const pxVrtOrigin = xy((f) =>
        xy((i) => pxOrigin[f][i] - pxOffset[f][i])
    )

    // Visible range of cells of a subgrid. For frozen axis this is the same as
    // vrtRange as those are always fully visible.
    const vsbRange = [
      _.zip([0, 0], frozen),
      xy((i) => [
        // cell containing top left pixel
        Math.floor(px2Cell(pxOffset[1][i], i)),
        // cell containing bottom right pixel
        Math.ceil(px2Cell(pxOffset[1][i] + vsbSize[1][i], i)),
      ].map((n) => _.clamp(n, frozen[i], dim[i]))),
    ]

    // -------------------------------------------------------------------------
    // Conduct repaint if neccessary
    //
    // Extract all visible cells, and use them together with other properties to
    // determine whether the repaint is going to have any effect.
    // -------------------------------------------------------------------------
    const cells = xy((f1) => xy((f2) => {
      const result = []
      for (let j = vsbRange[f2][1][0]; j < vsbRange[f2][1][1]; j++) {
        const row = []
        for (let i = vsbRange[f1][0][0]; i < vsbRange[f1][0][1]; i++) {
          const [txt, style] = this.props.getCell(i, j)
          row.push([i, j, txt, style])
        }

        // Add information about empty cells to the left and to the right of the
        // current cell. This information is important when deciding whether to
        // allow cell contents to overflow.
        const setEmpty = (range) => {
          const isEmpty = ([txt, {left, right}]) => (txt === '' && !left && !right)
          let lastEmpty = null
          for (const k of range) {
            const [i, , ...cell] = row[k]
            row[k].push(lastEmpty)
            if (!isEmpty(cell)) lastEmpty = null
            else if (lastEmpty == null) lastEmpty = i
          }
        }

        setEmpty(_.range(row.length))
        setEmpty(_.rangeRight(row.length))

        result.push(...row)
      }
      return result
    }))

    const {selections, activeCell} = this.props
    const ctx = this.ctx

    const data = {
      colWidths, // cellDim and pxSize values depend on it
      canvasSize: this.props.size, // canvas gets cleared whenever resized
      fontsLoaded: this.state.fontsLoaded,
      vrtRange, pxOrigin, vsbSize, vsbGridSize,
      dpr,
      pxOffset, pxVrtOrigin, vsbRange,
      cells, selections, activeCell, ctx,
      color, font, width, txtPadding, iconSize,
    }

    if (!_.isEqual(this.cache, data)) this.paintCanvas(data, {cellDim, pxSize, px2Cell})
    this.cache = data

    if (this.props.onPaint) {
      let mousePos = null
      let cellArea = null
      const {lastMousePos: lmp} = this
      if (lmp != null) {
        const mask = xy((k) => (lmp[k] >= pxOrigin[1][k] ? 1 : 0))
        const [sorg, org, vSize] = subgrid(mask, [pxVrtOrigin, pxOrigin, vsbSize])
        if (_.every(xy((k) => lmp[k] >= org[k] && lmp[k] < org[k] + vSize[k]))) {
          mousePos = xy((k) => Math.trunc(px2Cell(lmp[k] - sorg[k], k)))
          cellArea = 'middle'

          const [, {left, right}] = this.props.getCell(...mousePos)
          if (left && lmp[0] < pxSize(0, mousePos[0], 0) + sorg[0] + iconSize) {
            cellArea = 'left'
          } else if (right && lmp[0] > pxSize(0, mousePos[0] + 1, 0) + sorg[0] - iconSize) {
            cellArea = 'right'
          }
        }
      }

      this.props.onPaint({
        mousePos,
        cellArea,
        cell(i, j) {
          const mask = xy((k) => ([i, j][k] >= vrtRange[0][k][1]) ? 1 : 0)
          const [org, off, vSize] = subgrid(mask, [pxVrtOrigin, pxOffset, vsbSize])
          const cellRange = rect2range(
              ...xy((k) => org[k] + pxSize(0, [i, j][k], k)),
              ...xy((k) => cellDim([i, j][k], k)),
          )

          const vsbRange = xy((i) => cellRange[i].map(
              (v) => _.clamp(v, org[i] + off[i], org[i] + off[i] + vSize[i]))
          )

          return {cellRange, vsbRange}
        },
      })
    }
    this.lastRefreshTime = performance.now()
  }

  paintCanvas = ({
                   vrtRange, pxOrigin, vsbSize, vsbGridSize,
                   dpr,
                   pxOffset, pxVrtOrigin, vsbRange,
                   cells, selections, activeCell, ctx,
                   color, font, width, txtPadding, iconSize,
                 }, {cellDim, pxSize, px2Cell}) => {
    ctx.setTransform(dpr, 0, 0, dpr, 0, 0)
    // ------------------------------------------------------------------------
    // Render Grid
    // ------------------------------------------------------------------------

    clear(ctx)
    // There is a fixed, always visible border around the whole table.
    ctx.strokeStyle = color.grid
    ctx.lineWidth = width.grid
    ctx.strokeRect(...growRect(...pxOrigin[0], ...vsbGridSize, -0.5, -0.5))

    // Render gridlines.
    ctx.strokeStyle = color.grid
    for (let f = 0; f < 2; f++) {
      xy((s, flip) => withCtx(ctx, () => {
        // Clipping is essential because of HDPI monitors. On these devices we
        // support sub-pixel scroll which may result in a partially clipped
        // gridline.
        clipRect(ctx,
            ...flip([pxOrigin[f][s], pxOrigin[0][1 - s]]),
            ...flip([vsbSize[f][s], vsbGridSize[1 - s]])
        )
        ctx.translate(...flip([pxVrtOrigin[f][s], pxOrigin[0][1 - s]]))
        ctx.beginPath()
        for (let i = vsbRange[f][s][0]; i <= vsbRange[f][s][1]; i++) {
          const pos = 0.5 + pxSize(0, i, s)
          ctx.moveTo(...flip([pos, 0]))
          ctx.lineTo(...flip([pos, vsbGridSize[1 - s]]))
        }
        ctx.stroke()
      }))
    }

    // Render frozen bars.
    const fRect = (s, flip) => [
      ...flip([pxOrigin[1][s] - width.frozen, pxOrigin[0][1 - s]]),
      ...flip([width.frozen, vsbGridSize[1 - s]]),
    ]

    xy((s, flip) => {
      if (pxOrigin[0][s] === pxOrigin[1][s]) return
      ctx.strokeRect(...growRect(...fRect(s, flip), ...flip([0.5, -0.5])))
    })

    ctx.fillStyle = color.frozen
    xy((s, flip) => {
      if (pxOrigin[0][s] === pxOrigin[1][s]) return
      ctx.fillRect(...fRect(s, flip))
    })

    ctx.strokeStyle = color.gridBorder
    ctx.lineWidth = width.gridBorder
    ctx.strokeRect(...growRect(0, 0, ...this.props.size, -width.padding, -width.padding))
    ctx.strokeStyle = color.grid

    // ------------------------------------------------------------------------
    // Render Cells & Overlays
    // ------------------------------------------------------------------------

    const selectionsRange = selections.map((s) => rect2range(...s))

    function renderCells(cells, org, vsbSize, off, range, vrtRange) {
      const subgridRect = [...off, ...vsbSize]

      const selectionsPX = selectionsRange.map((sr) => [
        ...xy((i) => pxSize(0, sr[i][0], i)),
        ...xy((i) => pxSize(...sr[i], i)),
      ])

      const activeCellPX = xy((i) => pxSize(0, activeCell[i], i))

      // Move origin to match gridlines. This simplifies drawing correct rects.
      withCtx(ctx, () => {
        ctx.translate(org[0] + 0.5, org[1] + 0.5)

        // Allow cell content only inside the subgrid excluding borders.
        //
        // Our ctx origin now matches the grid lines, which are drawn
        // through the half-pixels.
        //
        // When we grow the subgridRect by 0.5, it will include the borders.
        // When we shrink it, it will exclude them.
        withCtx(ctx, () => {
          clipRect(ctx, ...growRect(...subgridRect, -0.5, -0.5))

          for (const [i, j, , {backgroundColor = color.defaultBg}] of cells) {
            ctx.fillStyle = backgroundColor
            ctx.fillRect(...growRect(
                pxSize(0, i, 0),
                pxSize(0, j, 1),
                cellDim(i, 0), cellDim(j, 1), -0.5, -0.5))
          }

          for (const [i, j, , {left, right}] of cells) {
            const defIcon = {
              color: color.defaultText,
              backgroundColor: color.defaultBg,
              font: font.icon,
            }
            const [x, y] = [pxSize(0, i, 0), pxSize(0, j, 1)]
            const w = cellDim(i, 0)

            if (left) drawIcons(ctx, defIcon, left, x, y, w, iconSize, 'left')
            if (right) drawIcons(ctx, defIcon, right, x, y, w, iconSize, 'right')
          }

          for (const [i, j, txt, {color: textColor = color.defaultText,
            align: textAlign = 'start', ...rest}, le, re] of cells) {
            if (txt === '') continue
            rest['small-caps'] = rest.smallCaps
            const fontMod = ['italic', 'small-caps', 'bold']
                .filter((key) => rest[key] === true)
                .join(' ')

            const fontFamily = (rest.mono === true ? font.mono : font.normal)
            ctx.font = `${fontMod} ${fontFamily}`
            ctx.textAlign = textAlign

            // Most Left PX & Most Right PX possible
            const lpx = rest.left ? pxSize(0, i, 0) + rest.left.length*iconSize : pxSize(0, le || i, 0)
            const rpx = rest.right ? pxSize(0, i + 1, 0) - rest.right.length*iconSize : pxSize(0, (re || i) + 1, 0)

            // Cell boundaries (with txt padding)
            const lb = pxSize(0, i, 0) + (rest.left ? rest.left.length*iconSize : 0) + txtPadding
            const rb = pxSize(0, i + 1, 0) - (rest.right ? rest.right.length*iconSize : 0) - txtPadding

            // What do we actually need
            const [ln, rn] = measureText(ctx, txt, lb, rb)
            const left = Math.max(lpx, pxSize(0, Math.floor(px2Cell(ln)), 0))
            const right = Math.min(rpx, pxSize(0, Math.ceil(px2Cell(rn)), 0))
            const top = pxSize(0, j, 1)
            const bottom = pxSize(0, j + 1, 1)

            // And finally render the text, make sure we don't draw outside of
            // the empty columns.
            withCtx(ctx, () => {
              const rect = growRect(left, top, right - left, bottom - top, -0.5, -0.5)
              clipRect(ctx, ...rect)
              // Refill the background to get rid of cell-borders if overflowing.
              ctx.fillStyle = rest.backgroundColor || color.defaultBg
              ctx.fillRect(...rect)
              ctx.fillStyle = textColor
              fillText(ctx, txt, lb, rb, top, bottom - txtPadding)
            })
          }

          for (const [i, j, , {mark = null}] of cells) {
            if (mark == null) continue
            ctx.fillStyle = mark
            drawMark(
                ctx,
                ...growRect(pxSize(0, i + 1, 0), pxSize(0, j, 1), -width.mark, width.mark, -0.5, -0.5)
            )
          }
        })

        // Indexes of those selections that contain at least one cell of this
        // subgrid.
        const subgridSelectionsIdx = _.range(0, selectionsRange.length).filter(
            (i) => _.every([0, 1], (j) => hasIntersect(selectionsRange[i][j], vrtRange[j]))
        )

        // If there are none, we're done.
        if (subgridSelectionsIdx.length < 1) return

        // Selection border can't be drawn over the subgrid border.
        withCtx(ctx, () => {
          clipRect(ctx, ...growRect(...subgridRect, -0.5, -0.5))
          ctx.strokeStyle = color.selectionBorder
          for (const sidx of subgridSelectionsIdx) {
            ctx.strokeRect(...growRect(...selectionsPX[sidx], -1, -1))
          }
        })

        // But overlay can cover even the subgrid/table borders. It just looks
        // better that way.
        const maxOutlineRect = growRect(...subgridRect, 0.5, 0.5)
        withCtx(ctx, () => {
          clipRect(ctx, ...maxOutlineRect)

          // Except, we don't cover the inside of an active cell. This way it
          // stands out of the selection.
          ctx.beginPath()
          ctx.rect(...maxOutlineRect)
          ctx.rect(
              ...growRect(...activeCellPX, ...xy((i) => cellDim(activeCell[i], i)), -0.5, -0.5)
          )
          ctx.clip('evenodd')

          ctx.fillStyle = color.selectionOverlay
          for (const sidx of subgridSelectionsIdx) {
            ctx.fillRect(...growRect(...selectionsPX[sidx], -1.5, -1.5))
          }
        })

        // The outline is most tricky. Not only it can be drawn over the subgrid
        // borders, it can exceed them by 1px. Also, their logic is little bit
        // more complicated.
        //
        // Instead of clipping, we will do the drawing ourselves.
        withCtx(ctx, () => {
          const maxOutlineRange = rect2range(...maxOutlineRect)
          const vrtOutlineRanges = subgridSelectionsIdx.map((sidx) =>
              rect2range(...growRect(...selectionsPX[sidx], 0.5, 0.5))
          )

          // Instead of clipping the outline when the selected part is hidden,
          // we display an indicator on the border.
          const outlineRange = vrtOutlineRanges.map((vor) => xy((i) => vor[i].map(
              (v) => _.clamp(v, ...maxOutlineRange[i]))
          ))

          // The only case when we don't draw a border is when the selection
          // continues in the other subgrid.
          const drawBorders = subgridSelectionsIdx.map((sidx) => _.flatten(_.zip(...xy((i) => [
            vrtRange[i][0] <= selectionsRange[sidx][i][0],
            selectionsRange[sidx][i][1] <= vrtRange[i][1],
          ]))))

          ctx.lineWidth = width.outline
          ctx.strokeStyle = color.selectionOutline

          // To take a care of that correctly, we use our own version of
          // strokeRect.
          _.zip(outlineRange, drawBorders).forEach(([or, db]) =>
              strokeRect(ctx, ...range2rect(or), ...db))
        })
      })
    }

    const renderCorner = (mask) =>
        renderCells(
            cells[mask[0]][mask[1]],
            ...subgrid(mask, [pxVrtOrigin, vsbSize, pxOffset, vsbRange, vrtRange])
        )

    renderCorner([0, 0])
    renderCorner([1, 0])
    renderCorner([0, 1])
    renderCorner([1, 1])
  }

  render() {
    const [w, h] = this.props.size
    const dpr = window.devicePixelRatio
    const {width, color} = this.style()

    return (
        <div style={{position: 'relative'}} onMouseMove={this.onMouseMove}>
          <div ref={this.scrollerRef} onScroll={this.onScroll} style={{
            width: w - 2 * width.padding - width.grid,
            height: h - 2 * width.padding - width.grid,
            position: 'absolute',
            top: width.padding,
            left: width.padding,
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: color.gridBg,
          }}
          >
            <div ref={this.scrolledRef} />
          </div>
          <canvas
              ref={this.canvasRef}
              width={w * dpr}
              height={h * dpr}
              style={{
                width: w,
                height: h,
                display: 'block',
                pointerEvents: 'none',
                position: 'relative',
              }}
          />
        </div>
    )
  }
}

GridCanvas.defaultProps = {
  frozen: [1, 1],
  minRefreshDelay: 1,
}
