import React, {FC, useCallback} from 'react'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useTableUiSelector,
  useThunkDispatch,
} from '../utils/connect_hocs'
import {get_default_filter} from '../AdvancedFilter/filter_utils'
import {open_filter_dropdown} from '../Toolbar/toolbar_actions'
import ContextMenuItem from './ContextMenuItem'
import {Box, styled} from '@material-ui/core'

type AddFilterItemProps = {
  on_close: () => void
}

const StyledIconBox = styled(Box)(({theme}) => ({
  marginLeft: theme.spacing(0.5),
}))

const AddFilterItem: FC<AddFilterItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const dispatch = useThunkDispatch()

  const col_id = cursor?.col_id

  const on_click = useCallback(() => {
    if (col_id) {
      const col_type = table._cols[col_id].type
      const new_condition = get_default_filter(col_id, col_type)
      dispatch(open_filter_dropdown(table_entity_id, table, [new_condition]))
    } else {
      console.error('Invalid cursor')
    }
    on_close()
  }, [table_entity_id, table, col_id, on_close, dispatch])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={
        <StyledIconBox>
          <i className="fas fa-filter" />
        </StyledIconBox>
      }
      item="Add Filter"
    />
  )
}

export default AddFilterItem
