import React, {useCallback} from 'react'
import Add from '@material-ui/icons/Add'
import ContextMenuItem from '../../../ContextMenu/ContextMenuItem'

type AddTableItemProps = {
  on_close: () => void
  on_select: () => void
}

const AddTableItem: React.FC<AddTableItemProps> = ({on_close, on_select}) => {
  const handle_click = useCallback(() => {
    on_close()
    on_select()
  }, [on_close, on_select])

  return <ContextMenuItem onClick={handle_click} icon={<Add fontSize="small" />} item="Add table" />
}

export default AddTableItem
