import React, {FC, useCallback, useEffect} from 'react'
import {styled, Typography} from '@material-ui/core'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {focus_table_content} from '../utils/focus'
import Commit, {CommitEntry} from './Commit'
import _ from 'lodash'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList} from 'react-window'

type CommitData = {
  commit_id: string
  sequence: number
}
// newest (bigger sequence) on top
const sort_by_sequence = (a: CommitData, b: CommitData) => b.sequence - a.sequence

const Container = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}))

const Content = styled('div')(() => ({
  flex: '1 1 auto',
  overflowY: 'hidden',
}))

const HistorySidebar: FC = () => {
  const {
    storage,
    resources: {
      table_resources: {table_entity_id, zone_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {dispatch_storage} = useRuntimeActions()
  const {commits, versions, history_mode} = storage
  const current_commit_id = versions[zone_id]
  const commits_for_zone = commits[zone_id]
  const sorted_commits = commits_for_zone
    ? Object.values(commits_for_zone).sort(sort_by_sequence)
    : []

  const view_current_changes_callback = useCallback(() => {
    dispatch_storage({
      type: 'set-history-mode',
      history_mode: false,
    })
    dispatch_storage({
      type: 'set-latest-versions',
    })
    focus_table_content()
  }, [dispatch_storage])

  const view_commit_callback = useCallback(
    (commit) => {
      const {zone_id, commit_id} = commit
      if (!history_mode) {
        dispatch_storage({
          type: 'set-history-mode',
          history_mode: true,
        })
      }
      dispatch_storage({
        type: 'set-versions',
        versions: {[zone_id]: commit_id},
      })
      focus_table_content()
    },
    [dispatch_storage, history_mode]
  )

  // reset history-related state when component is unmounted
  useEffect(
    () => () => {
      dispatch_storage({
        type: 'set-history-mode',
        history_mode: false,
      })
      dispatch_storage({
        type: 'set-latest-versions',
      })
    },
    [dispatch_storage]
  )

  return (
    <Container id="history">
      <Content>
        <AutoSizer>
          {({width, height}) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={165}
              itemCount={
                _.has(storage.downloads.entity_headers, table_entity_id)
                  ? sorted_commits.length + 1
                  : 1
              }
            >
              {({index, style}) => (
                <div style={style}>
                  {index === 0 ? (
                    <CommitEntry
                      header={{
                        title: 'Current changes',
                      }}
                      content={
                        <Typography variant="caption" component="p">
                          Not committed changes
                        </Typography>
                      }
                      is_active={!history_mode}
                      view_commit={view_current_changes_callback}
                    />
                  ) : (
                    <>
                      {_.has(storage.downloads.entity_headers, table_entity_id) && (
                        <Commit
                          commit={sorted_commits[index - 1]}
                          key={sorted_commits[index - 1].commit_id}
                          is_active={
                            history_mode &&
                            current_commit_id === sorted_commits[index - 1].commit_id
                          }
                          on_view_commit={view_commit_callback}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Content>
    </Container>
  )
}

export default HistorySidebar
