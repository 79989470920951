import _ from 'lodash'
import {empty_storage_with_version} from 'common/storage'
import {create_runtime_context, CallMonitor} from 'common/create_runtime_context'
import {get_cell_getter, get_table_size} from '../table_data_helpers'
import {get_fetchers} from 'common/fetchers'
import hrtime from 'browser-process-hrtime' // uses process.hrtime if we are in node
import {id} from 'common/utils'
import {create_apply_helpers} from './apply_helpers'
/* eslint-enable @typescript-eslint/no-unused-vars */

declare const window: any

const init_call_monitoring = () => {
  let profiling_data = {}

  const async_call_monitor = (fn, name) => {
    const monitored: any = async (...args) => {
      const start = hrtime()
      const result = await fn(...args)
      const time_delta = hrtime(start)
      if (!_.has(profiling_data, name)) {
        profiling_data[name] = {time: 0, count: 0, sqr_time: 0}
      }
      const time_delta_millis = (time_delta[0] * 1e9 + time_delta[1]) / 1e6
      profiling_data[name].time += time_delta_millis
      profiling_data[name].sqr_time += time_delta_millis ** 2
      profiling_data[name].count++
      return result
    }
    return monitored
  }

  function process_profiling_data(n) {
    return _.mapValues(profiling_data, ({time, count, sqr_time}) => {
      const avg_time = time / count
      // https://en.wikipedia.org/wiki/Variance
      // https://stats.stackexchange.com/questions/168971/variance-of-an-average-of-random-variables
      const avg_std_dev = Math.sqrt((sqr_time / count - avg_time ** 2) / n)
      return {count_per_repetition: count / n, avg_std_dev, avg_time}
    })
  }

  const measure_n_times = async (n, fn) => {
    // let's heat up the machine
    for (let i = 0; i < n; i++) {
      await fn()
    }
    // reset the counters and measure on a JIT-optimized code
    profiling_data = {}
    for (let i = 0; i < n; i++) {
      await fn()
    }
    return {repetitions: n, ...process_profiling_data(n)}
  }

  return {async_call_monitor, measure_n_times}
}

const measure_multiple = <T extends Record<string, Function>>(
  functions: T,
  monitor: CallMonitor
): T => {
  return _.mapValues(functions, (fn, name) => monitor(fn, name)) as T
}

// in browser, we use relative path to avoid sending cross-origin request
const fetchers =
  typeof window !== 'undefined'
    ? get_fetchers()
    : get_fetchers(process.env.bee_host, process.env.bee_port)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const measure_load = async () => {
  const {async_call_monitor, measure_n_times} = init_call_monitoring()
  return await measure_n_times(20, async () => {
    const {run} = create_runtime_context(
      empty_storage_with_version(),
      measure_multiple(fetchers, async_call_monitor),
      false,
      '',
      async_call_monitor
    )

    // load table
    await async_call_monitor(
      () => run((runtime) => runtime.get_table(id('bear'))._load()),
      'load'
    )()
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const measure_get_cell = async () => {
  const {async_call_monitor, measure_n_times} = init_call_monitoring()
  const {run} = create_runtime_context(empty_storage_with_version(), fetchers)

  // load table
  const table = await run((runtime) => runtime.get_table(id('bear'))._load())
  // cell factory
  const get_cell = get_cell_getter(
    table,
    undefined,
    undefined,
    false,
    false,
    table._row_ids,
    table._row_ids,
    []
  )
  const {width, height} = get_table_size(table)
  return await measure_n_times(10, async () => {
    await async_call_monitor(() => {
      for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
          get_cell(x, y)
        }
      }
    }, 'get_cell_pass')()
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const measure_apply = async () => {
  const {async_call_monitor, measure_n_times} = init_call_monitoring()
  const helpers = await create_apply_helpers(fetchers)

  const value_change_diff = helpers.create_value_change_diff(1, 1)
  const remove_row_diff = helpers.create_remove_row_diff(1)
  const add_row_diff = helpers.create_add_row_diff(10)
  const add_column_diff = helpers.create_add_column_diff()
  const remove_column_diff = helpers.create_remove_column_diff()
  const reorder_column_diff = helpers.create_reorder_column_diff()
  const column_width_change_diff = helpers.create_column_width_change_diff()

  return await measure_n_times(10, async () => {
    await async_call_monitor(() => {
      helpers.run_apply(value_change_diff)
    }, 'value_change_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(remove_row_diff)
    }, 'remove_row_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(add_row_diff)
    }, 'add_row_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(add_column_diff)
    }, 'add_column_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(remove_column_diff)
    }, 'remove_column_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(reorder_column_diff)
    }, 'reorder_column_diff')()

    await async_call_monitor(() => {
      helpers.run_apply(column_width_change_diff)
    }, 'column_width_change_diff')()
  })
}

const perf = async () => {
  //return await measure_load()
  return await measure_get_cell()
  //return await measure_apply()
}

// when running from node.js directly, performance is measured and logged
if (typeof window === 'undefined') {
  ;(async () => {
    console.log(await perf())
  })()
}

export {perf as measure_performance}
