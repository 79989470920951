export type Coordinates = {top: number; left: number}

export const relative_mouse_coordinates = (e) => {
  const bounds = e.target.parentElement.getBoundingClientRect()
  const x = e.clientX - bounds.left
  const y = e.clientY - bounds.top
  return {x, y}
}

export const is_right_click = (e: React.MouseEvent<HTMLDivElement>) => e.button === 2
