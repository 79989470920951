import React, {FC} from 'react'
import {get_editor_type} from './editor_utils'
import EditorPositioner from './EditorPositioner'
import {CellEditor, CellEditorProps} from './CellEditor'
import {Dimensions, Rect_LR_TB} from '../utils/layout'

type TableCellEditorProps = Omit<CellEditorProps, 'in_detailed_view'> & {
  cell_position: Rect_LR_TB
  canvas_size: Dimensions
}

export const TableCellEditor: FC<TableCellEditorProps> = ({
  cell_position,
  canvas_size,
  ...props
}) => {
  const {table, col_id} = props
  const col_type = table._cols[col_id].type
  const editor_type = get_editor_type(col_type.type)

  return (
    <EditorPositioner
      canvas_size={canvas_size}
      cell_position={cell_position}
      editor_type={editor_type}
    >
      <CellEditor {...props} />
    </EditorPositioner>
  )
}
