import React, {FC, useCallback, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {Tabs, Theme, Box, styled} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {makeStyles} from '@material-ui/core/styles'
import {SortableContainer, SortableElement, SortEndHandler} from 'react-sortable-hoc'

import {open_modal} from '../Modals'
import {ROUTES} from '../utils/navigation_utils'
import {get_tables_in_project, consolidate_tables_order} from 'common/project_utils'
import {
  has_create_permissions,
  has_write_permissions,
  is_permission_table_subtype,
} from 'common/permission/permission_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {EntityHeader, TableEntityId} from 'common/types/storage'
import {TableTabItem, StyledTab} from './TableTabItem'
import Committer from './Committer'
import RefreshButton from './RefreshButton'
import {useRuntimeActions} from '../RuntimeContextProvider'
import project_actions from 'common/entities/project_actions'
import {move_in_array} from 'common/utils'
import Archiver from './Archiver'
import {is_entity_or_parent_archived} from 'common/archived_utils'

const SortableTabs = SortableContainer(Tabs)
const SortableTableTabItem = SortableElement(TableTabItem)

const CREATE_TABLE_TAB = 'create_new_table'

const useTabsStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 'min-content',
  },
  indicator: {
    transition: 'none', //Switch of the animation to prevent issues with scroll buttons appearing https://github.com/mui-org/material-ui/issues/19673
  },
}))

const TabsWrapper = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.greyPalette[100]}`,
  padding: theme.spacing(0, 3),
}))

const TableTabs: FC = () => {
  const {
    runtime,
    storage: {entity_headers, history_mode, multidiff, include_archived},
    error: runtime_error,
    resources: {
      table_resources: {table_entity_id, zone_id, table: selected_table},
      project_resources,
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime<'table_resources'>

  const {dispatch_storage} = useRuntimeActions()
  const dispatch = useDispatch()
  const history = useHistory()
  const [reordering, set_reordering] = useState(false)

  const parent_entity_id = entity_headers[table_entity_id].parent_id
  const base_table_entity_id =
    entity_headers[table_entity_id].type === 'view_table' ? parent_entity_id : table_entity_id

  const handle_create_table = useCallback(() => {
    dispatch(open_modal('new_table', {project_id: zone_id}))
  }, [dispatch, zone_id])

  const handle_select_table = useCallback(
    (_, base_table_entity_id: TableEntityId) => {
      if (base_table_entity_id === CREATE_TABLE_TAB) {
        handle_create_table()
      } else {
        history.push(ROUTES.table(base_table_entity_id))
      }
    },
    [handle_create_table, history]
  )

  const table_headers = useMemo(() => {
    return get_tables_in_project(entity_headers, zone_id, include_archived)
  }, [entity_headers, zone_id, include_archived])

  const consolidated_tables_order: ReadonlyArray<TableEntityId> = useMemo(() => {
    return consolidate_tables_order(table_headers, project_resources?.project.tables_order ?? [])
  }, [project_resources?.project.tables_order, table_headers])

  const on_sort_end: SortEndHandler = useCallback(
    ({oldIndex: old_index, newIndex: new_index}) => {
      if (project_resources && old_index !== new_index) {
        dispatch_storage(
          project_actions.change_project_tables_order(
            entity_headers[project_resources.project_id] as EntityHeader<'project'>,
            project_resources.project.tables_order,
            move_in_array(consolidated_tables_order, old_index, new_index)
          )
        )
      }
      set_reordering(false)
    },
    [project_resources, consolidated_tables_order, dispatch_storage, entity_headers]
  )

  const tabsStyles = useTabsStyles()

  const can_create_table =
    !is_permission_table_subtype(entity_headers[table_entity_id].subtype) &&
    !history_mode &&
    has_create_permissions(runtime, zone_id)

  return (
    <TabsWrapper display="flex" alignItems="center" justifyContent="space-between">
      <SortableTabs
        onChange={handle_select_table}
        value={!reordering && base_table_entity_id}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        classes={tabsStyles}
        /* sortable props: */
        axis="x"
        lockAxis="x" // github.com/clauderic/react-sortable-hoc/issues/655#issuecomment-696283593
        lockToContainerEdges
        distance={2}
        onSortStart={useCallback(() => set_reordering(true), [])}
        onSortEnd={on_sort_end}
      >
        {consolidated_tables_order.map((entity_id, i) => (
          <SortableTableTabItem
            history_mode={history_mode}
            selected={entity_id === table_entity_id}
            is_selected_invalid={runtime_error != null || selected_table == null}
            value={entity_id}
            name={table_headers[entity_id].name}
            description={table_headers[entity_id].description}
            archived={table_headers[entity_id].archived}
            type={table_headers[entity_id].type}
            subtype={table_headers[entity_id].subtype}
            has_write_permissions={has_write_permissions(runtime, entity_id)}
            key={entity_id}
            stop_pointer_events={reordering}
            /* sortable props: */
            index={i}
            disabled={project_resources == null}
            is_modified={!!(multidiff[zone_id] && multidiff[zone_id][entity_id])}
          />
        ))}

        {can_create_table && !is_entity_or_parent_archived(parent_entity_id, entity_headers) && (
          <StyledTab title="Add Table" value={CREATE_TABLE_TAB} label={<AddIcon />} />
        )}
      </SortableTabs>
      <Box display="flex" alignItems="center">
        <RefreshButton />
        {(zone_id != null || table_entity_id != null) && <Archiver entity_id={table_entity_id} />}
        {(zone_id != null || table_entity_id != null) && <Committer />}
      </Box>
    </TabsWrapper>
  )
}

export default TableTabs
