import React, {FC, useCallback, useMemo} from 'react'
import {get_column_selection} from '../table_data_helpers'
import {reset_ui_state} from '../Table'
import {focus_table_content} from '../utils/focus'
import {
  useSetTableUiState,
  useTableUiSelector,
  useRuntimeSelector,
  AlwaysDefinedRuntime,
} from '../utils/connect_hocs'
import {col_id_on_index} from '../utils/table_helpers'
import {useRuntimeActions} from '../RuntimeContextProvider'
import ContextMenuItem from './ContextMenuItem'

type HideColumnItemProps = {
  on_close: () => void
}

const HideColumnsItem: FC<HideColumnItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const selection_size = useTableUiSelector(table_entity_id, 'selection_size')

  const {dispatch_storage} = useRuntimeActions()
  const set_table_ui_state = useSetTableUiState(table_entity_id)

  const col_selection = useMemo(() => get_column_selection(table, cursor, selection_size), [
    table,
    cursor,
    selection_size,
  ])

  const contains_first_column = col_selection.includes(col_id_on_index(table, 0))

  const on_click = useCallback(() => {
    dispatch_storage(table._actions.change_cols_hidden_flag(col_selection, true))
    set_table_ui_state(reset_ui_state)
    focus_table_content()
    on_close()
  }, [dispatch_storage, table._actions, col_selection, set_table_ui_state, on_close])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={<i className="fas fa-eye-slash" />}
      item={`Hide column${col_selection.length > 1 ? 's' : ''}`}
      disabled={contains_first_column}
      borderTop
    />
  )
}

export default HideColumnsItem
