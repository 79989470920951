import React, {FC} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'

import {get_entity_to_table_name_mapping} from 'common/project_utils'
import {EntityHeaders, EntityHeader, EntityId} from 'common/types/storage'

type TableSelectorCommonProps = {
  /** table selector is populated with the table entities from entity_headers */
  entity_headers: EntityHeaders
  /** if we want to limit tables, which should be selectable by the table selector we can do it with
   * specifying this filter predicate */
  entity_headers_filter: (entity_header: EntityHeader) => boolean
  disabled?: boolean
  placeholder?: string
  autoFocus?: boolean //input textfield will be focused when rendered
  openOnFocus?: boolean //menu will pop up when focused
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onClose?: (event: object, reason: string) => void
  label?: string
  blurOnSelect?: boolean
  inputValue?: string
  size?: 'medium' | 'small'
  start_adornment?: JSX.Element
}
type TableSelectorSingleProps = TableSelectorCommonProps & {
  value: string | null
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}
type TableSelectorMultipleProps = TableSelectorCommonProps & {
  multiple: true
  value: string[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string[]) => void
}
export type TableSelectorProps = TableSelectorSingleProps | TableSelectorMultipleProps

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  inputRoot: {},
}))

const TableSelector: FC<TableSelectorProps> = (props) => {
  const {
    entity_headers,
    entity_headers_filter,
    placeholder,
    autoFocus,
    label,
    start_adornment,
    ...autocomplete_props
  } = props

  const entity_to_table_name_mapping = get_entity_to_table_name_mapping(
    entity_headers,
    entity_headers_filter
  )

  const table_label_getter = (entity_id: EntityId) => entity_to_table_name_mapping[entity_id] || ''

  // table options should be sorted because Select lists them in this order
  const table_options = Object.keys(entity_to_table_name_mapping).sort((id1, id2) => {
    const label1 = table_label_getter(id1)
    const label2 = table_label_getter(id2)
    return label1.localeCompare(label2)
  })
  const classes = useStyles(props)

  return (
    <Autocomplete<string, boolean>
      {...autocomplete_props}
      options={table_options}
      filterSelectedOptions
      getOptionLabel={table_label_getter}
      renderOption={(option) => {
        const path = table_label_getter(option)
        const last_slash_index = path.lastIndexOf('/')
        const [zone_path, name] = [
          path.slice(0, last_slash_index),
          path.slice(last_slash_index + 1),
        ]

        return (
          <div>
            <Typography variant="body1">{name}</Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {zone_path}
            </Typography>
          </div>
        )
      }}
      autoComplete
      autoHighlight
      classes={classes}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          name="tables"
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {start_adornment}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default TableSelector
