import React, {FC, useCallback} from 'react'
import {useTableUiSelector, useRuntimeSelector, AlwaysDefinedRuntime} from '../utils/connect_hocs'
import Link from '@material-ui/icons/Link'
import ContextMenuItem from './ContextMenuItem'

type GetRowLinkItemProps = {
  on_close: () => void
  show_message: (message: string) => void
  include_filters?: boolean
}

const GetRowLinkItem: FC<GetRowLinkItemProps> = ({on_close, show_message, include_filters}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const row_id = useTableUiSelector(table_entity_id, 'cursor')?.row_id

  const on_click = useCallback(() => {
    const row_url = new URL(window.location.href)
    row_id && row_url.searchParams.append('row_id', row_id)
    if (!include_filters) {
      row_url.searchParams.delete('filter')
      row_url.searchParams.delete('search')
    }
    if (navigator.clipboard == null) {
      show_message('Error: Cannot copy to clipboard without HTTPS!')
      return
    }
    navigator.clipboard.writeText(row_url.toString())
    show_message('Row link copied to clipboard!')
    on_close()
  }, [on_close, show_message, row_id, include_filters])

  const text = include_filters ? 'Get row link with filters' : 'Get row link'
  return <ContextMenuItem onClick={on_click} item={text} icon={<Link fontSize="small" />} />
}

export default GetRowLinkItem
