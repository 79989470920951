import React, {FC, useState} from 'react'
import _ from 'lodash'
import ContextMenu, {ContextMenuProps} from './ContextMenu'
import DetailedViewItem from './DetailedViewItem'
import RemoveSelectedRowsItem from './RemoveSelectedRowsItem'
import AddColumnItems from './AddColumnItems'
import AddRowItems from './AddRowItems'
import AddFilterItem from './AddFilterItem'
import RemoveSelectedColumnsItem from './RemoveSelectedColumnsItem'
import EditCellItem from './EditCellItem'
import EditOrViewColumnItem from './EditOrViewColumnItem'
import HideColumnsItem from './HideColumnsItem'
import GetRowLinkItem from './GetRowLinkItem'
import {useTableUiSelector, useRuntimeSelector, AlwaysDefinedRuntime} from '../utils/connect_hocs'
import {get_column_selection} from '../table_data_helpers'
import ValidateOptionsItem from './ValidateOptionsItem'
import Snackbar from '@material-ui/core/Snackbar'

type TableContextMenuProps = ContextMenuProps & {
  selection: [number, number, number, number] // [left_col, top_row, col_count, row_count]
}

const TableContextMenu: FC<TableContextMenuProps> = ({pos, selection, on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
    storage: {history_mode},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const selection_size = useTableUiSelector(table_entity_id, 'selection_size')
  const filter = useTableUiSelector(table_entity_id, 'filter')
  const search_text = useTableUiSelector(table_entity_id, 'search_text')

  const multi_col_selection = selection[2] > 1
  const multi_row_selection = selection[3] > 1
  const multi_selection = multi_col_selection || multi_row_selection
  const anything_selected = cursor !== null
  const header_selected = selection[1] === 0 && anything_selected
  const row_num_col_selected = cursor != null && cursor.col_id == null
  const cell_editable = cursor?.col_id != null && table._can_edit_cell(cursor!.col_id)

  const show_single_cell_items = !multi_selection && !header_selected

  let can_remove_columns = false
  if (anything_selected) {
    const col_selection = get_column_selection(table, cursor, selection_size)
    const all_columns_selected = table.col_count() === col_selection.length
    const all_columns_editable = _.every(
      col_selection,
      (col_id) => table._cols_edit_flags[col_id] === 'editable'
    )
    can_remove_columns = !all_columns_selected && all_columns_editable
  }

  const show_edit = !history_mode && show_single_cell_items && cell_editable
  const show_detailed_view = !multi_row_selection && !header_selected && anything_selected
  const show_add_column =
    !history_mode &&
    !multi_col_selection &&
    !row_num_col_selected &&
    (header_selected || !anything_selected) &&
    table._can_insert_column()
  const show_add_row =
    !history_mode && !header_selected && anything_selected && table._can_insert_row()
  const show_edit_column =
    anything_selected && !row_num_col_selected && !multi_col_selection && header_selected
  const show_remove_selected_column = !history_mode && can_remove_columns && header_selected
  const show_remove_selected_rows =
    !history_mode && anything_selected && !header_selected && table._can_edit_cells()
  const show_get_row_link =
    !history_mode && row_num_col_selected && !header_selected && !multi_row_selection
  const show_get_row_link_with_filters =
    show_get_row_link && (filter != null || search_text != null)
  const show_hide_column =
    !history_mode &&
    anything_selected &&
    header_selected &&
    !row_num_col_selected &&
    table.type === 'view_table'
  const show_add_filter =
    anything_selected &&
    !row_num_col_selected &&
    !multi_col_selection &&
    header_selected &&
    table.row_count() > 0
  const show_validate_options =
    selection[2] === 1 &&
    cursor != null &&
    cursor.col_id != null &&
    table._cols[cursor.col_id].type.type === 'option' &&
    table._cols_edit_flags[cursor.col_id] === 'editable'

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null)
  const show_message = (message: string) => setSnackbarMessage(message)

  return (
    <>
      <ContextMenu pos={pos} on_close={on_close}>
        {show_edit && <EditCellItem on_close={on_close} />}
        {show_detailed_view && <DetailedViewItem on_close={on_close} />}
        {show_add_column && (
          <AddColumnItems on_close={on_close} is_column_selected={anything_selected} />
        )}
        {show_edit_column && (
          <EditOrViewColumnItem
            on_close={on_close}
            view_only={history_mode || !table._can_edit_schema()}
          />
        )}
        {show_remove_selected_column && <RemoveSelectedColumnsItem on_close={on_close} />}
        {show_add_filter && <AddFilterItem on_close={on_close} />}
        {show_add_row && <AddRowItems on_close={on_close} />}
        {show_remove_selected_rows && <RemoveSelectedRowsItem on_close={on_close} />}
        {show_get_row_link && <GetRowLinkItem on_close={on_close} show_message={show_message} />}
        {show_get_row_link_with_filters && (
          <GetRowLinkItem on_close={on_close} show_message={show_message} include_filters />
        )}
        {show_hide_column && <HideColumnsItem on_close={on_close} />}
        {show_validate_options && <ValidateOptionsItem on_close={on_close} />}
      </ContextMenu>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarMessage != null}
        autoHideDuration={2000}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
    </>
  )
}

export default React.memo(TableContextMenu)
