import {ensure} from './utils'
import {get_storage_utils} from './storage_utils'

const RESOURCE_DOES_NOT_EXIST_MESSAGE =
  'Invariant violation. Resource does not exist in the storage.'
const RESOURCE_IS_NOT_COMPUTABLE_OR_CUSTOM_MESSAGE =
  'Invariant violation. Resource is not computable or custom.'
const RESOURCE_IS_NOT_EXTERNAL_MESSAGE = 'Invariant violation. Resource is not external.'
const RESOURCE_IS_NOT_IN_PROGRESS_MESSAGE = 'Invariant violation. Resource is not in-progress.'
const RESOURCE_ALREADY_EXISTS_MESSAGE =
  'Invariant violation. Resource already exists in the storage.'

const _ensure_resource_does_not_exist = (storage, resource_id) => {
  ensure(storage.resources[resource_id] == null, RESOURCE_ALREADY_EXISTS_MESSAGE, {resource_id})
}

const _ensure_resource_exist = (storage, resource_id) => {
  ensure(storage.resources[resource_id] != null, RESOURCE_DOES_NOT_EXIST_MESSAGE, {resource_id})
}

const _ensure_resource_is_in_progress = (storage, resource_id) => {
  const resource = storage.resources[resource_id]
  ensure(
    resource.status === 'in-progress' && !resource.result,
    RESOURCE_IS_NOT_IN_PROGRESS_MESSAGE,
    {resource_id, status: resource.status, result: resource.result}
  )
}

const _ensure_resource_is_computable_or_custom = (storage, resource_id) => {
  const {is_computable_or_custom_resource} = get_storage_utils(storage)
  ensure(
    is_computable_or_custom_resource(resource_id),
    RESOURCE_IS_NOT_COMPUTABLE_OR_CUSTOM_MESSAGE,
    {type: resource_id.type}
  )
}

const _ensure_resource_is_external = (storage, resource_id) => {
  const {is_external_resource} = get_storage_utils(storage)
  ensure(is_external_resource(resource_id), RESOURCE_IS_NOT_EXTERNAL_MESSAGE, {
    type: resource_id.type,
  })
}

const _ensure_computed_result_can_be_set = (storage, resource_id) => {
  _ensure_resource_exist(storage, resource_id)
  _ensure_resource_is_in_progress(storage, resource_id)
  _ensure_resource_is_computable_or_custom(storage, resource_id)
}

const _ensure_dependency_can_be_added = (storage, resource_id, dependency_id) => {
  _ensure_resource_exist(storage, resource_id)
  _ensure_resource_is_in_progress(storage, resource_id)
  _ensure_resource_is_computable_or_custom(storage, resource_id)
  _ensure_resource_exist(storage, dependency_id)
}

const _ensure_external_data_temporary_result_can_be_set = (storage, resource_id) => {
  _ensure_resource_is_external(storage, resource_id)
}

const _ensure_external_data_final_result_can_be_set = (storage, resource_id) => {
  _ensure_resource_exist(storage, resource_id)
  _ensure_resource_is_external(storage, resource_id)
}

export {
  _ensure_resource_does_not_exist,
  _ensure_computed_result_can_be_set,
  _ensure_dependency_can_be_added,
  _ensure_external_data_temporary_result_can_be_set,
  _ensure_external_data_final_result_can_be_set,
}
