import {is_error, format_error, MaybeError} from 'common/error'
import {compose_reference_id} from 'common/params_utils'
import _ from 'lodash'
import {SelectOptionId, SelectOption} from './SelectEditor'
import {
  CellRawValue,
  CellType,
  ColumnId,
  ColumnType,
  RowId,
  TableColumnReference,
} from 'common/types/storage'
import {Runtime} from 'common/create_runtime'
import {TableObject} from 'common/objects/data_table'
import {conform_single_value_to_type} from 'common/types'
import {single_value_to_string} from 'common/value_to_string'

// Can be passed to a text editor
const raw_value_to_string = (value: CellRawValue): string => {
  if (value === null) {
    return ''
  } else if (Array.isArray(value)) {
    return String(value[0]) || ''
  } else {
    return value.toString()
  }
}

// Can be passed to a single-option select editor
const raw_value_to_single_id = (value: CellRawValue): SelectOptionId | null => {
  return raw_value_to_string(value) || null
}

// Can be passed to a multi-option select editor
const raw_value_to_multiple_ids = (value: CellRawValue): SelectOptionId[] => {
  if (value === null || value === '') {
    return []
  } else if (Array.isArray(value)) {
    return value.map((it) => String(it))
  } else {
    return [value.toString()]
  }
}

// Get a list of all valid references to given table
const get_references = (
  runtime: Runtime,
  table_reference: TableColumnReference<'entity'>
): SelectOption[] => {
  const entity_id = table_reference.table_id
  const table = runtime.get_table_or_error(entity_id)
  // table can be error in case of unknown-entity
  if (is_error(table)) return []

  // label can be error in case of cyclic dependency
  const label_resource = table._get_label(table_reference.column_id ?? undefined)

  if (is_error(label_resource)) {
    return table._row_ids.map((row_id) => ({
      id: compose_reference_id({entity_id, row_id}),
      label: `${row_id}: ${format_error(label_resource)}`,
    }))
  }

  return Object.entries(label_resource).map(([row_id, label]) => ({
    id: compose_reference_id({entity_id, row_id}),
    label: label || 'null',
  }))
}

// Get a list of all valid options allowed by given column type
function get_available_options(
  col_type: ColumnType<CellType, 'entity'>,
  runtime: Runtime
): SelectOption[] {
  switch (col_type.type) {
    case 'reference':
      return Object.values(col_type.tables)
        .map((table_reference) => get_references(runtime, table_reference))
        .flat()
    case 'option':
      return Object.entries(col_type.options).map(([id, {name: label}]) => ({id, label}))
    default:
      return []
  }
}

// Get a list of all valid options actually present in a given column
function get_selected_options(table: TableObject, col_id: ColumnId): SelectOption[] {
  return Object.entries(table._get_unique_values(col_id)).map(([id, label]) => ({
    id,
    label: String(label),
  }))
}

// Add additional options present in select editor but not in column
function with_additional_options(
  column_options: SelectOption[],
  raw_value: MaybeError<CellRawValue>,
  col_type: ColumnType<CellType, 'entity'>,
  runtime: Runtime
): SelectOption[] {
  if (raw_value === null || is_error(raw_value)) {
    return column_options
  }

  const existing_option_ids = new Set(column_options.map(({id}) => id))
  const additional_option_ids = raw_value_to_multiple_ids(raw_value).filter(
    (option_id) => !existing_option_ids.has(option_id)
  )

  const additional_options: SelectOption[] = additional_option_ids.map((option_id) => {
    const value = conform_single_value_to_type(runtime, option_id, col_type)
    if (is_error(value)) {
      return {id: option_id, label: `${format_error(value)} ${option_id}`}
    } else {
      const label = single_value_to_string(value, col_type) || 'null'
      return {id: option_id, label: String(label)}
    }
  })

  return column_options.concat(additional_options)
}

const set_cell_value = (
  table: TableObject,
  col_id: ColumnId,
  row_id: RowId,
  user_email: string | undefined,
  dispatch: (action: any) => void
) => (new_value: CellRawValue, new_options: SelectOption[] = []) => {
  const to_dispatch = _.merge(
    {},
    ...new_options.map((new_option) => {
      return table._actions.add_option_to_col_spec(col_id, new_option.id, String(new_option.label))
    }),
    table._actions.edit_cell(row_id, col_id, new_value, user_email)
  )
  dispatch(to_dispatch)
}

const get_editor_type = (col_type: CellType) => {
  switch (col_type) {
    case 'reference':
    case 'option': {
      return 'select'
    }
    case 'markdown': {
      return 'markdown'
    }
    case 'string':
    case 'number': {
      return 'string'
    }
    default:
      return col_type
  }
}

export {
  raw_value_to_string,
  raw_value_to_single_id,
  raw_value_to_multiple_ids,
  get_editor_type,
  get_available_options,
  get_selected_options,
  with_additional_options,
  set_cell_value,
}
