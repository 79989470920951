import {makeStyles} from '@material-ui/core/styles'

export const useInputStyles = makeStyles((theme) => ({
  styles: {
    'height': 30,
    'color': theme.palette.greyPalette[400],
    'fontSize': theme.typography.body2.fontSize,
    '& > input': {
      height: '30px !important',
    },
    '& > fieldset': {
      borderColor: theme.palette.greyPalette[100],
      borderRadius: 4,
    },
    '&:hover': {
      '& > fieldset': {
        borderColor: `${theme.palette.greyPalette[400]} !important`,
      },
    },
  },
}))

export const usePaperStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 4,
    overflow: 'visible',
  },
}))
