import React, {useCallback, ReactNode} from 'react'
import {Typography} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import HistoryCard from '../components/HistoryCard'

const formatDatetime = (time) => new Date(time).toLocaleString()

type CommitEntryProps = {
  header: {
    number?: number
    title: string
  }
  content?: ReactNode
  is_active: boolean
  view_commit: () => void
}

const StyledText = styled('span')(({theme}) => ({
  color: theme.palette.greyPalette[400],
  marginRight: theme.spacing(0.5),
}))

const StyledPreposition = styled('span')(({theme}) => ({
  color: theme.palette.greyPalette[400],
  marginLeft: theme.spacing(0.25),
}))

export const CommitEntry: React.FC<CommitEntryProps> = (props) => {
  const {header, content, is_active, view_commit} = props

  return (
    <HistoryCard title={header.title} content={content} active={is_active} onClick={view_commit} />
  )
}

type CommitProps = {
  commit
  is_active

  on_view_commit: (commit) => void
}

const Commit: React.FC<CommitProps> = ({commit, is_active, on_view_commit}) => {
  const view_commit = useCallback(() => on_view_commit(commit), [commit, on_view_commit])
  return (
    <CommitEntry
      header={{
        number: commit.sequence + 1, // commit sequence is zero-based
        title: commit.commit_id,
      }}
      content={
        <>
          <Typography variant="caption" component="p">
            <StyledText>Created by</StyledText>
            {commit.author}
          </Typography>
          <Typography variant="caption" component="p">
            {formatDatetime(commit.authored_time).split(',')[0]}
            <StyledPreposition>at</StyledPreposition>
            {formatDatetime(commit.authored_time).split(',')[1]}
          </Typography>
        </>
      }
      is_active={is_active}
      view_commit={view_commit}
    />
  )
}

export default Commit
