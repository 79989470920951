import {DependencyList, useCallback, useEffect, useState} from 'react'

/**
 * A `useCallback` that ensures that `callback` will not be called again if it's already running.
 * The new callback will return `null` if called again before the last call finishes.
 * @returns [new_callback (may return null or promise), in_progress (boolean)]
 */
export function useBlockingCallback<P extends any[], T>(
  callback: (...args: P) => Promise<T>,
  deps: DependencyList
): [(...args: P) => Promise<T> | null, boolean] {
  const [in_progress, set_in_progress] = useState(false)

  const new_callback = useCallback(
    (...args: P) => {
      if (in_progress) return null
      set_in_progress(true)
      const promise = callback(...args)
      promise.finally(() => set_in_progress(false))
      return promise
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [in_progress, ...deps]
  )

  return [new_callback, in_progress]
}

/**
 * Tracks dragging even outside of the browser window by listening on the window object.
 *
 * _Usage_: Call the returned function when dragging should start.
 */
export function useGlobalDragging(
  on_mouse_move: (e: MouseEvent) => void,
  on_mouse_up: (e: MouseEvent) => void
) {
  const [dragging, set_dragging] = useState(false)

  const on_mouse_up_and_end = useCallback(
    (e: MouseEvent) => {
      set_dragging(false)
      on_mouse_up(e)
    },
    [on_mouse_up]
  )

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', on_mouse_move)
      window.addEventListener('mouseup', on_mouse_up_and_end)
    }
    return () => {
      window.removeEventListener('mousemove', on_mouse_move)
      window.removeEventListener('mouseup', on_mouse_up_and_end)
    }
  })

  return useCallback(() => set_dragging(true), [])
}
