import {EntityDiff} from 'common/types/storage'

function is_new_entity(diff: EntityDiff): boolean {
  return !!diff.entity_id
}

function is_new_view_table(diff: EntityDiff): boolean {
  return !!diff.entity_id && diff.type === 'view_table'
}

function is_new_project(diff: EntityDiff): diff is EntityDiff<'project'> {
  return is_new_entity(diff) && diff.type === 'project'
}

export {is_new_entity, is_new_project, is_new_view_table}
