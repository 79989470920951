/**
 * Table actions
 * @namespace TableActions
 */

import {get_cell_diffs, get_schema_diffs} from 'common/entities/table_diffs'
import _ from 'lodash'
import ih from 'immutability-helper'
import data_table_diff from 'common/diffs/models/data_table_diff'
import {ensure, mod, throw_error} from 'common/utils'
import value_diff, {ValueDiff} from 'common/diffs/base/value_diff'
import view_table_diff from 'common/diffs/models/view_table_diff'
import {create_diff, entity_type_diff} from 'common/diffs/models/entity_diff'
import row_diff from 'common/diffs/models/row_diff'
import {force_type} from 'common/types'
import {
  CellRawValue,
  Cells,
  CellType,
  ColumnId,
  ColumnSpec,
  ComputedTable,
  DataTable,
  EntityDiff,
  EntityHeader,
  OptionId,
  ProjectId,
  RowId,
  SortField,
  TableColumnReference,
  TableEntityId,
  TableSubtype,
  TableType,
  ViewTable,
} from 'common/types/storage'
import {TableCols, TableData} from '../objects/data_table'
import {get_new_cols_order} from './table_utils'
import {Conflict} from '../types/data_table'
import {_add_diff, AddDiffAction, entity_diff_action} from 'common/entities/actions'
import {EntityEditableSchema} from 'common/entities/entity_utils'
import {create_computed_table_entity} from 'common/entities/computed_table'
import {create_data_table_entity} from 'common/entities/data_table'
import {create_view_table_entity} from 'common/entities/view_table'
import {Runtime} from 'common/create_runtime'
import {is_error} from 'common/error'
import {ComputedParamsSpec} from 'common/params_utils'

export type ReferenceLabel = string
export type ReferenceLabelsMap = Record<RowId, ReferenceLabel>
export type TablesReferenceLabelsMap = Record<TableEntityId, Record<ColumnId, ReferenceLabelsMap>>

const create_cell_actions = (
  data: TableData,
  cols: TableCols,
  cols_order_without_hidden_cols: ColumnId[],
  runtime: Runtime
) => {
  const {cells} = data
  const {
    diff_for_cells,
    diff_remove_rows,
    diff_for_cells_autoresolve,
    update_default_columns_in_diff,
  } = get_cell_diffs(data)

  const diff_for_cells_with_default_cols = (
    cells: Cells<'diff'>,
    user_email: string | undefined
  ): DataTable<'diff'> => update_default_columns_in_diff(diff_for_cells(cells), user_email)

  /**
   * Add row action
   * @memberOf TableActions
   * @param new_row_id {RowId}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const add_row = (new_row_id: RowId, user_email: string | undefined): DataTable<'diff'> => {
    const cells = {[new_row_id]: row_diff.create_diff({keep: true})}
    return diff_for_cells_with_default_cols(cells, user_email)
  }

  /**
   * Add rows action
   * @memberOf TableActions
   * @param new_row_ids {RowId[]}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const add_rows = (new_row_ids: RowId[], user_email: string | undefined): DataTable<'diff'> => {
    const cells = Object.fromEntries(
      new_row_ids.map((row_id) => [row_id, row_diff.create_diff({keep: true})])
    )
    return diff_for_cells_with_default_cols(cells, user_email)
  }

  /**
   * Remove rows action
   * @memberOf TableActions
   * @param removed_row_ids {RowId[]}
   * @return {DataTable<'diff'>}
   */
  const remove_rows = (removed_row_ids: RowId[]): DataTable<'diff'> => {
    return diff_remove_rows(removed_row_ids)
  }

  /**
   * Cell change
   * @memberOf TableActions
   * @function
   * @param col_spec {ColumnSpec<CellType, 'entity'>}
   * @param old_value {CellRawValue}
   * @param new_value {CellRawValue}
   * @param [reference_labels] {TablesReferenceLabelsMap}
   * @return {ValueDiff<CellRawValue>}
   */
  const _cell_change = (
    col_spec: ColumnSpec<CellType, 'entity'>,
    old_value: CellRawValue,
    new_value: CellRawValue,
    reference_labels?: TablesReferenceLabelsMap
  ): ValueDiff<CellRawValue> => {
    const changed_value = force_type(col_spec.type, new_value, reference_labels)
    return value_diff<CellRawValue>().change_diff(old_value, changed_value)
  }

  /**
   * Get a record of all labels and theirs row_ids of valid references of given table
   * @memberOf TableActions
   * @function
   * @param runtime {Runtime}
   * @param tableId {TableEntityId}
   * @param table_reference {TableColumnReference<'entity'>}
   * @return {ReferenceLabelsMap}
   */
  const get_reference_labels_for_table = (
    runtime: Runtime,
    table_reference: TableColumnReference<'entity'>
  ): ReferenceLabelsMap => {
    const table = runtime.get_table_or_error(table_reference.table_id)
    // table can be error in case of unknown-entity
    if (!is_error(table)) {
      // label can be error in case of cyclic dependency
      const label_resource = table._get_label(table_reference.column_id ?? undefined, false)
      if (!is_error(label_resource)) {
        return label_resource
      }
    }
    return {}
  }

  const update_references_map = (
    references_map: TablesReferenceLabelsMap,
    col_spec: ColumnSpec<'reference', 'entity'>
  ) => {
    Object.values(col_spec.type.tables).forEach((table_reference) => {
      const table_id = table_reference.table_id
      const column_id = table_reference.column_id ?? 'default'
      if (references_map[table_id] == null) {
        references_map[table_id] = {}
      }
      if (references_map[table_id][column_id] == null) {
        references_map[table_id][column_id] = get_reference_labels_for_table(
          runtime,
          table_reference
        )
      }
    })
  }

  /**
   * Edit cell action
   * @memberOf TableActions
   * @function
   * @param row_id {RowId}
   * @param col_id {ColumnId}
   * @param new_value {CellRawValue}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const edit_cell = (
    row_id: RowId,
    col_id: ColumnId,
    new_value: CellRawValue,
    user_email: string | undefined
  ): DataTable<'diff'> => {
    const col_spec = cols[col_id]
    const {slots, data} = cells
    const old_value = data[row_id][slots[col_id]]
    const references_map: TablesReferenceLabelsMap = {}
    if (col_spec.type.type === 'reference' && new_value) {
      update_references_map(references_map, col_spec as ColumnSpec<'reference', 'entity'>)
    }
    const changed_cells = {
      [row_id]: {[col_id]: _cell_change(col_spec, old_value, new_value, references_map)},
    }
    return diff_for_cells_with_default_cols(changed_cells, user_email)
  }

  /**
   * Edit cells action
   * @memberOf TableActions
   * @function
   * @param top_left {{number, number}}
   * @param range {{number, number}}
   * @param data {CellRawValue[][]}
   * @param rows_order {RowId[]}
   * @param can_edit_cell {boolean}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const edit_cells = (
    top_left: [number, number],
    range: [number, number],
    data: CellRawValue[][],
    rows_order: RowId[],
    can_edit_cell: (col_id: ColumnId) => boolean,
    user_email: string | undefined
  ): DataTable<'diff'> => {
    const changed_cells = {} as Cells<'diff'>

    /*If range is larger than the full size of the table the create_cell table action is used on,
    clamp the overflowing rows and/or columns to the size of the table*/
    const clamped_row = Math.min(rows_order.length - top_left[1], range[1])
    const clamped_column = Math.min(cols_order_without_hidden_cols.length - top_left[0], range[0])

    const {slots, data: curr_data} = cells

    const references_map: TablesReferenceLabelsMap = {}

    for (let r = 0; r < clamped_row; r++) {
      const row_index = top_left[1] + r

      // skip header row
      if (row_index < 0) continue

      const row_id = rows_order[row_index]
      const row_data = {}
      for (let c = 0; c < clamped_column; c++) {
        const col_index = top_left[0] + c

        // skip row numbering column
        if (col_index < 0) continue

        const col_id = cols_order_without_hidden_cols[col_index]
        const col_spec = cols[col_id]

        // skip uneditable cells
        if (!can_edit_cell(col_id)) continue

        const old_value = _.get(curr_data, [row_id, slots[col_id]], null)
        const new_value = data[r % data.length][c % data[0].length]

        if (col_spec.type.type === 'reference' && new_value) {
          update_references_map(references_map, col_spec as ColumnSpec<'reference', 'entity'>)
        }

        const change = _cell_change(col_spec, old_value, new_value, references_map)

        if (change && !_.isEmpty(change)) {
          row_data[col_id] = change
        }
      }
      if (row_data && !_.isEmpty(row_data)) {
        changed_cells[row_id] = row_data
      }
    }

    // clean neutral diff to remove diffs of unchanged cells
    return update_default_columns_in_diff(
      data_table_diff.clean_neutral_diffs(diff_for_cells(changed_cells)),
      user_email
    )
  }

  /**
   * Paste data
   * @memberOf TableActions
   * @function
   * @param top_left {{number, number}}
   * @param paste_size {{number, number}}
   * @param copied_data {CellRawValue[][]}
   * @param rows_order {RowId[]}
   * @param new_row_ids {string[]}
   * @param can_edit_cell {boolean}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const paste_data = (
    top_left: [number, number],
    paste_size: [number, number],
    copied_data: CellRawValue[][],
    rows_order: RowId[],
    new_row_ids: string[],
    can_edit_cell: (col_id: ColumnId) => boolean,
    user_email: string | undefined
  ): DataTable<'diff'> => {
    //Add new rows, if needed
    const added_row_diffs = add_rows(new_row_ids, user_email)
    const new_rows_order = [...rows_order, ...new_row_ids]

    return update_default_columns_in_diff(
      data_table_diff.squash(
        added_row_diffs,
        edit_cells(top_left, paste_size, copied_data, new_rows_order, can_edit_cell, user_email)
      ),
      user_email
    )
  }

  /**
   * reference labels matching is not used because it is not needed for write API, currently
   * @memberOf TableActions
   * @function
   * @param data {Record<RowId, Record<ColumnId, CellRawValue>>}
   * @param can_edit_cell {boolean}
   * @param user_email {string | undefined}
   * @param transform_references { boolean }
   * @return {DataTable<'diff'>}
   */
  const edit_scattered_cells = (
    data: Record<RowId, Record<ColumnId, CellRawValue>>,
    can_edit_cell: (col_id: ColumnId) => boolean,
    user_email: string | undefined,
    transform_references: boolean = false
  ): DataTable<'diff'> => {
    const changed_cells = {} as Cells<'diff'>

    const {slots, data: curr_data} = cells

    const references_map: TablesReferenceLabelsMap = {}
    for (const row_id in data) {
      const row_data = {}
      for (const col_id in data[row_id]) {
        const col_spec = cols[col_id]
        // skip non-existent columns
        if (col_spec == null) continue
        // skip uneditable cells
        if (!can_edit_cell(col_id)) continue

        const old_value = _.get(curr_data, [row_id, slots[col_id]], null)
        const new_value = data[row_id][col_id]

        if (transform_references && col_spec.type.type === 'reference' && new_value) {
          update_references_map(references_map, col_spec as ColumnSpec<'reference', 'entity'>)
        }

        const change = _cell_change(
          col_spec,
          old_value,
          new_value,
          transform_references ? references_map : undefined
        )

        if (change && !_.isEmpty(change)) {
          row_data[col_id] = change
        }
      }
      if (row_data && !_.isEmpty(row_data)) {
        changed_cells[row_id] = row_data
      }
    }

    // clean neutral diff to remove diffs of unchanged cells
    return update_default_columns_in_diff(
      data_table_diff.clean_neutral_diffs(diff_for_cells(changed_cells)),
      user_email
    )
  }

  /**
   * Paste scattered data
   * @memberOf TableActions
   * @function
   * @param data {Record<RowId, Record<ColumnId, CellRawValue>>}
   * @param new_row_ids {string[]}
   * @param can_edit_cell {boolean}
   * @param user_email {string | undefined}
   * @param transform_references { boolean }
   * @return {DataTable<'diff'>}
   */
  const paste_scattered_data = (
    data: Record<RowId, Record<ColumnId, CellRawValue>>,
    new_row_ids: string[],
    can_edit_cell: (col_id: ColumnId) => boolean,
    user_email: string | undefined,
    transform_references: boolean = false
  ): DataTable<'diff'> => {
    const added_row_diffs = add_rows(new_row_ids, user_email)

    return update_default_columns_in_diff(
      data_table_diff.squash(
        added_row_diffs,
        edit_scattered_cells(data, can_edit_cell, user_email, transform_references)
      ),
      user_email
    )
  }

  /**
   * Validate options in column
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param user_email {string | undefined}
   * @param [new_col_spec] {ColumnSpec<CellType, 'entity'>}
   * @return {DataTable<'diff'>}
   */
  const validate_options_in_column = (
    col_id: ColumnId,
    user_email: string | undefined,
    // new_col_spec is used for situations when we want to validate values for the new changed
    // column specification, but the diff hasn't been applied on the table yet.
    new_col_spec?: ColumnSpec<CellType, 'entity'>
  ): DataTable<'diff'> => {
    const {slots, data} = cells
    const col_idx = slots[col_id]

    const diff = _.fromPairs(
      Object.keys(data).map((row_id) => {
        // matching against option names is done in force_type inside _cell_change,
        // that's why it is enough to call _cell_change with the same value as there was before
        const diff = {
          [col_id]: _cell_change(
            // if new_col_spec is defined, we want to validate against that
            new_col_spec || cols[col_id],
            data[row_id][col_idx],
            data[row_id][col_idx]
          ),
        }
        return [row_id, diff]
      })
    )
    return update_default_columns_in_diff(
      data_table_diff.clean_neutral_diffs(diff_for_cells(diff)),
      user_email
    )
  }

  /**
   * Clear cells
   * @memberOf TableActions
   * @function
   * @param top_left {{number, number}}
   * @param range {{number, number}}
   * @param rows_order {RowId[]}
   * @param can_edit_cell {boolean}
   * @param user_email {string | undefined}
   * @return {DataTable<'diff'>}
   */
  const clear_cells = (
    top_left: [number, number],
    range: [number, number],
    rows_order: RowId[],
    can_edit_cell: (col_id: ColumnId) => boolean,
    user_email: string | undefined
  ): DataTable<'diff'> =>
    edit_cells(top_left, range, [[null]], rows_order, can_edit_cell, user_email)

  const autoresolve_cells_conflicts = (user_email: string | undefined): DataTable<'diff'> => {
    return update_default_columns_in_diff(diff_for_cells_autoresolve(), user_email)
  }

  return {
    add_row,
    add_rows,
    remove_rows,
    paste_data,
    paste_scattered_data,
    edit_cell,
    edit_cells,
    clear_cells,
    validate_options_in_column,
    autoresolve_cells_conflicts,
  }
}

const create_schema_actions = (
  data: TableData,
  invalids: Conflict[],
  cols: TableCols,
  cols_order_without_hidden_cols: ColumnId[]
) => {
  const {type, cols_order, view_entity} = data
  const table_diff = entity_type_diff[type]

  const {
    // for data and view table
    diff_for_col_spec,
    diff_for_col_width,
    diff_for_col_hidden,
    diff_for_cols_order,
    diff_for_cols,
    diff_for_frozen_cols,
    diff_for_order_by,
    // for data table
    diff_for_scheme_autoresolve,
    diff_nullify_cells_in_columns,
    // for view table
    diff_for_col_spec_override,
    diff_for_col_width_override,
    diff_for_col_hidden_override,
    // for computed table
    diff_for_table_fn,
    diff_for_table_logging,
    diff_for_params,
  } = get_schema_diffs(data, invalids)

  /**
   * Add column
   * @memberOf TableActions
   * @function
   * @param new_col_id {ColumnSpec}
   * @param new_col_spec {ColumnSpec<CellType, 'entity'>}
   * @param selected_column {ColumnId | null}
   * @param [where] {'left' | 'right'}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const add_column = (
    new_col_id: ColumnId,
    new_col_spec: ColumnSpec<CellType, 'entity'>,
    selected_column: ColumnId | null,
    where?: 'left' | 'right'
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    if (type === 'view_table') {
      ensure(!!new_col_spec.computed, 'View table can only add column of type computed.')
    }

    const pos_diff = where === 'right' ? 1 : 0
    const insert_pos = selected_column
      ? cols_order.indexOf(selected_column) + pos_diff
      : cols_order.length
    const new_cols_order = ih(cols_order, {$splice: [[insert_pos, 0, new_col_id]]})

    return table_diff.squash(
      diff_for_col_spec(new_col_id, new_col_spec),
      diff_for_cols_order(new_cols_order)
    )
  }

  /**
   * Edit column
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param new_col_spec { ColumnSpec<CellType, 'entity'>}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const edit_column = (
    col_id: ColumnId,
    new_col_spec: ColumnSpec<CellType, 'entity'>
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    if (type === 'data_table') {
      return diff_for_col_spec(col_id, new_col_spec)
    }

    if (_.has(view_entity!.cols, col_id)) {
      return diff_for_col_spec(col_id, new_col_spec)
    } else {
      return diff_for_col_spec_override(col_id, new_col_spec)
    }
  }

  /**
   * Change column width
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param new_width {number}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const change_column_width = (
    col_id: ColumnId,
    new_width: number
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    if (type === 'data_table') {
      return diff_for_col_width(col_id, new_width)
    }

    if (_.has(view_entity!.cols, col_id)) {
      return diff_for_col_width(col_id, new_width)
    } else {
      return diff_for_col_width_override(col_id, new_width)
    }
  }

  /**
   * Remove columns action
   * @memberOf TableActions
   * @function
   * @param removed_col_ids {ColumnId[]}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const remove_columns = (removed_col_ids: ColumnId[]): DataTable<'diff'> | ViewTable<'diff'> => {
    const new_cols_order = cols_order.filter((col) => !removed_col_ids.includes(col))
    const col_diffs = _.map(removed_col_ids, (col_id) => diff_for_col_spec(col_id, null))

    if (type === 'view_table') {
      ensure(
        _.isEmpty(_.difference(removed_col_ids, _.keys(view_entity!.cols))),
        'Only columns added through view_table can be removed here.'
      )
      return view_table_diff.squash(diff_for_cols_order(new_cols_order), ...col_diffs)
    } else {
      return data_table_diff.squash(
        diff_nullify_cells_in_columns(removed_col_ids),
        diff_for_cols_order(new_cols_order),
        ...col_diffs
      )
    }
  }

  /**
   * Change frozen columns
   * @memberOf TableActions
   * @function
   * @param new_frozen_cols {number}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const change_frozen_columns = (
    new_frozen_cols: number
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    return diff_for_frozen_cols(new_frozen_cols)
  }

  /**
   * Circular column reorder
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param shift {number}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const circular_column_reorder = (
    col_id: ColumnId,
    shift: number
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    // computes desired position in cols_order that has filtered hidden cols
    const col_pos = cols_order_without_hidden_cols.indexOf(col_id)
    const desired_col_pos = mod(col_pos + shift, cols_order_without_hidden_cols.length)

    // we need to change it in original cols_order, which includes hidden cols
    const new_cols_order = get_new_cols_order(
      cols_order,
      cols_order_without_hidden_cols,
      col_id,
      false,
      desired_col_pos
    )
    return diff_for_cols_order(new_cols_order)
  }

  /**
   * Add option to column specification
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param option_id {OptionId}
   * @param option_name {string}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const add_option_to_col_spec = (
    col_id: ColumnId,
    option_id: OptionId,
    option_name: string
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    if (type === 'view_table') {
      ensure(_.has(view_entity!.cols, col_id), "Cannot add option to source table's column")
    }

    const {type: col_type} = cols[col_id].type
    ensure(col_type === 'option', 'Cannot add option to a column of type', {col_type})

    const diff = {
      type: {
        options: {[option_id]: {name: value_diff().create_diff(option_name)}},
      },
    }
    return diff_for_cols({[col_id]: diff})
  }

  /**
   * Change order by
   * @memberOf TableActions
   * @function
   * @param order_by {SortField}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const change_order_by = (order_by: SortField[]): DataTable<'diff'> | ViewTable<'diff'> => {
    return diff_for_order_by(order_by)
  }

  /**
   * Autoresolve cheme conflicts
   * @memberOf TableActions
   * @function
   * @return {EntityDiff<TableType>}
   */
  const autoresolve_scheme_conflicts = (): EntityDiff<TableType> => {
    if (type === 'data_table') {
      return diff_for_scheme_autoresolve()
    } else {
      // resolving conflict and invalids on view and computed table is not implmeneted yet
      return {type}
    }
  }

  /**
   * Change column hidden flag
   * @memberOf TableActions
   * @function
   * @param col_id {ColumnId}
   * @param hidden {boolean}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const _change_col_hidden_flag = (
    col_id: ColumnId,
    hidden: boolean
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    if (type === 'data_table') {
      return diff_for_col_hidden(col_id, hidden)
    }

    if (_.has(view_entity!.cols, col_id)) {
      return diff_for_col_hidden(col_id, hidden)
    } else {
      return diff_for_col_hidden_override(col_id, hidden)
    }
  }

  /**
   * Change column hidden flag
   * @memberOf TableActions
   * @function
   * @param col_ids {ColumnId[]}
   * @param hidden {boolean}
   * @return {DataTable<'diff'> | ViewTable<'diff'>}
   */
  const change_cols_hidden_flag = (
    col_ids: ColumnId[],
    hidden: boolean
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    const diffs = _.map(col_ids, (col_id) => _change_col_hidden_flag(col_id, hidden))
    return table_diff.squash(...diffs)
  }

  const change_table_fn = (new_fn: string): ComputedTable<'diff'> => {
    return diff_for_table_fn(new_fn)
  }

  const change_table_logging = (new_logging: boolean): ComputedTable<'diff'> => {
    return diff_for_table_logging(new_logging)
  }

  const change_params = (params?: ComputedParamsSpec): ComputedTable<'diff'> => {
    return diff_for_params(params)
  }

  return {
    // common actions
    autoresolve_scheme_conflicts,
    // data and view table schema actions
    add_column,
    edit_column,
    remove_columns,
    change_frozen_columns,
    circular_column_reorder,
    add_option_to_col_spec,
    change_column_width,
    change_order_by,
    change_cols_hidden_flag,
    // computed table action
    change_table_fn,
    change_table_logging,
    change_params,
  }
}

type CellActions = ReturnType<typeof create_cell_actions>
type SchemaActions = ReturnType<typeof create_schema_actions>
type MapToDiffActions<T extends Record<string, (...args: any[]) => EntityDiff>> = {
  [P in keyof T]: (...args: Parameters<T[P]>) => AddDiffAction<ReturnType<T[P]>>
}

export type TableActions = MapToDiffActions<CellActions & SchemaActions>

/**
 * Actions that are used in UI, already wrapped in a diff to dispatch
 * @memberOf TableActions
 * @function
 * @param data {TableData}
 * @param invalids {Conflict[]}
 * @param cols {TableCols}
 * @param cols_order_without_hidden_cols {ColumnId[]}
 * @param runtime {Runtime}
 * @return {TableActions}
 */
const get_table_actions = (
  data: TableData,
  invalids: Conflict[],
  cols: TableCols,
  cols_order_without_hidden_cols: ColumnId[],
  runtime: Runtime
): TableActions => {
  const {
    type,
    is_cells_editable,
    is_schema_editable,
    entity_id,
    zone_id,
    original_entity_id,
    original_zone_id,
  } = data

  // actions
  const {autoresolve_cells_conflicts, ...common_cell_actions} = create_cell_actions(
    data,
    cols,
    cols_order_without_hidden_cols,
    runtime
  )
  const {
    change_table_fn,
    change_table_logging,
    change_params,
    autoresolve_scheme_conflicts,
    ...common_data_schema_actions
  } = create_schema_actions(data, invalids, cols, cols_order_without_hidden_cols)

  const wrapped_cell_actions = _.mapValues(
    common_cell_actions,
    (action: (...args: any[]) => DataTable<'diff'>) => {
      if (is_cells_editable) {
        return entity_diff_action(action, original_entity_id, original_zone_id)
      } else {
        return () =>
          throw_error('Cells actions are only supported on data tables and views of data tables.')
      }
    }
  ) as MapToDiffActions<CellActions>

  const wrapped_common_data_schema_actions = _.mapValues(
    common_data_schema_actions,
    (
      action: (...args: any[]) => DataTable<'diff'> | ViewTable<'diff'> | ComputedTable<'diff'>,
      action_name: string
    ) => {
      if (is_schema_editable) {
        return entity_diff_action(action, entity_id, zone_id)
      } else {
        return () => throw_error(`${action_name} is only supported on view and data tables.`)
      }
    }
  ) as MapToDiffActions<SchemaActions>

  const wrapped_autoresolve_cells_conflicts =
    type === 'data_table'
      ? entity_diff_action(autoresolve_cells_conflicts, entity_id, zone_id)
      : () => throw_error(`autoresolve_scheme_conflicts is not yet supported on ${type}.`)

  const wrapped_autoresolve_scheme_conflicts =
    type === 'data_table'
      ? entity_diff_action(autoresolve_scheme_conflicts, entity_id, zone_id)
      : () => throw_error(`autoresolve_scheme_conflicts is not yet supported on ${type}.`)

  const wrapped_change_table_fn =
    type === 'computed_table'
      ? entity_diff_action(change_table_fn, entity_id, zone_id)
      : () => throw_error('change_fn is only supported on computed tables.')

  const wrapped_change_table_logging =
    type === 'computed_table'
      ? entity_diff_action(change_table_logging, entity_id, zone_id)
      : () => throw_error('change_fn is only supported on computed tables.')

  const wrapped_change_params =
    type === 'computed_table'
      ? entity_diff_action(change_params, entity_id, zone_id)
      : () => throw_error('change_params is only supported on computed tables.')

  const actions: TableActions = {
    // cell actions always affect original table
    ...wrapped_cell_actions,
    ...wrapped_common_data_schema_actions,
    autoresolve_cells_conflicts: wrapped_autoresolve_cells_conflicts,
    autoresolve_scheme_conflicts: wrapped_autoresolve_scheme_conflicts,
    // type specific action
    change_table_fn: wrapped_change_table_fn,
    change_table_logging: wrapped_change_table_logging,
    change_params: wrapped_change_params,
  }

  return actions
}

/**
 * Create new view table
 * @memberOf TableActions
 * @function
 * @param id {TableEntityId}
 * @param project_id {ProjectId}
 * @param parent_id {TableEntityId}
 * @param source_entity_id {TableEntityId}
 * @param attrs {Partial<EntityEditableSchema<'view_table'>>}
 * @return {AddDiffAction<EntityDiff<'view_table'>>}
 */
const create_new_view_table = (
  id: TableEntityId,
  project_id: ProjectId,
  parent_id: TableEntityId,
  source_entity_id: TableEntityId,
  attrs: Partial<EntityEditableSchema<'view_table'>> = {}
): AddDiffAction<EntityDiff<'view_table'>> => {
  const entity = create_view_table_entity({
    entity_id: id,
    zone_id: project_id,
    parent_id,
    source_entity_id,
    ...attrs,
  })
  return _add_diff(id, project_id, create_diff<'view_table'>(entity))
}

/**
 * Create new data table
 * @memberOf TableActions
 * @function
 * @param id {TableEntityId}
 * @param project_id {ProjectId}
 * @param attrs {Partial<EntityEditableSchema<'data_table'>>}
 * @param [subtype] {TableSubtype}
 * @return {AddDiffAction<EntityDiff<'data_table'>>}
 */
const create_new_data_table = (
  id: TableEntityId,
  project_id: ProjectId,
  attrs: Partial<EntityEditableSchema<'data_table'>> = {},
  subtype?: TableSubtype
): AddDiffAction<EntityDiff<'data_table'>> => {
  const entity = create_data_table_entity({
    zone_id: project_id,
    parent_id: project_id,
    entity_id: id,
    subtype,
    archived: false,
    ...attrs,
  })
  return _add_diff(id, project_id, create_diff<'data_table'>(entity))
}

/**
 * Create new computed table
 * @memberOf TableActions
 * @function
 * @param id {TableEntityId}
 * @param project_id {ProjectId}
 * @param attrs {Partial<EntityEditableSchema<'computed_table'>>}
 * @return {AddDiffAction<EntityDiff<'computed_table'>>}
 */
const create_new_computed_table = (
  id: TableEntityId,
  project_id: ProjectId,
  attrs: Partial<EntityEditableSchema<'computed_table'>> = {}
): AddDiffAction<EntityDiff<'computed_table'>> => {
  const entity = create_computed_table_entity({
    entity_id: id,
    zone_id: project_id,
    parent_id: project_id,
    archived: false,
    ...attrs,
  })
  return _add_diff(id, project_id, create_diff<'computed_table'>(entity))
}

const change_table_description = (entity_header: EntityHeader, new_description: string) => {
  const {entity_id, description, type, zone_id} = entity_header
  const description_diff = {
    type,
    description: value_diff().change_diff(description, new_description),
  } as EntityDiff
  return _add_diff(entity_id, zone_id, description_diff)
}

export {
  create_new_view_table,
  create_new_data_table,
  create_new_computed_table,
  get_table_actions,
  change_table_description,
}
