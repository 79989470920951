import React, {
  FC,
  ReactEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {GridCanvas} from './GridCanvas/GridCanvas'
import {TableCellEditor} from './CellEditor/TableCellEditor'
import {ColDragMarker} from './ColDragMarker'
import {control_key, mac_ALT_other_CTRL} from './utils/keyboard'
import {
  ReduxState,
  TableUiState,
  CursorCoordinates,
  get_table_ui_state,
  AlwaysDefinedRuntime,
  CellCoordinates,
  useRuntimeSelector,
  useSetTableUiState,
  useZoneUiSelector,
} from './utils/connect_hocs'
import AutoSizer from './AutoSizer'
import TableContextMenu from './ContextMenu/TableContextMenu'
import styles from './Table.module.css'
import {styled} from '@material-ui/core'
import classNames from 'classnames'
import {focus_table_content_with_timeout, focus_table_find_input} from './utils/focus'
import {extract_clipboard_data, write_clipboard_data} from './utils/clipboard_utils'
import ColumnResizeTool from './ColumnResizeTool'
import {Coordinates, is_right_click} from './utils/mouse_utils'
import {get_cell_getter, get_table_size, edit_lower_bound, xy} from './table_data_helpers'
import {index_of_col, col_id_on_index, get_coords, get_position} from './utils/table_helpers'
import {get_storage_utils} from 'common/storage_utils'
import SummaryRow from './SummaryRow/SummaryRow'
import {useRuntimeActions} from './RuntimeContextProvider'
import {TableEntityId, ColumnId} from 'common/types/storage'
import {init_history} from './DetailedView/history_actions'
import {open_modal} from './Modals'
import {
  Dimensions,
  Offset,
  Position,
  GCSelection,
  Rect_LR_TB,
  CellPositionGetter,
  ColRectangleGetter,
} from './utils/layout'
import {clamp_repeated_target_range_size} from 'common/entities/table_utils'
import {redo, undo} from './utils/table_actions_helpers'
import {uuid} from 'common/utils'
import TableFind from './TableFind/TableFind'
import {TableTooltips} from './TableTooltips'
import {FrozenColsTool} from './FrozenColsTool'
import {UserAccountContext} from './UserAccountProvider'
import {useAddRowAction} from './utils/table_action'
import {style} from './GridCanvas/style'

declare global {
  interface Window {
    chrome?: object
  }
}

const UNSUPPORTED_PASTE_MSG =
  'Unfortunately, pasting to new rows is not supported on this browser. ' +
  'Please switch to a supported browser (such as Google Chrome, Microsoft Edge or Opera), ' +
  'or add the rows manually.'

// temporary solution for inserting to new rows only supported on Chromium-based browsers
let is_inserting_paste: boolean = false

const is_on_scrollbar = (e: React.MouseEvent<HTMLDivElement>): boolean => {
  const targetRect = e.currentTarget.getBoundingClientRect()
  const {clientWidth, clientHeight} = e.target as Element
  return targetRect.left + clientWidth < e.clientX || targetRect.top + clientHeight < e.clientY
}

/** Return number in [min, max] range (both inclusive). */
const clamp = (num: number, min: number, max: number): number => {
  return Math.min(Math.max(num, min), max)
}

export const selection_rectangle = (cursor_pos: Position, selection_size: Offset) => {
  const selection_top_left = xy((i) =>
    // top left is same as cursor if selection size is positive
    selection_size[i] > 0 ? cursor_pos[i] : cursor_pos[i] + selection_size[i]
  )
  const selection_positive_size = xy((i) => Math.abs(selection_size[i]) + 1)
  return {selection_top_left, selection_positive_size}
}

const get_selection = (cursor_pos: Position, selection_size: Offset): GCSelection => {
  const rect = selection_rectangle(cursor_pos, selection_size)
  return [...rect.selection_top_left, ...rect.selection_positive_size]
}

export const normalize_selection = (cursor_pos: Position, selection_size: Dimensions) => {
  const {selection_top_left, selection_positive_size: size} = selection_rectangle(
    cursor_pos,
    selection_size
  )
  const top_left = xy((i) => selection_top_left[i] - edit_lower_bound[i])
  return {top_left, size}
}

const get_offset_from_arrow_key = (key: string): Offset => {
  switch (key) {
    case 'ArrowDown':
      return [0, 1]
    case 'ArrowUp':
      return [0, -1]
    case 'ArrowLeft':
      return [-1, 0]
    case 'ArrowRight':
      return [1, 0]
    default:
      return [0, 0]
  }
}

const get_cell_coordinates = (cursor: CursorCoordinates | null): CellCoordinates | null => {
  if (cursor?.row_id != null && cursor?.col_id != null) {
    return {row_id: cursor.row_id, col_id: cursor.col_id}
  }
  return null
}

const stop_propagation: ReactEventHandler = (e) => e.stopPropagation()
const prevent_default: ReactEventHandler = (e) => e.preventDefault()

export const reset_ui_state = Object.freeze({
  cursor: null,
  last_cursor: null,
  selection_size: [0, 0],
  editing: null,
  scroll_to: null,
  last_scroll_to: null,
} as Partial<TableUiState>)

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const TableContainer = styled('div')({
  flex: '1 1 100%',
  position: 'relative',
  overflow: 'hidden',
})

type GridCanvasCellFn = (i: number, j: number) => {cellRange: Rect_LR_TB; vsbRange: Rect_LR_TB}

type TableProps = {
  redirect_to_table: (table_entity_id: TableEntityId) => void
}

export const Table: FC<TableProps> = ({redirect_to_table}) => {
  /*
   * How should I add a new "property"?
   *
   * 1. useRef (equivalent to an instance variable) - changing this will not trigger component
   *    rerender, so not good for storing any properties that should affect UI. Should be changed
   *    by mutating `property_name.current`
   *
   * 2. useState (equivalent to a state property) - setting the property via the returned setter
   *    will rerender the table => good for UI properties. However, all state information will be
   *    destroyed on component unmount.
   *
   * 3. Add to TableUiState (table_ui_state.property_name) - if your UI property should
   *    survive component unmount (e.g. switching between tables), you should store it in the UI
   *    state (which is stored in the redux storage). Such property should be changed by calling
   *    set_table_ui_state()
   */
  const {
    storage,
    resources: {
      table_resources: {table, diff, table_entity_id, zone_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {entity_headers, history_mode} = storage
  const storage_utils = get_storage_utils(storage)

  const current_user = useContext(UserAccountContext)
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const [modal, table_ui_state] = useSelector(
    (state: ReduxState) => [state.modal, get_table_ui_state(state, table_entity_id)] as const
  )
  const {
    cursor,
    dragged_column_id,
    dragged_column_pos,
    editing,
    full_rows_order,
    rows_order,
    selection_size,
    scroll_to,
    find_result,
    detailed_view,
    cache_rows_order,
    filter,
  } = table_ui_state

  const dispatch = useDispatch()
  const {dispatch_storage} = useRuntimeActions()

  // "instance" variables
  const table_ref = useRef<HTMLDivElement>(null)
  const container_ref = useRef<HTMLDivElement>(null)
  const canvas_ref = useRef<GridCanvas>(null)
  const first_focus = useRef(false)
  const cell_pos_getter_ref = useRef<CellPositionGetter>()

  // state variables
  const [clear_before_editing, set_clear_before_editing] = useState(false)
  const [context_menu_pos, set_context_menu_pos] = useState<Coordinates | null>(null)
  const [last_was_mouse, set_last_was_mouse] = useState(false)
  const [mouse_pos, set_mouse_pos] = useState<Position | null>(null)
  const [mouse_over_gc, set_mouse_over_gc] = useState(false)
  const [mouse_down, set_mouse_down] = useState(false)
  const [dragging_in_gc, set_dragging_in_gc] = useState(false)
  const [scroll_offset, set_scroll_offset] = useState<Offset>([0, 0])
  const [tab_seq_start, set_tab_seq_start] = useState<CursorCoordinates | null>(null)
  const [searching, set_searching] = useState(false)

  // predicates
  const show_changes = 'history' === useZoneUiSelector(zone_id, 'sidebar')
  const is_empty_table = table.col_count() === 0
  const is_table_editable = !history_mode
  const is_schema_editable = is_table_editable && table._can_edit_schema()
  const is_cells_editable = is_table_editable && table._can_edit_cells()
  const is_mouse_over_the_empty_row = mouse_pos && mouse_pos[1] === table.row_count() + 1
  const column_is_being_dragged = dragged_column_id != null
  const allow_draggable_tools = is_schema_editable && !dragging_in_gc
  const allow_tooltips =
    last_was_mouse && mouse_over_gc && !mouse_down && detailed_view.current == null && !modal.type
  const can_add_row = is_table_editable && table._can_insert_row()

  // Add empty row, which can be clicked to add a new row, if the table can be edited
  const empty_row = can_add_row ? 1 : 0

  // +1 because of the column with line numbers
  const frozen_offset: Offset = useMemo(() => [table._frozen_cols + 1, 1], [table._frozen_cols])

  const pos_to_cell = useCallback(
    (pos: Position): CursorCoordinates => get_coords(pos, table, rows_order),
    [rows_order, table]
  )

  const cell_to_pos = useCallback(
    (cell: CursorCoordinates): Position => get_position(cell, table, rows_order),
    [rows_order, table]
  )

  const selection: GCSelection = cursor
    ? get_selection(cell_to_pos(cursor), selection_size)
    : [0, 0, 0, 0]

  // returns position and size of column in `GridCanvas`
  // height of the column can be higher than height of the screen
  // notice that position is relative to `GridCanvas`
  const get_col_rectangle: ColRectangleGetter = useCallback(
    (col_pos: number) => {
      const {height, columns_widths} = get_table_size(table)
      const top = cell_pos_getter_ref.current!([col_pos, 0])[1][0]
      const bottom = cell_pos_getter_ref.current!([col_pos, height - 1 + empty_row])[1][1]
      const left = cell_pos_getter_ref.current!([col_pos, 0])[0][0]
      const right = cell_pos_getter_ref.current!([col_pos, 0])[0][1]

      return {
        left,
        right,
        top,
        width: columns_widths[col_pos],
        height: bottom - top,
      }
    },
    [table, empty_row]
  )

  const _normalize_selection = useCallback(
    (cursor: CursorCoordinates, selection_size: Dimensions) => {
      return normalize_selection(cell_to_pos(cursor), selection_size)
    },
    [cell_to_pos]
  )

  // Ensure that position is within the table's bounds
  const clamp_position = useCallback(
    (pos: Position): Position => {
      const {width, height} = get_table_size(table)
      return xy((i) => clamp(pos[i], 0, [width, height][i] - 1))
    },
    [table]
  )

  // Check if position is within current selection
  const is_in_selection = useCallback(
    (pos: Position): boolean => {
      if (cursor == null) {
        return false
      }
      const {selection_top_left, selection_positive_size} = selection_rectangle(
        cell_to_pos(cursor),
        selection_size
      )
      const in_selection_xy = xy(
        (i) =>
          pos[i] >= selection_top_left[i] &&
          pos[i] < selection_top_left[i] + selection_positive_size[i]
      )
      return in_selection_xy[0] && in_selection_xy[1]
    },
    [cell_to_pos, cursor, selection_size]
  )

  const get_offset_cursor = useCallback(
    (cursor: CursorCoordinates, offset: Offset): CursorCoordinates => {
      const cursor_pos = cell_to_pos(cursor)
      return pos_to_cell(clamp_position(xy((i) => cursor_pos[i] + offset[i])))
    },
    [cell_to_pos, clamp_position, pos_to_cell]
  )

  const circular_column_reorder = useCallback(
    (col_id: ColumnId, shift: number) => {
      if (shift === 0 || !is_schema_editable) return
      dispatch_storage(table._actions.circular_column_reorder(col_id, shift))
    },
    [dispatch_storage, is_schema_editable, table._actions]
  )

  const close_table_find = useCallback(() => {
    set_searching(false)
    if (table_ref.current) table_ref.current.focus()
  }, [])

  const close_context_menu = useCallback(() => {
    set_context_menu_pos(null)
    if (table_ref.current) table_ref.current.focus()
  }, [])

  const start_editing = useCallback(
    (coords: CellCoordinates, clear_before_editing: boolean) => {
      set_clear_before_editing(clear_before_editing)
      set_table_ui_state({scroll_to: coords, last_scroll_to: coords, editing: coords}, 'start_edit')
    },
    [set_table_ui_state]
  )

  const end_editing = useCallback(() => {
    set_table_ui_state({editing: null}, 'end_edit')
  }, [set_table_ui_state])

  // Set selection to a single cell and scroll to it
  const select_single_cell = useCallback(
    (new_cursor: CursorCoordinates | null, scroll_to?: CursorCoordinates) => {
      set_table_ui_state({
        cursor: new_cursor,
        last_cursor: new_cursor,
        scroll_to: scroll_to ?? new_cursor,
        last_scroll_to: scroll_to ?? new_cursor,
        selection_size: [0, 0],
      })
    },
    [set_table_ui_state]
  )

  // Set selection to a whole row and scroll to it
  const select_whole_row = useCallback(
    (new_cursor: CursorCoordinates, scroll_to?: CursorCoordinates) => {
      set_table_ui_state({
        cursor: new_cursor,
        last_cursor: new_cursor,
        scroll_to: scroll_to ?? new_cursor,
        last_scroll_to: scroll_to ?? new_cursor,
        selection_size: [table.col_count(), 0],
      })
    },
    [set_table_ui_state, table]
  )

  // Set selection to a whole col and scroll to top of it
  const select_whole_col = useCallback(
    (new_cursor: CursorCoordinates) => {
      set_table_ui_state({
        cursor: new_cursor,
        last_cursor: new_cursor,
        scroll_to: new_cursor,
        last_scroll_to: new_cursor,
        selection_size: [0, table.row_count()],
      })
    },
    [set_table_ui_state, table]
  )

  const select_whole_table = useCallback(
    (new_cursor: CursorCoordinates) => {
      set_table_ui_state({
        cursor: new_cursor,
        last_cursor: new_cursor,
        scroll_to: new_cursor,
        last_scroll_to: new_cursor,
        selection_size: [table.col_count(), table.row_count()],
      })
    },
    [set_table_ui_state, table]
  )

  // Set selection to null
  const unselect_all_cells = useCallback(() => {
    select_single_cell(null)
  }, [select_single_cell])

  const select_cells_from_pos = useCallback(
    (new_cursor_pos: Position, scroll_to_pos?: Position) => {
      const new_cursor = pos_to_cell(new_cursor_pos)
      const new_scroll_to = scroll_to_pos && pos_to_cell(scroll_to_pos)
      if (new_cursor_pos[0] !== 0 && new_cursor_pos[1] !== 0) {
        select_single_cell(new_cursor, new_scroll_to)
      } else if (new_cursor_pos[1] === 0 && new_cursor_pos[0] !== 0) {
        select_whole_col(new_cursor)
      } else if (new_cursor_pos[0] === 0 && new_cursor_pos[1] === 0) {
        select_whole_table(new_cursor)
      } else {
        select_whole_row(new_cursor, new_scroll_to)
      }
    },
    [pos_to_cell, select_single_cell, select_whole_col, select_whole_row, select_whole_table]
  )

  const select_cells_from_cursor = useCallback(
    (new_cursor: CursorCoordinates, scroll_to?: CursorCoordinates) => {
      if (new_cursor.col_id != null) {
        select_single_cell(new_cursor, scroll_to)
      } else {
        select_whole_row(new_cursor, scroll_to)
      }
    },
    [select_single_cell, select_whole_row]
  )

  const move_cursor_to_row_pos = useCallback(
    (row_pos: number) => {
      const row_id = row_pos === 0 ? null : rows_order[row_pos - 1]
      const col_id = cursor?.col_id || null
      select_cells_from_cursor({row_id, col_id})
    },
    [cursor, rows_order, select_cells_from_cursor]
  )

  const handle_end_key = useCallback(() => {
    move_cursor_to_row_pos(rows_order.length)
  }, [rows_order, move_cursor_to_row_pos])

  const handle_home_key = useCallback(() => {
    move_cursor_to_row_pos(1) // header is at 0
  }, [move_cursor_to_row_pos])

  // moves the page one page down (1) or up (-1) relative to the current viewport
  const move_page = useCallback(
    (direction: 1 | -1) => {
      const height = canvas_ref.current?.props?.size[1]
      // y position of the row at the top of the viewport
      const y_pos = canvas_ref.current?.cache?.vsbRange[1][1][0]
      if (!height || !y_pos) return
      const frozen_rows = frozen_offset[1]
      const row_count = rows_order.length
      const x_pos = cursor ? cell_to_pos(cursor)[0] : 0
      // calculate how many rows cursor should move in required direction
      const step_length = Math.floor(height / style.cellHeight) - frozen_rows
      // calculate future y position of cursor
      let new_y_pos = y_pos + direction * step_length
      if (new_y_pos < frozen_rows || new_y_pos > row_count - 1) {
        // if new y position would be out of table, cursor will move to first/last row,
        new_y_pos = direction === 1 ? row_count : frozen_rows
      }
      const new_cursor_pos: Position = [x_pos, new_y_pos]
      // move view that new cursor will be on top of the page
      const view_y_pos =
        direction === 1
          ? Math.min(new_y_pos + step_length - 1, row_count)
          : Math.max(new_y_pos, frozen_rows)
      select_cells_from_pos(new_cursor_pos, [x_pos, view_y_pos])
    },
    [canvas_ref, frozen_offset, rows_order, cursor, select_cells_from_pos, cell_to_pos]
  )

  const handle_page_up_key = useCallback(() => {
    move_page(-1)
  }, [move_page])

  const handle_page_down_key = useCallback(() => {
    move_page(1)
  }, [move_page])

  const handle_arrow_key = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (cursor == null || editing) {
        return
      }

      set_tab_seq_start(null)
      const cursor_pos = cell_to_pos(cursor)
      const offset = get_offset_from_arrow_key(e.key)

      if (mac_ALT_other_CTRL(e)) {
        const {col_id} = cursor
        const col_delta = offset[0]
        // Ctrl+Arrow moves the column (horizontal only)
        if (col_id != null && col_delta !== 0 && is_schema_editable) {
          circular_column_reorder(col_id, col_delta)
          set_table_ui_state({
            scroll_to: cursor,
            last_scroll_to: cursor,
          })
        }
      } else if (e.shiftKey) {
        e.preventDefault()
        // Multi selection: Move the selection end and adjust the size accordingly
        const old_selection_end = xy((i) => cursor_pos[i] + selection_size[i])
        const new_selection_end = clamp_position(xy((i) => old_selection_end[i] + offset[i]))
        const new_selection_size = xy((i) => new_selection_end[i] - cursor_pos[i])
        set_table_ui_state({
          scroll_to: pos_to_cell(new_selection_end),
          last_scroll_to: pos_to_cell(new_selection_end),
          selection_size: new_selection_size,
        })
      } else {
        // Single selection: Move the cursor and reset the size
        const new_cursor_pos = clamp_position(xy((i) => cursor_pos[i] + offset[i]))
        select_cells_from_pos(new_cursor_pos)
      }
    },
    [
      cursor,
      editing,
      cell_to_pos,
      is_schema_editable,
      circular_column_reorder,
      set_table_ui_state,
      clamp_position,
      pos_to_cell,
      selection_size,
      select_cells_from_pos,
    ]
  )

  const clear_cells_in_selection = useCallback(() => {
    if (cursor) {
      const {top_left, size} = _normalize_selection(cursor, selection_size)
      dispatch_storage(
        table._actions.clear_cells(
          top_left,
          size,
          rows_order,
          table._can_edit_cell,
          current_user?.email
        )
      )
    }
  }, [
    _normalize_selection,
    current_user?.email,
    cursor,
    dispatch_storage,
    rows_order,
    selection_size,
    table,
  ])

  const try_clear_cells_in_selection = useCallback(() => {
    const cells_in_selection_can_be_cleared = is_cells_editable && !editing
    if (cells_in_selection_can_be_cleared) {
      clear_cells_in_selection()
    }
  }, [clear_cells_in_selection, editing, is_cells_editable])

  const handle_undo = useCallback(() => {
    is_table_editable &&
      undo({
        dispatch_storage,
        entity_headers,
        redirect_to_table,
        storage_utils,
        table,
        table_entity_id,
      })
  }, [
    is_table_editable,
    dispatch_storage,
    entity_headers,
    redirect_to_table,
    storage_utils,
    table,
    table_entity_id,
  ])

  const handle_redo = useCallback(() => {
    is_table_editable && redo({dispatch_storage, storage_utils, table})
  }, [dispatch_storage, is_table_editable, storage_utils, table])

  // Handler for all Ctrl+[Character] shortcuts
  const handle_ctrl_action = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key.toLowerCase()) {
        case 'z': {
          if (e.shiftKey) {
            handle_redo()
          } else {
            handle_undo()
          }
          break
        }
        case 'a': {
          e.preventDefault()
          const new_cursor = {row_id: null, col_id: col_id_on_index(table, 0)}
          set_table_ui_state({
            cursor: new_cursor,
            last_cursor: new_cursor,
            scroll_to: new_cursor,
            last_scroll_to: new_cursor,
            selection_size: [table.col_count() - 1, table.row_count()],
          })
          break
        }
        case 'v': {
          if (e.shiftKey) {
            if (!window.chrome) {
              // Alert the user if using an unsupported browser
              alert(UNSUPPORTED_PASTE_MSG) // eslint-disable-line no-alert
            } else {
              is_inserting_paste = true
            }
          }
          break
        }
        case 'f': {
          // do not show browser's native search
          e.preventDefault()

          if (!searching) {
            set_searching(true)
          } else {
            focus_table_find_input()
          }
          break
        }
        default:
      }
    },
    [handle_redo, handle_undo, searching, set_table_ui_state, table]
  )

  const cell_editing_can_start = useCallback(
    (cell: CellCoordinates): boolean => {
      return !editing && is_cells_editable && table._can_edit_cell(cell.col_id)
    },
    [editing, is_cells_editable, table]
  )

  const handle_typing = useCallback(() => {
    const selected_cell = get_cell_coordinates(cursor)
    if (selected_cell !== null && cell_editing_can_start(selected_cell)) {
      start_editing(selected_cell, true)
    }
  }, [cell_editing_can_start, cursor, start_editing])

  const handle_enter_key = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const selected_cell = get_cell_coordinates(cursor)
      if (selected_cell !== null && cell_editing_can_start(selected_cell)) {
        if (!e.shiftKey) {
          e.preventDefault()
        }
        start_editing(selected_cell, false)
      }
    },
    [cell_editing_can_start, cursor, start_editing]
  )

  const handle_f1_key = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault()
      dispatch(open_modal('show_shortcuts', {}))
    },
    [dispatch]
  )

  const handle_tab_key = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault()
      if (cursor == null) return

      // remember where user started pressing tab
      if (!tab_seq_start) set_tab_seq_start(cursor)

      const new_cursor = get_offset_cursor(cursor, [e.shiftKey ? -1 : 1, 0])
      select_single_cell(new_cursor)
    },
    [cursor, get_offset_cursor, select_single_cell, tab_seq_start]
  )

  const handle_delete_key = try_clear_cells_in_selection
  const handle_backspace_key = try_clear_cells_in_selection

  const handle_key_down = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Delete':
          handle_delete_key()
          break
        case 'Enter':
        case 'F2':
          handle_enter_key(e)
          break
        case 'Tab':
          handle_tab_key(e)
          break
        case 'Backspace':
          handle_backspace_key()
          break
        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
          handle_arrow_key(e)
          break
        case 'Home':
          handle_home_key()
          break
        case 'End':
          handle_end_key()
          break
        case 'PageUp':
          handle_page_up_key()
          break
        case 'PageDown':
          handle_page_down_key()
          break
        case 'F1':
          handle_f1_key(e)
          break
        default:
          if (control_key(e)) {
            handle_ctrl_action(e)
          } else if (e.key.length === 1) {
            //a user is typing
            handle_typing()
          }
      }
    },
    [
      handle_arrow_key,
      handle_home_key,
      handle_end_key,
      handle_page_up_key,
      handle_page_down_key,
      handle_backspace_key,
      handle_ctrl_action,
      handle_delete_key,
      handle_enter_key,
      handle_f1_key,
      handle_tab_key,
      handle_typing,
    ]
  )

  const handle_key_down_when_context_menu_is_open = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      //disable keyboard input in table, except escape to close
      if (e.key === 'Escape') {
        close_context_menu()
      }
    },
    [close_context_menu]
  )

  const on_key_down = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      //we want to be able to copy with Ctrl+C from cell tooltips
      if (!control_key(e)) {
        set_last_was_mouse(false)
      }

      if (context_menu_pos) {
        handle_key_down_when_context_menu_is_open(e)
      } else {
        handle_key_down(e)
      }
    },
    [context_menu_pos, handle_key_down, handle_key_down_when_context_menu_is_open]
  )

  const add_row = useAddRowAction(table, table_entity_id, {
    place: 'bottom',
    col_id_to_focus: mouse_pos && pos_to_cell(mouse_pos).col_id,
  })

  // Note(ppershing): We intentionally do not have onClick handler.
  // There is a good reason for that -- Firefox triggers onClick after onMouseUp when dragging
  // We therefore move onClick handling to onMouseDown
  const handle_click = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      set_tab_seq_start(null)

      if (mouse_pos == null) {
        unselect_all_cells()
      } else if (e.shiftKey && cursor != null && !is_mouse_over_the_empty_row) {
        const cursor_pos = cell_to_pos(cursor)
        if (mouse_pos[0] === 0) {
          set_table_ui_state({
            selection_size: [table.col_count(), mouse_pos[1] - cursor_pos[1]],
          })
        } else if (mouse_pos[1] === 0) {
          set_table_ui_state({
            selection_size: [mouse_pos[0] - cursor_pos[0], table.row_count()],
          })
        } else {
          const selection_size = xy((i) => mouse_pos[i] - cursor_pos[i])
          set_table_ui_state({selection_size})
        }
      } else if (is_mouse_over_the_empty_row) {
        add_row()
        return
      } else {
        select_cells_from_pos(mouse_pos)
      }
    },
    [
      cell_to_pos,
      cursor,
      mouse_pos,
      select_cells_from_pos,
      set_table_ui_state,
      table,
      unselect_all_cells,
      add_row,
      is_mouse_over_the_empty_row,
    ]
  )

  const on_double_click = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (is_on_scrollbar(e)) {
        return
      }

      if (mouse_pos && mouse_pos[0] === 0 && mouse_pos[1] > 0) {
        const {row_id} = pos_to_cell(mouse_pos)
        set_table_ui_state(init_history(table_entity_id, row_id!), 'open_detailed_view')
        dispatch(open_modal('detailed_view', {}))
      }

      if (mouse_pos && mouse_pos[0] > 0 && mouse_pos[1] === 0) {
        const {col_id} = pos_to_cell(mouse_pos)
        dispatch(
          open_modal(is_schema_editable ? 'edit_column' : 'view_column', {
            col_id: col_id!,
            table_entity_id,
          })
        )
      }

      if (cursor == null || e.shiftKey || !is_cells_editable) {
        return
      }

      const {row_id, col_id} = cursor
      if (row_id != null && col_id != null && table._can_edit_cell(col_id)) {
        start_editing({row_id, col_id}, false)
      }
    },
    [
      cursor,
      dispatch,
      is_cells_editable,
      is_schema_editable,
      mouse_pos,
      pos_to_cell,
      set_table_ui_state,
      start_editing,
      table,
      table_entity_id,
    ]
  )

  const on_scroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const grid_canvas_active_cell = canvas_ref.current?.cache?.activeCell
      const cursor_pos = cursor ? cell_to_pos(cursor) : null
      // if cursor_pos is different then grid_canvas_active_cell in this event then it means that
      // grid canvas will be redrawn with cursor_pos as active_cell and it may require scrolling
      // (to cursor). If we would set scroll_to to null, grid canvas would not scroll there and
      // selected cell would be out of visible area. Situation when cursor_pos is different then
      // grid_canvas_active_cell is e.g. when some navigation key (e.g. an arrow) is held down and
      // this is onScroll event is fired because of scrolling caused by previous keyDown (B) event.
      // But it is fired after next keyDown (C) event.
      // keyDown_A onScroll_A keyDown_B keyDown_C onScroll_B -here we are- onScroll_C

      if (scroll_to && _.isEqual(cursor_pos, grid_canvas_active_cell)) {
        set_table_ui_state({scroll_to: null, last_scroll_to: null})
      }
      set_scroll_offset([e.currentTarget.scrollLeft, e.currentTarget.scrollTop])
    },
    [scroll_to, cursor, cell_to_pos, set_table_ui_state]
  )

  const _start_column_dragging = () => {
    if (mouse_pos) {
      const {col_id} = pos_to_cell(mouse_pos)
      if (col_id != null) {
        set_table_ui_state({
          dragged_column_id: col_id,
          dragged_column_pos: mouse_pos[0],
        })
      }
    }
  }

  const _column_dragging_should_start = (e: React.MouseEvent<HTMLDivElement>): boolean => {
    return !e.shiftKey && mouse_pos !== null && mouse_pos[1] === 0 && is_schema_editable
  }

  const _update_selection_size = () => {
    if (cursor && mouse_pos && !is_mouse_over_the_empty_row) {
      const cursor_pos = cell_to_pos(cursor)
      let new_selection_size: [number, number]
      if (mouse_pos[0] === 0 && cursor.col_id == null) {
        new_selection_size = [table.col_count(), mouse_pos[1] - cursor_pos[1]]
      } else {
        new_selection_size = [
          Math.max(mouse_pos[0], 1) - cursor_pos[0], // don't select row nums unless started there
          mouse_pos[1] - cursor_pos[1],
        ]
      }
      if (!_.isEqual(selection_size, new_selection_size)) {
        set_table_ui_state({selection_size: new_selection_size})
      }
    }
  }

  const _end_column_dragging = () => {
    if (mouse_pos) {
      // Limit dragging to first column of table
      // Cannot drag over row numbering col
      const current_id = col_id_on_index(table, Math.max(mouse_pos[0] - 1, 0))

      if (dragged_column_id && current_id !== dragged_column_id) {
        const shift = index_of_col(table, current_id) - index_of_col(table, dragged_column_id)
        circular_column_reorder(dragged_column_id, shift)
      }
    }

    if (dragged_column_id) {
      set_table_ui_state({
        dragged_column_id: null,
        dragged_column_pos: null,
      })
    }
  }

  const on_mouse_down = (e: React.MouseEvent<HTMLDivElement>) => {
    if (is_on_scrollbar(e) || is_right_click(e)) {
      // Right click is handled by onContextMenu
      return
    }

    handle_click(e)
    if (_column_dragging_should_start(e)) {
      _start_column_dragging()
    }

    const mouse_over_plus_button = is_mouse_over_the_empty_row && mouse_pos && mouse_pos[0] === 0
    if (!mouse_over_plus_button) {
      set_dragging_in_gc(true)
    }
  }

  const on_mouse_move = () => {
    const cell_dragging_is_in_progress =
      cursor != null && cell_to_pos(cursor)[1] !== 0 && mouse_pos !== null && dragging_in_gc
    if (cell_dragging_is_in_progress) {
      _update_selection_size()
    }

    if (!last_was_mouse) {
      set_last_was_mouse(true)
    }
  }

  const on_mouse_up = () => {
    if (column_is_being_dragged) {
      _end_column_dragging()
    }
    set_dragging_in_gc(false)
  }

  const on_context_menu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (is_on_scrollbar(e)) {
      return
    }

    e.preventDefault()

    if (mouse_pos == null || is_mouse_over_the_empty_row) {
      unselect_all_cells()
    } else if (mouse_pos && !is_in_selection(mouse_pos)) {
      select_cells_from_pos(mouse_pos)
    }

    const pointer_pos = {top: e.pageY, left: e.pageX}
    set_context_menu_pos(pointer_pos)
  }

  const on_insert = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      if (!cursor || editing || !is_cells_editable) return

      const data = extract_clipboard_data(e.clipboardData)

      if (!data || !cursor.col_id) return

      const {top_left} = _normalize_selection(cursor, selection_size)
      const new_row_ids = Array.from({length: data.length}, uuid)
      const selected_row_index = cursor.row_id ? cache_rows_order!.indexOf(cursor.row_id) : -1

      dispatch_storage(
        table._actions.paste_data(
          [top_left[0], 0],
          [data[0].length, data.length],
          data,
          [],
          new_row_ids,
          table._can_edit_cell,
          current_user?.email
        )
      )
      const new_cache_rows_order = [
        ...cache_rows_order!.slice(0, selected_row_index + 1),
        ...new_row_ids,
        ...cache_rows_order!.slice(selected_row_index + 1),
      ]
      set_table_ui_state({
        cache_rows_order: new_cache_rows_order,
      })
    },
    [
      _normalize_selection,
      cache_rows_order,
      current_user?.email,
      cursor,
      dispatch_storage,
      editing,
      is_cells_editable,
      selection_size,
      set_table_ui_state,
      table._actions,
      table._can_edit_cell,
    ]
  )

  const on_paste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      //Handle inserting paste
      if (is_inserting_paste) {
        is_inserting_paste = false
        on_insert(e)
        return
      }
      //Handle ordinary paste
      if (!cursor || editing || !is_cells_editable) return

      // First extract pasted data
      const data = extract_clipboard_data(e.clipboardData)
      if (!data) return

      // Next figure out where are we pasting it
      const {top_left, size} = _normalize_selection(cursor, selection_size)

      const paste_size: Dimensions = [
        Math.max(data[0].length, size[0]),
        Math.max(data.length, size[1]),
      ]

      /*If paste can be repeated, clamp the target_range_size to repeated pasting*/
      const clamped_size = clamp_repeated_target_range_size(
        [data[0].length, data.length],
        paste_size
      )

      //Add new rows, if needed
      const new_row_ids = Array.from(
        {length: paste_size[1] - (rows_order.length - top_left[1])},
        uuid
      )

      try {
        dispatch_storage(
          table._actions.paste_data(
            top_left,
            clamped_size,
            data,
            rows_order,
            new_row_ids,
            table._can_edit_cell,
            current_user?.email
          )
        )
      } catch (error) {
        if (error.type === 'ref') {
          // eslint-disable-next-line no-alert
          alert(`${error.message} Value: ${error.value}`)
        } else {
          throw error
        }
      }

      /*Since clamped_size indexes from [1, 1], but selection_size indexes from [0, 0],
    remove 1 from each value*/
      const new_selection_size = xy((i) => clamped_size[i] - 1)
      /* new_cursor should be the top_left selected cell.
    top_left index is increased by 1, since position expected by pos_to_cell indexes from 1*/
      const new_cursor = pos_to_cell([top_left[0] + 1, top_left[1] + 1])

      set_table_ui_state({
        cursor: new_cursor,
        last_cursor: new_cursor,
        scroll_to: new_cursor,
        last_scroll_to: new_cursor,
        selection_size: new_selection_size,
        cache_rows_order: [...cache_rows_order!, ...new_row_ids],
      })

      focus_table_content_with_timeout()
    },
    [
      _normalize_selection,
      cache_rows_order,
      current_user?.email,
      cursor,
      dispatch_storage,
      editing,
      is_cells_editable,
      on_insert,
      pos_to_cell,
      rows_order,
      selection_size,
      set_table_ui_state,
      table._actions,
      table._can_edit_cell,
    ]
  )

  const on_cut = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      // Three cases when we don't want to copy selected cells:
      // - no cell is selected
      // - we are editing some cell
      // - we have highlighted some text (e.g. from cell tooltip)
      if (!cursor || editing || window.getSelection()?.toString()) return

      const {top_left, size} = _normalize_selection(cursor, selection_size)
      write_clipboard_data(e.clipboardData, table, top_left, size, rows_order)
      e.preventDefault()

      dispatch_storage(
        table._actions.clear_cells(
          top_left,
          size,
          rows_order,
          table._can_edit_cell,
          current_user?.email
        )
      )
      focus_table_content_with_timeout()
    },
    [
      _normalize_selection,
      current_user?.email,
      cursor,
      dispatch_storage,
      editing,
      rows_order,
      selection_size,
      table,
    ]
  )

  const on_copy = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      // Three cases when we don't want to copy selected cells:
      // - no cell is selected
      // - we are editing some cell
      // - we have highlighted some text (e.g. from cell tooltip)
      if (!cursor || editing || window.getSelection()?.toString()) return

      const {top_left, size} = _normalize_selection(cursor, selection_size)

      write_clipboard_data(e.clipboardData, table, top_left, size, rows_order)
      e.preventDefault()
    },
    [_normalize_selection, cursor, editing, rows_order, selection_size, table]
  )

  // get_cell_getter uses table.rows(), which is expensive to be called on each re-render
  const get_cell = useMemo(
    () =>
      get_cell_getter(
        table,
        diff,
        find_result?.cells_map,
        show_changes,
        !!find_result,
        rows_order,
        full_rows_order,
        filter ? Object.keys(filter.conditions) : []
      ),
    [diff, filter, find_result, full_rows_order, rows_order, show_changes, table]
  )

  const {width, height, columns_widths} = get_table_size(table)

  const on_editor_enter = useCallback(() => {
    if (!cursor) return
    // Move cursor to next row. If user navigated to current cell by pressing
    // a sequence of tab keys, then return to column where tab sequence started.
    const new_cursor = get_offset_cursor(tab_seq_start || cursor, [0, 1])
    select_single_cell(new_cursor)
    set_tab_seq_start(null)
  }, [cursor, get_offset_cursor, select_single_cell, tab_seq_start])

  const on_paint = ({cell, mousePos}: {cell: GridCanvasCellFn; mousePos: Position | null}) => {
    // mouse_pos are coordinates of cell under mouse pointer or null if pointer is out of cell.
    // They are also null before the first onMouseMove event and if GridCanvas is unmounted due to
    // in-progress error, it causes unwanted behavior in the onClick event
    if (!_.isEqual(mouse_pos, mousePos)) {
      set_mouse_pos(mousePos)
    }
    // we store this outside of component state
    cell_pos_getter_ref.current = (pos: Position) => cell(...pos).vsbRange
  }

  useEffect(() => {
    if (editing) {
      first_focus.current = true
    } else if (table_ref.current && first_focus.current) {
      table_ref.current.focus()
      first_focus.current = false
    }
  })

  return (
    <Container ref={container_ref}>
      <TableContainer
        onMouseUp={useCallback(() => set_mouse_down(false), [])}
        onMouseDown={useCallback(() => set_mouse_down(true), [])}
        onMouseEnter={useCallback(() => set_mouse_over_gc(true), [])}
        onMouseLeave={useCallback(() => set_mouse_over_gc(false), [])}
      >
        <AutoSizer>
          {(size) => (
            <div
              id="table-content"
              tabIndex={0}
              ref={table_ref}
              onKeyDown={on_key_down}
              onCopy={on_copy}
              onCut={on_cut}
              onPaste={on_paste}
            >
              <div
                onMouseDown={on_mouse_down}
                onMouseMove={on_mouse_move}
                onMouseUp={on_mouse_up}
                // sometimes user is able to drag the table, which would prevent captureClick
                onDragStart={prevent_default}
                onDoubleClick={on_double_click}
                onContextMenu={on_context_menu}
                className={classNames(
                  !!dragged_column_id && styles.dragging,
                  !!mouse_pos &&
                    mouse_pos[1] === 0 &&
                    mouse_pos[0] > 0 &&
                    !dragged_column_id &&
                    is_schema_editable &&
                    !dragging_in_gc &&
                    styles.canDrag,
                  is_mouse_over_the_empty_row && styles.canClick
                )}
              >
                <GridCanvas
                  ref={canvas_ref}
                  getCell={get_cell}
                  rows={(is_empty_table ? 0 : height) + empty_row}
                  cols={is_empty_table ? 0 : width}
                  colWidths={columns_widths}
                  size={size}
                  selections={[selection]}
                  activeCell={cursor ? cell_to_pos(cursor) : [0, 0]}
                  scrollTo={scroll_to ? cell_to_pos(scroll_to) : null}
                  onScroll={on_scroll}
                  frozen={frozen_offset}
                  onPaint={on_paint}
                />
              </div>
              <TableFind on_close={close_table_find} open={searching} />
              <TableContextMenu
                pos={context_menu_pos}
                selection={selection}
                on_close={close_context_menu}
              />
              {!!mouse_pos && dragged_column_pos != null && mouse_pos[0] !== dragged_column_pos && (
                <>
                  <ColDragMarker
                    type={mouse_pos[0] > dragged_column_pos ? 'target_right' : 'target_left'}
                    // Limit grad marker to first column of table
                    //  excluding the row numbering column
                    {...get_col_rectangle(Math.max(mouse_pos[0], 1))}
                  />
                  <ColDragMarker type={'source'} {...get_col_rectangle(dragged_column_pos)} />
                </>
              )}
              {allow_tooltips && mouse_pos && cell_pos_getter_ref.current && (
                // Avoid triggering dragging state
                <div onMouseDown={stop_propagation}>
                  <TableTooltips
                    cursor_coordinates={pos_to_cell(mouse_pos)}
                    mouse_pos={mouse_pos}
                    size={size}
                    cell_position={cell_pos_getter_ref.current(mouse_pos)}
                    is_schema_editable={is_schema_editable}
                  />
                </div>
              )}
              {allow_draggable_tools && container_ref.current && (
                <ColumnResizeTool
                  offset_left={container_ref.current.offsetLeft}
                  on_context_menu={on_context_menu}
                  pos_to_cell={pos_to_cell}
                  mouse_pos={mouse_pos}
                  get_col_rectangle={get_col_rectangle}
                />
              )}
              {allow_draggable_tools && cell_pos_getter_ref.current && container_ref.current && (
                <FrozenColsTool
                  get_cell_position={cell_pos_getter_ref.current}
                  get_col_rectangle={get_col_rectangle}
                  offset_left={container_ref.current.offsetLeft}
                  on_context_menu={on_context_menu}
                />
              )}
              {!!editing && cell_pos_getter_ref.current && (
                <TableCellEditor
                  table={table}
                  col_id={editing.col_id}
                  row_id={editing.row_id}
                  end_edit={end_editing}
                  on_enter={on_editor_enter}
                  clear_on_open={clear_before_editing}
                  cell_position={cell_pos_getter_ref.current(cell_to_pos(editing))}
                  canvas_size={size}
                  boundaries_element={table_ref.current ?? undefined}
                />
              )}
            </div>
          )}
        </AutoSizer>
      </TableContainer>
      {table._has_summary() && (
        <SummaryRow
          table={table}
          // Exclude column with row numbering
          columns_widths={_.slice(columns_widths, 1)}
          frozen_offset={frozen_offset}
          scroll_offset={scroll_offset}
        />
      )}
    </Container>
  )
}
