import React, {FC} from 'react'
import {RowNumberColumnTooltip} from './RowNumberColumnTooltip'
import {cell_change_from_diff, CELL_VIEW_MODE} from './table_data_helpers'
import {get_cell_tooltips, Tooltip} from './Tooltips/cell_tooltips'
import {ColumnHeaderTooltip} from './Tooltips/ColumnHeaderTooltip'
import {AddNewRowTooltip} from './AddNewRowTooltip'
import Tooltips from './Tooltips/Tooltips'
import {
  AlwaysDefinedRuntime,
  CursorCoordinates,
  useRuntimeSelector,
  useTableUiSelector,
  useZoneUiSelector,
} from './utils/connect_hocs'
import {Dimensions, Position, Rect_LR_TB} from './utils/layout'

type TableTooltipsProps = {
  cursor_coordinates: CursorCoordinates
  mouse_pos: Position
  size: Dimensions
  cell_position: Rect_LR_TB
  is_schema_editable: boolean
}

export const TableTooltips: FC<TableTooltipsProps> = ({
  cursor_coordinates,
  mouse_pos,
  size,
  cell_position,
  is_schema_editable,
}) => {
  const {
    runtime,
    resources: {
      table_resources: {table, table_entity_id, zone_id, diff},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const show_changes = 'history' === useZoneUiSelector(zone_id, 'sidebar')
  const editing = useTableUiSelector(table_entity_id, 'editing')

  const get_tooltips_for_cell = ({row_id, col_id}: CursorCoordinates): Tooltip[] => {
    if (row_id == null || col_id == null) return []

    return get_cell_tooltips({
      runtime,
      col_spec: table._cols[col_id],
      cell: table._cell(row_id, col_id),
      style: table._get_style(row_id, col_id),
      custom_tooltips: table._get_tooltips(row_id, col_id),
      changes: show_changes && diff ? cell_change_from_diff(diff, row_id, col_id) : null,
      mode: show_changes ? CELL_VIEW_MODE.changes_only : CELL_VIEW_MODE.default,
    })
  }

  const get_tooltip_for_header = ({col_id}: CursorCoordinates): String => {
    if (col_id == null) return ''
    return table._cols[col_id].description ?? ''
  }

  return (
    <>
      {mouse_pos[1] === table.row_count() + 1 && <AddNewRowTooltip cell_position={cell_position} />}
      {mouse_pos[0] === 0 && mouse_pos[1] > 0 && mouse_pos[1] <= table.row_count() && (
        <RowNumberColumnTooltip cell_position={cell_position} />
      )}
      {mouse_pos[0] > 0 && mouse_pos[1] === 0 && (
        <ColumnHeaderTooltip
          cell_position={cell_position}
          is_schema_editable={is_schema_editable}
          col_description={get_tooltip_for_header(cursor_coordinates)}
        />
      )}
      {!editing && (
        <Tooltips
          cell_position={cell_position}
          canvas_size={size}
          tooltips={get_tooltips_for_cell(cursor_coordinates)}
        />
      )}
    </>
  )
}
