import React from 'react'
import styles from '../Table.module.css'
import ErrorDescription from './ErrorDescription'
import Hyperlinks from '../Hyperlinks'
import ConflictDescription from './ConflictDescription'
import Diff from './Diff'
import _ from 'lodash'
import {is_error, MaybeError, ErrorObject} from 'common/error'
import {extract_hyperlinks_from_string} from 'common/regex_utils'
import {CELL_VIEW_MODE, CellChange} from '../table_data_helpers'
import theme from '../theme'
import {Runtime} from 'common/create_runtime'
import {ColumnSpec, CellType} from 'common/types/storage'
import {CellObject, CellStyle, CellTooltip} from 'common/types/data_table'
import {conflict_value_to_string, conflicts_to_strings} from '../Conflicts/table_problems_utils'

const TOOLTIP_PROPS = {
  error: {
    title: 'Error',
    color: theme.palette.error.main,
  },
  custom: {
    title: 'Custom Information',
    color: theme.palette.info.main,
  },
  conflict: {
    title: 'Conflict',
    color: theme.palette.warning.main,
  },
  diff: {
    title: 'Diff',
    color: theme.palette.diff.main,
  },
  new_cell: {
    title: 'New cell',
    color: theme.palette.positive.main,
  },
  hyperlinks: {
    title: 'Hyperlinks',
    color: theme.palette.link.main,
  },
}

type GetCellTooltipArgs = {
  runtime: Runtime
  col_spec: ColumnSpec<CellType, 'entity'>
  cell: CellObject
  style: MaybeError<CellStyle>
  custom_tooltips: MaybeError<CellTooltip>
  changes: CellChange | null
  mode: 'default' | 'changes_only'
}

export type Tooltip = {
  type: keyof typeof TOOLTIP_PROPS
  content: JSX.Element | string
}

const _make_diff_tooltip = (
  runtime: Runtime,
  col_spec: ColumnSpec<CellType, 'entity'>,
  change: CellChange
): Tooltip => {
  return {
    type: change.change_type,
    content: <Diff change_obj={change} col_spec={col_spec} runtime={runtime} />,
  }
}

const ERR_DESC = {
  value: {
    default: 'Invalid value',
    required: 'Required value',
  },
  user: {default: "User's computation threw an error"},
  depend: {default: 'Value depends on cells, which are erroneous'},
  ref: {
    'default': 'Referenced value does not exist',
    'unknown-row': 'Referenced row does not exist',
    'unknown-col': 'Referenced column does not exist',
    'bad-table': 'Referenced table threw error',
  },
}

const error_to_description = ({type, subtype}: ErrorObject) => {
  return _.get(ERR_DESC, `${type}.${subtype}`) || _.get(ERR_DESC, `${type}.default`)
}

const _make_error_tooltip = (error_origin: string, error: ErrorObject): Tooltip => {
  return {
    type: 'error',
    content: (
      <>
        <div className={styles.errorText}>{`${error_origin}: ${error_to_description(error)}`}</div>
        <ErrorDescription error={error} />
      </>
    ),
  }
}

const _make_custom_tooltip = (tooltip: string): Tooltip => {
  return {
    type: 'custom',
    content: tooltip,
  }
}

const _make_hyperlinks_tooltip = (links: string[]): Tooltip => {
  return {
    type: 'hyperlinks',
    content: <Hyperlinks links={links} />,
  }
}

const _make_conflict_tooltip = (cell: CellObject): Tooltip => {
  const conflicts = cell.get_conflicts_as_labels()
  const local_value = cell.get_label()
  const remote_value = conflicts[0][0]

  return {
    type: 'conflict',
    content: (
      <ConflictDescription
        conflicts={conflicts_to_strings(conflicts)}
        local_value={conflict_value_to_string(local_value)}
        remote_value={conflict_value_to_string(remote_value)}
      />
    ),
  }
}

const _cell_error_tooltips = ({cell}: GetCellTooltipArgs) =>
  is_error(cell.error) ? [_make_error_tooltip('Cell', cell.error)] : []

const _conflict_tooltips = ({cell}: GetCellTooltipArgs) =>
  !_.isEmpty(cell.conflicts) ? [_make_conflict_tooltip(cell)] : []

const _diff_tooltips = ({runtime, changes, col_spec}: GetCellTooltipArgs) =>
  changes !== null ? [_make_diff_tooltip(runtime, col_spec, changes)] : []

const _hyperlinks_tooltips = ({cell, col_spec}: GetCellTooltipArgs) => {
  if (!is_error(cell.error) && ['string', 'markdown', 'attachment'].includes(col_spec.type.type)) {
    const links = extract_hyperlinks_from_string(String(cell.get_label()))
    return !_.isEmpty(links) ? [_make_hyperlinks_tooltip(links)] : []
  } else {
    return []
  }
}

const _custom_tooltips = ({custom_tooltips}: GetCellTooltipArgs) =>
  is_error(custom_tooltips) ? [] : custom_tooltips.map(_make_custom_tooltip)

const _style_error_tooltips = ({style}: GetCellTooltipArgs) =>
  is_error(style) ? [_make_error_tooltip('User defined style', style)] : []

const _tooltip_error_tooltips = ({custom_tooltips}: GetCellTooltipArgs) =>
  is_error(custom_tooltips) ? [_make_error_tooltip('User tooltip', custom_tooltips)] : []

// Tooltips (including errors) for style, custom tooltips & hyperlinks
const get_cell_tooltips = (args: GetCellTooltipArgs) => {
  const {mode} = args
  return mode === CELL_VIEW_MODE.changes_only
    ? [..._cell_error_tooltips(args), ..._conflict_tooltips(args), ..._diff_tooltips(args)]
    : [
        ..._cell_error_tooltips(args),
        ..._style_error_tooltips(args),
        ..._tooltip_error_tooltips(args),
        ..._conflict_tooltips(args),
        ..._diff_tooltips(args),
        ..._hyperlinks_tooltips(args),
        ..._custom_tooltips(args),
      ]
}

export {TOOLTIP_PROPS, get_cell_tooltips}
