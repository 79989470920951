import React, {FC, useCallback, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {uuid} from 'common/utils'
import _ from 'lodash'
import {create_new_view_table} from 'common/entities/table_actions'
import EntitySettingsEditor from '../EntitySettingsEditor'
import {EntityHeader, EntityHeaders, TableEntityId} from 'common/types/storage'
import {ROUTES} from '../utils/navigation_utils'
import {usePaperStyles} from '../utils/customStyles'
import CustomButton from '../components/CustomButton'

const BASE_VIEW_LABEL = 'Main'
const NEW_VIEW_LABEL = 'New view'

const ViewDisplayContainer = styled('div')({
  display: 'flex',
})

const StyledCardContent = styled(CardContent)(({theme}) => ({
  padding: 0,
  minWidth: 164,
}))

const StyledTypography = styled(Typography)(({theme}) => ({
  minWidth: 120,
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'none',
}))

const SetView: FC = () => {
  const anchor_ref = useRef(null)
  const popover_classes = usePaperStyles()
  const [view_name_editor_opened, set_view_name_editor_opened] = useState(false)
  const [view_selector_opened, set_view_selector_opened] = useState(false)
  const history = useHistory()

  const {
    resources: {
      table_resources: {table_entity_id: current_view_entity_id, zone_id, table},
    },
    storage: {entity_headers, history_mode},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const base_table_entity_id =
    entity_headers[current_view_entity_id].type === 'view_table'
      ? entity_headers[current_view_entity_id].parent_id
      : current_view_entity_id

  const redirect_to_view = useCallback(
    (view_table_entity_id: TableEntityId) => {
      history.push(ROUTES.table(base_table_entity_id, view_table_entity_id))
    },
    [base_table_entity_id, history]
  )

  const is_default_view = base_table_entity_id === current_view_entity_id

  const {dispatch_storage} = useRuntimeActions()

  const views_for_table: EntityHeaders = _.pickBy(
    entity_headers,
    (value) =>
      (value.type === 'view_table' && value.parent_id === base_table_entity_id) ||
      value.entity_id === base_table_entity_id
  )

  const current_view_label: string = is_default_view
    ? BASE_VIEW_LABEL
    : views_for_table[current_view_entity_id].name

  const on_close = useCallback(() => {
    set_view_selector_opened(false)
  }, [])

  const change_view = useCallback(
    (view_entity_id: TableEntityId) => {
      redirect_to_view(view_entity_id)
      on_close()
    },
    [on_close, redirect_to_view]
  )

  const list_items: JSX.Element[] = _.values(views_for_table).map((view: EntityHeader) => {
    const {name, entity_id} = view

    return (
      <ListItem
        button
        key={entity_id}
        selected={current_view_entity_id === entity_id}
        onClick={() => change_view(entity_id)}
      >
        <ListItemText primary={entity_id === base_table_entity_id ? BASE_VIEW_LABEL : name} />
      </ListItem>
    )
  })

  const add_new_view = () => {
    const new_view_entity_id = uuid()
    dispatch_storage(
      create_new_view_table(
        new_view_entity_id,
        zone_id,
        base_table_entity_id,
        current_view_entity_id!,
        {name: NEW_VIEW_LABEL}
      )
    )
    change_view(new_view_entity_id)
  }
  const can_edit = !history_mode && table?._can_edit_schema()
  const can_create_view = !history_mode && table?._can_create_view()

  return (
    // Displayed view name and dropdown button
    <ViewDisplayContainer>
      <Tooltip
        title={
          !can_edit
            ? 'select view'
            : is_default_view
            ? 'select view or use right-click to edit table name'
            : 'select view or use right-click to edit view name'
        }
      >
        <span
          ref={anchor_ref}
          onContextMenu={(e) => {
            if (can_edit) {
              e.preventDefault()
              if (current_view_entity_id != null) {
                set_view_name_editor_opened(true)
              }
            }
          }}
        >
          <CustomButton
            type="tertiary"
            onClick={() => set_view_selector_opened(true)}
            endIcon={<ExpandMoreIcon />}
          >
            <StyledTypography>{current_view_label}</StyledTypography>
          </CustomButton>
        </span>
      </Tooltip>
      {/*View selector*/}
      <Popover
        anchorEl={anchor_ref.current}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={view_selector_opened}
        onClose={on_close}
        classes={popover_classes}
      >
        <Card>
          <StyledCardContent>
            <List component="nav" aria-label="main mailbox folders">
              {list_items}
            </List>
          </StyledCardContent>
          <CardActions>
            {can_create_view && (
              <Button fullWidth color="primary" onClick={add_new_view}>
                Create view
              </Button>
            )}
          </CardActions>
        </Card>
      </Popover>
      {/* View name editor*/}
      <Popover
        open={view_name_editor_opened}
        onClose={() => {
          set_view_name_editor_opened(false)
        }}
        anchorEl={anchor_ref.current}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        classes={popover_classes}
      >
        <EntitySettingsEditor
          entity_id={current_view_entity_id}
          end_edit={() => {
            set_view_name_editor_opened(false)
          }}
        />
      </Popover>
    </ViewDisplayContainer>
  )
}

export default SetView
