export type date_locale = 'dmy' | 'mdy'
export const DATE_DEFAULT_LOCALE = 'dmy'
//ISO date format
export const DATE_DEFAULT_TEMPLATE = 'yyyy-MM-dd'

export type TimeFormatParameters = {
  template: string
}

export type TimeFormatItem = {
  name: string
  parameters: TimeFormatParameters
}

export type TimeFormatId = string

export const TIME_FORMATS: Record<TimeFormatId, TimeFormatItem> = {
  'HH:mm': {
    name: 'HH:mm (24h)',
    parameters: {template: 'HH:mm'},
  },
  'HH:mm:ss': {
    name: 'HH:mm:ss (24h)',
    parameters: {template: 'HH:mm:ss'},
  },
  'hh:mm a': {
    name: 'hh:mm AM/PM',
    parameters: {template: 'hh:mm a'},
  },
  'hh:mm:ss a': {
    name: 'hh:mm:ss AM/PM',
    parameters: {template: 'hh:mm:ss a'},
  },
}

export type DateFormatParameters = {
  template: string
  locale: date_locale
}

export type DateFormatItem = {
  name: string
  parameters: DateFormatParameters
  show: boolean
}

export type DateFormatId = string

export const DATE_FORMATS: Record<DateFormatId, DateFormatItem> = {
  'yyyy/M/d': {
    name: '2020/2/29',
    parameters: {
      template: 'yyyy/M/d',
      locale: 'dmy',
    },
    show: false,
  },
  'yyyy/MM/dd': {
    name: '2020/02/29',
    parameters: {
      template: 'yyyy/MM/dd',
      locale: 'dmy',
    },
    show: true,
  },
  'dd/M/yyyy': {
    name: '29/2/2020',
    parameters: {
      template: 'dd/M/yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd/MM/yyyy': {
    name: '29/02/2020',
    parameters: {
      template: 'dd/MM/yyyy',
      locale: 'dmy',
    },
    show: true,
  },
  'd. M. yyyy': {
    name: '29. 2. 2020',
    parameters: {
      template: 'd. M. yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd. MM. yyyy': {
    name: '29. 02. 2020',
    parameters: {
      template: 'dd. MM. yyyy',
      locale: 'dmy',
    },
    show: true,
  },
  'd.M.yyyy': {
    name: '29.2.2020',
    parameters: {
      template: 'd.M.yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd.MM.yyyy': {
    name: '29.02.2020',
    parameters: {
      template: 'dd.MM.yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'M/d/yyyy': {
    name: '2/29/2020 (US)',
    parameters: {
      template: 'M/d/yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'MM/dd/yyyy': {
    name: '02/29/2020 (US)',
    parameters: {
      template: 'MM/dd/yyyy',
      locale: 'mdy',
    },
    show: true,
  },
  'M. d. yyyy': {
    name: '2. 29. 2020 (US)',
    parameters: {
      template: 'M. d. yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'MM. dd. yyyy': {
    name: '02. 29. 2020 (US)',
    parameters: {
      template: 'MM. dd. yyyy',
      locale: 'mdy',
    },
    show: true,
  },
  'M.d.yyyy': {
    name: '2.29.2020 (US)',
    parameters: {
      template: 'M.d.yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'MM.dd.yyyy': {
    name: '02.29.2020 (US)',
    parameters: {
      template: 'MM.dd.yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'LLLL d, yyyy': {
    name: 'February 9, 2020',
    parameters: {
      template: 'LLLL d, yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'LLLL dd, yyyy': {
    name: 'February 09, 2020',
    parameters: {
      template: 'LLLL dd, yyyy',
      locale: 'mdy',
    },
    show: true,
  },
  'LLLL d yyyy': {
    name: 'February 9 2020',
    parameters: {
      template: 'LLLL d yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'LLLL dd yyyy': {
    name: 'February 09 2020',
    parameters: {
      template: 'LLLL dd yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'd LLLL, yyyy': {
    name: '9 February, 2020',
    parameters: {
      template: 'd LLLL, yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLLL, yyyy': {
    name: '09 February, 2020',
    parameters: {
      template: 'dd LLLL, yyyy',
      locale: 'dmy',
    },
    show: true,
  },
  'd LLLL yyyy': {
    name: '9 February 2020',
    parameters: {
      template: 'd LLLL yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLLL yyyy': {
    name: '09 February 2020',
    parameters: {
      template: 'dd LLLL yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'LLL d, yyyy': {
    name: 'Feb 9, 2020',
    parameters: {
      template: 'LLL d, yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'LLL dd, yyyy': {
    name: 'Feb 09, 2020',
    parameters: {
      template: 'LLL dd, yyyy',
      locale: 'mdy',
    },
    show: true,
  },
  'LLL d yyyy': {
    name: 'Feb 9 2020',
    parameters: {
      template: 'LLL d yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'LLL dd yyyy': {
    name: 'Feb 09 2020',
    parameters: {
      template: 'LLL dd yyyy',
      locale: 'mdy',
    },
    show: false,
  },
  'd LLL, yyyy': {
    name: '9 Feb, 2020',
    parameters: {
      template: 'd LLL, yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLL, yyyy': {
    name: '09 Feb, 2020',
    parameters: {
      template: 'dd LLL, yyyy',
      locale: 'dmy',
    },
    show: true,
  },
  'd LLL yyyy': {
    name: '9 Feb 2020',
    parameters: {
      template: 'd LLL yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLL yyyy': {
    name: '09 Feb 2020',
    parameters: {
      template: 'dd LLL yyyy',
      locale: 'dmy',
    },
    show: false,
  },
  'd. M.': {
    name: '29. 2.',
    parameters: {
      template: 'd. M.',
      locale: 'dmy',
    },
    show: false,
  },
  'dd. MM.': {
    name: '29. 02.',
    parameters: {
      template: 'dd. MM.',
      locale: 'dmy',
    },
    show: true,
  },
  'd. LLLL': {
    name: '9. February',
    parameters: {
      template: 'd. LLLL',
      locale: 'dmy',
    },
    show: false,
  },
  'dd. LLLL': {
    name: '09. February',
    parameters: {
      template: 'dd. LLLL',
      locale: 'dmy',
    },
    show: true,
  },
  'd LLLL': {
    name: '9 February',
    parameters: {
      template: 'd LLLL',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLLL': {
    name: '09 February',
    parameters: {
      template: 'dd LLLL',
      locale: 'dmy',
    },
    show: false,
  },
  'd. LLL': {
    name: '9. Feb',
    parameters: {
      template: 'd. LLL',
      locale: 'dmy',
    },
    show: false,
  },
  'dd. LLL': {
    name: '09. Feb',
    parameters: {
      template: 'dd. LLL',
      locale: 'dmy',
    },
    show: true,
  },
  'd LLL': {
    name: '9 Feb',
    parameters: {
      template: 'd LLL',
      locale: 'dmy',
    },
    show: false,
  },
  'dd LLL': {
    name: '09 Feb',
    parameters: {
      template: 'dd LLL',
      locale: 'dmy',
    },
    show: false,
  },
  'eeeeee dd-LLL-yyyy': {
    name: 'Su 09-Feb-2020',
    parameters: {
      template: 'EEEEEE dd-LLL-yyyy',
      locale: 'dmy',
    },
    show: true,
  },
  'yyyy-MM-dd': {
    name: '2020-02-09',
    parameters: {
      template: 'yyyy-MM-dd',
      locale: 'dmy',
    },
    show: false,
  },
}

export const get_date_time_format_combinations = (
  date_formats: DateFormatParameters[],
  time_formats: TimeFormatParameters[]
): DateFormatParameters[] => {
  const combinations: DateFormatParameters[] = []
  for (const time_format of time_formats) {
    for (const date_format of date_formats) {
      combinations.push(
        {template: `${date_format.template} ${time_format.template}`, locale: date_format.locale},
        {template: `${time_format.template} ${date_format.template}`, locale: date_format.locale}
      )
    }
  }
  return combinations
}

export const DATE_FORMATS_PARAMS = Object.values(DATE_FORMATS).map((format) => format.parameters)
const TIME_FORMATS_PARAMS = Object.values(TIME_FORMATS).map((format) => format.parameters)

export const DATETIME_FORMATS_PARAMS: DateFormatParameters[] = TIME_FORMATS_PARAMS.map(
  ({template}): DateFormatParameters => ({template, locale: DATE_DEFAULT_LOCALE})
).concat(
  DATE_FORMATS_PARAMS,
  get_date_time_format_combinations(DATE_FORMATS_PARAMS, TIME_FORMATS_PARAMS)
)

export const get_date_format = (
  format_id: DateFormatId | undefined
): DateFormatParameters | undefined => (format_id ? DATE_FORMATS[format_id]?.parameters : undefined)

export const get_time_format = (
  format_id: TimeFormatId | undefined
): TimeFormatParameters | undefined => (format_id ? TIME_FORMATS[format_id]?.parameters : undefined)
