import React, {useState, useEffect} from 'react'
import {measure_performance} from './measure_performance'

type UnpackPromise<T> = T extends Promise<infer U> ? U : T
type PerformanceResults = UnpackPromise<ReturnType<typeof measure_performance>>

const PerformanceDisplay = () => {
  const [results, set_results] = useState<PerformanceResults | null>(null)
  useEffect(() => {
    measure_performance().then((p) => set_results(p))
  }, [])
  return <pre>{results ? JSON.stringify(results, null, '  ') : 'Loading'}</pre>
}

export default PerformanceDisplay
