import React, {FC, useCallback, useRef, useState} from 'react'
import {Popover} from '@material-ui/core'
import {stringType} from 'aws-sdk/clients/iam'

import {EntityId, EntityType, TableSubtype} from 'common/types/storage'
import {
  has_write_permissions,
  is_permission_table_subtype,
} from 'common/permission/permission_utils'

import GalleryListItem from '../GalleryListItem'
import EntitySettingsEditor from '../EntitySettingsEditor'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import _ from 'lodash'
import {is_entity_or_parent_archived} from 'common/archived_utils'
import {useToggleArchived} from '../navigation/toggle_archived_hook'

type EntityListItemProps = {
  entity_id: EntityId
  parent_id: EntityId
  subtype?: TableSubtype
  label: string
  href: string
  description?: string
  type: stringType
}

export const EntityListItem: FC<EntityListItemProps> = ({
  entity_id,
  parent_id,
  subtype,
  label,
  href,
  description,
  type,
}) => {
  const {
    runtime,
    storage: {multidiff, entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const anchor = useRef(null)
  const [open, set_open] = useState(false)

  let can_edit = !is_permission_table_subtype(subtype) && has_write_permissions(runtime, entity_id)
  let has_unsaved_changes = !!(multidiff[parent_id] && multidiff[parent_id][entity_id])
  if (type === 'project') {
    can_edit = has_write_permissions(runtime, entity_id)
    has_unsaved_changes = !_.isEmpty(multidiff[entity_id])
  }

  const [is_toggling_archived_status, start_unarchiving] = useToggleArchived({
    on_error(error: {message: string; severity?: 'error' | 'success' | 'info' | 'warning'}): void {
      // eslint-disable-next-line no-alert
      alert(error.message)
    },
    on_redirect(data: {type: EntityType; entity_id: EntityId}): void {},
    entity: entity_headers[entity_id],
  })

  const start_edit = useCallback(() => {
    set_open(true)
  }, [])

  const end_edit = useCallback(() => {
    set_open(false)
  }, [])

  return (
    <>
      <GalleryListItem
        ref={anchor}
        on_edit={can_edit ? start_edit : undefined}
        on_unarchive={can_edit && !is_toggling_archived_status ? start_unarchiving : undefined}
        key={entity_id}
        subtype={subtype}
        label={label}
        hasUnsavedChanges={has_unsaved_changes}
        is_archived={!!entity_headers[entity_id].archived}
        is_parent_archived={is_entity_or_parent_archived(parent_id, entity_headers)}
        description={description}
        href={href}
        type={type}
        can_edit={can_edit}
        entity_id={entity_id}
      />
      <Popover
        open={open}
        onClose={end_edit}
        anchorEl={anchor.current}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <EntitySettingsEditor entity_id={entity_id} end_edit={end_edit} />
      </Popover>
    </>
  )
}
