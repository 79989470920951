import React from 'react'
import _ from 'lodash'

import ColSettingsModal from './ColSettingsModal'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'

type ViewColumnModalProps = {
  col_id: string
  on_request_close: () => void
}

const ViewColumnModal = (props: ViewColumnModalProps) => {
  const {col_id, on_request_close} = props
  const {
    storage: {entity_headers, resources},
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  return (
    <ColSettingsModal
      table={table}
      edit_flag={'readonly'}
      on_submit={_.noop}
      on_request_close={on_request_close}
      entity_headers={entity_headers}
      resources={resources}
      col_id={col_id}
    />
  )
}

export default ViewColumnModal
