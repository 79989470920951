import {useState} from 'react'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {EntityId, EntityType, Resource, ResourceId, ZoneDiff, ZoneId} from 'common/types/storage'
import {ActionPayload} from 'common/dispatch'
import {is_new_entity} from 'common/entities/diff_utils'
import {open_modal} from '../Modals'
import _ from 'lodash'
import {useDispatch} from 'react-redux'

export type DiscardChangesHook = [discarding: boolean, start_discarding: () => void]

export const useDiscardChanges = ({
  on_redirect,
  on_error,
}: {
  on_redirect: (data: {type: EntityType; entity_id: EntityId}) => void
  on_error: (error: {message: string; severity?: 'error' | 'success' | 'info' | 'warning'}) => void
}): DiscardChangesHook => {
  const [is_discarding, set_is_discarding] = useState<boolean>(false)

  const dispatch = useDispatch()
  const {dispatch_storage} = useRuntimeActions()
  const {
    resources: {project_resources, table_resources},
    storage: {multidiff, resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const get_parent_tables_resources = (
    resources: Record<ResourceId, Resource>,
    parent_id: EntityId
  ): Resource[] => {
    const table_types = ['computed_table', 'data_table', 'view_table']
    return Object.values(resources).filter(
      (resource) =>
        resource.result &&
        _.some(table_types, (type) => type === resource.result.type) &&
        resource.result.parent_id === parent_id
    )
  }

  const get_saved_table = (
    zone_tables: Resource[],
    zone_diff: ZoneDiff
  ): {
    entity_id: EntityId
    entity_type: EntityType
  } | null => {
    const next_saved_table = _.find(zone_tables, (table) => {
      const entity_diff = zone_diff[table.result.entity_id]
      return (
        _.isEmpty(entity_diff) ||
        (!_.isEmpty(entity_diff) && !is_new_entity(zone_diff[table.result.entity_id]))
      )
    })
    return next_saved_table
      ? {
          entity_id: next_saved_table.result.entity_id,
          entity_type: next_saved_table.result.type,
        }
      : null
  }

  const _discard = () => {
    set_is_discarding(true)

    const zone_id = project_resources ? project_resources.project.zone_id : table_resources.zone_id
    const project_tables = get_parent_tables_resources(resources, zone_id).filter(
      (resource) => resource.result.entity_id !== table_resources?.table_entity_id
    )
    const zone_diff = multidiff[zone_id]
    const entity_diff = table_resources
      ? zone_diff[table_resources.table_entity_id]
      : zone_diff[zone_id]

    const is_new = entity_diff && is_new_entity(entity_diff)

    if (is_new) {
      const redirect_to = table_resources
        ? get_saved_table(project_tables, multidiff[zone_id])
        : null
      on_redirect({
        type: redirect_to?.entity_type ?? 'organisation',
        entity_id: redirect_to?.entity_id ?? project_resources.project.parent_id,
      })
    }
    const discard_result: {
      zone_id: ZoneId
    } = {
      zone_id: project_resources ? project_resources?.project_id : table_resources?.zone_id,
    }
    if (!discard_result.zone_id) {
      on_error({message: 'Discarding was unsuccessful, please try again', severity: 'error'})
      set_is_discarding(false)
      return
    }
    const ap: ActionPayload = {type: 'discard-changes', ...discard_result}
    dispatch_storage(ap)
    set_is_discarding(false)
  }

  const on_prediscard = () => {
    dispatch(open_modal('confirm_discard', {on_discard: _discard}))
  }

  const run_discarding = () => {
    if (is_discarding) {
      return
    }
    on_prediscard()
  }
  return [is_discarding, run_discarding]
}
