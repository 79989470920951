import React, {FC, ReactNode} from 'react'
import {Button, styled} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

type ButtonProps = {
  onClick?: (e?: any) => void
  disabled?: boolean
  type: 'primary' | 'secondary' | 'tertiary'
  size?: 'small' | 'medium' | 'large'
  children: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  fullWidth?: boolean
  disableElevation?: boolean
  onlyIcon?: boolean
}

const StyledButton = styled(Button)({
  minWidth: 'auto',
  borderRadius: 4,
  whiteSpace: 'nowrap',
})

const useStyles = makeStyles((theme) => ({
  button: (props: {type: string; onlyIcon?: boolean}) => ({
    'width': props.onlyIcon ? 30 : 'auto',
    'height': 30,
    'fontSize': 14,
    'backgroundColor':
      props.type === 'secondary'
        ? `${theme.palette.common.white}`
        : props.type === 'tertiary'
        ? 'transparent'
        : undefined,
    'color':
      props.type === 'secondary'
        ? `${theme.palette.primary.main}`
        : props.type === 'tertiary'
        ? `${theme.palette.greyPalette[400]}`
        : undefined,
    'borderColor': props.type === 'tertiary' ? `${theme.palette.greyPalette[100]}` : undefined,
    '&:hover': {
      borderColor: `${props.type === 'tertiary' && `${theme.palette.greyPalette[400]} !important`}`,
    },
    '&:focus': {
      borderColor: `${props.type === 'tertiary' && `${theme.palette.greyPalette[100]} !important`}`,
    },
  }),
}))

const CustomButton: FC<ButtonProps> = ({
  onClick,
  disabled,
  type,
  size = 'small',
  fullWidth,
  children,
  startIcon,
  endIcon,
  disableElevation,
  onlyIcon,
}) => {
  const classes = useStyles({type, onlyIcon})
  return (
    <StyledButton
      variant={type === 'tertiary' ? 'outlined' : 'contained'}
      size={size}
      fullWidth={fullWidth}
      color={type === 'primary' ? 'primary' : 'inherit'}
      onClick={onClick}
      disabled={disabled}
      className={classes.button}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={disableElevation}
    >
      {children}
    </StyledButton>
  )
}

export default CustomButton
