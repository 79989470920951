import React, {FC, useCallback, useMemo} from 'react'
import classNames from 'classnames'
import {is_error} from 'common/error'
import styles from './CellDetail.module.css'
import ViewOnlyCellDetail from './ViewOnlyCellDetail'
import EditableCellDetail from './EditableCellDetail'
import {get_diff_style} from '../table_data_helpers'
import {ColumnId, RowId} from 'common/types/storage'
import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
  useZoneUiSelector,
} from '../utils/connect_hocs'

type CellDetailProps = {
  col_id: ColumnId
  row_id: RowId
  focused: boolean
  on_show_tooltips: (col_id: ColumnId) => void
}

const CellDetail: FC<CellDetailProps> = ({col_id, row_id, focused, on_show_tooltips}) => {
  const {
    storage: {history_mode},
    resources: {
      table_resources: {detailed_view_table, detailed_view_diff, table_entity_id, zone_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {name, type} = detailed_view_table._cols[col_id]
  const set_table_ui_state = useSetTableUiState(detailed_view_table._entity_id)
  const cell = detailed_view_table._cell(row_id, col_id)
  const view_editing = useTableUiSelector(detailed_view_table._entity_id, 'view_editing')
  const show_changes = useZoneUiSelector(zone_id, 'sidebar') === 'history'
  const is_editable = !history_mode && detailed_view_table._can_edit_cell(col_id)

  const edit_mode = view_editing && view_editing.row_id === row_id && view_editing.col_id === col_id

  // user defined style
  const cell_style = detailed_view_table._get_style(row_id, col_id)
  const style = is_error(cell_style) ? {} : cell_style

  // styles for the detail
  const color = cell.error ? styles.borderErr : styles.borderOk
  const focus = focused ? styles.focused : {}
  const detail = edit_mode ? styles.editDetail : styles.viewDetail

  // diff indicator style
  const diff_style = useMemo(
    () => (show_changes ? get_diff_style(detailed_view_diff, row_id, col_id) : {}),
    [detailed_view_diff, row_id, col_id, show_changes]
  )

  const start_editing = useCallback(() => {
    is_editable && set_table_ui_state({view_editing: {row_id, col_id}})
  }, [col_id, is_editable, row_id, set_table_ui_state])

  const handle_on_mouse_over = useCallback(() => {
    on_show_tooltips(col_id)
  }, [col_id, on_show_tooltips])

  const get_detail = () => {
    return edit_mode ? (
      <EditableCellDetail
        detailed_view_table={detailed_view_table}
        table_entity_id={detailed_view_table._entity_id}
        col_id={col_id}
        row_id={row_id}
      />
    ) : (
      <ViewOnlyCellDetail table_entity_id={table_entity_id} cell={cell} col_type={type} />
    )
  }

  return (
    <div
      key={col_id}
      className={classNames(detail, color, focus)}
      style={style}
      onMouseEnter={handle_on_mouse_over}
      onDoubleClick={start_editing}
    >
      <div className={styles.indicator} style={diff_style} />
      <div className={styles.columnName}>{name}</div>
      {get_detail()}
      {is_editable && focused && !edit_mode && (
        <button className={styles.editButton} onClick={start_editing}>
          <i className="fas fa-pencil-alt" />
        </button>
      )}
    </div>
  )
}

export default CellDetail
