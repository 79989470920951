import col_diff from '../diffs/models/col_diff'
import ord_set_diff from '../diffs/base/ordered_set_diff'
import _ from 'lodash'
import row_diff from '../diffs/models/row_diff'
import value_diff from '../diffs/base/value_diff'
import data_table_diff from 'common/diffs/models/data_table_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import {ensure} from 'common/utils'
import {
  Cells,
  CellType,
  ColumnId,
  ColumnSpec,
  OrderedSetDiff,
  RowDiff,
  RowId,
  SortField,
  SourceColumnSpec,
  DataTable,
  ViewTable,
  ComputedTable,
  TableSubtype,
} from 'common/types/storage'
import {TableData} from '../objects/data_table'
import {Conflict} from '../types/data_table'
import {default_cols_ids, has_default_columns} from 'common/entities/data_table'
import {ANONYMOUS_USER} from 'common/types/user'
import map_diff from 'common/diffs/base/map_diff'
import param_diff from 'common/diffs/models/param_diff'
import {ComputedParamsSpec} from 'common/params_utils'

const ordered_set_diff = ord_set_diff()
const order_by_diff = value_diff<SortField[]>()

// function that creates base for row_diff creation
const _get_row_base = (row_id: RowId, cells: Cells<'entity'>) => {
  const {slots, data} = cells
  const base = _.zipObject(_.keys(slots), data[row_id])

  return {...base, keep: true}
}

// nullifies values in col_ids of row
// if col_ids = null, removes whole row
const _nullify_values_in_row = (
  row_id: RowId,
  cells: Cells<'entity'>,
  col_ids: ColumnId[] | null
): RowDiff => {
  const base = _get_row_base(row_id, cells)
  const diff_base = col_ids ? _.pick(base, col_ids) : base
  return row_diff.remove_diff(diff_base)
}

const _invalids_to_autoresolve_diff = (
  invalids: Conflict[],
  {type, cols_order = []}: DataTable<'entity'>
): DataTable<'diff'> => {
  // Union of invalid.data
  const invalid_data: Partial<DataTable<'entity'>> = _.merge(
    {},
    ...invalids.map((invalid) => invalid.data)
  )
  /*
  Removes invalid cols, rows and cells.
  Tricky part is to remove invalid columns from cols_order because of ordered set diff.
  If invalid data contains cols_order, it cannot be changed to null, like the rest of the data
  with value diff on leafs (this would remove both invalid and valid cols),
  but it needs to be changed to valid cols_order.
   */
  return data_table_diff._change_diff(
    {type, ...invalid_data},
    _.has(invalid_data, ['cols_order']) ? {type, cols_order} : {type}
  )
}

const get_cell_diffs = ({cells, subtype}: {cells: Cells<'entity'>; subtype?: TableSubtype}) => {
  // return diff for cells
  const diff_for_cells = (cells: Cells<'diff'>): DataTable<'diff'> => {
    return {
      type: 'data_table',
      cells,
    }
  }

  // return diff with nullified cells in rows
  const diff_remove_rows = (row_ids: RowId[]): DataTable<'diff'> => {
    const nullified_cells = _.fromPairs(
      row_ids.map((row_id) => [row_id, _nullify_values_in_row(row_id, cells, null)])
    )
    return diff_for_cells(nullified_cells)
  }

  const diff_for_cells_autoresolve = (): DataTable<'diff'> => {
    const data_cells_map = {type: 'data_table', cells: data_table_diff.convert_cells_to_map(cells)}
    const conflict_free_data = data_table_diff.conflict_free(data_cells_map)
    const conflicts_cells_autoresolve_diff = data_table_diff.clean_neutral_diffs(
      data_table_diff._change_diff(data_cells_map, conflict_free_data)
    )
    return conflicts_cells_autoresolve_diff
  }

  const update_default_columns_in_diff = (
    diff: DataTable<'diff'>,
    user_email: string | undefined
  ): DataTable<'diff'> => {
    if (!has_default_columns(subtype)) {
      return diff
    }

    const {data, slots} = cells
    const timestamp = new Date().getTime()
    const user = user_email || ANONYMOUS_USER

    const updated_cells = _.mapValues(diff.cells, (row_diff, row_id) => {
      const old_last_edited_at = data[row_id]
        ? data[row_id][slots[default_cols_ids.last_edited_at]]
        : null
      const old_last_edited_by = data[row_id]
        ? data[row_id][slots[default_cols_ids.last_edited_by]]
        : null

      return {
        ...row_diff,
        [default_cols_ids.last_edited_at]: value_diff().change_diff(old_last_edited_at, timestamp),
        [default_cols_ids.last_edited_by]: value_diff().change_diff(old_last_edited_by, user),
      }
    })

    return {
      ...diff,
      cells: updated_cells,
    }
  }

  return {
    diff_for_cells,
    diff_remove_rows,
    diff_for_cells_autoresolve,
    update_default_columns_in_diff,
  }
}

const get_schema_diffs = (data: TableData, invalids: Conflict[]) => {
  const {type} = data
  const {cols_order, cols, order_by, label, name, cells, source_cols} = (type === 'view_table'
    ? data.view_entity
    : data) as TableData & ViewTable<'entity'>

  // diff for cols
  const diff_for_cols = (
    cols: Record<ColumnId, ColumnSpec<CellType, 'diff'>>
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    return {
      type,
      cols,
    }
  }

  // diff for number of frozen cols
  const diff_for_frozen_cols = (new_frozen_cols: number): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    const {frozen_cols} = data
    const diff = conflict_free_value_diff<number>().change_diff(frozen_cols, new_frozen_cols)
    return {
      type,
      frozen_cols: diff,
    }
  }

  // return diff for col_spec
  // if new spec is not defined, remove column
  const diff_for_col_spec = (
    col_id: ColumnId,
    new_spec: ColumnSpec<CellType, 'entity'> | null
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    const old_col_spec = cols[col_id]
    const col_spec_diff = col_diff.clean_neutral_diffs(
      col_diff.change_diff(old_col_spec, new_spec)
    ) as ColumnSpec<CellType, 'diff'>

    return diff_for_cols({[col_id]: col_spec_diff})
  }

  const diff_for_col_width = (
    col_id: ColumnId,
    new_width: number
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    const old_width = cols[col_id].width || null
    const diff = {
      width: conflict_free_value_diff<number>().change_diff(old_width, new_width),
    }
    return diff_for_cols({[col_id]: diff})
  }

  const diff_for_col_hidden = (
    col_id: ColumnId,
    hidden: boolean
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    const old_hidden = cols[col_id].hidden || null
    const diff = {
      hidden: conflict_free_value_diff<boolean>().change_diff(old_hidden, hidden),
    }
    return diff_for_cols({[col_id]: diff})
  }

  const diff_for_cols_order = (
    new_cols_order: ColumnId[]
  ): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    return {
      type,
      cols_order: ordered_set_diff.change_diff(cols_order, new_cols_order) as OrderedSetDiff<
        ColumnId
      >,
    }
  }

  const diff_for_order_by = (new_order_by: SortField[]): DataTable<'diff'> | ViewTable<'diff'> => {
    ensure(type !== 'computed_table', 'Trying to create diff from wrong table type', {type})
    return {
      type,
      order_by: order_by_diff.change_diff(order_by, new_order_by),
    }
  }

  const diff_for_scheme_autoresolve = (): DataTable<'diff'> => {
    ensure(type === 'data_table', 'Trying to create diff from wrong table type', {type})
    const data = {
      type,
      name,
      label,
      cols,
      cols_order,
    }
    const conflict_free_data = data_table_diff.conflict_free(data)
    const invalids_autoresolve_diff = _invalids_to_autoresolve_diff(invalids, conflict_free_data)
    const conflicts_scheme_autoresolve_diff = data_table_diff.clean_neutral_diffs(
      data_table_diff._change_diff(data, conflict_free_data)
    )
    return _.merge(invalids_autoresolve_diff, conflicts_scheme_autoresolve_diff)
  }

  // return diff with nullified cells in columns
  const diff_nullify_cells_in_columns = (col_ids: ColumnId[]): DataTable<'diff'> => {
    ensure(type === 'data_table', 'Trying to create diff from wrong table type', {type})
    const row_ids = Object.keys(cells.data)
    const nullified_cells = _.fromPairs(
      row_ids
        .map((row_id) => [row_id, _nullify_values_in_row(row_id, cells, col_ids)])
        .filter(([, diff]) => !_.isEmpty(diff))
    )
    const {diff_for_cells} = get_cell_diffs(data)
    return diff_for_cells(nullified_cells)
  }

  const diff_for_source_cols = (
    source_cols: Record<ColumnId, SourceColumnSpec<CellType, 'diff'>>
  ): ViewTable<'diff'> => {
    ensure(type === 'view_table', 'Trying to create diff from wrong table type', {type})
    return {
      type,
      source_cols,
    } as ViewTable<'diff'>
  }

  // note: 'width' and 'hidden' are overridable too and are diffed separately
  const COL_SPEC_OVERRIDABLE_FIELDS = [
    'style',
    'tooltip',
    'summary',
    'type.number_format_id',
    'type.date_format_id',
    'type.time_format_id',
    'type.boolean_format_id',
  ]

  const diff_for_col_spec_override = (
    col_id: ColumnId,
    new_spec: ColumnSpec<CellType, 'entity'>
  ) => {
    ensure(type === 'view_table', 'Trying to create diff from wrong table type', {type})
    const old_col_spec = source_cols[col_id]

    // check if non-overridable fields changed
    const extra_fields = _(
      col_diff.clean_neutral_diffs(col_diff.change_diff(data.cols[col_id], new_spec))
    )
      .omit(COL_SPEC_OVERRIDABLE_FIELDS)
      // note: the `type: {}` will remain after omiting 'type.number/date_format' nested props
      .omitBy((value, key) => key === 'type' && _.isEmpty(value))
      .value()

    ensure(_.isEmpty(extra_fields), 'Trying to change non-overridable fields', extra_fields)

    const col_spec_diff = col_diff.change_diff(old_col_spec, new_spec)
    const override_diff = col_diff.clean_neutral_diffs(
      _.pick(col_spec_diff, COL_SPEC_OVERRIDABLE_FIELDS)
    ) as SourceColumnSpec<CellType, 'diff'>

    return diff_for_source_cols({[col_id]: override_diff})
  }

  const diff_for_col_width_override = (col_id: ColumnId, new_width: number) => {
    ensure(type === 'view_table', 'Trying to create diff from wrong table type', {type})
    const old_width = source_cols[col_id]?.width || null
    const diff = {
      width: conflict_free_value_diff().change_diff(old_width, new_width),
    }
    return diff_for_source_cols({[col_id]: diff})
  }

  const diff_for_col_hidden_override = (col_id: ColumnId, hidden: boolean) => {
    ensure(type === 'view_table', 'Trying to create diff from wrong table type', {type})
    const old_hidden = source_cols[col_id]?.hidden || null
    const diff = {
      hidden: conflict_free_value_diff().change_diff(old_hidden, hidden),
    }
    return diff_for_source_cols({[col_id]: diff})
  }

  const diff_for_table_fn = (new_fn: string) => {
    ensure(type === 'computed_table', 'Trying to create diff from wrong table type', {type})
    return {
      type,
      fn: value_diff().change_diff(data.computed_table_entity!.fn, new_fn),
    } as ComputedTable<'diff'>
  }

  const diff_for_table_logging = (new_logging: boolean) => {
    ensure(type === 'computed_table', 'Trying to create diff from wrong table type', {type})
    const old_log_val = data.computed_table_entity?.log_computation || null
    return {
      type,
      log_computation: value_diff().change_diff(old_log_val, new_logging),
    } as ComputedTable<'diff'>
  }

  const diff_for_params = (params?: ComputedParamsSpec) => {
    ensure(type === 'computed_table', 'Trying to add param diff to wrong table type', {type})
    const old_params = data.computed_table_entity?.params || {}
    const params_entries = _.entries(params)
    const normalized_params = _.reduce(
      params_entries,
      (result, [id, data]) => {
        if (data.type.type === 'option') {
          data.type.options = _.reduce(
            _.entries(data.type.options),
            (result, [id, option]) => ({
              ...result,
              [id]: {name: option.name},
            }),
            {}
          )
        }
        return {
          ...result,
          [id]: data,
        }
      },
      {}
    )
    const new_params = map_diff(param_diff).change_diff(old_params, normalized_params)
    return {
      type,
      params: new_params,
    } as ComputedTable<'diff'>
  }

  return {
    // for data and view table
    diff_for_col_spec,
    diff_for_col_width,
    diff_for_col_hidden,
    diff_for_cols_order,
    diff_for_cols,
    diff_for_frozen_cols,
    diff_for_order_by,
    // for data table
    diff_for_scheme_autoresolve,
    diff_nullify_cells_in_columns,
    // for view table
    diff_for_source_cols,
    diff_for_col_spec_override,
    diff_for_col_width_override,
    diff_for_col_hidden_override,
    // for computed table
    diff_for_table_fn,
    diff_for_table_logging,
    diff_for_params,
  }
}

export {get_schema_diffs, get_cell_diffs}
