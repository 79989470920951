import React, {FC} from 'react'
import ContextMenu, {ContextMenuProps} from './ContextMenu'
import ArchiveEntityItem from './ArchiveEntityItem'
import {TableEntityId} from 'common/types/storage'

type TableContextMenuProps = ContextMenuProps & {
  entity_id: TableEntityId
}

const EntityContextMenu: FC<TableContextMenuProps> = ({pos, on_close, entity_id}) => {
  return (
    <>
      <ContextMenu pos={pos} on_close={on_close}>
        <ArchiveEntityItem on_close={on_close} entity_id={entity_id} />
      </ContextMenu>
    </>
  )
}

export default React.memo(EntityContextMenu)
