import {RowId, ColumnId} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {CursorCoordinates} from './connect_hocs'
import {Position} from './layout'

export const index_of_row = (rows_order: RowId[], row_id: RowId): number =>
  rows_order.indexOf(row_id)

export const index_of_col = (table: TableObject, col_id: ColumnId): number =>
  table._visible_cols_order.indexOf(col_id)

export const row_id_on_index = (rows_order: RowId[], row_index: number): RowId =>
  rows_order[row_index]

export const col_id_on_index = (table: TableObject, col_index: number): ColumnId =>
  table._visible_cols_order[col_index]

export const get_position = (
  {col_id, row_id}: CursorCoordinates,
  table: TableObject,
  rows_order: RowId[]
): Position => {
  const c = col_id === null ? -1 : index_of_col(table, col_id)
  const r = row_id === null ? -1 : index_of_row(rows_order, row_id)
  return [c + 1, r + 1]
}

export const get_coords = (
  [c, r]: Position,
  table: TableObject,
  rows_order: RowId[]
): CursorCoordinates => {
  const col_id = c > 0 ? col_id_on_index(table, c - 1) : null
  const row_id = r > 0 ? row_id_on_index(rows_order, r - 1) : null
  return {row_id, col_id}
}

//for each element of filtered_row_ids finds index of RowId in all_row_ids
//equivalent to :
//filtered_row_ids.map((row_id) => index_of_row(all_row_ids,row_id))
//since filtered subsequence has the same order of elements, it is sufficient
//to run one for loop with indexes to both arrays at the same time
export const get_filtered_row_indexes = (
  all_row_ids: RowId[],
  filtered_row_ids: RowId[]
): number[] => {
  const row_indexes: number[] = []
  row_indexes.length = filtered_row_ids.length
  for (let i = 0, j = 0; i < all_row_ids.length && j < filtered_row_ids.length; ++i) {
    if (all_row_ids[i] === filtered_row_ids[j]) {
      row_indexes[j] = i
      ++j
    }
  }
  return row_indexes
}
