import '@fortawesome/fontawesome-free/css/solid.css'
import theme from '../theme'

const cellHeight = 26

const { palette: { grid }, typography: { gridCell } } = theme

export const style = {
  color: {
    grid: grid.border,
    gridBg: grid.background,
    gridBorder: grid.border,
    defaultBg: grid.cell.defaultBackground,
    defaultText: grid.cell.defaultText,
    frozen: grid.background,
    selectionBorder: 'transparent',
    selectionOverlay: 'rgba(84, 54, 203, 0.1)',
    selectionOutline: '#5436CB',
  },
  font: {
    normal: `${gridCell.normal}`,
    mono: `${gridCell.mono}`,
    icon: `${gridCell.icon}`,
  },
  cellHeight,
  txtPadding: Math.round(0.3 * cellHeight),
  // line widths
  width: {
    grid: 1,
    gridBorder: 1,
    frozen: 3,
    outline: 1,
    padding: 1,
    mark: 7,
  },
}
