/**
 * @module ComputedResource
 */
import _ from 'lodash'
import {Runtime} from './create_runtime'
import {ensure, throw_error, normalize_name} from './utils'
import {parse_table_id, compose_table_id, ComputedParams, ComputedParamsSpec} from './params_utils'
import {eval_function, error_handling_decorator} from './eval_function'
import {create_error, is_error, MaybeError, throw_error_object} from './error'
import {conform_single_value_to_type} from './types'
import {
  CellType,
  ColumnId,
  ColumnData,
  ColumnSpec,
  ComputedTable,
  RowId,
  StyleData,
  SummaryData,
  TableId,
  TooltipData,
  ViewTable,
  ColumnResourceType,
} from './types/storage'
import {is_table} from './objects/table_utils'
import {TableData, TableObject, create_table} from './objects/data_table'
import computed_table_diff from './diffs/models/computed_table_diff'
import view_table_diff from 'common/diffs/models/view_table_diff'
import {get_overridden_data, create_view_table} from './objects/view_table'
import {get_computed_table_data, create_computed_table} from 'common/objects/computed_table'
import {SummaryComputeMethod, fn_to_compute_method} from 'common/summary/compute_methods'
import {eval_short_fn} from 'common/summary/eval_short_fn'
import {
  validate_cell_raw_value,
  validate_cell_style,
  validate_cell_tooltip,
  validate_column_data,
  ValueValidator,
} from './validation'
import {CellValue, MultiCellValue} from './types/data_table'
import {log_with_color} from '../client/src/utils/log_utils'

const with_get_param = (
  runtime: Runtime,
  {
    params,
    params_spec,
  }: {
    params?: ComputedParams
    params_spec?: ComputedParamsSpec
  }
) => {
  const get_param = (param_name: string, default_value: CellValue): MaybeError<CellValue> => {
    ensure(params_spec != null, 'Cannot ask for parameter within runtime with no param spec')
    const param_name_id = param_name
    if (params == null || !(param_name_id in params)) {
      return default_value
    }
    const param = params[param_name_id]

    const param_spec = _.find(params_spec, (p) => {
      return normalize_name(p.name) === param_name
    })

    ensure(param_spec != null, 'Unknown parameter', {param_name, params_spec})

    if (param_spec.type.multi) {
      if (param === null) return []
      ensure(Array.isArray(param), 'Parameter value must be array or null for multi spec', {
        param_name,
        params_spec,
        param,
      })

      const single_values = param.map((sub_param) => {
        ensure(sub_param != null && sub_param !== '', 'Cannot have empty subvalue in multi param')
        const value = conform_single_value_to_type(runtime, sub_param, param_spec.type)
        return is_error(value) ? throw_error_object(value) : value
      })

      return single_values as MultiCellValue<'option'> | MultiCellValue<'reference'>
    }

    ensure(!Array.isArray(param), 'Parameter cannot be an array', {
      param_name_id,
      params_spec,
      param,
    })
    const value = conform_single_value_to_type(runtime, param, param_spec.type)
    return is_error(value) ? throw_error_object(value) : value
  }

  return {params, params_spec, get_param}
}

const execute_value = (runtime: Runtime, table: TableObject, fn_string: string) => {
  const fn = eval_function(fn_string)
  return fn(runtime, {table})
}

const TYPE_TO_VALIDATOR = {
  column: validate_cell_raw_value,
  style: validate_cell_style,
  tooltip: validate_cell_tooltip,
}

const COLUMN_RESOURCE_TYPES: ColumnResourceType[] = ['column', 'style', 'tooltip']

/**
 * Function fn should be stored as a string, which is passed into eval and applied on table.
 * contract for fn should be: ({...runtime, table}) => ({[row_id]: value})
 * @function
 * @param runtime {Runtime}
 * @param table {TableObject}
 * @param fn_string {TableObject}
 * @param [type] {ColumnResourceType}
 * @param [multi] {boolean}
 * @return {MaybeError<Record<RowId, T>>}
 */
const execute_column = <T extends any>(
  runtime: Runtime,
  table: TableObject,
  fn_string: string,
  type?: ColumnResourceType,
  multi?: boolean
): MaybeError<Record<RowId, T>> => {
  const validator = type ? (TYPE_TO_VALIDATOR[type] as ValueValidator<T>) : (value: T): T => value
  const column = execute_value(runtime, table, fn_string)
  // return object with value exactly for each row_id, error otherwise
  return validate_column_data(column, table, validator, multi)
}

export type TableResourceIdParts = {
  table_id: TableId
}

export type ColumnResourceIdParts = TableResourceIdParts & {
  column_id: ColumnId
}

type GetTableFunction = (
  runtime: Runtime,
  resource_id_parts: TableResourceIdParts
) => MaybeError<TableObject>

type GetTableDataFunction = (
  runtime: Runtime,
  resource_id_parts: TableResourceIdParts
) => MaybeError<TableData>

type GetColumnRowsFunction<T> = (
  runtime: Runtime,
  resource_id_parts: ColumnResourceIdParts
) => MaybeError<T>

type GetSummaryRowValuesFunction = (
  runtime: Runtime,
  resource_id_parts: TableResourceIdParts
) => MaybeError<SummaryData>

export type ComputeResource = {
  table: GetTableFunction
  computed_table_data: GetTableDataFunction
  view_table_data: GetTableDataFunction
  column: GetColumnRowsFunction<ColumnData>
  style: GetColumnRowsFunction<StyleData>
  tooltip: GetColumnRowsFunction<TooltipData>
  summary: GetSummaryRowValuesFunction
}

/**
 * Create table object from table ID
 * Supports all three table types - data_table, view_table and computed_table
 *
 * Order of applying parameters from table ID:
 *   1. Get full table object by calling appropriate table creator according to table type
 *   2. Apply filter if present in table ID
 *   3. Do a fulltext search if search phrase present in table ID
 *
 * We are doing it recursively. This way we "cache" each step of table creation as resource
 * in the storage, so when we change "search" phrase, we don't have to recalculate "filtering"
 * on table or when we change "filter" we don't have to re-fetch unfiltered table.
 * @param runtime {Runtime}
 * @param table_id {{TableId}}
 * @return {MaybeError<TableObject>}
 */
function table(runtime: Runtime, {table_id}): MaybeError<TableObject> {
  const table_id_parts = parse_table_id(table_id)
  const {entity_id, search, filter} = table_id_parts

  function verify_table_id(table: TableObject): TableObject {
    ensure(
      table_id === table._table_id,
      "if this doesn't hold, seatch/filter is breaking the invariant"
    )
    return table
  }

  if (search) {
    const table_id_without_search = compose_table_id(_.omit(table_id_parts, 'search'))
    const table_without_search = runtime._get_table_or_error(table_id_without_search)
    if (is_error(table_without_search)) {
      return table_without_search
    }
    return verify_table_id(table_without_search._search(search))
  } else if (filter) {
    const table_id_without_filter = compose_table_id(_.omit(table_id_parts, 'filter'))
    const table_without_filter = runtime._get_table_or_error(table_id_without_filter)
    if (is_error(table_without_filter)) {
      return table_without_filter
    }
    return verify_table_id(table_without_filter._advanced_filter(filter))
  } else {
    // full table object
    const entity = runtime.get_entity(entity_id)
    if (is_error(entity)) {
      return entity
    }
    switch (entity.type) {
      case 'data_table':
        return create_table(runtime, {
          table_id,
          ...entity,
          original_entity_id: entity.entity_id,
          original_zone_id: entity.zone_id,
          ...(entity.subtype ? {original_subtype: entity.subtype} : {}),
          is_cells_editable: true,
          is_schema_editable: true,
        })
      case 'computed_table':
        return create_computed_table(runtime, table_id)
      case 'view_table':
        return create_view_table(runtime, table_id)
      default:
        return throw_error('Unexpected entity type', 'type', entity.type)
    }
  }
}

/**
 * Compute resource of type 'computed_table_data'
 *
 * Computed table data are needed to create table object for computed table. We are storing them as
 * a resource in the storage so we don't need to run computed table's function with every UI change
 *
 * Needed for computation:
 * fn          - function which should return table object. This function is stored as a string
 *               in the computed table entity
 * params_spec - specification of parameters, also taken from computed table entity
 * params      - parameter values taken from table_id
 * @function
 * @param runtime {Runtime}
 * @param table_id {{table_id: TableId}}
 * @return {MaybeError<TableData>}
 */
const computed_table_data = (
  runtime: Runtime,
  {table_id}: {table_id: TableId}
): MaybeError<TableData> => {
  const {entity_id, params} = parse_table_id(table_id)
  const table_entity = runtime.get_entity(entity_id, 'computed_table')
  if (is_error(table_entity)) {
    return table_entity
  }

  const conflict_f = computed_table_diff.conflict_free(table_entity)
  const {fn: fn_string, params: params_spec, log_computation} = conflict_f
  const fn = eval_function(fn_string)
  const run_params = with_get_param(runtime, {params, params_spec})
  if (log_computation === true) {
    log_with_color('Computed table function:', 'red')
    log_with_color(fn_string, 'orange')
  }
  const table = fn(runtime, run_params)
  if (is_table(table)) {
    return get_computed_table_data(
      table._to_json(),
      table_entity as ComputedTable<'entity'>,
      table_id
    )
  } else {
    const error = is_error(table)
      ? table
      : create_error('user', {
          subtype: 'compute-table-fn-illegal-return-type',
          data: table,
          message: 'Returned table is not of type data_table',
        })
    return create_error('depend', {original_error: error, subtype: 'source_data'})
  }
}

/**
 * Compute resource of type 'view_table_data'
 *
 * View table data are needed to create table object for view table. We are storing them as
 * a resource in the storage so we don't need to compute them from source table with every UI change
 * @param runtime {Runtime}
 * @param table_id {{TableId}}
 * @return {TableData | ErrorObject}
 */
function view_table_data(runtime, {table_id}) {
  const {entity_id, params} = parse_table_id(table_id)
  const view_table_entity = runtime.get_entity(entity_id, 'view_table')
  if (is_error(view_table_entity)) {
    return view_table_entity
  }

  const {source_entity_id} = view_table_diff.conflict_free(view_table_entity)
  const table = runtime.get_table_or_error(source_entity_id, params)

  if (is_table(table)) {
    return get_overridden_data(table._to_json(), view_table_entity as ViewTable<'entity'>, table_id)
  } else if (is_error(table)) {
    return create_error('depend', {original_error: table, subtype: 'source_data'})
  } else {
    return throw_error('Returned table is not a table object.', 'table', table)
  }
}

/**
 * Compute resource of type 'summary'
 *
 * Function for summary computation is stored in columns specs which are taken from table object
 * defined by table_id.
 * @param runtime {Runtime}
 * @param table_id {{TableId}}
 * @return {number | Date | number[] | ErrorObject}
 */
function summary(runtime, {table_id}) {
  const table = runtime._get_table(table_id)
  const cols_with_summary = _.pickBy(table._cols, (col) => 'summary' in col)
  return _.mapValues(
    cols_with_summary,
    (col_spec: ColumnSpec<CellType, 'entity'>, col_id: ColumnId) => {
      ensure('fn' in col_spec.summary!, 'missing user fn for column summary', {
        table_id,
        col_id,
      })

      const eval_method = fn_to_compute_method(col_spec.summary.fn)
      if (eval_method === SummaryComputeMethod.CUSTOM) {
        // contract for fn should be (runtime, {table}) => any
        // result will be stored as it is, but for rendering it will be casted to String,
        // so it's supposed to be human readable after cast
        return execute_value(runtime, table, col_spec.summary.fn)
      } else {
        return error_handling_decorator(() => {
          const col_values = table.rows().map((row) => row._get(col_id))
          return eval_short_fn(col_spec.type.type, col_values, eval_method)
        })()
      }
    }
  )
}

/**
 * Recipes for how to compute resources of certain types
 */
const compute_resource: ComputeResource = {
  table,
  computed_table_data,
  view_table_data,
  summary,
  column: (runtime, {table_id, column_id}) => {
    const table = runtime._get_table(table_id)
    const fn_string = table._cols[column_id].fn!
    const multi = table._cols[column_id].type.multi || false
    return execute_column(runtime, table, fn_string, 'column', multi)
  },
  style: (runtime, {table_id, column_id}) => {
    const table = runtime._get_table(table_id)
    const fn_string = table._cols[column_id].style!
    return execute_column(runtime, table, fn_string, 'style')
  },
  tooltip: (runtime, {table_id, column_id}) => {
    const table = runtime._get_table(table_id)
    const fn_string = table._cols[column_id].tooltip!
    return execute_column(runtime, table, fn_string, 'tooltip')
  },
}

export {compute_resource, execute_value, execute_column, COLUMN_RESOURCE_TYPES}
