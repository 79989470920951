import React, {FC, ReactNode} from 'react'
import {styled, Typography} from '@material-ui/core'

const Header = styled('header')(({theme}) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
}))

const Title = styled((props) => <Typography {...props} component="h2" />)({
  fontWeight: 300,
  fontSize: '2rem',
})

const SectionCaption: FC<{header: string; action?: ReactNode}> = ({header, action}) => {
  return (
    <Header>
      <Title color="textSecondary">{header}</Title>
      {action}
    </Header>
  )
}

export default SectionCaption
