import {EntityDiff, EntityId, ProjectId} from '../types/storage'
import {ActionPayload} from '../dispatch'

export type AddDiffAction<D extends EntityDiff> = ActionPayload<'add-diff'> & {diff: D}
export type AddMultiDiffAction = ActionPayload<'add-multi-diff'>

export const _add_diff = <D extends EntityDiff>(
  entity_id: EntityId,
  zone_id: ProjectId,
  diff: D
): AddDiffAction<D> => {
  return {
    type: 'add-diff',
    entity_id,
    zone_id,
    diff,
  }
}

export const _add_multi_diff = (
  zone_id: ProjectId,
  entity_diff_pairs: [EntityId, EntityDiff][]
): AddMultiDiffAction => {
  return {
    type: 'add-multi-diff',
    zone_id,
    multi_diff: Object.fromEntries(entity_diff_pairs),
  }
}

const entity_diff_action = <P extends any[], D extends EntityDiff>(
  action: (...arg: P) => D,
  entity_id: EntityId,
  zone_id: ProjectId
) => (...args: P): AddDiffAction<D> => _add_diff(entity_id, zone_id, action(...args))

export {entity_diff_action}
