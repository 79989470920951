import React, {useCallback} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
} from 'react-beautiful-dnd'

type SortableListProps = {
  on_item_move: Function
  children: React.ReactElement[]
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  handle: {
    display: 'flex',
    marginRight: theme.spacing(1),
    color: theme.palette.greyPalette[300],
  },
}))

const SortableList: React.FC<SortableListProps> = ({on_item_move, children}) => {
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const on_drag_end = useCallback(
    (result) => {
      const {destination, source} = result
      if (!destination) {
        return
      }

      if (destination.index === source.index) {
        return
      }

      on_item_move(reorder(children, source.index, destination.index))
    },
    [children, on_item_move]
  )
  const classes = useStyles()

  return (
    <DragDropContext onDragEnd={on_drag_end}>
      <Droppable droppableId="list">
        {(provided: DroppableProvided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {React.Children.map(children, (child, idx) => (
              <Draggable
                draggableId={(child.key || idx).toString()}
                index={idx}
                key={child.key || idx}
                isDragDisabled={children.length < 2}
              >
                {(provided: DraggableProvided) => (
                  <div {...provided.draggableProps} ref={provided.innerRef} className={classes.row}>
                    {children.length && (
                      <div {...provided.dragHandleProps} className={classes.handle}>
                        <MenuIcon color="inherit" fontSize="small" />
                      </div>
                    )}
                    {child}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SortableList
