import _ from 'lodash'
import {parse_resource_id} from './params_utils'
import {is_new_project} from './entities/diff_utils'
import {throw_error, Fn} from './utils'

import {
  Storage,
  ResourceType,
  ResourceId,
  Resource,
  CommitId,
  ZoneId,
  ZoneDiff,
} from './types/storage'

function get_storage_utils(storage: Storage) {
  function for_each_resource(fn: Fn<[Resource, ResourceId], any>) {
    for (const resource_id of _.keys(storage.resources)) {
      fn(storage.resources[resource_id], resource_id)
    }
  }

  function type_is_computable(type: ResourceType): boolean {
    return [
      'table',
      'computed_table_data',
      'view_table_data',
      'column',
      'style',
      'tooltip',
      'summary',
    ].includes(type)
  }

  function is_custom_resource(resource_id: ResourceId): boolean {
    return _.has(storage.resources[resource_id], 'fn')
  }

  function is_computable_or_custom_resource(resource_id: ResourceId): boolean {
    const {type} = parse_resource_id(resource_id)
    if (['entity', 'commit', 'permissions', 'external'].includes(type)) {
      return false
    } else if (type_is_computable(type) || is_custom_resource(resource_id)) {
      return true
    } else {
      return throw_error('unknown type of resource', 'type', type)
    }
  }

  function resource_to_commit(resource_id: ResourceId): CommitId {
    const {entity_id} = parse_resource_id(resource_id)
    const zone_id = _.get(storage.entity_headers[entity_id], 'zone_id')
    const commit_id = _.get(storage.versions, zone_id)
    return commit_id
  }

  function is_able_to_undo(zone_id: ZoneId): boolean {
    const {diffs, diff_cnt} = storage
    if (!_.has(diffs, zone_id) || !_.has(diff_cnt, zone_id)) {
      return false
    }

    // We do not allow undoing project creation. Project creation is composed of 2 diffs: for the
    // project itself and for the first table. We do not support table-less projects (yet) so in
    // such a case we need to stop undoing 2nd diff.
    if (diff_cnt[zone_id] === 2 && _.values(diffs[zone_id][0]).some(is_new_project)) {
      return false
    }

    // Must have something to undo
    return diff_cnt[zone_id] > 0
  }

  function is_able_to_redo(zone_id: ZoneId): boolean {
    const {diffs, diff_cnt} = storage
    return (
      _.has(diffs, zone_id) && _.has(diff_cnt, zone_id) && diff_cnt[zone_id] < diffs[zone_id].length
    )
  }

  function get_active_diffs(zone_id: ZoneId): ZoneDiff[] {
    const {diffs, diff_cnt} = storage
    return _.has(diffs, zone_id) ? diffs[zone_id].slice(0, diff_cnt[zone_id]) : []
  }

  function is_external_resource(resource_id: ResourceId): boolean {
    const {type} = parse_resource_id(resource_id)
    return type === 'external'
  }

  return {
    for_each_resource,
    type_is_computable,
    is_custom_resource,
    is_computable_or_custom_resource,
    resource_to_commit,
    get_active_diffs,
    is_able_to_undo,
    is_able_to_redo,
    is_external_resource,
  }
}

export type StorageUtils = ReturnType<typeof get_storage_utils>
export {get_storage_utils}
