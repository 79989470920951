import React, {FC, ChangeEvent, ReactNode} from 'react'
import {FormControl, Select, withStyles} from '@material-ui/core'
import {useInputStyles} from '../utils/customStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

type SelectProps = {
  value?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  children: ReactNode
  native?: boolean
  renderValue?: (value: string) => string
}

const StyledSelect = withStyles((theme) => ({
  outlined: {
    paddingTop: 6.5,
    paddingBottom: 6.5,
  },
  icon: {
    color: theme.palette.greyPalette[400],
  },
}))(Select)

const CustomSelect: FC<SelectProps> = ({
  value = '',
  onChange,
  children,
  native = false,
  renderValue,
}) => {
  const classes = useInputStyles()
  return (
    <FormControl size="small" variant="outlined">
      <StyledSelect
        className={classes.styles}
        native={native}
        value={value}
        renderValue={renderValue}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
      >
        {children}
      </StyledSelect>
    </FormControl>
  )
}

export default CustomSelect
