import {Auth} from '@aws-amplify/auth'
import {is_error} from 'common/error'
import {Fn} from 'common/utils'

/**
 * Wraps function to catch recognized errors, attempt fix and retry once.
 * Unrecognized errors are re-thrown.
 */
export const with_retry = <T extends any[], U>(request: Fn<T, Promise<U>>) => async (
  ...args: T
): Promise<U> => {
  try {
    return await request(...args)
  } catch (e) {
    if (is_error(e) && e.type === 'network-error' && e.value === 401) {
      // likely the token expired
      await Auth.currentSession() // refresh session
      return await request(...args)
    }
    throw e
  }
}
