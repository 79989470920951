import _ from 'lodash'
import {CursorCoordinates} from '../utils/connect_hocs'
import {TableObject} from 'common/objects/data_table'
import {Conflict} from 'common/types/data_table'

const DELETED_LABEL = '"DELETED"'
const EMPTY_LABEL = '"EMPTY"'

export type ConflictData = {
  type: 'cell-value' | 'column-spec' | string // `table-${key}`
  title: string
  conflicts: {
    values: string[][]
    location?: string[]
    local_value?: string | null
    remote_value?: string
  }[]
  cell_coordinates?: CursorCoordinates
}

const conflict_value_to_string = (value) => {
  return value == null || value === '' ? EMPTY_LABEL : String(value)
}

const conflicts_to_strings = (conflicts) => {
  return conflicts.map((pair) => [
    conflict_value_to_string(pair[1]),
    conflict_value_to_string(pair[0]),
  ])
}

// get data for conflicts of type 'cell-value'
const _get_cells_conflicts = (table: TableObject, cells: any): ConflictData[] => {
  return _.flatMap(cells, (col_ids, row_id) =>
    _.map(col_ids, (conflicts, col_id) => {
      const row_label = table._row(row_id).get_label()
      const col_name = table._cols[col_id].name
      // get human readable conflict values
      const cell = table._cell(row_id, col_id)
      const conflict_values = cell.get_conflicts_as_labels()

      return {
        title: `Row: ${conflict_value_to_string(row_label)} / Col: ${conflict_value_to_string(
          col_name
        )}`,
        type: 'Cell conflict',
        conflicts: [
          {
            values: conflicts_to_strings(conflict_values),
            location: ['cell', 'value'],
            local_value: conflict_value_to_string(cell.get_label()),
            remote_value: conflict_value_to_string(conflict_values[0][0]),
          },
        ],
        cell_coordinates: {row_id, col_id},
      }
    })
  )
}

const _get_option_conflicts = (options, conflict_options) =>
  _.flatMap(conflict_options, (conflict_option, option_id) => {
    const option = options?.[option_id]
    return _.flatMap(conflict_option, (conflicts, prop) => {
      return {
        values: conflicts_to_strings(conflicts),
        location: ['column', 'type', 'option', prop],
        local_value: option ? conflict_value_to_string(option[prop]) : null,
        remote_value: conflict_value_to_string(conflicts[0][0]),
      }
    })
  })

const _get_col_type_conflicts = (col_type, conflict_col_type) => {
  return _.flatMap(conflict_col_type, (conflicts, prop) => {
    if (prop === 'options') {
      return _get_option_conflicts(col_type[prop], conflicts)
    } else {
      return {
        values: conflicts_to_strings(conflicts),
        location: ['column', 'type', prop],
        local_value: col_type ? conflict_value_to_string(col_type[prop]) : null,
        remote_value: conflict_value_to_string(conflicts[0][0]),
      }
    }
  })
}

const _get_col_summary_conflicts = (summary, conflict_summary) => {
  return _.flatMap(conflict_summary, (conflicts, prop) => {
    return {
      values: conflicts_to_strings(conflicts),
      location: ['column', 'summary', prop],
      local_value: summary ? conflict_value_to_string(summary[prop]) : null,
      remote_value: conflict_value_to_string(conflicts[0][0]),
    }
  })
}

// get column spec conflicts
const _get_column_conflicts = (table: TableObject, data: any): ConflictData[] => {
  return _.flatMap(data.cols, (conflict_col_spec, column_id) => {
    const col_spec = table._cols[column_id]
    const conflicts = _.flatMap(conflict_col_spec, (conflicts, prop) => {
      switch (prop) {
        case 'type':
          return _get_col_type_conflicts(col_spec[prop], conflicts)
        case 'summary':
          return _get_col_summary_conflicts(col_spec[prop], conflicts)
        default:
          return {
            values: conflicts_to_strings(conflicts),
            location: ['column', prop],
            local_value: conflict_value_to_string(col_spec[prop]),
            remote_value: conflict_value_to_string(conflicts[0][0]),
          }
      }
    })
    return {
      type: 'Column conflict',
      title: `Col: ${conflict_value_to_string(col_spec.name)}`,
      conflicts,
      cell_coordinates: {row_id: null, col_id: column_id},
    }
  })
}

const extract_conflict_data = (table: TableObject, conflict: Conflict): ConflictData[] | null => {
  const {subtype, data} = conflict

  switch (subtype) {
    case 'column-spec':
      return _get_column_conflicts(table, data)
    case 'cell-value':
      return _get_cells_conflicts(table, data.cells)
    default: {
      if (subtype.startsWith('table-')) {
        const prop = subtype.substring(6)
        const [values] = data[prop]
        return [
          {
            type: 'Table conflict',
            title: subtype,
            conflicts: conflicts_to_strings(values),
          },
        ]
      } else {
        return null
      }
    }
  }
}

export type InvalidData = {
  title: string
  row_label?: string | null
  col_name?: string | null
  local_value?: string | null
  cell_values?: string[] | null
}

const invalid_message = {
  'row-deleted': 'Row was changed and deleted by different users',
  'column-deleted': 'Column was changed and deleted by different users',
  'cell-deleted': 'Cell was changed and deleted by different users',
  'cols_order-extra-cols': 'Extra columns found',
  'column-invalid-spec': 'Invalid specification of columns',
  'column-invalid-summary': 'Invalid column summary',
}

const _extract_invalid_cell_data = (data) => {
  return _.flatMap(data, (row_data, row_id) =>
    _.flatMap(row_data, (value, col_id) => {
      const local_value = typeof value === 'object' ? value.value : value
      return {row_id, col_id, local_value}
    })
  )
}

const _get_invalid_cell_data = (table: TableObject, data: any, subtype: string): InvalidData => {
  const cell_data = _extract_invalid_cell_data(data)[0]
  const row_label = table._has_row(cell_data.row_id)
    ? table._row(cell_data.row_id).get_label()
    : DELETED_LABEL
  const col_name =
    cell_data.col_id in table._cols ? table._cols[cell_data.col_id].name : DELETED_LABEL
  return {
    title: invalid_message[subtype],
    row_label: conflict_value_to_string(row_label),
    col_name: conflict_value_to_string(col_name),
    local_value: conflict_value_to_string(cell_data.local_value),
  }
}

const _get_invalid_column_data = (data: any, subtype: string): InvalidData => {
  const col_id = Object.keys(data)[0]
  const col_name = data[col_id].name?.value
  return {
    title: invalid_message[subtype],
    col_name: col_name ? conflict_value_to_string(col_name) : null,
  }
}

const _get_invalid_row_data = (data: any, subtype: string): InvalidData => {
  const cell_data = _extract_invalid_cell_data(data)
  const cell_values = cell_data.flatMap((data) => conflict_value_to_string(data.local_value))
  return {
    title: invalid_message[subtype],
    cell_values,
  }
}

const _get_invalid_summary_data = (table: TableObject, data: any, subtype: string): InvalidData => {
  const col_id = Object.keys(data)[0]
  const label = data[col_id].summary.label.value
  const col_name = col_id in table._cols ? table._cols[col_id].name : DELETED_LABEL
  return {
    title: invalid_message[subtype],
    col_name: conflict_value_to_string(col_name),
    local_value: conflict_value_to_string(label),
  }
}

// renders list of 'items' that are invalid based on invalid type
const extract_invalid_data = (table: TableObject, problem: Conflict): InvalidData | null => {
  const {subtype, data} = problem
  switch (subtype) {
    case 'cell-deleted':
      return _get_invalid_cell_data(table, data.cells, subtype)
    case 'row-deleted':
      return _get_invalid_row_data(data.cells, subtype)
    case 'column-deleted':
      return _get_invalid_column_data(data.cols, subtype)
    case 'column-invalid-summary':
      return _get_invalid_summary_data(table, data.cols, subtype)
    case 'column-invalid-spec':
    case 'cols_order-extra-cols':
      return {
        title: invalid_message[subtype],
      }
    default:
      return null
  }
}

export {
  extract_conflict_data,
  extract_invalid_data,
  invalid_message,
  conflict_value_to_string,
  conflicts_to_strings,
}
