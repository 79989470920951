import {EntityDiff, EntityHeaders, EntityId} from 'common/types/storage'

const is_entity_or_parent_archived = (
  entity_id: EntityId,
  entity_headers: EntityHeaders
): boolean => {
  const parent_id = entity_headers[entity_id]?.parent_id
  if (!entity_id || !parent_id || entity_id === parent_id) return false
  return (
    !!entity_headers[entity_id]?.archived || is_entity_or_parent_archived(parent_id, entity_headers)
  )
}

const is_parent_archived = (entity_id: EntityId, entity_headers: EntityHeaders): boolean => {
  return is_entity_or_parent_archived(entity_headers[entity_id].parent_id, entity_headers)
}

const is_entity_or_parent_archived_diff = (
  entity_id: EntityId,
  entity_headers: EntityHeaders,
  multidiff: Record<EntityId, EntityDiff>
): boolean => {
  const parent_id = entity_headers[entity_id]?.parent_id
  const new_archived_status = multidiff[entity_id]?.archived?.new_value

  if (!entity_id || !parent_id || entity_id === parent_id) {
    return false
  }

  return (
    (entity_headers[entity_id].archived && multidiff && new_archived_status === undefined) ||
    new_archived_status === true ||
    (entity_headers[entity_id] &&
      is_entity_or_parent_archived_diff(parent_id, entity_headers, multidiff))
  )
}

export {is_entity_or_parent_archived, is_parent_archived, is_entity_or_parent_archived_diff}
