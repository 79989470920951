import {withStyles, createStyles} from '@material-ui/core'
import {EditFlag} from 'common/objects/data_table'
import {CellRawValue, ColumnType, CellType} from 'common/types/storage'
import {throw_error} from 'common/utils'
import {noop} from 'lodash'
import React, {FC, useMemo} from 'react'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import DateEditor from '../CellEditor/DateEditor'
import {
  get_available_options,
  get_editor_type,
  raw_value_to_string,
  with_additional_options,
} from '../CellEditor/editor_utils'
import SelectEditor, {SelectOption} from '../CellEditor/SelectEditor'
import StringEditor from '../CellEditor/StringEditor'
import BooleanEditor from '../CellEditor/BooleanEditor'

export type ParamValueEditorProps = {
  end_edit: () => void
  cols_edit_flags?: EditFlag
  set_value: (new_value: CellRawValue, new_options?: SelectOption[]) => void
  value_type: ColumnType<CellType, 'entity'>
  value?: CellRawValue
  error?: boolean
}

export type ParamValueSelectEditorProps = ParamValueEditorProps

const StyledSelectEditor = withStyles(() =>
  createStyles({
    root: {
      height: '100%',
      background: 'white',
      width: 200,
    },
  })
)(SelectEditor)

export const ParamValueSelectEditor: FC<ParamValueSelectEditorProps> = ({
  value_type,
  value = null,
  set_value,
  error,
}) => {
  const {runtime} = useRuntimeSelector() as AlwaysDefinedRuntime

  const column_options = useMemo(() => {
    return get_available_options(value_type, runtime)
  }, [runtime, value_type])

  const all_options = useMemo(() => {
    return with_additional_options(column_options, value, value_type, runtime)
  }, [column_options, value, value_type, runtime])
  return (
    <StyledSelectEditor
      value={value}
      multi={value_type.multi}
      open="onFocus"
      show_option_chips
      clear_search_on_select
      options={all_options}
      set_value={set_value}
      end_edit={noop}
      autofocus={false}
      error={error}
      is_filter_value_editor
    />
  )
}

export const ParamValueEditor: FC<ParamValueEditorProps> = ({
  end_edit,
  cols_edit_flags = 'readonly',
  set_value,
  value_type,
  value = null,
}) => {
  const editor_type = get_editor_type(value_type.type)
  switch (editor_type) {
    case 'select':
      return (
        <ParamValueSelectEditor
          end_edit={end_edit}
          cols_edit_flags={cols_edit_flags}
          value_type={value_type}
          set_value={set_value}
          value={value}
        />
      )
    case 'date':
      return (
        <DateEditor
          value={raw_value_to_string(value)}
          set_value={set_value}
          end_edit={end_edit}
          date_format_id={(value_type as ColumnType<'date', 'entity'>).date_format_id}
          startOpen={false}
          autoFocus={false}
        />
      )
    case 'date_time':
    case 'markdown':
    case 'string':
    case 'attachment':
      return (
        <StringEditor
          multiline={editor_type === 'markdown'}
          value={raw_value_to_string(value)}
          set_value={set_value}
          end_edit={end_edit}
        />
      )
    case 'boolean':
      return (
        <BooleanEditor
          end_edit={end_edit}
          value={raw_value_to_string(value)}
          set_value={set_value}
          autofocus={false}
        />
      )
    default:
      return throw_error('Unknown cell type', editor_type)
  }
}
