export type BooleanFormatId = string

export type BooleanFormatValueId = string

export type BooleanFormatValues = [BooleanFormatValueId, BooleanFormatValueId]

export const BOOLEAN_VALUE_TO_FORMAT_MAP: Record<BooleanFormatValueId, BooleanFormatId> = {
  true: 'true/false',
  false: 'true/false',
  TRUE: 'TRUE/FALSE',
  FALSE: 'TRUE/FALSE',
  yes: 'yes/no',
  no: 'yes/no',
  YES: 'YES/NO',
  NO: 'YES/NO',
  1: '1/0',
  0: '1/0',
}

export const BOOLEAN_FORMAT_TO_VALUES_MAP: Record<BooleanFormatId, BooleanFormatValues> = {
  'true/false': ['true', 'false'],
  'TRUE/FALSE': ['TRUE', 'FALSE'],
  'yes/no': ['yes', 'no'],
  'YES/NO': ['YES', 'NO'],
  '1/0': ['1', '0'],
}

export const get_boolean_format_values = (
  format_id: BooleanFormatId | undefined
): BooleanFormatValues => {
  if (format_id) {
    return BOOLEAN_FORMAT_TO_VALUES_MAP[format_id] ?? BOOLEAN_FORMAT_TO_VALUES_MAP
  }
  return ['true', 'false']
}

export const get_boolean_format_by_value = (value?: string): BooleanFormatValues | undefined => {
  if (value) {
    const format_id = BOOLEAN_VALUE_TO_FORMAT_MAP[value]
    if (format_id) {
      return BOOLEAN_FORMAT_TO_VALUES_MAP[format_id]
    }
  }
  return undefined
}
