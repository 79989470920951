import React, {ChangeEvent, FC, useCallback} from 'react'

import FunctionEditor from '../ColSettings/FunctionEditor/FunctionEditor'
import {Checkbox, InputLabel} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'

const FieldContainer = styled('div')({
  'padding-bottom': '15px',
  '& label': {
    'padding-top': '10px',
    'display': 'inline-block',
    'text-align': 'right',
    'margin-right': '15px',
    'vertical-align': 'top',
    '&::after': {
      content: '":"',
    },
  },
})

const StyledCheckbox = styled(Checkbox)({
  padding: '5px',
})

const fn_template = `/* template table function */
/* replace table_id with id for changed table */

(runtime, {get_param}) => runtime.get_table(table_id).add_column({
  name: 'count',
  type: {type: 'number'},
  fn: (table) => {
    let i = 0
    return table.compute_column((row) => i++)
  }
})`

type FnFormProps = {
  fn: string
  set_fn: (fn: string) => void
  log_computation: boolean
  set_log_computation: (log: boolean) => void
  read_only: boolean
}

const ComputedTableFnEditor: FC<FnFormProps> = ({
  fn,
  set_fn,
  log_computation,
  set_log_computation,
  read_only,
}) => {
  const handle_log_change = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) => {
      set_log_computation(target.checked)
    },
    [set_log_computation]
  )

  return (
    <>
      <h3>Table Function</h3>
      <FieldContainer>
        <InputLabel htmlFor="table_enable_log">Log computation</InputLabel>
        <StyledCheckbox
          id="table_enable_log"
          name="log"
          checked={log_computation}
          onChange={handle_log_change}
          color="primary"
        />
      </FieldContainer>
      <FunctionEditor
        fn_string={fn}
        on_edit={set_fn}
        fn_template={fn_template}
        read_only={read_only}
      />
    </>
  )
}

export {ComputedTableFnEditor}
