import _ from 'lodash'
import React from 'react'
import styles from './ConflictDescription.module.css'

const ConflictDescription = ({conflicts, local_value, remote_value}) => {
  return (
    <div className={styles.conflictDescription}>
      <div>
        <b>Local value: </b>
        {local_value}
      </div>
      <div>
        <b>Remote value: </b>
        {remote_value}
      </div>
      <div>
        <b>Conflicting values: </b>
      </div>
      {_.map(conflicts, (pair, pair_index) => (
        <div key={pair_index}>{pair.join(' > ')}</div>
      ))}
    </div>
  )
}

export default ConflictDescription
