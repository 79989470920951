import React, {FC} from 'react'
import {IconButton, makeStyles} from '@material-ui/core'
import {
  AlwaysDefinedRuntime,
  TableUiState,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {mod, sorted_index_by} from 'common/utils'
import {scroll_to_new_cursor_payload} from '../utils/cursor_utils'
import {FindCellsOrder} from './AsyncFind'
import _ from 'lodash'
import {get_coords, get_position} from '../utils/table_helpers'
import {TableObject} from 'common/objects/data_table'
import {RowId} from 'common/types/storage'
import {focus_table_content} from '../utils/focus'

const shift_find_position = (
  current_position: number,
  offset: number,
  find_cells_order: FindCellsOrder,
  table: TableObject,
  rows_order: RowId[]
): Partial<TableUiState> => {
  if (find_cells_order.length > 0) {
    const new_index = mod(current_position + offset, find_cells_order.length)
    const new_cursor = get_coords(find_cells_order[new_index], table, rows_order)

    return {
      find_position: new_index,
      ...scroll_to_new_cursor_payload(new_cursor),
    }
  } else {
    return {}
  }
}

const useStyles = makeStyles((theme) => ({
  button: (props: {disabled}) => ({
    marginLeft: theme.spacing(1),
    borderRadius: 0,
    ...(props.disabled && {pointerEvents: 'none'}),
  }),
}))

const JumpButtons: FC = () => {
  const {
    resources: {
      table_resources: {table_entity_id, table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const find_position = useTableUiSelector(table_entity_id, 'find_position')
  const find_cells_order = useTableUiSelector(table_entity_id, 'find_result')?.cells_order
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')
  const cursor = useTableUiSelector(table_entity_id, 'cursor')

  const shift_by = (offset: 1 | -1) => {
    if (!find_cells_order?.length) return

    const cursor_matches_result_in_order =
      find_position != null &&
      _.isEqual(cursor, get_coords(find_cells_order[find_position], table, rows_order))

    if (cursor_matches_result_in_order) {
      // most expected, user was just clicking the jump buttons
      set_table_ui_state(
        shift_find_position(find_position!, offset, find_cells_order, table, rows_order),
        'jump_by_one_result'
      )
    } else if (cursor) {
      // user focused a different cell by clicking on it
      const cursor_pos = get_position(cursor, table, rows_order)
      const target_index = sorted_index_by(find_cells_order, cursor_pos, ([x1, y1], [x2, y2]) => {
        return y1 === y2 ? x1 < x2 : y1 < y2
      })

      // if cursor is between search results and user jumps forward, don't skip next result
      const corrected_offset =
        offset > 0 && !_.isEqual(find_cells_order[target_index], cursor_pos) ? offset - 1 : offset

      set_table_ui_state(
        shift_find_position(target_index, corrected_offset, find_cells_order, table, rows_order),
        'jump_to_nearest_following_result'
      )
    } else {
      // fallback by focusing the first result
      set_table_ui_state(
        shift_find_position(0, 0, find_cells_order, table, rows_order),
        'jump_to_first_result_via_shift'
      )
    }
  }

  const disabled = find_cells_order == null || find_cells_order.length === 0

  const classes = useStyles({disabled})

  const next = () => {
    shift_by(1)
    focus_table_content()
  }

  const prev = () => {
    shift_by(-1)
    focus_table_content()
  }

  return (
    <>
      <IconButton className={classes.button} size="small" onClick={prev} disableRipple>
        <i className="fas fa-angle-up" />
      </IconButton>
      <IconButton className={classes.button} size="small" onClick={next} disableRipple>
        <i className="fas fa-angle-down" />
      </IconButton>
    </>
  )
}

export default JumpButtons
