import React, {FC, useState, useCallback, ChangeEvent} from 'react'
import {DialogTitle, DialogContent, DialogActions, Button, Checkbox} from '@material-ui/core'
import {TableEntityId} from 'common/types/storage'

export type CommitOptions = {
  keep_order_by?: TableEntityId[]
  keep_frozen_cols?: TableEntityId[]
}

type ConfirmCommitModalProps = {
  entity_ids_with_order_by: TableEntityId[]
  entity_ids_with_frozen_cols: TableEntityId[]
  on_submit: (options: CommitOptions) => Promise<void>
  on_request_close: () => void
}

const ConfirmCommitModal: FC<ConfirmCommitModalProps> = ({
  entity_ids_with_order_by,
  entity_ids_with_frozen_cols,
  on_submit,
  on_request_close,
}) => {
  const [commit_order_by, set_commit_order_by] = useState(false)
  const [commit_frozen_cols, set_commit_frozen_cols] = useState(false)

  const handle_change_commit_order_by = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    set_commit_order_by(e.target.checked)
  }, [])

  const handle_change_commit_frozen_cols = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    set_commit_frozen_cols(e.target.checked)
  }, [])

  const handle_submit = useCallback(async () => {
    on_request_close()
    await on_submit({
      ...(!commit_order_by && {keep_order_by: entity_ids_with_order_by}),
      ...(!commit_frozen_cols && {keep_frozen_cols: entity_ids_with_frozen_cols}),
    })
  }, [
    commit_frozen_cols,
    commit_order_by,
    entity_ids_with_frozen_cols,
    entity_ids_with_order_by,
    on_request_close,
    on_submit,
  ])

  return (
    <>
      <DialogTitle disableTypography>
        <h3>Confirm commit</h3>
      </DialogTitle>
      <DialogContent>
        {entity_ids_with_order_by.length > 0 && (
          <>
            <label htmlFor="commit_order_by">Commit also changed table sort: </label>
            <Checkbox
              id="commit_order_by"
              color="primary"
              checked={commit_order_by}
              onChange={handle_change_commit_order_by}
            />
            <br />
          </>
        )}
        {entity_ids_with_frozen_cols.length > 0 && (
          <>
            <label htmlFor="commit_frozen_cols">Commit also changed frozen columns: </label>
            <Checkbox
              id="commit_frozen_cols"
              color="primary"
              checked={commit_frozen_cols}
              onChange={handle_change_commit_frozen_cols}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_submit} color="primary">
          Ok
        </Button>
        <Button onClick={on_request_close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmCommitModal
