import React, {FC, useCallback} from 'react'
import {useSetTableUiState} from '../utils/connect_hocs'
import {ColumnId, RowId, TableEntityId} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {CellEditor} from '../CellEditor/CellEditor'

type EditableCellDetailProps = {
  detailed_view_table: TableObject
  table_entity_id: TableEntityId
  col_id: ColumnId
  row_id: RowId
}

const EditableCellDetail: FC<EditableCellDetailProps> = ({
  detailed_view_table,
  table_entity_id,
  col_id,
  row_id,
}) => {
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const end_edit = useCallback(() => {
    set_table_ui_state({view_editing: null})
  }, [set_table_ui_state])

  return (
    <CellEditor
      table={detailed_view_table}
      col_id={col_id}
      row_id={row_id}
      end_edit={end_edit}
      in_detailed_view
    />
  )
}

export default EditableCellDetail
