import _ from 'lodash'
import type {Storage, DiffStorage} from 'common/types/storage'
import {create_multidiff_for_zone} from 'common/dispatch'

const DIFF_STORAGE_KEY = 'diff_storage'
const SETTINGS_STORAGE_KEY = 'settings'

/* eslint-disable prefer-template */
const PROMPT =
  'If you are online, please click "Save changes" as soon as possible and then reload the page. ' +
  'Otherwise your changes will be kept only as long as this tab is open and does not reload.'

const QUOTA_EXCEEDED_MSG =
  'You have made a lot of changes without saving and they can no longer be backed up.\n' + PROMPT

const UNKNOWN_ERROR_MSG = 'An unknown error occurred.\n' + PROMPT

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
// prettier-ignore
const is_quota_exception = (e: unknown): e is DOMException =>
  e instanceof DOMException && (
    // most browsers
    e.code === 22 ||
    // firefox
    e.code === 1014 ||
    // in case code is missing
    e.name === 'QuotaExceededError' ||
    // in case code is missing in firefox
    e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
  )

const LOCAL_STORAGE_AVAILABLE = (function () {
  try {
    const str = '__bee__test'
    window.localStorage.setItem(str, str)
    window.localStorage.removeItem(str)
    return true
  } catch (e) {
    // don't trust quota exception if storage is empty
    return is_quota_exception(e) && !!window.localStorage?.length
  }
})()

let using_local_storage = LOCAL_STORAGE_AVAILABLE

export const clear_diff_storage = () => {
  if (!LOCAL_STORAGE_AVAILABLE) return
  window.localStorage.removeItem(DIFF_STORAGE_KEY)
}

/**
 * Writes diffs and diff_cnt to local storage if possible.
 * If an error occurs, local storage will be cleared and unused until page refresh.
 */
export const update = ({diff_cnt, diffs}: DiffStorage) => {
  if (!using_local_storage) return
  try {
    window.localStorage.setItem(DIFF_STORAGE_KEY, JSON.stringify({diff_cnt, diffs}))
  } catch (e) {
    console.error(e)
    using_local_storage = false
    clear_diff_storage()
    // eslint-disable-next-line no-alert
    alert(is_quota_exception(e) ? QUOTA_EXCEEDED_MSG : UNKNOWN_ERROR_MSG)
  }
}

export const update_settings = ({include_archived}: {include_archived: boolean}) => {
  if (!using_local_storage) return
  try {
    window.localStorage.setItem(SETTINGS_STORAGE_KEY, JSON.stringify({include_archived}))
  } catch (e) {
    console.error(e)
    using_local_storage = false
    // eslint-disable-next-line no-alert
    alert(is_quota_exception(e) ? QUOTA_EXCEEDED_MSG : UNKNOWN_ERROR_MSG)
  }
}

export const get_settings_storage = (): Pick<Storage, 'include_archived'> => {
  if (!using_local_storage) return {include_archived: false}
  const maybe_settings_storage = window.localStorage.getItem(SETTINGS_STORAGE_KEY)
  return maybe_settings_storage != null ? JSON.parse(maybe_settings_storage) : null
}

const get_raw_diff_storage = (): DiffStorage | null => {
  if (!using_local_storage) return null
  const maybe_diff_storage = window.localStorage.getItem(DIFF_STORAGE_KEY)
  return maybe_diff_storage != null ? JSON.parse(maybe_diff_storage) : null
}

const generate_multidiffs = (diff_storage: DiffStorage): Storage['multidiff'] =>
  _.mapValues(diff_storage.diffs, (_, zone_id) => create_multidiff_for_zone(diff_storage, zone_id))

export const get_diff_storage = (): (DiffStorage & Pick<Storage, 'multidiff'>) | null => {
  const raw_diff_storage = get_raw_diff_storage()
  if (raw_diff_storage == null) return null
  return {...raw_diff_storage, multidiff: generate_multidiffs(raw_diff_storage)}
}

export const has_diffs = () => {
  if (!using_local_storage) return false
  return window.localStorage.getItem(DIFF_STORAGE_KEY) != null
}
