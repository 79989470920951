import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useContext,
} from 'react'
import {
  MenuItem,
  Select as MaterialUiSelect,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  Checkbox,
  InputLabel,
} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import ih from 'immutability-helper'
import _ from 'lodash'
import OptionsEditor, {validate_options} from './OptionsEditor'
import FunctionEditorSwitch, {
  FunctionChangeHandler,
  ColSettingsSummary,
} from './FunctionEditor/FunctionEditorSwitch'
import {SummaryComputeMethod, fn_to_compute_method} from 'common/summary/compute_methods'
import {EditFlag, TableObject} from 'common/objects/data_table'
import {DateFormatId, DATE_FORMATS, TimeFormatId, TIME_FORMATS} from 'common/formatting/date'
import {
  NUMBER_FORMAT_ITEMS,
  apply_number_format,
  get_number_format_name,
  NumberFormatId,
} from 'common/formatting/number'
import {
  OptionId,
  ColumnSpec,
  CellType,
  EntityHeaders,
  EntityHeader,
  TableType,
  Options,
  ColumnId,
  ResourceId,
  Resource,
  TableId,
  TableColumnReferences,
  TableColumnReferenceId,
} from 'common/types/storage'
import {SingleOptionValue} from 'common/types/data_table'
import {COL_TYPES} from 'common/types'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {UserAccountContext} from '../UserAccountProvider'
import {BOOLEAN_FORMAT_TO_VALUES_MAP, BooleanFormatId} from 'common/formatting/boolean'
import TableColumnReferenceSelector from '../Selectors/TableColumnReferenceSelector'
import {uuid} from 'common/utils'

type ColumnSpecEntity = ColumnSpec<CellType, 'entity'>
type ColumnSpecEntityStub = Partial<ColumnSpecEntity> & {name: string; description?: string}

const StyledForm = styled('form')({
  width: '80vw',
  display: 'flex',
  flexDirection: 'column',
  height: 'fill-available',
  overflowY: 'auto',
})

const StyledField = styled('div')({
  'display': 'flex',
  'alignItems': 'center',
  '& label': {
    flexShrink: 0,
  },
})

const StyledCheckbox = styled(Checkbox)({
  padding: '5px',
})

const ConstWidthSelect = styled(MaterialUiSelect)({
  width: '32ch',
})

const FormatMenuItem = styled(MenuItem)({
  justifyContent: 'space-between',
})

const FormatExample = styled('span')({
  color: 'gray',
})

const FieldContainer = styled('div')({
  'padding-bottom': '15px',
  '& label': {
    'padding-top': '10px',
    'display': 'inline-block',
    'width': '155px',
    'text-align': 'right',
    'margin-right': '15px',
    'vertical-align': 'top',
    '&::after': {
      content: '":"',
    },
  },
})

const StyledChip = styled('label')(({theme}) => ({
  width: 'min-content',
  backgroundColor: '#e0e0e0',
  borderRadius: '0.75rem',
  padding: '0.2rem 0.5rem',
  whiteSpace: 'nowrap',
  margin: '1rem 1.5rem',
  [theme.breakpoints.down('xs')]: {
    margin: '0 0 1.5rem 1.5rem',
  },
}))

const TitleContainer = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

const EXAMPLE_NUMBER_FOR_FORMATTING = 2718.281

const multi_col_types = Object.freeze(['option', 'reference'])

type ColSettingsModalProps = {
  table: TableObject
  edit_flag: EditFlag
  entity_headers: EntityHeaders
  resources: Record<ResourceId, Resource>
  on_request_close: () => void
  on_submit: (col_spec: ColumnSpecEntity) => void
  is_new_column?: boolean
  col_id: ColumnId
}

type ColSettings = {
  name: string
  slug: string
  description?: string
  type: {
    type: CellType
    required: boolean
    multi: boolean
    tables: TableColumnReferences
    options: Options
    disable_add_option: boolean
    number_format_id: NumberFormatId | null
    date_format_id: DateFormatId | null
    time_format_id: TimeFormatId | null
    boolean_format_id: BooleanFormatId | null
  }
  style: string
  tooltip: string
  fn: string
  summary: ColSettingsSummary
  computed: boolean
  width: number | null
  is_default: boolean
}

const EMPTY_COL_SETTINGS: Omit<ColSettings, 'name' | 'description'> = {
  slug: '',
  type: {
    type: 'string',
    required: false,
    multi: false,
    tables: {},
    date_format_id: null,
    time_format_id: null,
    number_format_id: null,
    options: {},
    disable_add_option: false,
    boolean_format_id: null,
  },
  style: '',
  tooltip: '',
  fn: '',
  computed: false,
  width: null,
  summary: {
    label: '',
    compute_method: SummaryComputeMethod.CUSTOM,
    custom_fn: '',
  },
  is_default: false,
}

// Appends a number to the column name in order to be unique
const new_column_spec = (table_type: TableType, existing_col_names: string[]) => {
  const computed = table_type !== 'data_table'
  const name_base = computed ? 'New computed column' : 'New column'
  const new_col_pattern = RegExp(`^${name_base} ([1-9]\\d*)$`)

  const highest_existing_new_col_num =
    _(existing_col_names) // parseInt is typed too stricly, calling it with undefined is fine
      .map((col_name) => parseInt(col_name.match(new_col_pattern)?.[1] as any, 10) || 0)
      .max() ?? 0

  return {
    name: `${name_base} ${highest_existing_new_col_num + 1}`,
    computed,
  }
}

const clean_col_spec = (col_spec: ColumnSpecEntityStub): ColumnSpecEntityStub => {
  // note: checking col_type === 'option' is not enough
  // as new 'option' column will have no options object
  return _.get(col_spec, 'type.options')
    ? ih(col_spec, {
        type: {
          // options are mutated, we need to pick only recipe keys (i.e. 'name')
          options: (options: Record<OptionId, SingleOptionValue>) =>
            _.mapValues(options, (option_spec) => ({
              name: option_spec.name,
            })),
        },
      })
    : col_spec
}

const spec_to_settings = (col_spec: ColumnSpecEntityStub): ColSettings => {
  const col_spec_summary = _.get(col_spec, 'summary', {fn: '', label: ''})
  const summary_method = fn_to_compute_method(col_spec_summary.fn)
  const summary: ColSettingsSummary = {
    label: col_spec_summary.label || '',
    compute_method: summary_method,
    // remove custom-fn for short fns
    custom_fn: summary_method === SummaryComputeMethod.CUSTOM ? col_spec_summary.fn : '',
  }

  return _.merge({}, EMPTY_COL_SETTINGS, {
    ...col_spec,
    summary,
  })
}

// false-ish values are omitted from specification
const settings_to_spec = (col_settings: ColSettings): ColumnSpecEntity => {
  const {
    name,
    slug,
    description,
    type: {
      type: col_type,
      required,
      multi,
      options,
      disable_add_option,
      number_format_id,
      date_format_id,
      time_format_id,
      boolean_format_id,
      tables,
    },
    style,
    tooltip,
    fn,
    computed,
    width,
    summary,
    is_default,
  } = col_settings

  const new_summary = {
    label: summary.label,
    fn:
      summary.compute_method === SummaryComputeMethod.CUSTOM
        ? summary.custom_fn
        : summary.compute_method,
  }

  const col_spec = _.assign(
    {name}, // name should always be defined,
    slug ? {slug} : {},
    description ? {description} : {},
    style ? {style} : {},
    tooltip ? {tooltip} : {},
    computed ? {computed} : {},
    computed && fn ? {fn} : {},
    new_summary.fn ? {summary: _.pickBy(new_summary, (s) => s.length)} : {},
    width ? {width} : {},
    {
      type: _.assign(
        {type: col_type},
        required ? {required} : {},
        multi_col_types.includes(col_type) && multi ? {multi} : {},
        col_type === 'option' ? {options, ...(disable_add_option ? {disable_add_option} : {})} : {},
        col_type === 'reference' ? {tables} : {},
        col_type === 'number' && number_format_id ? {number_format_id} : {},
        col_type === 'date' && date_format_id ? {date_format_id} : {},
        col_type === 'date_time' && date_format_id ? {date_format_id} : {},
        col_type === 'date_time' && time_format_id ? {time_format_id} : {},
        col_type === 'boolean' && boolean_format_id ? {boolean_format_id} : {}
      ),
    },
    is_default ? {is_default} : {}
  )
  return col_spec
}

const reference_filter = (entity: EntityHeader) => entity.type === 'data_table'

const ColSettingsModal: FC<ColSettingsModalProps> = ({
  table,
  edit_flag,
  entity_headers,
  resources,
  on_request_close,
  on_submit,
  is_new_column,
  col_id,
}) => {
  const {dispatch_storage} = useRuntimeActions()
  const current_user = useContext(UserAccountContext)
  const raw_current_spec = table._cols[col_id]

  const current_col_names = useMemo(() => _.map(table._cols, 'name'), [table._cols])
  const current_col_slugs = useMemo(() => _.map(table._cols, 'slug'), [table._cols])
  const current_col_ids = useMemo(() => _.keys(table._cols), [table._cols])

  const current_spec = useMemo(
    () => clean_col_spec(raw_current_spec || new_column_spec(table.type, current_col_names)),
    [raw_current_spec, table.type, current_col_names]
  )

  const [validate_options_field, set_validate_options_field] = useState<boolean>(false)
  const [col_settings, set_col_settings] = useState(spec_to_settings(current_spec))
  const dirty = useMemo(() => !_.isEqual(settings_to_spec(col_settings), current_spec), [
    col_settings,
    current_spec,
  ])

  const {
    name,
    slug,
    description,
    type: {
      type: col_type,
      required,
      multi: is_multi,
      options,
      disable_add_option,
      number_format_id,
      date_format_id,
      time_format_id,
      boolean_format_id,
      tables,
    },
    style: style_fn,
    tooltip: tooltip_fn,
    fn: computed_fn,
    computed,
    summary,
  } = col_settings

  const set_col_setting = useCallback((field: string, value: any) => {
    set_col_settings((state) => _.set({...state}, field, value))
  }, [])

  const set_validate_options_checkbox = useCallback(() => {
    set_validate_options_field(!validate_options_field)
  }, [validate_options_field])

  const validate_and_apply = useCallback(
    (form_element: HTMLFormElement): boolean => {
      if (!form_element.reportValidity()) {
        return false
      }
      const new_col_spec = settings_to_spec(col_settings)

      const new_name_already_exists =
        new_col_spec.name !== current_spec.name && current_col_names.includes(new_col_spec.name)

      if (new_name_already_exists) {
        // eslint-disable-next-line no-alert
        alert(`Column with name ${new_col_spec.name} already exists!`)
        return false
      }

      const slug_already_exists =
        new_col_spec.slug &&
        new_col_spec.slug !== current_spec.slug &&
        current_col_slugs.includes(new_col_spec.slug)

      if (slug_already_exists) {
        // eslint-disable-next-line no-alert
        alert(`Column with slug ${new_col_spec.slug} already exists!`)
        return false
      }

      const slug_conflicts_internal_id =
        new_col_spec.slug &&
        new_col_spec.slug !== col_id &&
        current_col_ids.includes(new_col_spec.slug)

      if (slug_conflicts_internal_id) {
        // eslint-disable-next-line no-alert
        alert(`Column slug ${new_col_spec.slug} is the same as ID of another column!`)
        return false
      }

      if (new_col_spec.slug === 'row_id') {
        // eslint-disable-next-line no-alert
        alert("Invalid column slug value: 'row_id'!")
        return false
      }

      if (new_col_spec.computed && !new_col_spec.fn) {
        // eslint-disable-next-line no-alert
        alert('Computed column function must be defined!')
        return false
      }
      if (new_col_spec.type.type === 'option') {
        const validation_message = validate_options(new_col_spec.type.options)
        if (validation_message) {
          // eslint-disable-next-line no-alert
          alert(validation_message)
          return false
        }
      }
      if (new_col_spec.type.type === 'reference' && _.isEmpty(new_col_spec.type.tables)) {
        // eslint-disable-next-line no-alert
        alert('Referenced tables must not be empty!')
        return false
      }

      on_submit(new_col_spec)
      if (new_col_spec.type.type === 'option' && validate_options_field) {
        dispatch_storage(
          table._actions.validate_options_in_column(col_id, current_user?.email, new_col_spec)
        )
      }
      return true
    },
    [
      col_id,
      col_settings,
      current_spec.name,
      current_spec.slug,
      current_col_names,
      current_col_slugs,
      current_col_ids,
      current_user?.email,
      dispatch_storage,
      on_submit,
      table._actions,
      validate_options_field,
    ]
  )

  const handle_submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (validate_and_apply(e.currentTarget)) {
        on_request_close()
      }
    },
    [validate_and_apply, on_request_close]
  )

  const handle_apply = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      validate_and_apply(e.currentTarget.form as HTMLFormElement)
    },
    [validate_and_apply]
  )

  const request_close = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // https://github.com/erikras/redux-form/issues/2679
      e.preventDefault()
      on_request_close()
    },
    [on_request_close]
  )

  const value_change_handler = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) => set_col_setting(target.name, target.value),
    [set_col_setting]
  )
  const checked_change_handler = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) => set_col_setting(target.name, target.checked),
    [set_col_setting]
  )
  const inverse_checked_change_handler = useCallback(
    ({target}: ChangeEvent<HTMLInputElement>) => set_col_setting(target.name, !target.checked),
    [set_col_setting]
  )
  const table_selector_handler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: string[]) => {
      const tables_entries = _.entries(tables)
      set_col_setting(
        'type.tables',
        value.reduce((prev, id, curr_index) => {
          const table_entry = tables_entries.find(([, {table_id}]) => id === table_id)
          if (table_entry) {
            return {
              ...prev,
              [table_entry[0]]: {...table_entry[1], index: curr_index},
            }
          }
          return {
            ...prev,
            [uuid()]: {table_id: id, index: curr_index},
          }
        }, {})
      )
    },
    [set_col_setting, tables]
  )

  const column_selector_handler = useCallback(
    (ref_id: TableColumnReferenceId, table_id: TableId, column_id: ColumnId) => {
      const tables_mapping = col_settings.type.tables
      const ref_index = tables_mapping[ref_id]?.index ?? _.keys(tables_mapping).length
      tables_mapping[ref_id] = {column_id, table_id, index: ref_index}
      set_col_setting('type.tables', tables_mapping)
    },
    [set_col_setting, col_settings.type.tables]
  )

  const function_change_handler: FunctionChangeHandler = set_col_setting

  const options_change_handler = useCallback(
    (options: Options) => set_col_setting('type.options', options),
    [set_col_setting]
  )

  return (
    <StyledForm onSubmit={handle_submit}>
      <TitleContainer>
        <DialogTitle>Column: "{name}"</DialogTitle>
        <StyledChip>ID: {col_id}</StyledChip>
      </TitleContainer>
      <DialogContent dividers>
        <FieldContainer>
          <InputLabel htmlFor="col_name_field">Name</InputLabel>
          <TextField
            id="col_name_field"
            name="name"
            type="text"
            value={name}
            required
            onChange={value_change_handler}
            disabled={edit_flag !== 'editable'}
          />
        </FieldContainer>
        <FieldContainer>
          <InputLabel htmlFor="col_slug_field">Slug</InputLabel>
          <TextField
            id="col_slug_field"
            name="slug"
            type="text"
            value={slug}
            onChange={value_change_handler}
            disabled={edit_flag !== 'editable'}
          />
        </FieldContainer>
        <FieldContainer>
          <InputLabel htmlFor="col_description_field">Description</InputLabel>
          <TextField
            id="col_description_field"
            name="description"
            type="text"
            multiline
            value={description}
            onChange={value_change_handler}
            disabled={edit_flag !== 'editable'}
          />
        </FieldContainer>
        <FieldContainer>
          <InputLabel htmlFor="col_type_field">Type</InputLabel>
          <TextField
            select
            id="col_type_field"
            name="type.type"
            value={col_type}
            onChange={value_change_handler}
            disabled={edit_flag !== 'editable'}
          >
            {COL_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </FieldContainer>
        {col_type === 'option' && (
          <>
            <FieldContainer>
              <InputLabel htmlFor="">Options</InputLabel>
              <OptionsEditor
                options={options}
                on_options_change={options_change_handler}
                disabled={edit_flag !== 'editable'}
              />
            </FieldContainer>
            <FieldContainer>
              <InputLabel htmlFor="col_disable_add_option_field">
                Allow adding new option in cell editor
              </InputLabel>
              <StyledCheckbox
                id="col_disable_add_option_field"
                name="type.disable_add_option"
                checked={!disable_add_option}
                disabled={edit_flag !== 'editable'}
                onChange={inverse_checked_change_handler}
                color="primary"
              />
            </FieldContainer>
            {!is_new_column && edit_flag === 'editable' && (
              <FieldContainer>
                <InputLabel htmlFor="validate_options_field">
                  Validate invalid options after save
                </InputLabel>
                <StyledCheckbox
                  id="validate_options_field"
                  name="validate_options"
                  checked={validate_options_field}
                  onChange={set_validate_options_checkbox}
                  color="primary"
                />
              </FieldContainer>
            )}
          </>
        )}
        {col_type === 'reference' && (
          <FieldContainer>
            <StyledField>
              <InputLabel>Referenced tables</InputLabel>

              <TableColumnReferenceSelector
                references={tables}
                on_column_changed={column_selector_handler}
                entity_headers={entity_headers}
                entity_headers_filter={reference_filter}
                onChange={table_selector_handler}
                disabled={edit_flag !== 'editable'}
              />
            </StyledField>
          </FieldContainer>
        )}
        {col_type === 'number' && (
          <FieldContainer>
            <InputLabel>Number format</InputLabel>
            <ConstWidthSelect
              value={number_format_id || ''}
              displayEmpty
              name="type.number_format_id"
              onChange={value_change_handler}
              renderValue={(value: string) => get_number_format_name(value) || 'None'}
              disabled={edit_flag === 'readonly'}
            >
              <FormatMenuItem value={''}>
                None
                <FormatExample>{EXAMPLE_NUMBER_FOR_FORMATTING}</FormatExample>
              </FormatMenuItem>
              {Object.entries(NUMBER_FORMAT_ITEMS).map(([key, format]) => (
                <FormatMenuItem key={key} value={key}>
                  {format.name}
                  <FormatExample>
                    {apply_number_format(EXAMPLE_NUMBER_FOR_FORMATTING, format.parameters)}
                  </FormatExample>
                </FormatMenuItem>
              ))}
            </ConstWidthSelect>
          </FieldContainer>
        )}
        {(col_type === 'date' || col_type === 'date_time') && (
          <FieldContainer>
            <InputLabel>Date format</InputLabel>
            <ConstWidthSelect
              value={date_format_id || ''}
              displayEmpty
              name="type.date_format_id"
              onChange={value_change_handler}
              disabled={edit_flag === 'readonly'}
            >
              <MenuItem value={''}>None</MenuItem>
              {Object.entries(DATE_FORMATS)
                .filter(([, format]) => format.show)
                .map(([key, format]) => (
                  <MenuItem key={key} value={key}>
                    {format.name}
                  </MenuItem>
                ))}
            </ConstWidthSelect>
          </FieldContainer>
        )}
        {col_type === 'date_time' && (
          <FieldContainer>
            <InputLabel>Time format</InputLabel>
            <ConstWidthSelect
              value={time_format_id || ''}
              displayEmpty
              name="type.time_format_id"
              onChange={value_change_handler}
              disabled={edit_flag === 'readonly'}
            >
              <MenuItem value={''}>None</MenuItem>
              {Object.entries(TIME_FORMATS).map(([key, format]) => (
                <MenuItem key={key} value={key}>
                  {format.name}
                </MenuItem>
              ))}
            </ConstWidthSelect>
          </FieldContainer>
        )}
        {col_type === 'boolean' && (
          <FieldContainer>
            <InputLabel>Boolean format</InputLabel>
            <ConstWidthSelect
              value={boolean_format_id || Object.keys(BOOLEAN_FORMAT_TO_VALUES_MAP)[0]}
              displayEmpty
              name="type.boolean_format_id"
              onChange={value_change_handler}
              disabled={edit_flag === 'readonly'}
            >
              {Object.keys(BOOLEAN_FORMAT_TO_VALUES_MAP).map((format_id) => (
                <MenuItem key={format_id} value={format_id}>
                  {format_id}
                </MenuItem>
              ))}
            </ConstWidthSelect>
          </FieldContainer>
        )}
        <FieldContainer>
          <InputLabel htmlFor="col_required_field">Allow empty entries</InputLabel>
          <StyledCheckbox
            id="col_required_field"
            name="type.required"
            checked={is_multi || !required}
            disabled={is_multi || edit_flag !== 'editable'}
            onChange={inverse_checked_change_handler}
            color="primary"
          />
        </FieldContainer>
        <FieldContainer>
          <InputLabel htmlFor="col_computed_field">Computed</InputLabel>
          <StyledCheckbox
            id="col_computed_field"
            name="computed"
            checked={computed}
            disabled={!is_new_column || table.type === 'view_table'}
            onChange={checked_change_handler}
            color="primary"
          />
        </FieldContainer>
        {multi_col_types.includes(col_type) && (
          <FieldContainer>
            <InputLabel htmlFor="col_multi_field">Multiple choices</InputLabel>
            <StyledCheckbox
              id="col_multi_field"
              name="type.multi"
              checked={is_multi}
              disabled={!is_new_column}
              onChange={checked_change_handler}
              color="primary"
            />
          </FieldContainer>
        )}
        <FunctionEditorSwitch
          col_type={col_type}
          style={style_fn}
          tooltip={tooltip_fn}
          fn={computed_fn}
          computed={computed}
          summary={summary}
          on_change={function_change_handler}
          edit_flag={edit_flag}
        />
      </DialogContent>
      <DialogActions>
        {edit_flag !== 'readonly' && (
          <Button color="primary" type="submit">
            Ok
          </Button>
        )}
        <Button color="primary" type="button" onClick={request_close}>
          Close
        </Button>
        {edit_flag !== 'readonly' && (
          <Button color="primary" type="button" onClick={handle_apply} disabled={!dirty}>
            Apply
          </Button>
        )}
      </DialogActions>
    </StyledForm>
  )
}

export default ColSettingsModal
