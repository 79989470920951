/**
 * Storage
 * @namespace Storage
 */

import _ from 'lodash'
import {ComputeResource} from './compute_resource'
import {Runtime} from './create_runtime'
import {StorageDispatch} from './dispatch'
import {
  FetchCommitsAction,
  FetchExternalDataAction,
  FetchResourceAction,
} from './create_runtime_context'
import {compose_resource_id, parse_resource_id, ResourceIdParts} from './params_utils'
import {throw_error} from './utils'
import {create_error, is_error, MaybeError} from './error'
import {get_storage_utils} from './storage_utils'
import {
  ExternalData,
  ExternalFn,
  Resource,
  ResourceData,
  ResourceFn,
  ResourceId,
  ResourceStatus,
  Storage,
  VersionMap,
} from './types/storage'

/**
 * stack is used for tracking the direct dependencies during the computation.
 * Before calculation of resource begins, resource_id is pushed to the stack.
 * If during the computation another resource is accessed, we add direct dependency
 * between calculated (last added resource to the stack) and accessed resource.
 * After computation of resource is done, its id is removed from stack.
 * If fetch was initialized during calculation, in-progress is thrown and stack is cleared.
 * @memberOf Storage
 */
const stack = []

/**
 * If another fetch was needed, 'in-progress' error is thrown.
 * All other errors are caught and are return as error object.
 * @memberOf Storage
 * @param stack {ResourceId[]}
 * @param resource_id {ResourceId}
 * @param fn {ResourceFn<T>}
 * @return {ResourceFn<T>}
 */
function users_calculation<T extends ResourceData = ResourceData>(
  stack: ResourceId[],
  resource_id: ResourceId,
  fn: ResourceFn<T>
): ResourceFn<T> {
  return (runtime: Runtime, resource_id_parts: ResourceIdParts) => {
    try {
      stack.push(resource_id)
      return fn(runtime, resource_id_parts)
    } catch (err) {
      if (is_error(err)) {
        switch (err.type) {
          case 'in-progress':
            throw err
          default:
            return create_error('depend', {original_error: err})
        }
      } else {
        return create_error('user', _.pick(err, ['message', 'stack']))
      }
    } finally {
      stack.pop()
    }
  }
}

type GetResourceArgs<T extends ResourceData = ResourceData> = {
  storage: Storage
  fetch_commits_action: FetchCommitsAction
  fetch_resource_action: FetchResourceAction
  fetch_external_data_action: FetchExternalDataAction
  // Note that storage only uses mutable_dispatch and not dispatch: when storage is dispatching it
  // is always about adding new facts to one consistent state of the world.
  mutable_dispatch: StorageDispatch
  runtime: Runtime
  compute_resource: ComputeResource
  resource_id: ResourceId
  custom_resource_fn?: ResourceFn<T>
  external_resource_fn?: ExternalFn<T>
  loading_data?: T
}

type GetExternalResourceArgs<R extends ExternalData = ExternalData> = Omit<
  GetResourceArgs<R>,
  'resource_id' | 'custom_resource_fn'
> & {cache_key: string}

/**
 * Check out docs for RuntimeContext (create_runtime_context.ts) to get some background.
 * @memberOf Storage
 * @param resource_id {ResourceId}
 * @param storage {Storage}
 * @param fetch_commits_action {FetchCommitsAction}
 * @param fetch_resource_action {FetchResourceAction}
 * @param fetch_external_data_action {FetchExternalDataAction}
 * @param mutable_dispatch {StorageDispatch}
 * @param runtime {Runtime}
 * @param compute_resource {ComputeResource}
 * @param [custom_resource_fn] {ResourceFn<T>}
 * @param [external_resource_fn] {ResourceFn<T>}
 * @param [loading_data] {T}
 * @return {GetResourceArgs<T>}
 */
function get_resource<T extends ResourceData = ResourceData>({
  // what resource to fetch
  resource_id,
  storage,
  // fetchers are needed to obtain resource, if it's not present in storage yet.
  // Fetchers have mutable_dispatch closured within them.
  fetch_commits_action,
  fetch_resource_action,
  fetch_external_data_action,
  // just mutable_dispatch is enough--if anything is missing, we'll just add new
  // data that are consistent with one 'state of world'. This means, mutating is
  // fine.
  mutable_dispatch,
  // this is needed for custom computation
  runtime,
  // recipes for how to compute resources of certain types
  compute_resource,
  // resources which types are not present in the compute_resource map need to
  // come with it's own recipe how to compute them
  custom_resource_fn,
  external_resource_fn,
  loading_data,
}: GetResourceArgs<T>): MaybeError<T> {
  function storage_get(resource_id: ResourceId): Resource<T> | undefined {
    return storage.resources[resource_id] as Resource<T>
  }

  function storage_get_status(resource_id: ResourceId): ResourceStatus | undefined {
    return storage_get(resource_id)?.status
  }

  function storage_get_fn(resource_id: ResourceId) {
    return storage_get(resource_id)?.fn
  }

  /**
   * Has effect inside user_calculation.
   * We use the dependencies structure to track all the transitive dependencies,
   * and the required_by to keep direct ones.
   * @memberOf Storage
   * @function
   */
  const check_dependencies = () => {
    const top_stack_resource_id = _.get(stack, stack.length - 1, null)
    if (top_stack_resource_id != null) {
      const {required_by = {}} = storage_get(resource_id)!
      if (!_.has(required_by, top_stack_resource_id)) {
        mutable_dispatch([
          {
            type: 'add-dependency',
            resource_id: top_stack_resource_id,
            dependency_id: resource_id,
          },
        ])
      }
    }
  }

  /**
   * if resource is done, it can be directly returned without parsing ids and additional checks
   * there needs to be only dependency check if resource is obtained inside user's calculation.
   * @memberOf Storage
   */
  const resource = storage_get(resource_id)
  if (resource?.status === 'done') {
    check_dependencies()
    return resource.result! // Resources in "done" status do have a result.
  }

  const {
    type_is_computable,
    is_custom_resource,
    is_computable_or_custom_resource,
    resource_to_commit,
  } = get_storage_utils(storage)

  const resource_id_components = parse_resource_id(resource_id)
  const {type, entity_id} = resource_id_components

  const header = storage.entity_headers[entity_id]

  if (header == null) {
    // Entity headers have been fetched and this entity_id doesn't have an associated header
    // it means either entity was deleted and someone is trying to reference it
    // or user used entity_id in custom function
    return create_error('user', {subtype: 'unknown-entity', entity_id})
  }

  const {zone_id} = header
  const commits = storage.commits[zone_id]

  if (commits === 'in-progress') {
    throw create_error('in-progress')
  }
  // Entity headers are supposed to be downloaded already.
  if (commits == null && !!storage.downloads.entity_headers![zone_id]) {
    // async call, fetches and sets commits
    fetch_commits_action(zone_id, storage.fixed_date)
    // set "in-progress" on commits that are being fetched
    // when previous call is done "in-progress" will be replaced by actual commits
    mutable_dispatch([{type: 'new-commits', zone_id}])
    throw create_error('in-progress')
  }
  if (is_error(commits)) {
    return commits
  }

  /**
   * Try fetch resources
   * @memberOf Storage
   * @param resource_id {ResourceId}
   */
  function try_fetch_resource(resource_id: ResourceId) {
    const commit_id = resource_to_commit(resource_id)
    fetch_resource_action(commit_id, resource_id)
  }

  /**
   * Try calculate computable or custom resource
   * @memberOf Storage
   * @function
   * @return {MaybeError<T>}
   */
  const try_calculate = (): MaybeError<T> => {
    function has_circular_dependency() {
      return _.has(storage_get(resource_id)!.dependencies, resource_id)
    }
    // Check if there is circular dependency problem. There are two checks here: the first one is to
    // avoid any computation (and possible infinite loop), if the circular dependency was already
    // detected. The second one is to detect circular dependency that may occur as a result of the
    // computation.
    if (has_circular_dependency()) {
      return create_error('circular-dependency')
    }

    // Based on type choose the right function or for custom resources use the given one
    // and calculate it inside users_calculation.
    let result: MaybeError<T>
    if (type_is_computable(type)) {
      // try calculate resource
      result = users_calculation<T>(
        stack,
        resource_id,
        compute_resource[type]
      )(runtime, resource_id_components)
    } else if (is_custom_resource(resource_id)) {
      // "fn" on this type of resource is always defined
      const fn = storage_get_fn(resource_id)! as ResourceFn
      result = users_calculation<T>(stack, resource_id, fn)(runtime, resource_id_components)
    } else {
      return throw_error('Invariant violation')
    }

    // The second circular-dependency check.
    if (has_circular_dependency()) {
      return create_error('circular-dependency')
    }
    return result
  }

  /**
   * Try fetch external resource
   * @memberOf Storage
   * @function
   */
  const try_fetch_external_resource = () => {
    if (loading_data) {
      mutable_dispatch([
        {
          type: 'set-external-data-temporary-result',
          resource_id,
          result: loading_data,
          fn: external_resource_fn,
        },
      ])
    } else {
      mutable_dispatch([{type: 'new-external-data', resource_id, fn: external_resource_fn}])
    }

    fetch_external_data_action(resource_id, external_resource_fn!)
  }

  /**
   * Resolve resource
   * @memberOf Storage
   * @function
   * @return {MaybeError<T>}
   */
  const resolve_resource = (): MaybeError<T> => {
    // if missing, add new resource with in-progress status and star fetch in case of entity
    if (storage_get(resource_id) === undefined) {
      if (custom_resource_fn) {
        mutable_dispatch([{type: 'new-custom-resource', resource_id, fn: custom_resource_fn}])
      } else if (type === 'entity') {
        // async call fetches resource, set it to 'downloads' and if it is at commit we are showing
        // it is set to 'resources' as well
        try_fetch_resource(resource_id)
        // set resource to "in-progress" - it is replaced, once the previous call is finished
        // (for commit we want to show)
        mutable_dispatch([{type: 'new-entity', resource_id}])
        const {entity_id} = parse_resource_id(resource_id)
        const permission_resource_id = compose_resource_id({type: 'permissions', entity_id})
        mutable_dispatch([{type: 'new-permissions', resource_id: permission_resource_id}])
      } else if (type === 'permissions') {
        return create_error('user', {
          message: `Permissions for entity ${entity_id} were not fetched.`,
        })
      } else if (type === 'commit') {
        try_fetch_resource(resource_id)
        mutable_dispatch([{type: 'new-entity-commit', resource_id}])
      } else if (type_is_computable(type)) {
        mutable_dispatch([{type: 'new-computed-resource', resource_id}])
      } else if (type === 'external') {
        try_fetch_external_resource()
      } else {
        return throw_error('Invariant violation.')
      }
    }

    // add new dependency if missing
    check_dependencies()

    // if in-progress resource is computable or custom, calculate it.
    if (storage_get_status(resource_id) === 'in-progress') {
      if (is_computable_or_custom_resource(resource_id)) {
        const result = try_calculate()
        // In a case of circular dependency, the result is already set and we need to avoid it's
        // overwriting.
        if (storage_get_status(resource_id) === 'in-progress') {
          mutable_dispatch([{type: 'set-computed-result', resource_id, result}])
        }
      } else {
        throw create_error('in-progress')
      }
    }

    // At this place, resource should be in storage with 'done' status and result.
    const {result, status} = storage_get(resource_id)!
    if (status === 'done') {
      return result!
    } else {
      return throw_error('Invariant violation.', 'status', status)
    }
  }

  // Proper header is there, let's resolve the result.
  return resolve_resource()
}

function get_external_resource<T extends ExternalData = ExternalData>({
  storage,
  fetch_commits_action,
  fetch_resource_action,
  fetch_external_data_action,
  mutable_dispatch,
  runtime,
  compute_resource,
  external_resource_fn,
  loading_data,
  cache_key,
}: GetExternalResourceArgs<T>): MaybeError<T> {
  if (stack.length === 0) {
    throw_error('Invariant violation.')
  }

  const dependent_resource = parse_resource_id(stack[stack.length - 1])
  if (!('table_id' in dependent_resource)) {
    throw_error('Invariant violation.')
  }

  const resource_id = compose_resource_id({
    type: 'external',
    cache_key,
    table_id: dependent_resource.table_id,
  })

  return get_resource<T>({
    storage,
    fetch_commits_action,
    fetch_resource_action,
    fetch_external_data_action,
    mutable_dispatch,
    runtime,
    compute_resource,
    resource_id,
    external_resource_fn,
    loading_data,
  })
}

const empty_storage: Storage = {
  include_archived: false,
  downloads: {
    resources: {},
    entity_headers: undefined,
    commits: {},
    permissions: {},
  },
  external_data: {},
  entity_headers: {},
  resources: {},
  versions: {},
  commits: {},
  diffs: {},
  diff_cnt: {},
  multidiff: {},
  history_mode: false,
}

function empty_storage_with_version(fixed_version?: VersionMap, fixed_date?: Date): Storage {
  return _.cloneDeep({...empty_storage, fixed_version, fixed_date})
}

export {get_resource, get_external_resource, empty_storage, empty_storage_with_version}
