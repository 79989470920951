import React, {FC, forwardRef, useContext, useEffect, useMemo} from 'react'
import {FixedSizeList, ListChildComponentProps} from 'react-window'

const Row: FC<ListChildComponentProps> = ({data, index, style}) => {
  return React.cloneElement(data[index], {style})
}

/**
 * OuterElementContext is used to pass extra Autocomplete props to OuterElementType
 */
const OuterElementContext = React.createContext({})

/**
 * OuterElementType merges props passed from Autocomplete and (FixedSize)List together
 *
 * It seems to be needed in order to integrate List nicely with Autocomplete, see:
 * https://material-ui.com/components/autocomplete/#virtualization
 */
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext)

  return <div ref={ref} {...props} {...outerProps} />
})

// forward ref to the inner div to make key-navigation work correctly in Autocomplete
const VirtualizedListbox = forwardRef<HTMLDivElement>((props, ref) => {
  const {children, ...other} = props
  const itemData = useMemo(() => React.Children.toArray(children), [children])
  const gridRef = React.useRef<FixedSizeList>(null)

  const itemCount = itemData.length
  const itemSize = 36

  const height = itemSize * (itemCount > 10 ? 10.5 : itemCount)

  // on itemCount change (filter input change) reset scroll to first position
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTo(0)
    }
  }, [itemCount])

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={height}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={itemSize}
          overscanCount={12}
          itemCount={itemCount}
        >
          {Row}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

export default VirtualizedListbox
