import React, {FC, useCallback} from 'react'
import {InputAdornment, makeStyles, TextField} from '@material-ui/core'
import {AlwaysDefinedRuntime, useRuntimeSelector, useTableUiSelector} from '../utils/connect_hocs'
import _ from 'lodash'
import {get_coords} from '../utils/table_helpers'

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,
    width: '16em',
  },
}))

type FindInputProps = {find_text: string; change_find_text: (new_find_text: string) => void}

const FindInput: FC<FindInputProps> = ({find_text, change_find_text}) => {
  const {
    resources: {
      table_resources: {table_entity_id, table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const find_result = useTableUiSelector(table_entity_id, 'find_result')
  const find_position = useTableUiSelector(table_entity_id, 'find_position')
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')

  const handle_change = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const new_find_text = e.target.value
      change_find_text(new_find_text)
    },
    [change_find_text]
  )

  const find_in_progress = find_result && !find_result.done

  const classes = useStyles()

  const adornment_text = !find_result
    ? ''
    : find_position != null &&
      _.isEqual(cursor, get_coords(find_result.cells_order[find_position], table, rows_order))
    ? `${find_position + 1} of ${find_result.cells_order.length}`
    : `${find_result.cells_order.length} results`

  return (
    <TextField
      id={'table-find-input'}
      className={classes.input}
      variant="outlined"
      size={'small'}
      placeholder="Find in table"
      type="text"
      value={find_text}
      onChange={handle_change}
      autoFocus
      InputProps={{
        endAdornment: (
          <>
            {find_in_progress && (
              <InputAdornment position="start">
                <i className="fas fa-spinner fa-spin" />
              </InputAdornment>
            )}
            {!!adornment_text && <InputAdornment position="end">{adornment_text}</InputAdornment>}
          </>
        ),
      }}
    />
  )
}

export default FindInput
