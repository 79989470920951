import React, {FC, useCallback} from 'react'
import {Button, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'

type ConfirmDiscardModalProps = {
  on_discard: () => void
  on_request_close: () => void
}

const ConfirmDiscardModal: FC<ConfirmDiscardModalProps> = ({on_discard, on_request_close}) => {
  const handle_discard = useCallback(() => {
    on_request_close()
    on_discard()
  }, [on_discard, on_request_close])

  return (
    <>
      <DialogTitle disableTypography>
        <h3>Confirm discard</h3>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{margin: 0}}>
          All uncommitted changes made within this project will be lost
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_discard} color="primary">
          Discard
        </Button>
        <Button onClick={on_request_close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmDiscardModal
