import React, {FC, useContext, useRef, useState} from 'react'
import {Auth, CognitoHostedUIIdentityProvider} from '@aws-amplify/auth'
import {IconButton, Menu, MenuItem, ListItemText, Button} from '@material-ui/core'
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone'

import {UserIdentity} from 'common/types/user'

import {UserAccountContext} from '../UserAccountProvider'
import * as local_storage from '../local_storage'

const SignInButton: FC = () => {
  const sign_in = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      // 'customState' is used to store the path
      // to which the user will be redirected
      // after a successful sign in.
      customState: window.location.pathname,
    }).catch((reason) => {
      console.error('sing in error', reason)
    })
  }

  return (
    <Button onClick={sign_in} color="inherit">
      Sign in
    </Button>
  )
}

const get_user_signout_confirmation = (): boolean => // eslint-disable-next-line no-alert
  window.confirm('Your unsaved changes will be lost. Do you really want to sign out?')

type UserButtonProps = {
  current_user: UserIdentity
}

const UserButton: FC<UserButtonProps> = ({current_user}) => {
  const menu_anchor = useRef(null)
  const [menu_visible, set_menu_visible] = useState<boolean>(false)

  const open_menu = () => {
    set_menu_visible(true)
  }

  const close_menu = () => {
    set_menu_visible(false)
  }

  const sign_out = () => {
    // ask for confirmation here only if local storage contains diffs,
    // otherwise the beforeunload event listener will handle prompting the user
    if (!local_storage.has_diffs() || get_user_signout_confirmation()) {
      local_storage.clear_diff_storage()
      Auth.signOut().catch((reason) => console.error('sign out error', reason))
    }
  }

  return (
    <>
      <IconButton color="inherit" size="small" onClick={open_menu} innerRef={menu_anchor}>
        <AccountCircleTwoToneIcon />
      </IconButton>

      <Menu
        anchorEl={menu_anchor.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={menu_visible}
        onClose={close_menu}
      >
        <MenuItem dense divider>
          <ListItemText primary={current_user.name} secondary={current_user.email} />
        </MenuItem>
        <MenuItem dense onClick={sign_out}>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Menu>
    </>
  )
}

const UserDetails: FC = () => {
  const current_user = useContext(UserAccountContext)

  return current_user ? <UserButton current_user={current_user} /> : <SignInButton />
}

export default UserDetails
