import {EntityEditableSchema, EntityUneditableSchema} from 'common/entities/entity_utils'
import {ComputedTable, Entity} from 'common/types/storage'

/**
 * Computed table entity
 * @module ComputedTable
 * @see {@tutorial main_concepts}
 */

const COMPUTED_TABLE_SCHEMA_DEFAULTS: Pick<
  Entity<'computed_table'>,
  keyof EntityEditableSchema<'computed_table'> | 'type'
> = {
  type: 'computed_table',
  name: 'empty_computed_table',
  params: {},
  fn: '',
}

/**
 * Create computed table entity
 * @function
 * @param zone_id {ZoneId}
 * @param parent_id {EntityId}
 * @param entity_id {EntityId}
 * @param schema {Schema}
 * @return {ComputedTable<'entity'>}
 */
const create_computed_table_entity = ({
  zone_id,
  parent_id,
  entity_id,
  archived,
  ...schema
}: Omit<EntityUneditableSchema<'computed_table'>, 'type'> &
  Partial<EntityEditableSchema<'computed_table'>>): ComputedTable<'entity'> => {
  return {
    ...COMPUTED_TABLE_SCHEMA_DEFAULTS,
    zone_id,
    parent_id,
    entity_id,
    archived,
    ...schema,
  }
}

export {COMPUTED_TABLE_SCHEMA_DEFAULTS, create_computed_table_entity}
