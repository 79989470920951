import {RowId, TableEntityId} from 'common/types/storage'
import {TableUiState} from '../utils/connect_hocs'

export type HistoryEntry = {
  table_entity_id: TableEntityId
  row_id: RowId
}

export type DetailedViewHistory = {
  history: HistoryEntry[]
  current: number | null
}

const get_current_entry = ({history, current}: DetailedViewHistory): HistoryEntry | null => {
  return current != null ? history[current] : null
}

const init_history = (table_entity_id: TableEntityId, row_id: RowId): Partial<TableUiState> => {
  return {detailed_view: {history: [{table_entity_id, row_id}], current: 0}}
}

const clear_history = (): Partial<TableUiState> => {
  return {detailed_view: {history: [], current: null}}
}

const back_in_history = ({history, current}: DetailedViewHistory): Partial<TableUiState> => {
  if (current != null) {
    return {detailed_view: {history, current: current - 1}}
  } else {
    return {}
  }
}

const forward_in_history = ({history, current}: DetailedViewHistory): Partial<TableUiState> => {
  if (current != null) {
    return {detailed_view: {history, current: current + 1}}
  } else {
    return {}
  }
}

const push_to_history = (
  {history, current}: DetailedViewHistory,
  table_entity_id: TableEntityId,
  row_id: RowId
): Partial<TableUiState> => {
  if (current != null) {
    const new_history = history.slice(0, current + 1).concat({table_entity_id, row_id})
    return {
      detailed_view: {
        history: new_history,
        current: current + 1,
      },
    }
  } else {
    return init_history(table_entity_id, row_id)
  }
}

const change_row = (detailed_view: DetailedViewHistory, row_id: RowId): Partial<TableUiState> => {
  const {history, current} = detailed_view
  if (current != null) {
    const table_entity_id = history[current].table_entity_id
    return push_to_history(detailed_view, table_entity_id, row_id)
  } else {
    return {}
  }
}

export {
  get_current_entry,
  init_history,
  clear_history,
  back_in_history,
  forward_in_history,
  push_to_history,
  change_row,
}
