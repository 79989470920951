import React, {FC, useState, useEffect, useCallback, useRef} from 'react'
import {makeStyles} from '@material-ui/core'

type AutoSizerProps = {
  children: (size: [number, number]) => JSX.Element
}

const useStyles = makeStyles({
  autosizer: {
    overflow: 'hidden',
    height: '100%',
  },
})

/**
 * Compute container size needed for grid canvas.
 * Implementation inspired by https://css-tricks.com/using-requestanimationframe-with-react-hooks/
 */
const AutoSizer: FC<AutoSizerProps> = ({children}) => {
  const [size, set_size] = useState<[number, number] | null>(null)
  const animation_loop_id = useRef<number>()
  const ref = useRef<HTMLDivElement | null>(null)
  const classes = useStyles()

  const measure = useCallback(() => {
    const el = ref.current
    if (el) {
      const new_size: [number, number] = [el.offsetWidth, el.offsetHeight]
      // We need to pass a function to this settter.
      set_size((size) =>
        size == null || size[0] !== new_size[0] || size[1] !== new_size[1] ? new_size : size
      )

      animation_loop_id.current = window.requestAnimationFrame(measure)
    }
  }, [ref, set_size])

  useEffect(() => {
    measure()
    return () => {
      animation_loop_id.current && window.cancelAnimationFrame(animation_loop_id.current)
    }
    // Empty dependency array is here because we want to start `measure` when component mounts. In
    // the measure function will start animation loop, which will call `measure` every repaint. When
    // component unmounts, we will cancel this animation loop with useEffect cleanup function above.
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className={classes.autosizer}>
      {size && children(size)}
    </div>
  )
}

export default AutoSizer
