import {has_same_elements} from './utils'
import {is_error, create_error, MaybeError} from './error'
import {CellStyle, CellTooltip} from './types/data_table'
import {CellRawValue, RowId} from './types/storage'
import {TableObject} from './objects/data_table'
import _ from 'lodash'
import {BOOLEAN_VALUE_TO_FORMAT_MAP} from 'common/formatting/boolean'

export type ValueValidator<T> = (value: unknown, ...args) => T

// Compare column's row_ids with table's row_ids, return error if the sets aren't equal
export function validate_column_data<T>(
  column: unknown,
  table: TableObject,
  validator: ValueValidator<T>,
  multi?: boolean
): MaybeError<Record<RowId, T>> {
  if (is_error(column)) {
    return column
  } else if (!_.isObject(column)) {
    return create_error('user', {
      subtype: 'column-fn-illegal-return-type',
      message: 'Function did not return a row-value record',
      data: column,
    })
  } else if (!has_same_elements(Object.keys(column), table._row_ids)) {
    return create_error('user', {
      subtype: 'column-fn-illegal-return-type',
      message: 'Function did not return exactly one value for each row',
      data: column,
    })
  } else {
    return _.mapValues(column as Record<string, unknown>, (value) => validator(value, multi))
  }
}

export function validate_cell_raw_value(value: unknown, multi?: boolean): MaybeError<CellRawValue> {
  if (is_error(value)) {
    return value
  } else if (value === null || value === undefined || value === '') {
    return null

    // in multi cells we expect an array of string
  } else if (multi === true) {
    if (!Array.isArray(value)) {
      return create_error('user', {
        subtype: 'column-fn-illegal-return-type',
        message: 'Function did not return an array in multi column',
        data: value,
      })
    } else if (value.some((sub_value) => typeof sub_value !== 'string')) {
      return create_error('user', {
        subtype: 'column-fn-illegal-return-type',
        message: 'Function did not return an array of strings in multi column.',
        data: value,
      })
    } else {
      return value
    }

    // in single cells we expect a string, boolean or a number
  } else if (multi === false) {
    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') {
      return create_error('user', {
        subtype: 'column-fn-illegal-return-type',
        message: 'Function did not return a string, number or boolean in single column',
        data: value,
      })
    } else {
      if (typeof value === 'boolean') {
        const booleanString = String(value)
        if (booleanString !== null && BOOLEAN_VALUE_TO_FORMAT_MAP[booleanString]) {
          return booleanString
        }
        return create_error('user', {
          subtype: 'column-fn-illegal-return-type',
          message: 'Function did not return a valid boolean format',
          data: value,
        })
      }
      return value
    }

    // if multi is undefined we should expect both types
  } else if (Array.isArray(value) && value.every((sub_value) => typeof sub_value === 'string')) {
    return value
  } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
    if (typeof value === 'boolean') {
      const booleanString = String(value)
      if (booleanString !== null && BOOLEAN_VALUE_TO_FORMAT_MAP[booleanString]) {
        return booleanString
      }
      return create_error('user', {
        subtype: 'column-fn-illegal-return-type',
        message: 'Function did not return a valid boolean format',
        data: value,
      })
    }
    return value
  } else {
    return create_error('user', {
      subtype: 'column-fn-illegal-return-type',
      message: 'Function did not return a valid cell value',
      data: value,
    })
  }
}

export function validate_cell_style(value: unknown): MaybeError<CellStyle> {
  if (is_error(value)) {
    return value
  } else if (value === null || value === undefined) {
    return {}
  } else if (_.isObject(value)) {
    return value
  } else {
    return create_error('user', {
      subtype: 'column-fn-illegal-return-type',
      message: 'Function did not return a valid cell style',
      data: value,
    })
  }
}

export function validate_cell_tooltip(value: unknown): MaybeError<CellTooltip> {
  if (is_error(value)) {
    return value
  } else if (value === null || value === undefined) {
    return []
  } else if (typeof value === 'string' || typeof value === 'number') {
    return [value.toString()]
  } else if (Array.isArray(value) && value.every((sub_value) => typeof sub_value === 'string')) {
    return value
  } else {
    return create_error('user', {
      subtype: 'column-fn-illegal-return-type',
      message: 'Function did not return a valid cell tooltip',
      data: value,
    })
  }
}
