import React, {FC, useCallback, useState} from 'react'
import {Popover, Tooltip} from '@material-ui/core'
import HideColumnsEditor from '../HideColumns/HideColumnsEditor'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import CustomButton from '../components/CustomButton'

const HideColumnsButton: FC = () => {
  const {
    storage: {history_mode},
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const [anchor, set_anchor] = useState(null)
  const [open, set_open] = useState(false)

  const on_click = useCallback(
    (e) => {
      set_anchor(e.currentTarget)
      set_open(!open)
    },
    [open]
  )

  return (
    <>
      <Tooltip title="Hide columns">
        <span ref={anchor}>
          <CustomButton
            type="tertiary"
            onClick={on_click}
            disabled={history_mode || !table._can_edit_schema()}
            onlyIcon
          >
            <i className="fas fa-eye-slash" />
          </CustomButton>
        </span>
      </Tooltip>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        open={open}
        onClose={on_click}
        PaperProps={{
          style: {overflowY: 'hidden', display: 'flex'},
        }}
      >
        <HideColumnsEditor />
      </Popover>
    </>
  )
}

export default HideColumnsButton
