import React, {FC, useCallback} from 'react'
import {
  useSetTableUiState,
  useRuntimeSelector,
  AlwaysDefinedRuntime,
  useTableUiSelector,
} from '../utils/connect_hocs'
import ContextMenuItem from './ContextMenuItem'
import Edit from '@material-ui/icons/Edit'

type EditCellItemProps = {
  on_close: () => void
}

const EditCellItem: FC<EditCellItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const set_table_ui_state = useSetTableUiState(table_entity_id)

  const on_click = useCallback(() => {
    if (cursor != null && cursor.row_id != null && cursor.col_id != null) {
      const {row_id, col_id} = cursor

      set_table_ui_state({editing: {row_id, col_id}}, 'start_value_edit')
    }

    on_close()
  }, [set_table_ui_state, cursor, on_close])

  return <ContextMenuItem onClick={on_click} icon={<Edit fontSize="small" />} item="Edit" />
}

export default EditCellItem
