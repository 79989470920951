import React, {FC, useCallback} from 'react'
import {IconButton, Tooltip, CircularProgress, styled} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import {useBlockingCallback} from '../utils/hooks'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {get_project_id} from 'common/project_utils'
import {OrganisationId, ProjectId} from 'common/types/storage'
import {ROUTES} from '../utils/navigation_utils'
import {useHistory} from 'react-router-dom'
import {get_organisation_id} from 'common/permission/permission_utils'

const StyledIconButton = styled(IconButton)(({theme}) => ({
  margin: theme.spacing(1, 1),
}))
const ToggleArchivedButton: FC = () => {
  const {
    storage: {entity_headers, include_archived},
    resources,
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const history = useHistory()

  const entity = {
    ...(resources?.project_resources?.project && {
      entity_id: resources?.project_resources?.project.entity_id,
      archived: resources?.project_resources?.project.archived,
    }),
    ...(resources?.table_resources?.table && {
      entity_id: resources?.table_resources?.table._entity_id,
      archived: resources?.table_resources?.table.archived,
    }),
  }

  const {dispatch_storage, fetch_entity_headers_action} = useRuntimeActions()

  const redirect_to_project = useCallback(
    (organisation_id: OrganisationId, project_id: ProjectId) => {
      history.replace(ROUTES.project(organisation_id, project_id))
    },
    [history]
  )
  const redirect_to_organisation = useCallback(
    (organisation_id: OrganisationId) => {
      history.replace(ROUTES.organisation(organisation_id))
    },
    [history]
  )

  const [toggle, toggling] = useBlockingCallback(async () => {
    if (!include_archived) {
      dispatch_storage({
        type: 'set-include-archived',
        include_archived: true,
      })
    } else {
      if (entity?.archived) {
        const project_id = get_project_id(entity_headers, entity.entity_id)
        const organisation_id = get_organisation_id(entity_headers, entity.entity_id)
        if (!entity_headers[project_id].archived) {
          redirect_to_project(organisation_id, project_id)
        } else {
          redirect_to_organisation(organisation_id)
        }
      }

      dispatch_storage({
        type: 'set-include-archived',
        include_archived: false,
      })
    }
    await fetch_entity_headers_action()
  }, [fetch_entity_headers_action, entity_headers, include_archived, entity])

  return (
    <>
      <Tooltip title={`${include_archived ? 'Hide' : 'Show'} archived tables and projects`}>
        <StyledIconButton color="inherit" size="small" onClick={toggle}>
          {toggling ? (
            <CircularProgress size={24} />
          ) : include_archived ? (
            <Visibility />
          ) : (
            <VisibilityOff />
          )}
        </StyledIconButton>
      </Tooltip>
    </>
  )
}

export default ToggleArchivedButton
