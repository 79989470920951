import React, {FC, useCallback} from 'react'
import {Button, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'
import {EntityType} from 'common/types/storage'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {parse_resource_id} from 'common/params_utils'
import {TableCols} from 'common/objects/data_table'

type ConfirmArchiveModalProps = {
  on_toggle: () => void
  on_request_close: () => void
  is_archived: boolean
  entity_type: EntityType
}

const ConfirmToggleArchivedModal: FC<ConfirmArchiveModalProps> = ({
  on_toggle,
  on_request_close,
  is_archived,
  entity_type,
}) => {
  const {
    resources: {table_resources},
    storage: {resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const table_entity_id = table_resources?.table_entity_id
  const referring_tables =
    table_entity_id &&
    Object.entries(resources)
      .filter(([resource_id, resource]) => {
        return (
          parse_resource_id(resource_id).type === 'entity' &&
          resource.result.type === 'data_table' &&
          Object.values(resource.result.cols as TableCols).some(
            (col) =>
              col.type.type === 'reference' &&
              Object.values(col.type.tables).some(
                (referenced_table) => referenced_table.table_id === table_entity_id
              )
          )
        )
      })
      .map(([resource_id, resource]) => resource.result.name)

  const handle_toggle = useCallback(() => {
    on_request_close()
    on_toggle()
  }, [on_toggle, on_request_close])
  const entity = entity_type === 'project' ? ' project' : ' table'

  return (
    <>
      <DialogTitle disableTypography>
        <h3>
          Confirm {is_archived ? 'unarchiving' : 'archiving'} {entity}
        </h3>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{margin: 0}}>
          Do you really want to {is_archived ? 'unarchive' : 'archive'} this {entity}?
        </Typography>
        {!is_archived && (
          <>
            <Typography variant="body1" style={{margin: 0}}>
              The {entity} will be no longer editable.
            </Typography>

            {referring_tables && referring_tables.length > 0 && (
              <>
                <Typography variant="body1">
                  References in following tables in current project will break:
                </Typography>
                <Typography variant="body1" style={{fontStyle: 'italic'}}>
                  {referring_tables.join(', ')}
                </Typography>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_toggle} color="primary">
          {is_archived ? 'Unarchive' : 'Archive'}
        </Button>
        <Button onClick={on_request_close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmToggleArchivedModal
