import {Filter, FilterOperator, FilterType, UiFilterCondition} from 'common/types/filter'
import {CellType, ColumnId, ColumnType} from 'common/types/storage'
import {uuid} from 'common/utils'
import {get_filter_types_applicable_to_column} from 'common/filter/filter_utils'

export const DEFAULT_FILTER_OPERATOR: FilterOperator = 'and'
export const DEFAULT_FILTER_TYPE_SINGLE: FilterType = 'equal_to'
export const DEFAULT_FILTER_TYPE_MULTI: FilterType = 'contains'

export const DEFAULT_FILTER: Filter<'internal'> = {
  operator: DEFAULT_FILTER_OPERATOR,
  conditions: {},
}

export function get_default_filter_type(col_type: ColumnType<CellType, 'entity'>): FilterType {
  const supported_types = get_filter_types_applicable_to_column(col_type)
  // some columns does not support filters types 'equal_to' or
  // 'contains' (attachment, boolean) used as a default
  if (col_type.multi) {
    return supported_types.indexOf(DEFAULT_FILTER_TYPE_MULTI) === -1
      ? supported_types[0]
      : DEFAULT_FILTER_TYPE_MULTI
  } else {
    return supported_types.indexOf(DEFAULT_FILTER_TYPE_SINGLE) === -1
      ? supported_types[0]
      : DEFAULT_FILTER_TYPE_SINGLE
  }
}

export function get_default_filter(
  col_id: ColumnId,
  col_type: ColumnType<CellType, 'entity'>
): UiFilterCondition {
  return [uuid(), col_id, get_default_filter_type(col_type), '']
}
