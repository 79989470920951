import React, {FC, useCallback, useState, ChangeEvent, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {createStyles, withStyles} from '@material-ui/core/styles'
import {InputAdornment} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {Storage, EntityHeader} from 'common/types/storage'
import {ROUTES} from '../utils/navigation_utils'
import {useRuntimeSelector} from '../utils/connect_hocs'
import TableSelector from '../Selectors/TableSelector'

const StyledTableSelector = withStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
      width: '350px',
    },
    inputRoot: {
      backgroundColor: theme.palette.greenPalette[800],
      color: theme.palette.common.white,
      borderRadius: 4,
    },
  })
)(TableSelector)

const table_selector_filter = (entity: EntityHeader) =>
  entity.subtype !== 'permission_entities' &&
  (entity.type === 'data_table' || entity.type === 'view_table' || entity.type === 'computed_table')

export const QuickSearch: FC = () => {
  const {storage} = useRuntimeSelector()
  const {entity_headers} = storage as Storage
  const history = useHistory()
  const [quicksearch_value, set_quicksearch_value] = useState('')
  const [redirected, set_redirected] = useState(false)

  const redirect_to_selected_table = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (entity_headers[value].type === 'view_table') {
        const base_table_entity_id = entity_headers[value].parent_id
        history.push(ROUTES.table(base_table_entity_id, value))
      } else {
        history.push(ROUTES.table(value, value))
      }
      set_redirected(true)
    },
    [entity_headers, history, set_redirected]
  )

  const change_quicksearch_value = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      set_quicksearch_value(event.target.value || '')
    },
    [set_quicksearch_value]
  )

  useEffect(() => {
    if (redirected) {
      set_quicksearch_value('')
      set_redirected(false)
    }
  }, [redirected])

  return (
    <StyledTableSelector
      entity_headers={entity_headers}
      entity_headers_filter={table_selector_filter}
      value={null}
      placeholder={'Go to table'}
      size={'small'}
      blurOnSelect
      onChange={redirect_to_selected_table}
      onInputChange={change_quicksearch_value}
      inputValue={quicksearch_value}
      start_adornment={
        <InputAdornment position="start">
          <SearchIcon color="inherit" />
        </InputAdornment>
      }
    />
  )
}

export default QuickSearch
