import React, {FC, useMemo} from 'react'
import _ from 'lodash'
import {ConflictData, extract_conflict_data, extract_invalid_data} from './table_problems_utils'
import {Box, Typography, styled, Theme} from '@material-ui/core'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {
  AlwaysDefinedRuntime,
  CursorCoordinates,
  useRuntimeSelector,
  useSetTableUiState,
} from '../utils/connect_hocs'
import {is_valid_coordinates, validate_coordinates} from '../utils/ui_validation'
import {Conflict as ConflictType} from 'common/types/data_table'
import ConflictCard from '../components/ConflictCard'
import {TableObject} from 'common/objects/data_table'

const Icon = styled(ArrowForwardIosIcon)(({theme}) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.greyPalette[400],
  margin: theme.spacing(0, 0.5),
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      borderRadius: 2,
      padding: theme.spacing(0, 1),
    },
    oldValue: {
      backgroundColor: theme.palette.panelBackground.main,
    },
    newValue: {
      backgroundColor: theme.palette.warning.light,
    },
    localValue: {
      backgroundColor: theme.palette.greenPalette[50],
    },
    content: {
      marginRight: theme.spacing(1),
    },
  })
)

type SingleConflictProps = ConflictData
const SingleConflict: FC<SingleConflictProps> = ({title, type, conflicts, cell_coordinates}) => {
  const classes = useStyles()
  const old_style = `${classes.chip} ${classes.oldValue}`
  const new_style = `${classes.chip} ${classes.newValue}`
  const local_style = `${classes.chip} ${classes.localValue}`
  const {
    resources: {
      table_resources: {table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const scroll_to = (cell_coordinates: CursorCoordinates) => {
    if (is_valid_coordinates(table, cell_coordinates)) {
      set_table_ui_state({
        cursor: validate_coordinates(table, cell_coordinates),
        last_cursor: cell_coordinates,
        scroll_to: validate_coordinates(table, cell_coordinates),
        last_scroll_to: cell_coordinates,
        selection_size: [0, 0],
      })
    } else {
      // eslint-disable-next-line no-alert
      alert('Cannot scroll to the cell, is filtered out from the table.')
    }
  }
  return (
    <ConflictCard
      title={title}
      onClick={() => cell_coordinates && scroll_to(cell_coordinates)}
      content={
        <>
          {_.map(conflicts, (conflict, conflict_index) => {
            const {location, values, local_value, remote_value} = conflict
            return (
              <Box key={`conflict_${conflict_index}`}>
                {location && (
                  <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                    <Box mb={0.5} mr={1.5}>
                      <b>Conflicting property:</b>
                    </Box>
                    <Box display="flex" alignItems="center" mb={0.5}>
                      {_.map(location, (prop, prop_index) => {
                        return (
                          <Box
                            key={`location_container_${prop_index}`}
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              display="inline-block"
                              className={old_style}
                              key={`location_${prop_index}`}
                            >
                              {prop}
                            </Box>
                            {prop_index < location!.length - 1 && <Icon />}
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                )}
                {local_value && (
                  <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                    <Box display="flex" mb={0.5} mr={1.5}>
                      <b>Local value:</b>
                    </Box>
                    <Box display="inline-block" className={local_style} mb={0.5}>
                      {local_value}
                    </Box>
                  </Box>
                )}
                {remote_value && (
                  <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                    <Box display="flex" mb={0.5} mr={1.5}>
                      <b>Remote value:</b>
                    </Box>
                    <Box display="inline-block" className={new_style} mb={0.5}>
                      {remote_value}
                    </Box>
                  </Box>
                )}
                <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                  <Box mr={1.5}>
                    <b>Conflicting pairs:</b>
                  </Box>
                  {_.map(values, (pair, value_index) => {
                    return (
                      <Box
                        key={`pair_box_${value_index}`}
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Box
                          display="inline-block"
                          className={old_style}
                          key={`old_value_${value_index}`}
                          my={0.5}
                        >
                          {pair[0]}
                        </Box>
                        <Icon />
                        <Box
                          display="inline-block"
                          className={new_style}
                          key={`new_value_${value_index}`}
                          my={0.5}
                        >
                          {pair[1]}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )
          })}
        </>
      }
    />
  )
}

type ConflictProps = {
  table: TableObject
  conflict: ConflictType
}
const Conflict: FC<ConflictProps> = ({table, conflict}) => {
  const data = useMemo(() => extract_conflict_data(table, conflict), [table, conflict])
  return (
    <>
      {_.map(data, (conflict_data, i) => (
        <SingleConflict key={`single_conflict_${i}`} {...conflict_data} />
      ))}
    </>
  )
}

type InvalidProps = {
  table: TableObject
  invalid: ConflictType
}
const Invalid: FC<InvalidProps> = ({table, invalid}) => {
  const classes = useStyles()
  const old_style = `${classes.chip} ${classes.oldValue}`
  const local_style = `${classes.chip} ${classes.localValue}`

  const invalid_data = useMemo(() => extract_invalid_data(table, invalid), [table, invalid])
  return (
    invalid_data && (
      <ConflictCard
        title={invalid_data.title}
        content={
          <Box>
            {invalid_data.row_label && (
              <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                <Box display="flex" mb={0.5} mr={1.5}>
                  <b>Row:</b>
                </Box>
                <Box display="inline-block" className={old_style} mb={0.5}>
                  {invalid_data.row_label}
                </Box>
              </Box>
            )}
            {invalid_data.col_name && (
              <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                <Box display="flex" mb={0.5} mr={1.5}>
                  <b>Column:</b>
                </Box>
                <Box display="inline-block" className={old_style} mb={0.5}>
                  {invalid_data.col_name}
                </Box>
              </Box>
            )}
            {invalid_data.local_value && (
              <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                <Box display="flex" mb={0.5} mr={1.5}>
                  <b>Local value:</b>
                </Box>
                <Box display="inline-block" className={local_style} mb={0.5}>
                  {invalid_data.local_value}
                </Box>
              </Box>
            )}
            {invalid_data.cell_values && (
              <Box display="flex" alignItems="center" flexWrap="wrap" mb={1.5}>
                <Box mr={1.5}>
                  <b>Local cell values:</b>
                </Box>
                {_.map(invalid_data.cell_values, (cell_value, value_index) => {
                  return (
                    <Box
                      display="inline-block"
                      className={local_style}
                      key={`cell_value_${value_index}`}
                      m={0.5}
                    >
                      {cell_value}
                    </Box>
                  )
                })}
              </Box>
            )}
          </Box>
        }
      />
    )
  )
}

type ProblemProps = {
  problem: ConflictType
}
const Problem: FC<ProblemProps> = ({problem}) => {
  const {
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  switch (problem.type) {
    case 'conflict':
      return <Conflict table={table} conflict={problem} />
    case 'invalid':
      return <Invalid table={table} invalid={problem} />
    default:
      return <Typography variant="subtitle1">Unknown type of problem</Typography>
  }
}

export default Problem
