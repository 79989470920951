import {ColumnId, MultiCellRawValue, RowId, SingleCellRawValue} from 'common/types/storage'
import {CellObject} from 'common/types/data_table'

export type BooleanValueFilter = 'is_empty' | 'is_error'
export const BooleanValueFilterSet: Set<FilterType> = new Set(['is_empty', 'is_error'])

export type SingleColumnFilter = 'equal_to' | 'not_equal_to'
export const SingleColumnFilterSet: Set<FilterType> = new Set(['equal_to', 'not_equal_to'])

export type MultiColumnFilter = 'multi_equal_to' | 'multi_not_equal_to' | 'contains'
export const MultiColumnFilterSet: Set<FilterType> = new Set([
  'multi_equal_to',
  'multi_not_equal_to',
  'contains',
])

export type MultiValueFilter = 'has_any_of' | 'has_none_of'
export const MultiValueFilterSet: Set<FilterType> = new Set(['has_any_of', 'has_none_of'])

export type StringFilter = 'substring' | 'starts_with' | 'ends_with'
export const StringFilterValuesSet: Set<FilterType> = new Set([
  'substring',
  'starts_with',
  'ends_with',
])

export type DateFilter = 'before' | 'after' | 'on_or_before' | 'on_or_after'
export const DateFilterValuesSet: Set<FilterType> = new Set([
  'before',
  'after',
  'on_or_before',
  'on_or_after',
])

export type NumberFilter =
  | 'greater_than'
  | 'less_than'
  | 'greater_than_or_equal_to'
  | 'less_than_or_equal_to'
export const NumberFilterValuesSet: Set<FilterType> = new Set([
  'greater_than',
  'less_than',
  'greater_than_or_equal_to',
  'less_than_or_equal_to',
])

export type BooleanFormatFilter = 'is_format_true' | 'is_format_false'
export const BooleanFormatFilterSet: Set<FilterType> = new Set([
  'is_format_true',
  'is_format_false',
])

export type FilterType =
  | BooleanValueFilter
  | SingleColumnFilter
  | MultiColumnFilter
  | MultiValueFilter
  | StringFilter
  | DateFilter
  | NumberFilter
  | BooleanFormatFilter

type FilterValueStage = 'internal' | 'parsed' // Whether dates are strings or are already parsed to Date objects

export type MultiCellFilterValue = NonNullable<MultiCellRawValue>
export type SingleCellFilterValue<S extends FilterValueStage> = NonNullable<
  SingleCellRawValue | {internal: string; parsed: Date}[S]
>

export type FilterCondition<S extends FilterValueStage> = Partial<
  | Record<BooleanValueFilter, boolean[]>
  | Record<SingleColumnFilter, SingleCellFilterValue<S>[]>
  | Record<MultiColumnFilter | MultiValueFilter, MultiCellFilterValue[]>
  | Record<StringFilter, string[]>
  | Record<NumberFilter, number[]>
  | Record<DateFilter, NonNullable<{internal: string; parsed: Date}[S]>[]>
  | Record<BooleanFormatFilter, string[]>
>

export type FilterValue<S extends FilterValueStage> =
  | SingleCellFilterValue<S>
  | MultiCellFilterValue
  | boolean
export type FilterOperator = 'or' | 'and'
export type FilterConditions<S extends FilterValueStage> = Record<ColumnId, FilterCondition<S>>
export type Filter<S extends FilterValueStage> = {
  operator: FilterOperator
  conditions: FilterConditions<S>
}

export type FilterPredicate<S extends FilterValueStage> = (
  cell: CellObject,
  filter_value: FilterValue<S>
) => boolean

// Representation of filter condition used in UI
export type FilterValueError = string | null
export type FilterConditionId = string
export type UiFilterCondition = [
  FilterConditionId,
  ColumnId,
  FilterType,
  FilterValue<'internal'>,
  FilterValueError?
]

export type FilterValidationResult = {
  value: FilterValue<'internal'> | null
  error?: FilterValueError
}

/**
 * Subtable selection
 * @memberOf TableObject
 * @see {@tutorial computed_tables_user_guide}
 */
export type SubtableSelection = {
  allowed_rows: RowId[]
  allowed_cols: ColumnId[]
}
