import _ from 'lodash'
import {throw_error, is_object, is_null_or_empty, ensure} from '../../utils'
import {create_squash} from './create_squash'

function ensure_keys(structure, recipe, type, what_str) {
  const extra_keys = _.difference(_.keys(structure), _.keys(recipe))
  if (!_.isEmpty(extra_keys)) {
    throw_error(`Record diff ${type} problem: extra keys`, 'where', what_str, 'keys', extra_keys)
  }
}

function record_diff(recipe, delete_empty = true) {
  if (!is_object(recipe)) {
    throw_error('Record_diff problem: Expected to get object.', 'recipe', recipe)
  }

  function should_delete(key, val) {
    return recipe[key].delete_empty && recipe[key].is_empty(val)
  }

  function apply(base, diff): any {
    if (base == null) base = {}

    if (!is_object(base) || !is_object(diff)) {
      throw_error('Record diff apply problem: Expected to get objects.', 'base', base, 'diff', diff)
    }

    for (const [what_str, what] of [
      ['base', base],
      ['diff', diff],
    ]) {
      ensure_keys(what, recipe, 'apply', what_str)
    }

    return Object.fromEntries(
      Object.keys(recipe)
        .map((key) => {
          const old_val = _.get(base, key, recipe[key].default_value)
          if (_.has(diff, key)) {
            const new_val = recipe[key].apply(old_val, diff[key])
            return [key, new_val]
          } else {
            return [key, old_val]
          }
        })
        .filter(([key, val]) => !should_delete(key, val))
    )
  }

  function reverse(diff) {
    return Object.fromEntries(
      Object.keys(diff).map((key) => {
        return [key, recipe[key].reverse(diff[key])]
      })
    )
  }

  function ensure_consistency(base, diff) {
    if (base == null) base = {}
    _.map(Object.keys(diff), (key) => {
      recipe[key].ensure_consistency(_.get(base, key, null), diff[key])
    })
  }

  function unapply(base, diff, ensure: boolean = true) {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(base, reversed_diff)
    return apply(base, reversed_diff)
  }

  function conflict_free(base): any {
    if (!is_object(base)) {
      throw_error('Record diff conflict_free problem: Expected to get object.', 'base', base)
    }

    ensure_keys(base, recipe, 'conflict_free', 'base')

    const new_base = _.mapValues(base, (val, key) => recipe[key].conflict_free(val))
    return _.omitBy(new_base, (val, key) => should_delete(key, val))
  }

  function squash2(diff1, diff2): any {
    if (!is_object(diff1) || !is_object(diff2)) {
      throw_error(
        'Record diff squash problem: Expected to get objects.',
        'diff1',
        diff1,
        'diff2',
        diff2
      )
    }

    for (const [what_str, what] of [
      ['diff1', diff1],
      ['diff2', diff2],
    ]) {
      ensure_keys(what, recipe, 'squash', what_str)
    }

    return Object.fromEntries(
      _.union(_.keys(diff1), _.keys(diff2)).map((key) => {
        if (!_.has(diff1, key)) return [key, diff2[key]]
        if (!_.has(diff2, key)) return [key, diff1[key]]

        const diff_key = recipe[key].squash(diff1[key], diff2[key])
        return [key, diff_key]
      })
    )
  }

  function is_conflict(base) {
    ensure(is_object(base), 'Record diff is_conflict problem: Expected to get object.', base)
    ensure_keys(base, recipe, 'is_conflict', 'base')

    return Object.entries(base).some(([key, val]) => recipe[key].is_conflict(val))
  }

  function get_conflicts(base) {
    ensure(is_object(base), 'Record diff get_conflicts problem: Expected to get object.', base)
    ensure_keys(base, recipe, 'get_conflicts', 'base')

    const conflicts = _.mapValues(base, (val, key) => recipe[key].get_conflicts(val))
    return _.omitBy(conflicts, _.isEmpty)
  }

  // Used to memoize the results of is_empty function. It prevents from repeatedly
  // (recursively) checking if inner diffs are empty if the value hasn't changed.
  const _is_empty = new WeakMap()

  function is_empty(base) {
    ensure(is_object(base), 'Record diff is_empty problem: Expected to get object.', base)
    ensure_keys(base, recipe, 'is_empty', 'base')

    if (!_is_empty.has(base))
      _is_empty.set(
        base,
        Object.entries(base).every(([key, val]) => recipe[key].is_empty(val))
      )
    return _is_empty.get(base)
  }

  function change_diff(old_base, new_base): any {
    if (old_base == null) old_base = {}
    if (new_base == null) new_base = {}

    if (!is_object(old_base) || !is_object(new_base)) {
      throw_error(
        'Record diff change_diff problem: Expected to get objects.',
        'old_base',
        old_base,
        'new_base',
        new_base
      )
    }

    for (const [what_str, what] of [
      ['old_base', old_base],
      ['new_base', new_base],
    ]) {
      ensure_keys(what, recipe, 'change_diff', what_str)
    }

    return Object.fromEntries(
      _.union(Object.keys(old_base), Object.keys(new_base)).map((key) => {
        const old_val = _.get(old_base, key, null)
        const new_val = _.get(new_base, key, null)
        const diff_key = recipe[key].change_diff(old_val, new_val)
        return [key, diff_key]
      })
    )
  }

  function clean_neutral_diffs(diff): any {
    if (!is_object(diff)) {
      throw_error('Record clean_neutral_diffs problem: Expected to get objects.', 'diff', diff)
    }

    ensure_keys(diff, recipe, 'clean_neutral_diffs', 'diff')

    const clean_diff = _.mapValues(diff, (diff_key, key) =>
      recipe[key].clean_neutral_diffs(diff_key)
    )
    return _.omitBy(clean_diff, is_null_or_empty)
  }

  return {
    conflict_free,
    is_conflict,
    get_conflicts,
    is_empty,
    apply,
    reverse,
    ensure_consistency,
    unapply,
    squash2,
    squash: create_squash(squash2),
    change_diff,
    create_diff: (base) => change_diff(null, base),
    remove_diff: (base) => change_diff(base, null),
    clean_neutral_diffs,
    delete_empty,
    default_value: {},
  }
}

export default record_diff
