import React, {FC, useCallback, useState} from 'react'
import ih from 'immutability-helper'
import _ from 'lodash'
import SortingColumnEditor from './SortingColumnEditor'
import SortableList from './SortableList'
import {Box, Card, CardActions, CardContent, styled, Typography} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {useThunkDispatch, useRuntimeSelector, AlwaysDefinedRuntime} from '../utils/connect_hocs'
import {force_recalculate_rows_order} from '../utils/ui_validation'
import {ColumnId, SortOrder} from 'common/types/storage'
import CustomButton from '../components/CustomButton'

type SortRowsEditorProps = {
  on_request_close: () => void
}

const StyledCard = styled(Card)(({theme}) => ({
  minWidth: 415,
}))

const StyledCardActions = styled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 2, 2, 2),
}))

const StyledTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.greyPalette[400],
}))

const SortRowsEditor: FC<SortRowsEditorProps> = ({on_request_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const [order_by, set_order_by] = useState(table._validated_order_by)
  const {dispatch_storage} = useRuntimeActions()
  const dispatch = useThunkDispatch()

  const confirm_change = useCallback(
    (new_order_by = order_by) => {
      if (_.isEqual(new_order_by, table._validated_order_by)) {
        dispatch(force_recalculate_rows_order(table_entity_id))
      } else {
        dispatch_storage(table._actions.change_order_by(new_order_by))
      }

      on_request_close()
    },
    [dispatch_storage, dispatch, table_entity_id, table, order_by, on_request_close]
  )

  const add_ordering = useCallback((col_id: ColumnId, asc: boolean = true) => {
    set_order_by((current_order) => ih(current_order, {$push: [[col_id, asc ? 1 : -1]]}))
  }, [])

  const on_column_change = useCallback((index: number, new_col_id: ColumnId) => {
    set_order_by((current_order) => ih(current_order, {[index]: {0: {$set: new_col_id}}}))
  }, [])

  const remove_row = useCallback((index: number) => {
    set_order_by((current_order) => ih(current_order, {$splice: [[index, 1]]}))
  }, [])

  const reset_all_rows = useCallback(() => {
    set_order_by((current_order) => ih(current_order, {$set: []}))
    confirm_change([])
  }, [set_order_by, confirm_change])

  const toggle_dir = useCallback((index: number) => {
    set_order_by((current_order) =>
      ih(current_order, {[index]: {1: {$set: -current_order[index][1] as SortOrder}}})
    )
  }, [])

  const reorder_rows = useCallback((reordered_rows) => {
    set_order_by(_.map(reordered_rows, (row) => [row.props.col_id, row.props.asc || 1]))
  }, [])

  const selected_col_ids = order_by.map(([col_id]) => col_id)
  const remaining_cols_order = table._cols_order.filter(
    (col_id) => !selected_col_ids.includes(col_id)
  )

  const allow_new_sort = !_.isEmpty(remaining_cols_order) && order_by.length < 3

  return (
    <StyledCard>
      <CardContent>
        {(order_by.length > 0 && (
          <SortableList on_item_move={reorder_rows}>
            {order_by.map(([col_id, dir], index) => (
              <SortingColumnEditor
                key={col_id}
                col={table._cols[col_id]}
                columns={table._cols_order
                  .filter((_col_id) => !selected_col_ids.includes(_col_id) || _col_id === col_id)
                  .map((_col_id) => [_col_id, table._cols[_col_id].name])}
                on_column_change={on_column_change}
                asc={dir}
                col_id={col_id}
                remove={remove_row}
                toggle_dir={toggle_dir}
                index={index}
              />
            ))}
          </SortableList>
        )) || <StyledTypography variant="body2">No sorts applied to this view</StyledTypography>}
      </CardContent>
      <StyledCardActions>
        <Box display="flex" alignItems="center" mr={2}>
          <CustomButton
            type="secondary"
            startIcon={<Add />}
            disabled={!allow_new_sort}
            onClick={() => add_ordering(remaining_cols_order[0])}
            disableElevation
          >
            Add sort
          </CustomButton>
          <Box ml={1}>
            <CustomButton type="primary" onClick={() => confirm_change()}>
              Apply
            </CustomButton>
          </Box>
        </Box>
        <CustomButton type="tertiary" onClick={reset_all_rows} disabled={order_by.length < 1}>
          Reset all sorts
        </CustomButton>
      </StyledCardActions>
    </StyledCard>
  )
}

export default SortRowsEditor
