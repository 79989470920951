import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {Box, Button} from '@material-ui/core'

import {ErrorObject} from 'common/error'

import ErrorMessage from '../components/ErrorMessage'
import {ROUTES} from '../utils/navigation_utils'

type ErrorPageProps = {
  error?: Error | ErrorObject | string | null
}

const ErrorPage: FC<ErrorPageProps> = ({error}) => {
  return (
    <Box textAlign="center" padding={4}>
      <ErrorMessage error={error} />
      <Button color="primary" component={Link} to={ROUTES.browser()}>
        Go Home
      </Button>
    </Box>
  )
}

export default ErrorPage
