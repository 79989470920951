import {convert_date_time_to_iso_string, convert_date_to_iso_string, throw_error} from './utils'
import {format_error, is_error, MaybeError} from './error'
import {conform_single_value_to_type} from './types'
import {format as formatDate} from 'date-fns'
import {apply_number_format, get_number_format} from 'common/formatting/number'

import {CellRawValue, CellType, ColumnType, SingleCellRawValue} from './types/storage'
import {SingleCellValue, SingleOptionValue, TableRowObject} from './types/data_table'
import {Runtime} from './create_runtime'
import {DateFormatId, get_date_format, get_time_format, TimeFormatId} from 'common/formatting/date'
import {
  BOOLEAN_FORMAT_TO_VALUES_MAP,
  BOOLEAN_VALUE_TO_FORMAT_MAP,
  BooleanFormatId,
} from 'common/formatting/boolean'
import _ from 'lodash'

export const date_to_formatted_string = (value: Date, date_format_id?: DateFormatId): string => {
  const date_format = get_date_format(date_format_id)
  return date_format ? formatDate(value, date_format.template) : convert_date_to_iso_string(value)
}

export const date_time_to_formatted_string = (
  value: Date,
  date_format_id?: DateFormatId,
  time_format_id?: TimeFormatId
): string => {
  const date_format = get_date_format(date_format_id)
  const time_format = get_time_format(time_format_id)
  if (date_format || time_format) {
    const date_string = date_format ? formatDate(value, date_format.template) : ''
    const time_string = time_format ? formatDate(value, time_format.template) : ''
    const space = date_string !== '' && time_string !== '' ? ' ' : ''
    return date_string + space + time_string
  }
  return convert_date_time_to_iso_string(value)
}

export const boolean_string_to_formatted_string = (
  value: string,
  boolean_format_id?: BooleanFormatId
): string => {
  if (boolean_format_id) {
    const raw_value_format_id = BOOLEAN_VALUE_TO_FORMAT_MAP[value]
    if (!_.isEmpty(raw_value_format_id)) {
      const value_format = BOOLEAN_FORMAT_TO_VALUES_MAP[raw_value_format_id]
      return value_format[0] === value
        ? BOOLEAN_FORMAT_TO_VALUES_MAP[boolean_format_id][0]
        : BOOLEAN_FORMAT_TO_VALUES_MAP[boolean_format_id][1]
    }
  }
  return value
}

export const single_value_to_string = (
  value: MaybeError<SingleCellValue>,
  col_type: ColumnType<CellType, 'entity'>,
  enable_formatting: boolean = true
): string | number | boolean => {
  if (is_error(value)) {
    return format_error(value)
  }

  if (value == null) {
    return ''
  }

  const type = col_type.type
  switch (type) {
    case 'reference': {
      const {row_id, table} = value as TableRowObject
      const referenced_table_column = Object.values(col_type.tables).find(
        (table_reference) => table_reference.table_id === table._entity_id
      )

      const label_resource = table._get_label(
        referenced_table_column?.column_id ?? undefined,
        enable_formatting
      )
      // label can be error in case of cyclic dependency
      if (is_error(label_resource)) return format_error(label_resource)
      // if table doesn't contain row with row_id the value is an error
      return label_resource[row_id]
    }
    case 'date':
      return date_to_formatted_string(
        value as Date,
        enable_formatting ? col_type.date_format_id : undefined
      )
    case 'date_time':
      return date_time_to_formatted_string(
        value as Date,
        enable_formatting ? col_type.date_format_id : undefined,
        enable_formatting ? col_type.time_format_id : undefined
      )
    case 'option':
      return (value as SingleOptionValue).name
    case 'number':
      return !enable_formatting
        ? (value as number)
        : col_type.number_format_id
        ? apply_number_format(value as number, get_number_format(col_type.number_format_id))
        : String(value)

    case 'string':
    case 'markdown':
    case 'attachment':
      return String(value)
    case 'boolean':
      return enable_formatting
        ? boolean_string_to_formatted_string(String(value), col_type.boolean_format_id)
        : (value as boolean)
    default:
      return throw_error('unknown cell type', 'type', type)
  }
}

export const raw_value_to_string_wrapper = (
  runtime: Runtime,
  raw_value: CellRawValue,
  col_type: ColumnType<CellType, 'entity'>
): string => {
  if (raw_value === null) {
    return ''
  }

  const raw_value_to_string = (raw_value: SingleCellRawValue) => {
    const value = conform_single_value_to_type(runtime, raw_value, col_type)
    return String(single_value_to_string(value, col_type))
  }

  if (Array.isArray(raw_value)) {
    return raw_value.map(raw_value_to_string).join(', ')
  } else {
    return raw_value_to_string(raw_value)
  }
}
