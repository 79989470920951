import React, {FC} from 'react'
import MarkdownIt from 'markdown-it'
import classNames from 'classnames'
import _ from 'lodash'
import {is_error} from 'common/error'
import ReferenceValue from './ReferenceValue'
import styles from './CellDetail.module.css'
import {COL_TYPES} from 'common/types'
import {CellType, ColumnType, TableEntityId} from 'common/types/storage'
import {CellObject, SimpleCellObject} from 'common/types/data_table'

const md = new MarkdownIt()

const get_value = (table_entity_id: TableEntityId, cell: SimpleCellObject, type: CellType) => {
  if (is_error(cell.error)) return `${cell.get_label()} ${cell.raw_value}`
  if (type === 'reference') return <ReferenceValue table_entity_id={table_entity_id} cell={cell} />
  return cell.get_label()
}

const multiple_values = (table_entity_id: TableEntityId, subcells: CellObject, type: CellType) => {
  if (!_.isArray(subcells)) return null

  return subcells.map((subcell, i) => {
    const color = is_error(subcell.error) ? styles.backgroundErr : styles.backgroundOk
    return (
      <div key={i} className={classNames(styles.pill, color)}>
        {get_value(table_entity_id, subcell, type)}
      </div>
    )
  })
}

type ViewOnlyCellDetailProps = {
  table_entity_id: TableEntityId
  cell: CellObject
  col_type: ColumnType<CellType, 'entity'>
}

const ViewOnlyCellDetail: FC<ViewOnlyCellDetailProps> = ({table_entity_id, cell, col_type}) => {
  if (col_type.multi) {
    return <>{multiple_values(table_entity_id, cell, col_type.type)}</>
  } else if (cell.error) {
    return <div className={styles.error}>{`${cell.get_label()} ${cell.error.message}`}</div>
  } else if (!COL_TYPES.includes(col_type.type)) {
    return <div className={styles.error}>Unknown column type</div>
  } else if (col_type.type === 'reference' && cell.length > 0) {
    return <ReferenceValue table_entity_id={table_entity_id} cell={cell[0]} />
  } else if (col_type.type === 'markdown') {
    const html = {__html: md.render(String(cell.get_label()))}
    return <div dangerouslySetInnerHTML={html} />
  } else {
    return <>{cell.get_label()}</>
  }
}

export default ViewOnlyCellDetail
