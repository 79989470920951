import _ from 'lodash'
import {ensure, throw_error} from '../utils'
import value_diff from '../diffs/base/value_diff'
import {is_error, MaybeError} from '../error'
import {compose_reference_id, compose_resource_id, ReferenceId} from '../params_utils'
import {Runtime} from '../create_runtime'

import {CellRawValue, ColumnId, DataTable, EntityId, RowId, TableId} from '../types/storage'

import {
  CellConflict,
  CellObject,
  CellValue,
  ColumnResource,
  TableRowObject,
} from 'common/types/data_table'
import {TableObject} from 'common/objects/data_table'
import {construct_cell} from 'common/objects/table_cell'

const {get_value, is_conflict, get_conflicts} = value_diff<CellRawValue>()

const create_row = ({
  runtime,
  name: table_name,
  table,
  entity_id,
  table_id,
  cells,
  cols,
  label,
  cols_order,
  computed_cols,
}: {
  runtime: Runtime
  name: string
  entity_id: EntityId
  table_id: TableId
  table: TableObject
  cells: DataTable<'entity'>['cells']
  cols: DataTable<'entity'>['cols']
  label: ColumnId | undefined
  cols_order: ColumnId[]
  computed_cols: Record<ColumnId, MaybeError<ColumnResource<'column'>>>
}) => (row_id: RowId): TableRowObject => {
  const slots = cells.slots
  const row_cells_data = cells.data[row_id]

  ensure(slots != null, 'Missing slots')
  ensure(row_cells_data != null, 'Table does not have row with given id', {
    type: 'unknown-row',
    table_name,
    row_id,
  })

  function _cell(col_id: ColumnId) {
    ensure(cols[col_id] != null, 'Table does not have column with given id.', {
      type: 'unknown-column',
      table_name,
      col_id,
    })

    const {type, computed = false, fn} = cols[col_id]
    let raw_value: MaybeError<CellRawValue>
    let conflicts: CellConflict[] | null = null
    if (computed) {
      if (fn && !computed_cols[col_id]) {
        computed_cols[col_id] = runtime._get_resource(
          compose_resource_id({type: 'column', column_id: col_id, table_id})
        )
      }
      const computed_col = computed_cols[col_id]
      if (is_error(computed_col)) {
        raw_value = computed_col
      } else if (computed_col == null || !_.has(computed_col, row_id)) {
        return throw_error('invariant violation')
      } else {
        raw_value = computed_col[row_id]
      }
    } else {
      const index = slots[col_id]
      const cell = row_cells_data[index]
      if (is_conflict(cell)) {
        raw_value = get_value(cell)
        conflicts = get_conflicts(cell)
      } else {
        raw_value = cell
      }
    }
    return construct_cell(runtime, raw_value, conflicts, type)
  }

  function _get(col_id: ColumnId): CellValue {
    return _cell(col_id).get_value()
  }

  function cell(col_name: string): CellObject {
    const col_id = _.findKey(cols, {name: col_name})
    ensure(col_id != null, 'Table does not have column with given name', {
      type: 'unknown-column',
      table_name,
      col_name,
    })
    return _cell(col_id)
  }

  function get(col_name: string): CellValue {
    return cell(col_name).get_value()
  }

  function get_label(
    col_id?: ColumnId,
    enable_formatting: boolean = true
  ): string | number | boolean {
    // if the label is not defined or does not contains valid column id, use the first column
    let label_col_id: string | null = null
    if (col_id && cols[col_id]) {
      label_col_id = col_id
    } else if (label && cols[label]) {
      label_col_id = label
    } else {
      label_col_id = cols_order[0]
    }
    // return textual representation of cell in labeled column
    // if the table has no columns, return row_id
    return label_col_id ? _cell(label_col_id).get_label(enable_formatting) : row_id
  }

  function get_reference_id(): ReferenceId {
    return compose_reference_id({entity_id, row_id})
  }

  // row API
  return {
    table_id,
    table,
    row_id,
    get, // value by column_name
    _get, // value by column_id
    cell, // cell by column_name
    _cell, // cell by column_id
    get_label,
    get_reference_id,
  }
}

export {create_row}
