import React, {FC} from 'react'
import styles from './ColDragMarker.module.css'
import classNames from 'classnames'

type ColDragMarkerProps = {
  type: 'source' | 'target_right' | 'target_left'
  left: number
  top: number
  width: number
  height: number
}

export const ColDragMarker: FC<ColDragMarkerProps> = ({left, top, width, height, type}) => {
  return (
    <div
      className={classNames(
        type === 'target_right' && styles.colMarkerRight,
        type === 'target_left' && styles.colMarkerLeft,
        type === 'source' && styles.colMarkerFull
      )}
      style={{left, top, width, height}}
    />
  )
}
