import React, {FC, RefCallback, RefObject} from 'react'
import {Typography} from '@material-ui/core'
import {Variant} from '@material-ui/core/styles/createTypography'

type CustomLabelProps = {
  label: string
  elementVariant?: Variant | 'inherit'
  showModificationIndicator?: boolean
  showArchivedIndicator?: boolean
  forwardedRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement> | null
}

const CustomLabel: FC<CustomLabelProps> = ({
  elementVariant = 'inherit',
  label,
  showModificationIndicator = false,
  showArchivedIndicator = false,
  forwardedRef,
  ...rest
}) => {
  let labelWithIndicators = showModificationIndicator ? `*${label}` : label
  labelWithIndicators += showArchivedIndicator ? ' (archived)' : ''

  return (
    <Typography
      innerRef={forwardedRef}
      variant={elementVariant}
      style={{fontStyle: showModificationIndicator ? 'italic' : 'inherit'}}
      color={showArchivedIndicator ? 'textSecondary' : undefined}
      {...rest}
    >
      {labelWithIndicators}
    </Typography>
  )
}

export default React.forwardRef<HTMLDivElement, CustomLabelProps>((props, ref) => (
  <CustomLabel {...props} forwardedRef={ref} />
))
