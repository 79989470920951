import React, {ChangeEvent, FC, useCallback, MouseEvent, KeyboardEvent} from 'react'
import {IconButton, styled, TextField} from '@material-ui/core'
import {OptionId} from 'common/types/storage'

type OptionProps = {
  id: OptionId
  name: string
  focused: boolean
  validation_message: string
  remove_option_with_id: (option_id: OptionId) => void
  add_option: () => void
  on_change: (option_id: OptionId, new_name: string) => void
  button_disabled?: boolean
  input_disabled?: boolean
}

const StyledTextField = styled(TextField)({
  fontSize: 'small',
  paddingTop: 1,
  margin: 2,
})

const Option: FC<OptionProps> = ({
  id,
  name,
  focused,
  validation_message,
  button_disabled,
  input_disabled,
  remove_option_with_id,
  add_option,
  on_change,
}) => {
  const remove_option = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      remove_option_with_id(id)
    },
    [id, remove_option_with_id]
  )

  const handle_key_down = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (add_option) add_option()
      }
      if (e.key === 'Backspace') {
        if (name.length === 0) {
          e.preventDefault()
          remove_option_with_id(id)
        }
      }
    },
    [add_option, id, name.length, remove_option_with_id]
  )

  const handle_change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      on_change(id, e.target.value)
    },
    [id, on_change]
  )

  return (
    <div>
      <StyledTextField
        size="small"
        placeholder={'Option name'}
        inputRef={(input) => {
          input && focused && input.focus()
        }}
        error={!!validation_message}
        helperText={validation_message}
        value={name}
        onChange={handle_change}
        onKeyDown={handle_key_down}
        disabled={input_disabled}
      />
      <IconButton size="small" onClick={remove_option} disabled={button_disabled}>
        <i className="fas fa-times-circle" />
      </IconButton>
    </div>
  )
}

export default Option
