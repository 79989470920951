import _ from 'lodash'
import {create_error, is_error, MaybeError} from 'common/error'
import {throw_error} from 'common/utils'
import {
  PERMISSION_TABLE_SUBTYPES,
  PermissionTableSubtype,
  memberships_col_ids,
  users_col_ids,
  roles_col_ids,
  permissions_col_ids,
  entities_col_ids,
} from 'common/permission/permission_tables'
import {TableObject} from 'common/objects/data_table'
import {
  EntityDiff,
  EntityHeader,
  EntityHeaders,
  EntityId,
  EntityType,
  OrganisationId,
  TableSubtype,
  ZoneId,
} from 'common/types/storage'
import {SingleOptionValue, TableRowObject} from 'common/types/data_table'
import {RuntimeContext} from 'common/create_runtime_context'
import constant_diff from 'common/diffs/base/constant_diff'
import {Runtime} from 'common/create_runtime'

const subtype_code = {
  permission_entities: 'ENT',
  permission_users: 'USR',
  permission_roles: 'RLS',
  permission_memberships: 'MBR',
  permission_permissions: 'PRM',
}

const get_permission_table_entity_id = (
  zone_id: ZoneId,
  subtype: PermissionTableSubtype
): EntityId => `${subtype_code[subtype]}${zone_id}`

const is_permission_table_subtype = (
  subtype: TableSubtype | undefined
): subtype is PermissionTableSubtype =>
  subtype != null && (PERMISSION_TABLE_SUBTYPES as ReadonlyArray<TableSubtype>).includes(subtype)

const get_roles_for_user = (memberships_table: TableObject, user_email: string): string[] => {
  return Array.from(
    // Permission tables are filled by user:
    // there can be duplicates
    new Set(
      Object.values(
        // tables can contained errors or empty values
        // erroneous rows will throw, but compute_column catch them and we ignore error at the end
        memberships_table.compute_column((row) => {
          if (
            // who column is reference to the Users table
            (row._get(memberships_col_ids.who) as TableRowObject)._get(users_col_ids.email) ===
            user_email
          ) {
            // role column is reference to the Roles table
            return (row._get(memberships_col_ids.role) as TableRowObject[]).map((row) =>
              row._get(roles_col_ids.role_name)
            )
          }
          return []
        })
      ).flatMap((roles_names) => (is_error(roles_names) ? [] : roles_names))
    )
  )
}

export type PermissionType = 'read' | 'admin' // 'write will be added later
export type PermissionObject<T extends PermissionType = PermissionType> = {
  admin: {type: 'admin'}
  read: {type: 'read'; read_fn?: string}
}[T]
export type EntityPermissionsObjects = Partial<Record<EntityType, PermissionObject[]>>
type EntitySuperEntitiesIds = Partial<Record<EntityType, EntityId>>

const get_all_super_entities = (
  entity_headers: EntityHeaders,
  entity_id: EntityId
): EntitySuperEntitiesIds => {
  const parent_entity_id = entity_headers[entity_id].parent_id

  if (parent_entity_id === entity_id) {
    return {[entity_headers[entity_id].type]: entity_id}
  }

  return {
    ...get_all_super_entities(entity_headers, parent_entity_id),
    // we don't specify permission for view_table
    ...(entity_headers[entity_id].type !== 'view_table'
      ? {[entity_headers[entity_id].type]: entity_id}
      : {}),
  }
}

const get_permissions_on_one_entity_for_roles = (
  permissions_table: TableObject,
  entity_id: EntityId,
  roles: string[],
  user?: string
): PermissionObject[] => {
  return Object.values(
    // tables can contained errors or empty values
    // erroneous rows will throw, but compute_column catch them and we ignore error at the end
    permissions_table.compute_column((row): PermissionObject | null => {
      const referenced_who = row._get(permissions_col_ids.who) as TableRowObject
      // Who can be either reference to Users or Roles
      switch (referenced_who.table._subtype) {
        case 'permission_roles': {
          if (!roles.includes(referenced_who._get(roles_col_ids.role_name) as string)) return null
          break
        }
        case 'permission_users': {
          if (referenced_who._get(users_col_ids.email) !== user) return null
          break
        }
        default:
          return throw_error('Unexpected table subtype', {
            subtype: referenced_who.table._subtype,
          })
      }

      if (
        (row._get(permissions_col_ids.what) as TableRowObject)._get(
          entities_col_ids.resource_id
        ) === entity_id
      ) {
        return {
          type: (row._get(permissions_col_ids.permission) as SingleOptionValue).value,
          filter: row._get(permissions_col_ids.filter),
        } as PermissionObject
      }

      return null
    })
  ).filter((permissions) => !is_error(permissions) && permissions != null)
}

const get_all_permissions_on_entity_for_user = (
  entity_headers: EntityHeaders,
  memberships_table: TableObject,
  permissions_table: TableObject,
  entity_id: EntityId,
  user_email: string
): EntityPermissionsObjects => {
  const roles = get_roles_for_user(memberships_table, user_email)
  const super_entities_ids = get_all_super_entities(entity_headers, entity_id)
  return _.mapValues(super_entities_ids, (entity_id: EntityId) =>
    get_permissions_on_one_entity_for_roles(permissions_table, entity_id, roles, user_email)
  )
}

type PermissionUnion = {
  admin?: boolean
  read_all?: boolean
  read_fn?: string[]
}

export type EntityPermission = {
  read_all: boolean
  read_fn?: string[]
  history: boolean
  write: boolean
  create?: boolean
}

type EntityPermissionKey = keyof EntityPermission

// Permission on organisation/project can be:
// - 'admin', (create project/table, edit entity and permissions in zone, edit/read with history)
// - 'read', (read everything below with history)
// where read is included in the 'admin' permission
const union_zone_permissions = (permissions: PermissionObject[]): PermissionUnion => {
  return {
    admin: permissions.some(({type}) => type === 'admin'),
    read_all: permissions.some(({type}) => ['read', 'admin'].includes(type)),
  }
}

// Permission on table can be
// - admin, (create view, edit schema and data, read without history)
// - 'read' with read_fn option (read w/o history, if read_fn filtered read, read and edit views)
// where unfiltered read is included in the 'admin' permission
const union_table_permissions = (permissions: PermissionObject[]): PermissionUnion => {
  return {
    admin: permissions.some(({type}) => type === 'admin'),
    read_all: permissions.some(
      (permission) =>
        permission.type === 'admin' || (permission.type === 'read' && permission.read_fn == null)
    ),
    read_fn: permissions.flatMap((permission) =>
      permission.type === 'read' && permission.read_fn != null ? [permission.read_fn] : []
    ),
  }
}

const get_read_permission_data_table = (
  organisation: PermissionUnion,
  project: PermissionUnion,
  table: PermissionUnion
): Pick<EntityPermission, 'read_all' | 'read_fn'> => {
  if (organisation.read_all || project.read_all || table.read_all) {
    return {read_all: true}
  } else if (table.read_fn && table.read_fn.length !== 0) {
    return {read_all: false, read_fn: table.read_fn}
  } else {
    return {read_all: false}
  }
}

// this is only private helper for get_permission_on_entity_for_user
// and we rely that permissions are relevant for entity
const _get_permission_on_entity = (
  entity_header: EntityHeader,
  permissions: EntityPermissionsObjects
): EntityPermission => {
  const union_org = union_zone_permissions(permissions.organisation || [])
  const union_project = union_zone_permissions(permissions.project || [])
  const union_table = union_table_permissions(
    permissions.data_table || permissions.computed_table || []
  )

  const {type} = entity_header
  switch (type) {
    case 'organisation': {
      const is_admin = union_org.admin || false
      return {read_all: true, history: true, write: is_admin, create: is_admin}
    }
    case 'project': {
      const is_admin = union_org.admin || union_project.admin || false
      return {read_all: true, history: true, write: is_admin, create: is_admin}
    }
    case 'computed_table': {
      const is_admin = union_org.admin || union_project.admin || union_table.admin || false
      return {read_all: true, history: true, write: is_admin, create: is_admin}
    }
    case 'data_table': {
      if ((PERMISSION_TABLE_SUBTYPES as ReadonlyArray<any>).includes(entity_header.subtype)) {
        // Permission tables can read and edit only organisation admin
        const is_org_admin = union_org.admin || false
        return {
          read_all: is_org_admin,
          history: is_org_admin,
          write: is_org_admin,
          create: is_org_admin,
        }
      }

      const is_table_admin = union_org.admin || union_project.admin || union_table.admin || false
      return {
        ...get_read_permission_data_table(union_org, union_project, union_table),
        history: union_org.read_all || union_project.read_all || false,
        write: is_table_admin,
        create: is_table_admin,
      }
    }
    case 'view_table': {
      const can_read_parent_table =
        union_org.read_all ||
        union_project.read_all ||
        union_table.read_all ||
        (union_table.read_fn && union_table.read_fn.length !== 0) ||
        false

      return {
        read_all: can_read_parent_table,
        history: union_org.read_all || union_project.read_all || false,
        write: can_read_parent_table,
      }
    }
    default:
      return throw_error('Unexpected entity type', type)
  }
}

const get_organisation_id = (
  entity_headers: EntityHeaders,
  entity_id: EntityId
): OrganisationId => {
  const entity = entity_headers[entity_id]
  if (entity.type === 'organisation') {
    return entity_id
  }
  return get_organisation_id(entity_headers, entity.parent_id)
}

async function get_permission_on_entity_for_user(
  {run}: RuntimeContext,
  entity_id: EntityId,
  user_email: string
): Promise<EntityPermission> {
  return await run((runtime) => {
    const entity_headers = runtime.get_headers()

    if (entity_headers[entity_id] == null) {
      throw create_error('user', {
        subtype: 'unknown-entity',
        entity_id,
        message: `Entity with id ${entity_id} does not exist`,
      })
    }

    const organisation_id = get_organisation_id(entity_headers, entity_id)

    const [memberships_table, permissions_table] = runtime.get_all([
      () => runtime.get_permission_table(organisation_id, 'permission_memberships'),
      () => runtime.get_permission_table(organisation_id, 'permission_permissions'),
    ])
    const permissions = get_all_permissions_on_entity_for_user(
      entity_headers,
      memberships_table,
      permissions_table,
      entity_id,
      user_email
    )

    return _get_permission_on_entity(entity_headers[entity_id], permissions)
  })
}

async function get_parent_permissions(
  runtime_context,
  entity_id: EntityId,
  multidiff: Record<EntityId, EntityDiff>,
  user_email: string
): Promise<MaybeError<EntityPermission>> {
  // we shouldn't try to get permissions of organisation's parent entity
  if (multidiff[entity_id].type === 'organisation') {
    return create_error('missing-privileges', {
      subtype: 'create',
      entity_id,
      message: `You can't create new organisation with id: ${entity_id}`,
    })
  }

  const parent_id = constant_diff().get_new_value(multidiff[entity_id].parent_id)

  try {
    return await get_permission_on_entity_for_user(runtime_context, parent_id, user_email)
  } catch (err) {
    // if parent entity doesn't exist in the system yet, try getting its parent's permissions
    if (is_error(err) && err.type === 'user' && err.subtype === 'unknown-entity') {
      const parent_diff = multidiff[parent_id]
      if (!parent_diff) {
        return create_error('user', {
          subtype: 'unknown-parent-entity',
          entity_id: parent_id,
          message: `Parent entity with id ${parent_id} does not exist`,
        })
      }

      // if we create for example table together with its parent project,
      // we recursively check for the highest existing entity and return its permissions
      return await get_parent_permissions(runtime_context, parent_id, multidiff, user_email)
    } else {
      throw err
    }
  }
}

const _has_permissions = (
  runtime: Runtime,
  entity_id: EntityId,
  type: EntityPermissionKey
): boolean => {
  const permissions = runtime.get_permissions_for_entity(entity_id)
  return !is_error(permissions) && !!permissions[type]
}

const has_create_permissions = (runtime: Runtime, entity_id: EntityId): boolean =>
  _has_permissions(runtime, entity_id, 'create')

const has_write_permissions = (runtime: Runtime, entity_id: EntityId): boolean =>
  _has_permissions(runtime, entity_id, 'write')

export {
  get_permission_table_entity_id,
  get_roles_for_user,
  get_all_super_entities,
  get_all_permissions_on_entity_for_user,
  get_permissions_on_one_entity_for_roles,
  get_permission_on_entity_for_user,
  is_permission_table_subtype,
  get_parent_permissions,
  has_create_permissions,
  has_write_permissions,
  get_organisation_id,
}
