import {ColumnId} from 'common/types/storage'
import {ensure, mod} from 'common/utils'
import ih from 'immutability-helper'

function get_actual_new_col_pos(
  cols_order_all: ColumnId[],
  cols_order_visible: ColumnId[],
  new_column: boolean,
  index_to_visible: number
): number {
  const in_place_of = cols_order_visible[index_to_visible]
  // clamps index_to within the inclusive <0, cols_order_all.length (-1 if !new_column)> bounds.
  return mod(cols_order_all.indexOf(in_place_of), cols_order_all.length + Number(new_column))
}

function get_new_cols_order(
  cols_order_all: ColumnId[],
  cols_order_visible: ColumnId[],
  col_id: ColumnId,
  new_column: boolean,
  index_to_visible: number
) {
  const actual_old_col_pos = cols_order_all.indexOf(col_id)
  ensure(new_column || actual_old_col_pos !== -1, 'Column does not exist.', {
    col_id,
    cols_order_all,
  })
  const actual_new_col_pos = get_actual_new_col_pos(
    cols_order_all,
    cols_order_visible,
    new_column,
    index_to_visible
  )

  return ih(cols_order_all, {
    $splice: [
      // remove column from old position, if new column do nothing
      [actual_old_col_pos, Number(!new_column)],
      // add column to new position
      [actual_new_col_pos, 0, col_id],
    ],
  })
}

/*Create a clamping consistent with Google sheets logic.
Return the largest multiple of data_range_size
which is less or equal to target_range_size.
target_range_size is required to be larger than data_range_size*/
const clamp_repeated_target_range_size = (
  data_range_size: [number, number],
  target_range_size: [number, number]
): [number, number] => {
  return [
    target_range_size[0] - (target_range_size[0] % data_range_size[0]),
    target_range_size[1] - (target_range_size[1] % data_range_size[1]),
  ]
}

export {get_new_cols_order, clamp_repeated_target_range_size}
