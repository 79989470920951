import {
  EntityDefaults,
  EntityEditableSchema,
  EntityUneditableSchema,
} from 'common/entities/entity_utils'
import {Project} from 'common/types/storage'

const PROJECT_SCHEMA_DEFAULTS: EntityDefaults<'project'> = {
  type: 'project',
  name: 'empty_project',
  tables_order: [],
}

const create_project_entity = ({
  zone_id,
  parent_id,
  entity_id,
  ...schema
}: Omit<EntityUneditableSchema<'project'>, 'type'> &
  Partial<EntityEditableSchema<'project'>>): Project<'entity'> => {
  return {
    ...PROJECT_SCHEMA_DEFAULTS,
    zone_id,
    parent_id,
    entity_id,
    ...schema,
  }
}

export {create_project_entity}
