import _ from 'lodash'
import {ensure, normalize_name, parse_iso_date, throw_error} from './utils'
import {create_error, ErrorObject, is_error, MaybeError} from './error'
import {
  CellRawValue,
  ColumnId,
  ComputedParamSpec,
  EntityId,
  ResourceId,
  ResourceType,
  RowId,
  TableEntityId,
  TableId,
} from 'common/types/storage'

import {Filter} from 'common/types/filter'
import {is_web_address} from 'common/regex_utils'
import {BOOLEAN_VALUE_TO_FORMAT_MAP} from 'common/formatting/boolean'

export type ComputedParams = Record<string, CellRawValue>

export type ResourceIdParts =
  | {
      type: ResourceType
      entity_id: EntityId
    }
  | {
      type: ResourceType
      table_id: TableId
      column_id?: ColumnId
      formatted?: boolean
      entity_id?: EntityId
      preview_id?: string
      cache_key?: string
    }

export type TableIdParts = {
  entity_id: TableEntityId
  params?: ComputedParams
  search?: string
  filter?: Filter<'internal'>
}

export type ReferenceId = string
export type ReferenceIdParts = {
  entity_id: TableEntityId
  row_id: RowId
}

export type ComputedParamsSpec = Record<string, ComputedParamSpec<'entity'>>

function sort_object<T>(obj: T): T {
  if (!_.isObject(obj) || Array.isArray(obj)) {
    return obj
  } else {
    const res = {} as T
    for (const key of Object.keys(obj).sort()) {
      res[key] = sort_object(obj[key])
    }
    return res
  }
}

function obj_to_string(obj: object): string {
  return JSON.stringify(sort_object(obj))
}

function normalize_params(params: ComputedParams, params_spec?: ComputedParamsSpec) {
  // tests access computed tables without params_spec, so no normalizing there
  if (params_spec == null) {
    return params
  } else {
    const fin_params: ComputedParams = {}
    for (const {name} of Object.values(params_spec)) {
      const normalized = normalize_name(name)
      if (normalized in params) {
        fin_params[normalized] = params[normalized]
      }
    }
    return fin_params
  }
}

const remove_param_spec = (id: string, target?: ComputedParamsSpec): ComputedParamsSpec => {
  if (target) {
    const keys = _.keys(target).filter((key) => key !== id)
    return _.reduce(
      keys,
      (result, id) => {
        return {
          ...result,
          [id]: target[id],
        }
      },
      {}
    ) as ComputedParamsSpec
  }
  return {}
}

const update_param = (
  target: ComputedParams,
  id_name: string,
  value?: CellRawValue
): ComputedParams => {
  if (_.isEmpty(value)) {
    const keys = _.keys(target).filter((key) => key !== id_name)
    return _.reduce(
      keys,
      (result, key) => {
        return {
          ...result,
          [key]: target[key],
        }
      },
      {}
    ) as ComputedParams
  }
  return {...target, [id_name]: value} as ComputedParams
}

function parse_table_id(table_id: TableId): TableIdParts {
  try {
    return JSON.parse(table_id) as TableIdParts
  } catch (err) {
    return throw_error('cannot parse table_id', 'table_id', table_id)
  }
}

function compose_resource_id({type, ...parts}: ResourceIdParts): ResourceId {
  if ('table_id' in parts) {
    const {table_id, entity_id, column_id, preview_id, cache_key} = parts
    if (entity_id) {
      const {entity_id: table_entity_id} = parse_table_id(table_id)
      ensure(entity_id === table_entity_id, 'entity_ids in compose_resource_id do not correspond', {
        entity_id,
        table_entity_id,
      })
    }
    const table_id_json = JSON.stringify(table_id)
    if (preview_id) {
      return `{"type":"${type}","table_id":${table_id_json},"preview_id":"${preview_id}"}`
    } else if (column_id) {
      return `{"type":"${type}","table_id":${table_id_json},"column_id":"${column_id}"}`
    } else if (cache_key) {
      return `{"type":"${type}","table_id":${table_id_json},"cache_key":"${cache_key}"}`
    } else {
      return `{"type":"${type}","table_id":${table_id_json}}`
    }
  } else {
    const {entity_id} = parts
    return `{"type":"${type}","entity_id":"${entity_id}"}`
  }
}

function parse_resource_id(resource_id: ResourceId): ResourceIdParts & {entity_id: EntityId} {
  try {
    const resource_id_parts: ResourceIdParts = JSON.parse(resource_id)
    if ('table_id' in resource_id_parts) {
      if ('entity_id' in resource_id_parts) {
        return throw_error(
          'resource id_cannot contain both, entity and table ids',
          'resource_id',
          resource_id
        )
      } else {
        const {entity_id} = parse_table_id(resource_id_parts.table_id)
        return {entity_id, ...resource_id_parts}
      }
    } else {
      return resource_id_parts
    }
  } catch (err) {
    return throw_error('cannot parse resource_id', 'resource_id', resource_id)
  }
}

function compose_table_id(args: TableIdParts & {params_spec?: ComputedParamsSpec}): TableId {
  const {params, params_spec, ...res} = args

  if (params && !_.isEmpty(params)) {
    ;(res as TableIdParts).params = sort_object(normalize_params(params, params_spec))
  }

  return obj_to_string(res)
}

function compose_reference_id(referenceIdParts: ReferenceIdParts): ReferenceId {
  return JSON.stringify(referenceIdParts)
}

function parse_reference_id(reference_id: ReferenceId | null): MaybeError<ReferenceIdParts> {
  try {
    if (typeof reference_id !== 'string') {
      throw Error('reference_id should be a string')
    }

    const parsed_reference = JSON.parse(reference_id)

    ensure(
      parsed_reference.row_id != null &&
        parsed_reference.entity_id != null &&
        Object.keys(parsed_reference).length === 2,
      'reference_id does not have proper form'
    )

    return parsed_reference as ReferenceIdParts
  } catch (err) {
    return create_error('value', {
      value: reference_id,
      message: `Cannot parse reference id: ${reference_id}`,
    })
  }
}

const get_param_value_or_error = (
  spec: ComputedParamSpec<'entity'>,
  value: CellRawValue
): MaybeError<CellRawValue> => {
  const type = spec.type
  switch (type.type) {
    case 'number': {
      return Number.isNaN(Number(value))
        ? create_error('value', {value, message: 'Number format not recognized'})
        : value
    }
    case 'date':
      return typeof value === 'string' && parse_iso_date(value)
        ? value
        : create_error('value', {
            value,
            message: 'Date format not recognized',
          })
    case 'date_time': {
      const as_number = Number(value)
      if (Number.isNaN(as_number) || as_number > 253402261199000 || as_number < -2208988800000) {
        return create_error('value', {value, message: 'Invalid DateTime value'})
      } else {
        return value
      }
    }
    case 'option': {
      const {options} = type
      return typeof value === 'string' && options[value] != null
        ? value
        : create_error('value', {
            value,
            message: `Value should be of type ${type.type}`,
          })
    }
    case 'reference':
      return typeof value === 'string'
        ? value
        : create_error('value', {
            value,
            message: `Value should be of type ${type.type}`,
          })
    case 'attachment':
      return typeof value === 'string' && is_web_address(value)
        ? value
        : create_error('value', {value, message: 'Value should be a link to a file'})
    case 'boolean':
      return value === null || _.isEmpty(BOOLEAN_VALUE_TO_FORMAT_MAP[String(value).valueOf()])
        ? create_error('value', {
            value,
            message: 'Value is not valid boolean format',
          })
        : value
    default:
      return value
  }
}

const check_params_errors = (
  params: ComputedParams,
  spec?: ComputedParamsSpec
): Record<string, ErrorObject> => {
  if (!spec) {
    return {}
  }
  const spec_entries = _.entries(spec)
  const result: Record<string, ErrorObject> = {}
  _.reduce(
    _.entries(params),
    (prev, [param_name, value]) => {
      const spec_entry = spec_entries.find(([, {name}]) => name === param_name)
      if (!spec_entry) {
        return prev
      }
      const [id, param_spec] = spec_entry
      const validated = get_param_value_or_error(param_spec, value)
      if (is_error(validated)) {
        prev[id] = validated
      }
      return prev
    },
    result
  )
  return result
}

export {
  compose_resource_id,
  parse_resource_id,
  compose_table_id,
  parse_table_id,
  compose_reference_id,
  parse_reference_id,
  remove_param_spec,
  update_param,
  check_params_errors,
}
