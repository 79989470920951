/**
 * @module TableAction
 */
import {useCallback, useContext} from 'react'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {
  CellCoordinates,
  CursorCoordinates,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {focus_table_content} from './focus'
import {TableObject} from 'common/objects/data_table'
import {ColumnId, RowId, TableEntityId} from 'common/types/storage'
import {uuid} from 'common/utils'
import ih from 'immutability-helper'
import {UserAccountContext} from '../UserAccountProvider'
import {col_id_on_index, get_position, index_of_row, row_id_on_index} from './table_helpers'
import {Dimensions, Offset, Position} from './layout'
import {serialize_selection} from './clipboard_utils'
import {normalize_selection} from '../Table'

/**
 * Calculates a shifted row_id (row_id + number) in regards to specified rows_order.
 * If shift amount is zero, skips calculation (linear complexity).
 * @param shift_by {number}
 * @param row_id {RowId}
 * @param rows_order {RowId[]}
 * @return {RowId | undefined}
 */
const shift_row_id = (shift_by: number, row_id: RowId, rows_order: RowId[]): RowId | undefined =>
  shift_by === 0 ? row_id : row_id_on_index(rows_order, shift_by + index_of_row(rows_order, row_id))

/**
 * Calculates where to insert new rows inside cache_rows_order to achieve desired placement.
 * @param options {AddRowOptions}
 * @param rows_order {RowId[]}
 * @param cache_rows_order {RowId[] | null}
 * @return {number}
 */
const get_insertion_index = (
  options: AddRowOptions,
  rows_order: RowId[],
  cache_rows_order: RowId[] | null
): number => {
  if ('cursor' in options && options.cursor?.row_id != null && cache_rows_order != null) {
    const selection_size_y = options.selection_size?.[1] ?? 0

    const negative_selection_compensation = Math.min(0, selection_size_y)
    const row_count_compensation =
      options.place === 'below-selected' ? 1 + Math.abs(selection_size_y) : 0

    /**
      Example of how the math plays out with a negative selection. (S = selected cells, C = cursor)
      +---+---+
      |   |   |
      +---+---+                       1) Find top-most row    2a) Stay here if inserting above
      | S | S |                      <====================   <================================
      +---+---+
      | S | S |
      +---+---+   0) Start at cursor
      | S | C |  <==================
      +---+---+                                               2b) Or shift here if inserting below
      |   |   |                                              <====================================
      +---+---+
    */
    const row_id_to_insert_at = shift_row_id(
      negative_selection_compensation + row_count_compensation,
      options.cursor.row_id,
      rows_order
    )

    // If we run out of rows at the bottom of table, just fall back to one past the last row.
    return row_id_to_insert_at == null
      ? cache_rows_order.length
      : cache_rows_order.indexOf(row_id_to_insert_at)
  } else {
    return cache_rows_order?.length ?? 0
  }
}

type AddRowOptionsWithCursor = {
  place: 'above-selected' | 'below-selected'
  cursor: CursorCoordinates | null
  selection_size?: Offset
  duplicate_content?: boolean
}

type AddRowOptionsWithoutCursor = {
  place: 'bottom'
  col_id_to_focus?: ColumnId | null
}

type AddRowOptions = {
  on_close?: () => void
} & (AddRowOptionsWithCursor | AddRowOptionsWithoutCursor)

export const useAddRowAction = (
  table: TableObject | undefined,
  table_entity_id: TableEntityId,
  options: AddRowOptions
) => {
  const {dispatch_storage} = useRuntimeActions()
  const current_user = useContext(UserAccountContext)
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')
  const cache_rows_order = useTableUiSelector(table_entity_id, 'cache_rows_order')

  return useCallback(() => {
    if (table != null) {
      const selection_size = ('selection_size' in options && options.selection_size) || [0, 0]
      const new_row_ids = Array.from(new Array(1 + Math.abs(selection_size[1])), uuid)

      if ('cursor' in options && options.cursor?.row_id && options.duplicate_content) {
        const cursor_pos = get_position(options.cursor, table, rows_order)
        const {
          top_left: [, top_left_y],
          size: [, size_y],
        } = normalize_selection(cursor_pos, selection_size)
        const top_left: Position = [0, top_left_y]
        const size: Dimensions = [table.col_count(), size_y]
        const {data} = serialize_selection(table, top_left, size, rows_order).json_data
        dispatch_storage(
          table._actions.paste_data(
            [top_left[0], 0],
            [data[0].length, data.length],
            data,
            [],
            new_row_ids,
            table._can_edit_cell,
            current_user?.email
          )
        )
      } else {
        dispatch_storage(table._actions.add_rows(new_row_ids, current_user?.email))
      }

      const insertion_index = get_insertion_index(options, rows_order, cache_rows_order)

      // typed as CellCoordinates to avoid accidentally focusing null row/col id
      const new_cursor: CellCoordinates = {
        row_id: new_row_ids[selection_size[1] < 0 ? new_row_ids.length - 1 : 0],
        col_id:
          ('cursor' in options ? options.cursor?.col_id : options.col_id_to_focus) ??
          col_id_on_index(table, 0), // fallback
      }

      // Sets only last_cursor and last_scroll_to as the UI probably hasn't updated yet
      // so when the focused_row comes into view, it will get automatically focused.
      // Also setting cursor, and scroll_to would break the focus.
      set_table_ui_state({
        cache_rows_order: ih(cache_rows_order ?? [], {
          $splice: [[insertion_index, 0, ...new_row_ids]],
        }),
        last_cursor: new_cursor,
        last_scroll_to: new_cursor,
        // reset selection if it's too wide (e.g. from selecting whole rows via row-number column)
        selection_size: selection_size[0] >= table._cols_order.length ? [0, 0] : selection_size,
      })
    }
    // regain focus of the main table
    focus_table_content()
    options.on_close?.()
  }, [
    cache_rows_order,
    current_user?.email,
    dispatch_storage,
    options,
    rows_order,
    set_table_ui_state,
    table,
  ])
}
