export const get_icon = (col_type: string, in_canvas: boolean) => {
  switch (col_type) {
    case 'date':
      return in_canvas ? '\uf133' : 'far fa-calendar'
    case 'date_time':
      return in_canvas ? '\uf017' : 'far fa-clock'
    case 'option':
      return in_canvas ? '\uf150' : 'far fa-caret-square-down'
    case 'string':
      return in_canvas ? '\uf031' : 'fas fa-font'
    case 'number':
      return in_canvas ? '\uf292' : 'fas fa-hashtag'
    case 'markdown':
      return in_canvas ? '\uf036' : 'fas fa-align-left'
    case 'attachment':
      return in_canvas ? '\uf0c6' : 'fas fa-paperclip'
    case 'boolean':
      return in_canvas ? '\uf058' : 'far fa-check-circle'
    default:
      return in_canvas ? '\uf03a' : 'fas fa-list'
  }
}

export const get_icon_font = (icon: string) => {
  switch (icon) {
    case '\uf111': //'fas fa-circle'
      return '600 8px "Font Awesome 5 Free"'
    case '\uf133': //'far fa-calendar'
    case '\uf017': //'far fa-clock'
    case '\uf150': //'far fa-caret-square-down'
      return '400 14px "Font Awesome 5 Free"'
    case '\uf031': //'fas fa-font'
    case '\uf292': //'fas fa-hashtag'
    case '\uf036': //'fas fa-align-left'
    case '\uf0c6': //'fas fa-paperclip'
    case '\uf03a': //'fas fa-list'
    default:
      return '600 14px "Font Awesome 5 Free"'
  }
}
