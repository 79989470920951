import React, {FC, useCallback, useState, KeyboardEvent, useRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {convert_date_to_iso_string, parse_iso_date, strip_timezone} from 'common/utils'
import {enGB} from 'date-fns/locale'
import {DATE_DEFAULT_TEMPLATE, DateFormatId, get_date_format} from 'common/formatting/date'

registerLocale('en-GB', enGB)

type DatePickerProps = {
  startOpen?: boolean
  autoFocus?: boolean
  customInput?: React.ReactNode
}

type DateEditorProps = {
  value: string
  set_value: (value: string) => void
  end_edit?: () => void
  on_enter?: () => void
  date_format_id?: DateFormatId
  clear_on_open?: boolean
  boundaries_element?: Element
} & DatePickerProps

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '0px 6px',
    border: '2px solid var(--color-inputOutline)',
    borderRadius: '0px',
  },
  wrapper: {
    'width': '100%',
    'height': '100%',
    '& div': {
      width: '100%',
      height: '100%',
    },
  },
  filterWrapper: {
    'width': '100%',
    '& div': {
      width: '100%',
    },
  },
}))

export const DateEditor: FC<DateEditorProps> = ({
  value,
  set_value,
  end_edit = () => {},
  on_enter,
  date_format_id,
  clear_on_open = false,
  boundaries_element,
  ...datepicker_props
}) => {
  const old_value: Date | null = clear_on_open ? null : strip_timezone(parse_iso_date(value))
  const [input_value, set_input_value] = useState(old_value)
  const datepicker_ref = useRef<DatePicker>(null)
  const date_format = get_date_format(date_format_id)

  const save_value = useCallback(
    (_value) => {
      if (
        convert_date_to_iso_string(strip_timezone(old_value)) !==
        convert_date_to_iso_string(strip_timezone(_value))
      ) {
        set_value(convert_date_to_iso_string(strip_timezone(_value)))
      }
    },
    [set_value, old_value]
  )

  const save_current_value = useCallback(() => {
    if (input_value) {
      save_value(input_value)
    }
  }, [save_value, input_value])

  const handle_key_down = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Tab': {
          save_current_value()
          // force trigger onCalendarClose as workaround for bug:
          // github.com/Hacker0x01/react-datepicker/issues/2115
          datepicker_ref.current?.setOpen(false)
          return // let Tab propagate
        }
        case 'Enter': {
          save_current_value()
          on_enter?.()
          break
        }
        default:
        //pass
      }
      event.stopPropagation()
    },
    [save_current_value, on_enter]
  )

  const classes = useStyles()
  return (
    <DatePicker
      selected={input_value}
      onChange={(_value: Date) => set_input_value(_value)}
      dateFormat={date_format?.template || DATE_DEFAULT_TEMPLATE}
      dateFormatCalendar={date_format?.template || DATE_DEFAULT_TEMPLATE}
      className={!datepicker_props.customInput ? classes.root : ''}
      wrapperClassName={!datepicker_props.customInput ? classes.wrapper : classes.filterWrapper}
      ref={datepicker_ref}
      onKeyDown={handle_key_down}
      onClickOutside={save_current_value}
      onSelect={save_value}
      onCalendarClose={end_edit} // internally called on Enter, Esc, onClickOutside, onSelect, ...
      showPopperArrow={false}
      locale={'en-GB'}
      peekNextMonth
      showYearDropdown
      showMonthDropdown
      showTwoColumnMonthYearPicker
      scrollableYearDropdown
      shouldCloseOnSelect
      enableTabLoop={false}
      popperPlacement="auto-start"
      popperModifiers={{
        flip: {
          enabled: true,
          boundariesElement: boundaries_element ?? 'scrollParent',
          behavior: ['bottom', 'right', 'left'],
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: boundaries_element ?? 'scrollParent',
        },
      }}
      {...datepicker_props}
    />
  )
}

export default DateEditor
