import fetch from 'isomorphic-fetch'
import {create_error} from './error'
import {throw_error} from './utils'
import _ from 'lodash'

const _parse_title = async (response: any): Promise<string> => {
  const default_title = 'Invalid API request'
  try {
    const body_json = await response.json()
    if (!_.isEmpty(body_json)) {
      return body_json.message ?? default_title
    }
  } catch (e) {
    return default_title
  }
  return default_title
}

async function _fetch_json(...args: Parameters<typeof fetch>) {
  const res = await fetch(...args)
  if (res.status >= 300) {
    const err_title = await _parse_title(res)
    return throw_error(
      // props in 'res' are inherited, so for Object.assign() to work inside throw_error(),
      // we have to assign them explicitly or copy them from the prototype with _.assignIn()
      {status: res.status},
      err_title,
      'status_code',
      res.status,
      'statusText',
      res.statusText,
      'args',
      ...args
    )
  } else {
    return await res.json()
  }
}

export async function fetch_json(...args: Parameters<typeof _fetch_json>) {
  try {
    return await _fetch_json(...args)
  } catch (e) {
    if (typeof e.status === 'number' && e.status >= 400) {
      throw e
    } else if (e instanceof TypeError) {
      // fetch may throw "TypeError: Failed to fetch" when the user is offline
      throw create_error('network-error', {original_error: e})
    } else {
      throw e
    }
  }
}
