import React, {FC, useCallback} from 'react'
import {parse_reference_id, ReferenceId} from 'common/params_utils'
import styles from './CellDetail.module.css'
import {push_to_history} from './history_actions'
import {useSetTableUiState, useTableUiSelector} from '../utils/connect_hocs'
import {is_error} from 'common/error'
import {TableEntityId} from 'common/types/storage'
import {SimpleCellObject} from 'common/types/data_table'

const usePushToHistory = (table_entity_id: TableEntityId) => {
  const set_table_ui_state = useSetTableUiState(table_entity_id)
  const detailed_view = useTableUiSelector(table_entity_id, 'detailed_view')

  return useCallback(
    (ref_entity_id, row_id) => {
      set_table_ui_state(push_to_history(detailed_view, ref_entity_id, row_id))
    },
    [set_table_ui_state, detailed_view]
  )
}

type ReferenceValueProps = {
  table_entity_id: TableEntityId
  cell: SimpleCellObject
}

const ReferenceValue: FC<ReferenceValueProps> = ({table_entity_id, cell}) => {
  const push = usePushToHistory(table_entity_id)
  const parsed_ref = parse_reference_id(cell.raw_value as ReferenceId | null)
  const {entity_id: ref_entity_id = null, row_id = null} = is_error(parsed_ref) ? {} : parsed_ref

  const handle_on_click = (e) => {
    e.stopPropagation()
    push(ref_entity_id, row_id)
  }

  const label = cell.get_label() || 'null'
  return !ref_entity_id || !row_id ? (
    <>{label}</>
  ) : (
    <div className={styles.reference} onClick={handle_on_click}>
      {label}
    </div>
  )
}

export default ReferenceValue
