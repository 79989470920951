import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import './index.css'
import App from './App'
import {Provider} from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider} from '@material-ui/core/styles'
import {createStore, applyMiddleware, compose} from 'redux'
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk'
import _ from 'lodash'
import {deepFreeze} from './deepFreeze'
import {
  ReduxState,
  ReduxAction,
  ReduxDispatch,
  ThunkExtraArgs,
  TableUiState,
  ZoneUiState,
} from './utils/connect_hocs'
import RuntimeContextProvider from './RuntimeContextProvider'
import UserAccountProvider from './UserAccountProvider'
import {ensure} from 'common/utils'
import {JSHINT} from 'jshint'
import theme from './theme'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// Add JSHINT to window
;(window as any).JSHINT = JSHINT

// General App logging flag
const LOGGING_CLIENT_ENABLED = Number(process.env.REACT_APP_LOGS_ENABLED)
// We can turn on/off deep clone of the UI state. When turned on, logs will show the actual
// state in the time it was created and not the mutated one. This is wanted when debugging.
// Deep cloning in logs is slowing down many table actions, so we don't want to have it
// turned on in production
const LOGGING_DEEP_CLONE_ENABLED = Number(process.env.REACT_APP_LOGS_DEEP_CLONE_ENABLED)
const UI_STATE_AUTO_FREEZE = Number(process.env.REACT_APP_UI_STATE_AUTO_FREEZE)

let currently_dispatched_action: ReduxAction<any> | null = null

function _reducer(state: ReduxState, action: ReduxAction): ReduxState {
  if (action.reducer == null) return state

  const newState = action.reducer(state, action.payload)
  if (UI_STATE_AUTO_FREEZE) {
    const to_freeze = _.without(Object.keys(newState), 'runtime')
    for (const key of to_freeze) deepFreeze(newState[key])
  }

  return newState
}

/*
 * guard _reducer to prevent dispatch-within-dispatch
 */
function reducer(state: ReduxState, action: ReduxAction): ReduxState {
  try {
    ensure(currently_dispatched_action == null, 'nested dispatches detected', {
      currently_dispatched_action,
      action,
    })

    currently_dispatched_action = action
    return _reducer(state, action)
  } finally {
    currently_dispatched_action = null
  }
}

export const empty_table_ui_state: TableUiState = {
  cursor: null,
  scroll_to: null,
  last_cursor: null,
  last_scroll_to: null,
  rows_order: [],
  cols_order: [],
  full_rows_order: [],
  cache_rows_order: null,
  cache_order_by: null,
  last_view_entity_id: null,
  editing: null,
  view_editing: null,
  dragged_column_id: null,
  dragged_column_pos: null,
  selection_size: [0, 0],
  search_text: null,
  filter: null,
  params: {},
  previews: {},
  detailed_view: {
    history: [],
    current: null,
  },
  dropdown: null,
  filter_dropdown: {
    conditions: [],
    operator: 'and',
  },
  find_result: null,
  find_position: null,
}

export const empty_zone_ui_state: ZoneUiState = {
  sidebar: null,
}

const initialState: ReduxState = {
  table_ui: {},
  zone_ui: {},
  modal: {
    type: null,
    params: {},
  },
  runtime: {
    runtime: null,
    storage: null,
    resources: {},
    status: 'in-progress',
    error: null,
  },
  explorer: {
    open: false,
  },
}

function configureStore() {
  const thunk_extras: ThunkExtraArgs = {
    now: () => Date.now(),
  }

  const middleware = [thunk.withExtraArgument(thunk_extras)]

  if (LOGGING_CLIENT_ENABLED) {
    middleware.push(
      createLogger({
        collapsed: true,
        stateTransformer: (state) => ({
          ...(LOGGING_DEEP_CLONE_ENABLED
            ? _.cloneDeep(_.omit(state, 'runtime'))
            : _.omit(state, 'runtime')),
          runtime: state.runtime,
        }),
      })
    )
  }

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose

  return createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware<ReduxDispatch>(...middleware))
  )
}

const store = configureStore()

ReactDOM.render(
  <Router>
    <UserAccountProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RuntimeContextProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </RuntimeContextProvider>
      </ThemeProvider>
    </UserAccountProvider>
  </Router>,
  document.getElementById('root')
)
