import React, {useState, useCallback, FC} from 'react'
import {styled} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {useGlobalDragging} from './utils/hooks'
import {ColumnId} from 'common/types/storage'
import {useRuntimeActions} from './RuntimeContextProvider'
import {CursorCoordinates, useRuntimeSelector, AlwaysDefinedRuntime} from './utils/connect_hocs'
import {ColRectangle, ColRectangleGetter, Position} from './utils/layout'

const MIN_COL_WIDTH = 30 // if the size of column is smaller, content is not rendered correctly
const RESIZER_WIDTH = 8

const Marker = styled('div')(({theme}) => ({
  top: '0',
  width: '2px',
  position: 'absolute',
  backgroundColor: theme.palette.secondary.main,
}))

const Resizer = styled('div')({
  'top': '0',
  'position': 'absolute',
  '&:hover': {
    cursor: 'col-resize',
  },
})

const useStyles = makeStyles<Theme>({
  resizer: (props: {active; height; col_right; marker_left}) => ({
    left: props.active ? 0 : props.col_right - RESIZER_WIDTH / 2,
    height: props.height,
    width: props.active ? '100%' : RESIZER_WIDTH,
    userSelect: 'none',
  }),
  marker: (props: {height; marker_left}) => ({
    left: props.marker_left,
    height: props.height,
  }),
})

type ColumnResizerProps = {
  offset_left: number
  right: number
  width: number
  height: number
  start_resizing: (e: MouseEvent) => void
  end_resizing: (e: MouseEvent) => void
  on_context_menu: React.MouseEventHandler<HTMLDivElement>
  active: boolean
}

const ColumnResizer: FC<ColumnResizerProps> = ({
  offset_left,
  right,
  width,
  height,
  start_resizing,
  on_context_menu,
  end_resizing,
  active,
}) => {
  const [marker_left, set_marker_left] = useState(right)

  const set_marker_position = useCallback(
    (e) => {
      const x = e.clientX - offset_left
      if (x - right + width >= MIN_COL_WIDTH) set_marker_left(x)
    },
    [offset_left, right, width]
  )

  const start_global_dragging = useGlobalDragging(set_marker_position, end_resizing)

  const on_mouse_down = useCallback(
    (e) => {
      start_global_dragging()
      set_marker_position(e)
      start_resizing(e)
    },
    [start_global_dragging, set_marker_position, start_resizing]
  )
  const classes = useStyles({active, height, col_right: right, marker_left})

  if (!Number.isFinite(right)) return null

  return (
    <Resizer
      className={classes.resizer}
      onMouseDown={on_mouse_down}
      onContextMenu={on_context_menu}
    >
      {active && <Marker className={classes.marker} />}
    </Resizer>
  )
}

type ColumnResizeToolProps = {
  offset_left: number
  on_context_menu: React.MouseEventHandler<HTMLDivElement>
  mouse_pos: Position | null
  get_col_rectangle: ColRectangleGetter
  pos_to_cell: (pos: Position) => CursorCoordinates
}

const ColumnResizeTool: FC<ColumnResizeToolProps> = ({
  offset_left,
  on_context_menu,
  mouse_pos,
  get_col_rectangle,
  pos_to_cell,
}) => {
  const {table} = (useRuntimeSelector() as AlwaysDefinedRuntime).resources.table_resources
  const [resizing_col, set_resizing_col] = useState<{id: ColumnId; rect: ColRectangle} | null>(null)
  const {dispatch_storage} = useRuntimeActions()

  const start_resizing = useCallback(
    (e: MouseEvent) => {
      if (e.button !== 0) {
        // resize only with left button
        return
      }
      if (mouse_pos != null) {
        const {col_id: id} = pos_to_cell(mouse_pos)
        if (id != null) {
          // can't resize col with row numbers
          set_resizing_col({id, rect: get_col_rectangle(mouse_pos[0])})
        }
      }
    },
    [get_col_rectangle, mouse_pos, pos_to_cell]
  )

  const end_resizing = useCallback(
    (e: MouseEvent) => {
      if (resizing_col != null) {
        const {left} = resizing_col.rect
        const x = e.clientX - offset_left
        const new_width = Math.max(x - left, MIN_COL_WIDTH)
        dispatch_storage(table._actions.change_column_width(resizing_col.id, new_width))
        set_resizing_col(null)
      }
    },
    [dispatch_storage, offset_left, resizing_col, table._actions]
  )

  const col_rectangle =
    resizing_col?.rect || (!!mouse_pos && mouse_pos[0] > 0 ? get_col_rectangle(mouse_pos[0]) : null)

  return (
    col_rectangle && (
      <ColumnResizer
        offset_left={offset_left}
        start_resizing={start_resizing}
        end_resizing={end_resizing}
        on_context_menu={on_context_menu}
        active={!!resizing_col}
        {...col_rectangle}
      />
    )
  )
}

export default ColumnResizeTool
