import React, {useCallback} from 'react'

import {useRuntimeActions} from '../RuntimeContextProvider'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import ComputedTableSettingsModal from './ComputedTableSettingsModal'
import {EntityDiff} from 'common/types/storage'
import {AddDiffAction} from 'common/entities/actions'
import {change_entity_name} from 'common/entities/entity_actions'
import _ from 'lodash'
import {change_table_description} from 'common/entities/table_actions'

type EditComputedTableModalProps = {
  on_request_close: () => void
  read_only: boolean
}

const EditComputedTableModal = ({on_request_close, read_only}: EditComputedTableModalProps) => {
  const {dispatch_storage} = useRuntimeActions()
  const {
    storage: {entity_headers},
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const table_spec = {
    name: table.name,
    fn: table._fn,
    log_computation: table._log_computation ?? false,
    description: entity_headers[table._entity_id].description,
  }
  const handle_submit = useCallback(
    (new_spec) => {
      const header = entity_headers[table._entity_id]
      const actions = [
        new_spec.name !== table.name ? change_entity_name(header, new_spec.name) : {},
        new_spec.fn !== table._fn ? table._actions.change_table_fn(new_spec.fn) : {},
        new_spec.log_computation !== table._log_computation
          ? table._actions.change_table_logging(new_spec.log_computation)
          : {},
        new_spec.description !== table.description
          ? change_table_description(header, new_spec.description)
          : {},
      ]
      const filtered_actions = actions.filter((action) => !_.isEmpty(action)) as AddDiffAction<
        EntityDiff<'computed_table'>
      >[]
      const combined_action = filtered_actions.reduce((prev, curr) => {
        return {
          ...curr,
          diff: {
            ...(prev as AddDiffAction<EntityDiff<'computed_table'>>)?.diff,
            ...curr.diff,
          },
        }
      }, {}) as AddDiffAction<EntityDiff<'computed_table'>>
      dispatch_storage(combined_action)
    },
    [dispatch_storage, entity_headers, table]
  )

  return (
    <ComputedTableSettingsModal
      on_submit={handle_submit}
      on_request_close={on_request_close}
      read_only={read_only}
      parent_id={table._parent_id}
      current_spec={table_spec}
    />
  )
}

export default EditComputedTableModal
