import {Tab, Tooltip, Popover, Typography} from '@material-ui/core'
import {styled, makeStyles} from '@material-ui/core/styles'
import React, {FC, useCallback, useMemo, useRef, useState} from 'react'
import {TableEntityId, TableSubtype, TableType} from 'common/types/storage'
import {open_modal} from '../Modals'
import {useDispatch} from 'react-redux'
import EntitySettingsEditor from '../EntitySettingsEditor'
import {is_permission_table_subtype} from 'common/permission/permission_utils'
import CustomLabel from '../components/CustomLabel'
import {is_entity_or_parent_archived} from 'common/archived_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'

export const StyledTab = styled(Tab)(({theme}) => ({
  'minWidth': 72,
  'maxHeight': 48,
  'padding': theme.spacing(2),
  'color': theme.palette.greyPalette[400],
  'opacity': 1,
  '&:hover': {
    color: theme.palette.common.black,
  },
  '&:focus': {
    color: theme.palette.common.black,
  },
}))

const useStyles = makeStyles((theme) => ({
  tab: (props: {selected; stop_pointer_events}) => ({
    color: props.selected && `${theme.palette.common.black}`,
    pointerEvents: props.stop_pointer_events ? 'none' : 'auto',
  }),
}))

const StyledTooltipLabel = styled('div')({
  textAlign: 'center',
})

type ActionType =
  | 'edit_computed_table_schema'
  | 'view_computed_table_schema'
  | 'edit_table_name'
  | 'none_available_action'

const get_action_type = (
  can_edit: boolean,
  selected: boolean,
  is_selected_invalid,
  type: TableType,
  subtype?: TableSubtype
): ActionType => {
  switch (true) {
    case selected && is_selected_invalid:
    case is_permission_table_subtype(subtype):
      return 'none_available_action'
    case selected && type === 'computed_table':
      return can_edit ? 'edit_computed_table_schema' : 'view_computed_table_schema'
    default:
      return can_edit ? 'edit_table_name' : 'none_available_action'
  }
}

type TableTabItemProps = {
  history_mode: boolean
  selected: boolean
  is_selected_invalid: boolean
  has_write_permissions: boolean
  value: TableEntityId
  name: string
  description?: string
  archived: boolean | null | undefined
  type: TableType
  subtype?: TableSubtype
  stop_pointer_events?: boolean
  is_modified?: boolean
}

export const TableTabItem: FC<TableTabItemProps> = ({
  history_mode,
  selected,
  is_selected_invalid,
  has_write_permissions,
  value,
  name,
  description,
  archived,
  type,
  subtype,
  stop_pointer_events,
  is_modified = false,
  ...props
}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime<'table_resources'>

  const anchor = useRef(null)
  const [open_name_editor, set_open_name_editor] = useState(false)
  const dispatch = useDispatch()

  const can_edit =
    !history_mode && has_write_permissions && !is_entity_or_parent_archived(value, entity_headers)
  const action_type = useMemo(
    () => get_action_type(can_edit, selected, is_selected_invalid, type, subtype),
    [can_edit, selected, is_selected_invalid, type, subtype]
  )

  const tooltip = useMemo(() => {
    switch (action_type) {
      case 'edit_computed_table_schema':
        return 'edit schema on right-click'
      case 'view_computed_table_schema':
        return 'view schema on right-click'
      case 'edit_table_name':
        return 'edit name on right-click'
      default:
        return ''
    }
  }, [action_type])

  const start_edit = useCallback(
    (e) => {
      e.preventDefault()
      switch (action_type) {
        case 'edit_computed_table_schema':
          dispatch(
            open_modal('edit_computed_table', {
              table_entity_id: value,
              read_only: false,
            })
          )
          break
        case 'view_computed_table_schema':
          dispatch(
            open_modal('edit_computed_table', {
              table_entity_id: value,
              read_only: true,
            })
          )
          break
        case 'edit_table_name':
          set_open_name_editor(true)
          break
        default:
          break
      }
    },
    [dispatch, action_type, value, set_open_name_editor]
  )

  const end_name_edit = useCallback(() => {
    set_open_name_editor(false)
  }, [])

  const classes = useStyles({selected, stop_pointer_events})

  const tooltipTitle = description ? (
    <StyledTooltipLabel>
      <Typography>{description}</Typography>
      {tooltip}
    </StyledTooltipLabel>
  ) : (
    tooltip
  )

  return (
    <>
      <StyledTab
        ref={anchor}
        value={value}
        label={
          <Tooltip title={tooltipTitle}>
            <CustomLabel
              label={name}
              showModificationIndicator={is_modified}
              showArchivedIndicator={!!entity_headers[value].archived}
            />
          </Tooltip>
        }
        onContextMenu={start_edit}
        className={classes.tab}
        {...props}
      />
      <Popover
        open={open_name_editor}
        onClose={end_name_edit}
        anchorEl={anchor.current}
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <EntitySettingsEditor entity_id={value} end_edit={end_name_edit} />
      </Popover>
    </>
  )
}
