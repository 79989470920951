import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {
  Collapse,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import StringEditor from '../CellEditor/StringEditor'
import CustomButton from '../components/CustomButton'
import {CellType, ColumnSpec, ColumnType} from 'common/types/storage'
import _ from 'lodash'
import {ComputedParamsSpec} from 'common/params_utils'
import {get_icon} from '../utils/icons_utils'

const useStyles = makeStyles(({palette}) => ({
  leading: {
    padding: '0.5rem 0.5rem 1.5rem 0rem',
  },
  inner: {
    padding: '0.5rem 0.5rem 1.5rem 0.5rem',
  },
  trailing: {
    padding: '0.5rem 0rem 1.5rem 0.5rem',
    minWidth: '7rem',
  },
  borderless: {
    border: 'none',
  },
  divider: {
    borderBottom: `2px solid ${palette.greenPalette[600]}`,
  },
  select: {
    backgroundColor: 'white',
    width: '7rem',
  },
}))

const ErrorMessage = styled(Typography)(({theme}) => ({
  color: `${theme.palette.error.main}`,
  padding: '0rem 0rem 0.75rem 0rem',
}))

const ParamNameWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '1rem',
})

const OptionsSelectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '1rem',
  padding: '0rem 0rem 1rem 0rem',
})

export type ParamFormProps = {
  existing_params?: ComputedParamsSpec
  on_add_param: (name: string, type: ColumnType<CellType, 'entity'>) => void
  param_types: ColumnType<CellType, 'entity'>[]
  options: ColumnSpec<CellType, 'entity'>[]
  references: ColumnSpec<CellType, 'entity'>[]
}

const CustomSelect = ({value, on_change, children}) => {
  const classes = useStyles()
  return (
    <Select
      className={classes.select}
      displayEmpty
      value={value}
      onChange={on_change}
      onBlur={() => {}}
      MenuProps={{
        MenuListProps: {
          disablePadding: true,
        },
      }}
      autoWidth
    >
      {children}
    </Select>
  )
}

const group_types = ['option', 'reference']

const ParamForm: FC<ParamFormProps> = ({
  existing_params = {},
  on_add_param,
  param_types,
  options,
  references,
}) => {
  const classes = useStyles()
  const [param_name, set_param_name] = useState<string>('')
  const [param_type, set_param_type] = useState<ColumnType<CellType, 'entity'> | undefined>()
  const [options_group, set_options_group] = useState<ColumnSpec<CellType, 'entity'> | undefined>()
  const [param_error_message, set_param_error_message] = useState<string | null>(null)
  const [skip_error_visibility, set_skip_error_visibility] = useState<boolean>(true)

  const all_types: ColumnType<CellType, 'entity'>[] = useMemo(() => {
    const types = [...param_types]
    if (!_.isEmpty(options)) {
      types.push({type: 'option', options: {}})
    }
    if (!_.isEmpty(references)) {
      types.push({type: 'reference', tables: {}})
    }
    return types
  }, [param_types, options, references])

  const handle_type_change = useCallback(
    (event) => {
      const new_type = all_types.find((type) => type.type === event.target.value)
      set_param_type(new_type)
      set_options_group(undefined)
    },
    [all_types, set_param_type, set_options_group]
  )

  const handle_option_group_change = useCallback(
    (event) => {
      if (_.includes(group_types, param_type?.type)) {
        const target = param_type?.type === 'option' ? options : references
        const new_options_group = target.find(({name}) => name === event.target.value)
        set_options_group(new_options_group)
      }
    },
    [options, references, param_type]
  )

  const handle_name_change = useCallback(set_param_name, [set_param_name])

  useEffect(() => {
    if (skip_error_visibility) {
      return
    }
    let error_message = _.isEmpty(param_name)
      ? 'No param name'
      : param_type
      ? null
      : 'Select param type'
    if (!error_message) {
      const existing_param = _.values(existing_params).find(({name}) => name === param_name)
      if (param_name && existing_param) {
        error_message = `param with name '${param_name}' already exists`
      } else if (_.includes(group_types, param_type?.type) && _.isEmpty(options_group)) {
        error_message = 'Select options group'
      }
    }
    set_param_error_message(error_message)
  }, [param_name, param_type, skip_error_visibility, options_group, existing_params])

  const add_param = useCallback(() => {
    let skip_error = false
    if (!_.isEmpty(param_name) && param_type) {
      const existing_param = _.values(existing_params).find(({name}) => name === param_name)
      const is_group_type = _.includes(group_types, param_type.type)
      const selected_type = is_group_type ? options_group?.type : param_type
      if (!existing_param && selected_type) {
        on_add_param(param_name, selected_type)
        skip_error = true
        set_param_name('')
        set_param_type(undefined)
        set_options_group(undefined)
      }
    }
    set_skip_error_visibility(skip_error)
  }, [param_name, param_type, options_group, existing_params, on_add_param])

  const show_options = param_type?.type === 'option' || param_type?.type === 'reference'
  const options_list = param_type?.type === 'option' ? options : references

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={`${classes.leading}  ${classes.borderless}`}>
            <StringEditor
              key={param_name}
              value={param_name}
              set_value={handle_name_change}
              end_edit={() => {}}
              placeholder={'Name'}
            />
          </TableCell>
          <TableCell className={`${classes.inner}  ${classes.borderless}`}>
            <FormControl>
              <CustomSelect value={param_type?.type ?? ''} on_change={handle_type_change}>
                <MenuItem value="" disabled>
                  <Typography variant="body1" style={{fontStyle: 'italic', opacity: 0.5}}>
                    Select type
                  </Typography>
                </MenuItem>
                {all_types.map(({type}) => (
                  <MenuItem key={type} value={type}>
                    <ParamNameWrapper>
                      <i
                        style={{width: 10, padding: 0, opacity: 0.5}}
                        className={get_icon(type, false)}
                      />
                      {type}
                    </ParamNameWrapper>
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </TableCell>
          <TableCell className={`${classes.trailing}  ${classes.borderless}`}>
            <CustomButton type="primary" onClick={add_param}>
              Add param
            </CustomButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={3}
            padding="none"
            className={`${classes.trailing}  ${classes.borderless}`}
          >
            <Collapse in={show_options}>
              <OptionsSelectWrapper>
                <Typography variant="body1" style={{opacity: 0.5}}>
                  Select option group
                </Typography>
                <FormControl>
                  <CustomSelect
                    value={options_group?.name || ''}
                    on_change={handle_option_group_change}
                  >
                    <MenuItem value="" disabled>
                      <Typography variant="body1" style={{fontStyle: 'italic', opacity: 0.5}}>
                        None
                      </Typography>
                    </MenuItem>
                    {options_list.map(({name}) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </OptionsSelectWrapper>
            </Collapse>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} padding="none" className={classes.divider}>
            <Collapse in={!!param_error_message} mountOnEnter>
              <ErrorMessage variant="subtitle2">{param_error_message}</ErrorMessage>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ParamForm
