import {error_handling_decorator} from 'common/eval_function'
import {ExternalData, ExternalFn, ExternalResourceData} from 'common/types/storage'
import {create_error} from 'common/error'

export const fetch_external_data = async <T extends ExternalData = ExternalData>(
  fn: ExternalFn<T>
): Promise<ExternalResourceData<T>> => {
  return {data: await error_handling_decorator(fn)()}
}

// Used as fetcher for external resources in server's runtime context
// Server should not evaluate user's function. Client is responsible for fetching external data.
export const server_fetch_external_data = <T extends ExternalData = ExternalData>(
  fn: ExternalFn<T>
): Promise<ExternalResourceData<T>> =>
  new Promise((resolve) => ({
    data: create_error('user', {message: 'Can not evaluate user function on server', fn}),
  }))
