import React, {FC, useMemo} from 'react'
import {createStyles, withStyles, makeStyles} from '@material-ui/core/styles'
import {Switch, TextField, Theme, Box} from '@material-ui/core'
import {CellRawValue, CellType, ColumnId, ColumnType} from 'common/types/storage'
import {
  BooleanValueFilterSet,
  DateFilterValuesSet,
  FilterType,
  FilterValue,
  MultiCellFilterValue,
  MultiColumnFilterSet,
  MultiValueFilterSet,
  SingleColumnFilterSet,
} from 'common/types/filter'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {useInputStyles} from '../utils/customStyles'
import {
  get_available_options,
  get_selected_options,
  raw_value_to_string,
  with_additional_options,
} from '../CellEditor/editor_utils'
import SelectEditor from '../CellEditor/SelectEditor'
import DateEditor from '../CellEditor/DateEditor'

type FilterValueEditorProps = {
  col_id: ColumnId
  col_type: ColumnType<CellType, 'entity'>
  value: FilterValue<'internal'>
  on_change: (value: FilterValue<'internal'>) => void
  autofocus: boolean
  filter_type: FilterType
  error?: boolean
}

type FilterEditorType = 'switch' | 'select' | 'date_picker' | 'text_field'
const get_editor_type = (
  filter_type: FilterType,
  col_type: ColumnType<CellType, 'entity'>
): FilterEditorType => {
  if (BooleanValueFilterSet.has(filter_type)) {
    return 'switch'
  }
  if (
    MultiColumnFilterSet.has(filter_type) ||
    MultiValueFilterSet.has(filter_type) ||
    (SingleColumnFilterSet.has(filter_type) && ['reference', 'option'].includes(col_type.type))
  ) {
    return 'select'
  }
  if (
    (DateFilterValuesSet.has(filter_type) && col_type.type !== 'date_time') ||
    (SingleColumnFilterSet.has(filter_type) && col_type.type === 'date')
  ) {
    return 'date_picker'
  }
  return 'text_field'
}

type FilterValueSelectEditorProps = Omit<FilterValueEditorProps, 'value' | 'on_change'> & {
  value: MultiCellFilterValue
  on_change: (value: MultiCellFilterValue) => void
}

const StyledSelectEditor = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      background: 'white',
      width: 200,
    },
  })
)(SelectEditor)

const useWidthStyle = makeStyles((theme) => ({
  widthInput: {
    width: 200,
  },
}))

const noop = () => {}

const FilterValueSelectEditor: FC<FilterValueSelectEditorProps> = ({
  col_id,
  col_type,
  value,
  filter_type,
  on_change,
  autofocus,
  error,
}) => {
  const {
    runtime,
    resources: {
      table_resources: {full_table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const column_options = useMemo(() => {
    if (col_type.type === 'reference') {
      return get_selected_options(full_table, col_id)
    } else {
      return get_available_options(col_type, runtime)
    }
  }, [runtime, col_type, full_table, col_id])

  const all_options = useMemo(() => {
    return with_additional_options(column_options, value, col_type, runtime)
  }, [column_options, value, col_type, runtime])

  const is_multi = useMemo(() => {
    return MultiColumnFilterSet.has(filter_type) || MultiValueFilterSet.has(filter_type)
  }, [filter_type])

  return (
    <StyledSelectEditor
      value={value}
      multi={is_multi}
      open="onFocus"
      show_option_chips
      clear_search_on_select
      options={all_options}
      set_value={on_change}
      end_edit={noop}
      autofocus={autofocus}
      error={error}
      is_filter_value_editor
    />
  )
}

const FilterValueEditor: FC<FilterValueEditorProps> = (props) => {
  const classes = useInputStyles()
  const widthClass = useWidthStyle()
  const {col_type, filter_type, value, on_change, autofocus, error} = props

  switch (get_editor_type(filter_type, col_type)) {
    case 'switch':
      return (
        <Box display="flex" justifyContent="center" className={widthClass.widthInput}>
          <Switch checked={!!value} onClick={() => on_change(!value)} autoFocus={autofocus} />
        </Box>
      )
    case 'select':
      return <FilterValueSelectEditor {...(props as FilterValueSelectEditorProps)} />
    case 'date_picker':
      return (
        <DateEditor
          value={raw_value_to_string(value as string)}
          set_value={on_change}
          date_format_id={(col_type as ColumnType<'date', 'entity'>).date_format_id}
          customInput={
            <TextField variant="outlined" size="small" InputProps={{className: classes.styles}} />
          }
          autoFocus={autofocus}
          startOpen={autofocus}
        />
      )
    case 'text_field':
    default:
      return (
        <TextField
          variant="outlined"
          size="small"
          value={raw_value_to_string(value as CellRawValue)}
          onChange={(event) => on_change(event.target.value)}
          autoFocus={autofocus}
          error={error}
          InputProps={{className: `${classes.styles} ${widthClass.widthInput}`}}
        />
      )
  }
}

export default FilterValueEditor
