import React, {FC} from 'react'
import {styled} from '@material-ui/core/styles'
import {DialogContent, Table, TableBody, TableCell, TableRow} from '@material-ui/core'

const ModalContainer = styled(DialogContent)({
  width: '75vw',
  maxWidth: '800px',
})

type KeysGroup = string[]

type Shortcut = {
  description: string
  keys: KeysGroup[]
}

const Key = styled('div')(({theme}) => ({
  display: 'inline-block',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '15px',
  textTransform: 'capitalize',
  opacity: 0.5,
  borderRadius: '5px',
  padding: '5px',
  margin: '5px',
}))

const shortcuts: Shortcut[] = [
  {description: 'Show available shortcuts', keys: [['f1']]},
  {description: 'Undo the latest change in the current project', keys: [['ctrl'], ['z']]},
  {description: 'Redo the change in the current project', keys: [['ctrl'], ['shift'], ['z']]},
  {description: 'Copy the content of the selected cells to clipboard', keys: [['ctrl'], ['c']]},
  {
    description: 'Paste the content from clipboard starting from the selected cell',
    keys: [['ctrl'], ['v']],
  },
  {
    description:
      "Paste the content from clipboard to newly created rows starting from the selected cell (doesn't work in Firefox and Safari)",
    keys: [['ctrl'], ['shift'], ['v']],
  },
  {description: 'Cut the content of the selected cells to clipboard', keys: [['ctrl'], ['x']]},
  {description: 'If editing, end editing of cell without saving the change', keys: [['esc']]},
  {description: 'If any modal is opened, close the modal', keys: [['esc']]},
  {description: 'Start/end editing selected cell', keys: [['enter']]},
  {description: 'Start editing selected cell', keys: [['f2']]},
  {description: 'Move selected column left/right', keys: [['ctrl'], ['←', '→']]},
  {description: 'Move to the beginning/end of the table', keys: [['Home', 'End']]},
  {
    description: 'Move the view to just above/below your current view',
    keys: [['PageUp', 'PageDn']],
  },
  {description: 'Select multiple cells', keys: [['shift'], ['←', '→', '↑', '↓']]},
  {
    description: 'Select the whole table including column headers, excluding row numbers',
    keys: [['ctrl'], ['a']],
  },
  {description: 'Clear selected cells', keys: [['backspace', 'delete']]},
  {description: 'Open Find in table bar', keys: [['ctrl', 'f']]},
]

const ShowShortcutsModal: FC = () => {
  return (
    <ModalContainer>
      <Table>
        <TableBody>
          {shortcuts.map((shortcut, idx) => (
            <TableRow key={idx}>
              <TableCell>{shortcut.description}</TableCell>
              <TableCell>
                {shortcut.keys.map((keys_group, keys_group_idx) => (
                  <React.Fragment key={`${idx}_${keys_group_idx}`}>
                    {keys_group_idx > 0 && '+'}
                    {keys_group.map((key, key_idx) => (
                      <React.Fragment key={`rf_${idx}_${keys_group_idx}_${key_idx}`}>
                        {key_idx > 0 && '/'}
                        <Key key={`key_${idx}_${keys_group_idx}_${key_idx}`}>{key}</Key>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ModalContainer>
  )
}

export default ShowShortcutsModal
