import React, {FC, useState, useCallback, useMemo} from 'react'
import {Controlled as ControlledCodeMirror} from 'react-codemirror2'
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/idea.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/javascript-lint'
import 'codemirror/addon/lint/lint.css'
import {makeStyles, styled} from '@material-ui/core/styles'
import _ from 'lodash'
import {get_entity_to_table_name_mapping} from 'common/project_utils'
import PopupTableSelector from '../../Selectors/PopupTableSelector'
import EditorContextMenu from './ContextMenu/EditorContextMenu'
import FunctionPreview, {PreviewType} from './FunctionPreview'
import {Coordinates} from '../../utils/mouse_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../../utils/connect_hocs'
import {EntityHeader} from 'common/types/storage'

type FunctionEditorProps = {
  fn_string: string
  fn_template: string
  on_edit: (value: string) => void
  // Preview is only available in table page
  preview_type?: PreviewType
  read_only: boolean
}

const custom_validator = (cm, updateLinting, options) => {
  // call the built in javascript linter from addon/lint/javascript-lint.js
  // getAnnotations and async are only used by CodeMirror, but not the linter
  const errors = CodeMirror.lint.javascript(
    cm,
    _.omit(options, ['getAnnotations', 'async', 'selfContain'])
  )
  updateLinting(errors)
}

const TABLE_SELECTOR_WIDTH = 350

// check if position is inside the selection defined by start & end
// Positions are defined as codemirror {line, ch} objects
const is_in_selection = (pos, start, end): boolean =>
  (start.line < pos.line || (start.line === pos.line && start.ch <= pos.ch)) &&
  (end.line > pos.line || (end.line === pos.line && end.ch >= pos.ch))

// MUI autocomplete component displays unconsistently on the right side of the viewport:
// Menu's position is shifted, so the menu is displayed whole, but textarea overflows.
const fix_autocomplete_overflow = (pos) =>
  pos + TABLE_SELECTOR_WIDTH > window.innerWidth
    ? window.innerWidth - TABLE_SELECTOR_WIDTH - 5
    : pos

const useStyles = makeStyles({
  'template': {
    'border': '1px solid #d3d3d3',
    '@global': {
      '.CodeMirror-scroll': {
        backgroundColor: '#f6f6f6',
        color: '#888',
      },
    },
  },
  'user-fn': {
    'border': '1px solid #aaa',
    '@global': {
      '.CodeMirror-scroll': {
        backgroundColor: '#fff',
        color: '#888',
      },
    },
  },
})

const MainContainer = styled('div')({
  display: 'flex',
  height: '300px',
})

const CodeContainer = styled('div')({
  flex: '1 1 auto',
  minWidth: '0px',
})

const table_selector_filter = (entity: EntityHeader) =>
  entity.type === 'data_table' || entity.type === 'view_table' || entity.type === 'computed_table'

const FunctionEditor: FC<FunctionEditorProps> = ({
  fn_string,
  fn_template,
  on_edit,
  preview_type,
  read_only,
}) => {
  const {
    storage: {entity_headers},
    resources: {table_resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const [focused, set_focused] = useState(false)
  const [cm_editor, set_cm_editor] = useState<CodeMirror.Editor>(null)
  const [table_selector_pos, set_table_selector_pos] = useState<Coordinates | null>(null)
  const [context_menu_pos, set_context_menu_pos] = useState<Coordinates | null>(null)

  const entity_names = useMemo(
    () => get_entity_to_table_name_mapping(entity_headers, table_selector_filter),
    [entity_headers]
  )

  const classes = useStyles()
  const value = fn_string || fn_template || ''
  const edited = !!fn_string
  const mode = focused || edited ? 'javascript' : null
  const className = edited ? 'user-fn' : 'template'

  const handle_change = useCallback(
    (editor, data, value) => {
      on_edit(value)
    },
    [on_edit]
  )

  const handle_focus = useCallback(() => {
    set_focused(true)
  }, [])

  const handle_blur = useCallback(() => {
    set_focused(false)
  }, [])

  const block_context_menu = useCallback((editor, event) => {
    if (event.button === 2) {
      event.preventDefault()
    }
  }, [])

  const open_context_menu = useCallback(
    (editor, event) => {
      if (event.button === 2) {
        event.preventDefault()
        // set_table_selector_pos(null)
        const coords = editor.coordsChar({left: event.pageX, top: event.pageY})

        //if we click on selected text, we want to replace the selection with
        //the table id. If not, move the cursor into the position of right click.
        if (
          !editor.somethingSelected() ||
          !editor.listSelections().some(
            ({anchor, head}) =>
              //this is done two times, because it different if we make selection from top
              //to bottom or from bottom to top.
              is_in_selection(coords, anchor, head) || is_in_selection(coords, head, anchor)
          )
        ) {
          editor.setCursor(coords)
        }
        set_context_menu_pos({left: event.pageX, top: event.pageY})
      }
    },
    [set_context_menu_pos]
  )

  const close_context_menu = useCallback(() => {
    set_context_menu_pos(null)
  }, [set_context_menu_pos])

  const close_table_selector = useCallback(() => {
    set_table_selector_pos(null)
    if (cm_editor) {
      cm_editor.focus()
    }
  }, [set_table_selector_pos, cm_editor])

  const open_table_selector = useCallback(() => {
    const coords = cm_editor.cursorCoords(true, 'window')
    set_table_selector_pos({
      left: fix_autocomplete_overflow(coords.left),
      top: Math.round(coords.top),
    })
  }, [cm_editor, set_table_selector_pos])

  const handle_table_selector_change = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, entity_id: string) => {
      if (cm_editor !== null) {
        const table_name = entity_names[entity_id]
        cm_editor.doc.replaceSelection(`runtime.get_table(/*${table_name}*/'${entity_id}')`)
      }
    },
    [cm_editor, entity_names]
  )

  return (
    <MainContainer>
      <CodeContainer>
        {table_selector_pos != null && (
          <PopupTableSelector
            pos={table_selector_pos}
            width={TABLE_SELECTOR_WIDTH}
            entity_headers={entity_headers}
            entity_headers_filter={table_selector_filter}
            value={null}
            autoFocus
            openOnFocus
            onChange={handle_table_selector_change}
            onBlur={close_table_selector}
            onClose={close_table_selector}
          />
        )}
        <EditorContextMenu
          pos={context_menu_pos}
          on_close={close_context_menu}
          on_click_add_table={open_table_selector}
        />
        <ControlledCodeMirror
          className={classes[className]}
          value={value}
          options={{
            readOnly: read_only ? 'nocursor' : false,
            mode,
            theme: 'idea',
            tabSize: 2,
            lineNumbers: true,
            autofocus: false,
            indentUnit: 2,
            smartIndent: true,
            lineWrapping: false,
            gutters: ['CodeMirror-lint-markers'],
            extraKeys: {'Ctrl-D': open_table_selector},
            lint: {
              selfContain: true,
              esversion: 6,
              asi: true,
              expr: true,
              undef: true,
              getAnnotations: custom_validator,
              async: true,
              globals: {
                _: true,
              },
            },
          }}
          onBeforeChange={handle_change}
          onFocus={handle_focus}
          onBlur={handle_blur}
          onContextMenu={open_context_menu}
          onMouseDown={block_context_menu}
          editorDidMount={(editor) => {
            set_cm_editor(editor)
          }}
        />
      </CodeContainer>
      {!!preview_type && table_resources !== undefined && (
        <FunctionPreview fn_string={fn_string} preview_type={preview_type} />
      )}
    </MainContainer>
  )
}

export default FunctionEditor
