import _ from 'lodash'
import React, {FC, MouseEvent, useCallback, useMemo, useState, FormEvent} from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
} from '@material-ui/core'

import {validate_entity_name} from 'common/project_utils'
import {ComputedTable, EntityId} from 'common/types/storage'
import {ComputedTableFnEditor} from './ComputedTableFnEditor'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: 'fill-available',
  overflowY: 'auto',
})

type ComputedTableSpec = Partial<ComputedTable<'entity'>>

type ComputedTableSettingsModalProps = {
  read_only: boolean
  on_request_close: () => void
  on_submit: (table_spec: ComputedTableSpec) => void
  parent_id: EntityId
  current_spec: ComputedTableSpec
}

const ComputedTableSettingsModal: FC<ComputedTableSettingsModalProps> = ({
  read_only,
  on_request_close,
  on_submit,
  parent_id,
  current_spec,
}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const [table_settings, set_table_settings] = useState({
    name: current_spec.name || '',
    description: current_spec.description || '',
    fn: current_spec.fn || '',
    log_computation: current_spec.log_computation || false,
  })

  const dirty = useMemo(() => !_.isEqual(table_settings, current_spec), [
    table_settings,
    current_spec,
  ])

  const set_table_setting = useCallback((field: string, value: any) => {
    set_table_settings((state) => _.set({...state}, field, value))
  }, [])

  const value_change_handler = useCallback(
    (e) => set_table_setting(e.target.name, e.target.value),
    [set_table_setting]
  )

  const fn_change_handler = useCallback((new_fn) => set_table_setting('fn', new_fn), [
    set_table_setting,
  ])

  const log_change_handler = useCallback((log) => set_table_setting('log_computation', log), [
    set_table_setting,
  ])

  const validate_and_apply = useCallback(
    (form_element: HTMLFormElement): boolean => {
      if (!form_element.reportValidity()) {
        return false
      }

      if (current_spec.name !== table_settings.name) {
        const error_message = validate_entity_name(
          entity_headers,
          'computed_table',
          parent_id,
          table_settings.name
        )
        if (error_message) {
          // eslint-disable-next-line no-alert
          alert(error_message)
          return false
        }
      }

      if (!table_settings.fn) {
        // eslint-disable-next-line no-alert
        alert('Table function must be defined!')
        return false
      }

      on_submit(table_settings)
      return true
    },
    [current_spec, table_settings, on_submit, entity_headers, parent_id]
  )

  const handle_submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (validate_and_apply(e.currentTarget)) {
        on_request_close()
      }
    },
    [validate_and_apply, on_request_close]
  )

  const handle_apply = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      validate_and_apply(e.currentTarget.form as HTMLFormElement)
    },
    [validate_and_apply]
  )

  const request_close = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // https://github.com/erikras/redux-form/issues/2679
      e.preventDefault()
      on_request_close()
    },
    [on_request_close]
  )

  const {name, description, fn, log_computation} = table_settings

  return (
    <StyledForm onSubmit={handle_submit}>
      <DialogTitle>Table: "{name}"</DialogTitle>
      <DialogContent>
        <div>
          <TextField
            id="table_name_input"
            type="string"
            name="name"
            placeholder="Enter table name"
            value={name}
            onChange={value_change_handler}
            disabled={read_only}
            label={'Table name'}
            margin={'dense'}
            required
          />
        </div>
        <div>
          <TextField
            id="table_description_input"
            type="string"
            name="description"
            fullWidth
            placeholder="Enter description"
            value={description}
            onChange={value_change_handler}
            disabled={read_only}
            label="Description"
            multiline
          />
        </div>
        <ComputedTableFnEditor
          fn={fn}
          set_fn={fn_change_handler}
          log_computation={log_computation}
          set_log_computation={log_change_handler}
          read_only={read_only}
        />
      </DialogContent>
      <DialogActions>
        {!read_only && (
          <Button color="primary" type="submit" disabled={!dirty}>
            Ok
          </Button>
        )}
        <Button color="primary" type="button" onClick={request_close}>
          Close
        </Button>
        {!read_only && (
          <Button color="primary" type="button" onClick={handle_apply} disabled={!dirty}>
            Apply
          </Button>
        )}
      </DialogActions>
    </StyledForm>
  )
}

export default ComputedTableSettingsModal
