import _ from 'lodash'
import {
  FilterPredicate,
  FilterType,
  FilterValue,
  MultiCellFilterValue,
  SingleCellFilterValue,
} from 'common/types/filter'
import {CellObject} from 'common/types/data_table'
import {is_error} from 'common/error'
import {get_boolean_format_by_value} from 'common/formatting/boolean'

// Boolean Filters

const _is_error_filter = (cell: CellObject, filter_value: boolean) =>
  is_error(cell.error) === filter_value

const _is_empty_filter = ({raw_value}: CellObject, filter_value: boolean) =>
  _.isNull(raw_value) === filter_value

// Single Value Filters

const _equal_to_filter = (
  {raw_value}: CellObject,
  filter_value: SingleCellFilterValue<'parsed'>
) => {
  return raw_value === filter_value
}

// Multi Column Filters

const _multi_equal_to_filter = ({raw_value}: CellObject, filter_value: MultiCellFilterValue) =>
  _.xor(raw_value as MultiCellFilterValue, filter_value).length === 0

const _contains_filter = ({raw_value}: CellObject, filter_value: MultiCellFilterValue) =>
  _.difference(filter_value, raw_value as MultiCellFilterValue).length === 0

// Multi Value Filters

const _has_any_of_filter = ({raw_value}: CellObject, filter_value: MultiCellFilterValue) =>
  _.intersection(filter_value, _.castArray(raw_value as MultiCellFilterValue)).length > 0

// String Filters

const _substring_filter = ({raw_value}: CellObject, filter_value: string) =>
  (raw_value as string).includes(filter_value)

const _starts_with_filter = ({raw_value}: CellObject, filter_value: string) =>
  (raw_value as string).startsWith(filter_value)

const _ends_with_filter = ({raw_value}: CellObject, filter_value: string) =>
  (raw_value as string).endsWith(filter_value)

// Number and Date Filters
// These are a bit more verbose because of this typescript "feature"
//    https://github.com/microsoft/TypeScript/issues/14889

const _less_than_filter = ({get_value}: CellObject, filter_value: Date) => {
  const value = get_value()
  if (value == null) return false
  return value < filter_value
}

const _greater_than_filter = ({get_value}: CellObject, filter_value: Date) => {
  const value = get_value()
  if (value == null) return false
  return value > filter_value
}

const _is_format_true = ({raw_value}: CellObject, filter_value: string) => {
  const raw_value_string = String(raw_value).valueOf()
  const filter_format_values = get_boolean_format_by_value(filter_value)
  const raw_format_values = get_boolean_format_by_value(raw_value_string)
  if (filter_format_values && raw_format_values) {
    return (
      filter_format_values.indexOf(filter_value) === raw_format_values.indexOf(raw_value_string)
    )
  }
  return false
}
// Helper transformations

const validate = (filter_fn: FilterPredicate<'parsed'>) => {
  return (cell: CellObject, filter_value: FilterValue<'parsed'>) =>
    !is_error(cell.error) && !_.isNull(cell.raw_value) && filter_fn(cell, filter_value)
}

const not = <P extends any[]>(filter_fn: (...arg: P) => boolean) => {
  return (...args: P): boolean => !filter_fn(...args)
}

export const filter_to_fn: Record<FilterType, FilterPredicate<'parsed'>> = {
  is_empty: _is_empty_filter,
  is_error: _is_error_filter,
  equal_to: validate(_equal_to_filter),
  not_equal_to: validate(not(_equal_to_filter)),
  multi_equal_to: validate(_multi_equal_to_filter),
  multi_not_equal_to: validate(not(_multi_equal_to_filter)),
  contains: validate(_contains_filter),
  has_any_of: validate(_has_any_of_filter),
  has_none_of: validate(not(_has_any_of_filter)),
  substring: validate(_substring_filter),
  starts_with: validate(_starts_with_filter),
  ends_with: validate(_ends_with_filter),
  before: validate(_less_than_filter),
  after: validate(_greater_than_filter),
  on_or_before: validate(not(_greater_than_filter)),
  on_or_after: validate(not(_less_than_filter)),
  greater_than: validate(_greater_than_filter),
  less_than: validate(_less_than_filter),
  greater_than_or_equal_to: validate(not(_less_than_filter)),
  less_than_or_equal_to: validate(not(_greater_than_filter)),
  is_format_true: validate(_is_format_true),
  is_format_false: validate(not(_is_format_true)),
}
