import _ from 'lodash'
import {throw_error} from '../../utils'
import {create_squash} from './create_squash'
import {Nullable} from '../../types/storage'
import {DiffSpec} from './diff_type'

export type ConflictFreeValueDiff<T> = {
  old_value: Nullable<T>
  new_value: Nullable<T>
}

type ConflictFreeValueDiffSpec<T> = DiffSpec<
  ConflictFreeValueDiff<T>,
  Nullable<T>,
  Nullable<T>,
  never[]
> & {
  get_value: (value: T | null) => T | null
  get_new_value: (diff: ConflictFreeValueDiff<T>) => T | null
  get_old_value: (diff: ConflictFreeValueDiff<T>) => T | null
}

function is_value_type(diff: unknown): diff is ConflictFreeValueDiff<any> {
  return typeof diff === 'object' && diff != null && 'old_value' in diff && 'new_value' in diff
}

function ensure_diff(diff: unknown) {
  if (!is_value_type(diff)) {
    throw_error('Given object is not valid conflict_free_value_diff.', 'diff', diff)
  }
}

export default function conflict_free_value_diff<T = any>(
  delete_empty: boolean = true
): ConflictFreeValueDiffSpec<T> {
  function get_old_value(diff: ConflictFreeValueDiff<T>): T | null {
    ensure_diff(diff)
    return diff.old_value
  }

  function get_new_value(diff: ConflictFreeValueDiff<T>): T | null {
    ensure_diff(diff)
    return diff.new_value
  }

  function change_diff(old_value: T | null, new_value: T | null): ConflictFreeValueDiff<T> {
    return {old_value, new_value}
  }

  function apply(old_value: T | null, diff: ConflictFreeValueDiff<T>): T | null {
    return get_new_value(diff)
  }

  function reverse(diff: ConflictFreeValueDiff<T>): ConflictFreeValueDiff<T> {
    ensure_diff(diff)
    return change_diff(get_new_value(diff), get_old_value(diff))
  }

  function ensure_consistency(value: T | null, diff: ConflictFreeValueDiff<T>): void {
    ensure_diff(diff)
    if (!_.isEqual(value, get_old_value(diff)))
      throw_error(
        'Conflict free value diff consistency problem: Can not apply diff without causing conflicts',
        'value',
        value,
        'diff',
        diff
      )
  }

  function unapply(value: T, diff: ConflictFreeValueDiff<T>, ensure: boolean = true): T | null {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(value, reversed_diff)
    return apply(value, reversed_diff)
  }

  function squash2(
    diff1: ConflictFreeValueDiff<T>,
    diff2: ConflictFreeValueDiff<T>
  ): ConflictFreeValueDiff<T> {
    return {old_value: get_old_value(diff1), new_value: get_new_value(diff2)}
  }

  function is_conflict(value: T | null): false {
    return false
  }

  function get_conflicts(value: T | null): never[] {
    return []
  }

  function is_empty(value: T | null): value is null {
    return value == null
  }

  function get_value(value: T | null): T | null {
    return value
  }

  function clean_neutral_diffs(diff: ConflictFreeValueDiff<T>): ConflictFreeValueDiff<T> | null {
    return _.isEqual(get_old_value(diff), get_new_value(diff)) ? null : diff
  }

  return {
    conflict_free: get_value,
    is_conflict,
    get_conflicts,
    is_empty,
    get_value,
    get_old_value,
    get_new_value,
    apply,
    reverse,
    ensure_consistency,
    unapply,
    squash2,
    squash: create_squash(squash2),
    change_diff,
    create_diff: (value: T) => change_diff(null, value),
    remove_diff: (value: T) => change_diff(value, null),
    clean_neutral_diffs,
    delete_empty,
    default_value: null,
  }
}
