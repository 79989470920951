import React, {FC} from 'react'
import {useRuntimeSelector, AlwaysDefinedRuntime, useTableUiSelector} from '../utils/connect_hocs'
import {useAddRowAction} from '../utils/table_action'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ControlPointDuplicate from '@material-ui/icons/ControlPointDuplicate'
import ContextMenuItem from './ContextMenuItem'

type AddRowItemsProps = {
  on_close: () => void
}

const AddRowItems: FC<AddRowItemsProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const cursor = useTableUiSelector(table._entity_id, 'cursor')
  const selection_size = useTableUiSelector(table._entity_id, 'selection_size')

  const add_row_above = useAddRowAction(table, table._entity_id, {
    place: 'above-selected',
    cursor,
    selection_size,
    on_close,
  })

  const add_row_below = useAddRowAction(table, table._entity_id, {
    place: 'below-selected',
    cursor,
    selection_size,
    on_close,
  })

  const duplicate_row = useAddRowAction(table, table._entity_id, {
    place: 'below-selected',
    cursor,
    selection_size,
    on_close,
    duplicate_content: true,
  })

  const selected_rows_count = Math.abs(selection_size[1]) + 1
  const row_count_text = `${selected_rows_count} row${selected_rows_count > 1 ? 's' : ''}`

  return (
    <>
      <ContextMenuItem
        onClick={add_row_above}
        icon={<ArrowUpward fontSize="small" />}
        item={`Add ${row_count_text} Above`}
      />
      <ContextMenuItem
        onClick={add_row_below}
        icon={<ArrowDownward fontSize="small" />}
        item={`Add ${row_count_text} Below`}
      />
      <ContextMenuItem
        onClick={duplicate_row}
        icon={<ControlPointDuplicate fontSize="small" />}
        item={`Duplicate ${row_count_text}`}
      />
    </>
  )
}

export default AddRowItems
