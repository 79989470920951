import React, {FC, useCallback} from 'react'
import {focus_table_content} from '../utils/focus'
import {
  useSetTableUiState,
  useTableUiSelector,
  useRuntimeSelector,
  AlwaysDefinedRuntime,
} from '../utils/connect_hocs'
import {reset_ui_state} from '../Table'
import {useRuntimeActions} from '../RuntimeContextProvider'
import Delete from '@material-ui/icons/Delete'
import ContextMenuItem from './ContextMenuItem'
import {get_row_selection} from '../table_data_helpers'

type RemoveSelectedRowsItemProps = {
  on_close: () => void
}

const RemoveSelectedRowsItem: FC<RemoveSelectedRowsItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const selection_size = useTableUiSelector(table_entity_id, 'selection_size')
  const rows_order = useTableUiSelector(table_entity_id, 'rows_order')

  const {dispatch_storage} = useRuntimeActions()
  const set_table_ui_state = useSetTableUiState(table_entity_id)

  const row_selection = get_row_selection(rows_order, cursor, selection_size)

  const on_click = useCallback(() => {
    dispatch_storage(table._actions.remove_rows(row_selection))
    set_table_ui_state(reset_ui_state)

    focus_table_content()
    on_close()
  }, [dispatch_storage, table, set_table_ui_state, row_selection, on_close])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={<Delete fontSize="small" />}
      item={`Delete row${row_selection.length > 1 ? 's' : ''}`}
    />
  )
}

export default RemoveSelectedRowsItem
