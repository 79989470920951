import {EntityEditableSchema, EntityUneditableSchema} from 'common/entities/entity_utils'
import {Entity, ViewTable} from 'common/types/storage'

const VIEW_TABLE_SCHEMA_DEFAULTS: Pick<
  Entity<'view_table'>,
  keyof EntityEditableSchema<'view_table'> | 'type'
> = {
  type: 'view_table',
  name: 'empty_view_table',
  cols: {},
  source_cols: {},
  cols_order: [],
  order_by: [],
}

const create_view_table_entity = ({
  zone_id,
  parent_id,
  entity_id,
  source_entity_id,
  ...schema
}: Omit<EntityUneditableSchema<'view_table'>, 'type'> &
  Partial<EntityEditableSchema<'view_table'>>): ViewTable<'entity'> => {
  return {
    ...VIEW_TABLE_SCHEMA_DEFAULTS,
    zone_id,
    parent_id,
    entity_id,
    source_entity_id,
    ...schema,
  }
}

export {VIEW_TABLE_SCHEMA_DEFAULTS, create_view_table_entity}
