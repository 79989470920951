import React, {FC, useCallback} from 'react'
import {Box, Collapse} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import _ from 'lodash'
import {Redirect, useRouteMatch} from 'react-router-dom'

import {Runtime} from 'common/create_runtime'
import {projects_table_entities_task} from 'common/project_utils'
import {Entity, Project, ProjectId, TableType} from 'common/types/storage'
import {is_error, MaybeError} from 'common/error'

import {useContextRunner} from '../runtime_hooks'
import {RuntimeResources, useRuntimeSelector} from '../utils/connect_hocs'
import RecentlyViewed from '../RecentlyViewed'
import {ROUTES} from '../utils/navigation_utils'
import OrganisationGallery from './OrganisationGallery'
import ProjectGallery from './ProjectGallery'
import ErrorPage from './ErrorPage'
import LoadingSkeleton from '../components/LoadingSkeleton'
import Committer from '../navigation/Committer'
import {organisations_project_entities_task} from 'common/organisation_utils'
import {ArchivedInfoBar} from '../ArchivedInfoBar'

export type OrganisationPageResources = {
  organisation: {name: string}
  organisation_id: string
}

export type ProjectPageResources = {
  project: Project<'entity'>
  project_id: ProjectId
}

const Container = styled(Box)(({theme}) => ({
  flex: '1 1 auto',
  padding: theme.spacing(3),
}))

export type BrowserPageRouteProps = {organisation_id?: string; project_id?: string}

const BrowserPage: FC = () => {
  const project_match = useRouteMatch<BrowserPageRouteProps>({
    path: ROUTES.project(':organisation_id', ':project_id'),
  })
  const organisation_match = useRouteMatch<BrowserPageRouteProps>({
    path: ROUTES.organisation(':organisation_id'),
  })
  const url_organisation_id =
    project_match?.params.organisation_id || organisation_match?.params.organisation_id
  const url_project_id = project_match?.params.project_id

  const {status, runtime, error, storage, resources} = useRuntimeSelector()
  const include_archived = storage?.include_archived

  const get_resources = useCallback(
    (runtime: Runtime): RuntimeResources => {
      let response = {}
      if (url_organisation_id) {
        const project_tasks = organisations_project_entities_task(runtime, url_organisation_id)
        const projects = runtime.get_all<MaybeError<Entity<'project'>>>(project_tasks)

        response = {
          ...response,
          organisation_resources: {
            organisation: runtime.get_organisation(url_organisation_id),
            organisation_id: url_organisation_id,
            projects,
          },
        }
      }

      if (url_project_id) {
        const project_task = () => runtime.get_project(url_project_id)
        const table_tasks = projects_table_entities_task(runtime, url_project_id, include_archived)

        const [project] = runtime.get_all<MaybeError<Entity<'project' | TableType>>>([
          project_task,
          ...table_tasks,
        ])

        response = {
          ...response,
          project_resources: {
            project: project as Project<'entity'>,
            project_id: url_project_id,
          },
        }
      }

      return response
    },
    [url_organisation_id, url_project_id, include_archived]
  )
  useContextRunner(get_resources)

  if (runtime == null || storage == null) {
    return (
      <Container>
        <LoadingSkeleton />
      </Container>
    )
  } else if (status === 'error') {
    if (is_error(error) && error.type === 'user' && error.subtype === 'unknown-entity') {
      return <Redirect to={ROUTES.browser()} />
    } else {
      return <ErrorPage error={error} />
    }
  }

  const show_alert =
    resources?.project_resources &&
    !_.isEmpty(storage.multidiff[resources.project_resources.project.zone_id])

  return (
    <Container>
      {resources?.project_resources?.project && (
        <ArchivedInfoBar entity_id={resources?.project_resources?.project.entity_id} />
      )}

      <Collapse in={show_alert} exit={false} mountOnEnter={false} unmountOnExit>
        <Alert
          severity="warning"
          style={{marginBottom: 16}}
          action={
            resources?.project_resources && (
              <Committer commit_button_type="secondary" discard_button_type="tertiary" />
            )
          }
        >
          There are changes that you didn't save. Please save them before leaving.
        </Alert>
      </Collapse>
      {!url_organisation_id && !url_project_id && <RecentlyViewed />}
      {url_organisation_id && !url_project_id && <OrganisationGallery />}
      {url_project_id && <ProjectGallery />}
    </Container>
  )
}

export default BrowserPage
