import React, {FC, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {Button, Grid} from '@material-ui/core'
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone'
import _ from 'lodash'

import {open_modal} from '../Modals'
import {ROUTES} from '../utils/navigation_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import SectionCaption from '../components/SectionCaption'
import {has_create_permissions} from 'common/permission/permission_utils'
import {EntityListItem} from './EntityListItem'

const OrganisationProjects: FC = () => {
  const {
    runtime,
    storage: {entity_headers, include_archived},
    resources: {
      organisation_resources: {organisation_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const dispatch = useDispatch()

  const handle_create_project = useCallback(() => {
    dispatch(open_modal('new_project', {organisation_id}))
  }, [dispatch, organisation_id])

  const projects = useMemo(() => {
    return _.sortBy(
      _.map(
        _.pickBy(
          entity_headers,
          (header) =>
            header.type === 'project' &&
            header.parent_id === organisation_id &&
            (!header.archived || include_archived)
        ),
        (project, project_id) => ({
          label: project.name,
          id: project_id,
          type: project.type,
        })
      ),
      [(project) => project.label.toLowerCase()]
    )
  }, [entity_headers, organisation_id, include_archived])
  const can_create = has_create_permissions(runtime, organisation_id)

  return (
    <>
      <SectionCaption
        action={
          can_create && (
            <Button startIcon={<AddTwoToneIcon />} onClick={handle_create_project} color="primary">
              Create a project
            </Button>
          )
        }
        header="Projects"
      />

      <Grid container spacing={2}>
        {projects.map(({id, label, type}) => (
          <EntityListItem
            key={id}
            parent_id={organisation_id}
            entity_id={id}
            label={label}
            type={type}
            href={ROUTES.project(organisation_id, id)}
          />
        ))}
      </Grid>
    </>
  )
}

export default OrganisationProjects
