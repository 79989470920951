import React, {useEffect, useState, useRef, useCallback, FC} from 'react'
import _ from 'lodash'
import {Tooltip} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CustomInput from '../components/CustomInput'

import {
  AlwaysDefinedRuntime,
  useRuntimeSelector,
  useSetTableUiState,
  useTableUiSelector,
} from '../utils/connect_hocs'
import {split_on_space_outside_quotes} from 'common/utils'

const Search: FC = () => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const redux_search_text = useTableUiSelector(table_entity_id, 'search_text')
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const set_ui_state = useSetTableUiState(table_entity_id)

  const [search_text, set_search_text] = useState<string>(redux_search_text || '')
  const debounced_save = useRef<_.DebouncedFunc<(value: string) => void> | null>(null)

  const tooltip_content = split_on_space_outside_quotes(redux_search_text || '')
    .map((item) => `"${item}"`)
    .join(' ')

  const handle_change = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    set_search_text(e.target.value)
    if (debounced_save.current) {
      debounced_save.current(e.target.value)
    }
  }, [])

  useEffect(() => {
    set_search_text(redux_search_text || '')
  }, [redux_search_text])

  useEffect(() => {
    debounced_save.current = _.debounce((search_text: string) => {
      if (cursor) {
        set_ui_state({search_text, scroll_to: cursor, last_scroll_to: cursor}, 'set_search')
      } else {
        set_ui_state({search_text}, 'set_search')
      }
    }, 550)

    return () => {
      // Flush when changing tables (even if no change) to avoid race condition
      if (debounced_save.current) {
        debounced_save.current.flush()
      }
    }
  }, [set_ui_state, cursor])

  return (
    <Tooltip placement={'top'} title={tooltip_content}>
      <span>
        <CustomInput
          placeholder="Find in table"
          value={search_text}
          onChange={handle_change}
          icon={<SearchIcon />}
        />
      </span>
    </Tooltip>
  )
}

export default Search
