import React, {FC, useCallback, useState} from 'react'
import {OutlinedInput} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

type StringEditorProps = {
  value: string
  set_value: (value: string) => void
  end_edit: () => void
  on_enter?: () => void
  multiline?: boolean
  clear_on_open?: boolean
  in_detailed_view?: boolean
  placeholder?: string
}

const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      width: '100%',
      padding: theme.spacing(0),
    },
    input: {
      padding: theme.spacing(1),
      font: theme.typography.gridCell.normal,
    },
  })
)

const useDetailedViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      width: '100%',
      padding: theme.spacing(0),
    },
    input: {
      padding: theme.spacing(1),
      fontSize: '14px',
    },
  })
)

export const StringEditor: FC<StringEditorProps> = ({
  value,
  set_value,
  end_edit,
  on_enter,
  multiline = false,
  clear_on_open = false,
  in_detailed_view = false,
  placeholder,
}) => {
  const [input_value, set_input_value] = useState(clear_on_open ? '' : value)
  const table_style = useTableStyles()
  const detailed_view_style = useDetailedViewStyles()

  const save_value = useCallback(() => {
    if (input_value !== value) {
      set_value(input_value)
    }
  }, [input_value, set_value, value])

  const handle_key_down = useCallback(
    (e) => {
      switch (e.key) {
        case 'Tab': {
          save_value()
          end_edit()
          return // let Tab propagate
        }
        case 'Enter':
          if (!e.shiftKey) {
            e.preventDefault()
            save_value()
            end_edit()
            on_enter?.()
          }
          break
        case 'Escape': {
          end_edit()
          break
        }
        default:
        //pass
      }
      e.stopPropagation()
    },
    [save_value, end_edit, on_enter]
  )

  const on_blur = useCallback(() => {
    save_value()
    end_edit()
  }, [end_edit, save_value])

  const handle_change = useCallback((e) => {
    set_input_value(e.target.value)
  }, [])

  const rows = in_detailed_view ? {} : {rows: 9}
  const classes = in_detailed_view ? detailed_view_style : table_style

  return (
    <OutlinedInput
      multiline={multiline}
      value={input_value}
      onChange={handle_change}
      onKeyDown={handle_key_down}
      onBlur={on_blur}
      classes={classes}
      placeholder={placeholder}
      autoFocus
      {...rows}
    />
  )
}

export default StringEditor
