import ih_update from 'immutability-helper'
import _ from 'lodash'
import {ensure, throw_error} from './utils'
import {get_storage_utils} from './storage_utils'
import {compose_resource_id, parse_resource_id} from './params_utils'
import {is_error, MaybeError} from './error'
import {apply, apply as entity_apply, squash as entity_squash} from './diffs/models/entity_diff'
import {is_new_entity} from './entities/diff_utils'
import {
  _ensure_computed_result_can_be_set,
  _ensure_dependency_can_be_added,
  _ensure_external_data_final_result_can_be_set,
  _ensure_external_data_temporary_result_can_be_set,
  _ensure_resource_does_not_exist,
} from './dispatch_asserts'
import {
  CommitId,
  Commits,
  ComputableResourceData,
  DiffStorage,
  DownloadableResourceData,
  Entity,
  EntityDiff,
  EntityHeader,
  EntityHeaders,
  EntityId,
  ExternalData,
  ExternalFn,
  Resource,
  ResourceData,
  ResourceFn,
  ResourceId,
  Storage,
  VersionMap,
  ZoneCommits,
  ZoneDiff,
  ZoneId,
} from 'common/types/storage'
import {get_entities_table_entity} from 'common/permission/permission_tables'
import {EntityPermission} from 'common/permission/permission_utils'
;(ih_update as any).extend('$auto', (value, object) => {
  return object ? ih_update(object, value) : ih_update({}, value)
})

const IMMUTABLE_ACTIONS = new Set([
  'undo',
  'redo',
  'commit',
  'set-history-mode',
  'set-include-archived',
  'set-latest-versions',
  'add-diff',
  'add-multi-diff',
  'set-versions',
  'set-external-data-final-result',
  'discard-changes',
])

const MUTABLE_ACTIONS = new Set([
  'remove-resource',
  'update-commits',
  'update-entity-headers',
  'new-entity-headers',
  'set-resource-result',
  'set-computed-result',
  'set-permissions-result',
  'add-dependency',
  'new-commits',
  'new-entity',
  'new-entity-commit',
  'new-permissions',
  'new-computed-resource',
  'new-custom-resource',
  'new-external-data',
  'set-external-data-temporary-result',
])

function clean_multidiff(multidiff: ZoneDiff): ZoneDiff {
  // remove diffs that include only `type`
  return _.omitBy(multidiff, (diff) => _.isEqual(_.keys(diff), ['type']))
}

function pick_multidiff(multidiff: ZoneDiff, pathsByEntity: Record<EntityId, string[]>): ZoneDiff {
  return clean_multidiff(
    _.mapValues(
      multidiff,
      (diff, entity_id) =>
        // always pick also 'type'
        _.pick(diff, ['type', ...(pathsByEntity[entity_id] || [])]) as EntityDiff
    )
  )
}

function omit_multidiff(multidiff: ZoneDiff, pathsByEntity: Record<EntityId, string[]>): ZoneDiff {
  return clean_multidiff(
    _.mapValues(
      multidiff,
      (diff, entity_id) => _.omit(diff, pathsByEntity[entity_id] || []) as EntityDiff
    )
  )
}

function _make_safe<T extends object>(obj: T): T {
  const handler = {
    get: (target: T, prop: keyof T) => {
      ensure(_.has(target, prop), 'Missing key', {key: prop, obj: target})
      return target[prop]
    },
  }
  return new Proxy(obj, handler as ProxyHandler<T>)
}

// returns only the active diffs for a specific zone
// (we decrease only diff_cnt[zone_id] on undo, so redo is possible)
function get_active_diffs_for_zone({diffs, diff_cnt}: DiffStorage, zone_id: ZoneId): ZoneDiff[] {
  return diffs[zone_id] ? diffs[zone_id].slice(0, diff_cnt[zone_id]) : []
}

// returns the active diffs for all zones, grouped by zone
function get_active_diffs_by_zones(storage: DiffStorage): Record<ZoneId, ZoneDiff[]> {
  return _.mapValues(storage.diffs, (_diffs, zone_id) =>
    get_active_diffs_for_zone(storage, zone_id)
  )
}

// groups an array of diffs by entity_id
function group_by_entities(diffs: ZoneDiff[]): Record<EntityId, EntityDiff[]> {
  const diffs_by_entities: Record<EntityId, EntityDiff[]> = {}
  for (const multi_diff of diffs) {
    for (const [entity_id, entity_diff] of Object.entries(multi_diff)) {
      if (!diffs_by_entities[entity_id]) {
        diffs_by_entities[entity_id] = []
      }
      diffs_by_entities[entity_id].push(entity_diff)
    }
  }
  return diffs_by_entities
}

// returns a squashed entity diff
function squash_entity_diffs(diffs: EntityDiff[]): EntityDiff {
  return entity_squash(...diffs)
}

export function create_multidiff_for_zone(storage: DiffStorage, zone_id: ZoneId): ZoneDiff {
  const active_diffs = get_active_diffs_for_zone(storage, zone_id)
  return _.mapValues(group_by_entities(active_diffs), squash_entity_diffs)
}

// recalculates the multidiff for one zone
function recalculate_multidiff(storage: Storage, zone_id: ZoneId): Storage {
  return ih_update(storage, {
    multidiff: {[zone_id]: {$set: create_multidiff_for_zone(storage, zone_id)}},
  })
}

function apply_diffs_on_resource(
  resource: MaybeError<ResourceData>,
  resource_id: ResourceId,
  storage: Storage
): MaybeError<ResourceData> {
  if (is_error(resource)) {
    return resource
  }

  const {type, entity_id} = parse_resource_id(resource_id)
  if (type === 'entity') {
    const {zone_id} = resource as Entity
    // Optimization - only look for diffs in the resource's zone
    const diffs = group_by_entities(get_active_diffs_for_zone(storage, zone_id))[entity_id]
    if (diffs && diffs.length > 0) {
      return entity_apply(resource, ...diffs)
    }
  }

  return resource
}

function get_entity_header(entity: Entity): EntityHeader {
  return _.pick(entity, [
    'zone_id',
    'type',
    'subtype',
    'name',
    'description',
    'archived',
    'entity_id',
    'parent_id',
  ])
}

function get_entity_header_changes(partial_entity: Entity): Partial<EntityHeader> {
  return _.omitBy(
    _.pick(partial_entity, [
      'zone_id',
      'type',
      'subtype',
      'name',
      'description',
      'archived',
      'entity_id',
      'parent_id',
    ]),
    _.isNil
  )
}

function recalculate(storage: Storage): Storage {
  const resources: Record<ResourceId, Resource> = {}
  const {history_mode} = storage
  const {resource_to_commit} = get_storage_utils(storage)
  // Order of diffs accross different zones does not matter
  const active_diffs = history_mode ? [] : Object.values(get_active_diffs_by_zones(storage)).flat()
  const diffs_by_entity = group_by_entities(active_diffs)

  const downloaded_entities: Record<EntityId, MaybeError<Entity>> = {}
  const downloaded_resources = storage.downloads.resources
  const downloaded_permissions = storage.downloads.permissions

  for (const resource_id of Object.keys(downloaded_resources)) {
    // Note that resource_to_commit uses only '.versions' part of storage, that is already updated.
    const commit_id = resource_to_commit(resource_id)
    const resource = downloaded_resources[resource_id][commit_id]
    if (resource != null) {
      const {type: resource_type, entity_id} = parse_resource_id(resource_id)
      // commit resources cannot be modified by diffs, so we can copy them right away
      // into `resources`
      if (resource_type === 'commit') {
        resources[resource_id] = {result: resource, status: 'done', required_by: {}}
      } else {
        // Just remember the entity_id, it'll be processed later.
        downloaded_entities[entity_id] = resource as MaybeError<Entity>
      }
    }
  }

  for (const entity_id of _.union(_.keys(downloaded_permissions), _.keys(diffs_by_entity))) {
    const permission = downloaded_permissions[entity_id]
    const permission_resource_id = compose_resource_id({
      type: 'permissions',
      entity_id,
    })
    if (permission != null) {
      // permissions resources cannot be modified by diffs, so we can copy them right away
      // into `resources`
      resources[permission_resource_id] = {result: permission, status: 'done', required_by: {}}
    } else {
      const diffs = _.get(diffs_by_entity, entity_id, [])
      if (diffs.length > 0 && is_new_entity(diffs[0])) {
        // Entity is not committed yet, it was created by the user, which means
        // the user has all permissions, possibly without history. History is not
        // shown before committing anyway, so we set it to false.
        const new_entity_permission = {read_all: true, write: true, create: true, history: false}
        resources[permission_resource_id] = {
          result: new_entity_permission,
          status: 'done',
          required_by: {},
        }
      }
    }
  }

  const downloaded_headers = storage.downloads.entity_headers
  const entity_headers: EntityHeaders =
    downloaded_headers !== undefined && downloaded_headers !== 'in-progress'
      ? {...downloaded_headers}
      : {}

  // if entity is included in diffs, it might have one of its header params changed (e.g. name),
  // so we get entity header's params by merging downloaded headers with changes in diffs
  // and this way also get entity headers from tables which are created locally
  for (const entity_id of _.keys(diffs_by_entity)) {
    for (const diff of diffs_by_entity[entity_id]) {
      const partial_entity = apply(null, diff)
      const entity_changed_fields = get_entity_header_changes(partial_entity)
      entity_headers[entity_id] = {
        ...entity_headers[entity_id],
        ...entity_changed_fields,
      }
    }
  }

  for (const entity_id of _.union(_.keys(downloaded_entities), _.keys(diffs_by_entity))) {
    const resource_id = compose_resource_id({type: 'entity', entity_id})
    const downloaded_entity = downloaded_entities[entity_id]
    const diffs = _.get(diffs_by_entity, entity_id, [])
    let entity: MaybeError<Entity>
    if (is_error(downloaded_entity)) {
      entity = downloaded_entity
    } else if (downloaded_entity == null && diffs.length > 0 && !is_new_entity(diffs[0])) {
      // First diff for newly created entities has to set entity_id. There are however a few
      // cases, where downloaded_entity is null and diffs do not set entity_id:
      // - runner dispatching change before fetching the entity
      // - glitchy state after committing, while the entity with a new state is not yet fetched
      // - diffs loaded from localStorage right after starting the app
      // - (in the future:) diffs applied on entity for which user does have read permissions, but
      // still wants to modify it.
      continue
    } else {
      // Note that if the entity is newly created, its initial state will be undefined.
      entity = entity_apply(downloaded_entity, ...diffs)
    }

    resources[resource_id] = {result: entity, status: 'done', required_by: {}}
  }

  const all_organisations_ids = Object.keys(entity_headers).filter((entity_id) => {
    return entity_headers[entity_id].type === 'organisation'
  })

  for (const organisation_id of all_organisations_ids) {
    const entities_table_entity = get_entities_table_entity(entity_headers, organisation_id)
    resources[compose_resource_id({type: 'entity', entity_id: entities_table_entity.entity_id})] = {
      result: entities_table_entity,
      status: 'done',
      required_by: {},
    }

    entity_headers[entities_table_entity.entity_id] = get_entity_header(entities_table_entity)
  }

  const external_resources = storage.external_data
  Object.entries(external_resources).forEach(([resource_id, external_resource]) => {
    const common_properties = {
      required_by: {},
      dependencies: {},
      fn: external_resource.fn,
    }
    if (external_resource.status === 'done') {
      resources[resource_id] = {
        result: external_resource.result,
        status: 'done',
        ...common_properties,
      }
    } else if (external_resource.status === 'in-progress') {
      if ('tmp_result' in external_resource) {
        resources[resource_id] = {
          result: external_resource.tmp_result,
          status: 'done',
          ...common_properties,
        }
      } else {
        resources[resource_id] = {
          status: 'in-progress',
          ...common_properties,
        }
      }
    } else {
      throw_error('Invariant violation. Unknown external resource status')
    }
  })

  return ih_update(storage, {
    resources: {$set: resources},
    entity_headers: {$set: entity_headers},
    commits: {$set: storage.downloads.commits},
  })
}

// delta=-1 -> undo
// delta=+1 -> redo
function move_in_edit_history(storage: Storage, zone_id: ZoneId, delta: number): Storage {
  const zone_diffs = _.get(storage.diffs, zone_id, [])
  const new_zone_diff_cnt = _.get(storage.diff_cnt, zone_id, 0) + delta
  ensure(new_zone_diff_cnt >= 0, 'Undo not possible, already at the first element')
  ensure(new_zone_diff_cnt <= zone_diffs.length, 'Redo not possible, already at the last element')
  const updates = {diff_cnt: {[zone_id]: {$set: new_zone_diff_cnt}}}
  return recalculate(recalculate_multidiff(ih_update(storage, updates), zone_id))
}

function get_all_dependent_of(derived: ResourceId, storage: Storage): Set<ResourceId> {
  const bases = new Set<ResourceId>()
  const _collect_all_dependent_of = (current_id: ResourceId) => {
    if (bases.has(current_id)) {
      return
    }
    bases.add(current_id)
    for (const key of Object.keys(_.get(storage.resources[current_id], 'required_by', {}))) {
      _collect_all_dependent_of(key)
    }
  }
  _collect_all_dependent_of(derived)
  return bases
}

const _set_resource_result = (
  storage: Storage,
  {
    result,
    resource_id,
    commit_id,
  }: {
    result: MaybeError<DownloadableResourceData>
    resource_id: ResourceId
    commit_id: CommitId
  }
): Storage => {
  const {resource_to_commit} = get_storage_utils(storage)
  const {history_mode} = storage

  // do not apply diffs in history mode
  const head_result = history_mode ? result : apply_diffs_on_resource(result, resource_id, storage)

  storage.downloads = ih_update(storage.downloads, {
    resources: {[resource_id]: {$auto: {[commit_id]: {$auto: {$set: result}}}}},
  })

  if (commit_id === resource_to_commit(resource_id)) {
    storage.resources[resource_id] = ih_update(storage.resources[resource_id], {
      result: {$set: head_result},
      status: {$set: 'done'},
    })
  }

  return storage
}

const _set_permissions_result = (
  storage: Storage,
  {
    result,
    resource_id,
  }: {
    result: MaybeError<EntityPermission>
    resource_id: ResourceId
  }
): Storage => {
  const {entity_id} = parse_resource_id(resource_id)
  const permission_resource_id = compose_resource_id({type: 'permissions', entity_id})
  storage.downloads = ih_update(storage.downloads, {
    permissions: {
      [entity_id]: {
        $set: result,
      },
    },
  })

  storage.resources[permission_resource_id] = ih_update(storage.resources[permission_resource_id], {
    result: {$set: result},
    status: {$set: 'done'},
  })

  return storage
}

const _set_computed_result = (
  storage: Storage,
  {
    result,
    resource_id,
  }: {
    result: MaybeError<ComputableResourceData>
    resource_id: ResourceId
  }
): Storage => {
  _ensure_computed_result_can_be_set(storage, resource_id)
  storage.resources[resource_id] = ih_update(storage.resources[resource_id], {
    result: {$set: result},
    status: {$set: 'done'},
  })

  return storage
}

const _add_dependency = (
  storage: Storage,
  {
    resource_id,
    dependency_id,
  }: {
    resource_id: ResourceId
    dependency_id: ResourceId
  }
): Storage => {
  _ensure_dependency_can_be_added(storage, resource_id, dependency_id)
  // Check if there already is direct dependency between resources.
  // If not, mark this to require_by structure of dependency_id
  // and add all of it's dependencies to resource_id.
  if (!_.has(storage.resources[dependency_id].required_by, resource_id)) {
    const dependency_with_deps = _.assign(
      {[dependency_id]: true},
      _.get(storage.resources[dependency_id], 'dependencies', {})
    )

    storage.resources[dependency_id] = ih_update(storage.resources[dependency_id], {
      required_by: {$auto: {$merge: {[resource_id]: true}}} as any,
    })

    for (const base_id of get_all_dependent_of(resource_id, storage)) {
      storage.resources[base_id] = ih_update(storage.resources[base_id], {
        dependencies: {$auto: {$merge: dependency_with_deps}} as any,
      })
    }
  }

  return storage
}

const _set_new_resource = (
  storage: Storage,
  resource_id: ResourceId,
  dependencies?: Record<ResourceId, boolean>,
  fn?: ResourceFn
): Storage => {
  _ensure_resource_does_not_exist(storage, resource_id)

  storage.resources[resource_id] = {
    status: 'in-progress',
    required_by: {},
    ...(dependencies ? {dependencies} : {}),
    ...(fn ? {fn} : {}),
  }

  return storage
}

const _new_permissions = (
  storage: Storage,
  {
    resource_id,
  }: {
    resource_id: ResourceId
  }
): Storage => {
  storage.resources[resource_id] = {
    status: 'in-progress',
    required_by: {},
  }
  return storage
}

const _new_entity_or_entity_commit = (
  storage: Storage,
  {
    resource_id,
  }: {
    resource_id: ResourceId
  }
): Storage => {
  return _set_new_resource(storage, resource_id)
}

const _new_computed_resource = (
  storage: Storage,
  {
    resource_id,
  }: {
    resource_id: ResourceId
  }
): Storage => {
  return _set_new_resource(storage, resource_id, {})
}

const _new_custom_resource = (
  storage: Storage,
  {
    resource_id,
    fn,
  }: {
    resource_id: ResourceId
    fn: ResourceFn
  }
): Storage => {
  return _set_new_resource(storage, resource_id, {}, fn)
}

const _new_entity_headers = (storage: Storage, _params: {}): Storage => {
  const {entity_headers} = storage.downloads
  if (entity_headers !== undefined) {
    throw_error('Invariant violation. Entity headers fetch has already been started')
  }
  storage.downloads.entity_headers = 'in-progress'
  return storage
}

const _update_entity_headers = (
  storage: Storage,
  {entity_headers}: {entity_headers: EntityHeaders}
): Storage => {
  storage.downloads.entity_headers = entity_headers
  const recalculated_storage = recalculate(storage)
  storage.resources = recalculated_storage.resources
  storage.entity_headers = recalculated_storage.entity_headers
  storage.commits = recalculated_storage.commits
  return storage
}

function get_latest_commit(zone_commits: ZoneCommits, fixed_date?: Date): CommitId | undefined {
  if (fixed_date) {
    const commit_ids = Object.keys(zone_commits).filter(
      (commit_id) => new Date(zone_commits[commit_id].authored_time) <= fixed_date
    )
    zone_commits = _.pick(zone_commits, commit_ids)
  }
  const max_sequence = _.max(_.map(zone_commits, 'sequence'))
  if (max_sequence === undefined) {
    return undefined
  }

  return _.findKey(zone_commits, {sequence: max_sequence})
}

export function get_latest_versions(commits: Commits, fixed_date?: Date): VersionMap {
  const versions = _.mapValues(commits, (zone_commits): CommitId | undefined => {
    if (is_error(zone_commits) || zone_commits === 'in-progress') {
      return undefined
    }
    return get_latest_commit(zone_commits, fixed_date)
  })

  return _.pickBy(versions, (commit_id) => commit_id) as VersionMap
}

const _new_commits = (storage: Storage, {zone_id}: {zone_id: ZoneId}): Storage => {
  const commits = storage.commits[zone_id]
  if (commits != null) {
    throw_error('Invariant violation. Commits already exist in the storage.', 'zone_id', zone_id)
  }
  if (commits === 'in-progress') {
    throw_error('Invariant violation. Commit fetch has been already initiated.', 'zone_id', zone_id)
  }
  storage.commits = ih_update(storage.commits, {[zone_id]: {$set: 'in-progress'}})
  return storage
}

const _update_commits = (storage: Storage, {commits}: {commits: Commits}): Storage => {
  const existing_commits = storage.commits
  const {fixed_version = {}, fixed_date} = storage
  const latest_versions = get_latest_versions(commits, fixed_date)

  const latest_versions_for_in_progress_commits: VersionMap = Object.fromEntries(
    Object.keys(commits)
      .filter((zone_id) => existing_commits[zone_id] === 'in-progress')
      .map((zoneId) => [zoneId, latest_versions[zoneId]])
  )

  const versions = {...latest_versions_for_in_progress_commits, ...fixed_version}

  const updated_storage = ih_update(storage, {
    commits: {$merge: commits},
    downloads: {commits: {$merge: commits}},
    //it is safe to merge versions here because we are updating only versions for new commits
    //that were in-progress and now they are fetched (if any) and fixed versions - they are already
    // in the storage. So the storage should be still in consistent state after this.
    versions: {$merge: versions},
  })
  _.assign(storage, updated_storage)
  return storage
}

const _set_versions = (storage: Storage, {versions}: {versions: VersionMap}): Storage => {
  const new_versions = {...versions}
  return recalculate(
    ih_update(storage, {
      versions: {$merge: new_versions},
    })
  )
}

const _set_latest_versions = (storage: Storage): Storage => {
  // We want to get rid of fixed_version. Actually production code is not using them anymore.
  // But they are used in tests. It is used in case in which we want to be at some specific commit
  // and we want pretend that it is the newest one. See description of this field in Storage.
  // So in order to simulate that case we need to override real versions with fixed versions.
  // There is no reason to use fix_version elsewhere.
  const {fixed_version = {}} = storage
  const latest_versions = get_latest_versions(storage.commits)
  const desired_versions = {...latest_versions, ...fixed_version}
  return _set_versions(storage, {versions: desired_versions})
}

const _add_diff = (
  storage: Storage,
  {
    entity_id,
    zone_id,
    diff,
  }: {
    entity_id: EntityId
    zone_id: ZoneId
    diff: EntityDiff
  }
): Storage => {
  const zone_diffs = get_active_diffs_for_zone(storage, zone_id)
  const zone_diff_cnt = storage.diff_cnt[zone_id] || 0
  zone_diffs.push({[entity_id]: diff})
  const updates = {
    diffs: {
      [zone_id]: {
        $set: zone_diffs,
      },
    },
    diff_cnt: {
      [zone_id]: {
        $set: zone_diff_cnt + 1,
      },
    },
  }

  return recalculate(recalculate_multidiff(ih_update(storage, updates), zone_id))
}

const _add_multi_diff = (
  storage: Storage,
  {zone_id, multi_diff}: {zone_id: ZoneId; multi_diff: ZoneDiff}
): Storage => {
  const zone_diffs = get_active_diffs_for_zone(storage, zone_id)
  const zone_diff_cnt = storage.diff_cnt[zone_id] || 0
  const updates = {
    diffs: {[zone_id]: {$set: zone_diffs.concat(multi_diff)}},
    diff_cnt: {[zone_id]: {$set: zone_diff_cnt + 1}},
  }

  return recalculate(recalculate_multidiff(ih_update(storage, updates), zone_id))
}

const _remove_local_changes = (
  storage: Storage,
  {zone_id, keep_diff_paths}: {zone_id: ZoneId; keep_diff_paths: Record<EntityId, string[]>}
): Storage => {
  const kept_zone_diffs = keep_diff_paths
    ? storage.diffs[zone_id]
        .map((multidiff: ZoneDiff) => pick_multidiff(multidiff, keep_diff_paths))
        .filter((multidiff: ZoneDiff) => !_.isEmpty(multidiff))
    : []
  const updates = {
    diffs: {
      [zone_id]: {
        $set: kept_zone_diffs,
      },
    },
    diff_cnt: {
      [zone_id]: {
        $set: kept_zone_diffs.length,
      },
    },
  }
  return recalculate(recalculate_multidiff(ih_update(storage, updates), zone_id))
}

const _commit = (
  storage: Storage,
  {zone_id, keep_diff_paths}: {zone_id: ZoneId; keep_diff_paths: Record<EntityId, string[]>}
): Storage => {
  return _remove_local_changes(storage, {zone_id, keep_diff_paths})
}

const _discard_changes = (storage: Storage, {zone_id}: {zone_id: ZoneId}): Storage => {
  return _remove_local_changes(storage, {zone_id, keep_diff_paths: {}})
}

const _set_history_mode = (
  storage: Storage,
  {
    history_mode,
  }: {
    history_mode: boolean
  }
): Storage =>
  recalculate(
    ih_update(storage, {
      history_mode: {$set: history_mode},
    })
  )

const _set_include_archived = (
  storage: Storage,
  {
    include_archived,
  }: {
    include_archived: boolean
  }
): Storage =>
  recalculate(
    ih_update(storage, {
      include_archived: {$set: include_archived},
    })
  )

const _undo = (storage: Storage, {zone_id}: {zone_id: ZoneId}): Storage => {
  const {is_able_to_undo} = get_storage_utils(storage)
  return is_able_to_undo(zone_id) ? move_in_edit_history(storage, zone_id, -1) : storage
}

const _redo = (storage: Storage, {zone_id}: {zone_id: ZoneId}): Storage => {
  const {is_able_to_redo} = get_storage_utils(storage)
  return is_able_to_redo(zone_id) ? move_in_edit_history(storage, zone_id, +1) : storage
}

const _remove_resource = (storage: Storage, {resource_id}: {resource_id: ResourceId}): Storage => {
  // Check if another resource depends on it
  const resources_to_remove = Array.from(get_all_dependent_of(resource_id, storage))
  // Remove record of resources in required_by from their dependencies
  const resources_with_removed_require = _.mapValues(
    _.omit(storage.resources, resources_to_remove),
    () => ({required_by: {$unset: resources_to_remove}})
  )
  // Remove resource from resources
  storage.resources = ih_update(storage.resources, {
    ...resources_with_removed_require,
    $unset: resources_to_remove,
  })
  return storage
}

const _new_external_data = (
  storage: Storage,
  {
    resource_id,
    fn,
  }: {
    resource_id: ResourceId
    fn: ExternalFn
  }
): Storage => {
  storage.external_data = ih_update(storage.external_data, {
    [resource_id]: {
      $set: {
        status: 'in-progress',
        fn,
      },
    },
  })

  return _set_new_resource(storage, resource_id, {}, fn)
}

const _set_external_data_temporary_result = (
  storage: Storage,
  {
    resource_id,
    result,
    fn,
  }: {
    resource_id: ResourceId
    result: MaybeError<ExternalData>
    fn: ExternalFn
  }
): Storage => {
  _ensure_external_data_temporary_result_can_be_set(storage, resource_id)

  storage.external_data = ih_update(storage.external_data, {
    [resource_id]: {
      $set: {
        tmp_result: result,
        status: 'in-progress',
        fn,
      },
    },
  })

  storage.resources = ih_update(storage.resources, {
    [resource_id]: {
      $set: {
        result,
        status: 'done',
        required_by: {},
        dependencies: {},
        fn,
      },
    },
  })

  return storage
}

const _set_external_data_final_result = (
  storage: Storage,
  {
    result,
    resource_id,
  }: {
    result: MaybeError<ExternalData>
    resource_id: ResourceId
  }
): Storage => {
  _ensure_external_data_final_result_can_be_set(storage, resource_id)

  return recalculate(
    ih_update(storage, {
      external_data: {
        [resource_id]: {
          result: {$set: result},
          status: {$set: 'done'},
        },
      },
    })
  )
}

const apply_change_reducers = {
  // These actions create new storage_state. Should be used with immutable dispatch only,
  // which sets new runtime with updated storage state inside runtime_context.
  'undo': _undo,
  'redo': _redo,
  'commit': _commit,
  'discard-changes': _discard_changes,
  'set-history-mode': _set_history_mode,
  'set-include-archived': _set_include_archived,
  'add-diff': _add_diff,
  'add-multi-diff': _add_multi_diff,
  'set-versions': _set_versions,
  'set-latest-versions': _set_latest_versions,
  'set-external-data-final-result': _set_external_data_final_result,
  // These actions mutate storage_state parts. Should be used with mutable dispatch only,
  // which leaves the previous runtime inside runtime_context with mutated storage_state.
  // These actions mutate storage_state.downloads part.
  'update-commits': _update_commits,
  'update-entity-headers': _update_entity_headers,
  'set-resource-result': _set_resource_result,
  'set-permissions-result': _set_permissions_result,
  'new-entity-headers': _new_entity_headers,
  // These actions mutate storage_state.external_data and storage_state.resources part.
  'new-external-data': _new_external_data,
  'set-external-data-temporary-result': _set_external_data_temporary_result,
  // These actions mutate storage_state.resources part.
  'remove-resource': _remove_resource,
  'set-computed-result': _set_computed_result,
  'add-dependency': _add_dependency,
  'new-entity': _new_entity_or_entity_commit,
  'new-entity-commit': _new_entity_or_entity_commit,
  'new-permissions': _new_permissions,
  'new-computed-resource': _new_computed_resource,
  'new-custom-resource': _new_custom_resource,
  // This action mutates storage_state.commits part.
  'new-commits': _new_commits,
}

export type ActionReducers = typeof apply_change_reducers
export type ActionType = keyof ActionReducers

type ActionParams<T extends ActionType> = Parameters<ActionReducers[T]>[1]
type ActionReducer<T extends ActionType> = (storage: Storage, params: ActionParams<T>) => Storage
export type ActionPayload<T extends ActionType = ActionType> = {type: T} & ActionParams<T>

export type StorageReducer = (storage: Storage) => Storage
export type StorageDispatch<T = void> = (actions: ActionPayload[]) => T

function apply_change<T extends ActionType>(storage: Storage, change: ActionPayload<T>): Storage {
  const reducer: ActionReducer<T> = apply_change_reducers[change.type]
  if (!reducer) {
    throw_error('Unknown operation type', 'type', change.type)
  }
  return reducer(storage, _make_safe(change))
}

export {apply_change, pick_multidiff, omit_multidiff, IMMUTABLE_ACTIONS, MUTABLE_ACTIONS}
