import record_diff from '../base/record_diff'
import value_diff from '../base/value_diff'
import type_diff from './type_diff'

const param_diff = record_diff({
  name: value_diff(),
  type: type_diff,
})

export default param_diff
