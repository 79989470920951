import React, {FC} from 'react'
import {Collapse} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {EntityId} from 'common/types/storage'
import {is_entity_or_parent_archived} from 'common/archived_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from './utils/connect_hocs'

type ArchivedInfoBarProps = {
  entity_id: EntityId
}

export const ArchivedInfoBar: FC<ArchivedInfoBarProps> = ({entity_id}) => {
  const {
    storage: {entity_headers},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const parent_entity_id = entity_headers[entity_id].parent_id
  const parent_entity_type = entity_headers[parent_entity_id].type

  const is_entity_archived = !!entity_headers[entity_id].archived
  const is_parent_entity_archived = is_entity_or_parent_archived(parent_entity_id, entity_headers)

  return (
    <Collapse
      in={is_entity_archived || is_parent_entity_archived}
      exit={false}
      mountOnEnter={false}
      unmountOnExit
    >
      <Alert severity="info" style={{marginBottom: 16}}>
        You are viewing
        {is_entity_archived && ' archived'}
        {entity_headers[entity_id].type === 'project' ? ' project' : ' table'}
        {is_parent_entity_archived &&
          (parent_entity_type === 'project'
            ? ' under archived project'
            : ' based on archived table')}
        .
      </Alert>
    </Collapse>
  )
}
