import React, {FC, useCallback} from 'react'
import {redo} from '../utils/table_actions_helpers'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {get_storage_utils} from 'common/storage_utils'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {Tooltip} from '@material-ui/core'
import CustomButton from '../components/CustomButton'

const RedoButton: FC = () => {
  const {dispatch_storage} = useRuntimeActions()
  const {
    storage,
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const storage_utils = get_storage_utils(storage)

  const handle_click = useCallback(() => {
    redo({dispatch_storage, storage_utils, table})
  }, [dispatch_storage, storage_utils, table])

  const disabled =
    table?._zone_id == null ||
    !storage_utils.is_able_to_redo(table._zone_id) ||
    storage.history_mode

  return (
    <Tooltip title={'Redo (Ctrl+Shift+Z)'}>
      <span>
        <CustomButton type="tertiary" onClick={handle_click} disabled={disabled} onlyIcon>
          <i className="fas fa-arrow-right" />
        </CustomButton>
      </span>
    </Tooltip>
  )
}

export default RedoButton
