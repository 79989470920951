import React, {FC, useCallback} from 'react'
import {
  MenuItem,
  Select,
  styled,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import {FilterOperator, FilterType, FilterValue, UiFilterCondition} from 'common/types/filter'
import {CellType, ColumnId, ColumnType} from 'common/types/storage'
import FilterTypeSelector from './FilterTypeSelector'
import FilterValueEditor from './FilterValueEditor'
import CustomIconButton from '../components/CustomIconButton'
import ColumnSelector from '../Selectors/ColumnSelector'

const OPERATOR_LABELS: Record<FilterOperator, string> = {and: 'AND', or: 'OR'}

type ConditionRowProps = {
  condition: UiFilterCondition
  columns: [ColumnId, string][]
  col_type: ColumnType<CellType, 'entity'>
  operator: FilterOperator
  on_change_filter_operator: (event: React.ChangeEvent<HTMLInputElement>) => void
  on_remove: (index: number) => void
  on_filter_column_change: (index: number, col_id: ColumnId) => void
  on_filter_type_change: (index: number, filter: FilterType) => void
  on_filter_value_change: (index: number, value: FilterValue<'internal'>) => void
  autofocus: boolean
  index: number
}

const ValidationError = styled(Typography)(({theme}) => ({
  color: 'red',
  fontSize: 'small',
}))

const StyledTypography = styled(Typography)(({theme}) => ({
  width: 53,
}))

const StyledSelect = withStyles((theme) => ({
  root: {
    color: theme.palette.greenPalette[600],
    fontSize: theme.typography.body2.fontSize,
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: theme.palette.greenPalette[600],
  },
}))(Select)

const StyledTableCell = styled(TableCell)(({theme}) => ({
  'border': 0,
  'padding': theme.spacing(0.5),
  '& > *:before': {
    borderColor: 'transparent',
  },
  '& > *:hover': {
    '&:before': {
      borderColor: 'transparent !important',
    },
  },
  '& > *:after': {
    borderColor: 'transparent !important',
  },
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
}))

const useStyles = makeStyles({
  operator: {
    '& > *': {
      width: 90,
    },
  },
})

const ConditionRow: FC<ConditionRowProps> = (props) => {
  const {
    condition: [, col_id, condition_type, condition_value, condition_value_error],
    columns,
    col_type,
    operator,
    on_change_filter_operator,
    on_remove,
    on_filter_column_change,
    on_filter_type_change,
    on_filter_value_change,
    autofocus,
    index,
  } = props
  const error: boolean = condition_value_error != null
  const _on_filter_column_change = useCallback(
    (col_id: ColumnId) => {
      on_filter_column_change(index, col_id)
    },
    [index, on_filter_column_change]
  )
  const _on_filter_type_change = useCallback(
    (filter: FilterType) => {
      on_filter_type_change(index, filter)
    },
    [index, on_filter_type_change]
  )
  const _on_filter_value_change = useCallback(
    (value: FilterValue<'internal'>) => {
      on_filter_value_change(index, value)
    },
    [index, on_filter_value_change]
  )
  const _on_remove = useCallback(() => {
    on_remove(index)
  }, [index, on_remove])

  const classes = useStyles()
  return (
    <>
      <TableRow>
        <StyledTableCell>
          {index === 0 && <StyledTypography variant="body2">Filter</StyledTypography>}
          {index === 1 && (
            <StyledSelect
              value={operator}
              onChange={on_change_filter_operator}
              IconComponent={ExpandMoreIcon}
            >
              {Object.entries(OPERATOR_LABELS).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
          {index > 1 && <Typography variant="body2">{OPERATOR_LABELS[operator]}</Typography>}
        </StyledTableCell>
        <StyledTableCell>
          <ColumnSelector value={col_id} columns={columns} on_change={_on_filter_column_change} />
        </StyledTableCell>
        <StyledTableCell className={classes.operator}>
          <FilterTypeSelector
            value={condition_type}
            col_type={col_type}
            on_change={_on_filter_type_change}
          />
        </StyledTableCell>
        <StyledTableCell>
          <FilterValueEditor
            autofocus={autofocus}
            value={condition_value}
            col_id={col_id}
            col_type={col_type}
            on_change={_on_filter_value_change}
            filter_type={condition_type}
            error={error}
          />
        </StyledTableCell>
        <StyledTableCell>
          <CustomIconButton onClick={_on_remove} tooltip="delete row">
            <DeleteIcon fontSize="small" />
          </CustomIconButton>
        </StyledTableCell>
      </TableRow>
      {error && (
        <TableRow>
          <StyledTableCell colSpan={3} />
          <StyledTableCell>
            <ValidationError>{condition_value_error}</ValidationError>
          </StyledTableCell>
        </TableRow>
      )}
    </>
  )
}

export default ConditionRow
