import React, {FC, useCallback, useContext} from 'react'
import {useRuntimeSelector, AlwaysDefinedRuntime, useTableUiSelector} from '../utils/connect_hocs'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {UserAccountContext} from '../UserAccountProvider'
import Check from '@material-ui/icons/Check'
import ContextMenuItem from './ContextMenuItem'

type ValidateOptionsItemProps = {
  on_close: () => void
}

const ValidateOptionsItem: FC<ValidateOptionsItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {dispatch_storage} = useRuntimeActions()
  const current_user = useContext(UserAccountContext)
  const cursor = useTableUiSelector(table._entity_id, 'cursor')
  const col_id = cursor?.col_id

  const on_click = useCallback(() => {
    if (col_id != null)
      dispatch_storage(table._actions.validate_options_in_column(col_id, current_user?.email))
    on_close()
  }, [col_id, current_user?.email, dispatch_storage, on_close, table._actions])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={<Check fontSize="small" />}
      item="Validate options in column"
    />
  )
}

export default ValidateOptionsItem
