import React, {ReactNode} from 'react'
import {styled, Typography} from '@material-ui/core'

const Header = styled(Typography)(({theme}) => ({
  fontWeight: 400,
  padding: theme.spacing(2),
}))

const Content = styled('div')(({theme}) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(0.25, 2, 0, 2),
}))

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
})

type SidebarPanelProps = {
  title: string
  children: ReactNode
}

const SidebarContentPanel: React.FC<SidebarPanelProps> = ({title, children}) => {
  return (
    <Container>
      <Header variant="h6">{title}</Header>
      <Content>{children}</Content>
    </Container>
  )
}

export default SidebarContentPanel
