import React, {FC} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import {Rect_LR_TB} from '../utils/layout'
import {Typography} from '@material-ui/core'

type ColumnHeaderTooltipProps = {
  cell_position: Rect_LR_TB
  is_schema_editable: boolean
  col_description: String
}

export const ColumnHeaderTooltip: FC<ColumnHeaderTooltipProps> = ({
  cell_position,
  is_schema_editable,
  col_description,
}) => {
  const hint = `double-click to ${is_schema_editable ? 'edit' : 'view'}`
  return (
    <Tooltip
      open
      title={
        <div style={{textAlign: 'center'}}>
          <Typography>{col_description}</Typography>
          {hint}
        </div>
      }
      placement="bottom"
    >
      <div
        style={{
          zIndex: -1,
          position: 'absolute',
          left: cell_position[0][0],
          top: cell_position[1][0],
          width: cell_position[0][1] - cell_position[0][0],
          height: cell_position[1][1] - cell_position[1][0],
        }}
      />
    </Tooltip>
  )
}
