import value_diff from '../base/value_diff'
import record_diff from '../base/record_diff'
import map_diff from '../base/map_diff'
import constant_diff from '../base/constant_diff'

import {
  type_decorate_apply,
  type_decorate_reverse,
  type_decorate_ensure_consistency,
  type_decorate_unapply,
  type_decorate_squash2,
  type_decorate_change_diff,
  type_decorate_clean_neutral_diffs,
} from './entity_type_helpers'
import {create_squash} from '../base/create_squash'
import col_diff from './col_diff'
import ordered_set_diff from '../base/ordered_set_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'

const recipe = {
  // 'view_table'
  type: constant_diff(),
  zone_id: constant_diff(),
  entity_id: constant_diff(),
  parent_id: constant_diff(),
  name: conflict_free_value_diff(),
  description: conflict_free_value_diff(),
  label: value_diff(),
  // id of data_table or computed_table, which is source for the view
  source_entity_id: constant_diff(),
  // columns that are not included in source entity = computed columns only
  cols: map_diff(col_diff, false, false),
  // used to reorder columns without changing cols_order of source entity
  cols_order: ordered_set_diff(false),
  // used to freeze columns without changing frozen_cols of source entity
  frozen_cols: conflict_free_value_diff(),
  // used to order rows without changing rows_order/order_by of source entity
  order_by: value_diff(),
  // contains col_spec data, which the user wants to override
  // right now we can override tooltips, styles and widths
  source_cols: map_diff(col_diff, false, false),
}

const {
  conflict_free,
  is_conflict,
  get_conflicts,
  apply: _apply,
  reverse: _reverse,
  ensure_consistency: _ensure_consistency,
  unapply: _unapply,
  squash2: _squash2,
  change_diff: _change_diff,
  clean_neutral_diffs: _clean_neutral_diffs,
} = record_diff(recipe)

const apply = type_decorate_apply('view_table', _apply)
const reverse = type_decorate_reverse('view_table', _reverse)
const ensure_consistency = type_decorate_ensure_consistency('view_table', _ensure_consistency)
const unapply = type_decorate_unapply('view_table', _unapply)
const squash2 = type_decorate_squash2('view_table', _squash2)
const change_diff = type_decorate_change_diff('view_table', _change_diff)
const clean_neutral_diffs = type_decorate_clean_neutral_diffs('view_table', _clean_neutral_diffs)

export default {
  conflict_free,
  is_conflict,
  get_conflicts,
  apply,
  reverse,
  ensure_consistency,
  unapply,
  squash: create_squash(squash2),
  change_diff,
  create_diff: (entity) => change_diff({type: 'view_table'}, entity),
  clean_neutral_diffs,
  recipe,
}
