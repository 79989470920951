import React, {FC, useCallback, useEffect, useState} from 'react'
import {Box, CircularProgress, Snackbar, styled, Tooltip} from '@material-ui/core'
import _ from 'lodash'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {Alert} from '@material-ui/lab'
import CustomButton from '../components/CustomButton'
import * as local_storage from '../local_storage'
import {useCommit} from './commit_hook'
import {useDiscardChanges} from './discard_changes_hook'
import {useHistory} from 'react-router-dom'
import {ROUTES} from '../utils/navigation_utils'
import {EntityId, EntityType} from 'common/types/storage'

const ButtonsWrap = styled('div')(({theme}) => ({
  minWidth: 130,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '1rem',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
    gap: '0.5rem',
    padding: '0.5rem',
  },
}))

const ButtonWrap = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
}))

//Never add a beforeunload listener unconditionally or use it as an end-of-session signal.
//Only add it when a user has unsaved work, and remove it as soon as that work has been saved.
//https://developers.google.com/web/updates/2018/07/page-lifecycle-api#the-beforeunload-event
const unload_event_listener = (event: BeforeUnloadEvent): string => {
  //proper handling of event described at:
  //https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  const unloadMessage = 'There are unsaved changes'
  //according to specification
  event.preventDefault()
  //2 legacy options :
  //a) assigning returnValue - modern browsers generally ignore actual content
  event.returnValue = unloadMessage
  //b) returning string, also ignoring content
  return unloadMessage
}

type CommitterButtonType = 'primary' | 'secondary' | 'tertiary'
type CommitterProps = {
  commit_button_type?: CommitterButtonType
  discard_button_type?: CommitterButtonType
}

export const Committer: FC<CommitterProps> = ({
  commit_button_type = 'primary',
  discard_button_type = 'secondary',
}) => {
  const {
    storage,
    resources: {project_resources, table_resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const history = useHistory()
  const [popup_message, set_popup_message] = useState<{
    message: string
    severity?: 'error' | 'success' | 'info' | 'warning'
  }>({message: ''})
  const close_popup = useCallback(
    () => set_popup_message({message: '', severity: popup_message?.severity}),
    [popup_message?.severity]
  )

  const redirect_after_discard = useCallback(
    ({type, entity_id}: {type: EntityType; entity_id: EntityId}) => {
      if (type === 'organisation') {
        history.replace(ROUTES.organisation(entity_id))
      } else if (type === 'project') {
        history.replace(ROUTES.browser())
      } else {
        history.replace(ROUTES.table(entity_id))
      }
    },
    [history]
  )

  const [saving, start_saving] = useCommit({on_error: set_popup_message})
  const [discarding, start_discarding] = useDiscardChanges({
    on_redirect: redirect_after_discard,
    on_error: set_popup_message,
  })

  const zone_id = project_resources ? project_resources.project.zone_id : table_resources.zone_id
  const multidiff = storage.multidiff[zone_id]
  const buttons_disabled = storage.history_mode || _.isEmpty(multidiff) || saving || discarding
  const commit_tooltip = storage.history_mode
    ? 'Saving is disabled in history mode'
    : _.isEmpty(multidiff)
    ? 'No changes to save'
    : 'Save changes'
  const tooltip_discard = storage.history_mode
    ? 'Discarding is disabled in history mode'
    : _.isEmpty(multidiff)
    ? 'No changes to discard'
    : 'Discard changes'

  const {multidiff: full_multidiff} = storage
  const is_every_change_saved =
    local_storage.has_diffs() ||
    Object.values(full_multidiff).every((zone_diff) => _.isEmpty(zone_diff))

  useEffect(() => {
    if (is_every_change_saved) {
      // it's safe to try and remove an event listener even if it isn't registered
      // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#notes
      window.removeEventListener('beforeunload', unload_event_listener)
    } else {
      window.addEventListener('beforeunload', unload_event_listener)
    }
  }, [is_every_change_saved])

  const buttons = [
    {
      tooltip: tooltip_discard,
      type: discard_button_type,
      onClick: start_discarding,
      show_loader: discarding,
      label: 'discard changes',
    },
    {
      tooltip: commit_tooltip,
      type: commit_button_type,
      onClick: start_saving,
      show_loader: saving,
      label: 'save changes',
    },
  ]

  return (
    <Box ml={2}>
      <ButtonsWrap>
        {buttons.map(({tooltip, type, onClick, show_loader, label}) => (
          <Tooltip title={tooltip} key={label}>
            <ButtonWrap>
              <CustomButton type={type} onClick={onClick} disabled={buttons_disabled} fullWidth>
                {show_loader ? <CircularProgress color="inherit" size={24} /> : label}
              </CustomButton>
            </ButtonWrap>
          </Tooltip>
        ))}
      </ButtonsWrap>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={popup_message.message !== ''}
        autoHideDuration={6000}
        onClose={close_popup}
      >
        <Alert severity={popup_message.severity} onClose={close_popup}>
          {popup_message.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Committer
