import {
  EntityDefaults,
  EntityEditableSchema,
  EntityUneditableSchema,
} from 'common/entities/entity_utils'
import {ColumnSpec, DataTable, TableSubtype} from 'common/types/storage'
import {TableCols} from 'common/objects/data_table'
import {get_indexes} from 'common/utils'

const last_edited_at_column: ColumnSpec<'date_time', 'entity'> = {
  type: {
    type: 'date_time',
    date_format_id: 'eeeeee dd-LLL-yyyy',
    time_format_id: 'HH:mm',
  },
  name: 'last edited at',
  slug: 'last_edited_at',
  is_default: true,
  width: 170,
}

const last_edited_by_column: ColumnSpec<'string', 'entity'> = {
  type: {type: 'string'},
  name: 'last edited by',
  slug: 'last_edited_by',
  is_default: true,
  width: 170,
}

const default_cols_ids = {
  last_edited_at: 'EZPrfOO3l1p',
  last_edited_by: 'R4VwtqVwFAD',
}

const default_cols = {
  [default_cols_ids.last_edited_at]: last_edited_at_column,
  [default_cols_ids.last_edited_by]: last_edited_by_column,
}

const default_cells = {
  slots: {[default_cols_ids.last_edited_at]: 0, [default_cols_ids.last_edited_by]: 1},
  data: {},
}

const default_cols_cols_order = [default_cols_ids.last_edited_at, default_cols_ids.last_edited_by]

const DATA_TABLE_SCHEMA_DEFAULTS: EntityDefaults<'data_table'> = {
  type: 'data_table',
  name: 'empty_data_table',
  cols: {},
  cols_order: [],
  frozen_cols: 1,
  order_by: [],
  cells: {slots: {}, data: {}},
}

const has_default_columns = (subtype: TableSubtype | undefined): boolean =>
  !subtype ||
  [
    'permission_users',
    'permission_roles',
    'permission_memberships',
    'permission_permissions',
  ].includes(subtype)

const create_partial_schema_from_cols = (
  cols: TableCols
): Pick<DataTable<'entity'>, 'cols' | 'cols_order' | 'cells'> => {
  const cols_order = Object.keys(cols)
  const data_cols = cols_order.filter((col_id) => !cols[col_id].computed)
  const slots = get_indexes(data_cols)
  return {cols, cols_order, cells: {slots, data: {}}}
}

const create_data_table_entity = ({
  zone_id,
  parent_id,
  entity_id,
  subtype,
  archived,
  ...schema
}: Omit<EntityUneditableSchema<'data_table'>, 'type'> &
  Partial<EntityEditableSchema<'data_table'>>): DataTable<'entity'> => {
  const defaults = !has_default_columns(subtype)
    ? DATA_TABLE_SCHEMA_DEFAULTS
    : {
        ...DATA_TABLE_SCHEMA_DEFAULTS,
        cols: default_cols,
        cols_order: default_cols_cols_order,
        cells: default_cells,
      }

  // if schema contains any cols/cols_order,
  // the default columns are replaced by the ones in the schema
  // for creating of testing tables, default columns should be included in cols already
  return {
    ...defaults,
    zone_id,
    parent_id,
    entity_id,
    archived,
    ...(subtype ? {subtype} : {}),
    ...schema,
  }
}

export {
  DATA_TABLE_SCHEMA_DEFAULTS,
  default_cols,
  default_cols_cols_order,
  default_cols_ids,
  create_partial_schema_from_cols,
  create_data_table_entity,
  has_default_columns,
}
