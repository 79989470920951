import {
  convert_date_to_iso_string,
  parse_date,
  parse_date_time,
  normalize_name,
  parse_iso_date,
  parse_number,
  throw_error,
  parse_boolean,
} from './utils'
import {create_error, is_error, MaybeError} from './error'
import {compose_reference_id, parse_reference_id, ReferenceId} from './params_utils'
import {
  CellRawValue,
  CellType,
  EntityColumnType,
  OptionId,
  Options,
  SingleCellRawValue,
  TableColumnReferences,
} from './types/storage'
import {SingleCellValue, SingleOptionValue, TableRowObject} from './types/data_table'
import {Runtime} from './create_runtime'
import {is_web_address} from 'common/regex_utils'
import {TablesReferenceLabelsMap} from './entities/table_actions'
import {get_number_format} from './formatting/number'
import {BOOLEAN_VALUE_TO_FORMAT_MAP} from 'common/formatting/boolean'
import _ from 'lodash'
import {ColumnIdOrSlug} from 'server/api_v2/utils'

const COL_TYPES: CellType[] = [
  'reference',
  'date',
  'date_time',
  'option',
  'number',
  'string',
  'markdown',
  'attachment',
  'boolean',
]

function _conform_to_reference(
  runtime: Runtime,
  value: string,
  type: EntityColumnType<'reference'>
): MaybeError<TableRowObject> {
  const parsed_reference_id = parse_reference_id(value)
  if (is_error(parsed_reference_id)) {
    return parsed_reference_id
  }

  const {entity_id, row_id} = parsed_reference_id

  if (
    !Object.values(type.tables)
      .map((reference) => reference.table_id)
      .includes(entity_id)
  ) {
    return create_error('value', {
      value,
      message: `Tables in col spec doesn't contain table with given id:
      table_entity_id: ${entity_id}, tables: ${type.tables}`,
    })
  }

  const table = runtime.get_table_or_error(entity_id)

  if (is_error(table)) {
    return create_error('ref', {
      value,
      subtype: 'bad-table',
      original_error: table,
      message: `Bad table ${entity_id}`,
    })
  }

  // Check if referenced column exists in given table
  const referenced_col_id = Object.values(type.tables).find((it) => it.table_id === entity_id)
    ?.column_id
  if (referenced_col_id && !table._has_col(referenced_col_id)) {
    return create_error('ref', {
      value,
      subtype: 'unknown-col',
      message: `Unknown column "${referenced_col_id}" in table "${table.name}"`,
    })
  }

  if (!table._has_row(row_id)) {
    return create_error('ref', {
      value,
      subtype: 'unknown-row',
      message: `Unknown row "${row_id}" in table "${table.name}"`,
    })
  }

  return table._row(row_id)
}

/* ignores type.multi assuming that the given value is of a single type */
function conform_single_value_to_type(
  runtime: Runtime,
  value: MaybeError<SingleCellRawValue>,
  type: EntityColumnType<CellType>,
  column?: ColumnIdOrSlug
): MaybeError<SingleCellValue<CellType>> {
  if (is_error(value)) {
    return value
  }

  if (value === null || value === '') {
    return type.required
      ? create_error('value', {
          value,
          message: 'Value is missing',
          subtype: 'required',
          column,
        })
      : null
  }

  switch (type.type) {
    case 'string':
    case 'markdown':
      return value.toString()
    case 'number': {
      const res = Number(value)
      return Number.isNaN(res)
        ? create_error('value', {value, message: 'Number format not recognized'})
        : res
    }
    case 'date':
      if (typeof value === 'string') {
        return (
          parse_iso_date(value) ||
          create_error('value', {value, message: 'Date format not recognized'})
        )
      } else {
        return create_error('value', {value, message: 'Date format not recognized'})
      }
    case 'date_time': {
      const as_number = Number(value)
      if (typeof value === 'boolean') {
        return create_error('value', {
          value,
          message: 'Invalid boolean value used as DateTime format',
        })
      }
      if (Number.isNaN(as_number)) {
        return create_error('value', {value, message: 'DateTime format not recognized'})
      } else if (as_number > 253402261199000) {
        return create_error('value', {value, message: 'DateTime can not be after 31.12.9999'})
      } else if (as_number < -2208988800000) {
        return create_error('value', {value, message: 'DateTime can not be before 1.1.1900'})
      }
      return new Date(value)
    }
    case 'option': {
      const {options} = type
      // dirty hack to gain some performance - memoize some data within option object
      if (typeof value === 'string' && options[value] != null) {
        const option = options[value] as SingleOptionValue
        if (option.value == null) {
          option.value = normalize_name(option.name)
          option.id = value
        }
        return option
      } else {
        return create_error('value', {value, message: `Value should be of type ${type.type}`})
      }
    }
    case 'reference':
      if (typeof value === 'string') {
        return _conform_to_reference(runtime, value, type)
      } else {
        return create_error('value', {value, message: `Value should be of type ${type.type}`})
      }
    case 'attachment':
      //attachment is represented as a string containing exactly one URL addressing the file
      return typeof value === 'string' && is_web_address(value)
        ? value
        : create_error('value', {value, message: 'Value should be a link to a file'})
    case 'boolean': {
      if (value === null || _.isEmpty(BOOLEAN_VALUE_TO_FORMAT_MAP[String(value).valueOf()])) {
        return create_error('value', {
          value,
          message: 'Value is not valid boolean format',
        })
      }
      return value
    }
    default:
      return throw_error('Unknown type', 'type', type)
  }
}

//try to match value to an item in options: first as id, then name; return value if not found
const _match_option = (value: string, options: Options): string | OptionId => {
  return options[value]
    ? value
    : Object.keys(options).find((key) => options[key].name === value) || value
}

//try to match value to an row in referenced tables as label of one of the referenced columns;
// return value if not found
const _match_reference = (
  value: SingleCellRawValue,
  tables: TableColumnReferences,
  tables_labels: TablesReferenceLabelsMap | undefined
): SingleCellRawValue | ReferenceId => {
  if (typeof value === 'string' && !is_error(parse_reference_id(value))) return value
  if (tables_labels) {
    let match: string | null = null
    for (const table_column_reference of Object.values(tables)) {
      const table_id = table_column_reference.table_id
      const column_id = table_column_reference.column_id ?? 'default'
      for (const row_id of Object.keys(tables_labels[table_id][column_id])) {
        if (tables_labels[table_id][column_id][row_id] === value) {
          if (match) {
            throw create_error('ref', {
              value,
              subtype: 'non-unique-reference',
              message: 'Referenced value is not unique.',
            })
          }
          match = compose_reference_id({
            entity_id: table_id,
            row_id,
          })
        }
      }
    }
    if (match) {
      return match
    }
  }
  return value
}

const isNumeric = (str) => {
  if (typeof str !== 'string') return false
  return !Number.isNaN(Number(str)) && !Number.isNaN(parseFloat(str))
}

const _split_string_to_array = (value: string): (string | number | boolean)[] => {
  return value
    .replace(/; /g, ';')
    .split(';')
    .filter((v) => v.trim() !== '')
    .map((v) => {
      return ['true', 'false'].includes(v) ? v === 'true' : isNumeric(v) ? Number(v) : v
    })
}

const force_type = (
  col_type: EntityColumnType<CellType>,
  value: CellRawValue,
  reference_labels?: TablesReferenceLabelsMap
): CellRawValue => {
  if (!COL_TYPES.includes(col_type.type)) {
    return throw_error('Unknown column type', 'type', col_type.type)
  }

  if (value === null || value === '') return null

  const single_value = Array.isArray(value) ? value[0] : value
  const array_value: SingleCellRawValue[] = Array.isArray(value)
    ? value
    : col_type.multi
    ? _split_string_to_array(value.toString())
    : [value]

  if (col_type.multi && array_value.length === 0) return null
  switch (col_type.type) {
    case 'option': {
      const option_values = [
        ...new Set(
          array_value.map((val) => {
            return typeof val === 'string'
              ? _match_option(val.replace(/;/g, ','), col_type.options)
              : val
          })
        ),
      ]
      return col_type.multi ? option_values : option_values[0]
    }
    case 'reference': {
      const reference_values = [
        ...new Set(
          array_value.map((val) => _match_reference(val, col_type.tables, reference_labels))
        ),
      ]
      return col_type.multi ? reference_values : reference_values[0]
    }
    case 'number':
      if (single_value === null || single_value === '') return null
      return parse_number(single_value, get_number_format(col_type.number_format_id || ''))
    case 'date': {
      if (single_value === null || single_value === '') return null
      const parsed_date = parse_date(single_value.toString(), col_type.date_format_id)
      if (parsed_date instanceof Date) return convert_date_to_iso_string(parsed_date)
      return parsed_date
    }
    case 'date_time': {
      if (single_value === null || single_value === '') return null
      const parsed_date_time = parse_date_time(
        single_value.toString(),
        col_type.date_format_id,
        col_type.time_format_id
      )
      return parsed_date_time instanceof Date ? parsed_date_time.getTime() : parsed_date_time
    }
    case 'boolean': {
      if (single_value === null || single_value === '') return null
      return parse_boolean(single_value.toString())
    }
    default: {
      if (single_value === null || single_value === '') return null
      return single_value.toString()
    }
  }
}

/* ignores type.multi assuming that the given value is of a single type */
function convert_single_value_to_raw_value<T extends CellType>(
  value: SingleCellValue<T>,
  type: EntityColumnType<T>
): SingleCellRawValue {
  if (value == null) {
    return null
  }
  switch (type.type) {
    case 'string':
    case 'markdown':
    case 'attachment':
      return value as string
    case 'boolean':
      return value as boolean
    case 'number':
      return value as number
    case 'date_time':
      return (value as Date).getMilliseconds()
    case 'date':
      return convert_date_to_iso_string(value as Date)
    case 'option':
      return (value as SingleOptionValue).id
    case 'reference':
      return (value as TableRowObject).get_reference_id()
    default:
      return throw_error('Unknown type', 'type', type)
  }
}

export {conform_single_value_to_type, force_type, convert_single_value_to_raw_value, COL_TYPES}
