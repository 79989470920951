import _ from 'lodash'
import {throw_error, get_indexes, has_same_elements} from '../../utils'
import record_diff from '../base/record_diff'
import value_diff from '../base/value_diff'
import conflict_free_value_diff from 'common/diffs/base/conflict_free_value_diff'
import ordered_set_diff from '../base/ordered_set_diff'
import map_diff from '../base/map_diff'
import constant_diff from '../base/constant_diff'
import col_diff from './col_diff'
import row_diff from './row_diff'
import {create_squash} from '../base/create_squash'
import {
  type_decorate_apply,
  type_decorate_reverse,
  type_decorate_ensure_consistency,
  type_decorate_squash2,
  type_decorate_change_diff,
  type_decorate_clean_neutral_diffs,
} from './entity_type_helpers'
import {DataTable, ColumnId, Cells, CellsMap, RowId, CellRawValue} from 'common/types/storage'
import {Conflict} from 'common/types/data_table'

type DataTableWithCellsMap = {
  [P in keyof DataTable<'entity'>]: P extends 'cells' ? CellsMap<'entity'> : DataTable<'entity'>[P]
}

const {
  is_conflict: val_is_conflict,
  get_value: val_get_value,
  ensure_consistency: val_ensure_consistency,
} = value_diff()

const recipe = {
  type: constant_diff(),
  subtype: constant_diff(),
  zone_id: constant_diff(),
  name: conflict_free_value_diff(),
  description: conflict_free_value_diff(),
  archived: conflict_free_value_diff(),
  entity_id: constant_diff(),
  parent_id: constant_diff(),
  label: value_diff(),
  cols: map_diff(col_diff, false, false),
  // in what order columns should be enumerated
  cols_order: ordered_set_diff(false),
  // number of frozen columns from the left
  frozen_cols: conflict_free_value_diff(),
  // the columns to sort by
  order_by: value_diff(),
  // note that cells in data and diff are represented differently.
  // see apply_data_table
  cells: map_diff(row_diff, false, false),
}

const {
  conflict_free,
  is_conflict: is_conflict_part,
  get_conflicts: get_conflicts_part,
  apply: apply_part,
  ensure_consistency: _ensure_consistency,
  reverse: _reverse,
  squash2: _squash2,
  change_diff: _change_diff,
  clean_neutral_diffs: _clean_neutral_diffs,
} = record_diff(recipe)

const reverse = type_decorate_reverse('data_table', _reverse)
const squash2 = type_decorate_squash2('data_table', _squash2)
const change_diff = type_decorate_change_diff('data_table', _change_diff)
const clean_neutral_diffs = type_decorate_clean_neutral_diffs('data_table', _clean_neutral_diffs)

// Choose some (arbitrary) order in which we will compactly store table rows as arrays
function choose_slot_order(cols: {[id: string]: any} = {}) {
  return Object.entries(cols)
    .filter(([id, col_spec]) => !col_spec.computed)
    .map(([id, col_spec]) => id)
}

const is_valid_col_spec = (c_col_spec) => {
  const col_spec = col_diff.conflict_free(c_col_spec)
  if (!_.has(col_spec, 'name')) return false
  if (!_.has(col_spec, ['type', 'type'])) return false
  const spec = col_spec.type

  // this is just a simple test
  switch (spec.type) {
    case 'string':
    case 'number':
    case 'date':
    case 'date_time':
    case 'markdown':
    case 'reference':
    case 'option':
    case 'attachment':
    case 'boolean':
      return true
    default:
      return false
  }
}

const is_valid_col_summary = (c_summary): boolean => {
  const {summary} = col_diff.conflict_free({summary: c_summary})
  return summary == null || summary.fn != null
}

function convert_cells_to_map(cells?: Cells<'entity'>): CellsMap<'entity'> {
  if (cells == null) {
    return {}
  }
  const cells_map: CellsMap<'entity'> = {}
  const slots_order = Object.keys(cells.slots)
  for (const row_id of _.keys(cells.data)) {
    const row = cells.data[row_id]
    if (row.length !== slots_order.length) {
      throw_error(
        'Data table diff convert_cells_to_map problem: Row and row slots are not of the same length.',
        'row',
        row,
        'slots',
        slots_order
      )
    }
    cells_map[row_id] = _.assign(
      {keep: true},
      _.pickBy(_.fromPairs(_.zip(slots_order, row)), (cell) => cell != null)
    )
  }
  return cells_map
}

function _apply_cells(
  cells: Cells<'entity'>,
  cells_diff: Cells<'diff'> | undefined,
  new_slots: Record<ColumnId, number>
): {cells: Cells<'entity'>; invalids: Conflict[]} {
  const old_cells_map = convert_cells_to_map(cells)
  const new_cells_map = cells_diff
    ? (recipe.cells.apply(old_cells_map, cells_diff) as CellsMap<'entity'>)
    : old_cells_map

  const invalids: Conflict[] = []
  const new_cells_data: Cells<'entity'>['data'] = {}
  for (const [row_id, row] of _.entries(new_cells_map)) {
    if (val_get_value(row.keep) && !val_is_conflict(row.keep)) {
      // new_cells_map doesn't contain conflict-free empty cells
      new_cells_data[row_id] = new Array(_.keys(new_slots).length).fill(null)
      for (const [col_id, cell] of _.entries(_.omit(row, 'keep'))) {
        const col_index = _.get(new_slots, col_id, null)
        if (col_index != null) {
          new_cells_data[row_id][col_index] = cell
        } else {
          invalids.push({
            type: 'invalid',
            subtype: 'cell-deleted',
            data: {
              cells: {
                [row_id]: {
                  [col_id]: cell,
                },
              },
            },
          })
        }
      }
    } else {
      // If the row was deleted properly, it wouldn't appear in new_cells_map.
      // This means it has conflict on keep or non-null cells
      invalids.push({
        type: 'invalid',
        subtype: 'row-deleted',
        data: {
          cells: {
            [row_id]: row,
          },
        },
      })
    }
  }

  return {
    invalids,
    cells: {
      slots: new_slots,
      data: new_cells_data,
    },
  }
}

function merge_updated_and_new_cell_data(
  old_data: Record<RowId, CellRawValue[]>,
  new_data: Record<RowId, CellRawValue[]>,
  cells_diff: Cells<'diff'>
): Record<RowId, CellRawValue[]> {
  const updated_rows = Object.keys(old_data).reduce((rows, row_id) => {
    //add changed rows from the new_data, if they are not removed
    if (cells_diff[row_id] != null) {
      if (new_data[row_id] != null) {
        rows[row_id] = new_data[row_id]
      }
      //add unchanged rows from the old_data, if they are not removed
    } else {
      rows[row_id] = old_data[row_id]
    }
    return rows
  }, {})

  // New rows (not present in old_data) are added at the bottom
  const new_rows = Object.fromEntries(Object.entries(new_data).filter((row) => !old_data[row[0]]))

  return Object.assign(updated_rows, new_rows)
}

function apply_cells(
  old_cells: Cells<'entity'>,
  cells_diff: Cells<'diff'> | undefined,
  new_data_cols_ids: ColumnId[]
): {cells: Cells<'entity'>; invalids: Conflict[]} {
  // Data columns have been added/removed:
  //  1. Calculate new slots corresponding to the new data column ids
  //  2. Rebuild the whole cells object using these new slots
  // This process is not necessary, if only the order of columns has changed.
  // Therefore, we only check for has_same_elements and not _.isEqual.
  if (!has_same_elements(new_data_cols_ids, _.keys(old_cells.slots))) {
    const new_slots = get_indexes(new_data_cols_ids)
    return _apply_cells(old_cells, cells_diff, new_slots)
  }

  // Diff contains non-empty cells:
  // This means that either cells were edited, or rows have been added/removed.
  // In this case, we only need to convert the cell data of the affected rows.
  if (cells_diff != null && !_.isEmpty(cells_diff)) {
    const affected_rows = _.keys(cells_diff)
    const affected_cells = {
      slots: old_cells.slots,
      data: _.pick(old_cells.data, affected_rows),
    }

    const {cells: new_cells, invalids} = _apply_cells(affected_cells, cells_diff, old_cells.slots)

    return {
      invalids,
      cells: {
        slots: new_cells.slots,
        data: merge_updated_and_new_cell_data(old_cells.data, new_cells.data, cells_diff),
      },
    }
  }

  return {
    cells: old_cells,
    invalids: [] as Conflict[],
  }
}

function ensure_consistency_cells(cells: Cells<'entity'>, cells_diff: Cells<'diff'>): void {
  Object.entries(cells_diff).forEach(([row_id, row_diff]) => {
    Object.entries(row_diff).forEach(([col_id, diff]) => {
      const col_index = cells.slots[col_id]
      const value =
        col_id === 'keep'
          ? _.has(cells.data, row_id)
            ? true
            : null
          : _.get(cells.data, [row_id, col_index], null)

      val_ensure_consistency(value, diff)
    })
  })
}

function ensure_consistency(
  data: Partial<DataTable<'entity'>> & {type: 'data_table'},
  diff: DataTable<'diff'>
): void {
  type_decorate_ensure_consistency('data_table', _ensure_consistency)(
    _.omit(data, 'cells'),
    _.omit(diff, 'cells')
  )

  ensure_consistency_cells(data.cells || {slots: {}, data: {}}, diff.cells || {})
}

// Note: this function is unusual as data and diff follow slightly different format
// to shave off table memory
// Namely:
// - cells in diff are `{[row_id]: {[col_id]: <cell diff>}}`
// - cells in data are `{slots: {[col_id]: position}, rows: {[row_id]: Array<cell>}}`
//   where `slots` stores column order in which row data is encoded. Slots maintain invariant
//   that i-th key in the (sorted) map is mapped to value i. The data is stored like this for speed
//   optimisation reasons.
function apply_data_table(
  data: Partial<DataTable<'entity'>> & {type: 'data_table'},
  diff: DataTable<'diff'>
): DataTable<'entity'> {
  const {
    subtype: new_subtype,
    name: new_name,
    description: new_description,
    archived: new_archived,
    zone_id: new_zone_id,
    entity_id: new_entity_id,
    parent_id: new_parent_id,
    label: new_label,
    cols: _new_cols,
    cols_order: _new_cols_order,
    frozen_cols: new_frozen_cols,
    order_by: new_order_by,
  }: DataTableWithCellsMap = type_decorate_apply('data_table', apply_part)(
    _.omit(data, 'cells'),
    _.omit(diff, 'cells')
  )

  const invalids: Conflict[] = []
  const valid_cols: ColumnId[] = []
  for (const [col_id, col] of Object.entries(_new_cols)) {
    if (!_new_cols_order.includes(col_id)) {
      // column missing from cols orders - column is invalid
      invalids.push({
        type: 'invalid',
        subtype: 'column-deleted',
        data: {
          cols: {
            [col_id]: col,
          },
        },
      })
    } else if (!is_valid_col_spec(col)) {
      // can't render column if name or type is missing
      invalids.push({
        type: 'invalid',
        subtype: 'column-invalid-spec',
        data: {
          cols: {
            [col_id]: col,
          },
        },
      })
    } else {
      valid_cols.push(col_id)
    }
  }
  const new_cols = _.mapValues(_.pick(_new_cols, valid_cols), (col_spec, col_id) => {
    if (col_spec.summary != null && !is_valid_col_summary(col_spec.summary)) {
      invalids.push({
        type: 'invalid',
        subtype: 'column-invalid-summary',
        data: {
          cols: {
            [col_id]: {summary: col_spec.summary},
          },
        },
      })
      return _.omit(col_spec, 'summary')
    }

    return col_spec
  })

  // Removing invalid columns from cols_order.
  if (_new_cols_order.some((col_id) => !new_cols[col_id])) {
    invalids.push({
      type: 'invalid',
      subtype: 'cols_order-extra-cols',
      data: {cols_order: _new_cols_order},
    })
  }

  const new_cols_order = _new_cols_order.filter((col_id) => _.has(new_cols, [col_id]))
  const new_slot_order = choose_slot_order(new_cols)

  const {cells: new_cells, invalids: cells_invalids} = apply_cells(
    data.cells || {slots: {}, data: {}},
    diff.cells,
    new_slot_order
  )

  invalids.push(...cells_invalids)
  return {
    type: 'data_table',
    ...(new_subtype ? {subtype: new_subtype} : {}),
    zone_id: new_zone_id,
    entity_id: new_entity_id,
    parent_id: new_parent_id,
    name: new_name,
    description: new_description,
    archived: new_archived,
    label: new_label,
    cols: new_cols,
    cols_order: new_cols_order,
    frozen_cols: new_frozen_cols,
    order_by: new_order_by,
    cells: new_cells,
    // Data on which should be diff applied cannot contain invalids.
    ...(invalids.length > 0 ? {invalids} : {}),
  }
}

function unapply(data, diff, ensure: boolean = true) {
  const reversed_diff = reverse(diff)
  if (ensure) ensure_consistency(data, reversed_diff)
  const result = apply_data_table(data, reversed_diff)

  if (ensure)
    if (typeof result.invalids !== 'undefined' && result.invalids.length > 0)
      throw_error(
        'Data table diff unapply problem: unapply causes invalids',
        'data',
        data,
        'diff',
        diff
      )

  return result
}

function get_conflicts_data_table(data) {
  const {cells, ...res} = data
  const cells_map = convert_cells_to_map(cells)
  return get_conflicts_part({...res, cells: cells_map})
}

function is_conflict_data_table(data) {
  const {cells, ...rest} = data
  // we do not need to convert cells if scheme is conflicted
  return is_conflict_part(rest) || is_conflict_part({cells: convert_cells_to_map(cells)})
}

function change_diff_data_table(old_data, new_data) {
  const {cells: old_cells} = old_data
  const {cells: new_cells} = new_data

  const old_cells_map = convert_cells_to_map(old_cells)
  const new_cells_map = convert_cells_to_map(new_cells)

  return change_diff(
    _.assign({cells: old_cells_map}, _.omit(old_data, 'cells')),
    _.assign({cells: new_cells_map}, _.omit(new_data, 'cells'))
  )
}

export default {
  convert_cells_to_map,
  // conflict_free of data, where cells is map of row_diff structure
  conflict_free,
  is_conflict: is_conflict_data_table,
  get_conflicts: get_conflicts_data_table,
  apply: apply_data_table,
  reverse,
  ensure_consistency,
  unapply,
  squash: create_squash(squash2),
  // change_diff from old_data to new_data, where cells is map of row_diff structure
  _change_diff: change_diff,
  change_diff: change_diff_data_table,
  create_diff: (value) => change_diff_data_table({type: 'data_table'}, value),
  clean_neutral_diffs,
  recipe,
}
