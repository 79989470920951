import React, {FC, MouseEventHandler, useCallback, useState} from 'react'
import FrozenColsDivider from './FrozenColsDivider'
import FrozenColsMarker from './FrozenColsMarker'
import {useRuntimeActions} from './RuntimeContextProvider'
import {get_table_size} from './table_data_helpers'
import {AlwaysDefinedRuntime, useRuntimeSelector} from './utils/connect_hocs'
import {CellPositionGetter, ColRectangleGetter} from './utils/layout'

type FrozenColsToolProps = {
  offset_left: number
  on_context_menu: MouseEventHandler
  get_cell_position: CellPositionGetter
  get_col_rectangle: ColRectangleGetter
}

export const FrozenColsTool: FC<FrozenColsToolProps> = ({
  offset_left,
  on_context_menu,
  get_cell_position,
  get_col_rectangle,
}) => {
  const {table} = (useRuntimeSelector() as AlwaysDefinedRuntime).resources.table_resources
  const {dispatch_storage} = useRuntimeActions()
  const [target_cols, set_target_cols] = useState<number | null>(null)
  const [marker_left, set_marker_left] = useState<number | null>(null)

  const original_divider = get_col_rectangle(table._frozen_cols)

  const start_setting_frozen_cols = useCallback(() => {
    set_target_cols(table._frozen_cols)
    set_marker_left(original_divider.right)
  }, [original_divider.right, table._frozen_cols])

  // returns position of the last column before coordinate X
  const _get_col_pos_before_position = (x: number): number => {
    const {columns_widths} = get_table_size(table)

    // find column for which coordinate x is between its right and left boundaries
    let col_pos = 0
    for (const _col_pos of columns_widths.keys()) {
      const [left, right] = get_cell_position([_col_pos, 0])[0]

      const col_is_not_visible = right - left === 0
      if (!col_is_not_visible && x > left) col_pos = _col_pos
    }

    // return last column before the found one
    return Math.max(0, col_pos - 1)
  }

  const set_target_frozen_cols = (e: MouseEvent) => {
    const x = e.clientX - offset_left
    const new_target_frozen_cols = _get_col_pos_before_position(x)
    if (new_target_frozen_cols !== target_cols) set_target_cols(new_target_frozen_cols)
    set_marker_left(x)
  }

  const end_setting_frozen_cols = useCallback(() => {
    if (target_cols) {
      dispatch_storage(table._actions.change_frozen_columns(target_cols))
      set_target_cols(null)
      set_marker_left(null)
    }
  }, [dispatch_storage, table._actions, target_cols])

  return (
    <>
      <FrozenColsDivider
        left={original_divider.right}
        height={original_divider.height}
        on_context_menu={on_context_menu}
        start_dragging={start_setting_frozen_cols}
        on_drag={set_target_frozen_cols}
        end_dragging={end_setting_frozen_cols}
      />
      {marker_left && (
        <FrozenColsMarker
          left={marker_left}
          height={original_divider.height}
          on_context_menu={on_context_menu}
          type="marker"
        />
      )}
      {target_cols && (
        <FrozenColsMarker
          left={get_col_rectangle(target_cols).right}
          height={original_divider.height}
          on_context_menu={on_context_menu}
          type="target"
        />
      )}
    </>
  )
}
