import {TableData} from './data_table'

const ERROR_EMPTY_TABLE_DATA: TableData = {
  name: 'empty_table',
  type: 'data_table',
  cols: {},
  cols_order: [],
  frozen_cols: 1,
  order_by: [],
  cells: {slots: {}, data: {}},
  is_error_table: true,
  is_cells_editable: false,
  is_schema_editable: false,
  table_id: '',
  zone_id: '',
  parent_id: '',
  entity_id: '',
  original_zone_id: '',
  original_entity_id: '',
}

export {ERROR_EMPTY_TABLE_DATA}
