import React, {FC, useCallback, useMemo} from 'react'
import {focus_table_content} from '../utils/focus'
import {
  useSetTableUiState,
  useTableUiSelector,
  useRuntimeSelector,
  AlwaysDefinedRuntime,
} from '../utils/connect_hocs'
import {reset_ui_state} from '../Table'
import assert from 'assert'
import {useRuntimeActions} from '../RuntimeContextProvider'
import {get_column_selection} from '../table_data_helpers'
import Delete from '@material-ui/icons/Delete'
import ContextMenuItem from './ContextMenuItem'

type RemoveSelectedColumnsItemProps = {
  on_close: () => void
}

const RemoveSelectedColumnsItem: FC<RemoveSelectedColumnsItemProps> = ({on_close}) => {
  const {
    resources: {
      table_resources: {full_table: table, table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const selection_size = useTableUiSelector(table_entity_id, 'selection_size')

  const {dispatch_storage} = useRuntimeActions()
  const set_table_ui_state = useSetTableUiState(table_entity_id)

  const col_selection = useMemo(() => get_column_selection(table, cursor, selection_size), [
    table,
    cursor,
    selection_size,
  ])

  const on_click = useCallback(() => {
    assert(!!col_selection.length)
    dispatch_storage(table._actions.remove_columns(col_selection))
    set_table_ui_state(reset_ui_state)

    // regain focus of the main table
    focus_table_content()
    on_close()
  }, [dispatch_storage, table, set_table_ui_state, col_selection, on_close])

  return (
    <ContextMenuItem
      onClick={on_click}
      icon={<Delete fontSize="small" />}
      item={`Delete column${col_selection.length > 1 ? 's' : ''}`}
    />
  )
}

export default RemoveSelectedColumnsItem
