import _ from 'lodash'
import {create_squash} from './create_squash'
import {is_null_or_empty} from '../../utils'
import val_diff from './value_diff'

const value_diff = val_diff()

function map_diff(inner_diff: any, handle_keep = false, delete_empty = true) {
  function should_delete(key, val) {
    return handle_keep && key === 'keep'
      ? value_diff.is_empty(val)
      : inner_diff.delete_empty && inner_diff.is_empty(val)
  }

  function get_diff(key) {
    return handle_keep && key === 'keep' ? value_diff : inner_diff
  }

  function apply(base, diff) {
    if (base == null) base = {}

    return Object.fromEntries(
      _.union(Object.keys(base), Object.keys(diff))
        .map((key) => {
          const old_val = _.get(base, key, null)
          if (_.has(diff, key)) {
            const new_val = get_diff(key).apply(old_val, diff[key])
            return [key, new_val]
          } else {
            return [key, old_val]
          }
        })
        .filter(([key, val]) => !should_delete(key, val))
    )
  }

  function reverse(diff) {
    return Object.fromEntries(
      Object.keys(diff).map((key) => {
        return [key, get_diff(key).reverse(diff[key])]
      })
    )
  }

  function ensure_consistency(base, diff) {
    if (base == null) base = {}
    Object.entries(diff).map(([key, value]) => {
      get_diff(key).ensure_consistency(_.get(base, key, null), value)
    })
  }

  function unapply(base, diff, ensure: boolean = true) {
    const reversed_diff = reverse(diff)
    if (ensure) ensure_consistency(base, reversed_diff)
    return apply(base, reversed_diff)
  }

  function squash2(diff1, diff2) {
    return Object.fromEntries(
      _.union(_.keys(diff1), _.keys(diff2)).map((key) => {
        if (!_.has(diff1, key)) return [key, diff2[key]]
        if (!_.has(diff2, key)) return [key, diff1[key]]

        const new_diff = get_diff(key).squash(diff1[key], diff2[key])
        return [key, new_diff]
      })
    )
  }

  function conflict_free(base) {
    const new_base = _.mapValues(base, (val, key) => get_diff(key).conflict_free(val))
    return _.omitBy(new_base, (val, key) => should_delete(key, val))
  }

  function is_conflict(base) {
    return Object.entries(base).some(([key, val]) => get_diff(key).is_conflict(val))
  }

  function get_conflicts(base) {
    const conflicts = _.mapValues(base, (val, key) => get_diff(key).get_conflicts(val))
    return _.omitBy(conflicts, _.isEmpty)
  }

  // Used to memoize the results of is_empty function. It prevents from repeatedly
  // (recursively) checking if inner diffs are empty if the value hasn't changed.
  const _is_empty = new WeakMap()

  function is_empty(base) {
    if (!_is_empty.has(base))
      _is_empty.set(
        base,
        Object.entries(base).every(([key, val]) => get_diff(key).is_empty(val))
      )
    return _is_empty.get(base)
  }

  function change_diff(old_base, new_base) {
    if (old_base == null) old_base = {}
    if (new_base == null) new_base = {}

    return Object.fromEntries(
      _.union(Object.keys(old_base), Object.keys(new_base)).map((key) => {
        const old_val = _.get(old_base, key, null)
        const new_val = _.get(new_base, key, null)
        const diff_key = get_diff(key).change_diff(old_val, new_val)
        return [key, diff_key]
      })
    )
  }

  function clean_neutral_diffs(diff) {
    const clean_diff = _.mapValues(diff, (diff_key, key) =>
      get_diff(key).clean_neutral_diffs(diff_key)
    )
    return _.omitBy(clean_diff, is_null_or_empty)
  }

  return {
    conflict_free,
    is_conflict,
    get_conflicts,
    is_empty,
    apply,
    reverse,
    ensure_consistency,
    unapply,
    squash2,
    squash: create_squash(squash2),
    change_diff,
    create_diff: (base) => change_diff(null, base),
    remove_diff: (base) => change_diff(base, null),
    clean_neutral_diffs,
    delete_empty,
    default_value: {},
  }
}

export default map_diff
