import React, {FC, ReactNode} from 'react'
import {Card, CardContent, Theme, Typography, Button, Box} from '@material-ui/core'
import {createStyles, makeStyles, styled} from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

type HistoryCardProps = {
  title: string
  content: ReactNode
  active: boolean
  onClick: () => void
}

const Header = styled(Typography)(({theme}) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1.5),
}))

const ActiveText = styled(Typography)(({theme}) => ({
  marginLeft: theme.spacing(1),
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: (props: {active}) => ({
      border: props.active ? `2px solid ${theme.palette.greenPalette[400]}` : undefined,
      borderRadius: 8,
      boxShadow: props.active && 'none',
      marginBottom: theme.spacing(2),
    }),
    cardContent: (props: {active}) => ({
      'borderTop': `1px solid ${theme.palette.greyPalette[100]}`,
      'paddingTop': props.active ? undefined : theme.spacing(1),
      '&:last-child': {
        paddingBottom: props.active ? theme.spacing(2) : theme.spacing(1),
      },
    }),
  })
)

const HistoryCard: FC<HistoryCardProps> = ({title, content, active, onClick}) => {
  const classes = useStyles({active})
  return (
    <Card className={classes.card}>
      <CardContent>
        <Header variant="subtitle1">{title}</Header>
        {content}
      </CardContent>
      <CardContent className={classes.cardContent}>
        {active ? (
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="primary" />
            <ActiveText color="primary">Active</ActiveText>
          </Box>
        ) : (
          <Button color="primary" onClick={onClick}>
            Show
          </Button>
        )}
      </CardContent>
    </Card>
  )
}

export default HistoryCard
