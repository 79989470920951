import React, {FC, useCallback, useContext, useMemo} from 'react'
import {useRuntimeActions} from '../RuntimeContextProvider'
import Problem from './Problem'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import {UserAccountContext} from '../UserAccountProvider'
import {Button, Box, Typography, styled} from '@material-ui/core'
import _ from 'lodash'
import {parse_resource_id} from 'common/params_utils'
import {_is_invalid_data_table} from '../utils/conflict_utils'

const Note = styled(Typography)({
  fontSize: 10,
  fontWeight: 500,
})

const StyledTypography = styled(Typography)({
  fontWeight: 500,
})

const ConflictsSidebar: FC = () => {
  const {
    storage: {resources, multidiff},
    resources: {
      table_resources: {zone_id, full_table: table},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const {dispatch_storage} = useRuntimeActions()
  const current_user = useContext(UserAccountContext)
  const problems = useMemo(() => table._get_problems(), [table])

  const resolve_schema = useCallback(() => {
    dispatch_storage(table._actions.autoresolve_scheme_conflicts())
  }, [dispatch_storage, table])

  const resolve_cells = useCallback(() => {
    dispatch_storage(table._actions.autoresolve_cells_conflicts(current_user?.email))
  }, [current_user?.email, dispatch_storage, table._actions])

  const enabled_schema = useMemo(
    () => problems.some((problem) => problem.subtype !== 'cell-value'),
    [problems]
  )

  const enabled_cells = useMemo(
    () => problems.some((problem) => problem.subtype === 'cell-value'),
    [problems]
  )

  const error_tables_msg = useMemo((): string => {
    const error_ids = _.filter(_.keys(resources), (key) =>
      _is_invalid_data_table(key, resources, multidiff[zone_id])
    ).map((key) => parse_resource_id(key as string).entity_id)
    return _.isEmpty(error_ids) ? '' : `Check [${error_ids.join(', ')}]`
  }, [multidiff, resources, zone_id])

  return (
    <div id="conflicts">
      <Typography variant="body2" gutterBottom>
        {problems.length > 0 ? 'Table problems' : `No problems in this table. ${error_tables_msg}`}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={resolve_schema}
        disabled={!enabled_schema}
      >
        <Box>
          <StyledTypography variant="body2">Resolve scheme conflicts</StyledTypography>
          <Note>(and invalid cells)</Note>
        </Box>
      </Button>
      <Box mt={1} mb={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={resolve_cells}
          disabled={!enabled_cells}
        >
          Resolve cells conflicts
        </Button>
      </Box>
      {problems.map((problem, problem_index) => (
        <Problem problem={problem} key={`problem_${problem_index}`} />
      ))}
    </div>
  )
}

export default ConflictsSidebar
