import {CellRawValue, CellType, ColumnType, SingleCellRawValue} from 'common/types/storage'
import {Runtime} from 'common/create_runtime'
import {
  create_error,
  ErrorObject,
  format_error,
  is_error,
  MaybeError,
  throw_error_object,
} from 'common/error'
import {
  CellConflict,
  CellObject,
  CellValue,
  FormattedReference,
  SimpleCellObject,
  SingleCellValue,
} from 'common/types/data_table'
import {conform_single_value_to_type} from 'common/types'
import {raw_value_to_string_wrapper, single_value_to_string} from 'common/value_to_string'
import {convert_date_time_to_iso_string, convert_date_to_iso_string, ensure} from 'common/utils'

/**
 * @module TableCell
 */
/**
 * simple cell API:
 *
 * simple_cell = {
 *
 *   // Raw (not conformed) value of the cell (or subcell in case of the multicell).
 *   // Options and references are stored as string id
 *   // Can be null, string, number or an error object
 *   raw_value,
 *
 *   // Returns - conformed value (string, number, date, option object, row object)
 *   //         - throw an error, if value doesn't meet the column specification
 *   get_value,
 *
 *   // returns label of the cell (string seen by user)
 *   get_label,
 *
 *   // Returns conformed value in format suitable for the read API
 *   // Null value is returned as empty string ('')
 *   get_value_api_format
 *
 *   // error object, if value is an error
 *   error,
 * }
 *
 * value is parsed as following:
 * - string, number, date corresponds to js equivalents,
 * - reference has row api, plus reference_id field which equals to raw_value field. If there
 *   is a permission problem, only label and reference_id are visible from the row
 * - option is a {name, value, id} structure
 * @param runtime {Runtime}
 * @param raw_value {MaybeError<SingleCellRawValue>}
 * @param type {ColumnType<T, 'entity'>}
 * @return {SimpleCellObject<T>}
 */
function construct_simple_cell<T extends CellType>(
  runtime: Runtime,
  raw_value: MaybeError<SingleCellRawValue>,
  type: ColumnType<T, 'entity'>
): SimpleCellObject<T> {
  ensure(!Array.isArray(raw_value), "simple cell's raw_value can not be an array", {raw_value})
  const value = conform_single_value_to_type(runtime, raw_value, type)

  const get_value = () => {
    if (is_error(value)) {
      return throw_error_object(value)
    }
    return value as SingleCellValue<T>
  }

  const get_label = (enable_formatting: boolean = true) => {
    return single_value_to_string(value, type, enable_formatting)
  }

  const get_value_api_format = (enable_formatting: boolean = true) => {
    if (is_error(value)) return format_error(value)
    if (value === null) return ''
    switch (type.type) {
      case 'number':
        return value as number
      case 'date':
        return convert_date_to_iso_string(value as Date)
      case 'date_time':
        return convert_date_time_to_iso_string(value as Date)
      default:
        return single_value_to_string(value, type, enable_formatting)
    }
  }

  const get_value_api_format_v2 = () => {
    if (is_error(value)) return format_error(value)
    if (value === null) return null
    const formatted_value = get_value_api_format(false)
    if (type.type !== 'string' && formatted_value === '') {
      return null
    } else if (type.type === 'boolean') {
      return value as boolean
    }
    return formatted_value
  }

  const cell: SimpleCellObject<T> = {
    raw_value,
    get_value,
    get_label,
    get_value_api_format,
    get_value_api_format_v2,
  }

  if (is_error(value)) {
    cell.error = value
  }

  return cell
}

/**
 *  cell API:
 *  cell = {
 *
 *   // Raw value of the cell stored in database
 *   // can be null, string, number, array of strings in case of multi cell or an error object
 *   raw_value,
 *
 *   // Returns - conformed value (string, number, date, option object, row object) for single cell
 *   //         - array of conformed values for multi cell
 *   //         - throw an error, if value doesn't meet the column specification
 *   get_value,
 *
 *   // returns label of the cell (string seen by user)
 *   get_label,
 *
 *   // Returns conformed value in format suitable for the read API
 *   // Multi values can be only of type option and string for which
 *   //   SimpleCell.get_value_api_format returns string, so we can be sure about the assertion
 *   get_value_api_format
 *
 *   // [[actual, expected], ...] structure.
 *   // Currently both `actual` and `expected` are raw structures.
 *   conflicts,
 *
 *   // [[actual, expected], ...] structure in label form (string seen by user)
 *   get_conflicts_as_labels,
 *
 *   // error object, if raw_value is an error or at least one of the subcells is an error
 *   // undefined otherwise
 *   error,
 * }
 *
 * Returned CellObject is an array of SimpleCellObjects with similar API as an SimpleCellObject:
 * {raw_value, get_value, get_label, conflicts, error}
 *
 * In case of single cell this array contains only one simple cell object and
 * {raw_value, get_value, get_label, error} of the CellObject are identical as
 * {raw_value, get_value, get_label, error} of the SimpleCellObject in the array.
 *
 * In case of multi cell this array can contain more SimpleCellObjects
 * @param runtime {Runtime}
 * @param raw_value {MaybeError<CellRawValue>}
 * @param conflicts {CellConflict[] | null}
 * @param type {ColumnType<T, 'entity'>}
 * @return {CellObject<T>}
 */
function construct_cell<T extends CellType>(
  runtime: Runtime,
  raw_value: MaybeError<CellRawValue>,
  conflicts: CellConflict[] | null,
  type: ColumnType<T, 'entity'>
): CellObject<T> {
  let simple_cells: SimpleCellObject<T>[] = []
  let error: ErrorObject | null = null

  // for multi cell we want to have simple_cells array empty in case when raw_value is null
  // for single cell, null raw value can conform to an error (when empty values are not allowed)
  if (!(type.multi && raw_value === null)) {
    if (Array.isArray(raw_value)) {
      simple_cells = raw_value.map((raw_subvalue) => {
        return construct_simple_cell(runtime, raw_subvalue, type)
      })
    } else {
      simple_cells = [construct_simple_cell(runtime, raw_value, type)]
    }
  }

  const get_value = () => {
    if (is_error(error)) {
      return throw_error_object(error)
    }
    if (type.multi) {
      return simple_cells.map((sub_cell) => sub_cell.get_value())
    } else {
      return simple_cells[0].get_value()
    }
  }

  const get_label = (enable_formatting?: boolean) =>
    type.multi
      ? simple_cells.map((sub_cell) => sub_cell.get_label(enable_formatting)).join('; ')
      : simple_cells[0].get_label(enable_formatting)

  const get_value_api_format = () =>
    type.multi
      ? // must be string because only options and references can be multicell
        simple_cells.map((sub_cell) => sub_cell.get_value_api_format() as string)
      : simple_cells[0].get_value_api_format()

  const get_value_api_format_v2 = () => {
    if (type.multi) {
      if (simple_cells.length === 0) {
        return null
      }

      if (type.type === 'option') {
        return simple_cells.map((sub_cell) => sub_cell.get_value_api_format_v2()) as string[]
      }
      return simple_cells.map((sub_cell) =>
        sub_cell.get_value_api_format_v2()
      ) as FormattedReference[]
    }
    return simple_cells[0].get_value_api_format_v2()
  }

  if (is_error(raw_value)) {
    error = raw_value
  } else if (type.multi && simple_cells.some((sub_cell) => is_error(sub_cell.error))) {
    error = create_error('value', {
      value: raw_value,
      message: 'Cell contains invalid value',
      data: simple_cells.filter((sub_cell) => is_error(sub_cell.error)),
    })
  } else if (!type.multi && simple_cells.length > 0 && simple_cells[0].error) {
    error = simple_cells[0].error
  }

  const get_conflicts_as_labels = () => {
    return conflicts == null
      ? []
      : conflicts.map((pair) =>
          pair.map((value) => raw_value_to_string_wrapper(runtime, value, type))
        )
  }

  //The cell object could be put together more nicely (e.g. using spreading or Object.assign)
  //but since we aim for performance, it's done this ugly
  const cell: CellObject<T> = simple_cells as CellObject<T>
  cell.raw_value = raw_value
  cell.get_value = get_value as () => CellValue<T>
  cell.get_label = get_label
  cell.get_value_api_format = get_value_api_format
  cell.get_value_api_format_v2 = get_value_api_format_v2
  cell.get_conflicts_as_labels = get_conflicts_as_labels

  if (error) {
    cell.error = error
  }

  if (conflicts) {
    cell.conflicts = conflicts
  }

  return cell
}

export {construct_cell}
