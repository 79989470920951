import _ from 'lodash'
import {throw_error} from '../../utils'

export function assert_type(value, type) {
  if (value.type !== type) {
    throw_error('missing type', 'value', value, 'type', type)
  }
}

export const type_decorate_squash2 = (type, squash2) => (diff1, diff2) => {
  assert_type(diff1, type)
  assert_type(diff2, type)
  return {type, ...squash2(_.omit(diff1, 'type'), _.omit(diff2, 'type'))}
}

export const type_decorate_apply = (type, apply) => (entity, diff) => {
  assert_type(entity, type)
  assert_type(diff, type)
  return {type, ...apply(entity, _.omit(diff, 'type'))}
}

export const type_decorate_reverse = (type, reverse) => (diff) => {
  assert_type(diff, type)
  return {type, ...reverse(_.omit(diff, 'type'))}
}

export const type_decorate_ensure_consistency = (type, ensure_consistency) => (entity, diff) => {
  assert_type(entity, type)
  assert_type(diff, type)
  return {type, ...ensure_consistency(entity, _.omit(diff, 'type'))}
}

export const type_decorate_unapply = (type, unapply) => (entity, diff) => {
  assert_type(entity, type)
  assert_type(diff, type)
  return {type, ...unapply(entity, _.omit(diff, 'type'))}
}

export const type_decorate_change_diff = (type, change_diff) => (old_entity, new_entity) => {
  assert_type(old_entity, type)
  assert_type(new_entity, type)
  return {type, ...change_diff(_.omit(old_entity, 'type'), _.omit(new_entity, 'type'))}
}

export const type_decorate_clean_neutral_diffs = (type, remove_neutral_diff) => (diff) => {
  assert_type(diff, type)
  return {type, ...remove_neutral_diff(_.omit(diff, 'type'))}
}

export default {
  assert_type,
  type_decorate_apply,
  type_decorate_reverse,
  type_decorate_ensure_consistency,
  type_decorate_unapply,
  type_decorate_squash2,
  type_decorate_change_diff,
  type_decorate_clean_neutral_diffs,
}
