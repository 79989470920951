import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {open_modal} from '../Modals'
import {AlwaysDefinedRuntime, useRuntimeSelector, useTableUiSelector} from '../utils/connect_hocs'
import ContextMenuItem from './ContextMenuItem'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Add from '@material-ui/icons/Add'

type AddColumnItemsProps = {
  on_close: () => void
  is_column_selected: boolean
}

const AddColumnItems: React.FC<AddColumnItemsProps> = ({on_close, is_column_selected}) => {
  const {
    resources: {
      table_resources: {table_entity_id},
    },
  } = useRuntimeSelector() as AlwaysDefinedRuntime
  const cursor = useTableUiSelector(table_entity_id, 'cursor')
  const dispatch = useDispatch()
  const col_id = cursor ? cursor.col_id : null

  const add_column_left = useCallback(() => {
    dispatch(open_modal('add_column', {table_entity_id, col_id, where: 'left'}))
    on_close()
  }, [dispatch, table_entity_id, col_id, on_close])

  const add_column_right = useCallback(() => {
    dispatch(open_modal('add_column', {table_entity_id, col_id, where: 'right'}))
    on_close()
  }, [dispatch, table_entity_id, col_id, on_close])

  if (is_column_selected) {
    return (
      <>
        <ContextMenuItem
          onClick={add_column_left}
          icon={<ArrowBack fontSize="small" />}
          item="Add column Left"
        />
        <ContextMenuItem
          onClick={add_column_right}
          icon={<ArrowForward fontSize="small" />}
          item="Add column Right"
        />
      </>
    )
  } else {
    return (
      <>
        <ContextMenuItem
          onClick={add_column_right}
          icon={<Add fontSize="small" />}
          item="Add column"
        />
      </>
    )
  }
}

export default AddColumnItems
