import React, {FC, useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {Drawer, IconButton, InputBase, List} from '@material-ui/core'
import {styled} from '@material-ui/core/styles'
import _ from 'lodash'
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone'

import {TABLE_TYPES} from 'common/objects/data_table'

import {ReduxState, useRuntimeSelector} from '../utils/connect_hocs'
import TreeItem from './TreeItem'

export const EXPLORER_WIDTH = 300

const FilterInput = styled(InputBase)(({theme}) => ({
  width: EXPLORER_WIDTH,
  height: 56,
  flexShrink: 0,
  padding: `0 ${theme.spacing(2)}px`,
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  border: `1px solid ${theme.palette.divider}`,
}))

const StyledList = styled(List)(({theme}) => ({
  width: EXPLORER_WIDTH,
  padding: 0,
  overflowX: 'hidden',
}))

export type ExplorerState = {
  open: boolean
}

export const toggle_explorer = (open: boolean) => ({
  type: 'toggle_explorer',
  payload: {open},
  reducer: (state: ReduxState, explorer: ExplorerState): ReduxState => ({
    ...state,
    explorer,
  }),
})

type ExplorerListProps = {
  filter_query: string
}

const ExplorerList: FC<ExplorerListProps> = ({filter_query}) => {
  const {storage} = useRuntimeSelector()
  if (!storage) return <div>LOADING</div>

  const entities = _.pickBy(
    storage.entity_headers,
    (entity) =>
      entity.subtype !== 'permission_entities' &&
      ['organisation', 'project', ...TABLE_TYPES].includes(entity.type)
  )

  const organisations = Object.values(
    _.sortBy(
      _.pickBy(entities, (entity) => entity.type === 'organisation'),
      ['name']
    )
  )

  const filtered_list = filter_query
    ? _.sortBy(
        Object.values(entities).filter((entity) =>
          entity.name.toLocaleLowerCase().includes(filter_query.toLocaleLowerCase())
        ),
        ['name']
      )
    : organisations

  return (
    <>
      {filtered_list.map(({entity_id, type, name}) => (
        <TreeItem id={entity_id} key={entity_id} label={name} entities={entities} type={type} />
      ))}
    </>
  )
}

const Explorer = () => {
  const [filter_query, set_filter_query] = useState('')
  const handle_filter_change = useCallback(
    (event) => {
      set_filter_query(event.target.value)
    },
    [set_filter_query]
  )

  const explorer_state = useSelector((state: ReduxState) => state.explorer)

  return (
    <Drawer variant="persistent" open={explorer_state.open}>
      <FilterInput
        placeholder="Filter..."
        onChange={handle_filter_change}
        value={filter_query}
        startAdornment={
          <FilterListTwoToneIcon
            color={filter_query ? 'action' : 'disabled'}
            style={{marginRight: 32}}
          />
        }
        endAdornment={
          !!filter_query && (
            <IconButton
              size="small"
              onClick={() => {
                set_filter_query('')
              }}
            >
              <CloseTwoToneIcon fontSize="small" color="action" />
            </IconButton>
          )
        }
      />
      <StyledList>
        <ExplorerList filter_query={filter_query} />
      </StyledList>
    </Drawer>
  )
}

export default Explorer
