import {is_new_entity, is_new_view_table} from 'common/entities/diff_utils'
import {get_tables_in_project} from 'common/project_utils'
import {TableEntityId} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'

const undo = ({
  dispatch_storage,
  entity_headers,
  redirect_to_table,
  storage_utils,
  table,
  table_entity_id,
}: {
  dispatch_storage
  entity_headers
  redirect_to_table
  storage_utils
  table: TableObject
  table_entity_id: TableEntityId
}) => {
  const {get_active_diffs, is_able_to_undo} = storage_utils
  const {_zone_id: zone_id} = table
  if (is_able_to_undo(zone_id)) {
    const undo_diff = get_active_diffs(zone_id).pop()
    // Undoing the creation of the current table
    // We redirect to another existing table within the project
    if (undo_diff && undo_diff[table_entity_id] && is_new_entity(undo_diff[table_entity_id])) {
      if (is_new_view_table(undo_diff[table_entity_id])) {
        // if undoing view creation, redirect to the base table of that view
        const base_table_entity_id = entity_headers[table_entity_id].parent_id
        redirect_to_table(base_table_entity_id)
      } else {
        // otherwise find another table to redirect to
        const project_table_entity_ids = Object.keys(get_tables_in_project(entity_headers, zone_id))
        const first_table_entity_id = project_table_entity_ids.find((id) => id !== table_entity_id)
        if (first_table_entity_id) {
          redirect_to_table(first_table_entity_id)
        }
      }
    }

    dispatch_storage({type: 'undo', zone_id})
  }
}

const redo = ({
  dispatch_storage,
  storage_utils,
  table,
}: {
  dispatch_storage
  storage_utils
  table: TableObject
}) => {
  const {is_able_to_redo} = storage_utils
  const {_zone_id: zone_id} = table
  if (is_able_to_redo(zone_id)) {
    dispatch_storage({type: 'redo', zone_id})
  }
}

export {undo, redo}
