/*
 * This file contains functions, that are used to get and process data needed for rendering tables,
 * but don't directly depend on UI implementation. They can also be used for testing purposes,
 * displaying data in alternative ways, etc.
 */

import _ from 'lodash'
import {is_error} from 'common/error'
import {
  col_id_on_index,
  index_of_col,
  get_filtered_row_indexes,
  index_of_row,
  row_id_on_index,
} from './utils/table_helpers'
import val_diff, {ValueDiff} from 'common/diffs/base/value_diff'
import {ordered_diff} from 'common/diffs/base/ordered_set_diff'
import theme from './theme'
import {Runtime} from 'common/create_runtime'
import {
  CellRawValue,
  ColumnId,
  DataTable,
  EntityColumnSpec,
  RowId,
  TableId,
} from 'common/types/storage'
import {TableObject} from 'common/objects/data_table'
import {throw_error} from 'common/utils'
import {CellObject} from 'common/types/data_table'
import {FindCellsMap} from './TableFind/AsyncFind'
import {get_icon, get_icon_font} from './utils/icons_utils'
import {CursorCoordinates} from './utils/connect_hocs'

export type TableDiff = DataTable<'diff'>

export type CellChange = {
  change_type: 'new_cell' | 'diff'
  cell_change: ValueDiff<CellRawValue>
}

type GridCanvasIcon = {
  icon: string
  color?: string
  backgroundColor?: string
  font?: string
}

type GridCanvasCellStyle = Partial<{
  color: string
  backgroundColor: string
  mark: OptColor
  align: 'start' | 'end' | 'left' | 'right' | 'center'
  bold: boolean
  italic: boolean
  smallCaps: boolean
  mono: boolean
  // for icons
  left: GridCanvasIcon[]
  right: GridCanvasIcon[]
}>

type GridCanvasCell = [text: string | undefined | null, style: GridCanvasCellStyle]

const value_diff = val_diff()
const {get_old_index} = ordered_diff()

const CELL_VIEW_MODE = {
  default: 'default',
  find: 'find',
  changes_only: 'changes_only',
} as const
type CellViewMode = typeof CELL_VIEW_MODE[keyof typeof CELL_VIEW_MODE]

const load_table = (runtime: Runtime, table_id: TableId): TableObject => {
  return runtime.get_table_for_ui(table_id)._load()
}

const get_diff_for_table = (runtime: Runtime, table: TableObject): TableDiff | undefined => {
  const {_original_entity_id: original_entity_id, _original_zone_id: original_zone_id} = table
  if (table._is_error_table || !original_entity_id || !original_zone_id) return undefined
  const diff = runtime.storage.history_mode
    ? runtime.get_entity_diff(original_entity_id)?.data // diff for history mode
    : _.get(runtime.storage.multidiff[original_zone_id], original_entity_id)

  if (diff !== undefined && diff.type !== 'data_table') {
    throw_error('Unexpected diff type', 'type', diff.type, 'expected', 'data_table')
  }

  return diff
}

// get representation of cell change that can be used to display tooltips to user
const cell_change_from_diff = (
  diff_data: TableDiff | undefined,
  row_id: RowId,
  col_id: ColumnId
): CellChange | null => {
  if (_.isEmpty(diff_data)) return null // also checks if diff_data is undefined

  // check direct cell change
  const cell_change = _.get(diff_data, ['cells', row_id, col_id], null)

  // check if a value was added to an ordered set
  function is_added_to_os(checked_value, os_diff) {
    return os_diff?.[checked_value] && get_old_index(os_diff[checked_value]) === null
  }

  // check if the cell is in a new row or column
  const {cols_order, cells} = diff_data as TableDiff // safe assertion, see _.isEmpty() above
  if (
    _.isEqual(_.get(cells, [row_id, 'keep'], false), value_diff.create_diff(true)) ||
    is_added_to_os(col_id, cols_order)
  ) {
    return {change_type: 'new_cell', cell_change}
  } else if (
    !_.isEmpty(cell_change) &&
    !_.isEqual(value_diff.get_old_value(cell_change), value_diff.get_new_value(cell_change))
  ) {
    return {change_type: 'diff', cell_change}
  }

  return null
}

type OptColor = string | null

const _cell_error_color = ({cell}): OptColor =>
  is_error(cell.error) ? theme.palette.error.main : null

const _conflict_color = ({cell}): OptColor =>
  !_.isEmpty(cell.conflicts) ? theme.palette.warning.main : null

const _custom_tooltips_color = ({custom_tooltips}): OptColor =>
  !_.isEmpty(custom_tooltips) ? theme.palette.info.main : null

const _style_error_color = ({style}): OptColor =>
  is_error(style) ? theme.palette.error.main : null

const _tooltip_error_color = ({custom_tooltips}): OptColor =>
  is_error(custom_tooltips) ? theme.palette.error.main : null

const get_cell_indicator_color = (table, cell, row_id, col_id, mode): OptColor => {
  const custom_tooltips = table._get_tooltips(row_id, col_id)
  const style = table._get_style(row_id, col_id)
  const col_spec = table._cols[col_id]
  const args = {cell, col_spec, custom_tooltips, style}
  // first non-null color is returned
  return mode === CELL_VIEW_MODE.changes_only
    ? _cell_error_color(args) || _conflict_color(args)
    : _cell_error_color(args) ||
        _style_error_color(args) ||
        _tooltip_error_color(args) ||
        _conflict_color(args) ||
        _custom_tooltips_color(args)
}

const DEFAULT_ROW_NUM_COL_WIDTH = 65

const get_table_size = (table: TableObject) => {
  const DEFAULT_COL_WIDTH = 100
  // To include column with row numbers
  const width = table.col_count() + 1
  const height = table.row_count() + 1
  const columns_widths = [
    DEFAULT_ROW_NUM_COL_WIDTH,
    ...table._visible_cols_order.map((col_id) => table._cols[col_id].width || DEFAULT_COL_WIDTH),
  ]
  return {width, height, columns_widths}
}

const get_diff_style = (diff: TableDiff | undefined, row_id: RowId, col_id: ColumnId) => {
  const change = cell_change_from_diff(diff, row_id, col_id)
  if (change !== null) {
    const cellColors = theme.palette.grid.cell
    return {
      backgroundColor:
        change.change_type === 'new_cell'
          ? cellColors.createdBackground
          : cellColors.changedBackground,
    }
  }
  return {}
}

const get_cell_style = (
  table: TableObject,
  row_id: RowId,
  col_id: ColumnId,
  mode: CellViewMode,
  diff: TableDiff | undefined,
  found_cells: FindCellsMap | undefined
) => {
  switch (mode) {
    case 'changes_only':
      return get_diff_style(diff, row_id, col_id)
    case 'find': {
      const cellColors = theme.palette.grid.cell
      return found_cells?.[row_id]?.[col_id] ? {backgroundColor: cellColors.foundBackground} : {}
    }
    default:
      return table._get_style(row_id, col_id)
  }
}

const get_column_alignment = (col_spec: EntityColumnSpec): 'end' | 'start' =>
  col_spec.type.type === 'number' ? 'end' : 'start'

const emptyRowLineNumber: GridCanvasCell = ['+', {bold: true, mono: true}]
const emptyRowCell: GridCanvasCell = ['', {}]

// returns cell getter (see `get_cell`) used to render table
// only row and col indexes are needed to access cell data using `get_cell`
const get_cell_getter = (
  table: TableObject,
  diff: TableDiff | undefined,
  found_cells: FindCellsMap | undefined,
  show_changes: boolean,
  show_find: boolean,
  rows_order: string[],
  full_rows_order: string[],
  filtered_cols: ColumnId[]
) => {
  const table_rows = table.rows(rows_order || undefined)
  const row_indexes = get_filtered_row_indexes(full_rows_order, rows_order)

  const mode = show_find
    ? CELL_VIEW_MODE.find
    : show_changes
    ? CELL_VIEW_MODE.changes_only
    : CELL_VIEW_MODE.default
  const cell_style = (cell: CellObject, row_id: RowId, col_id: ColumnId): GridCanvasCellStyle => {
    // in show_changes mode the user defined style is not taken into account,
    // background color of changed/new cells is set instead
    const style = get_cell_style(table, row_id, col_id, mode, diff, found_cells)

    // Note: this is a potential performance hog
    // as it is run inside the busy loop of GridCanvas
    // and we are doing rather non-trivial calculations here
    const mark = get_cell_indicator_color(table, cell, row_id, col_id, mode)
    const align = get_column_alignment(table._cols[col_id])

    return {mark, align, ...(is_error(style) ? undefined : style)}
  }

  const get_cell = (c: number, r: number): GridCanvasCell => {
    // if the row number is one more than the number of rows in
    // the table we will render an empty row
    if (r === table_rows.length + 1) {
      return c === 0 ? emptyRowLineNumber : emptyRowCell
    }
    if (c > table.col_count() || r > table_rows.length) {
      console.warn('Calling get_cell out of bounds.')
      return [undefined, {}]
    }

    // Column for row numbering
    if (c === 0) {
      return r === 0 ? ['', {}] : [String(row_indexes[r - 1] + 1), {}]
    }

    const col_id = col_id_on_index(table, c - 1)
    // Column header cells
    if (r === 0) {
      const col_spec = table._cols[col_id]
      const is_filtered_col = filtered_cols.includes(col_id)
      const is_sorted_col = table._validated_order_by
        .map((sorted_field) => sorted_field[0])
        .includes(col_id)
      const background_color = is_sorted_col ? theme.palette.warning.light : '#fff'

      const cell_style = {
        backgroundColor: background_color,
        color: theme.palette.greyPalette[400],
      }

      const data_type_icon = get_icon(col_spec.type.type, true)
      const left = [
        {
          icon: data_type_icon,
          color: theme.palette.greyPalette[400],
          backgroundColor: background_color,
          font: get_icon_font(data_type_icon),
        },
      ]

      const right: GridCanvasIcon[] = []

      if (is_filtered_col) {
        right.push({
          icon: '\uf111', // fa-circle
          color: theme.palette.greenPalette[600],
          backgroundColor: background_color,
          font: get_icon_font('\uf111'),
        })
      }

      if (col_spec.description) {
        right.push({
          icon: '\uf05a', // fa-info-circle
          color: 'darkblue',
          backgroundColor: background_color,
        })
      }

      return [String(col_spec.name), {...cell_style, left, right}]
    }
    const row = table_rows[r - 1]
    const cell = row._cell(col_id)
    const res = String(cell.get_label())

    const style = cell_style(cell, row.row_id, col_id)
    return [res, style]
  }
  return get_cell
}

function xy<T>(f: (i: number) => T): [T, T] {
  return [0, 1].map(f) as [T, T]
}

function cell_in_bounds(cell_position, lower_bound, upper_bound?) {
  if (!cell_position) return false
  if (lower_bound && _.some(xy((i) => cell_position[i] < lower_bound[i]))) return false
  if (upper_bound && _.some(xy((i) => cell_position[i] >= upper_bound[i]))) return false
  return true
}

// in y-dimension, we cannot edit header, so we cut at y >= 1
// in x-dimension, we cannot edit row num. col., we cut at x >= 1
const edit_lower_bound = Object.freeze([1, 1])

function get_column_selection(
  table: TableObject,
  cursor: CursorCoordinates | null,
  selection_size: [number, number]
): ColumnId[] {
  if (!cursor || !selection_size) return []

  const cursor_col = cursor.col_id == null ? -1 : index_of_col(table, cursor.col_id)

  const selected_cols_offset = selection_size[0]
  const selection_bounds = _.sortBy([cursor_col, cursor_col + selected_cols_offset])

  return _.range(
    Math.max(selection_bounds[0], 0),
    Math.min(selection_bounds[1] + 1, table._cols_order.length)
  ).map((i) => col_id_on_index(table, i))
}

function get_row_selection(
  rows_order: RowId[],
  cursor: CursorCoordinates | null,
  selection_size: [number, number]
): RowId[] {
  if (!cursor || !selection_size) return []
  const cursor_row = cursor.row_id == null ? -1 : index_of_row(rows_order, cursor.row_id)
  const selected_rows_offset = selection_size[1]

  const selection_bounds = _([cursor_row, cursor_row + selected_rows_offset])
    .sortBy()
    .value()

  return _.range(
    Math.max(selection_bounds[0], 0),
    Math.min(selection_bounds[1] + 1, rows_order.length)
  ).map((i) => row_id_on_index(rows_order, i))
}

export {
  CELL_VIEW_MODE,
  get_column_alignment,
  get_cell_getter,
  cell_change_from_diff,
  load_table,
  get_diff_for_table,
  DEFAULT_ROW_NUM_COL_WIDTH,
  get_table_size,
  get_diff_style,
  cell_in_bounds,
  get_column_selection,
  get_row_selection,
  xy,
  edit_lower_bound,
}
