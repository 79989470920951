import React, {FC, ReactNode} from 'react'
import {IconButton, Tooltip} from '@material-ui/core'
import {createStyles, withStyles} from '@material-ui/core/styles'

type IconButtonProps = {
  onClick: (e?: any) => void
  disabled?: boolean
  size?: 'small' | 'medium'
  children: ReactNode
  tooltip: string
}

const StyledIconButton = withStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.greyPalette[400],
    },
  })
)(IconButton)

const CustomIconButton: FC<IconButtonProps> = ({
  onClick,
  disabled,
  size = 'small',
  children,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <StyledIconButton size={size} color="inherit" onClick={onClick} disabled={disabled}>
          {children}
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}

export default CustomIconButton
