import React, {FC} from 'react'
import {CircularProgress} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

import {useRuntimeSelector} from '../utils/connect_hocs'
import {useBlockingCallback} from '../utils/hooks'
import {useRuntimeActions} from '../RuntimeContextProvider'
import CustomIconButton from '../components/CustomIconButton'

const RefreshButton: FC = () => {
  const {fetch_entity_headers_action, fetch_commits_action} = useRuntimeActions()
  const {
    storage,
    resources: {project_resources, table_resources, organisation_resources},
  } = useRuntimeSelector()
  const {
    downloads: {entity_headers},
  } = storage!
  const zone_id = project_resources
    ? project_resources.project.zone_id
    : table_resources
    ? table_resources.zone_id
    : organisation_resources?.organisation_id

  const [reload, reload_running] = useBlockingCallback(async () => {
    if (zone_id == null || entity_headers?.[zone_id] == null) {
      // do not fetch commits if there isn't selected zone or zone is new created by local diffs
      await fetch_entity_headers_action()
    } else {
      //fetching commits and headers in parallel
      await Promise.all([fetch_entity_headers_action(), fetch_commits_action(zone_id)])
    }
  }, [zone_id, fetch_entity_headers_action, fetch_commits_action, entity_headers])

  return (
    <CustomIconButton tooltip="Refresh" onClick={reload} disabled={reload_running}>
      {reload_running ? <CircularProgress size={24} /> : <RefreshIcon />}
    </CustomIconButton>
  )
}

export default RefreshButton
