import {Fn} from './utils'
import _ from 'lodash'

/**
 * @module Error
 */
const error_sentinel = '__bee__error_sentinel'

export type ErrorType =
  | 'missing-privileges'
  | 'circular-dependency'
  | 'depend'
  | 'in-progress'
  | 'network-error'
  | 'parse'
  | 'ref'
  | 'user'
  | 'value'
  | 'external-error'
  | 'other'

export type ErrorObject = {
  [error_sentinel]: true
  original_error?: Error
  message?: string
  subtype?: string
  stack?: string
  type: ErrorType
  value?: any
}

export type MaybeError<T> = T | ErrorObject

function create_error(type: ErrorType, data?: object): ErrorObject {
  const stack = {stack: new Error().stack}
  return {[error_sentinel]: true, ...stack, ...data, type}
}

function format_error(err: ErrorObject): string {
  return `#${err.type.toUpperCase()}`
}

function throw_error_object(err: ErrorObject): never {
  const current_stack = new Error().stack
  const stack = current_stack + (err && err.stack ? '\nOriginal stacktrace:\n' + err.stack : '') //eslint-disable-line prefer-template
  throw {...err, stack} //eslint-disable-line no-throw-literal
}

function is_error(obj: unknown): obj is ErrorObject {
  return _.isObject(obj) && obj[error_sentinel] === true
}

function throw_on_error<K extends any[], T>(fn: Fn<K, MaybeError<T>>): Fn<K, T> {
  return (...args) => {
    const res = fn(...args)
    if (is_error(res)) {
      return throw_error_object(res)
    } else {
      return res
    }
  }
}

/**
 * Error that means that there are no changes for entity to be displayed - either there is no commit
 * for given entity or we are missing privileges to display commit changes
 * @param error {ErrorObject}
 * @return {boolean}
 */
function entity_has_no_changes_to_display(error: ErrorObject) {
  return (
    (error.type === 'user' && error.subtype === 'unknown-entity-commit') ||
    (error.type === 'missing-privileges' && error.subtype === 'history')
  )
}

export {
  create_error,
  is_error,
  format_error,
  throw_on_error,
  throw_error_object,
  error_sentinel as _error_sentinel,
  entity_has_no_changes_to_display,
}
