import React, {FC} from 'react'

import ProjectTables from './ProjectTables'
import {AlwaysDefinedRuntime, useRuntimeSelector} from '../utils/connect_hocs'
import LoadingSkeleton from '../components/LoadingSkeleton'

const ProjectGallery: FC = () => {
  const {
    resources: {project_resources},
  } = useRuntimeSelector() as AlwaysDefinedRuntime

  if (!project_resources) {
    return <LoadingSkeleton />
  }

  return (
    <>
      <ProjectTables />
    </>
  )
}

export default ProjectGallery
