import {fetch_json} from './networking'
import {Fetchers} from './create_runtime_context'
import {ZoneId, ResourceId, CommitId} from './types/storage'
import {fetch_external_data} from './external_data'

const get_fetchers = (host?: string, port?: string): Fetchers => {
  const prefix = host == null || port == null ? '' : `http://${host}:${port}`
  const fetchers = {
    fetch_commits: (zone_id: ZoneId) =>
      fetch_json(`${prefix}/commits/${encodeURIComponent(zone_id)}`),
    fetch_resource: (resource_id: ResourceId, commit_id: CommitId) =>
      fetch_json(
        `${prefix}/resource/${encodeURIComponent(commit_id)}/${encodeURIComponent(resource_id)}`
      ),
    fetch_entity_headers: (include_archived: boolean = false) =>
      fetch_json(`${prefix}/headers?include_archived=${include_archived}`),
    fetch_external_data,
  }
  return fetchers
}

export {get_fetchers}
