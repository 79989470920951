type AwsConfiguration = {
  Auth?: {
    // Amazon Cognito Region
    region: string
    // Amazon Cognito Identity Pool ID
    identityPoolId?: string
    // Amazon Cognito Federated Identity Pool Region
    // Required if it's different from Amazon Cognito Region.
    identityPoolRegion?: string
    // Amazon Cognito User Pool ID
    userPoolId?: string
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId?: string
    // Mandatory sing-in flag
    // Indicates if user authentication is enforced before accessing AWS resources.
    mandatorySignIn?: boolean
    // Amazon Cognito User Pool Authentication Flow Type
    // Default value is 'USER_SRP_AUTH'.
    authenticationFlowType?: string

    // Configuration for cookie storage
    cookieStorage?: {
      // Cookie domain
      domain: string
      // Cookie path
      path?: string
      // Cookie expiration in days
      expires?: number
      // Cookie secure flag
      // Indicates if the cookie transmission requires a secure protocol (HTTPS).
      secure?: boolean
    }

    // Amazon Cognito Hosted UI configuration
    oauth?: {
      // Amazon Cognito domain
      domain: string
      // List of allowed OAuth scopes
      scope: string[]
      // URL to which Amazon Cognito Hosted UI will redirect the user
      // after a successful sign in
      redirectSignIn: string
      // URL to which Amazon Cognito Hosted UI will redirect the user
      // after a successful sign out
      redirectSignOut: string
      // OAuth Grant type requested from Amazon Cognito
      // Possible values are:
      // 'code' for Authorization Code Grant and
      // 'token' for Implicit Flow Grant.
      responseType: string
    }
  }
}

let cognito_region: string
let cognito_domain: string
if (process.env.REACT_APP_cognito_region && process.env.REACT_APP_cognito_domain) {
  cognito_region = process.env.REACT_APP_cognito_region
  cognito_domain = process.env.REACT_APP_cognito_domain
} else {
  throw new Error('cognito_region and cognito_domain environment variables must be set')
}

const configuration: AwsConfiguration = {
  Auth: {
    region: cognito_region,
    userPoolId: process.env.REACT_APP_cognito_user_pool_id,
    userPoolWebClientId: process.env.REACT_APP_cognito_user_pool_web_client_id,

    oauth: {
      domain: cognito_domain,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${window.location.origin}/sign-in`,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },

    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 30,
      // A separate condition for setting the 'secure' cookie attribute
      // independently from the hostname
      // is needed e.g. in cases when the application
      // is run within a local network.
      secure: window.location.protocol === 'https:',
    },
  },
}

export default configuration
